import { IFSkeleton } from 'Components'
import React from 'react'
import Styles from './StationConnectorMapComponentSkeleton.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'

const MaxPowerContainer = styled.div`
  background-color: ${Colors.MaxPowerBackground};
`

const StationConnectorMapComponentSkeleton = () => {
  return (
    <div
      className={Styles.Container}
      style={{
        border: `1px solid ${Colors.ConnectorComponentBorder}`,
      }}
    >
      <MaxPowerContainer className={Styles.MaxPowerContainer}>
        <IFSkeleton variant="text" width="2rem" height="1.5rem" />
        <IFSkeleton variant="text" width="3rem" height="1.5rem" />
      </MaxPowerContainer>
      <div className={Styles.IconContainer}>
        <IFSkeleton variant="circular" width="1.5rem" height="1.5rem" />
      </div>
      <div className={Styles.UIDContainer}>
        <IFSkeleton variant="text" width="3.5rem" height="1.5rem" />
      </div>
      <div className={Styles.LastUpdatedContainer}>
        <IFSkeleton variant="text" width="4rem" height="1.5rem" />
      </div>
    </div>
  )
}

export default StationConnectorMapComponentSkeleton
