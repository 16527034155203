import axios from 'axios'
import i18n from 'i18next'
import { Config } from 'Config'
import { toaster } from 'rsuite'
import AuthActions from 'Stores/Auth/Actions'
import { IFToastMessage } from 'Components'
import storage from 'redux-persist/lib/storage'

var base64 = require('base-64')

let store
const DEFAULT_TIMEOUT = 0
const DEFAULT_OPTIONS = {
  timeout: DEFAULT_TIMEOUT,
  headers: { store },
}
/**
 * Creates axios instances with different custom configurations
 */

export const authApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/auth',
})

const tenantUserTokenRefreshService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/auth/tenantUser/refreshAccessToken',
})

export const tenantUserApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/tenantUsers',
})

export const stationApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/stations',
})

export const chargePointApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/chargePoints',
})
export const chargePointDataApiService = axios.create({
  ...DEFAULT_OPTIONS,
  responseType: 'blob',
  baseURL: Config.API_URL + '/chargePoints',
})

export const chargeApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/charge',
})

export const chargingTokenService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/chargingTokens',
})

export const cityService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/cities',
})

const invalidTokenErrorCode = 'J0000'
const forceLogout = async () => {
  await storage.removeItem('persist:main')
  await storage.removeItem('persist:secure')
  window.location = '/auth/login'
}
export const subscriptionService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/subscriptions',
})
export const privacyRuleService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/privacyRules',
})

export const accessGroupApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/accessGroups',
})

export const userApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/users',
})

export const chargingProfileApiService = axios.create({
  ...DEFAULT_OPTIONS,
  baseURL: Config.API_URL + '/chargingProfiles',
})

// axiosRetry(chargingApiService, { retries: 10, retryDelay: 6000 })
const handleInvalidToken = async (error) => {
  if (
    error?.response?.data?.code === invalidTokenErrorCode ||
    error?.code === invalidTokenErrorCode
  ) {
    await forceLogout()
  }
}
export function initApiService(_store) {
  store = _store
}

export async function handleError(error) {
  error = await error

  if (error?.code === 'I0029') return

  if (error.status && error.message && error.message.en) {
    if (i18n.language === 'ar') {
      toaster.push(<IFToastMessage type="error" text={error.message.ar} />, {
        placement: 'topEnd',
      })
    } else {
      toaster.push(<IFToastMessage type="error" text={error.message.en} />, {
        placement: 'topEnd',
      })
    }
  } else {
    if (i18n.language === 'ar') {
      toaster.push(
        <IFToastMessage type="error" text="لا يوجد اتصال بالإنترنت" />,
        { placement: 'topEnd' },
      )
    } else {
      toaster.push(
        <IFToastMessage type={'error'} text={'No Internet Connection'} />,
        { placement: 'topEnd' },
      )
    }
  }
}
/**
 * Sets Authorization header
 */
export const setAuthHeader = (token, refreshToken) => {
  store.dispatch(AuthActions.setAuthToken(token))
  if (refreshToken)
    tenantUserTokenRefreshService.defaults.headers.common[
      'x-tenant-refresh-token'
    ] = refreshToken
  _executeOnAllServices((service) => {
    service.defaults.headers.common['x-tenant-auth-token'] = token
    if (refreshToken)
      service.defaults.headers.common['x-tenant-refresh-token'] = refreshToken
  })
}
;(function () {
  _handleUnauthorizedRequests()
})()

function _handleUnauthorizedRequests() {
  const flattenErrorInfo = async ({
    response: {
      status,
      statusText,
      data: { message },
      data: { data },
      data: { code },
    },
  }) => {
    return {
      status,
      statusText,
      message,
      data,
      code,
    }
  }

  const globalErrorHandler = async (error) => {
    if (error && error.response) {
      await handleInvalidToken(error)
      return Promise.reject(flattenErrorInfo(error))
    } else {
      return Promise.reject(error)
    }
  }

  _executeOnAllServices((service) => {
    service.interceptors.response.use(
      (response) => response,
      globalErrorHandler,
    )

    service.interceptors.request.use(async (config) => {
      config.headers.common['x-tenant-auth-token'] =
        store.getState().secure.authState.token
      config.headers.common['x-tenant-refresh-token'] =
        store.getState().secure.authState.refreshToken

      if (
        store.getState().secure.authState.token !== null &&
        config.baseURL !== Config.API_URL + '/auth' &&
        config.url !== '/resetPasswordEmailVerification' &&
        config.url !== '/resetPassword'
      ) {
        const [, payload] =
          config.headers.common['x-tenant-auth-token'].split('.')
        const { exp: expires } = JSON.parse(base64.decode(payload))
        const now = Date.now()
        let expiry = expires * 1000
        const diff = (expiry - now) / 60000

        try {
          if (diff < 5) {
            const tokenResponse = await tenantUserTokenRefreshService.post(
              '/',
              {},
              {
                headers: {
                  'x-tenant-auth-token':
                    store.getState().secure.authState.token,
                  'x-tenant-refresh-token':
                    store.getState().secure.authState.refreshToken,
                },
              },
            )
            config.headers.common['x-tenant-auth-token'] =
              tokenResponse.headers['x-tenant-auth-token']
            setAuthHeader(
              tokenResponse.headers['x-tenant-auth-token'],
              tokenResponse.headers['x-tenant-refresh-token'],
            )
          }
        } catch (error) {
          if (error && error.response) {
            const { status } = error.response
            if (status === 401) {
              // TODO: Navigate to Login & revoke access & reset data
              console.log('Unauthorized')
              await forceLogout()
              toaster.push(
                <IFToastMessage type="error" text="Please login again" />,
                { placement: 'topEnd' },
              )
            }
            return Promise.reject(flattenErrorInfo(error))
          } else {
            return Promise.reject(error)
          }
        }
      }
      return config
    })
  })
}

/**
 * Helper function
 */
function _executeOnAllServices(action) {
  ;[authApiService].forEach(action)
  ;[tenantUserApiService].forEach(action)
  ;[stationApiService].forEach(action)
  ;[chargePointApiService].forEach(action)
  ;[chargePointDataApiService].forEach(action)
  ;[chargeApiService].forEach(action)
  ;[chargingTokenService].forEach(action)
  ;[subscriptionService].forEach(action)
  ;[privacyRuleService].forEach(action)
  ;[accessGroupApiService].forEach(action)
  ;[userApiService].forEach(action)
  ;[chargingProfileApiService].forEach(action)
  ;[cityService].forEach(action)
}
