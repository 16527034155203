import React, { useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import AccessGroupList from '../AccessGroupList/AccessGroupList'

const ModalContainer = forwardRef(
  (
    {
      accessGroups,
      fetchAccessGroups,
      isEditing,
      maxListHeight,
      scrollbarsHeight,
      onModalSubmit = () => {},
    },
    ref,
  ) => {
    const onSubmit = (addSubscriptions, deleteSubscriptions) => {
      onModalSubmit(addSubscriptions)
    }

    useEffect(() => {
      fetchAccessGroups()
    }, [])

    return (
      <div>
        <AccessGroupList
          ref={ref}
          displayedList={[]}
          dropdownList={accessGroups}
          isEditing={isEditing}
          isLoading={false}
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={onSubmit}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

const mapStateToProps = (state) => ({
  accessGroups: TenantUserSelectors.getAccessGroupsList(state),
})

ModalContainer.propTypes = {
  accessGroups: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  fetchAccessGroups: PropTypes.func,
  maxListHeight: PropTypes.number,
  scrollbarsHeight: PropTypes.string,
  onModalSubmit: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ModalContainer)
