import React, { useState, useEffect } from 'react'
import Styles from './SearchBox.module.css'
import { Colors } from 'Theme'
import styled from 'styled-components'
import { IFText, IFsvg, IFTextInput } from 'Components'
import { isPropsMatch } from 'Utils/PropsMatch'

const WrapperContainer = styled.div`
  box-shadow: ${Colors.BoxShadow} 0px 0px 4px;
  background-color: ${Colors.white};
`

const CircleContainer = styled.div`
  &:hover {
    border-radius: 50%;
    background-color: ${(props) =>
      props.hasResults ? 'lightgray' : Colors.white};
    transition: background-color 0.5s, border-radius 0s;
  }
`

const DividerVertical = styled.div`
  border-left: 1px solid ${Colors.MapSearchBoxDivider};
  width: 1px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`

const SearchBox = ({
  mapRef,
  stationMapClustersSearch,
  fetchStationMapClustersSearch,
  setStationMapClustersSearchWord,
  showPopover,
  hidePopover,
  clearStationMapClustersSearchWord,
  clearStationMapClustersSearch,
}) => {
  const [textInputValue, setTextInputValue] = useState('')
  const [displayIndex, setDisplayIndex] = useState(0)
  const [showDisplayCounter, setShowDisplayCounter] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  useEffect(() => {
    return () => {
      clearStationMapClustersSearchWord()
      clearStationMapClustersSearch()
    }
  }, [])

  const resetValues = () => {
    setTextInputValue('')
    setDisplayIndex(0)
    setShowDisplayCounter(false)
  }

  useEffect(() => {
    if (mapRef.current.setZoom && stationMapClustersSearch.length > 0) {
      mapRef.current.setZoom(stationMapClustersSearch[0].zoomLevel)
      mapRef.current.panTo({
        lat: stationMapClustersSearch[0].coordinates[1],
        lng: stationMapClustersSearch[0].coordinates[0],
      })
      setTimeout(() => {
        showPopover(
          `${stationMapClustersSearch[0].coordinates[1]},${stationMapClustersSearch[0].coordinates[0]}`,
        )
      }, 1000)
    }
  }, [stationMapClustersSearch])

  const panToItem = (index) => {
    if (mapRef.current.setZoom && stationMapClustersSearch[index]) {
      mapRef.current?.setZoom(stationMapClustersSearch[index].zoomLevel)
      mapRef.current?.panTo({
        lat: stationMapClustersSearch[index].coordinates[1],
        lng: stationMapClustersSearch[index].coordinates[0],
      })

      setTimeout(() => {
        showPopover(
          `${stationMapClustersSearch[index].coordinates[1]},${stationMapClustersSearch[index].coordinates[0]}`,
        )
      }, 1000)
    }
  }

  return (
    <WrapperContainer
      className={
        isClicked
          ? Styles.WrapperContainerVisible
          : Styles.WrapperContainerHidden
      }
    >
      <div
        className={Styles.SearchContainer}
        onClick={() => setIsClicked(true)}
      >
        <IFsvg.Search
          height={23}
          width={23}
          fill={isClicked ? Colors.black : Colors.MapSearchLightGray}
        />
      </div>
      <div
        className={
          isClicked
            ? Styles.TextInputContainerVisible
            : Styles.TextInputContainerHidden
        }
      >
        <IFTextInput
          isFixed={true}
          value={textInputValue}
          className={Styles.TextInput}
          fontSize={14}
          onChange={(e) => {
            setTextInputValue(e.target.value)
            if (e.target.value.length === 0) {
              setShowDisplayCounter(false)
              setDisplayIndex(0)
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && textInputValue.length > 0) {
              hidePopover()
              clearStationMapClustersSearch()
              setDisplayIndex(0)
              setShowDisplayCounter(true)
              setStationMapClustersSearchWord(textInputValue)
              fetchStationMapClustersSearch()
            }
          }}
        />
        <div className={Styles.ResultsCounterContainer}>
          {showDisplayCounter ? (
            <IFText className={Styles.CounterText}>{`${
              stationMapClustersSearch.length === 0 ? 0 : displayIndex + 1
            }/${stationMapClustersSearch.length}`}</IFText>
          ) : null}
        </div>
      </div>
      <DividerVertical
        className={isClicked ? Styles.DividerVisible : Styles.DividerHidden}
      />
      <div
        className={
          isClicked ? Styles.IconsContainerVisible : Styles.IconsContainerHidden
        }
      >
        <CircleContainer
          className={Styles.CircleContainer}
          hasResults={
            stationMapClustersSearch.length > 0 && textInputValue.length > 0
          }
          onClick={
            isClicked &&
            stationMapClustersSearch.length > 0 &&
            textInputValue.length > 0
              ? () => {
                  if (displayIndex - 1 < 0) {
                    if (stationMapClustersSearch.length !== 1) hidePopover()
                    panToItem(stationMapClustersSearch.length - 1)
                    setDisplayIndex(stationMapClustersSearch.length - 1)
                  } else {
                    if (stationMapClustersSearch.length !== 1) hidePopover()
                    panToItem(displayIndex - 1)
                    setDisplayIndex((prevState) => prevState - 1)
                  }
                }
              : () => {}
          }
        >
          <IFsvg.Up height={20} width={20} fill={Colors.MapSearchBoxIcons} />
        </CircleContainer>
        <CircleContainer
          className={Styles.CircleContainer}
          hasResults={
            stationMapClustersSearch.length > 0 && textInputValue.length > 0
          }
          onClick={
            isClicked &&
            stationMapClustersSearch.length > 0 &&
            textInputValue.length > 0
              ? () => {
                  if (displayIndex + 1 === stationMapClustersSearch.length) {
                    if (stationMapClustersSearch.length !== 1) hidePopover()
                    panToItem(0)
                    setDisplayIndex(0)
                  } else {
                    if (stationMapClustersSearch.length !== 1) hidePopover()
                    panToItem(displayIndex + 1)
                    setDisplayIndex((prevState) => prevState + 1)
                  }
                  panToItem()
                }
              : () => {}
          }
        >
          <IFsvg.Down height={20} width={20} fill={Colors.MapSearchBoxIcons} />
        </CircleContainer>
        <CircleContainer
          className={Styles.CircleContainer}
          hasResults={true}
          onClick={
            isClicked
              ? () => {
                  setIsClicked(false)
                  resetValues()
                }
              : () => {}
          }
        >
          <IFsvg.Cancel
            height={18}
            width={18}
            fill={Colors.MapSearchBoxIcons}
          />
        </CircleContainer>
      </div>
    </WrapperContainer>
  )
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, ['stationMapClustersSearch'])
}

export default React.memo(SearchBox, shouldSkipRender)
