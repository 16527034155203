import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import Styles from './ChargingSchedulePeriodList.module.css'
import { ChargingSchedulePeriodListItem } from 'Components'
import { Colors } from 'Theme'
import styled from 'styled-components'
import AddIcon from '@mui/icons-material/Add'

function generateFormattedDateList(numHours = 23) {
  const formattedDateList = []
  const hourInMillis = 60 * 60 * 1000 // Number of milliseconds in an hour
  const currentDate = new Date()

  // Calculate the starting time as 1 AM
  currentDate.setUTCHours(1, 0, 0, 0)

  let startingTime = new Date(currentDate)

  // Iterate over the number of hours specified
  for (let i = 0; i < numHours; i++) {
    // Format the date in "hh aa" (12-hour clock with AM/PM)
    const formattedDate = startingTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC',
    })

    formattedDateList.push({
      label: formattedDate,
      value: startingTime.toString(),
    })

    // Increment the starting time by 1 hour
    startingTime = new Date(startingTime.getTime() + hourInMillis)
  }

  return formattedDateList
}

const StyledDiv = styled.div`
  &:hover {
    color: whitesmoke;
  }
`

const ChargingSchedulePeriodList = React.forwardRef(
  ({ onValueSelected, defaultMaxCurrentInput }, ref) => {
    const allDates = generateFormattedDateList()
    const [scheduleListCount, setScheduleListCount] = useState(0)
    const [selectedHours, setSelectedHours] = useState([
      allDates[allDates.length - 1],
    ])
    const [availableHours, setAvailableHours] = useState(
      allDates.filter(
        (dateObject) =>
          !selectedHours.some((x) => x?.value === dateObject?.value),
      ),
    )
    const [chargingPeriods, setChargingPeriods] = useState([])
    const firstEntryRef = useRef()
    const getChargingPeriods = () => {
      return [...chargingPeriods]
    }
    useImperativeHandle(ref, () => ({
      getChargingPeriods,
    }))

    useEffect(() => {
      addEntry({
        id: firstEntryRef.current.getId(),
        time: new Date().setUTCHours(0, 0, 0, 0),
        current: defaultMaxCurrentInput,
      })
    }, [])

    const addEntry = (entry) => {
      if (entry.time && entry.current) {
        entry.time = new Date(entry.time).getTime()
        const entryIndex = chargingPeriods.findIndex(
          (period) => period.id === entry.id,
        )
        if (entryIndex !== -1) {
          chargingPeriods[entryIndex] = entry
        } else {
          chargingPeriods.push(entry)
        }
        const sortedChargingPeriods = chargingPeriods.sort(
          (a, b) => a.time - b.time,
        )
        setChargingPeriods(sortedChargingPeriods)
        onValueSelected(sortedChargingPeriods)
      }
    }

    const removeEntry = (value) => {
      const timeValue = new Date(value).getTime()
      let newChargingPeriods = chargingPeriods
      const entryIndex = newChargingPeriods.findIndex(
        (period) => period.time === timeValue,
      )
      if (entryIndex !== -1) {
        newChargingPeriods.splice(entryIndex, 1)
      }
      setChargingPeriods(newChargingPeriods)
      onValueSelected(newChargingPeriods)
    }

    useEffect(() => {
      setAvailableHours(
        allDates.filter(
          (dateObject) =>
            !selectedHours.some((x) => x?.value === dateObject?.value),
        ),
      )
    }, [selectedHours])
    const onSelect = (value, selectedValue) => {
      let newSelectedHours = [...selectedHours]
      if (selectedValue)
        newSelectedHours = newSelectedHours.filter(
          (d) => d.value !== selectedValue,
        )
      const valueObject = allDates.find((d) => d.value === value)
      if (!selectedHours.find((d) => d.value === value))
        setSelectedHours([...newSelectedHours, valueObject])
    }

    const onClean = (value) => {
      const valueObject = selectedHours.find((d) => d.value === value)
      if (valueObject)
        setSelectedHours([...selectedHours.filter((d) => d.value !== value)])
      removeEntry(value)
    }

    return (
      <div className={Styles.ListContainer}>
        <ChargingSchedulePeriodListItem
          ref={firstEntryRef}
          defaultValue={new Date().setUTCHours(0, 0, 0, 0)}
          readOnly={true}
          readOnlyValue="12:00 AM"
          selectedValues={selectedHours}
          data={availableHours}
          onClean={onClean}
          onSelect={onSelect}
          defaultMaxCurrentInput={defaultMaxCurrentInput}
          onChange={(time, current, id) => {
            addEntry({
              id,
              time,
              current: Number(current),
            })
          }}
        />
        {[...Array(scheduleListCount)].map((ref, index) => {
          return (
            <div ref={ref}>
              <ChargingSchedulePeriodListItem
                id={index}
                key={index}
                selectedValues={selectedHours}
                data={availableHours}
                onClean={onClean}
                onSelect={onSelect}
                readOnly={false}
                defaultMaxCurrentInput={defaultMaxCurrentInput}
                onChange={(time, current, id) => {
                  addEntry({
                    id,
                    time,
                    current: Number(current),
                  })
                }}
              />
            </div>
          )
        })}
        <div className={Styles.ButtonContainer}>
          <button
            className={Styles.AddButton}
            style={{ color: Colors.primary }}
            onClick={() => setScheduleListCount(scheduleListCount + 1)}
          >
            <StyledDiv
              className={Styles.IconContainer}
              style={{ color: Colors.primary }}
            >
              <AddIcon />
            </StyledDiv>
          </button>
        </div>
      </div>
    )
  },
)

export default ChargingSchedulePeriodList
