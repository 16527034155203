import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  loginLoading: false,
  resetPasswordRequestState: RequestState.UNINITIALIZED,
  resetPassVerificationRequestState: RequestState.UNINITIALIZED,
  resetPassEmailID: '',
  token: null,
  refreshToken: null,
  tenant: null,
  tenantUser: null,
  fetchTenantUserRequestState: RequestState.UNINITIALIZED,
  tenantUserLoading: false,
}
