import React, { useEffect, useReducer, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import StationSelectors from 'Stores/Station/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import Styles from './ChargePointLogList.module.css'
import ChargePointLogListItem from 'Components/ChargePointLogListItem/ChargePointLogListItem'
import ChargePointLogListItemSkeleton from 'Components/ChargePointLogListItem/ChargePointLogListItemSkeleton'
import { EmptyLogs, CustomScrollbar, IFText } from 'Components'
import { NoLogsFilter, IFsvg } from 'Components'
import { Virtuoso } from 'react-virtuoso'
import { Emitter } from '../../Services/EmitterService'
import { Colors } from 'Theme'
import { isPropsMatch } from 'Utils/PropsMatch'
import IFFilterType from 'Enums/IFFilterType'

const reducer = (state, action) => {
  if (state === false && action.isChargePointLogsLive === true) {
    action.handleLogsLive()
    return action.isChargePointLogsLive
  }
  return action.isChargePointLogsLive
}

const ChargePointLogList = ({
  fetchChargePointLogList,
  chargePointLogList,
  chargePointLogsFirstItemIndex,
  fetchChargePointLogListRequestState,
  fetchStationListRequestState,
  fetchChargePointListRequestState,
  paginationOffset,
  ChargePointLogFilter,
  selectedIndex,
  chargePoints,
  ascending = false,
  fetchChargePointRequestState,
  prependChargePointLogList,
  bufferedChargePointLogList,
  appendBufferChargePointLogList,
  isChargePointLogsLive,
  tenant,
  chargePointUid,
  setChargePointLogPaginationOffset,
  setIsChargePointLogsLive,
}) => {
  const [empty, setEmpty] = useState(true)
  const [didBufferLogs, setDidBufferLogs] = useState(false)
  const [prevChargePointUid, setPrevChargePointUid] = useState()
  const prependItemsQueue = useRef([])
  const prependItemsInterval = useRef(null)

  const shouldSkipScroll = useRef(false)
  const isAtTopRef = useRef(false)
  const virtuosoRef = useRef(null)
  const chargePointLogFilterRef = useRef([])

  const prevScrollTop = useRef(0)
  const prevScrollHeight = useRef(0)
  const autoScroll = useRef(false)
  const [, dispatch] = useReducer(reducer, null)
  const isChargePointLogsLiveChange = (data) => {
    dispatch({ ...data })
  }

  const Footer = () => {
    return paginationOffset &&
      chargePointLogList &&
      chargePointLogList.length !== 0 ? (
      <div className={Styles.WaypointContainer}>
        <ChargePointLogListItemSkeleton className={Styles.item} />
        <ChargePointLogListItemSkeleton className={Styles.item} />
      </div>
    ) : null
  }

  const loadMoreData = () => {
    if (
      fetchChargePointLogListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return

    fetchChargePointLogList(
      ChargePointLogFilter,
      paginationOffset,
      chargePoints[selectedIndex]._id,
      ascending,
    )
  }
  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }
  useEffect(() => {
    checkFilterEmpty(ChargePointLogFilter)
    chargePointLogFilterRef.current = ChargePointLogFilter
  }, [ChargePointLogFilter])

  useEffect(() => {
    if (bufferedChargePointLogList.length > 0) setDidBufferLogs(true)
  }, [bufferedChargePointLogList])

  const handleLogsLive = () => {
    setChargePointLogPaginationOffset(0)
    setTimeout(() => {
      fetchChargePointLogList(
        ChargePointLogFilter,
        0,
        chargePoints[selectedIndex]._id,
        false,
      )
    }, 200)
  }
  useEffect(() => {
    isChargePointLogsLiveChange({ isChargePointLogsLive, handleLogsLive })
  }, [isChargePointLogsLive])

  useEffect(() => {
    if (!prevChargePointUid && chargePointUid)
      setPrevChargePointUid(chargePointUid)
    if (chargePointUid && isChargePointLogsLive) {
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/chargePoint/${chargePointUid}/logs/`,
        tenant.channelKey,
        (log) => {
          if (chargePointUid === log.data.chargePointUid) {
            prependItemsQueue.current.push(log.data)
            if (!prependItemsInterval.current) {
              DequeueLogs()
            }
          }
        },
        {},
      )
    } else {
      Emitter.unsubscribeFromChannelGroup(
        `tenant/${tenant._id}/chargePoint/${chargePointUid}/logs/`,
        tenant.channelKey,
      )
    }
    if (
      prevChargePointUid &&
      chargePointUid &&
      prevChargePointUid !== chargePointUid
    ) {
      Emitter.unsubscribeFromChannelGroup(
        `tenant/${tenant._id}/chargePoint/${prevChargePointUid}/logs/`,
        tenant.channelKey,
      )
      setPrevChargePointUid(chargePointUid)
    }
  }, [chargePointUid, isChargePointLogsLive])

  useEffect(() => {
    if (ascending) {
      setIsChargePointLogsLive(false)
    }
  }, [ascending])

  const DequeueLogs = () => {
    prependItemsInterval.current = setInterval(() => {
      let tempLogs = []
      for (let i = 0; i < 2; i++) {
        if (prependItemsQueue.current[0]) {
          tempLogs.push(prependItemsQueue.current.shift())
        } else {
          break
        }
      }
      if (tempLogs.length > 0) {
        prependItems(tempLogs)
      } else {
        clearInterval(prependItemsInterval.current)
        prependItemsInterval.current = null
      }
    }, 400)
  }

  function scrollToTop() {
    setTimeout(() => {
      if (isAtTopRef.current) {
        virtuosoRef.current?.scrollToIndex({
          index: 0,
          behavior: 'smooth',
          align: 'start',
        })
      }
    }, 50)
  }

  const prependItems = (newItems) => {
    let filteredLogs = newItems.filter((log) => {
      let logMatchFilters = true
      for (const filter of chargePointLogFilterRef.current) {
        const value = filter.value
        if (filter.type === IFFilterType.KEYWORD) {
          const regex = new RegExp(filter.value.join('|'))
          if (!(regex.test(log.messageId) || regex.test(log.messageAction))) {
            logMatchFilters = false
          }
        } else if (filter.type === IFFilterType.CHECK) {
          if (value.length === 1) {
            const incoming = value[0] === 'Station'
            if (log.isIncoming !== incoming) {
              logMatchFilters = false
            }
          }
        } else if (filter.type === IFFilterType.DATE_RANGE) {
          if (value.length != 0) {
            let createdAt = new Date(log.createdAt)
            if (!(createdAt > value[0] && createdAt < value[1])) {
              logMatchFilters = false
            }
          }
        }
      }
      return logMatchFilters
    })
    if (isAtTopRef.current) {
      prependChargePointLogList(filteredLogs)
      scrollToTop()
    } else {
      appendBufferChargePointLogList(filteredLogs)
    }
  }

  return (
    <div
      className={Styles.container}
      {...((empty && chargePointLogList.length === 0) ||
      (!empty && chargePointLogList.length === 0)
        ? { style: { width: '100%', height: '100%' } }
        : {})}
    >
      <div
        className={`${Styles.newLogsIndicator} ${
          bufferedChargePointLogList.length > 0
            ? Styles.animateInNewLogsIndicator
            : didBufferLogs
            ? Styles.animateOutNewLogsIndicator
            : ''
        }`}
        onClick={() => {
          autoScroll.current = true
          prependChargePointLogList([...bufferedChargePointLogList])
          isAtTopRef.current = true
          scrollToTop()
        }}
      >
        <IFsvg.ArrowUp height={20} width={20} fill={Colors.black} />
        <IFText
          style={{ color: 'black', fontWeight: '500', marginLeft: '4px' }}
        >{`${bufferedChargePointLogList.length} new logs`}</IFText>
      </div>

      {(fetchChargePointLogListRequestState === RequestState.LOADING &&
        paginationOffset === 0) ||
      fetchStationListRequestState === RequestState.LOADING ||
      fetchChargePointListRequestState === RequestState.LOADING ||
      fetchChargePointRequestState === RequestState.LOADING ? (
        <div>
          {[...Array(15)].map((e, index) => (
            <ChargePointLogListItemSkeleton
              className={Styles.item}
              key={`ChargePointLogListItemSkeleton ${index}`}
            />
          ))}
        </div>
      ) : chargePointLogList.length === 0 ? (
        empty ? (
          <EmptyLogs />
        ) : (
          <NoLogsFilter />
        )
      ) : (
        <Virtuoso
          ref={virtuosoRef}
          data={chargePointLogList}
          endReached={
            paginationOffset &&
            chargePointLogList &&
            chargePointLogList.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, chargePointLog) => {
            return (
              <div key={index}>
                {chargePointLog.responseMessage ? (
                  <ChargePointLogListItem
                    key={`ChargePointLogListItemResponse ${index}`}
                    isIncoming={!chargePointLog.isIncoming}
                    action={chargePointLog.messageAction}
                    date={chargePointLog.updatedAt}
                    callId={chargePointLog.messageId}
                    message={
                      chargePointLog.responseMessage
                        ? chargePointLog.responseMessage.message
                        : undefined
                    }
                  />
                ) : null}
                {chargePointLog.requestMessage ? (
                  <ChargePointLogListItem
                    key={`ChargePointLogListItemRequest ${index}`}
                    isIncoming={chargePointLog.isIncoming}
                    action={chargePointLog.messageAction}
                    date={chargePointLog.createdAt}
                    callId={chargePointLog.messageId}
                    message={
                      chargePointLog.requestMessage
                        ? chargePointLog.requestMessage.message
                        : undefined
                    }
                  />
                ) : null}
              </div>
            )
          }}
          firstItemIndex={chargePointLogsFirstItemIndex}
          atTopStateChange={(isAtTop) => {
            if (isAtTop && bufferedChargePointLogList.length > 0) {
              prependChargePointLogList([...bufferedChargePointLogList])
              isAtTopRef.current = false
              return
            }
            if (isAtTop) isAtTopRef.current = isAtTop
          }}
          onScroll={(event) => {
            if (event.target.scrollTop === 0) {
              setTimeout(() => {
                if (event.target.scrollTop === 0) autoScroll.current = false
              }, 150)
            }
            if (prevScrollHeight.current === event.target.scrollHeight) {
              //prepend
              if (prevScrollTop.current < event.target.scrollTop) {
                //Scroll Down

                if (!autoScroll.current) isAtTopRef.current = false
              }
            } else {
              if (prevScrollTop.current > event.target.scrollTop) {
                prevScrollTop.current +=
                  event.target.scrollHeight - prevScrollHeight.current
                prevScrollHeight.current = event.target.scrollHeight
                return
              }
            }

            prevScrollTop.current = event.target.scrollTop
            prevScrollHeight.current = event.target.scrollHeight
          }}
          components={{ Footer: Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointLogList: (filter, offset, chargePointId, ascending) =>
      dispatch(
        ChargePointActions.fetchChargePointLogList(
          filter,
          offset,
          chargePointId,
          ascending,
        ),
      ),
    prependChargePointLogList: (chargePointLogs) =>
      dispatch(ChargePointActions.prependChargePointLogList(chargePointLogs)),
    appendBufferChargePointLogList: (chargePointLogs) =>
      dispatch(
        ChargePointActions.appendBufferChargePointLogList(chargePointLogs),
      ),
    setChargePointLogPaginationOffset: (offset) =>
      dispatch(ChargePointActions.setChargePointLogPaginationOffset(offset)),
    setIsChargePointLogsLive: (flag) =>
      dispatch(ChargePointActions.setIsChargePointLogsLive(flag)),
  }
}

const mapStateToProps = (state) => ({
  chargePointLogList: ChargePointSelectors.getChargePointLogs(state),
  chargePointLogsFirstItemIndex:
    ChargePointSelectors.getChargePointLogsFirstItemIndex(state),
  bufferedChargePointLogList:
    ChargePointSelectors.getBufferedChargePointLogs(state),
  fetchChargePointLogListRequestState:
    ChargePointSelectors.getFetchChargePointLogListRequestState(state),
  paginationOffset:
    ChargePointSelectors.getChargePointLogPaginationOffset(state),
  ChargePointLogFilter: ChargePointSelectors.getChargePointLogFilter(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  isChargePointLogsLive: ChargePointSelectors.getIsChargePointLogsLive(state),
  tenant: AuthSelectors.getTenant(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
})

ChargePointLogList.propTypes = {
  fetchChargePointLogList: PropTypes.func,
  chargePointLogList: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointLogListRequestState: PropTypes.number,
  paginationOffset: PropTypes.number,
  ChargePointLogFilter: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  chargePoints: PropTypes.arrayOf(PropTypes.object),
}
function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'fetchChargePointLogList',
    'chargePointLogList',
    'chargePointLogsFirstItemIndex',
    'fetchChargePointLogListRequestState',
    'fetchStationListRequestState',
    'fetchChargePointListRequestState',
    'paginationOffset',
    'ChargePointLogFilter',
    'selectedIndex',
    'chargePoints',
    'ascending',
    'fetchChargePointRequestState',
    'prependChargePointLogList',
    'bufferedChargePointLogList',
    'appendBufferChargePointLogList',
    'isChargePointLogsLive',
    'chargePointUid',
  ])
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(ChargePointLogList, shouldSkipRender))
