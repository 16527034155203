import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  CustomScrollbar,
  UserSessionItemSkeleton,
  NoUserSessions,
} from 'Components'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import UserActions from 'Stores/User/Actions'
import UserSelectors from 'Stores/User/Selectors'
import { Virtuoso } from 'react-virtuoso'
import Styles from './UserSessions.module.css'
import UserSessionItem from 'Components/UserSessionItem/UserSessionItem'
import { handleMaxPower } from 'Utils/ConnectorFunctions'
import AuthSelectors from 'Stores/Auth/Selectors'
import { Emitter } from 'Services/EmitterService'
TimeAgo.addDefaultLocale(en)

const UserSessions = ({
  userSessions = [],
  selectedUser,
  fetchUserSessionList,
  fetchUserSessionListRequestState,
  fetchUserListRequestState,
  fetchUserDetailsRequestState,
  tenant,
  emitterUpdateUserSessionStatus,
}) => {
  useEffect(() => {
    if (selectedUser && selectedUser?._id) {
      fetchUserSessionList(selectedUser._id)
    }
  }, [selectedUser?._id])
  useEffect(() => {
    if (selectedUser?._id && userSessions.length > 0) {
      userSessions.forEach((session) => {
        Emitter.subscribeToChannel(
          `tenant/${tenant._id}/chargePoint/${session.chargePoint.uid}/connector/${session.connectorUid}/sessionStatus/`,
          tenant.channelKey,
          emitterUpdateUserSessionStatus,
          {},
        )
        Emitter.subscribeToChannel(
          `tenant/${tenant._id}/chargePoint/${session.chargePoint.uid}/updateConnector/`,
          tenant.channelKey,
          (args) => {
            emitterUpdateUserSessionStatus(args.data)
          },
          {},
        )
      })
    }
  }, [selectedUser?._id, userSessions])

  const usersContainerRef = useRef()
  return (
    <div className={Styles.Container} ref={usersContainerRef}>
      {fetchUserSessionListRequestState === RequestState.LOADING ||
      fetchUserListRequestState === RequestState.LOADING ||
      fetchUserDetailsRequestState === RequestState.LOADING ? (
        <div>
          {[...Array(selectedUser?.email ? 3 : 1)].map((e, index) => (
            <div
              style={{
                marginRight: 16,
              }}
              key={`UserSessionListItemSkeleton ${index}`}
            >
              <UserSessionItemSkeleton key={index} />
            </div>
          ))}
        </div>
      ) : userSessions.length === 0 ? (
        <NoUserSessions />
      ) : (
        <div style={{ height: '100%' }}>
          {userSessions.length < 3 ? (
            userSessions.map((session, index) => {
              const connector = session.chargePoint.connectors.find(
                (connector) => connector.uid === session.connectorUid,
              )
              return (
                <div
                  className={Styles.ItemContainer}
                  key={`UserSessionListItem ${index}`}
                >
                  <UserSessionItem
                    connectorUid={session.connectorUid}
                    connectorType={connector?.type}
                    energyConsumption={
                      session.transaction.meterEnd
                        ? (
                            session.transaction.meterEnd -
                            session.transaction.meterStart
                          ).toFixed(2)
                        : 0
                    }
                    startedAt={session.startedAt}
                    stationName={session.chargePoint.station.name}
                    chargePointName={session.chargePoint.name}
                    reservationID={session.reservation?.uid}
                    chargingLevel={handleMaxPower(connector.maxPower)}
                    chargePointId={session.chargePoint._id}
                    stationId={session.chargePoint.station._id}
                    connectorStatus={connector.status}
                    isChargePointOffline={!session.chargePoint.isOnline}
                    startingChargingTokenType={session.chargingToken.type}
                  />
                </div>
              )
            })
          ) : (
            <Virtuoso
              data={userSessions}
              increaseViewportBy={480}
              itemContent={(index, session) => {
                const connector = session.chargePoint.connectors.find(
                  (connector) => connector.uid === session.connectorUid,
                )
                return (
                  <div
                    className={Styles.ItemContainer}
                    key={`UserSessionListItem ${index}`}
                  >
                    <UserSessionItem
                      connectorUid={session.connectorUid}
                      connectorType={connector?.type}
                      energyConsumption={
                        session.transaction.meterEnd
                          ? (
                              session.transaction.meterEnd -
                              session.transaction.meterStart
                            ).toFixed(2)
                          : 0
                      }
                      startedAt={session.startedAt}
                      stationName={session.chargePoint.station.name}
                      chargePointName={session.chargePoint.name}
                      reservationID={session.reservation?.uid}
                      chargingLevel={handleMaxPower(connector.maxPower)}
                      chargePointId={session.chargePoint._id}
                      stationId={session.chargePoint.station._id}
                      connectorStatus={connector.status}
                      isChargePointOffline={!session.chargePoint.isOnline}
                      startingChargingTokenType={session.chargingToken.type}
                    />
                  </div>
                )
              }}
              components={{ Scroller: CustomScrollbar }}
            />
          )}
        </div>
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSessionList: (userId) =>
      dispatch(UserActions.fetchUserSessionList(userId)),

    emitterUpdateUserSessionStatus: (data) =>
      dispatch(UserActions.emitterUpdateUserSessionStatus(data)),
  }
}

const mapStateToProps = (state) => ({
  fetchUserSessionListRequestState:
    UserSelectors.getFetchUserSessionListRequestState(state),
  userSessions: UserSelectors.getUserSessions(state),
  selectedUser: UserSelectors.getSelectedUser(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
  tenant: AuthSelectors.getTenant(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserSessions)
