import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Colors from 'Theme/Colors'
import React, { useState, useImperativeHandle } from 'react'
import { ChargePointDetailsTab } from 'Components'
import ChargePointTabsSkeleton from './ChargePointDetailsTabsSkeleton'

const tabTitles = {
  StationLog: 'Station Log',
  TransactionLog: 'Transaction Log',
  Statistics: 'Statistics',
}

const ChargePointDetailsTabs = React.forwardRef(
  ({ onValueChange, isLoading, initialIndex, canViewUptimeStats }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)
    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    return (
      <div>
        {isLoading ? (
          <ChargePointTabsSkeleton
            tabsCount={Object.keys(tabTitles).length - +!canViewUptimeStats}
          />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {Object.values(tabTitles).map((title, index) =>
              title === tabTitles.Statistics ? (
                canViewUptimeStats ? (
                  <Tab
                    sx={{ padding: '0px' }}
                    key={index}
                    label={
                      <ChargePointDetailsTab
                        title={title}
                        isSelected={selectedIndex === index}
                      />
                    }
                  />
                ) : null
              ) : (
                <Tab
                  sx={{ padding: '0px' }}
                  key={index}
                  label={
                    <ChargePointDetailsTab
                      title={title}
                      isSelected={selectedIndex === index}
                    />
                  }
                />
              ),
            )}
          </Tabs>
        )}
      </div>
    )
  },
)

ChargePointDetailsTabs.propTypes = {
  onValueChange: PropTypes.func,
}

export default ChargePointDetailsTabs
