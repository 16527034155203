import React, { useEffect, useRef, useState } from 'react'
import Styles from './TransactionsDownload.module.css'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { chargePointDataApiService, userApiService } from 'Services/ApiService'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { toaster } from 'rsuite'
import {
  IFToastMessage,
  IFText,
  IFsvg,
  IFTooltipIconsLoading,
} from 'Components'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { styled } from '@mui/material/styles'
import { Colors } from 'Theme'
import moment from 'moment'
import UserSelectors from 'Stores/User/Selectors'

var base64 = require('base-64')

const CustomList = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    '&:hover': {
      backgroundColor: Colors.ChargePointLogListItemHover,
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
})

const TransactionsDownload = ({
  chargePointTransactionFilter,
  chargePoint,
  selectedUser,
  userTransactionFilter,
}) => {
  const [getReport, setGetReport] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { t } = useTranslation()
  const downloadRef = useRef()

  const getTransactionsReport = (reportFileType) => {
    setGetReport(true)
    let filter
    if (chargePoint) {
      if (chargePointTransactionFilter !== null) {
        filter = base64.encode(JSON.stringify(chargePointTransactionFilter))
      }
      chargePointDataApiService({
        url: `/${chargePoint._id}/transactions/report`,
        method: 'POST',
        data: {
          filter: filter,
          fileType: reportFileType,
        },
        responseType: 'blob', // important
      })
        .then((res) => {
          setGetReport(false)
          if (res.headers['content-type'] === 'application/pdf') {
            const pdfBlob = new Blob([res.data], {
              type: 'application/pdf',
            })
            saveAs(
              pdfBlob,
              `Transactions_${chargePoint.uid}_${moment().toISOString()}.pdf`,
            )
          } else {
            const excelBlob = new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            saveAs(
              excelBlob,
              `Transactions_${chargePoint.uid}_${moment().toISOString()}.xlsx`,
            )
          }
        })
        .catch((err) => {
          setGetReport(false)
          toaster.push(
            <IFToastMessage
              type="error"
              text={err.message ? err.message : err.statusText}
            />,
            { placement: 'topEnd' },
          )
        })
    } else if (selectedUser) {
      if (userTransactionFilter !== null) {
        filter = base64.encode(JSON.stringify(userTransactionFilter))
      }
      userApiService({
        url: `/${selectedUser._id}/transactions/report`,
        method: 'POST',
        data: {
          filter: filter,
          fileType: reportFileType,
        },
        responseType: 'blob', // important
      })
        .then((res) => {
          setGetReport(false)
          if (res.headers['content-type'] === 'application/pdf') {
            const pdfBlob = new Blob([res.data], {
              type: 'application/pdf',
            })
            saveAs(
              pdfBlob,
              `Transactions_${selectedUser.name}_${moment().toISOString()}.pdf`,
            )
          } else {
            const excelBlob = new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            saveAs(
              excelBlob,
              `Transactions_${
                selectedUser.name
              }_${moment().toISOString()}.xlsx`,
            )
          }
        })
        .catch((err) => {
          setGetReport(false)
          toaster.push(
            <IFToastMessage
              type="error"
              text={err.message ? err.message : err.statusText}
            />,
            { placement: 'topEnd' },
          )
        })
    }
  }

  const handleClick = (event) => {
    setAnchorEl(downloadRef?.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)
  const popoverID = popoverOpen ? 'simple-popover' : undefined

  const handleSubmitRef = useRef(handleClick)

  useEffect(() => {
    handleSubmitRef.current = handleClick
  })

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.downloadIcon}>
        <div ref={downloadRef}>
          <IFTooltipIconsLoading
            onClick={(e) => {
              handleSubmitRef.current(e)
            }}
            tooltipPlacement={'left'}
            title={t('IFFilter.Download')}
            Icon={IFsvg.CloudDownload}
            FilledIcon={IFsvg.CloudDownloadFill}
            isLoading={getReport}
            size={26}
            iconClassname={Styles.iconContainer}
            animationDisabled={false}
          />
        </div>
        <Popover
          id={popoverID}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
              <CustomList sx={{ borderRadius: '5px' }}>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    onClick={() => {
                      getTransactionsReport('pdf')
                      handleClose()
                    }}
                  >
                    <ListItemIcon>
                      <PictureAsPdfIcon />
                    </ListItemIcon>
                    <IFText>{t('TransactionsReport.PDF')}</IFText>
                  </ListItemButton>
                </ListItem>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    onClick={() => {
                      getTransactionsReport('excel')
                      handleClose()
                    }}
                  >
                    <ListItemIcon>
                      <DocumentScannerIcon />
                    </ListItemIcon>
                    <IFText>{t('TransactionsReport.Excel')}</IFText>
                  </ListItemButton>
                </ListItem>
              </CustomList>
            </nav>
          </Box>
        </Popover>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  chargePointTransactionFilter:
    ChargePointSelectors.getChargePointTransactionFilter(state),
  selectedUser: UserSelectors.getSelectedUser(state),
  userTransactionFilter: UserSelectors.getUserTransactionsFilter(state),
})

TransactionsDownload.propTypes = {
  chargePoint: PropTypes.object,
  chargePointTransactionFilter: PropTypes.arrayOf(PropTypes.object),
}

export default connect(mapStateToProps)(TransactionsDownload)
