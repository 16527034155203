import InfinityEnums from './InfinityEnums'

const OCPPEnums = {
  OCPPMessages: [
    {
      label: 'Trigger Message',
      value: InfinityEnums.OCPPActions.TRIGGER_MESSAGE,
      description: {
        requestedMessage:
          'Required, [BootNotification, DiagnosticsStatusNotification, FirmwareStatusNotification, Heartbeat, MeterValues, StatusNotification]',
        connectorId: 'Optional, Integer',
      },
    },
    {
      label: 'Send Local List',
      value: InfinityEnums.OCPPActions.SEND_LOCAL_LIST,
      description: {
        listVersion: 'Required, Integer',
        localAuthorizationList: {
          idTag: 'Required, String',
          idTagInfo: {
            expiryDate: 'Optional, Date',
            parentIdTag: 'Optional, String',
            status:
              'Required, [Accepted, Blocked, Expired, Invalid, ConcurrentTx]',
          },
        },
        updateType: 'Required, [Differential, Full]',
      },
    },
    {
      label: 'Get Local List Version',
      value: InfinityEnums.OCPPActions.GET_LOCAL_LIST_VERSION,
      description: {
        listVersion: 'Required, Integer',
      },
    },
    {
      label: 'Get Composite Schedule',
      value: InfinityEnums.OCPPActions.GET_COMPOSITE_SCHEDULE,
      description: {
        connectorId: 'Required, Integer',
        duration: 'Required, Integer',
        chargingRateUnit: 'Optional, [W,A]',
      },
    },
    {
      label: 'Data Transfer',
      value: InfinityEnums.OCPPActions.DATA_TRANSFER,
      description: {
        vendorId: 'Required, String',
        messageId: 'Optional, String',
        data: 'Optional, String',
      },
    },
  ],
  LocalListUpdateType: [
    {
      label: 'Differential',
      value: 'Differential',
    },
    {
      label: 'Full',
      value: 'Full',
    },
  ],
}
export default OCPPEnums
