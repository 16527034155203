import React, { useRef } from 'react'
import { connect } from 'react-redux'
import StationSelectors from 'Stores/Station/Selectors'
import {
  IFText,
  StationListItem,
  StationListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import { Images, Colors } from 'Theme'
import Styles from './StationMoveList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import ReactTimeAgo from 'react-time-ago'
import { Divider } from '@material-ui/core'
import { Waypoint } from 'react-waypoint'
import StationActions from 'Stores/Station/Actions'
import { Virtuoso } from 'react-virtuoso'

const StationMoveList = ({
  moveStationList,
  fetchStationMoveList,
  stationsFilter,
  formattedMoveStationList,
  fetchStationListRequestState,
  paginationOffset,
  isPicking,
  returnIndex,
}) => {
  const { t } = useTranslation()

  const Footer = () => {
    return paginationOffset &&
      moveStationList &&
      moveStationList.length !== 0 ? (
      <div className={Styles.WaypointContainer}>
        <Waypoint onEnter={loadMoreData} />
        <StationListItemSkeleton />
        <Divider />
        <StationListItemSkeleton />
      </div>
    ) : null
  }

  const loadMoreData = () => {
    if (fetchStationListRequestState === RequestState.LOADING_MORE) return
    fetchStationMoveList(stationsFilter, paginationOffset)
  }

  const [selectedIndex, setSelectedIndex] = React.useState(-1)

  const stationsMoveListContainerRef = useRef()
  const getStationsMoveListSkeletonsCount = () => {
    if (stationsMoveListContainerRef.current) {
      let containerHeight =
        stationsMoveListContainerRef.current.parentElement.clientHeight / 16
      return Math.floor(containerHeight / 5)
    }
    return 7
  }

  return (
    <div className={Styles.wrapper} ref={stationsMoveListContainerRef}>
      {fetchStationListRequestState === RequestState.LOADING &&
      paginationOffset === 0 ? (
        [...Array(getStationsMoveListSkeletonsCount())].map((e, index) => (
          <div key={`stationListItemMoveSkeleton ${index}`}>
            <StationListItemSkeleton key={index} />
            <Divider />
          </div>
        ))
      ) : formattedMoveStationList.length === 0 ? (
        <div className={Styles.EmptySet}>
          <img className={Styles.NoSitesIcon} src={Images.noSites} alt="" />
          <IFText className={Styles.EmptyText} style={{ color: Colors.black }}>
            {t('StationList.Empty')}
          </IFText>
        </div>
      ) : (
        <Virtuoso
          data={formattedMoveStationList}
          endReached={
            paginationOffset && moveStationList && moveStationList.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, station) => {
            return (
              <div key={`stationListItemMove ${index}`}>
                <StationListItem
                  key={index}
                  name={station.name}
                  isSelected={selectedIndex === index}
                  lastUpdated={
                    <ReactTimeAgo
                      date={new Date(station.lastUpdated)}
                      locale="en-US"
                      timeStyle="mini"
                    />
                  }
                  onClick={() => {
                    setSelectedIndex(index)
                    returnIndex(index)
                  }}
                  isPicking={isPicking}
                  connectors={station.connectors}
                  chargePoints={station.chargePoints}
                />
                <Divider />
              </div>
            )
          }}
          components={{ Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStationMoveList: (filter, offset) =>
      dispatch(StationActions.fetchStationMoveList(filter, offset)),
  }
}

const mapStateToProps = (state) => ({
  formattedMoveStationList:
    StationSelectors.getFormattedMoveStationsList(state),
  fetchStationListRequestState:
    StationSelectors.getFetchMoveStationListRequestState(state),
  selectedIndex: StationSelectors.getSelectedMoveIndex(state),
  paginationOffset: StationSelectors.getPaginationOffsetMove(state),
  stationsFilter: StationSelectors.getMoveStationsFilter(state),
  moveStationList: StationSelectors.getMoveStationsList(state),
})

StationMoveList.propTypes = {
  fetchStationMoveList: PropTypes.func,
  stationsFilter: PropTypes.arrayOf(PropTypes.object),
  formattedMoveStationList: PropTypes.arrayOf(PropTypes.object),
  fetchStationListRequestState: PropTypes.number,
  selectedIndex: PropTypes.number,
  paginationOffset: PropTypes.number,
  isPicking: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationMoveList)
