import React, { forwardRef, Fragment } from 'react'
import Styles from './SubscriptionContainer.module.css'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Colors, Icons } from 'Theme'
import { IFSkeleton, IFText } from 'Components'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import ChargePointSubscription from './ChargePointSubscription/ChargePointSubscription'
import UserSubscription from './UserSubscription/UserSubscription'
import SubscriptionModalContainer from './SubscriptionModalContainer/SubscriptionModalContainer'
import ChargingTokensSubscriptionContainer from './ChargingTokensSubscriptionContainer/ChargingTokensSubscriptionContainer'
import UserSelectors from 'Stores/User/Selectors'
import CardSelectors from 'Stores/Card/Selectors'

const SubscriptionContainer = forwardRef(
  (
    {
      isEditing,
      renderFor,
      maxListHeight,
      scrollbarsHeight,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointRequestState,
      fetchChargePointSubscriptionsRequestState,
      onModalSubmit,
      style,
      fetchUserListRequestState,
      fetchUserDetailsRequestState,
      removeExtraMargin = false,
      fetchCardListRequestState,
      fetchCardRequestState,
      selectedUser,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const isLoading = () => {
      return renderFor === InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT
        ? fetchStationListRequestState === RequestState.LOADING ||
            fetchChargePointListRequestState === RequestState.LOADING ||
            fetchChargePointRequestState === RequestState.LOADING ||
            fetchChargePointSubscriptionsRequestState === RequestState.LOADING
        : renderFor === InfinityEnums.ChargePointSpecsContainer.USER
        ? fetchUserListRequestState === RequestState.LOADING ||
          fetchUserDetailsRequestState === RequestState.LOADING ||
          selectedUser?.isInvitedUser
        : renderFor === InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS
        ? fetchCardListRequestState === RequestState.LOADING ||
          fetchCardRequestState === RequestState.LOADING
        : false
    }

    return (
      <div
        className={Styles.SubscriptionsContainer}
        {...(style ? { style: style } : {})}
      >
        <div className={Styles.TitleIconsContainer}>
          <div className={Styles.TitleContainer}>
            {isLoading() ? (
              <IFSkeleton variant="text" width="5rem" height="1.5rem" />
            ) : (
              <IFText
                style={{ color: Colors.text }}
                className={Styles.PriceTitle}
              >
                {t('SubscriptionList.Price')}
              </IFText>
            )}
          </div>
          <div className={Styles.IconsContainer}>
            <div className={Styles.ChargingIconsContainer}>
              {isLoading() ? (
                <IFSkeleton variant="text" width="1rem" height="1rem" />
              ) : (
                <div className={Styles.FlashIcon}>{Icons.flash}</div>
              )}
            </div>
            <div className={Styles.ChargingIconsContainer}>
              {isLoading() ? (
                <IFSkeleton variant="text" width="1.5rem" height="1rem" />
              ) : (
                <Fragment>
                  <div className={Styles.FlashIcon}>{Icons.flash}</div>
                  <div className={Styles.FlashIcon}>{Icons.flash}</div>
                </Fragment>
              )}
            </div>
            <div className={Styles.ChargingIconsContainer}>
              {isLoading() ? (
                <IFSkeleton variant="text" width="2rem" height="1rem" />
              ) : (
                <Fragment>
                  <div className={Styles.FlashIcon}>{Icons.flash}</div>
                  <div className={Styles.FlashIcon}>{Icons.flash}</div>
                  <div className={Styles.FlashIcon}>{Icons.flash}</div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        {renderFor === InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT ? (
          <ChargePointSubscription
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            removeExtraMargin={removeExtraMargin}
          />
        ) : renderFor === InfinityEnums.ChargePointSpecsContainer.USER ? (
          <UserSubscription
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
            removeExtraMargin={removeExtraMargin}
          />
        ) : renderFor === InfinityEnums.ChargePointSpecsContainer.MODAL ? (
          <SubscriptionModalContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
            onModalSubmit={onModalSubmit}
            removeExtraMargin={removeExtraMargin}
          />
        ) : renderFor ===
          InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS ? (
          <ChargingTokensSubscriptionContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
            removeExtraMargin={removeExtraMargin}
          />
        ) : null}
      </div>
    )
  },
)

const mapStateToProps = (state) => ({
  fetchChargePointSubscriptionsRequestState:
    ChargePointSelectors.getFetchChargePointSubscriptionsRequestState(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
  fetchCardRequestState: CardSelectors.getFetchCardRequestState(state),
  fetchCardListRequestState: CardSelectors.getFetchCardListRequestState(state),
  selectedUser: UserSelectors.getSelectedUser(state),
})

SubscriptionContainer.propTypes = {
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  fetchChargePointRequestState: PropTypes.number,
  isEditing: PropTypes.bool,
  renderFor: PropTypes.number,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
  subscriptionsListRef: PropTypes.object,
  fetchChargePointSubscriptionsRequestState: PropTypes.number,
  onModalSubmit: PropTypes.func,
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(SubscriptionContainer)
