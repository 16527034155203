import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { StationTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import history from 'history/browser'
import IFFilterType from 'Enums/IFFilterType'
import { moveStationsFilter } from 'Constants/Filters'

export const fetchStationListLoading = (state) => {
  return {
    ...state,
    fetchStationListRequestState: RequestState.LOADING,
  }
}

export const fetchStationListLoadingMore = (state) => {
  return {
    ...state,
    fetchStationListRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchStationListSuccess = (
  state,
  { offset, stations, nextOffset, newStation, index, onNotFound },
) => {
  let result = []
  for (let j = 0; j < stations.length; j++) {
    if (!state.stations.some((station) => station._id === stations[j]._id)) {
      result.push(stations[j])
    }
  }

  const onSitesPage =
    window.location.pathname.toLocaleLowerCase().indexOf('sites') ||
    history.location.pathname.indexOf('sites')
  if (onSitesPage !== -1) {
    if (offset === 0) {
      if (stations && stations.length !== 0) {
        let currentPath = history.location.pathname
        const stationIndex = currentPath.indexOf('station')
        let chargePoint = null
        const slashArray = history.location.pathname.split('/')
        const chargePointId = slashArray[slashArray.length - 1]

        if (newStation !== null) {
          if (stationIndex !== -1) {
            chargePoint = newStation.chargePoints.find(
              (chargePoint) => chargePoint._id === chargePointId,
            )
            if (!chargePoint) {
              onNotFound()
              return { ...state }
            }
            history.push({
              pathname: `/sites/${newStation['station']['_id']}/stations/${chargePoint._id}`,
            })
          } else
            history.push({ pathname: `/sites/${newStation['station']['_id']}` })
        } else {
          let station = stations[index]

          if (stationIndex !== -1) {
            chargePoint = station['chargePoints'].find(
              (chargePoint) => chargePoint._id === chargePointId,
            )
            if (!chargePoint) {
              onNotFound()
              return { ...state }
            }
            history.push({
              pathname: `/sites/${station._id}/stations/${chargePoint._id}`,
            })
          } else history.push({ pathname: `/sites/${station._id}` })
        }
      }
      if (newStation !== null) {
        const formattedStation = {
          ...newStation.station,
          chargePoints: newStation.chargePoints,
        }
        return {
          ...state,
          stations: [...stations, formattedStation],
          paginationOffset: nextOffset,
          fetchStationListRequestState: RequestState.SUCCEEDED,
          selectedIndex: index + 1,
        }
      } else
        return {
          ...state,
          stations: [...stations],
          paginationOffset: nextOffset,
          fetchStationListRequestState: RequestState.SUCCEEDED,
          selectedIndex: index,
        }
    } else {
      let selectedIndex = 0
      const stationsList = state.stations
      if (stationsList.length !== 0) {
        const stateSelectedIndex = state.selectedIndex
        const selectedStation = stationsList[stateSelectedIndex]
        let stationOutOfPlace = false

        if (stations[0] && selectedStation.updatedAt < stations[0]['updatedAt'])
          stationOutOfPlace = true

        if (stationOutOfPlace) {
          const outOfPlaceStationIndex = stations.findIndex(
            (station) => station._id === selectedStation._id,
          )
          if (outOfPlaceStationIndex !== -1)
            selectedIndex = stationsList.length + outOfPlaceStationIndex - 1
          else selectedIndex = stationsList.length + stations.length - 1
        } else selectedIndex = state.selectedIndex
      }
      let combinedStations = [...state.stations, ...result]
      combinedStations.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
      return {
        ...state,
        stations: combinedStations,
        paginationOffset: nextOffset,
        fetchStationListRequestState: RequestState.SUCCEEDED,
        selectedIndex: selectedIndex,
      }
    }
  } else {
    return {
      ...state,
      fetchStationListRequestState: RequestState.UNINITIALIZED,
    }
  }
}

export const fetchStationListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationListRequestState: requestState,
  }
}

export const fetchStationMoveListLoading = (state) => {
  return {
    ...state,
    fetchMoveStationListRequestState: RequestState.LOADING,
  }
}

export const fetchStationMoveListLoadingMore = (state) => {
  return {
    ...state,
    fetchMoveStationListRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchStationMoveListSuccess = (
  state,
  { offset, stations, nextOffset },
) => {
  let result = []
  const storeStations = state.stations
  const selectedIndex = state.selectedIndex
  const selectedStation = storeStations[selectedIndex]
  const newMoveStations = stations.filter(
    (station) => station._id !== selectedStation._id,
  )
  for (let j = 0; j < newMoveStations.length; j++) {
    if (
      !state.moveStations.some(
        (station) => station._id === newMoveStations[j]._id,
      )
    ) {
      result.push(newMoveStations[j])
    }
  }

  if (offset === 0) {
    return {
      ...state,
      moveStations: [...newMoveStations],
      paginationOffsetMove: nextOffset,
      fetchMoveStationListRequestState: RequestState.SUCCEEDED,
      selectedMoveIndex: stations.length !== 0 ? 0 : null,
    }
  } else {
    return {
      ...state,
      moveStations: [...state.moveStations, ...result],
      paginationOffsetMove: nextOffset,
      fetchMoveStationListRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchStationMoveListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationListRequestState: requestState,
  }
}

export const clearMoveStations = (state) => {
  return {
    ...state,
    moveStations: [],
  }
}

export const clearStations = (state) => {
  return {
    ...state,
    stations: [],
    moveStations: [],
    selectedIndex: -1,
    paginationOffset: 0,
  }
}

export const setStationListSelectedIndex = (state, { index }) => {
  return {
    ...state,
    selectedIndex: index,
  }
}

export const setMoveStationSelectedIndex = (state, { index }) => {
  return {
    ...state,
    selectedMoveIndex: index,
  }
}

export const setStationsFilter = (state, { filter }) => {
  return {
    ...state,
    stationsFilter: filter,
  }
}

export const setMoveStationsFilter = (state, { filter }) => {
  return {
    ...state,
    moveStationsFilter: filter,
  }
}

export const updateStationLoading = (state) => {
  return {
    ...state,
    updateStationRequestState: RequestState.LOADING,
  }
}

export const updateStationSuccess = (state, { index, updatedStation }) => {
  let newStations = [...state.stations]
  newStations[index].name = updatedStation.name
    ? updatedStation.name
    : newStations[index].name
  newStations[index].location = updatedStation.location
    ? updatedStation.location
    : newStations[index].location
  return {
    ...state,
    stations: newStations,
    updateStationRequestState: RequestState.SUCCEEDED,
  }
}

export const updateStationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateStationRequestState: requestState,
  }
}

export const addStationLoading = (state) => {
  return {
    ...state,
    addStationRequestState: RequestState.LOADING,
  }
}

export const addStationSuccess = (state, { station }) => {
  if (
    !state.stations.some(
      (selectedStation) => selectedStation._id === station._id,
    )
  ) {
    station.isNew = true
    return {
      ...state,
      stations: [station, ...state.stations],
      stationsCount: state.stationsCount + 1,
      selectedIndex: 0,
      addStationRequestState: RequestState.SUCCEEDED,
    }
  } else
    return {
      ...state,
      addStationRequestState: RequestState.SUCCEEDED,
      selectedIndex: 0,
    }
}

export const addStationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addStationRequestState: requestState,
  }
}

export const setStationDetailsSelectedIndex = (state, { index }) => {
  return {
    ...state,
    stationDetailsSelectedIndex: index,
  }
}

export const deleteStationChargePoint = (state, { chargePointUid }) => {
  if (
    state.stations[state.selectedIndex]['chargePoints'].some(
      (chargePoint) => chargePoint.uid === chargePointUid,
    )
  ) {
    let index = state.selectedIndex
    let newStation = state.stations[index]

    newStation = newStation.chargePoints.filter(
      (chargePoint) => chargePoint.uid !== chargePointUid,
    )
    let newStations = state.stations
    newStations[index]['chargePoints'] = newStation
    return {
      ...state,
      stations: newStations,
    }
  } else return { ...state }
}

export const addMovedStation = (state, { chargePoint }) => {
  if (
    !state.stations[state.selectedMoveIndex]['chargePoints'].some(
      (innerChargePoint) => innerChargePoint._id === chargePoint._id,
    )
  ) {
    const index = state.selectedMoveIndex
    let newStations = []
    let selectedMoveStationId = state.moveStations[index]._id
    let selectedStationIndex = state.stations.findIndex(
      (station) => station._id === selectedMoveStationId,
    )
    if (selectedStationIndex !== -1) {
      newStations = state.stations
      newStations[selectedStationIndex]['chargePoints'].push(chargePoint)
      const newChargePoints = newStations[selectedStationIndex][
        'chargePoints'
      ].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
      newStations[selectedStationIndex]['chargePoints'] = newChargePoints
    } else newStations = state.stations

    return {
      ...state,
      stations: newStations,
    }
  } else return { ...state }
}

export const setPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    paginationOffset: offset,
  }
}

export const setMoveStationPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    paginationOffsetMove: offset,
  }
}

export const editChargePoints = (state, { selectedIndex, chargePoint }) => {
  let newStations = state.stations
  if (chargePoint.name)
    newStations[state.selectedIndex]['chargePoints'][selectedIndex]['name'] =
      chargePoint.name
  if (chargePoint.connectors)
    newStations[state.selectedIndex]['chargePoints'][selectedIndex][
      'connectors'
    ] = chargePoint.connectors
  return {
    ...state,
    stations: newStations,
  }
}

export const addStationChargePoint = (state, { chargePoint }) => {
  let currentPath = history.location.pathname
  const index = currentPath.indexOf('station')
  if (index === -1) {
    currentPath = currentPath + `/stations/${chargePoint._id}`
    history.push({ pathname: currentPath })
  }
  if (!state.stations[state.selectedIndex]['chargePoints']) {
    state.stations[state.selectedIndex].chargePoints = []
  }
  if (
    state.stations[state.selectedIndex]['chargePoints'].some(
      (innerChargePoint) => innerChargePoint._id === chargePoint._id,
    )
  )
    return { ...state }
  else {
    let stations = state.stations
    stations[state.selectedIndex]['chargePoints'].push(chargePoint)
    return {
      ...state,
      stations: stations,
    }
  }
}

export const resetMoveStationsFilter = (state) => {
  return {
    ...state,
    moveStationsFilter: moveStationsFilter,
  }
}

export const activateChargePoint = (state, { index }) => {
  let newStations = state.stations
  newStations[state.selectedIndex]['chargePoints'][index]['isActivated'] = true
  return {
    ...state,
    stations: [...newStations],
  }
}

export const emitterUpdateChargePointStation = (
  state,
  { args: { index, data } },
) => {
  const { uid, chargePoint } = data
  let newStations = [...state.stations]

  let indexCP = newStations[index]['chargePoints'].findIndex(
    (chargePoint) => chargePoint.uid === uid,
  )

  if (indexCP !== -1) {
    if (chargePoint.connectors)
      newStations[index]['chargePoints'][indexCP]['connectors'] =
        chargePoint.connectors
    if (chargePoint.isOnline === true || chargePoint.isOnline === false)
      newStations[index]['chargePoints'][indexCP]['isOnline'] =
        chargePoint.isOnline
  } else return { ...state }

  return {
    ...state,
    stations: newStations,
  }
}

export const emitterAddChargePointStation = (
  state,
  { args: { index, data } },
) => {
  if (!state.stations[index]['chargePoints']) {
    state.stations[index].chargePoints = []
  }

  if (
    state.stations[index]['chargePoints'].some(
      (chargePoint) => chargePoint._id === data._id,
    )
  )
    return { ...state }
  else {
    let stations = state.stations
    stations[index]['chargePoints'].push(data)
    return {
      ...state,
      stations: stations,
    }
  }
}

export const emitterDeleteChargePointStation = (
  state,
  { args: { index, data } },
) => {
  if (
    state.stations[index]['chargePoints'].some(
      (chargePoint) => chargePoint.uid === data,
    )
  ) {
    let newStation = state.stations[index]

    newStation = newStation.chargePoints.filter(
      (chargePoint) => chargePoint.uid !== data,
    )
    let newStations = state.stations
    newStations[index]['chargePoints'] = newStation

    return {
      ...state,
      stations: newStations,
    }
  } else return { ...state }
}

export const emitterMoveChargePointStation = (state, { args: { data } }) => {
  let newStations = state.stations

  const deleteIndex = state.stations.findIndex(
    (station) => station._id === data.oldStation,
  )

  if (
    deleteIndex !== -1 &&
    state.stations[deleteIndex]['chargePoints'].length > 0 &&
    state.stations[deleteIndex]['chargePoints'].some(
      (chargePoint) => chargePoint.uid === data.chargePoint.uid,
    )
  ) {
    let newStation = state.stations[deleteIndex]

    newStation = newStation.chargePoints.filter(
      (chargePoint) => chargePoint.uid !== data.chargePoint.uid,
    )
    newStations[deleteIndex]['chargePoints'] = newStation
  }
  const newIndex = state.stations.findIndex(
    (station) => station._id === data.newStation,
  )
  if (
    newIndex !== -1 &&
    !state.stations[newIndex]['chargePoints'].some(
      (chargePoint) => chargePoint.uid === data.chargePoint.uid,
    )
  ) {
    newStations[newIndex]['chargePoints'].push(data.chargePoint)
    const newChargePoints = newStations[newIndex]['chargePoints'].sort((a, b) =>
      a.createdAt > b.createdAt ? 1 : -1,
    )
    newStations[newIndex]['chargePoints'] = newChargePoints
  }
  return {
    ...state,
    stations: newStations,
  }
}

export const emitterAddStation = (state, { args: { data } }) => {
  data.isNew = true
  let isFiltersSelected = false
  for (const filter of state.stationsFilter) {
    if (filter.value.length > 0) {
      isFiltersSelected = true
      break
    }
  }
  if (
    !state.stations.some(
      (selectedStation) => selectedStation._id === data._id,
    ) &&
    !isFiltersSelected
  ) {
    const newStation = {
      ...data,
      chargePoints: [],
    }
    return {
      ...state,
      stationsCount: state.stationsCount + 1,
      stations: [newStation, ...state.stations],
      selectedIndex: state.selectedIndex + 1,
    }
  } else {
    return { ...state }
  }
}

export const emitterUpdateStation = (state, { args: { index, data } }) => {
  if (state.stations[index]) {
    let newStations = state.stations
    newStations[index].name = data.name ? data.name : newStations[index].name
    newStations[index].location = data.location
      ? data.location
      : newStations[index].location
    return {
      ...state,
      stations: [...newStations],
    }
  } else return { ...state }
}

export const emitterDeleteStation = (state, { args: { data } }) => {
  if (state.stations.some((selectedStation) => selectedStation._id === data)) {
    const newStations = state.stations.filter((station) => station._id !== data)
    let newIndex = -1
    const deleteIndex = state.stations.findIndex(
      (station) => station._id === data,
    )
    if (deleteIndex !== -1) {
      if (deleteIndex === state.selectedIndex) newIndex = 0
      else if (deleteIndex < state.selectedIndex)
        newIndex = state.selectedIndex - 1
      else newIndex = state.selectedIndex
      return {
        ...state,
        stations: newStations,
        selectedIndex: newIndex,
        stationsCount: state.stationsCount - 1,
        deleteStationEmitterState: RequestState.SUCCEEDED,
        deleteStationRequestState: RequestState.SUCCEEDED,
      }
    } else
      return { ...state, deleteStationEmitterState: RequestState.SUCCEEDED }
  } else return { ...state, deleteStationEmitterState: RequestState.SUCCEEDED }
}

export const deleteStationLoading = (state) => {
  return {
    ...state,
    deleteStationRequestState: RequestState.LOADING,
  }
}

export const deleteStationSuccess = (state, { stationId }) => {
  if (
    state.stations.some((selectedStation) => selectedStation._id === stationId)
  ) {
    const newStations = state.stations.filter(
      (station) => station._id !== stationId,
    )
    const deleteIndex = state.stations.findIndex(
      (station) => station._id === stationId,
    )
    let index = state.selectedIndex
    if (deleteIndex === state.selectedIndex) index = 0
    else if (deleteIndex < state.selectedIndex) index = state.selectedIndex - 1
    return {
      ...state,
      deleteStationRequestState: RequestState.SUCCEEDED,
      deleteStationEmitterState: RequestState.SUCCEEDED,
      stationsCount: state.stationsCount - 1,
      selectedIndex: index,
      stations: [...newStations],
    }
  } else return { ...state, deleteStationRequestState: RequestState.SUCCEEDED }
}

export const deleteStationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteStationRequestState: requestState,
  }
}

export const addStationDetails = (state, { station }) => {
  const newStation = { ...station.station, chargePoints: station.chargePoints }
  return { ...state, stations: [...state.stations, newStation] }
}

export const resetDeleteStationEmitterState = (state) => {
  return { ...state, deleteStationEmitterState: RequestState.UNINITIALIZED }
}

export const fetchStationCitiesLoading = (state) => {
  return { ...state, fetchStationCitiesRequestState: RequestState.LOADING }
}

export const fetchStationCitiesSuccess = (state, { cities }) => {
  const citiesNames = cities.map((city) => {
    return city.name
  })

  let stationsFilterNew = state.stationsFilter.map((filter) => {
    if (filter.title === 'City' && filter.type === IFFilterType.CHECK) {
      return { ...filter, data: citiesNames }
    } else {
      return filter
    }
  })

  let stationsMapFilterNew = state.stationsMapFilter.map((filter) => {
    if (filter.title === 'City' && filter.type === IFFilterType.CHECK) {
      return { ...filter, data: citiesNames }
    } else {
      return filter
    }
  })

  return {
    ...state,
    fetchStationCitiesRequestState: RequestState.SUCCEEDED,
    stationsFilter: stationsFilterNew,
    stationsMapFilter: stationsMapFilterNew,
  }
}

export const fetchStationCitiesFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationCitiesRequestState: requestState,
  }
}

export const addStationCityLoading = (state) => {
  return { ...state, addStationCityRequestState: RequestState.LOADING }
}

export const addStationCitySuccess = (state, { city }) => {
  let stationsFilterNew = state.stationsFilter.map((filter) => {
    if (filter.title === 'City' && filter.type === IFFilterType.CHECK) {
      let newData = filter.data
      newData.push(city)
      return { ...filter, data: newData }
    } else {
      return filter
    }
  })

  return {
    ...state,
    addStationCityRequestState: RequestState.SUCCEEDED,
    stationsFilter: stationsFilterNew,
  }
}

export const addStationCityFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addStationCityRequestState: requestState,
  }
}

export const fetchStationMapClustersSuccess = (state, { data }) => {
  return {
    ...state,
    stationMapClusters: data.clusteredData,
    fetchStationMapClustersRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchStationMapClustersLoading = (state) => {
  return {
    ...state,
    fetchStationMapClustersRequestState: RequestState.LOADING,
  }
}

export const fetchStationHeatMapLoading = (state) => {
  return {
    ...state,
    fetchStationHeatMapRequestState: RequestState.LOADING,
  }
}

export const fetchStationHeatMapSuccess = (state, { data }) => {
  return {
    ...state,
    stationMapData: data.stationsData,
    fetchStationHeatMapRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchStationHeatMapFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationHeatMapRequestState: requestState,
  }
}

export const setStationsMapFilter = (state, { filter }) => {
  return {
    ...state,
    stationsMapFilter: filter,
  }
}

export const emitterActivateChargePointStation = (
  state,
  { args: { index, data } },
) => {
  const chargePointIndex = state.stations[index]['chargePoints'].findIndex(
    (chargePoint) => chargePoint.uid === data.uid,
  )
  if (chargePointIndex > -1) {
    let newStations = state.stations

    newStations[index]['chargePoints'][chargePointIndex]['isActivated'] =
      data.chargePoint.isActivated

    return {
      ...state,
      stations: newStations,
    }
  } else return { ...state }
}

export const fetchStationListCountLoading = (state) => {
  return {
    ...state,
    fetchStationListCountRequestState: RequestState.LOADING,
  }
}

export const fetchStationListCountSuccess = (state, { stationsCount }) => {
  return {
    ...state,
    stationsCount: stationsCount,
    fetchStationListCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchStationListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationListCountRequestState: requestState,
  }
}

export const fetchStationMapClustersSearchLoading = (state) => {
  return {
    ...state,
    fetchStationMapClustersSearchRequestState: RequestState.LOADING,
  }
}

export const fetchStationMapClustersSearchSuccess = (state, { data }) => {
  return {
    ...state,
    stationMapClustersSearch: data.clusteredData,
    fetchStationMapClustersSearchRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchStationMapClustersSearchFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchStationMapClustersSearchRequestState: requestState,
  }
}

export const setStationMapClustersSearchWord = (state, { filter }) => {
  return {
    ...state,
    stationMapClustersSearchWord: filter,
  }
}

export const clearStationMapClustersSearchWord = (state) => {
  return {
    ...state,
    stationMapClustersSearchWord: '',
  }
}

export const clearStationMapClustersSearch = (state) => {
  return {
    ...state,
    stationMapClustersSearch: [],
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [StationTypes.FETCH_STATION_LIST_LOADING]: fetchStationListLoading,
  [StationTypes.FETCH_STATION_LIST_LOADING_MORE]: fetchStationListLoadingMore,
  [StationTypes.FETCH_STATION_LIST_SUCCESS]: fetchStationListSuccess,
  [StationTypes.FETCH_STATION_LIST_FAIL]: fetchStationListFail,
  [StationTypes.FETCH_STATION_MOVE_LIST_LOADING]: fetchStationMoveListLoading,
  [StationTypes.FETCH_STATION_MOVE_LIST_LOADING_MORE]:
    fetchStationMoveListLoadingMore,
  [StationTypes.FETCH_STATION_MOVE_LIST_SUCCESS]: fetchStationMoveListSuccess,
  [StationTypes.FETCH_STATION_MOVE_LIST_FAIL]: fetchStationMoveListFail,
  [StationTypes.CLEAR_STATIONS]: clearStations,
  [StationTypes.SET_STATION_LIST_SELECTED_INDEX]: setStationListSelectedIndex,
  [StationTypes.SET_STATIONS_FILTER]: setStationsFilter,
  [StationTypes.SET_MOVE_STATIONS_FILTER]: setMoveStationsFilter,
  [StationTypes.UPDATE_STATION_LOADING]: updateStationLoading,
  [StationTypes.UPDATE_STATION_SUCCESS]: updateStationSuccess,
  [StationTypes.UPDATE_STATION_FAIL]: updateStationFail,
  [StationTypes.ADD_STATION_LOADING]: addStationLoading,
  [StationTypes.ADD_STATION_SUCCESS]: addStationSuccess,
  [StationTypes.ADD_STATION_FAIL]: addStationFail,
  [StationTypes.SET_STATION_DETAILS_SELECTED_INDEX]:
    setStationDetailsSelectedIndex,
  [StationTypes.DELETE_STATION_CHARGE_POINT]: deleteStationChargePoint,
  [StationTypes.SET_MOVE_STATION_SELECTED_INDEX]: setMoveStationSelectedIndex,
  [StationTypes.ADD_MOVED_STATION]: addMovedStation,
  [StationTypes.SET_PAGINATION_OFFSET]: setPaginationOffset,
  [StationTypes.SET_MOVE_STATION_PAGINATION_OFFSET]:
    setMoveStationPaginationOffset,
  [StationTypes.EDIT_CHARGE_POINTS]: editChargePoints,
  [StationTypes.CLEAR_MOVE_STATIONS]: clearMoveStations,
  [StationTypes.ADD_STATION_CHARGE_POINT]: addStationChargePoint,
  [StationTypes.RESET_MOVE_STATIONS_FILTER]: resetMoveStationsFilter,
  [StationTypes.ACTIVATE_CHARGE_POINT]: activateChargePoint,
  [StationTypes.EMITTER_UPDATE_CHARGE_POINT_STATION]:
    emitterUpdateChargePointStation,
  [StationTypes.EMITTER_ADD_CHARGE_POINT_STATION]: emitterAddChargePointStation,
  [StationTypes.EMITTER_DELETE_CHARGE_POINT_STATION]:
    emitterDeleteChargePointStation,
  [StationTypes.EMITTER_MOVE_CHARGE_POINT_STATION]:
    emitterMoveChargePointStation,
  [StationTypes.EMITTER_ADD_STATION]: emitterAddStation,
  [StationTypes.EMITTER_UPDATE_STATION]: emitterUpdateStation,
  [StationTypes.EMITTER_DELETE_STATION]: emitterDeleteStation,
  [StationTypes.DELETE_STATION_LOADING]: deleteStationLoading,
  [StationTypes.DELETE_STATION_SUCCESS]: deleteStationSuccess,
  [StationTypes.DELETE_STATION_FAIL]: deleteStationFail,
  [StationTypes.ADD_STATION_DETAILS]: addStationDetails,
  [StationTypes.RESET_DELETE_STATION_EMITTER_STATE]:
    resetDeleteStationEmitterState,
  [StationTypes.FETCH_STATION_CITIES_SUCCESS]: fetchStationCitiesSuccess,
  [StationTypes.FETCH_STATION_CITIES_LOADING]: fetchStationCitiesLoading,
  [StationTypes.FETCH_STATION_CITIES_FAIL]: fetchStationCitiesFail,
  [StationTypes.ADD_STATION_CITY_LOADING]: addStationCityLoading,
  [StationTypes.ADD_STATION_CITY_SUCCESS]: addStationCitySuccess,
  [StationTypes.ADD_STATION_CITY_FAIL]: addStationCityFail,
  [StationTypes.FETCH_STATION_MAP_CLUSTERS_SUCCESS]:
    fetchStationMapClustersSuccess,
  [StationTypes.FETCH_STATION_MAP_CLUSTERS_LOADING]:
    fetchStationMapClustersLoading,
  [StationTypes.FETCH_STATION_HEAT_MAP_SUCCESS]: fetchStationHeatMapSuccess,
  [StationTypes.FETCH_STATION_HEAT_MAP_LOADING]: fetchStationHeatMapLoading,
  [StationTypes.FETCH_STATION_HEAT_MAP_FAIL]: fetchStationHeatMapFail,
  [StationTypes.SET_STATIONS_MAP_FILTER]: setStationsMapFilter,
  [StationTypes.EMITTER_ACTIVATE_CHARGE_POINT_STATION]:
    emitterActivateChargePointStation,
  [StationTypes.FETCH_STATION_LIST_COUNT_LOADING]: fetchStationListCountLoading,
  [StationTypes.FETCH_STATION_LIST_COUNT_SUCCESS]: fetchStationListCountSuccess,
  [StationTypes.FETCH_STATION_LIST_COUNT_FAIL]: fetchStationListCountFail,
  [StationTypes.FETCH_STATION_MAP_CLUSTERS_SEARCH_LOADING]:
    fetchStationMapClustersSearchLoading,
  [StationTypes.FETCH_STATION_MAP_CLUSTERS_SEARCH_SUCCESS]:
    fetchStationMapClustersSearchSuccess,
  [StationTypes.FETCH_STATION_MAP_CLUSTERS_SEARCH_FAIL]:
    fetchStationMapClustersSearchFail,
  [StationTypes.SET_STATION_MAP_CLUSTERS_SEARCH_WORD]:
    setStationMapClustersSearchWord,
  [StationTypes.CLEAR_STATION_MAP_CLUSTERS_SEARCH_WORD]:
    clearStationMapClustersSearchWord,
  [StationTypes.CLEAR_STATION_MAP_CLUSTERS_SEARCH]:
    clearStationMapClustersSearch,
})
