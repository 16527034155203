import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import Styles from './LocalListModal.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import CardSelectors from 'Stores/Card/Selectors'
import RequestState from 'Enums/RequestState'
import {
  IFButton,
  IFModal,
  IFSkeleton,
  IFText,
  IFToastMessage,
  UserChip,
} from 'Components'
import { UsersTokenSearchBar, CardsTokenSearchBar } from 'Containers'
import PropTypes from 'prop-types'
import validator from 'validator'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserSelectors from 'Stores/User/Selectors'
import { DatePicker, SelectPicker, toaster } from 'rsuite'
import { Divider } from '@material-ui/core'
import OCPPEnums from 'Enums/OCPPEnums'
import { Send } from '@material-ui/icons'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'

const AddStationModal = React.forwardRef(
  (
    {
      chargePointLocalList,
      fetchChargePointLocalListRequestState,
      fetchChargePointLocalList,
      chargePoint,
      tenant,
      localListFilteredUsers,
      updateChargePointLocalList,
      updateChargePointLocalListRequestState,
      localListFilteredCards,
    },
    ref,
  ) => {
    const [updateType, setUpdateType] = useState('')
    const [localList, setLocalList] = useState([])
    const [fetchedLocalListUsers, setFetchedLocalListUsers] = useState([])
    const [expiryDate, setExpiryDate] = useState()

    useEffect(() => {
      const filteredList = localListFilteredUsers.filter(
        (item) => !localList.some((token) => token._id === item._id),
      )
      setFetchedLocalListUsers(filteredList)
    }, [localListFilteredUsers])

    useEffect(() => {
      const filteredList = localListFilteredCards.filter(
        (item) => !localList.some((token) => token._id === item._id),
      )
      setFetchedLocalListUsers(filteredList)
    }, [localListFilteredCards])

    const { t } = useTranslation()

    const handleClose = () => {
      setLocalList([])
      setUpdateType('')
      setExpiryDate()
    }
    const handleDelete = (id) => {
      const deletedItem = localList.find(
        (token) => token._id === id || token.uid === id,
      )
      let newFetchedLocalList = [...fetchedLocalListUsers, deletedItem]

      newFetchedLocalList = newFetchedLocalList.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      )
      setFetchedLocalListUsers(newFetchedLocalList)
      const updatedLocalList = localList.filter(
        (item) => item._id !== id && item.uid !== id,
      )
      setLocalList(updatedLocalList)
    }
    const handleSubmit = () => {
      const chargingTokenUIDs = tenant.isUsingMobileApp
        ? undefined
        : localList.map((item) => item.uid)
      const userIds = tenant.isUsingMobileApp ? localList : undefined
      updateChargePointLocalList(
        chargePoint._id,
        chargingTokenUIDs,
        userIds,
        updateType,
        expiryDate,
        chargePointLocalList.listVersion,
        () => {
          toaster.push(
            <IFToastMessage
              type="success"
              text={t('LocalListModal.LocalListUpdated')}
            />,
            { placement: 'topEnd' },
          )
          ref.current?.handleClose()
        },
      )
    }

    return (
      <Fragment>
        <IFModal
          width={'40rem'}
          onClose={handleClose}
          title={t('LocalListModal.LocalAuthorizationList')}
          ref={ref}
        >
          <div style={{ zIndex: 100 }}>
            <div className={Styles.Container}>
              {fetchChargePointLocalListRequestState ===
              RequestState.LOADING ? (
                <IFSkeleton width="9rem" height="1.75rem" variant="text" />
              ) : (
                <IFText className={Styles.CurrentVersion}>
                  {t('LocalListModal.CurrentVersion')}{' '}
                  {chargePointLocalList.listVersion}
                </IFText>
              )}
            </div>
            <div className={Styles.ChipContainer}>
              {fetchChargePointLocalListRequestState ===
              RequestState.LOADING ? (
                [...Array(14)].map((item) => {
                  return (
                    <IFSkeleton
                      className={Styles.SkeletonChip}
                      width="4.5rem"
                      height="1.75rem"
                      variant="text"
                    />
                  )
                })
              ) : (
                <>
                  {(chargePointLocalList.users ||
                    chargePointLocalList.chargingTokens) &&
                    [
                      ...(tenant.isUsingMobileApp
                        ? chargePointLocalList.users
                        : chargePointLocalList.chargingTokens),
                    ].map((item) => {
                      return (
                        <UserChip
                          name={
                            tenant.isUsingMobileApp
                              ? item.name
                              : item.visualNumber
                              ? item.visualNumber
                              : item.reference
                              ? item.reference
                              : item.uid
                          }
                          chargingTokensCount={item.tokenCount}
                        />
                      )
                    })}
                  {chargePointLocalList?.totalCount -
                    (tenant.isUsingMobileApp
                      ? chargePointLocalList?.users?.length
                      : chargePointLocalList?.chargingTokens?.length) >
                    0 && (
                    <UserChip
                      name={
                        '+' +
                        (chargePointLocalList.totalCount -
                          (tenant.isUsingMobileApp
                            ? chargePointLocalList.users.length
                            : chargePointLocalList.chargingTokens.length)) +
                        ' ...'
                      }
                    />
                  )}
                </>
              )}
            </div>
            <Divider
              className={Styles.Divider}
              style={{
                backgroundColor: Colors.DividerColor,
              }}
            />
            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton width="9rem" height="1.75rem" variant="text" />
            ) : (
              <IFText className={Styles.LocalListText}>
                {t('LocalListModal.SendLocalList')}
              </IFText>
            )}
            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton width="5rem" height="1.5rem" variant="text" />
            ) : (
              <IFText className={Styles.UpdateTypeText}>
                {t('LocalListModal.UpdateType')}
              </IFText>
            )}
            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton
                className={Styles.UpdateTypeTextSP}
                width="7rem"
                height="2.5rem"
                variant="text"
              />
            ) : (
              <SelectPicker
                className={Styles.UpdateTypeTextSP}
                preventOverflow={true}
                menuStyle={{ color: Colors.text, zIndex: 15 }}
                appearance="default"
                searchable={false}
                cleanable={false}
                onSelect={(value) => {
                  setUpdateType(value)
                }}
                onClean={() => {}}
                data={OCPPEnums.LocalListUpdateType}
                size="sm"
              />
            )}
            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton width="5rem" height="1.5rem" variant="text" />
            ) : (
              <IFText className={Styles.UpdateTypeText}>
                {t('LocalListModal.ExpiryDate')}
              </IFText>
            )}
            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton
                className={Styles.UpdateTypeTextSP}
                width="7rem"
                height="2.5rem"
                variant="text"
              />
            ) : (
              <div>
                <DatePicker
                  className={Styles.DatePicker}
                  value={expiryDate}
                  onChange={(newValue) => {
                    setExpiryDate(newValue)
                  }}
                  cleanable={false}
                  size="sm"
                  placeholder="YYYY-MM-DD"
                  shouldCloseOnSelect={false}
                  shouldDisableDate={(date) => date < new Date()}
                />
              </div>
            )}

            {fetchChargePointLocalListRequestState === RequestState.LOADING ? (
              <IFSkeleton
                className={Styles.LocalList}
                width="5rem"
                height="1.5rem"
                variant="text"
              />
            ) : (
              <IFText className={Styles.LocalList}>
                {t('LocalListModal.LocalList')}
              </IFText>
            )}
            <div
              className={Styles.LocalListWrapper}
              style={{
                boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
              }}
            >
              <div className={Styles.LocalListChipContainer}>
                {fetchChargePointLocalListRequestState ===
                RequestState.LOADING ? (
                  <div className={Styles.ChipContainer}>
                    {[...Array(14)].map((item) => {
                      return (
                        <IFSkeleton
                          className={Styles.SkeletonChip}
                          width="4.5rem"
                          height="1.75rem"
                          variant="text"
                        />
                      )
                    })}
                  </div>
                ) : (
                  <>
                    {localList.length === 0 ? (
                      <EmptySpecs title={t('LocalListModal.LocalListEmpty')} />
                    ) : (
                      <div className={Styles.ChipContainer}>
                        {localList.map((item) => {
                          return (
                            <UserChip
                              name={
                                tenant.isUsingMobileApp
                                  ? item.name
                                  : item.visualNumber
                                  ? item.visualNumber
                                  : item.reference
                                  ? item.reference
                                  : item.uid
                              }
                              chargingTokensCount={item.chargingTokensCount}
                              onDelete={(e) => {
                                handleDelete(item.uid ? item.uid : item._id)
                              }}
                            />
                          )
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>

              {fetchChargePointLocalListRequestState ===
              RequestState.LOADING ? (
                <IFSkeleton
                  className={Styles.SkeletonChip}
                  width="100%"
                  height="2rem"
                  variant="rectangular"
                />
              ) : tenant.isUsingMobileApp ? (
                <UsersTokenSearchBar
                  localListFilteredUsers={fetchedLocalListUsers}
                  onSelect={(value) => {
                    let userToken = localListFilteredUsers.find(
                      (item) => item._id === value,
                    )
                    setLocalList([...localList, userToken])
                  }}
                />
              ) : (
                <CardsTokenSearchBar
                  onSelect={(value) => {
                    let chargingTokens = localListFilteredCards.find(
                      (item) => item.uid === value,
                    )
                    setLocalList([...localList, chargingTokens])
                  }}
                  localListFilteredCards={fetchedLocalListUsers}
                />
              )}
            </div>

            <div className={Styles.ButtonContainer}>
              {fetchChargePointLocalListRequestState ===
              RequestState.LOADING ? (
                <IFSkeleton
                  className={Styles.SkeletonChip}
                  width="9rem"
                  height="2.5rem"
                  variant="rectangular"
                />
              ) : (
                <IFButton
                  text={t('TerminalModal.ButtonText')}
                  icon={<Send style={{ fontSize: '18px' }} />}
                  color={Colors.primary}
                  isFill={true}
                  className={Styles.Button}
                  isLoading={
                    updateChargePointLocalListRequestState ===
                    RequestState.LOADING
                  }
                  size={'sm'}
                  isDead={
                    validator.isEmpty(updateType) || localList.length === 0
                  }
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </IFModal>
      </Fragment>
    )
  },
)
AddStationModal.propTypes = {
  tenantUser: PropTypes.object,
  addStationRequestState: PropTypes.number,
  addStation: PropTypes.func,
}
function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointLocalList: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointLocalList(chargePointId)),
    updateChargePointLocalList: (
      chargePointId,
      chargingTokenUIDs,
      userIds,
      updateType,
      expiryDate,
      version,
      onComplete,
    ) =>
      dispatch(
        ChargePointActions.updateChargePointLocalList(
          chargePointId,
          chargingTokenUIDs,
          userIds,
          updateType,
          expiryDate,
          version,
          onComplete,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  localListFilteredUsers: UserSelectors.getLocalListFilteredUsers(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  tenant: AuthSelectors.getTenant(state),
  chargePointLocalList: ChargePointSelectors.getChargePointLocalList(state),
  fetchChargePointLocalListRequestState:
    ChargePointSelectors.getFetchChargePointLocalListRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  updateChargePointLocalListRequestState:
    ChargePointSelectors.getUpdateChargePointLocalListRequestState(state),
  localListFilteredCards: CardSelectors.getLocalListFilteredCards(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddStationModal)
