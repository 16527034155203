import { React } from 'react'
import styles from './IFSearchFieldSkeleton.module.css'
import styled from 'styled-components'
import colors from 'Theme/Colors'
import { IFSkeleton } from 'Components'

const FilterContainer = styled.div`
  border: 1px solid ${colors.skeleton};
  color: ${colors.FilterShadow};
`

const IFSearchFieldSkeleton = () => {
  return (
    <FilterContainer
      className={styles.container}
      style={{ boxShadow: colors.BoxShadowColor }}
    >
      <IFSkeleton
        variant="circular"
        style={{
          width: '1.5rem',
          height: '1.5rem',
          alignSelf: 'center',
          marginLeft: '0.75rem',
        }}
      />
      <IFSkeleton
        variant="text"
        style={{
          width: '9rem',
          height: '1.25rem',
          alignSelf: 'center',
          marginLeft: '0.75rem',
        }}
      />
    </FilterContainer>
  )
}

export default IFSearchFieldSkeleton
