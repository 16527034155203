import React from 'react'
import Styles from './StationIcon.module.css'
import icons from '../../Theme/Icons'
import InfinityEnums from 'Enums/InfinityEnums'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
const { ConnectorPowerLevel } = InfinityEnums
const StationIcon = React.forwardRef(
  (
    {
      maxChargingSpeed = ConnectorPowerLevel.LEVEL1,
      onClick,
      stationStatus = InfinityEnums.ConnectorStatus.AVAILABLE,
      isClickable = true,
      addHeatMapOffset = false,
      isOffline = false,
    },
    ref,
  ) => {
    const getIconColor = () => {
      if (isOffline) {
        return Colors.OfflineConnector
      }
      let iconColor = Colors.AvailableConnector
      if (stationStatus !== InfinityEnums.ConnectorStatus.AVAILABLE) {
        switch (stationStatus) {
          case InfinityEnums.ConnectorStatus.UNAVAILABLE:
          case InfinityEnums.ConnectorStatus.FAULTED:
            iconColor = Colors.UnAvailableConnector
            break
          case InfinityEnums.ConnectorStatus.PREPARING:
          case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
          case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
          case InfinityEnums.ConnectorStatus.RESERVED:
            iconColor = Colors.SuspendedConnector
            break
          case InfinityEnums.ConnectorStatus.CHARGING:
          case InfinityEnums.ConnectorStatus.FINISHING:
            iconColor = Colors.ChargingConnector
            break
        }
      }
      return iconColor
    }

    return (
      <div
        style={
          addHeatMapOffset ? { marginTop: '-3rem', marginLeft: '-1.5rem' } : {}
        }
      >
        <div
          ref={ref}
          onClick={onClick}
          style={{
            cursor: isClickable ? 'pointer' : null,
          }}
          className={Styles.container}
        >
          <div
            className={Styles.dot}
            style={{ backgroundColor: getIconColor() }}
          >
            <div className={Styles.chargingSpeedContainer}>
              {maxChargingSpeed <= ConnectorPowerLevel.LEVEL1 ? (
                <div className={Styles.flash}>{icons.flash}</div>
              ) : maxChargingSpeed <= ConnectorPowerLevel.LEVEL2 ? (
                [...Array(2)].map((e, index) => {
                  return (
                    <div key={`flash-${index}`} className={Styles.flash}>
                      {icons.flash}
                    </div>
                  )
                })
              ) : (
                [...Array(3)].map((e, index) => {
                  return (
                    <div key={`flash-${index}`} className={Styles.flash}>
                      {icons.flash}
                    </div>
                  )
                })
              )}
            </div>
          </div>
          <div
            className={Styles.triangle}
            style={{
              borderTop: `0 ${getIconColor()}`,
              borderBottom: `0.75rem solid ${getIconColor()}`,
            }}
          ></div>
        </div>
      </div>
    )
  },
)

StationIcon.propTypes = {
  maxChargingSpeed: PropTypes.number,
  onClick: PropTypes.func,
  stationStatus: PropTypes.string,
  isClickable: PropTypes.bool,
  addHeatMapOffset: PropTypes.bool,
  isOffline: PropTypes.bool,
}

export default StationIcon
