import PropTypes from 'prop-types'
import Styles from './IFTextArea.module.css'
import React, { useState } from 'react'
import colors from 'Theme/Colors'
import IFText from 'Components/IFText/IFText'

const IFTextArea = ({
  children,
  className,
  style,
  isFixed,
  errorText,
  value = '',
  fontSize = 14,
  onFocus = () => {},
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (
    <div className={Styles.inputContainer}>
      <textarea
        {...props}
        value={value}
        onFocus={() => {
          setIsFocused(true)
          onFocus()
        }}
        onBlur={() => setIsFocused(false)}
        style={{
          ...style,
          borderColor: errorText
            ? colors.red
            : isFocused
            ? colors.primary
            : colors.transparent,
          fontSize: fontSize,
        }}
        className={`${errorText ? Styles.error : Styles.input} ${className}`}
      />
      <div className={Styles.errorContainer}>
        {errorText ? (
          <IFText className={Styles.errorText} style={{ color: colors.red }}>
            {errorText}
          </IFText>
        ) : null}
      </div>
    </div>
  )
}

IFTextArea.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  isFixed: PropTypes.bool,
  value: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  fontSize: PropTypes.number,
}

export default IFTextArea
