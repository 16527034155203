import {
  handleError,
  chargeApiService,
  chargePointApiService,
} from 'Services/ApiService.js'
import { toaster } from 'rsuite'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import i18n from 'i18next'
import { IFToastMessage } from 'Components'
import InfinityEnums from 'Enums/InfinityEnums'
let store
export function initOCPPService(_store) {
  store = _store
}

const startTransaction = (
  chargePointId,
  connectorUid,
  chargingTokenUid,
  email,
  phone,
  onResponse,
) => {
  const data = {
    chargePointId,
    connectorUid,
    chargingTokenUid,
    email,
    phone,
  }
  chargeApiService
    .post('/start', data)
    .then(() => {
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.SessionStarted')}
        />,
        { placement: 'topEnd' },
      )
      onResponse(false)
    })
    .catch((err) => {
      onResponse(true)
      handleError(err)
    })
}

const stopTransaction = (
  chargePointId,
  connectorUid,
  onResponse,
  chargingSessionId,
  chargingTokenId,
) => {
  const data = { chargePointId, connectorUid }
  chargeApiService
    .post('/stop', data)
    .then(() => {
      onResponse()
      if (i18n.language === 'ar') {
        toaster.push(
          <IFToastMessage type="success" text="توقفت العملية بنجاح" />,
          { placement: 'topEnd' },
        )
      } else {
        toaster.push(
          <IFToastMessage
            type="success"
            text={i18n.t('OCPPService.SessionStopped')}
          />,
          { placement: 'topEnd' },
        )
      }
    })
    .catch((err) => {
      onResponse()
      handleError(err)
    })
}

const unlockConnector = (chargePointId, connectorId, onResponse) => {
  chargePointApiService
    .post(`/${chargePointId}/connectors/${connectorId}/unlock`)
    .then(() => {
      onResponse()

      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.ConnectorUnlocked')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      onResponse()
      handleError(err)
    })
}

const resetChargePoint = (chargePointId, type, onDone) => {
  const data = { type }
  store.dispatch(ChargePointActions.resetChargePointLoading())
  chargePointApiService
    .post(`/${chargePointId}/reset`, data)
    .then(() => {
      store.dispatch(ChargePointActions.resetChargePointSuccess())

      toaster.push(
        <IFToastMessage
          type="success"
          text={
            type === InfinityEnums.ResetType.HARD
              ? i18n.t('OCPPService.HardResetSuccessful')
              : i18n.t('OCPPService.SoftResetSuccessful')
          }
        />,
        { placement: 'topEnd' },
      )
      onDone()
    })
    .catch((err) => {
      store.dispatch(ChargePointActions.resetChargePointFail())
      handleError(err)
      onDone()
    })
}

const clearCache = (chargePointId) => {
  store.dispatch(ChargePointActions.clearChargePointCacheLoading())
  chargePointApiService
    .post(`/${chargePointId}/clearCache`)
    .then(() => {
      store.dispatch(ChargePointActions.clearChargePointCacheSuccess())
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.CacheCleared')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      store.dispatch(ChargePointActions.clearChargePointCacheFail())
      handleError(err)
    })
}

const syncConnectorStatus = (chargePointId, connectorUid, onResponse) => {
  chargePointApiService
    .post(`/${chargePointId}/triggerMessage`, {
      connectorId: connectorUid,
      requestedMessage: InfinityEnums.MessageTrigger.STATUS_NOTIFICATION,
    })
    .then(() => {
      onResponse()
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.StatusUpdateRequested')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      onResponse()
      handleError(err)
    })
}

const changeAvailability = (chargePointId, connectorStatuses, onResponse) => {
  store.dispatch(ChargePointActions.changeChargePointAvailabilityLoading())
  let successfulIds = []
  let scheduleChangeIds = []
  let unsuccessfulIds = []
  let promises = []

  for (const conn of connectorStatuses) {
    promises.push(
      chargePointApiService
        .post(`/${chargePointId}/connectors/${conn.id}/availability`, {
          isOperative: conn.status,
        })
        .then((res) => {
          successfulIds.push(conn)
          if (res?.data?.status === 'Scheduled') scheduleChangeIds.push(conn.id)
        })
        .catch(() => {
          unsuccessfulIds.push(conn.id)
        }),
    )
  }

  Promise.all(promises)
    .then(() => {
      if (successfulIds.length > 0) {
        store.dispatch(
          ChargePointActions.changeChargePointAvailabilitySuccess(),
        )
        if (i18n.language === 'ar') {
          toaster.push(
            <IFToastMessage
              type="success"
              text="تم قبول طلب تغيير التوفر وسيتم تنفيذه"
            />,
            { placement: 'topEnd' },
          )
        } else {
          let successString = ''
          if (successfulIds.length === 1) {
            successString = `Change availability accepted for connector ${successfulIds[0].id} `
          } else {
            successString = `Change availability accepted for connectors:`
            successfulIds.sort((a, b) => a.id - b.id)
            for (const [index, conn] of successfulIds.entries()) {
              if (index === successfulIds.length - 1)
                successString = successString + ` ${conn.id}`
              else successString = successString + ` ${conn.id},`
            }
          }

          toaster.push(<IFToastMessage type="success" text={successString} />, {
            placement: 'topEnd',
          })
        }
      }

      if (unsuccessfulIds.length > 0) {
        store.dispatch(ChargePointActions.changeChargePointAvailabilityFail())
        if (i18n.language === 'ar') {
          toaster.push(
            <IFToastMessage
              type="error"
              text="تم قبول طلب تغيير التوفر وسيتم تنفيذه"
            />,
            { placement: 'topEnd' },
          )
        } else {
          let failString = ''
          if (unsuccessfulIds.length === 1) {
            failString = `Change availability rejected for connector ${unsuccessfulIds[0]} `
          } else {
            failString = `Change availability rejected for connectors:`
            unsuccessfulIds.sort()
            for (const [index, id] of unsuccessfulIds.entries()) {
              if (index === unsuccessfulIds.length - 1)
                failString = failString + ` ${id}`
              else failString = failString + ` ${id},`
            }
          }

          toaster.push(<IFToastMessage type="error" text={failString} />, {
            placement: 'topEnd',
          })
        }
      }
      if (scheduleChangeIds.length > 0)
        toaster.push(
          <IFToastMessage
            type="info"
            text={
              i18n.t('OCPPService.ScheduledChangeAvailability') +
              scheduleChangeIds.sort((a, b) => a.id - b.id).join(', ')
            }
          />,
          {
            placement: 'topEnd',
          },
        )
      onResponse()
    })
    .catch()
}
const ocppMessage = (chargePointId, type, request, onResponse) => {
  chargePointApiService
    .post(`/${chargePointId}/ocppRequest`, {
      type,
      request,
    })
    .then((res) => {
      onResponse(res?.data)
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.TerminalSuccessMessage')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      onResponse()
      handleError(err)
    })
}

const reserveConnector = (
  chargePointId,
  connectorUid,
  chargingTokenUid,
  expiresAt,
  email,
  onResponse,
) => {
  chargeApiService
    .post(`/reserve`, {
      chargingTokenUid,
      chargePointId,
      connectorUid,
      expiresAt,
      email,
    })
    .then(async () => {
      onResponse(false)
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.ConnectorReserved')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      onResponse(true)
      handleError(err)
    })
}

const CancelConnectorReservation = (reservationId, onResponse) => {
  chargeApiService
    .patch(`/reserve/${reservationId}`)
    .then(() => {
      onResponse()
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.ReservationCancelled')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      onResponse()
      handleError(err)
    })
}

const diagnosticsStatusNotification = (chargePointId) => {
  store.dispatch(ChargePointActions.diagnosticsStatusNotificationLoading())
  chargePointApiService
    .post(`/${chargePointId}/triggerMessage`, {
      requestedMessage:
        InfinityEnums.MessageTrigger.DIAGNOSTICS_STATUS_NOTIFICATION,
    })
    .then(() => {
      store.dispatch(ChargePointActions.diagnosticsStatusNotificationSuccess())
      toaster.push(
        <IFToastMessage
          type="success"
          text={i18n.t('OCPPService.DiagnosticsStatusNotification')}
        />,
        { placement: 'topEnd' },
      )
    })
    .catch((err) => {
      store.dispatch(ChargePointActions.diagnosticsStatusNotificationFail())
      handleError(err)
    })
}

const OCPPServices = {
  startTransaction,
  stopTransaction,
  unlockConnector,
  resetChargePoint,
  clearCache,
  syncConnectorStatus,
  changeAvailability,
  reserveConnector,
  CancelConnectorReservation,
  diagnosticsStatusNotification,
  ocppMessage,
}

export default OCPPServices
