import React, { useEffect, forwardRef, useState } from 'react'
import { connect } from 'react-redux'
import CardActions from 'Stores/Card/Actions'
import CardSelectors from 'Stores/Card/Selectors'
import RequestState from 'Enums/RequestState'
import { LocalListAdd, ChargingTokenListItem } from 'Components'
import { cardsFilter } from 'Constants/Filters'

const CardsTokenSearchBar = forwardRef(
  ({
    localListFilteredCards,
    fetchLocalListFilteredCardsRequestState,
    localListFilteredCardsPaginationOffset,
    fetchLocalListFilteredCards,
    clearLocalListFilteredCards,
    onSelect = () => {},
  }) => {
    const [filter, setFilter] = useState(
      JSON.parse(JSON.stringify(cardsFilter)),
    )
    useEffect(() => {
      fetchLocalListFilteredCards(
        filter,
        localListFilteredCardsPaginationOffset,
        () => {},
      )
    }, [])

    useEffect(() => {
      return () => {
        clearLocalListFilteredCards()
      }
    }, [])
    useEffect(() => {
      clearLocalListFilteredCards()
      fetchLocalListFilteredCards(filter, 0, () => {})
    }, [filter])

    const loadMoreData = () => {
      if (
        fetchLocalListFilteredCardsRequestState === RequestState.LOADING ||
        localListFilteredCardsPaginationOffset === null
      )
        return

      fetchLocalListFilteredCards(
        filter,
        localListFilteredCardsPaginationOffset,
        () => {},
      )
    }
    return (
      <div>
        <LocalListAdd
          data={
            fetchLocalListFilteredCardsRequestState === RequestState.LOADING
              ? [
                  ...localListFilteredCards.map((token) => {
                    return {
                      ...token,
                      backgroundHover: 'inherit',
                    }
                  }),

                  ...(localListFilteredCardsPaginationOffset === 0
                    ? [...Array(7)].map((e, index) => {
                        return {
                          isLoading: true,
                          uid: 'skeleton',
                          backgroundHover: 'inherit',
                        }
                      })
                    : [...Array(2)].map(() => {
                        return {
                          isLoading: true,
                          uid: 'skeleton',
                          backgroundHover: 'inherit',
                        }
                      })),
                ]
              : localListFilteredCards.map((token) => {
                  return {
                    ...token,
                    backgroundHover: 'inherit',
                  }
                })
          }
          onSelect={onSelect}
          RenderMenuItem={ChargingTokenListItem}
          RenderMenuItemkeys={[
            'reference',
            'uid',
            'visualNumber',
            'isSelected',
            'onClick',
            'status',
            'type',
            'isLoading',
            'backgroundHover',
          ]}
          onSearch={(searchKeyword) => {
            let newFilter = [...filter]
            newFilter[0].value = [searchKeyword]
            setFilter(newFilter)
          }}
          isLoading={
            fetchLocalListFilteredCardsRequestState === RequestState.LOADING
          }
          loadMoreData={() => {
            loadMoreData()
          }}
          itemSize={84}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchLocalListFilteredCards: (filter, offset) =>
      dispatch(CardActions.fetchLocalListFilteredCards(filter, offset)),
    clearLocalListFilteredCards: () =>
      dispatch(CardActions.clearLocalListFilteredCards()),
  }
}

const mapStateToProps = (state) => ({
  localListFilteredCardsPaginationOffset:
    CardSelectors.getLocalListFilteredCardsPaginationOffset(state),
  fetchLocalListFilteredCardsRequestState:
    CardSelectors.getFetchLocalListFilteredCardsRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CardsTokenSearchBar)
