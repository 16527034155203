import { IFSkeleton } from 'Components'
import React, { Fragment } from 'react'
import Styles from './TenantUserListItemSkeleton.module.css'
import PropTypes from 'prop-types'

const TenantUserListItemSkeleton = ({ editPermission }) => {
  return (
    <div className={Styles.Container}>
      {editPermission ? (
        <Fragment>
          <div className={Styles.AvatarContainer}>
            <IFSkeleton variant="circular" width="2.5rem" height="2.5rem" />
          </div>
          <div className={Styles.NameContainer}>
            <IFSkeleton variant="text" width="10rem" height="2rem" />
            <IFSkeleton variant="text" width="10rem" height="2rem" />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className={Styles.AvatarNameContainer}>
            <div className={Styles.AvatarContainer}>
              <IFSkeleton variant="circular" width="3rem" height="3rem" />
            </div>
            <div className={Styles.NameContainer}>
              <IFSkeleton variant="text" width="10rem" height="2rem" />
              <IFSkeleton variant="text" width="10rem" height="2rem" />
            </div>
          </div>
        </Fragment>
      )}
      <div
        className={Styles.PermissionsContainer}
        {...(!editPermission ? { style: { justifyContent: 'center' } } : {})}
      >
        <IFSkeleton variant="text" width="12rem" height="2.5rem" />
      </div>
      {editPermission && (
        <div className={Styles.EditContainer}>
          <IFSkeleton variant="circular" width="2rem" height="2rem" />
        </div>
      )}
    </div>
  )
}
TenantUserListItemSkeleton.propTypes = {
  editPermission: PropTypes.bool,
}

export default TenantUserListItemSkeleton
