import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { CardTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import { cardsTransactionFilter } from 'Constants/Filters'

export const fetchCardListLoading = (state) => {
  return {
    ...state,
    fetchCardListRequestState: RequestState.LOADING,
  }
}

export const fetchCardListLoadingMore = (state) => {
  return {
    ...state,
    fetchCardListRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchCardListSuccess = (
  state,
  { offset, cards, nextOffset, index },
) => {
  let result = []
  for (let j = 0; j < cards.length; j++) {
    if (!state.cards.some((card) => card._id === cards[j]._id)) {
      result.push(cards[j])
    }
  }

  if (offset === 0) {
    return {
      ...state,
      cards: [...cards],
      paginationOffset: nextOffset,
      selectedIndex: index,
      selectedCard: cards[index],
      fetchCardListRequestState: RequestState.SUCCEEDED,
    }
  } else {
    let selectedIndex = 0
    const chargingTokensList = state.cards
    if (chargingTokensList.length !== 0) {
      const stateSelectedIndex = state.selectedIndex
      const selectedToken = chargingTokensList[stateSelectedIndex]
      let tokenOutOfPlace = false

      if (cards[0] && selectedToken.updatedAt < cards[0]['updatedAt'])
        tokenOutOfPlace = true

      if (tokenOutOfPlace) {
        const outOfPlaceTokenIndex = cards.findIndex(
          (token) => token._id === selectedToken._id,
        )
        if (outOfPlaceTokenIndex !== -1)
          selectedIndex = chargingTokensList.length + outOfPlaceTokenIndex - 1
        else selectedIndex = chargingTokensList.length + cards.length - 1
      } else selectedIndex = state.selectedIndex
    }
    let combinedTokens = [...state.cards, ...result]
    combinedTokens.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))

    return {
      ...state,
      cards: combinedTokens,
      paginationOffset: nextOffset,
      fetchCardListRequestState: RequestState.SUCCEEDED,
      selectedIndex: selectedIndex,
    }
  }
}

export const fetchCardListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCardListRequestState: requestState,
  }
}

export const deleteCardLoading = (state) => {
  return {
    ...state,
    deleteCardRequestState: RequestState.LOADING,
  }
}

export const deleteCardSuccess = (state, { cardId }) => {
  let newCards = state.cards.filter((card) => card._id !== cardId)
  return {
    ...state,
    cards: [...newCards],
    selectedIndex: 0,
    selectedCard: newCards.length === 0 ? null : newCards[0],
    deleteCardRequestState: RequestState.SUCCEEDED,
    cardsCount: state.cardsCount - 1,
  }
}

export const deleteCardFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteCardRequestState: requestState,
  }
}

export const updateCardLoading = (state) => {
  return {
    ...state,
    updateCardRequestState: RequestState.LOADING,
  }
}

export const updateCardSuccess = (state, { index, newCard }) => {
  let newCards = state.cards
  let selectedCard = state.selectedCard

  if (index < 0) {
    return
  } else {
    const newObject = {
      ...newCards[index],
    }
    if (newCard.parentId) {
      newObject.parentId = newCard.parentId
      selectedCard.parentId = newCard.parentId
    }
    if (newCard.uid) {
      newObject.uid = newCard.uid
      selectedCard.uid = newCard.uid
    }
    if (newCard.reference) {
      newObject.reference = newCard.reference
      selectedCard.reference = newCard.reference
    }
    if (newCard.visualNumber) {
      newObject.visualNumber = newCard.visualNumber
      selectedCard.visualNumber = newCard.visualNumber
    }
    if (newCard.type) {
      newObject.type = newCard.type
      selectedCard.type = newCard.type
    }
    if (newCard.status) {
      newObject.status = newCard.status
      selectedCard.status = newCard.status
    }
    newCards[index] = newObject
    return {
      ...state,
      cards: [...newCards],
      selectedCard: selectedCard,
      updateCardRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const updateCardFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateCardRequestState: requestState,
  }
}

export const clearCards = (state) => {
  return {
    ...state,
    cards: [],
    selectedCard: null,
  }
}

export const setCardsFilter = (state, { newFilter }) => {
  return {
    ...state,
    cardsFilter: newFilter,
  }
}

export const addCardLoading = (state) => {
  return {
    ...state,
    addCardRequestState: RequestState.LOADING,
  }
}

export const addCardSuccess = (state, { newCard }) => {
  return {
    ...state,
    cards: [...state.cards, newCard],
    addCardRequestState: RequestState.SUCCEEDED,
    cardsCount: state.cardsCount + 1,
  }
}

export const addCardFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCardRequestState: requestState,
  }
}

export const addCardBulkSuccess = (state, { newCards }) => {
  return {
    ...state,
    cards: [...state.cards, ...newCards],
    addCardRequestState: RequestState.SUCCEEDED,
    cardsCount: state.cardsCount + newCards.length,
  }
}

export const resetCardRequestStates = (state) => {
  return {
    ...state,
    fetchCardListRequestState: RequestState.UNINITIALIZED,
    deleteCardRequestState: RequestState.UNINITIALIZED,
    updateCardRequestState: RequestState.UNINITIALIZED,
    addCardRequestState: RequestState.UNINITIALIZED,
  }
}

export const fetchCardTransactionsLoading = (state) => {
  return {
    ...state,
    fetchCardTransactionsRequestState: RequestState.LOADING,
  }
}

export const fetchCardTransactionsLoadingMore = (state) => {
  return {
    ...state,
    fetchCardTransactionsRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchCardTransactionsSuccess = (
  state,
  { offset, chargingTokenTransactions, nextOffset },
) => {
  let result = []
  for (let j = 0; j < chargingTokenTransactions.length; j++) {
    if (
      !state.cardTransactions.some(
        (cardTransaction) =>
          cardTransaction._id === chargingTokenTransactions[j]._id,
      )
    ) {
      result.push(chargingTokenTransactions[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      cardTransactions: [...chargingTokenTransactions],
      cardTransactionsPaginationOffset: nextOffset,
      fetchCardTransactionsRequestState: RequestState.SUCCEEDED,
    }
  } else {
    return {
      ...state,
      cardTransactions: [...state.cardTransactions, ...result],
      cardTransactionsPaginationOffset: nextOffset,
      fetchCardTransactionsRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchCardTransactionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCardTransactionsRequestState: requestState,
  }
}

export const setCardTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    cardsTransactionsFilter: newFilter,
  }
}

export const setCardSelectedIndex = (state, { index }) => {
  let newSelectedCard
  if (index === state.selectedIndex) {
    newSelectedCard = state.selectedCard
  } else {
    newSelectedCard = state.cards[index]
  }
  return {
    ...state,
    selectedCard: newSelectedCard,
    selectedIndex: index,
  }
}

export const fetchCardLoading = (state) => {
  return {
    ...state,
    fetchCardRequestState: RequestState.LOADING,
  }
}

export const fetchCardSuccess = (state, { card }) => {
  const newCard = {
    ...card.chargingToken,
    subscriptions: card.subscriptions,
    accessGroups: card.accessGroups,
  }
  return {
    ...state,
    selectedCard: newCard,
    fetchCardRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchCardFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCardRequestState: requestState,
  }
}

export const addCardAccessGroupsLoading = (state) => {
  return {
    ...state,
    addCardAccessGroupsRequestState: RequestState.LOADING,
  }
}

export const addCardAccessGroupsSuccess = (state, { accessGroups }) => {
  const groups = [
    ...new Set(state.selectedCard.accessGroups.concat(accessGroups)),
  ]
  const updatedCard = { ...state.selectedCard }
  updatedCard.accessGroups = groups
  return {
    ...state,
    selectedCard: updatedCard,
    addCardAccessGroupsRequestState: RequestState.SUCCEEDED,
  }
}

export const addCardAccessGroupsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCardAccessGroupsRequestState: requestState,
  }
}

export const deleteCardAccessGroupsLoading = (state) => {
  return {
    ...state,
    deleteCardAccessGroupsRequestState: RequestState.LOADING,
  }
}

export const deleteCardAccessGroupsSuccess = (state, { accessGroups }) => {
  const oldAccessGroups = state.selectedCard.accessGroups
  const filteredAccessGroups = oldAccessGroups.filter((group) => {
    return accessGroups.indexOf(group._id) === -1
  })
  const updatedCard = { ...state.selectedCard }
  updatedCard.accessGroups = filteredAccessGroups
  return {
    ...state,
    selectedCard: updatedCard,
    deleteCardAccessGroupsRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteCardAccessGroupsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteCardAccessGroupsRequestState: requestState,
  }
}

export const addCardSubscriptionsLoading = (state) => {
  return {
    ...state,
    addCardSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const addCardSubscriptionsSuccess = (state, { subscriptions }) => {
  const newSubscriptions = [
    ...new Set(state.selectedCard.subscriptions.concat(subscriptions)),
  ]
  const updatedCard = state.selectedCard
  updatedCard.subscriptions = newSubscriptions
  return {
    ...state,
    selectedCard: updatedCard,
    addCardSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const addCardSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addCardSubscriptionsRequestState: requestState,
  }
}

export const deleteCardSubscriptionsLoading = (state) => {
  return {
    ...state,
    deleteCardSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const deleteCardSubscriptionsSuccess = (state, { subscriptions }) => {
  const oldSubscriptions = state.selectedCard.subscriptions
  const filteredSubscriptions = oldSubscriptions.filter((sub) => {
    return subscriptions.indexOf(sub.subscription._id) === -1
  })
  const updatedCard = state.selectedCard
  updatedCard.subscriptions = filteredSubscriptions
  return {
    ...state,
    selectedCard: updatedCard,
    deleteCardSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteCardSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteCardSubscriptionsRequestState: requestState,
  }
}
export const clearCardTransactions = (state) => {
  return {
    ...state,
    cardTransactions: [],
  }
}
export const resetCardState = (state) => {
  return {
    ...state,
    selectedCard: null,
    fetchCardTransactionsRequestState: RequestState.UNINITIALIZED,
    cardTransactionsPaginationOffset: 0,
    cardsTransactionsFilter: cardsTransactionFilter,
  }
}

export const setPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    paginationOffset: offset,
  }
}

export const setCardsTransactionPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    cardsTransactionPaginationOffset: offset,
  }
}

export const fetchCardListCountLoading = (state) => {
  return {
    ...state,
    fetchCardListCountRequestState: RequestState.LOADING,
  }
}

export const fetchCardListCountSuccess = (state, { cardsCount }) => {
  return {
    ...state,
    cardsCount: cardsCount,
    fetchCardListCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchCardListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchCardListCountRequestState: requestState,
  }
}

export const fetchLocalListFilteredCardsLoading = (state) => {
  return {
    ...state,
    fetchLocalListFilteredCardsRequestState: RequestState.LOADING,
  }
}

export const fetchLocalListFilteredCardsSuccess = (
  state,
  { offset, cards, nextOffset },
) => {
  let result = []
  for (let j = 0; j < cards.length; j++) {
    if (!state.cards.some((card) => card._id === cards[j]._id)) {
      result.push(cards[j])
    }
  }

  if (offset === 0) {
    return {
      ...state,
      localListFilteredCards: [...cards],
      localListFilteredCardsPaginationOffset: nextOffset,
      fetchLocalListFilteredCardsRequestState: RequestState.SUCCEEDED,
    }
  } else {
    let combinedTokens = [...state.localListFilteredCards, ...result]
    combinedTokens.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))

    return {
      ...state,
      localListFilteredCards: combinedTokens,
      localListFilteredCardsPaginationOffset: nextOffset,
      fetchLocalListFilteredCardsRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchLocalListFilteredCardsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchLocalListFilteredCardsRequestState: requestState,
  }
}
export const clearLocalListFilteredCards = (state) => {
  return {
    ...state,
    localListFilteredCards: [],
    localListFilteredCardsPaginationOffset: 0,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [CardTypes.FETCH_CARD_LIST_LOADING]: fetchCardListLoading,
  [CardTypes.FETCH_CARD_LIST_LOADING_MORE]: fetchCardListLoadingMore,
  [CardTypes.FETCH_CARD_LIST_SUCCESS]: fetchCardListSuccess,
  [CardTypes.FETCH_CARD_LIST_FAIL]: fetchCardListFail,
  [CardTypes.DELETE_CARD_LOADING]: deleteCardLoading,
  [CardTypes.DELETE_CARD_SUCCESS]: deleteCardSuccess,
  [CardTypes.DELETE_CARD_FAIL]: deleteCardFail,
  [CardTypes.UPDATE_CARD_LOADING]: updateCardLoading,
  [CardTypes.UPDATE_CARD_SUCCESS]: updateCardSuccess,
  [CardTypes.UPDATE_CARD_FAIL]: updateCardFail,
  [CardTypes.CLEAR_CARDS]: clearCards,
  [CardTypes.SET_CARDS_FILTER]: setCardsFilter,
  [CardTypes.ADD_CARD_LOADING]: addCardLoading,
  [CardTypes.ADD_CARD_SUCCESS]: addCardSuccess,
  [CardTypes.ADD_CARD_FAIL]: addCardFail,
  [CardTypes.ADD_CARD_BULK_SUCCESS]: addCardBulkSuccess,
  [CardTypes.RESET_CARD_REQUEST_STATES]: resetCardRequestStates,
  [CardTypes.FETCH_CARD_LOADING]: fetchCardLoading,
  [CardTypes.FETCH_CARD_SUCCESS]: fetchCardSuccess,
  [CardTypes.FETCH_CARD_FAIL]: fetchCardFail,
  [CardTypes.ADD_CARD_ACCESS_GROUPS_LOADING]: addCardAccessGroupsLoading,
  [CardTypes.ADD_CARD_ACCESS_GROUPS_SUCCESS]: addCardAccessGroupsSuccess,
  [CardTypes.ADD_CARD_ACCESS_GROUPS_FAIL]: addCardAccessGroupsFail,
  [CardTypes.DELETE_CARD_ACCESS_GROUPS_LOADING]: deleteCardAccessGroupsLoading,
  [CardTypes.DELETE_CARD_ACCESS_GROUPS_SUCCESS]: deleteCardAccessGroupsSuccess,
  [CardTypes.DELETE_CARD_ACCESS_GROUPS_FAIL]: deleteCardAccessGroupsFail,
  [CardTypes.ADD_CARD_SUBSCRIPTIONS_LOADING]: addCardSubscriptionsLoading,
  [CardTypes.ADD_CARD_SUBSCRIPTIONS_SUCCESS]: addCardSubscriptionsSuccess,
  [CardTypes.ADD_CARD_SUBSCRIPTIONS_FAIL]: addCardSubscriptionsFail,
  [CardTypes.DELETE_CARD_SUBSCRIPTIONS_LOADING]: deleteCardSubscriptionsLoading,
  [CardTypes.DELETE_CARD_SUBSCRIPTIONS_SUCCESS]: deleteCardSubscriptionsSuccess,
  [CardTypes.DELETE_CARD_SUBSCRIPTIONS_FAIL]: deleteCardSubscriptionsFail,
  [CardTypes.FETCH_CARD_TRANSACTIONS_LOADING]: fetchCardTransactionsLoading,
  [CardTypes.FETCH_CARD_TRANSACTIONS_LOADING_MORE]:
    fetchCardTransactionsLoadingMore,
  [CardTypes.FETCH_CARD_TRANSACTIONS_SUCCESS]: fetchCardTransactionsSuccess,
  [CardTypes.FETCH_CARD_TRANSACTIONS_FAIL]: fetchCardTransactionsFail,
  [CardTypes.SET_CARD_TRANSACTIONS_FILTER]: setCardTransactionsFilter,
  [CardTypes.SET_CARD_SELECTED_INDEX]: setCardSelectedIndex,
  [CardTypes.CLEAR_CARD_TRANSACTIONS]: clearCardTransactions,
  [CardTypes.RESET_CARD_STATE]: resetCardState,
  [CardTypes.FETCH_CARD_LOADING]: fetchCardLoading,
  [CardTypes.FETCH_CARD_SUCCESS]: fetchCardSuccess,
  [CardTypes.FETCH_CARD_FAIL]: fetchCardFail,
  [CardTypes.SET_PAGINATION_OFFSET]: setPaginationOffset,
  [CardTypes.SET_CARDS_TRANSACTION_PAGINATION_OFFSET]:
    setCardsTransactionPaginationOffset,
  [CardTypes.FETCH_CARD_LIST_COUNT_LOADING]: fetchCardListCountLoading,
  [CardTypes.FETCH_CARD_LIST_COUNT_SUCCESS]: fetchCardListCountSuccess,
  [CardTypes.FETCH_CARD_LIST_COUNT_FAIL]: fetchCardListCountFail,
  [CardTypes.FETCH_LOCAL_LIST_FILTERED_CARDS_LOADING]:
    fetchLocalListFilteredCardsLoading,
  [CardTypes.FETCH_LOCAL_LIST_FILTERED_CARDS_SUCCESS]:
    fetchLocalListFilteredCardsSuccess,
  [CardTypes.FETCH_LOCAL_LIST_FILTERED_CARDS_FAIL]:
    fetchLocalListFilteredCardsFail,
  [CardTypes.CLEAR_LOCAL_LIST_FILTERED_CARDS]: clearLocalListFilteredCards,
})
