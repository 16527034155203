import React, { forwardRef, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SubscriptionList from '../SubscriptionList/SubscriptionList'
import TenantUserActions from 'Stores/TenantUser/Actions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'

const SubscriptionModalContainer = forwardRef(
  (
    {
      isEditing,
      subscriptionsList,
      maxListHeight,
      scrollbarsHeight,
      onModalSubmit = () => {},
      removeExtraMargin,
      fetchSubscriptionsList,
    },
    ref,
  ) => {
    const onSubmit = (addSubscriptions, deleteSubscriptions) => {
      onModalSubmit(addSubscriptions)
    }
    const defaultSubscriptionsList = subscriptionsList
      .filter((sub) => {
        return sub.isDefault === true
      })
      .map((sub) => {
        return {
          subscription: {
            _id: sub._id,
            name: sub.name,
            tariff: sub.tariff,
            power: undefined,
            isDefault: sub.isDefault,
          },
          expiryDate: sub.expiresAt,
          consumedPower: sub.consumedPower,
        }
      })

    useEffect(() => {
      fetchSubscriptionsList()
    }, [])

    return (
      <div style={{ height: '100%' }}>
        <SubscriptionList
          ref={ref}
          displayedList={defaultSubscriptionsList}
          dropdownList={subscriptionsList}
          isEditing={isEditing}
          isLoading={false}
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={onSubmit}
          removeExtraMargin={removeExtraMargin}
        />
      </div>
    )
  },
)
function mapDispatchToProps(dispatch) {
  return {
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),
  }
}

const mapStateToProps = (state) => ({
  subscriptionsList: TenantUserSelectors.getSubscriptionsList(state),
})

SubscriptionModalContainer.propTypes = {
  isEditing: PropTypes.bool,
  subscriptionsList: PropTypes.array,
  subscriptionsListRef: PropTypes.object,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
  onModalSubmit: PropTypes.func,
  fetchSubscriptionsList: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SubscriptionModalContainer)
