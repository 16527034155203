import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  CustomScrollbar,
  ChargingProfileListItem,
  ChargingProfileListItemSkeleton,
} from 'Components'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import { Divider } from '@material-ui/core'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { Virtuoso } from 'react-virtuoso'
import Styles from './ChargingProfileList.module.css'

TimeAgo.addDefaultLocale(en)

const ChargingProfileList = ({
  chargingProfiles = [],
  fetchChargePointChargingProfiles,
  fetchChargePointChargingProfilesRequestState,
  clearChargePointChargingProfile,
  clearChargePointChargingProfileRequestState,
  chargePoint,
  connectorIndex,
}) => {
  useEffect(() => {
    if (chargePoint)
      fetchChargePointChargingProfiles(
        chargePoint._id,
        chargePoint.connectors[connectorIndex].uid,
      )
  }, [chargePoint?._id, connectorIndex])

  return (
    <div className={Styles.Container}>
      <div className={Styles.Container}>
        {fetchChargePointChargingProfilesRequestState ===
        RequestState.LOADING ? (
          [...Array(3)].map((e, index) => (
            <div
              className={Styles.SkeletonContainer}
              key={`ChargingProfileListItemSkeleton ${index}`}
            >
              <ChargingProfileListItemSkeleton key={index} />
              <Divider />
            </div>
          ))
        ) : (
          <Virtuoso
            data={chargingProfiles}
            increaseViewportBy={480}
            itemContent={(index, profile) => {
              return (
                <div
                  key={`ChargingProfileListItem ${index}`}
                  className={Styles.ItemContainer}
                >
                  <ChargingProfileListItem
                    name={profile.name}
                    maxCurrent={profile.maxCurrent}
                    chargingSchedulePeriods={
                      profile.profile.chargingSchedule.chargingSchedulePeriod
                    }
                    clearChargingProfile={() => {
                      clearChargePointChargingProfile(
                        chargePoint._id,
                        profile.profile.uid,
                        null,
                        null,
                        null,
                      )
                    }}
                    clearChargePointChargingProfileRequestState={
                      clearChargePointChargingProfileRequestState
                    }
                    hideIndividualAxis={true}
                  />
                  <div className={Styles.DividerContainer}>
                    <Divider className={Styles.Divider} />
                  </div>
                </div>
              )
            }}
            components={{ Scroller: CustomScrollbar }}
          />
        )}
      </div>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointChargingProfiles: (chargePointId, connectorId) =>
      dispatch(
        ChargePointActions.fetchChargePointChargingProfiles(
          chargePointId,
          connectorId,
        ),
      ),
    clearChargePointChargingProfile: (
      chargePointId,
      chargingProfileId,
      connectorId,
      stackLevel,
      chargingProfilePurpose,
    ) =>
      dispatch(
        ChargePointActions.clearChargePointChargingProfile(
          chargePointId,
          chargingProfileId,
          connectorId,
          stackLevel,
          chargingProfilePurpose,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargingProfiles: ChargePointSelectors.getChargingProfiles(state),
  fetchChargePointChargingProfilesRequestState:
    ChargePointSelectors.getFetchChargePointChargingProfilesRequestState(state),
  clearChargePointChargingProfileRequestState:
    ChargePointSelectors.getClearChargePointChargingProfileRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
})

ChargingProfileList.propTypes = {
  chargingProfiles: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointChargingProfiles: PropTypes.func,
  fetchChargePointChargingProfilesRequestState: PropTypes.number,
  clearChargePointChargingProfile: PropTypes.func,
  clearChargePointChargingProfileRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  connectorId: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingProfileList)
