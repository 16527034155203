import DefaultRouter from 'Navigators/DefaultRouter/DefaultRouter'
import LoginRouter from 'Navigators/LoginRouter/LoginRouter'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'

function AppBrowserRouter({ isLoggedIn }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth/*"
          element={isLoggedIn ? <Navigate to="/" /> : <LoginRouter />}
        />
        <Route
          path="/*"
          element={
            isLoggedIn ? <DefaultRouter /> : <Navigate to="/auth/login" />
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  isLoggedIn: AuthSelectors.isLoggedIn(state),
})

export default connect(mapStateToProps)(AppBrowserRouter)
