import { IFSkeleton } from 'Components'
import React from 'react'
import Styles from './StationListItemSkeleton.module.css'

const StationListItemSkeleton = () => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.MarkerContainer}>
        <IFSkeleton variant="circular" width="2rem" height="2rem" />
      </div>
      <div className={Styles.RightDetailsContainer}>
        <div className={Styles.LastUpdatedContainer}>
          <IFSkeleton variant="text" width="2rem" height="1rem" />
        </div>
        <div className={Styles.StationNameContainer}>
          <IFSkeleton variant="text" width="10rem" height="2rem" />
        </div>
        <div className={Styles.BottomContainer}>
          <div className={Styles.ConnectorsContainer}>
            <IFSkeleton variant="text" width="5rem" height="1.25rem" />
          </div>
          <div>
            <IFSkeleton variant="text" width="5rem" height="1.25rem" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StationListItemSkeleton
