import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import TenantUserActions from 'Stores/TenantUser/Actions'
import { calculateConnectorsHeight } from 'Utils/ConnectorFunctions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import ScheduleList from '../ScheduleList/ScheduleList'

const ChargePointSchedule = forwardRef(
  (
    {
      chargePoint,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointRequestState,
      isEditing,
      maxListHeight,
      chargePointPrivacySchedule,
      fetchChargePointPrivacyScheduleRequestState,
      fetchChargePointPrivacyScheduleList,
      privacySchedule,
      fetchPrivacySchedule,
      deleteChargePointPrivacyRules,
      addChargePointPrivacyRules,
      chargePointUid,
    },
    ref,
  ) => {
    const scheduleListRef = useRef()

    useEffect(() => {
      if (
        (chargePoint && !isEditing) ||
        (chargePoint && chargePoint.isActivated === false)
      ) {
        fetchChargePointPrivacyScheduleList(chargePoint._id)
        fetchPrivacySchedule()
      }
    }, [chargePointUid])

    const handleRefresh = () => {
      fetchChargePointPrivacyScheduleList(chargePoint._id)
      fetchPrivacySchedule()
    }

    const onSubmit = (addPrivacyRules, deletePrivacyRules) => {
      if (deletePrivacyRules.length > 0)
        deleteChargePointPrivacyRules(chargePoint._id, deletePrivacyRules)
      if (addPrivacyRules.length > 0)
        addChargePointPrivacyRules(chargePoint._id, addPrivacyRules)
    }

    const handleSubmit = (resetValues) => {
      scheduleListRef?.current?.handleSubmit(resetValues)
    }
    const handleCancel = () => {
      scheduleListRef?.current?.handleCancel()
    }
    const didChange = () => {
      return scheduleListRef?.current?.didChange()
    }
    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleRefresh,
      handleCancel,
      didChange,
    }))

    const isLoading = () => {
      return (
        fetchStationListRequestState === RequestState.LOADING ||
        fetchChargePointListRequestState === RequestState.LOADING ||
        fetchChargePointRequestState === RequestState.LOADING ||
        fetchChargePointPrivacyScheduleRequestState === RequestState.LOADING
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: isEditing && !isLoading() ? 'unset' : 'hidden',
        }}
      >
        <ScheduleList
          ref={scheduleListRef}
          displayedList={chargePointPrivacySchedule}
          dropdownList={privacySchedule}
          isEditing={isEditing}
          isLoading={isLoading()}
          maxListHeight={maxListHeight}
          scrollbarsHeight={
            isLoading()
              ? '68px'
              : calculateConnectorsHeight(chargePoint?.connectors)
          }
          onSubmit={onSubmit}
          isEmpty={
            privacySchedule.length === 0 &&
            fetchChargePointPrivacyScheduleRequestState ===
              RequestState.SUCCEEDED &&
            fetchChargePointRequestState !== RequestState.LOADING
          }
        />
      </div>
    )
  },
)
function mapDispatchToProps(dispatch) {
  return {
    fetchPrivacySchedule: () =>
      dispatch(TenantUserActions.fetchPrivacySchedule()),
    deleteChargePointPrivacyRules: (chargePointId, privacyRules) =>
      dispatch(
        ChargePointActions.deleteChargePointPrivacyRules(
          chargePointId,
          privacyRules,
        ),
      ),
    addChargePointPrivacyRules: (chargePointId, privacyRules) =>
      dispatch(
        ChargePointActions.addChargePointPrivacyRules(
          chargePointId,
          privacyRules,
        ),
      ),

    fetchChargePointPrivacyScheduleList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointPrivacyScheduleList(chargePointId),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargePointPrivacySchedule:
    ChargePointSelectors.getChargePointPrivacySchedule(state),
  fetchChargePointPrivacyScheduleRequestState:
    ChargePointSelectors.getFetchChargePointPrivacyScheduleRequestState(state),
  privacySchedule: TenantUserSelectors.getPrivacySchedule(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
})

ChargePointSchedule.propTypes = {
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  fetchChargePointRequestState: PropTypes.number,
  isEditing: PropTypes.bool,
  fetchSubscriptionsList: PropTypes.func,
  chargePointSubscriptions: PropTypes.array,
  fetchChargePointSubscriptionsRequestState: PropTypes.number,
  subscriptionsList: PropTypes.array,
  deleteChargePointSubscriptions: PropTypes.func,
  addChargePointSubscriptions: PropTypes.func,
  subscriptionsListRef: PropTypes.object,
  maxListHeight: PropTypes.any,
  chargePointUid: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargePointSchedule)
