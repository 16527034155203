import { React, Fragment } from 'react'
import styles from './IFButton.module.css'
import styled from 'styled-components'
import { SpinnerCircularFixed } from 'spinners-react'
import PropTypes from 'prop-types'
import IFText from 'Components/IFText/IFText'
import colors from 'Theme/Colors'
import IFButtonSkeleton from './IFButtonSkeleton'

const StyledDiv = styled.div`
  &:hover {
    color: whitesmoke;
  }
`

const AliveButton = styled.button`
  &:hover {
    color: ${(props) => (props.isFill ? colors.white : colors.white)};
    background: ${(props) => (props.isFill ? props.color : props.color)};
    ${(props) => props.isFill && `opacity: 0.9;`};
  }
  &:before {
    background: ${(props) => props.color};
  }
  -webkit-transition-duration: 0.1s !important;
  transition-duration: 0.1s !important;
  color: ${(props) =>
    props.isFill || props.isLoading ? 'whitesmoke' : props.color};
  border: 1px solid ${(props) => props.color};
  background: ${(props) =>
    props.isFill || props.isLoading ? props.color : colors.transparent};
`

const DeadButton = styled.button`
  background: ${colors.deadButton};
  cursor: not-allowed;
  border: 2px ${colors.deadButton} solid;
  color: ${colors.whiteSmoke};
`

const IFButton = ({
  color,
  isFill,
  isDead,
  isLoading,
  loadSkeleton,
  icon,
  text,
  onClick,
  size,
  className,
}) => {
  const Button = isDead ? DeadButton : AliveButton

  return (
    <div className={className}>
      {loadSkeleton ? (
        <IFButtonSkeleton size={size} />
      ) : (
        <Button
          style={
            size === 'micro'
              ? {
                  width: '2.5rem',
                  height: '1.875rem',
                  fontSize: '0.875rem',
                  fontWeight: '400',
                }
              : size === 'xsm'
              ? {
                  width: '7rem',
                  height: '2rem',
                  fontSize: '0.875rem',
                  fontWeight: '400',
                }
              : size === 'sm'
              ? {
                  width: '9rem',
                  height: '2.5rem',
                  fontSize: '0.875rem',
                  fontWeight: '400',
                }
              : {
                  width: '12rem',
                  height: '3rem',
                  fontSize: '1rem',
                  fontWeight: '500',
                }
          }
          color={color}
          isFill={isFill}
          isLoading={isLoading}
          onClick={onClick}
          disabled={isDead ? true : false}
          className={
            (isDead
              ? styles.disabled
              : isFill && !isLoading
              ? styles.filled
              : isLoading
              ? null
              : styles.fill,
            styles.IFButton)
          }
        >
          {isLoading ? (
            <SpinnerCircularFixed
              color={'rgba(256,256,256,1)'}
              secondaryColor={'rgba(256,256,256,0.3)'}
              thickness={200}
              size={25}
            />
          ) : icon ? (
            <Fragment>
              <StyledDiv className={styles.IconContainer}>{icon}</StyledDiv>

              <IFText>&nbsp;&nbsp;{text}</IFText>
            </Fragment>
          ) : (
            <IFText>{text}</IFText>
          )}
        </Button>
      )}
    </div>
  )
}

IFButton.propTypes = {
  color: PropTypes.string.isRequired,
  isFill: PropTypes.bool,
  isDead: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  size: PropTypes.string.isRequired,
}

export default IFButton
