import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  ChargingProfileListItem,
  IFModal,
  IFButton,
  IFText,
  ChargingProfileTabs,
  IFSkeleton,
  IFsvg,
} from 'Components'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import Styles from './ChargingProfileModal.module.css'
import { Colors } from 'Theme'
import { DateRange, Send } from '@mui/icons-material'
import ChargingScheduleModal from 'Containers/ChargingScheduleModal/ChargingScheduleModal'
import ChargingProfileList from 'Containers/ChargingProfileList/ChargingProfileList'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import { useTranslation } from 'react-i18next'
import { fillChargingSchedulePeriodDays } from 'Utils/Format'
import moment from 'moment'

TimeAgo.addDefaultLocale(en)
const weekToSecs = 604800

const ChargingProfileModal = React.forwardRef(
  (
    {
      maxCurrent = 500,
      chargePoint,
      fetchChargePointCompositeSchedule,
      compositeSchedule,
      fetchChargePointCompositeScheduleRequestState,
    },
    ref,
  ) => {
    const [connectorIndex, setConnectorIndex] = useState(0)
    const [lastUpdated, setLastUpdated] = useState('')
    const ChargingScheduleListRef = useRef()
    const { t } = useTranslation()
    useEffect(() => {
      if (chargePoint)
        fetchChargePointCompositeSchedule(
          chargePoint._id,
          chargePoint.connectors[connectorIndex].uid,
          weekToSecs,
          'A',
        )
    }, [connectorIndex])
    useEffect(() => {
      if (
        fetchChargePointCompositeScheduleRequestState === RequestState.SUCCEEDED
      ) {
        setLastUpdated(Date.now())
      }
    }, [fetchChargePointCompositeScheduleRequestState])

    return (
      <IFModal
        width={'50rem'}
        title={t('ChargingProfileModal.Title')}
        ref={ref}
        height={'40rem'}
      >
        <div className={Styles.Wrapper}>
          <ChargingProfileTabs
            connectors={chargePoint?.connectors}
            initialIndex={connectorIndex}
            onValueChange={(value) => {
              setConnectorIndex(value)
            }}
          />
          <div className={Styles.CompositeSchedule}>
            <div className={Styles.CompositeScheduleTitleContainer}>
              <IFText>{t('ChargingProfileModal.CompositeSchedule')}</IFText>
              {fetchChargePointCompositeScheduleRequestState ===
              RequestState.LOADING ? (
                <div className={Styles.RefreshIconTextContainer}>
                  <div>
                    <div className={Styles.RefreshIconTextSkeleton}>
                      <div className={Styles.RefreshIconSkeleton}>
                        <IFSkeleton
                          width="1rem"
                          height="1rem"
                          variant="circular"
                        />
                      </div>
                      <IFSkeleton
                        height="1.5rem"
                        className={Styles.RefreshTextSkeleton}
                      />
                    </div>

                    <IFSkeleton
                      height="1.25rem"
                      className={Styles.RefreshTextDateSkeleton}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.RefreshIconTextContainer}>
                  <div className={Styles.RefreshTextContainer}>
                    {fetchChargePointCompositeSchedule ===
                    RequestState.LOADING ? (
                      <div>
                        <div className={Styles.RefreshIconTextSkeleton}>
                          <div className={Styles.RefreshIconSkeleton}>
                            <IFSkeleton
                              width="1rem"
                              height="1rem"
                              variant="circular"
                            />
                          </div>
                          <IFSkeleton
                            height="1.5rem"
                            className={Styles.RefreshTextSkeleton}
                          />
                        </div>

                        <IFSkeleton
                          height="1.25rem"
                          className={Styles.RefreshTextDateSkeleton}
                        />
                      </div>
                    ) : (
                      <>
                        <div className={Styles.LiveLogs}>
                          <div
                            className={Styles.RefreshIcon}
                            onClick={() => {
                              fetchChargePointCompositeSchedule(
                                chargePoint._id,
                                chargePoint.connectors[connectorIndex].uid,
                                weekToSecs,
                                'A',
                              )
                            }}
                          >
                            <div className={Styles.RefreshSvg}>
                              <IFsvg.refresh
                                height={16}
                                width={16}
                                fill={Colors.black}
                              />
                            </div>
                            <IFText
                              className={Styles.RefreshText}
                              style={{ color: Colors.black }}
                            >
                              {t('ChargePointDetails.lastUpdated')}
                            </IFText>
                          </div>
                        </div>
                        <IFText className={Styles.RefreshTextDate}>
                          {lastUpdated
                            ? moment(lastUpdated).format(
                                'MMM DD, YYYY HH:mm:ss',
                              )
                            : ''}
                        </IFText>
                      </>
                    )}
                  </div>

                  {fetchChargePointCompositeScheduleRequestState ===
                  RequestState.LOADING ? (
                    <div className={Styles.LiveLogsToggleSkeleton}>
                      <IFSkeleton
                        width="4rem"
                        height="2rem"
                        variant="text"
                        style={{ alignSelf: 'center' }}
                      />
                    </div>
                  ) : (
                    <div className={Styles.LiveLogsToggle}></div>
                  )}
                </div>
              )}
            </div>
            {fetchChargePointCompositeScheduleRequestState ===
            RequestState.LOADING ? (
              [...Array(3)].map(() => (
                <div className={Styles.Skeletons}>
                  <IFSkeleton
                    width="3rem"
                    height="1.5rem"
                    style={{ marginRight: 16 }}
                  />
                  <IFSkeleton
                    width="100%"
                    height="1rem"
                    style={{ alignSelf: 'center' }}
                  />
                </div>
              ))
            ) : (compositeSchedule?.chargingSchedulePeriod?.length === 1 &&
                compositeSchedule?.chargingSchedulePeriod[0].limit === 0 &&
                fetchChargePointCompositeScheduleRequestState !==
                  RequestState.LOADING) ||
              !compositeSchedule?.chargingSchedulePeriod ? (
              <EmptySpecs
                title={t('ChargingProfileModal.NoCompositeSchedule')}
              />
            ) : (
              <ChargingProfileListItem
                useAccordion={false}
                chargingSchedulePeriods={fillChargingSchedulePeriodDays(
                  compositeSchedule.duration,
                  compositeSchedule.chargingSchedulePeriod,
                )}
                chargingSchedulePeriodsOffset={
                  compositeSchedule.startSchedule
                    ? moment(compositeSchedule.startSchedule).day()
                    : 0
                }
                hideIndividualAxis={true}
              />
            )}
          </div>

          <ChargingProfileList connectorIndex={connectorIndex} />
          <IFButton
            text={t('ChargingProfileModal.CreateProfile')}
            icon={<DateRange style={{ fontSize: '18px' }} />}
            color={Colors.primary}
            isFill={true}
            className={Styles.Button}
            size={'sm'}
            onClick={() => ChargingScheduleListRef.current.handleOpen()}
          />
        </div>
        <ChargingScheduleModal
          ref={ChargingScheduleListRef}
          connectorIndex={connectorIndex}
        />
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointCompositeSchedule: (
      chargePointId,
      connectorId,
      duration,
      chargingRateUnitType,
    ) =>
      dispatch(
        ChargePointActions.fetchChargePointCompositeSchedule(
          chargePointId,
          connectorId,
          duration,
          chargingRateUnitType,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  compositeSchedule: ChargePointSelectors.getCompositeSchedule(state),
  fetchChargePointCompositeScheduleRequestState:
    ChargePointSelectors.getFetchChargePointCompositeScheduleRequestState(
      state,
    ),
  clearChargePointChargingProfileRequestState:
    ChargePointSelectors.getClearChargePointChargingProfileRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargingProfileModal)
