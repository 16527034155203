import { IFText, IFButton } from 'Components'
import PropTypes from 'prop-types'
import Styles from './InviteTenantUserListItem.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useState, useImperativeHandle } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const PenIcon = styled.div`
color: ${Colors.TenantUserListPenIcon};
  }
`

const InviteTenantUserListItem = React.forwardRef(
  (
    {
      tenantUser,
      avatar,
      permissions,
      onDeleteClick,
      isDeleteLoading,
      onResendClick,
      isResendLoading,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const [accordExpanded, setAccordExpanded] = useState(false)

    const collapse = () => {
      setAccordExpanded(false)
    }

    useImperativeHandle(ref, () => ({
      collapse,
    }))

    const getPermissionPlaceholder = () => {
      let result = ''
      if (permissions[0] && permissions[1])
        result = `${permissions[0]} + ${permissions.length - 1}`
      else if (permissions[0]) result = permissions[0]
      else result = t('TenantUserListItem.NoPermissionsSelected')
      return result
    }

    const onClickAccordionHandler = () => {
      setAccordExpanded((expanded) => {
        return !expanded
      })
    }

    const useStyles = makeStyles({
      root: {
        height: '5rem',
        width: '12rem',
      },
      accordion: {
        boxShadow: '0px 0px 0px 0px' + Colors.ChargingTransactionListItemShadow,
        paddingRight: '1rem',
        paddingLeft: '1rem',
        '&:hover': {
          backgroundColor: Colors.ChargePointLogListItemHover,
        },
      },
    })

    const Classes = useStyles()

    return (
      <div className={Styles.Container}>
        <Accordion
          onClick={onClickAccordionHandler}
          expanded={accordExpanded}
          className={Classes.accordion}
        >
          <AccordionSummary>
            <div className={Styles.ListItemSummary}>
              <div className={Styles.AvatarNameEmail}>
                <div className={Styles.AvatarIcon}>{avatar}</div>
                <div className={Styles.NameEmail}>
                  <IFText className={Styles.Name}>{tenantUser.email}</IFText>
                  <IFText
                    className={Styles.Email}
                    style={{ color: Colors.TenantUserListItemEmail }}
                  >
                    {t('InviteTenantUserListItem.InvitationSent')}
                  </IFText>
                </div>
              </div>

              <div
                className={
                  accordExpanded
                    ? Styles.PermissionsContainerExpanded
                    : Styles.PermissionsContainer
                }
              >
                <IFText
                  style={{ backgroundColor: Colors.GreyBackground }}
                  className={Styles.Role}
                >
                  {getPermissionPlaceholder()}
                </IFText>
              </div>

              {!accordExpanded ? (
                <PenIcon className={Styles.PenIcon}>
                  <FontAwesomeIcon icon={faPen} />
                </PenIcon>
              ) : null}
            </div>
          </AccordionSummary>

          <AccordionDetails
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className={Styles.ListItemDetails}>
              <IFButton
                color={Colors.red}
                text={t('InviteTenantUserListItem.Delete')}
                isLoading={isDeleteLoading}
                size="xsm"
                isFill={false}
                className={Styles.DeleteButton}
                onClick={onDeleteClick}
              />

              <IFButton
                color={Colors.primary}
                text={t('InviteTenantUserListItem.Resend')}
                isLoading={isResendLoading}
                size="xsm"
                isFill={true}
                className={Styles.ResendButton}
                onClick={onResendClick}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  },
)

InviteTenantUserListItem.propTypes = {
  tenantUser: PropTypes.object,
  avatar: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
  onResendClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDeleteLoading: PropTypes.bool,
  isResendLoading: PropTypes.bool,
}

export default InviteTenantUserListItem
