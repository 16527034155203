import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import UserSelectors from 'Stores/User/Selectors'
import UserActions from 'Stores/User/Actions'
import RequestState from 'Enums/RequestState'
import Styles from './UserTransactionList.module.css'
import { Waypoint } from 'react-waypoint'
import {
  TransactionListItem,
  TransactionListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import { EmptyLogs } from 'Components'
import { NoLogsFilter } from 'Components'
import { Virtuoso } from 'react-virtuoso'
import { handleMaxPower } from 'Utils/ConnectorFunctions'

const UserTransactionList = ({
  fetchUserTransactions,
  userTransactions,
  fetchUserTransactionsRequestState,
  userTransactionsFilter,
  paginationOffset,
  users,
  selectedUserIndex,
  fetchUserListRequestState,
  ascending,
  selectedUser,
  fetchUserDetailsRequestState,
}) => {
  const [empty, setEmpty] = useState(true)

  const Footer = () => {
    return paginationOffset &&
      userTransactions &&
      userTransactions.length !== 0 ? (
      <div className={Styles.WaypointContainer}>
        <Waypoint onEnter={loadMoreData} />
        <TransactionListItemSkeleton />
        <TransactionListItemSkeleton />
      </div>
    ) : null
  }

  const loadMoreData = () => {
    if (fetchUserTransactionsRequestState === RequestState.LOADING_MORE) return
    fetchUserTransactions(
      userTransactionsFilter,
      paginationOffset,
      users[selectedUserIndex]._id,
      ascending,
    )
  }

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }

  useEffect(() => {
    checkFilterEmpty(userTransactionsFilter)
  }, [userTransactionsFilter])

  useEffect(() => {
    if (users.length !== 0 && selectedUser && selectedUser?.appUserId)
      fetchUserTransactions(
        userTransactionsFilter,
        paginationOffset,
        users[selectedUserIndex]._id,
        ascending,
      )
  }, [selectedUser, users])
  return (
    <div
      className={Styles.container}
      {...(userTransactions.length === 0
        ? { style: { width: '100%', height: '100%' } }
        : {})}
    >
      {(fetchUserTransactionsRequestState === RequestState.LOADING &&
        paginationOffset === 0) ||
      fetchUserListRequestState === RequestState.LOADING ||
      fetchUserDetailsRequestState === RequestState.LOADING ||
      (selectedUser?.isInvitedUser && !selectedUser?.appUserId) ? (
        [...Array(15)].map((e, index) => (
          <TransactionListItemSkeleton key={index} />
        ))
      ) : userTransactions.length === 0 ? (
        empty ? (
          <EmptyLogs />
        ) : (
          <NoLogsFilter />
        )
      ) : (
        <Virtuoso
          data={userTransactions}
          endReached={
            paginationOffset &&
            userTransactions &&
            userTransactions.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, userTransaction) => {
            const connectorIndex =
              userTransaction.chargePoint[0].connectors.findIndex(
                (conn) => conn.uid === userTransaction.connectorUid,
              )
            return (
              <div key={index}>
                <TransactionListItem
                  stationName={userTransaction.station[0].name}
                  chargePointName={userTransaction.chargePoint[0].name}
                  chargePointId={userTransaction.chargePoint[0]._id}
                  stationId={userTransaction.station[0]._id}
                  energyConsumption={userTransaction.energyConsumption}
                  amount={userTransaction.amount}
                  processedAt={userTransaction.processedAt}
                  endedAt={userTransaction.endedAt}
                  startedAt={userTransaction.startedAt}
                  connectorUid={userTransaction.connectorUid}
                  connectorType={
                    userTransaction.chargePoint[0].connectors[connectorIndex]
                      ?.type
                  }
                  tariff={userTransaction?.subscription?.tariff}
                  subscription={userTransaction?.subscription?.name}
                  chargingLevel={handleMaxPower(
                    userTransaction.chargePoint[0].connectors[connectorIndex]
                      ?.maxPower,
                  )}
                  status={userTransaction.status}
                  startingChargingTokenType={
                    userTransaction?.startingChargingTokenDetails[0]?.type
                  }
                />
              </div>
            )
          }}
          components={{ Footer: Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserTransactions: (filter, offset, userId, ascending) =>
      dispatch(
        UserActions.fetchUserTransactions(filter, offset, userId, ascending),
      ),
  }
}

const mapStateToProps = (state) => ({
  userTransactions: UserSelectors.getUserTransactions(state),
  fetchUserTransactionsRequestState:
    UserSelectors.getFetchUserTransactionsRequestState(state),
  userTransactionsFilter: UserSelectors.getUserTransactionsFilter(state),
  paginationOffset: UserSelectors.getUserTransactionsPaginationOffset(state),
  users: UserSelectors.getUserList(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  selectedUser: UserSelectors.getSelectedUser(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
})

UserTransactionList.propTypes = {
  userTransactions: PropTypes.arrayOf(PropTypes.object),
  fetchUserTransactionsRequestState: PropTypes.number,
  paginationOffset: PropTypes.number,
  fetchUserTransactions: PropTypes.func,
  userTransactionsFilter: PropTypes.string,
  users: PropTypes.array,
  selectedUserIndex: PropTypes.number,
  fetchUserListRequestState: PropTypes.number,
  ascending: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTransactionList)
