import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { AuthTypes } from './Actions'
import RequestState from 'Enums/RequestState'

export const tenantUserLoginLoading = (state) => {
  return {
    ...state,
    tenantUserLoading: true,
  }
}

export const tenantUserLoginSuccess = (
  state,
  { token, refreshToken, tenantUser },
) => {
  const tenant = Object.assign({}, tenantUser.tenant)
  tenantUser.tenant = tenant._id
  return {
    ...state,
    refreshToken: refreshToken,
    token: token,
    tenant: tenant,
    tenantUser: tenantUser,
  }
}

export const tenantUserLoginFail = (state) => {
  return {
    ...state,
    token: null,
    refreshToken: null,
    tenantUser: null,
    tenantUserLoading: false,
  }
}

export const tenantUserLoginResetLoading = (state) => {
  return {
    ...state,
    tenantUserLoading: false,
  }
}

export const fetchTenantUserLoading = (state) => {
  return {
    ...state,
    fetchTenantUserRequestState: RequestState.LOADING,
  }
}

export const fetchTenantUserSuccess = (state, { tenantUser }) => {
  return {
    ...state,
    tenantUser: tenantUser,
  }
}

export const fetchTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    tenantUser: null,
    fetchTenantUserRequestState: requestState,
  }
}

export const setAuthToken = (state, { token }) => {
  return {
    ...state,
    token: token,
  }
}

export const resetPassLoading = (state) => {
  return {
    ...state,
    resetPasswordRequestState: RequestState.LOADING,
  }
}

export const resetPassSuccess = (state) => {
  return {
    ...state,
    resetPasswordRequestState: RequestState.SUCCEEDED,
  }
}

export const resetPassFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }

  return {
    ...state,
    resetPasswordRequestState: requestState,
  }
}

export const resetPassVerificationLoading = (state) => {
  return {
    ...state,
    resetPassVerificationRequestState: RequestState.LOADING,
  }
}

export const resetPassVerificationSuccess = (state, { emailID }) => {
  return {
    ...state,
    resetPassVerificationRequestState: RequestState.SUCCEEDED,
    resetPassEmailID: emailID,
  }
}

export const resetPassVerificationFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }

  return {
    ...state,
    resetPassVerificationRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.TENANT_USER_LOGIN_LOADING]: tenantUserLoginLoading,
  [AuthTypes.TENANT_USER_LOGIN_SUCCESS]: tenantUserLoginSuccess,
  [AuthTypes.TENANT_USER_LOGIN_FAIL]: tenantUserLoginFail,
  [AuthTypes.TENANT_USER_LOGIN_RESET_LOADING]: tenantUserLoginResetLoading,
  [AuthTypes.FETCH_TENANT_USER_LOADING]: fetchTenantUserLoading,
  [AuthTypes.FETCH_TENANT_USER_SUCCESS]: fetchTenantUserSuccess,
  [AuthTypes.FETCH_TENANT_USER_FAIL]: fetchTenantUserFail,
  [AuthTypes.SET_AUTH_TOKEN]: setAuthToken,
  [AuthTypes.RESET_PASS_LOADING]: resetPassLoading,
  [AuthTypes.RESET_PASS_FAIL]: resetPassFail,
  [AuthTypes.RESET_PASS_SUCCESS]: resetPassSuccess,
  [AuthTypes.RESET_PASS_VERIFICATION_LOADING]: resetPassVerificationLoading,
  [AuthTypes.RESET_PASS_VERIFICATION_SUCCESS]: resetPassVerificationSuccess,
  [AuthTypes.RESET_PASS_VERIFICATION_FAIL]: resetPassVerificationFail,
})
