import React, { useCallback, useState, useRef, useEffect } from 'react'
import Styles from './ChargePointLogsDownload.module.css'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { saveAs } from 'file-saver'
import { chargePointDataApiService } from 'Services/ApiService'
import CircularProgress from '@mui/material/CircularProgress'
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded'
import DoneIcon from '@mui/icons-material/Done'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { toaster } from 'rsuite'
import { IFsvg, IFToastMessage } from 'Components'
import { CREATED } from 'Constants/HttpStatus'
import moment from 'moment'
import { CloudDownloadOutlined } from '@mui/icons-material'
import IFTooltipIconsLoading from 'Components/IFTooltipIconsLoading/IFTooltipIconsLoading'
var base64 = require('base-64')

const ChargePointLogsDownload = ({ chargePointLogFilter, chargePoint }) => {
  const [getReport, setGetReport] = useState(false)

  const { t } = useTranslation()

  const getLogsReport = () => {
    setGetReport(true)
    let filter
    if (chargePointLogFilter != null) {
      filter = base64.encode(JSON.stringify(chargePointLogFilter))
    }
    chargePointDataApiService({
      url: `/${chargePoint._id}/logs/report`,
      method: 'GET',
      params: {
        filter: filter,
      },
      responseType: 'blob', // important
    })
      .then((res) => {
        if (res.headers['content-type'] === 'application/json') {
          const jsonBlob = new Blob([res.data], {
            type: 'application/json',
          })
          let currentTimestamp = moment().format('YYYY-MM-DDTHH꞉mm꞉ss') // The colon added here is a different character code from ':' as file names do not allow colons
          saveAs(jsonBlob, `${chargePoint.uid}_${currentTimestamp}.log.json`)
          setGetReport(false)
        }
      })
      .catch((err) => {
        setGetReport(false)
        toaster.push(
          <IFToastMessage
            type="error"
            text={err.message ? err.message : err.statusText}
          />,
          { placement: 'topEnd' },
        )
      })
  }

  const getLogsReportRef = useRef(getLogsReport)
  useEffect(() => {
    getLogsReportRef.current = getLogsReport
  })

  return (
    <div className={Styles.downloadIcon}>
      <IFTooltipIconsLoading
        onClick={() => getLogsReportRef.current()}
        tooltipPlacement={'left'}
        title={t('IFFilter.Download')}
        Icon={IFsvg.CloudDownload}
        FilledIcon={IFsvg.CloudDownloadFill}
        isLoading={getReport}
        size={26}
        iconClassname={Styles.iconContainer}
        animationDisabled={false}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  chargePointLogFilter: ChargePointSelectors.getChargePointLogFilter(state),
})

ChargePointLogsDownload.propTypes = {
  chargePoint: PropTypes.object,
  chargePointLogFilter: PropTypes.arrayOf(PropTypes.object),
}

export default connect(mapStateToProps)(ChargePointLogsDownload)
