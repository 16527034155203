import PropTypes from 'prop-types'
import Styles from './LocalListAdd.module.css'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { SelectPicker } from 'rsuite'
import AddIcon from '@mui/icons-material/Add'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StyledDiv = styled.div`
  &:hover {
    color: whitesmoke;
  }
`
const LocalListAdd = ({
  data = [],
  onClick = () => {},
  onSelect = () => {},
  RenderMenuItem,
  RenderMenuItemkeys = [],
  onSearch,
  isLoading,
  loadMoreData,
  itemSize = 50,
}) => {
  const [selectPickerPlacement, setSelectPickerPlacement] =
    useState('bottomStart')
  const options = []
  const selectPickerRef = useRef()
  const buttonRef = useRef()
  const { t } = useTranslation()

  data.forEach((element) => {
    options.push({
      label: element.name ? element.name : element.uid,
      value: element.name ? element._id : element.uid,
    })
  })

  const onItemsRendered = (props) => {
    if (props.visibleStopIndex >= data.length - 1) {
      loadMoreData()
    }
  }

  return (
    <div className={Styles.Container}>
      <button
        className={Styles.AddButton}
        style={{ color: Colors.primary }}
        onClick={() => {
          if (
            buttonRef.current.getBoundingClientRect().y + 286 >
            window.innerHeight
          ) {
            setSelectPickerPlacement('topStart')
          } else {
            setSelectPickerPlacement('bottomStart')
          }
          selectPickerRef.current.open()
          onClick()
        }}
        ref={buttonRef}
      >
        <StyledDiv
          className={Styles.IconContainer}
          style={{ color: Colors.primary }}
        >
          <AddIcon />
        </StyledDiv>
      </button>
      <SelectPicker
        searchable={true}
        style={{
          marginTop: selectPickerPlacement === 'bottomStart' ? '-42px' : '42px',
        }}
        ref={selectPickerRef}
        placement={selectPickerPlacement}
        className={Styles.SelectPicker}
        data={options}
        onSelect={(value) => {
          onSelect(value)
        }}
        virtualized
        value={null}
        disabledItemValues={['skeleton']}
        renderMenuItem={(value) => {
          let element = data.find((el) => {
            return el.uid === value || el.name === value
          })
          let values = {}
          if (element)
            RenderMenuItemkeys.forEach((key) => {
              values[key] = element[key]
            })
          return (
            <div style={{ padding: 0 }}>
              {element && (
                <RenderMenuItem
                  style={{ padding: 0 }}
                  key={`RenderMenuItem`}
                  {...values}
                />
              )}
            </div>
          )
        }}
        preventOverflow={true}
        menuStyle={{
          flexDirection:
            selectPickerPlacement === 'bottomStart'
              ? 'column'
              : 'column-reverse',

          zIndex: 10,
          maxWidth: '18.75rem',
          padding: 0,
          height: 250,
        }}
        loading={true}
        locale={{
          noResultsText: isLoading
            ? ''
            : t('ChargePointDetails.NoResultsFound'),
        }}
        listProps={{
          onItemsRendered,
          itemSize: () => {
            return itemSize
          },
        }}
        onSearch={onSearch}
        searchBy={() => {
          return true
        }}
      />
    </div>
  )
}

LocalListAdd.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  RenderMenuItem: PropTypes.element,
  RenderMenuItemkeys: PropTypes.array,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  loadMoreData: PropTypes.func,
  itemSize: PropTypes.number,
}

export default LocalListAdd
