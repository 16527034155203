import styled from 'styled-components'
import Colors from 'Theme/Colors'
import styles from './IFFilter.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
  IFText,
  IFButton,
  IFSelectPicker,
  IFCheckPicker,
  IFDateRangePicker,
  IFRangePicker,
  IFLoadingIndicator,
  IFsvg,
} from 'Components'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { createRef, Fragment, useImperativeHandle, useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@material-ui/icons/Close'
import { Accordion, AccordionDetails } from '@material-ui/core'
import IFFilterType from 'Enums/IFFilterType'
import PropTypes from 'prop-types'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import IFFilterSkeleton from './IFFilterSkeleton'
import { TransactionsDownload } from 'Containers'
import { Icons } from 'Theme'
import InfinityEnums from 'Enums/InfinityEnums'
import IFSliderPicker from './IFSliderPicker/IFSliderPicker'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import ChargePointLogsDownload from 'Containers/ChargePointLogsDownload/ChargePointLogsDownload'

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: `${Colors.primary} !important`,
    color: `${Colors.white} !important`,
    fontSize: '0.875rem !important',
    fontFamily: 'ProximaNova !important',
    padding: '0.3rem !important',
    marginTop: '0.5rem !important',
  },
})(Tooltip)

const TextContainer = styled.div`
  color: ${Colors.FilterShadow};
`

const TextField = styled.input`
  border: 0em;
  &:focus ${this} {
    outline: none;
  }
`
const InputContainer = styled.div`
  background-color: ${(props) => props.FilterBackground};
`
const AccordionSummary = withStyles({
  root: {
    padding: '0em',
    backgroundColor: 'white !important',
    height: 48,
    '&$expanded': {
      minHeight: 48,
    },
    margin: 0,
    borderRadius: '5px',
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const IFFilter = React.forwardRef(
  (
    {
      onFilterChange = () => {},
      filters,
      textFieldPlaceholder,
      isLoading,
      downloadTransactions,
      downloadChargePointLogs,
      disableKeyword = false,
      fixedFilters = false,
      showLoadingIndicator = false,
      canSort = false,
      ascending = false,
      onSortChange = () => {},
      tenant,
      disableFutureDates = false,
    },
    ref,
  ) => {
    const useStyles = makeStyles((theme) => ({
      chip: {
        margin: theme.spacing(0.5),
        backgroundColor: Colors.FilterBackground,
        '&:hover': {
          backgroundColor: Colors.primary,
          color: 'white',
        },
        fontSize: '0.875rem',
      },
      accordionDetails: {
        padding: '0em',
      },
      Accordion: {
        boxShadow: '0px 0px 2px 1px' + Colors.ChargingTransactionListItemShadow,
        zIndex: 3,
      },
    }))

    const classes = useStyles()
    const filterRef = useRef(null)
    const { t } = useTranslation()
    const [filterText, setFilterText] = React.useState('')
    const [isAccordionExpanded, setIsAccordionExpanded] = React.useState(false)
    const [chipFilters, setChipFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].value &&
          !(filters[i].value === '' || filters[i].value.length === 0)
        ) {
          if (filters[i].type === IFFilterType.KEYWORD) {
            for (let j = 0; j < filters[i].value.length; j++) {
              result.push({
                ...filters[i],
                value: filters[i].value[j],
                id: i,
              })
            }
          } else {
            result.push({
              ...filters[i],
              value: filters[i].value,
              id: i,
            })
          }
        }
      }
      return result
    })

    const [currentFilters, setCurrentFilters] = React.useState(() => {
      let result = []
      for (let i = 0; i < filters.length; i++) {
        if (
          filters[i].type === IFFilterType.CHECK ||
          filters[i].type === IFFilterType.DATE_RANGE ||
          filters[i].type === IFFilterType.KEYWORD ||
          filters[i].type === IFFilterType.VALUE_RANGE ||
          filters[i].type === IFFilterType.VALUE_RANGE_DECIMAL
        ) {
          result.push({ ...filters[i], value: [], ref: createRef() })
        } else {
          result.push({
            ...filters[i],
            value: filters[i].value === '' ? '' : filters[i].value,
            ref: createRef(),
          })
        }
      }
      return result
    })

    useImperativeHandle(ref, () => ({
      setIsAccordionExpanded,
      clearFilter: () => {
        for (let i = 0; i < currentFilters.length; i++) {
          if (
            currentFilters[i].type === IFFilterType.CHECK ||
            currentFilters[i].type === IFFilterType.DATE_RANGE ||
            currentFilters[i].type === IFFilterType.SELECT ||
            currentFilters[i].type === IFFilterType.VALUE_RANGE
          ) {
            currentFilters[i]?.ref?.current?.clearValue()
          }
        }
        clearValues()
        setFilterText('')
      },
    }))

    React.useEffect(() => {
      let result = currentFilters
      for (let i = 0; i < result.length; i++) {
        result[i].data = filters[i].data
      }

      setCurrentFilters(result)
    }, [filters])

    React.useEffect(() => {
      function handleClickOutside(event) {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
          for (let i = 0; i < currentFilters.length; i++) {
            if (
              currentFilters[i].ref.current &&
              currentFilters[i].ref.current.isOpen
            ) {
              return
            }
          }
          setIsAccordionExpanded(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [filterRef, currentFilters])
    const clearValues = () => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters.length; i++) {
        newFilters[i].type === IFFilterType.CHECK ||
        newFilters[i].type === IFFilterType.DATE_RANGE ||
        newFilters[i].type === IFFilterType.KEYWORD ||
        newFilters[i].type === IFFilterType.VALUE_RANGE ||
        newFilters[i].type === IFFilterType.VALUE_RANGE_DECIMAL
          ? (newFilters[i].value = [])
          : (newFilters[i].value = '')
      }
      setChipFilters([])
      setCurrentFilters(newFilters)
      onFilterChange(newFilters)
    }

    const deleteKeyword = (chipIndex) => {
      var newFilters = [...currentFilters]
      for (let i = 0; i < newFilters[0].value.length; i++) {
        if (newFilters[0].value[i] === chipFilters[chipIndex].value) {
          newFilters[0].value.splice(i, 1)
          break
        }
      }
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
    }

    const clearSelect = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = ''
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
      if (currentFilters[chipFilters[chipIndex].id].ref.current)
        currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const deleteDateCheckOrRangeItem = (chipIndex) => {
      var newFilters = [...currentFilters]
      newFilters[chipFilters[chipIndex].id].value = []
      setCurrentFilters(newFilters)
      let newChips = [...chipFilters]
      newChips.splice(chipIndex, 1)
      setChipFilters(newChips)
      onFilterChange(newFilters)
      currentFilters[chipFilters[chipIndex].id].ref.current.clearValue()
    }

    const addKeywordFilter = () => {
      var newFilters = [...currentFilters]
      newFilters[0].value.push(filterText.trim())
      setCurrentFilters(newFilters)
      setChipFilters([
        ...chipFilters,
        { type: IFFilterType.KEYWORD, value: filterText.trim() },
      ])
      setFilterText('')
      onFilterChange(newFilters)
    }

    const changeFilterValue = (value, index) => {
      if (value.length === 0) {
        for (let i = 0; i < chipFilters.length; i++) {
          if (chipFilters[i].id === index) {
            let newChips = [...chipFilters]
            newChips.splice(i, 1)
            setChipFilters(newChips)
            break
          }
        }
      }
      let found = false
      var newFilters = [...currentFilters]
      newFilters[index].value = value

      for (let i = 0; i < chipFilters.length; i++) {
        if (chipFilters[i].id === index) {
          chipFilters[i] = { ...newFilters[index], id: index }
          found = true
          break
        }
      }
      if (!found) {
        setChipFilters([...chipFilters, { ...newFilters[index], id: index }])
      }
      setCurrentFilters([...newFilters])
      onFilterChange(newFilters)
    }

    const handleChargingSpeedLogo = (level) => {
      if (level === InfinityEnums.ChargingSpeedLevels.LEVEL1) {
        return [
          <div
            key={Math.floor(Math.random() * 100)}
            style={{ width: '2px' }}
          ></div>,
          <div
            key={Math.floor(Math.random() * 100)}
            className={styles.FlashContainer}
          >
            {Icons.flash}
          </div>,
        ]
      } else if (level === InfinityEnums.ChargingSpeedLevels.LEVEL2) {
        let symbolsDiv = [...Array(2)].map((e, index) => (
          <div
            key={`${InfinityEnums.ChargingSpeedLevels.LEVEL2 + index}`}
            className={styles.FlashContainer}
          >
            {Icons.flash}
          </div>
        ))
        symbolsDiv.splice(
          0,
          0,
          <div
            key={Math.floor(Math.random() * 100)}
            style={{ width: '2px' }}
          />,
        )
        return symbolsDiv
      } else if (level === InfinityEnums.ChargingSpeedLevels.LEVEL3) {
        let symbolsDiv = [...Array(3)].map((e, index) => (
          <div
            key={`${InfinityEnums.ChargingSpeedLevels.LEVEL3 + index}`}
            className={styles.FlashContainer}
          >
            {Icons.flash}
          </div>
        ))
        symbolsDiv.splice(
          0,
          0,
          <div
            key={Math.floor(Math.random() * 100)}
            style={{ width: '2px' }}
          />,
        )
        return symbolsDiv
      }
    }

    const options = []

    const handleChargingSpeedFilter = (data, options) => {
      for (const index in data) {
        const logo = handleChargingSpeedLogo(data[index])
        options.push({
          label: (
            <div
              key={'handleChargingSpeedFilter' + index}
              className={styles.ChargingSpeedContainer}
            >
              {logo}
            </div>
          ),
          value: data[index],
        })
      }
      return options
    }

    return (
      <Fragment>
        {isLoading ? (
          <IFFilterSkeleton />
        ) : (
          <div ref={filterRef}>
            <Accordion
              className={classes.Accordion}
              expanded={isAccordionExpanded}
            >
              <AccordionSummary>
                <TextContainer
                  className={styles.TextContainer}
                  onClick={() => {
                    setIsAccordionExpanded(true)
                  }}
                >
                  {canSort ? (
                    <StyledTooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 60 }}
                      title={
                        ascending
                          ? t('IFFilter.Ascending')
                          : t('IFFilter.Descending')
                      }
                      placement="bottom"
                    >
                      <div
                        className={styles.SortContainer}
                        onClick={(e) => {
                          e.stopPropagation()
                          onSortChange()
                        }}
                      >
                        {ascending ? (
                          <IFsvg.ArrowUpSort
                            height={18}
                            width={18}
                            fill={
                              chipFilters.length === 0
                                ? Colors.FilterIconEmpty
                                : Colors.FilterIconFilled
                            }
                          />
                        ) : (
                          <IFsvg.ArrowDownSort
                            height={18}
                            width={18}
                            fill={
                              chipFilters.length === 0
                                ? Colors.FilterIconEmpty
                                : Colors.FilterIconFilled
                            }
                          />
                        )}
                      </div>
                    </StyledTooltip>
                  ) : (
                    <FontAwesomeIcon
                      className={styles.FilterIcon}
                      style={
                        chipFilters.length === 0
                          ? { color: Colors.FilterIconEmpty }
                          : { color: Colors.FilterIconFilled }
                      }
                      icon={faFilter}
                    />
                  )}

                  <div className={styles.TextInputWrapper}>
                    {chipFilters.map((filter, index) => {
                      if (filter.type === IFFilterType.KEYWORD) {
                        return (
                          <Chip
                            key={`chip keyword ${index}`}
                            deleteIcon={
                              !fixedFilters ? (
                                <CloseIcon
                                  style={{
                                    color: 'inherit',
                                    width: '14px',
                                    height: '14px',
                                  }}
                                />
                              ) : (
                                <></>
                              )
                            }
                            label={
                              <IFText style={{ display: 'inline' }}>
                                Keyword | {filter.value}
                              </IFText>
                            }
                            size="small"
                            className={classes.chip}
                            onDelete={() => deleteKeyword(index)}
                          />
                        )
                      } else if (
                        filter.type === IFFilterType.SELECT ||
                        filter.type === IFFilterType.SLIDER
                      ) {
                        return (
                          <Chip
                            key={`chip select ${index}`}
                            deleteIcon={
                              !fixedFilters ? (
                                <CloseIcon
                                  style={{
                                    color: 'inherit',
                                    width: '14px',
                                    height: '14px',
                                  }}
                                />
                              ) : (
                                <></>
                              )
                            }
                            className={classes.chip}
                            size="small"
                            label={
                              <IFText style={{ display: 'inline' }}>
                                {filter.title} | {filter.value}
                              </IFText>
                            }
                            onDelete={() => clearSelect(index)}
                          />
                        )
                      } else if (filter.type === IFFilterType.CHECK) {
                        return (
                          <div
                            key={`chip check ${index}`}
                            className={styles.FlashLogoContainer}
                          >
                            <Chip
                              deleteIcon={
                                !fixedFilters ? (
                                  <CloseIcon
                                    style={{
                                      color: 'inherit',
                                      width: '14px',
                                      height: '14px',
                                    }}
                                  />
                                ) : (
                                  <></>
                                )
                              }
                              {...(filter.field === 'chargePointType'
                                ? {
                                    style: {
                                      margin: '0px',
                                    },
                                  }
                                : {})}
                              className={classes.chip}
                              size="small"
                              label={
                                filter.field === 'chargePointType' ? (
                                  <div className={styles.ChargingSpeedChip}>
                                    <IFText style={{ display: 'inline' }}>
                                      {filter.title} |{' '}
                                    </IFText>
                                    {filter.value.map((value, index) =>
                                      index === 0
                                        ? handleChargingSpeedLogo(value)
                                        : filter.value.length === index + 1
                                        ? [
                                            <div
                                              style={{
                                                marginLeft: '2px',
                                              }}
                                            >
                                              {t('IFFilter.or')}
                                            </div>,
                                            handleChargingSpeedLogo(value),
                                          ]
                                        : [
                                            ', ',
                                            handleChargingSpeedLogo(value),
                                          ],
                                    )}
                                  </div>
                                ) : (
                                  <IFText style={{ display: 'inline' }}>
                                    {filter.title} |{' '}
                                    {filter.value.map((value, index) =>
                                      index === 0
                                        ? value
                                        : filter.value.length === index + 1
                                        ? t('IFFilter.or') + value
                                        : ', ' + value,
                                    )}
                                  </IFText>
                                )
                              }
                              onDelete={() => deleteDateCheckOrRangeItem(index)}
                            />
                          </div>
                        )
                      } else if (filter.type === IFFilterType.DATE_RANGE) {
                        return (
                          <Chip
                            key={`chip dateRange ${index}`}
                            deleteIcon={
                              !fixedFilters ? (
                                <CloseIcon
                                  style={{
                                    color: 'inherit',
                                    width: '14px',
                                    height: '14px',
                                  }}
                                />
                              ) : (
                                <></>
                              )
                            }
                            className={classes.chip}
                            label={
                              <IFText style={{ display: 'inline' }}>
                                {filter.title} |{' '}
                                {moment(filter.value[0]).format(
                                  'DD-MM-YYYY HH:mm',
                                )}{' '}
                                to{' '}
                                {moment(filter.value[1]).format(
                                  'DD-MM-YYYY HH:mm',
                                )}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteDateCheckOrRangeItem(index)}
                          />
                        )
                      } else if (
                        filter.type === IFFilterType.VALUE_RANGE ||
                        filter.type === IFFilterType.VALUE_RANGE_DECIMAL
                      ) {
                        return (
                          <Chip
                            key={`chip valueRangeS ${index}`}
                            deleteIcon={
                              !fixedFilters ? (
                                <CloseIcon
                                  style={{
                                    color: 'inherit',
                                    width: '14px',
                                    height: '14px',
                                  }}
                                />
                              ) : (
                                <></>
                              )
                            }
                            className={classes.chip}
                            label={
                              <IFText style={{ display: 'inline' }}>
                                {filter.title} |{' '}
                                {filter.value[0] === filter.value[1]
                                  ? `Exactly ${filter.value[0]}`
                                  : filter.value[0] === -1
                                  ? `Less than ${Number(filter.value[1])}`
                                  : filter.value[1] === -1
                                  ? `More than ${Number(filter.value[0])}`
                                  : `Between ${filter.value[0]} to ${filter.value[1]}`}
                              </IFText>
                            }
                            size="small"
                            onDelete={() => deleteDateCheckOrRangeItem(index)}
                          />
                        )
                      }
                      return null
                    })}

                    {!disableKeyword ? (
                      <TextField
                        type="search"
                        placeholder={textFieldPlaceholder}
                        value={filterText}
                        onKeyPress={(event) => {
                          if (
                            event.key === 'Enter' ||
                            event.key === 'NumpadEnter'
                          ) {
                            if (filterText.trim()) {
                              addKeywordFilter()
                            }
                          }
                        }}
                        className={styles.TextField}
                        onChange={(event) => setFilterText(event.target.value)}
                      />
                    ) : (
                      <IFText className={styles.FilterPlaceHolderText}>
                        {textFieldPlaceholder}
                      </IFText>
                    )}
                  </div>

                  {isAccordionExpanded ? (
                    !fixedFilters ? (
                      <>
                        <IFButton
                          text={t('IFFilter.ClearButton')}
                          color={Colors.FilterClearButton}
                          size="xsm"
                          onClick={() => {
                            for (let i = 0; i < currentFilters.length; i++) {
                              if (
                                currentFilters[i].type === IFFilterType.CHECK ||
                                currentFilters[i].type ===
                                  IFFilterType.DATE_RANGE ||
                                currentFilters[i].type ===
                                  IFFilterType.SELECT ||
                                currentFilters[i].type ===
                                  IFFilterType.VALUE_RANGE
                              ) {
                                currentFilters[i].ref.current.clearValue()
                              }
                            }
                            clearValues()
                            setFilterText('')
                          }}
                          className={styles.Button}
                        />
                      </>
                    ) : null
                  ) : null}

                  {showLoadingIndicator ? (
                    <IFLoadingIndicator
                      size={'24px'}
                      className={styles.LoadingIndicator}
                      secondaryColor={Colors.white}
                    />
                  ) : null}
                </TextContainer>
                {downloadTransactions && (
                  <div
                    className={styles.download}
                    style={
                      isAccordionExpanded
                        ? {
                            top: '56px',
                          }
                        : {
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }
                    }
                  >
                    <TransactionsDownload />
                  </div>
                )}
                {downloadChargePointLogs && (
                  <div
                    className={styles.download}
                    style={
                      isAccordionExpanded
                        ? { top: '56px' }
                        : { top: '50%', transform: 'translateY(-50%)' }
                    }
                  >
                    <ChargePointLogsDownload />
                  </div>
                )}
              </AccordionSummary>
              <AccordionDetails
                className={classes.accordionDetails}
                style={{ backgroundColor: Colors.FilterBackground }}
              >
                <InputContainer
                  FilterBackground={Colors.FilterBackground}
                  className={styles.InputContainer}
                >
                  {currentFilters.map((filter, index) => {
                    if (filter.type === IFFilterType.SELECT) {
                      return (
                        <IFSelectPicker
                          key={`IfSelectPicker select ${index}`}
                          ref={filter.ref}
                          title={filter.title}
                          data={filter.data}
                          onValueChange={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.value}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.CHECK) {
                      if (
                        !tenant?.isUsingMobileApp &&
                        filter.field === 'appVisibility'
                      )
                        return
                      else {
                        return (
                          <IFCheckPicker
                            key={`IFCheckPicker check ${index}`}
                            ref={filter.ref}
                            title={filter.title}
                            data={
                              filter.field === 'chargePointType'
                                ? handleChargingSpeedFilter(
                                    filter.data,
                                    options,
                                  )
                                : filter.data
                            }
                            isChargingSpeed={
                              filter.field === 'chargePointType' ? true : false
                            }
                            onValueChange={(value) => {
                              changeFilterValue(value, index)
                            }}
                            initialValue={filter.value}
                          />
                        )
                      }
                    }
                    if (filter.type === IFFilterType.DATE_RANGE) {
                      return (
                        <IFDateRangePicker
                          key={`IFDateRangePicker dateRange ${index}`}
                          ref={filter.ref}
                          title={filter.title}
                          onDateRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                          initialValue={filter.initialValue}
                          ranges={filter.ranges}
                          disableFutureDates={disableFutureDates}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.VALUE_RANGE) {
                      return (
                        <IFRangePicker
                          key={`IFDateRangePicker valueRange ${index}`}
                          min={0}
                          isDecimal={false}
                          initialValue={filter.value}
                          ref={filter.ref}
                          unit={filter.unit}
                          title={filter.title}
                          onRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.VALUE_RANGE_DECIMAL) {
                      return (
                        <IFRangePicker
                          key={`IFDateRangePicker valueRangeDecimal ${index}`}
                          min={0}
                          isDecimal={true}
                          initialValue={filter.value}
                          unit={filter.unit}
                          ref={filter.ref}
                          title={filter.title}
                          onRangeSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    if (filter.type === IFFilterType.SLIDER) {
                      return (
                        <IFSliderPicker
                          minSliderValue={filter.data[0]}
                          maxSliderValue={filter.data[1]}
                          initialValue={filter.value}
                          ref={filter.ref}
                          title={filter.title}
                          onValueSelected={(value) => {
                            changeFilterValue(value, index)
                          }}
                        />
                      )
                    }
                    return null
                  })}
                </InputContainer>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </Fragment>
    )
  },
)
IFFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func,
  textFieldPlaceholder: PropTypes.string,
  downloadTransactions: PropTypes.bool,
  disableKeyword: PropTypes.bool,
  fixedFilters: PropTypes.bool,
  showLoadingIndicator: PropTypes.bool,
  tenant: PropTypes.object,
}

export default IFFilter
