import React, { useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux'
import StationSelectors from 'Stores/Station/Selectors'
import {
  StationListItem,
  StationListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import { StationDetailsModal } from 'Containers'
import Styles from './StationList.module.css'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import { Divider } from '@material-ui/core'
import { Waypoint } from 'react-waypoint'
import StationActions from 'Stores/Station/Actions'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import history from 'history/browser'
import AuthSelectors from 'Stores/Auth/Selectors'
import { Virtuoso } from 'react-virtuoso'
import { Emitter } from '../../Services/EmitterService'

TimeAgo.addDefaultLocale(en)

const StationList = React.forwardRef(
  (
    {
      stationsList,
      fetchStationList,
      fetchChargePointList,
      stationsFilter,
      formattedStationList,
      fetchStationListRequestState,
      selectedIndex,
      stationDetailsSelectedIndex,
      setStationDetailsSelectedIndex,
      setSelectedIndex,
      paginationOffset,
      isPicking,
      setChargePointSelectedIndex,
      clearChargePoints,
      clearStations,
      emitterUpdateChargePointStation,
      emitterAddChargePointStation,
      resetChargePointState,
      emitterDeleteChargePointStation,
      emitterMoveChargePointStation,
      emitterUpdateStation,
      resetFilters,
      setPaginationOffset,
      emitterActivateChargePointStation,
      resetChargePointSpecifications,
      tenant,
    },
    ref,
  ) => {
    const stationDetailsRef = useRef()

    const Footer = () => {
      return paginationOffset && stationsList && stationsList.length !== 0 ? (
        <div className={Styles.WaypointContainer}>
          <Waypoint onEnter={loadMoreData} />
          <StationListItemSkeleton />
          <Divider />
          <StationListItemSkeleton />
        </div>
      ) : null
    }

    useEffect(() => {
      return () => {
        Emitter.unsubscribeFromChannelGroup(
          `tenant/${tenant._id}/station/`,
          tenant.channelKey,
        )
        clearStations()
        setPaginationOffset(0)
      }
    }, [])

    useEffect(() => {
      if (stationsList.length > 0) {
        stationsList.forEach((station, index) => {
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/chargePointActions/updateConnector/`,
            tenant.channelKey,
            emitterUpdateChargePointStation,
            {
              index: index,
            },
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/addChargePoint/`,
            tenant.channelKey,
            emitterAddChargePointStation,
            {
              index: index,
            },
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/deleteChargePoint/`,
            tenant.channelKey,
            emitterDeleteChargePointStation,
            {
              index: index,
            },
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/moveChargePoint/`,
            tenant.channelKey,
            emitterMoveChargePointStation,
            {},
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/stationActions/chargePointOnline/`,
            tenant.channelKey,
            emitterUpdateChargePointStation,
            {
              index: index,
            },
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/editStation/`,
            tenant.channelKey,
            emitterUpdateStation,
            {
              index: index,
            },
          )
          Emitter.subscribeToChannel(
            `tenant/${tenant._id}/station/${station._id}/activateChargePoint/`,
            tenant.channelKey,
            emitterActivateChargePointStation,
            {
              index: index,
            },
          )
        })
      }
    }, [stationsList, tenant])

    useEffect(() => {
      if (stationsList[selectedIndex]) {
        const selectedStation = stationsList[selectedIndex]
        let selectedChargePoints = selectedStation['chargePoints']

        let currentPath = history.location.pathname
        const stationIndex = currentPath.indexOf('station')
        let chargePoint = null
        const slashArray = history.location.pathname.split('/')
        const chargePointId = slashArray[slashArray.length - 1]

        if (selectedChargePoints && stationIndex !== -1) {
          chargePoint = selectedChargePoints.find(
            (chargePoint) => chargePoint._id === chargePointId,
          )

          if (chargePoint)
            history.push({
              pathname: `/sites/${selectedStation._id}/stations/${chargePoint._id}`,
            })
          else if (selectedChargePoints.length === 0)
            history.push({ pathname: `/sites/${selectedStation._id}` })
          else {
            selectedChargePoints = selectedChargePoints[0]
            history.push({
              pathname: `/sites/${selectedStation._id}/stations/${selectedChargePoints._id}`,
            })
          }
        } else {
          if (!selectedChargePoints || selectedChargePoints.length === 0)
            history.push({ pathname: `/sites/${selectedStation._id}` })
          else {
            selectedChargePoints = selectedChargePoints[0]
            history.push({
              pathname: `/sites/${selectedStation._id}/stations/${selectedChargePoints._id}`,
            })
          }
        }
      }
    }, [selectedIndex])

    const stations = useMemo(() => formattedStationList, [formattedStationList])

    const handleStationItemClick = (index) => {
      setSelectedIndex(index)
      clearChargePoints()
      if (
        stationsList[index]['chargePoints'] &&
        stationsList[index]['chargePoints'].length !== 0
      ) {
        setChargePointSelectedIndex(0)
        resetChargePointState()
        resetChargePointSpecifications()
        resetFilters()
        fetchChargePointList(stationsList[index]._id)
      }
    }

    const loadMoreData = () => {
      if (fetchStationListRequestState === RequestState.LOADING_MORE) return
      fetchStationList(stationsFilter, paginationOffset, () => {})
    }

    const stationsContainerRef = useRef()
    const getStationsSkeletonsCount = () => {
      if (stationsContainerRef.current) {
        let containerHeight =
          stationsContainerRef.current.parentElement.clientHeight / 16
        return Math.floor(containerHeight / 5)
      }
      return 12
    }

    return (
      <div className={Styles.Container} ref={stationsContainerRef}>
        {fetchStationListRequestState === RequestState.LOADING &&
        paginationOffset === 0 ? (
          [...Array(getStationsSkeletonsCount())].map((e, index) => (
            <div key={`StationListItemSkeleton ${index}`}>
              <StationListItemSkeleton key={index} />
              <Divider />
            </div>
          ))
        ) : (
          <Virtuoso
            ref={ref}
            data={stations}
            endReached={
              paginationOffset && stationsList && stationsList.length !== 0
                ? loadMoreData
                : () => {}
            }
            increaseViewportBy={480}
            itemContent={(index, station) => {
              return (
                <div key={`StationListItem ${index}`}>
                  <StationListItem
                    name={station.name}
                    isSelected={selectedIndex === index}
                    lastUpdated={
                      station ? (
                        <ReactTimeAgo
                          date={new Date(station.lastUpdated)}
                          locale="en-US"
                          timeStyle="mini"
                        />
                      ) : (
                        ''
                      )
                    }
                    onMarkerClick={
                      isPicking
                        ? null
                        : (e) => {
                            e.stopPropagation()
                            setStationDetailsSelectedIndex(index)
                            stationDetailsRef.current.handleOpen()
                          }
                    }
                    isPicking={isPicking}
                    connectors={station.connectors}
                    chargePoints={station.chargePoints}
                    isNew={station.isNew}
                    onClick={() => handleStationItemClick(index)}
                  />
                  <Divider />
                </div>
              )
            }}
            components={{ Footer: Footer, Scroller: CustomScrollbar }}
          />
        )}
        <StationDetailsModal
          key="StationDetailsModal"
          ref={stationDetailsRef}
          index={stationDetailsSelectedIndex}
          name={
            formattedStationList[stationDetailsSelectedIndex]
              ? formattedStationList[stationDetailsSelectedIndex].name
              : ''
          }
          connectors={
            formattedStationList[stationDetailsSelectedIndex]
              ? formattedStationList[stationDetailsSelectedIndex].connectors
              : []
          }
          chargePoints={
            formattedStationList[stationDetailsSelectedIndex]
              ? formattedStationList[stationDetailsSelectedIndex].chargePoints
              : []
          }
          location={
            formattedStationList[stationDetailsSelectedIndex]
              ? formattedStationList[stationDetailsSelectedIndex].location
              : {}
          }
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchStationList: (filter, offset, onNotFound) =>
      dispatch(StationActions.fetchStationList(filter, offset, onNotFound)),
    setSelectedIndex: (index) =>
      dispatch(StationActions.setStationListSelectedIndex(index)),
    setStationDetailsSelectedIndex: (index) =>
      dispatch(StationActions.setStationDetailsSelectedIndex(index)),
    fetchChargePointList: (stationId) =>
      dispatch(ChargePointActions.fetchChargePointList(stationId)),
    setChargePointSelectedIndex: (index) =>
      dispatch(ChargePointActions.setChargePointSelectedIndex(index)),
    clearChargePoints: () => dispatch(ChargePointActions.clearChargePoints()),
    clearStations: () => dispatch(StationActions.clearStations()),
    emitterUpdateChargePointStation: (data) =>
      dispatch(StationActions.emitterUpdateChargePointStation(data)),
    emitterAddChargePointStation: (data) =>
      dispatch(StationActions.emitterAddChargePointStation(data)),
    resetChargePointState: () =>
      dispatch(ChargePointActions.resetChargePointState()),
    emitterDeleteChargePointStation: (data) =>
      dispatch(StationActions.emitterDeleteChargePointStation(data)),
    emitterMoveChargePointStation: (data) =>
      dispatch(StationActions.emitterMoveChargePointStation(data)),
    emitterUpdateStation: (args) =>
      dispatch(StationActions.emitterUpdateStation(args)),
    resetFilters: () => dispatch(ChargePointActions.resetFilters()),
    setPaginationOffset: (offset) =>
      dispatch(StationActions.setPaginationOffset(offset)),
    emitterActivateChargePointStation: (index, data) =>
      dispatch(StationActions.emitterActivateChargePointStation(index, data)),
    resetChargePointSpecifications: () =>
      dispatch(ChargePointActions.resetChargePointSpecifications()),
  }
}

const mapStateToProps = (state) => ({
  formattedStationList: StationSelectors.getFormattedStationsList(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  selectedIndex: StationSelectors.getSelectedIndex(state),
  stationDetailsSelectedIndex:
    StationSelectors.getStationDetailsSelectedIndex(state),
  paginationOffset: StationSelectors.getPaginationOffset(state),
  stationsFilter: StationSelectors.getStationsFilter(state),
  stationsList: StationSelectors.getStationsList(state),
  tenant: AuthSelectors.getTenant(state),
})

StationList.propTypes = {
  fetchStationList: PropTypes.func,
  stationsFilter: PropTypes.arrayOf(PropTypes.object),
  formattedStationList: PropTypes.arrayOf(PropTypes.object),
  fetchStationListRequestState: PropTypes.number,
  selectedIndex: PropTypes.number,
  paginationOffset: PropTypes.number,
  isPicking: PropTypes.bool,
  resetFilters: PropTypes.func,
  setPaginationOffset: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(StationList)
