const isTenantUserLoginLoading = (state) => {
  return state.secure.authState.tenantUserLoading
}

const getResetPasswordRequestState = (state) => {
  return state.secure.authState.resetPasswordRequestState
}

const getTenantUser = (state) => {
  return state.secure.authState.tenantUser
}
const getTenant = (state) => {
  return state.secure.authState.tenant
}

const getToken = (state) => {
  return state.secure.authState.token
}

const isLoggedIn = (state) => {
  return state.secure.authState.token && state.secure.authState.tenantUser
}

const getRefreshToken = (state) => {
  return state.secure.authState.refreshToken
}

const getTenantUserLoading = (state) => {
  return state.secure.authState.tenantUserLoading
}

const getFetchTenantUserRequestState = (state) => {
  return state.secure.authState.fetchTenantUserRequestState
}

const getResetPassVerificationRequestState = (state) => {
  return state.secure.authState.resetPassVerificationRequestState
}

const getResetPassEmailID = (state) => {
  return state.secure.authState.resetPassEmailID
}

const AuthSelectors = {
  getTenantUser,
  isTenantUserLoginLoading,
  getToken,
  getRefreshToken,
  isLoggedIn,
  getTenantUserLoading,
  getResetPasswordRequestState,
  getFetchTenantUserRequestState,
  getResetPassVerificationRequestState,
  getResetPassEmailID,
  getTenant,
}

export default AuthSelectors
