import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import Colors from 'Theme/Colors'
import Styles from './UserListItem.module.css'
import styled from 'styled-components'
import Icon from '@material-ui/core/Icon'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'
import { IFAvatar, IFText, UserListItemSkeleton } from 'Components'
import Variant from 'Enums/Variant'

const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.UserListItemBackgroundHover};
  }
`
const StatusContainer = styled.div`
  background-color: ${Colors.StatusContainerBackground};
  color: ${(props) => props.status};
`

const UserListItem = ({
  name,
  email,
  lastUpdated,
  isSelected,
  onClick,
  isNew,
  isActive,
  isInvited,
  userExists,
  style,
  isLoading,
  backgroundHover,
  variant = Variant.NORMAL,
  chargingTokensCount,
}) => {
  const { t } = useTranslation()

  return isLoading ? (
    <UserListItemSkeleton variant={variant} />
  ) : (
    <Container
      backgroundColor={
        backgroundHover
          ? backgroundHover
          : isSelected
          ? Colors.StationListItemSelected
          : Colors.white
      }
      UserListItemBackgroundHover={
        backgroundHover
          ? backgroundHover
          : Colors.StationListItemBackgroundHover
      }
      className={Styles.Container}
      onClick={onClick}
      style={style}
    >
      <div className={Styles.LastUpdatedContainer}>
        {isNew ? (
          <Fragment>
            <div
              className={Styles.NewContainer}
              style={{
                backgroundColor: Colors.primary,
              }}
            >
              <IFText className={Styles.New} style={{ color: Colors.white }}>
                {t('StationListItem.New')}
              </IFText>
            </div>
            <IFText style={{ color: Colors.LastUpdatedColor }}>
              {lastUpdated}
            </IFText>
          </Fragment>
        ) : isInvited ? (
          <StatusContainer
            status={Colors.primary}
            className={Styles.InviteStatusContainer}
          >
            <IFText>{t('UsersPage.Pending')}</IFText>
          </StatusContainer>
        ) : (
          <IFText style={{ color: Colors.LastUpdatedColor }}>
            {lastUpdated}
          </IFText>
        )}
      </div>
      <div className={Styles.UserDetailsContainer}>
        <div className={Styles.UserAvatarContainer}>
          <IFAvatar
            name={name}
            size={variant === Variant.LOCALLIST ? 30 : 40}
          ></IFAvatar>
          {userExists && (
            <div
              className={Styles.StatusContainer}
              style={{
                backgroundColor: isActive
                  ? Colors.UserActive
                  : Colors.UserBlocked,
                border: `2px solid ${Colors.white} `,
                width: variant === Variant.LOCALLIST ? '0.7rem' : '0.9rem',
                height: variant === Variant.LOCALLIST ? '0.7rem' : '0.9rem',
              }}
            />
          )}
        </div>
        <div className={Styles.UserCredentialsContainer}>
          <div
            className={Styles.NameContainer}
            style={{ color: Colors.text, justifyContent: 'space-between' }}
          >
            <IFText
              className={Styles.NameText}
              style={{ fontSize: variant === Variant.LOCALLIST ? 12 : 14 }}
            >
              {name}
            </IFText>
          </div>
          <div className={Styles.EmailContainer}>
            <IFText
              style={{
                color: Colors.UserListItemEmail,
                fontSize: variant === Variant.LOCALLIST ? 10 : 12,
              }}
              className={Styles.EmailText}
            >
              {email}
            </IFText>
          </div>
        </div>
        {chargingTokensCount >= 0 && (
          <div className={Styles.TokenCountContainer}>
            <div
              className={Styles.Chip}
              style={{ backgroundColor: Colors.primary }}
            >
              <IFText
                className={Styles.TokenCountText}
                style={{
                  color: Colors.white,
                }}
              >
                {chargingTokensCount}
              </IFText>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

UserListItem.propTypes = {
  name: PropTypes.string,
  connectors: PropTypes.array,
  chargePoints: PropTypes.array,
  lastUpdated: PropTypes.object,
  onMarkerClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isPicking: PropTypes.bool,
}

export default UserListItem
