import Styles from './ChargePointScheduleListItemSkeleton.module.css'
import IFSkeleton from 'Components/IFSkeleton/IFSkeleton'

const ChargePointScheduleListItemSkeleton = ({}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        <div className={Styles.TextContainer}>
          <IFSkeleton variant="text" height="1.5rem" width="6rem" />
        </div>
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.DateTextContainer}>
          <IFSkeleton variant="text" height="1.5rem" width="9rem" />
        </div>
      </div>
    </div>
  )
}

ChargePointScheduleListItemSkeleton.propTypes = {}

export default ChargePointScheduleListItemSkeleton
