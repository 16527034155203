import React, { forwardRef } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ChargePointContainer from './ChargePointContainer/ChargePointContainer'
import UserContainer from './UserContainer/UserContainer'
import ModalContainer from './ModalContainer/ModalContainer'
import ChargingTokensContainer from './ChargingTokensContainer/ChargingTokensContainer'
import Styles from './AccessGroupsContainer.module.css'
import RequestState from 'Enums/RequestState'
import { Colors } from 'Theme'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { IFText, IFSkeleton } from 'Components'
import StationSelectors from 'Stores/Station/Selectors'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'
import UserSelectors from 'Stores/User/Selectors'
import CardSelectors from 'Stores/Card/Selectors'

const AccessGroupsContainer = forwardRef(
  (
    {
      renderFor,
      isEditing,
      maxListHeight,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointRequestState,
      fetchChargePointAccessGroupsRequestState,
      style,
      scrollbarsHeight,
      onModalSubmit = () => {},
      fetchUserListRequestState,
      fetchUserDetailsRequestState,
      fetchCardListRequestState,
      fetchCardRequestState,
      selectedUser,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const isLoading = () => {
      return renderFor === InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT
        ? fetchStationListRequestState === RequestState.LOADING ||
            fetchChargePointListRequestState === RequestState.LOADING ||
            fetchChargePointRequestState === RequestState.LOADING ||
            fetchChargePointAccessGroupsRequestState === RequestState.LOADING
        : renderFor === InfinityEnums.ChargePointSpecsContainer.USER
        ? fetchUserListRequestState === RequestState.LOADING ||
          fetchUserDetailsRequestState === RequestState.LOADING ||
          selectedUser?.isInvitedUser
        : InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS
        ? fetchCardListRequestState === RequestState.LOADING ||
          fetchCardRequestState === RequestState.LOADING
        : false
    }

    return (
      <div
        className={Styles.AccessGroupsContainer}
        {...(style ? { style: style } : {})}
      >
        <div
          className={Styles.AccessGroupListHeader}
          style={isEditing ? {} : { paddingRight: '1rem' }}
        >
          <div>
            {isLoading() ? (
              <IFSkeleton variant="text" width="5rem" height="1.5rem" />
            ) : (
              <IFText
                className={Styles.HeaderText}
                style={{ color: Colors.text }}
              >
                {t('AccessGroupList.AccessGroupName')}
              </IFText>
            )}
          </div>
          <div>
            {isLoading() ? (
              <div>
                <IFSkeleton variant="text" width="4rem" height="1.5rem" />
              </div>
            ) : (
              <IFText
                className={Styles.HeaderText}
                style={{ color: Colors.text }}
              >
                {renderFor ===
                InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS
                  ? t('AccessGroupList.NoOfTokens')
                  : t('AccessGroupList.NoOfUsers')}
              </IFText>
            )}
          </div>
        </div>
        {renderFor === InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT ? (
          <ChargePointContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
          />
        ) : renderFor === InfinityEnums.ChargePointSpecsContainer.USER ? (
          <UserContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
          />
        ) : renderFor === InfinityEnums.ChargePointSpecsContainer.MODAL ? (
          <ModalContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
            onModalSubmit={onModalSubmit}
          />
        ) : renderFor ===
          InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS ? (
          <ChargingTokensContainer
            ref={ref}
            isEditing={isEditing}
            maxListHeight={maxListHeight}
            scrollbarsHeight={scrollbarsHeight}
          />
        ) : null}
      </div>
    )
  },
)

AccessGroupsContainer.propTypes = {
  renderFor: PropTypes.number,
  isEditing: PropTypes.bool,
  maxListHeight: PropTypes.number,
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  fetchChargePointRequestState: PropTypes.number,
  fetchChargePointAccessGroupsRequestState: PropTypes.number,
  style: PropTypes.object,
  scrollbarsHeight: PropTypes.string,
  onModalSubmit: PropTypes.func,
}

const mapStateToProps = (state) => ({
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointAccessGroupsRequestState:
    ChargePointSelectors.getFetchChargePointAccessGroupsRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
  fetchCardRequestState: CardSelectors.getFetchCardRequestState(state),
  fetchCardListRequestState: CardSelectors.getFetchCardListRequestState(state),
  selectedUser: UserSelectors.getSelectedUser(state),
})

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(AccessGroupsContainer)
