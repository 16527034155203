import React from 'react'
import { connect } from 'react-redux'
import IFModal from 'Components/IFModal/IFModal'
import IFText from 'Components/IFText/IFText'
import Styles from './AddChargePointModal.module.css'
import IFButton from 'Components/IFButton/IFButton'
import { Colors } from 'Theme'
import IFTextInput from 'Components/IFTextInput/IFTextInput'
import { useTranslation } from 'react-i18next'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'

const AddChargePointModal = React.forwardRef(
  ({ addChargePointRequestState, addChargePoint, station }, ref) => {
    const [stationID, setStationID] = React.useState('')
    const [stationName, setStationName] = React.useState('')
    const { t } = useTranslation()

    const handleCloseIcon = () => {
      setStationID('')
      setStationName('')
    }

    return (
      <IFModal
        onClose={handleCloseIcon}
        width={'40rem'}
        title={t('AddChargePointModal.Title')}
        ref={ref}
      >
        <div className={Styles.Container}>
          <div>
            <div className={Styles.TextField}>
              <IFText className={Styles.Text}>
                {t('AddChargePointModal.StationIDText')}
              </IFText>

              <IFTextInput
                isFixed={false}
                value={stationID}
                className={Styles.TextInput}
                fontSize={14}
                onChange={(e) => {
                  setStationID(e.target.value)
                }}
              />
            </div>

            <IFText className={Styles.Text}>
              {t('AddChargePointModal.StationNameText')}
            </IFText>
            <IFTextInput
              value={stationName}
              className={Styles.TextInput}
              onChange={(e) => {
                setStationName(e.target.value)
              }}
            />
          </div>

          <div className={Styles.Button}>
            <IFButton
              text={t('AddChargePointModal.ButtonText')}
              color={Colors.primary}
              isFill={true}
              size={'sm'}
              isDead={stationID === '' || stationName === ''}
              isLoading={addChargePointRequestState === RequestState.LOADING}
              onClick={() => {
                addChargePoint({
                  uid: stationID,
                  name: stationName,
                  station: station._id,
                  connectors: [],
                })
              }}
            ></IFButton>
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    addChargePoint: (chargePoint) =>
      dispatch(ChargePointActions.addChargePoint(chargePoint)),
  }
}

const mapStateToProps = (state) => ({
  addChargePointRequestState:
    ChargePointSelectors.getAddChargePointRequestState(state),
  station: StationSelectors.getSelectedStation(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddChargePointModal)
