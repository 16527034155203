import { put, call, select, spawn } from 'redux-saga/effects'
import { stationApiService, handleError } from 'Services/ApiService.js'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import history from 'history/browser'
import IFFilterType from 'Enums/IFFilterType'
var base64 = require('base-64')

export function* fetchStationListCount({ filter }) {
  yield put(StationActions.fetchStationListCountLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(stationApiService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(StationActions.fetchStationListCountSuccess(data.stationsCount))
  } catch (e) {
    yield put(StationActions.fetchStationListCountFail(e))
    if (e.message !== 'Network Error') handleError(e)
  }
}
export function* fetchStationList({ filter, offset, onNotFound }) {
  if (offset === 0) {
    yield spawn(fetchStationListCount, { filter })
    yield put(StationActions.fetchStationListLoading())
  } else {
    yield put(StationActions.fetchStationListLoadingMore())
  }
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(stationApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    const slashArray = history.location.pathname.split('/')
    const stationIdParam = slashArray[2]
    if (
      stationIdParam &&
      stationIdParam.match(/^[0-9a-fA-F]{24}$/) &&
      offset === 0
    ) {
      const index = data.stations.findIndex(
        (station) => station._id === stationIdParam,
      )
      if (index !== -1) {
        yield put(
          StationActions.fetchStationListSuccess(
            offset,
            data.stations,
            data.nextOffset,
            null,
            index,
            onNotFound,
            data.stationsCount,
          ),
        )
      } else {
        const station = yield call(stationApiService.get, `/${stationIdParam}`)

        if (!station.data) {
          onNotFound()
          return
        }

        yield put(
          StationActions.fetchStationListSuccess(
            offset,
            data.stations,
            data.nextOffset,
            station.data,
            data.stations.length - 1,
            onNotFound,
            data.stationsCount,
          ),
        )
      }
    } else {
      yield put(
        StationActions.fetchStationListSuccess(
          offset,
          data.stations,
          data.nextOffset,
          null,
          0,
          onNotFound,
          data.stationsCount,
        ),
      )
    }
  } catch (e) {
    yield put(StationActions.fetchStationListFail(e))
    if (e.message !== 'Network Error') handleError(e)
    e = yield e
    if (e.status === 404) onNotFound()
  }
}

export function* fetchStationMoveList({ filter, offset }) {
  if (offset === 0) yield put(StationActions.fetchStationMoveListLoading())
  else yield put(StationActions.fetchStationMoveListLoadingMore())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(stationApiService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    yield put(
      StationActions.fetchStationMoveListSuccess(
        offset,
        data.stations,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(StationActions.fetchStationMoveListFail(e))
    handleError(e)
  }
}

export function* updateStation({ index, station }) {
  yield put(StationActions.updateStationLoading())
  try {
    const selectedStation = yield select(
      StationSelectors.getSelectedStationDetails,
    )
    const { data } = yield call(
      stationApiService.patch,
      `/${selectedStation._id}`,
      {
        ...station,
      },
    )

    yield put(StationActions.updateStationSuccess(index, data))
  } catch (e) {
    yield put(StationActions.updateStationFail(e))
    handleError(e)
  }
}

export function* addStation({ station }) {
  yield put(StationActions.addStationLoading())
  try {
    const { data } = yield call(stationApiService.post, '/', {
      ...station,
    })

    yield put(StationActions.addStationSuccess(data))
    yield put(ChargePointActions.clearChargePoints())
    history.push({ pathname: `/sites/${data._id}` })
  } catch (e) {
    yield put(StationActions.addStationFail(e))
    handleError(e)
  }
}

export function* deleteStation({ index }) {
  yield put(StationActions.deleteStationLoading())
  try {
    const selectedStation = yield select(
      StationSelectors.getSelectedStationDetails,
    )
    const { data } = yield call(
      stationApiService.delete,
      `/${selectedStation._id}`,
    )
    let stationId = selectedStation._id
    yield put(StationActions.deleteStationSuccess(stationId))
  } catch (e) {
    yield put(StationActions.deleteStationFail(e))
    handleError(e)
  }
}

export function* fetchStationDetails({ emitterData }) {
  try {
    const filter = yield select(StationSelectors.getStationsFilter)

    const connectorFilter = filter.find(
      (obj) => obj.field === 'connectorStatus',
    )
    const onlineFilter = filter.find((obj) => obj.field === 'chargePointStatus')

    const isFilterFilled =
      connectorFilter.value.length > 0 || onlineFilter.value.length > 0
    if (isFilterFilled) {
      if (emitterData.isOnline) {
        const stationId = emitterData.chargePoint.station

        const stations = yield select(StationSelectors.getStationsList)

        const foundStationIndex = stations.findIndex(
          (station) => station._id === stationId,
        )
        if (foundStationIndex === -1) {
          const { data } = yield call(
            stationApiService.get,
            `/${emitterData.chargePoint.station}`,
          )
          let foundFlag = false
          if (connectorFilter.value.length > 0) {
            for (const cp of data.chargePoints) {
              const connectorStatuses = []
              for (const conn of cp.connectors) {
                if (conn.uid !== 0) {
                  connectorStatuses.push(conn.status)
                }
              }
              const connectorFound = connectorFilter.value.some((filter) =>
                connectorStatuses.includes(filter),
              )
              if (connectorFound) {
                foundFlag = true
                break
              }
            }
          } else foundFlag = true

          if (foundFlag) {
            yield put(StationActions.addStationDetails(data))
          }
        }
      }
    }
  } catch (e) {
    handleError(e)
  }
}

export function* fetchStationMapClusters({ zoom, bounds, filter }) {
  yield put(StationActions.fetchStationMapClustersLoading())
  try {
    const { data } = yield call(stationApiService.get, `/map`, {
      params: {
        zoom: zoom,
        bounds: bounds,
        filter: filter,
      },
    })
    yield put(StationActions.fetchStationMapClustersSuccess(data))
  } catch (e) {
    handleError(e)
  }
}

export function* fetchStationHeatMap({ filter }) {
  yield put(StationActions.fetchStationHeatMapLoading())
  try {
    const { data } = yield call(stationApiService.get, `/heatmap`, {
      params: {
        filter: filter,
      },
    })
    yield put(StationActions.fetchStationHeatMapSuccess(data))
  } catch (e) {
    yield put(StationActions.fetchStationHeatMapFail(e))
    handleError(e)
  }
}

export function* fetchStationMapClustersSearch({ zoom, bounds, filter }) {
  yield put(StationActions.fetchStationMapClustersSearchLoading())
  try {
    const searchWord = yield select(
      StationSelectors.getStationMapClustersSearchWord,
    )
    const tempFilter = JSON.parse(JSON.stringify(filter))
    for (const filter of tempFilter) {
      if (filter.type === IFFilterType.KEYWORD) {
        filter.value.push(searchWord)
        break
      }
    }
    const encodedFilter = base64.encode(JSON.stringify(tempFilter))
    const { data } = yield call(stationApiService.get, `/map`, {
      params: {
        zoom: zoom,
        bounds: bounds,
        filter: encodedFilter,
      },
    })
    yield put(StationActions.fetchStationMapClustersSearchSuccess(data))
  } catch (e) {
    handleError(e)
    yield put(StationActions.fetchStationMapClustersSearchFail(e))
  }
}
