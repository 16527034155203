import React, { useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import CardSelectors from 'Stores/Card/Selectors'
import CardActions from 'Stores/Card/Actions'
import RequestState from 'Enums/RequestState'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import AccessGroupList from '../AccessGroupList/AccessGroupList'

const ChargingTokensContainer = forwardRef(
  (
    {
      selectedChargingToken,
      accessGroups,
      fetchAccessGroups,
      addChargingTokenAccessGroups,
      deleteChargingTokenAccessGroups,
      isEditing,
      fetchChargingTokenListRequestState,
      fetchChargingTokenRequestState,
      maxListHeight,
      scrollbarsHeight,
    },
    ref,
  ) => {
    useEffect(() => {
      if (selectedChargingToken) {
        fetchAccessGroups()
      }
    }, [selectedChargingToken?._id])

    return (
      <div style={{ height: '100%' }}>
        <AccessGroupList
          ref={ref}
          displayedList={selectedChargingToken?.accessGroups}
          dropdownList={accessGroups}
          isEditing={isEditing}
          isLoading={
            fetchChargingTokenListRequestState === RequestState.LOADING ||
            fetchChargingTokenRequestState === RequestState.LOADING
          }
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={(addAccessGroups, deleteAccessGroups) => {
            if (deleteAccessGroups.length !== 0) {
              deleteChargingTokenAccessGroups(
                selectedChargingToken._id,
                deleteAccessGroups,
              )
            }
            if (addAccessGroups.length !== 0) {
              addChargingTokenAccessGroups(
                selectedChargingToken._id,
                addAccessGroups,
              )
            }
          }}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    addChargingTokenAccessGroups: (cardId, accessGroups) =>
      dispatch(CardActions.addCardAccessGroups(cardId, accessGroups)),
    deleteChargingTokenAccessGroups: (cardId, accessGroups) =>
      dispatch(CardActions.deleteCardAccessGroups(cardId, accessGroups)),
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

const mapStateToProps = (state) => ({
  selectedChargingToken: CardSelectors.getSelectedCard(state),
  accessGroups: TenantUserSelectors.getAccessGroupsList(state),
  fetchChargingTokenListRequestState:
    CardSelectors.getFetchCardListRequestState(state),
  fetchChargingTokenRequestState: CardSelectors.getFetchCardRequestState(state),
})

ChargingTokensContainer.propTypes = {
  accessGroups: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  deleteChargingTokenAccessGroups: PropTypes.func,
  addChargingTokenAccessGroups: PropTypes.func,
  selectedChargingToken: PropTypes.object,
  fetchAccessGroups: PropTypes.func,
  fetchChargingTokenListRequestState: PropTypes.number,
  fetchChargingTokenRequestState: PropTypes.number,
  maxListHeight: PropTypes.number,
  scrollbarsHeight: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargingTokensContainer)
