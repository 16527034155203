import React, { useState, useImperativeHandle } from 'react'
import { styled, Box } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import ClearIcon from '@mui/icons-material/Clear'
import Styles from './IFModal.module.css'
import IFText from 'Components/IFText/IFText'
import Colors from '../../Theme/Colors'
import PropTypes from 'prop-types'
import IFsvg from 'Components/IFsvg/IFsvg'

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);
`

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${Colors.backdrop};
  -webkit-tap-highlight-color: transparent;
  opacity: 0.7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`

const style = {
  width: '25rem',
  bgcolor: Colors.white,
  p: 2,
  px: 4,
  pb: 3,
  borderRadius: '0.3125rem',
  paddingTop: '1rem',
  paddingBottom: '1rem',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  overflow: 'hidden',
}

const IFModal = React.forwardRef(
  (
    {
      children,
      title,
      width,
      height,
      open = false,
      backdrop = Backdrop,
      onClose,
      centerTitle = false,
      modalCloseDisabled = false,
      canGoBack = false,
      onBackClicked = () => {},
    },
    ref,
  ) => {
    const [openModal, setOpenModal] = useState(open)
    const handleOpen = () => setOpenModal(true)

    const handleClose = (event, reason) => {
      if (!modalCloseDisabled && reason !== 'backdropClick') {
        setOpenModal(false)
        if (onClose) {
          onClose()
        }
      }
    }

    if (width) style.width = width
    if (height) style.height = height

    useImperativeHandle(ref, () => ({
      handleOpen,
      handleClose,
    }))

    return (
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        disableEnforceFocus
        open={openModal}
        onClose={handleClose}
        BackdropComponent={backdrop}
        ref={ref}
        style={{ marginTop: 'auto', marginBottom: 'auto', padding: 'auto' }}
      >
        {openModal && (
          <Box sx={{ ...style, width, height }} style={{ margin: 'auto' }}>
            <div className={Styles.ModalTitleBar}>
              <div className={Styles.TitleContainer}>
                {canGoBack ? (
                  <div
                    className={Styles.BackIconContainer}
                    onClick={onBackClicked}
                  >
                    <IFsvg.GoBack height={25} width={25} fill={Colors.black} />
                  </div>
                ) : null}
                {typeof title === 'string' ? (
                  <IFText
                    className={Styles.Title}
                    style={
                      centerTitle ? { flexGrow: 1, textAlign: 'center' } : {}
                    }
                  >
                    {title}
                  </IFText>
                ) : (
                  <div>{title}</div>
                )}
              </div>

              <div
                className={
                  modalCloseDisabled ? Styles.ExitIconDisabled : Styles.ExitIcon
                }
              >
                <ClearIcon
                  onClick={handleClose}
                  sx={{ color: Colors.exitIcon }}
                />
              </div>
            </div>
            {children}
          </Box>
        )}
      </StyledModal>
    )
  },
)

IFModal.propTypes = {
  children: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  height: PropTypes.string,
  open: PropTypes.bool,
  backdrop: PropTypes.elementType,
  onClose: PropTypes.func,
  modalCloseDisabled: PropTypes.bool,
}

export default IFModal
