import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { UserTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import IFFilterType from 'Enums/IFFilterType'
import history from 'history/browser'
import { usersFilter, userTransactionsFilter } from 'Constants/Filters'
import InfinityEnums from 'Enums/InfinityEnums'

export const fetchUserListLoading = (state) => {
  return {
    ...state,
    fetchUserListRequestState: RequestState.LOADING,
  }
}

export const fetchUserListLoadingMore = (state) => {
  return {
    ...state,
    fetchUserListRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchUserListSuccess = (
  state,
  { offset, users, nextOffset, index },
) => {
  let result = []
  for (let j = 0; j < users.length; j++) {
    if (!state.users.some((user) => user.appUserId === users[j].appUserId)) {
      result.push(users[j])
    }
  }

  if (offset === 0) {
    return {
      ...state,
      users: [...users],
      selectedUser: users[index],
      usersPaginationOffset: nextOffset,
      fetchUserListRequestState: RequestState.SUCCEEDED,
      selectedUserIndex: index,
    }
  } else {
    let selectedIndex = 0
    const usersList = state.users
    if (usersList.length !== 0) {
      const stateSelectedIndex = state.selectedUserIndex
      const selectedUser = usersList[stateSelectedIndex]
      let userOutOfPlace = false

      if (users[0] && selectedUser.updatedAt < users[0]['updatedAt'])
        userOutOfPlace = true

      if (userOutOfPlace) {
        const outOfPlaceUserIndex = users.findIndex(
          (user) => user._id === selectedUser._id,
        )
        if (outOfPlaceUserIndex !== -1)
          selectedIndex = usersList.length + outOfPlaceUserIndex - 1
        else selectedIndex = usersList.length + users.length - 1
      } else selectedIndex = state.selectedUserIndex
    }
    let combinedUsers = [...state.users, ...result]
    combinedUsers.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1))
    return {
      ...state,
      users: [...combinedUsers],
      usersPaginationOffset: nextOffset,
      fetchUserListRequestState: RequestState.SUCCEEDED,
      selectedUserIndex: selectedIndex,
    }
  }
}

export const fetchUserListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserListRequestState: requestState,
  }
}

export const inviteUserLoading = (state) => {
  return {
    ...state,
    inviteUserRequestState: RequestState.LOADING,
  }
}

export const inviteUserSuccess = (state) => {
  return {
    ...state,
    inviteUserRequestState: RequestState.SUCCEEDED,
  }
}

export const inviteUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    inviteUserRequestState: requestState,
  }
}

export const setSelectedUserIndex = (state, { index }) => {
  if (index === -1) {
    return {
      ...state,
      selectedUser: null,
      selectedUserIndex: index,
    }
  } else if (state.users[index]._id === state.selectedUser._id)
    return {
      ...state,
    }
  else
    return {
      ...state,
      selectedUser: state.users[index],
      selectedUserIndex: index,
    }
}

export const fetchUserDetailsLoading = (state) => {
  return {
    ...state,
    fetchUserDetailsRequestState: RequestState.LOADING,
  }
}

export const fetchUserDetailsSuccess = (state, { user }) => {
  return {
    ...state,
    selectedUser: user,
    fetchUserDetailsRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchUserDetailsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserDetailsRequestState: requestState,
  }
}

export const fetchUserTransactionsLoading = (state) => {
  return {
    ...state,
    fetchUserTransactionsRequestState: RequestState.LOADING,
  }
}

export const fetchUserTransactionsLoadingMore = (state) => {
  return {
    ...state,
    fetchUserTransactionsRequestState: RequestState.LOADING_MORE,
  }
}

export const fetchUserTransactionsSuccess = (
  state,
  { offset, transactions, nextOffset },
) => {
  let result = []
  for (let j = 0; j < transactions.length; j++) {
    if (
      !state.userTransactions.some(
        (transaction) => transaction._id === transactions[j]._id,
      )
    ) {
      result.push(transactions[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      userTransactions: [...transactions],
      userTransactionsPaginationOffset: nextOffset,
      fetchUserTransactionsRequestState: RequestState.SUCCEEDED,
    }
  } else
    return {
      ...state,
      userTransactions: [...state.userTransactions, ...result],
      userTransactionsPaginationOffset: nextOffset,
      fetchUserTransactionsRequestState: RequestState.SUCCEEDED,
    }
}

export const fetchUserTransactionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserTransactionsRequestState: requestState,
  }
}

export const addUserSubscriptionsLoading = (state) => {
  return {
    ...state,
    addUserSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const addUserSubscriptionsSuccess = (state, { subscriptions }) => {
  const subs = [
    ...new Set(state.selectedUser.subscriptions.concat(subscriptions)),
  ]
  const updatedUser = state.selectedUser
  updatedUser.subscriptions = subs
  return {
    ...state,
    selectedUser: updatedUser,
    addUserSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const addUserSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addUserSubscriptionsRequestState: requestState,
  }
}

export const deleteUserSubscriptionsLoading = (state) => {
  return {
    ...state,
    deleteUserSubscriptionsRequestState: RequestState.LOADING,
  }
}

export const deleteUserSubscriptionsSuccess = (state, { subscriptions }) => {
  const oldSubscriptions = state.selectedUser.subscriptions
  const filteredSubscriptions = oldSubscriptions.filter((sub) => {
    return subscriptions.indexOf(sub.subscription._id) === -1
  })
  const updatedUser = state.selectedUser
  updatedUser.subscriptions = filteredSubscriptions
  return {
    ...state,
    selectedUser: updatedUser,
    deleteUserSubscriptionsRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteUserSubscriptionsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteUserSubscriptionsRequestState: requestState,
  }
}

export const addUserAccessGroupsLoading = (state) => {
  return {
    ...state,
    addUserAccessGroupsRequestState: RequestState.LOADING,
  }
}

export const addUserAccessGroupsSuccess = (state, { accessGroups }) => {
  const userAccessGroups = [
    ...new Set(state.selectedUser.accessGroups.concat(accessGroups)),
  ]
  const updatedUser = state.selectedUser
  updatedUser.accessGroups = userAccessGroups
  return {
    ...state,
    selectedUser: updatedUser,
    addUserAccessGroupsRequestState: RequestState.SUCCEEDED,
  }
}

export const addUserAccessGroupsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    addUserAccessGroupsRequestState: requestState,
  }
}

export const deleteUserAccessGroupsLoading = (state) => {
  return {
    ...state,
    deleteUserAccessGroupsRequestState: RequestState.LOADING,
  }
}

export const deleteUserAccessGroupsSuccess = (state, { accessGroups }) => {
  accessGroups = accessGroups.map((group) => {
    return group.id
  })
  const oldAccessGroups = state.selectedUser.accessGroups
  const filteredAccessGroups = oldAccessGroups.filter((group) => {
    return accessGroups.indexOf(group._id) === -1
  })
  const updatedUser = state.selectedUser
  updatedUser.accessGroups = filteredAccessGroups
  return {
    ...state,
    selectedUser: updatedUser,
    deleteUserAccessGroupsRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteUserAccessGroupsFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteUserAccessGroupsRequestState: requestState,
  }
}

export const clearUserTransactions = (state) => {
  return {
    ...state,
    userTransactions: [],
  }
}

export const resetUserState = (state) => {
  return {
    ...state,
    // selectedUser: {},
    fetchUserTransactionsRequestState: RequestState.UNINITIALIZED,
    userTransactionsPaginationOffset: 0,
    userTransactionsFilter: userTransactionsFilter,
  }
}

export const setUserTransactionsFilter = (state, { newFilter }) => {
  return {
    ...state,
    userTransactionsFilter: newFilter,
  }
}

export const setUserTransactionsPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    userTransactionsPaginationOffset: offset,
  }
}

export const deleteUserLoading = (state) => {
  return {
    ...state,
    deleteUserRequestState: RequestState.LOADING,
  }
}

export const deleteUserSuccess = (state, { userId }) => {
  if (state.users.some((user) => user._id === userId)) {
    const newUsers = state.users.filter((user) => user._id !== userId)
    return {
      ...state,
      users: [...newUsers],
      selectedUserIndex: 0,
      deleteUserRequestState: RequestState.SUCCEEDED,
      usersCount: state.usersCount - 1,
    }
  } else return { ...state }
}

export const deleteUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteUserRequestState: requestState,
  }
}

export const setPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    usersPaginationOffset: offset,
  }
}

export const setUserTransactionPaginationOffset = (state, { offset }) => {
  return {
    ...state,
    userTransactionsPaginationOffset: offset,
  }
}
export const setUsersFilter = (state, { filter }) => {
  return {
    ...state,
    usersFilter: filter,
  }
}
export const clearUsers = (state, {}) => {
  return {
    ...state,
    users: [],
    usersPaginationOffset: 0,
    selectedUser: null,
    selectedUserIndex: -1,
    userTransactions: [],
    fetchUserListRequestState: RequestState.UNINITIALIZED,
    fetchUserTransactionsRequestState: RequestState.UNINITIALIZED,
    userTransactionsPaginationOffset: 0,
    usersFilter: [...usersFilter],
  }
}
export const fetchUserListCountLoading = (state) => {
  return {
    ...state,
    fetchUserListCountRequestState: RequestState.LOADING,
  }
}

export const fetchUserListCountSuccess = (state, { usersCount }) => {
  return {
    ...state,
    usersCount: usersCount,
    fetchUserListCountRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchUserListCountFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserListCountRequestState: requestState,
  }
}
export const fetchUserSessionListLoading = (state) => {
  return {
    ...state,
    fetchUserSessionListRequestState: RequestState.LOADING,
  }
}

export const fetchUserSessionListSuccess = (state, { sessions }) => {
  return {
    ...state,
    userSessions: sessions,
    fetchUserSessionListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchUserSessionListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchUserSessionListRequestState: requestState,
  }
}
export const emitterUpdateUserSessionStatus = (state, { session }) => {
  if (session.uid) {
    let newSessions = state.userSessions
    for (const oldSession of newSessions) {
      if (oldSession.chargePoint.uid !== session.uid) {
        continue
      }
      let updatedConnector = session.chargePoint.connectors.find(
        (connector) => connector.uid === oldSession.connectorUid,
      )
      let sessionConnectorIndex = oldSession.chargePoint.connectors.findIndex(
        (connector) => connector.uid === oldSession.connectorUid,
      )
      if (sessionConnectorIndex !== -1) {
        if (
          updatedConnector.status === InfinityEnums.ConnectorStatus.AVAILABLE
        ) {
          newSessions.splice(sessionConnectorIndex, 1)
        } else {
          oldSession.chargePoint.connectors[sessionConnectorIndex] =
            updatedConnector
        }
      }
    }
    return {
      ...state,
      userSessions: [...newSessions],
    }
  } else {
    let userSession = session.data
    let newSessions = state.userSessions
    let index = newSessions.findIndex(
      (oldSession) => oldSession._id === userSession._id,
    )
    if (index !== -1) {
      if (userSession.status === InfinityEnums.ChargingSessionStatus.ENDED) {
        newSessions.splice(index, 1)
      } else {
        newSessions[index].transaction = userSession.transaction
        newSessions[index].status = userSession.status
        newSessions[index].updatedAt = userSession.updatedAt
      }
    } else return { ...state }
    return {
      ...state,
      userSessions: [...newSessions],
    }
  }
}
export const fetchLocalListFilteredUsersLoading = (state) => {
  return {
    ...state,
    fetchLocalListFilteredUsersRequestState: RequestState.LOADING,
  }
}

export const fetchLocalListFilteredUsersSuccess = (
  state,
  { offset, users, nextOffset, index },
) => {
  let result = []
  for (let j = 0; j < users.length; j++) {
    if (
      !state.localListFilteredUsers.some(
        (user) => user.appUserId === users[j].appUserId,
      )
    ) {
      result.push(users[j])
    }
  }
  if (offset === 0) {
    return {
      ...state,
      localListFilteredUsers: [...users],
      localListFilteredUsersPaginationOffset: nextOffset,
      fetchLocalListFilteredUsersRequestState: RequestState.SUCCEEDED,
    }
  } else {
    let combinedUsers = [...state.localListFilteredUsers, ...result]
    return {
      ...state,
      localListFilteredUsers: [...combinedUsers],
      localListFilteredUsersPaginationOffset: nextOffset,
      fetchLocalListFilteredUsersRequestState: RequestState.SUCCEEDED,
    }
  }
}

export const fetchLocalListFilteredUsersFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchLocalListFilteredUsersRequestState: requestState,
  }
}
export const clearLocalListFilteredUsers = (state, {}) => {
  return {
    ...state,
    localListFilteredUsers: [],
    localListFilteredUsersPaginationOffset: 0,
  }
}
export const reducer = createReducer(INITIAL_STATE, {
  [UserTypes.FETCH_USER_LIST_LOADING]: fetchUserListLoading,
  [UserTypes.FETCH_USER_LIST_LOADING_MORE]: fetchUserListLoadingMore,
  [UserTypes.FETCH_USER_LIST_SUCCESS]: fetchUserListSuccess,
  [UserTypes.FETCH_USER_LIST_FAIL]: fetchUserListFail,
  [UserTypes.SET_SELECTED_USER_INDEX]: setSelectedUserIndex,
  [UserTypes.FETCH_USER_DETAILS_LOADING]: fetchUserDetailsLoading,
  [UserTypes.FETCH_USER_DETAILS_SUCCESS]: fetchUserDetailsSuccess,
  [UserTypes.FETCH_USER_DETAILS_FAIL]: fetchUserDetailsFail,
  [UserTypes.FETCH_USER_TRANSACTIONS_LOADING]: fetchUserTransactionsLoading,
  [UserTypes.FETCH_USER_TRANSACTIONS_LOADING_MORE]:
    fetchUserTransactionsLoadingMore,
  [UserTypes.FETCH_USER_TRANSACTIONS_SUCCESS]: fetchUserTransactionsSuccess,
  [UserTypes.FETCH_USER_TRANSACTIONS_FAIL]: fetchUserTransactionsFail,
  [UserTypes.ADD_USER_SUBSCRIPTIONS_LOADING]: addUserSubscriptionsLoading,
  [UserTypes.ADD_USER_SUBSCRIPTIONS_SUCCESS]: addUserSubscriptionsSuccess,
  [UserTypes.ADD_USER_SUBSCRIPTIONS_FAIL]: addUserSubscriptionsFail,
  [UserTypes.DELETE_USER_SUBSCRIPTIONS_LOADING]: deleteUserSubscriptionsLoading,
  [UserTypes.DELETE_USER_SUBSCRIPTIONS_SUCCESS]: deleteUserSubscriptionsSuccess,
  [UserTypes.DELETE_USER_SUBSCRIPTIONS_FAIL]: deleteUserSubscriptionsFail,
  [UserTypes.ADD_USER_ACCESS_GROUPS_LOADING]: addUserAccessGroupsLoading,
  [UserTypes.ADD_USER_ACCESS_GROUPS_SUCCESS]: addUserAccessGroupsSuccess,
  [UserTypes.ADD_USER_ACCESS_GROUPS_FAIL]: addUserAccessGroupsFail,
  [UserTypes.DELETE_USER_ACCESS_GROUPS_LOADING]: deleteUserAccessGroupsLoading,
  [UserTypes.DELETE_USER_ACCESS_GROUPS_SUCCESS]: deleteUserAccessGroupsSuccess,
  [UserTypes.DELETE_USER_ACCESS_GROUPS_FAIL]: deleteUserAccessGroupsFail,
  [UserTypes.INVITE_USER_LOADING]: inviteUserLoading,
  [UserTypes.INVITE_USER_SUCCESS]: inviteUserSuccess,
  [UserTypes.INVITE_USER_FAIL]: inviteUserFail,
  [UserTypes.CLEAR_USER_TRANSACTIONS]: clearUserTransactions,
  [UserTypes.RESET_USER_STATE]: resetUserState,
  [UserTypes.SET_USER_TRANSACTIONS_FILTER]: setUserTransactionsFilter,
  [UserTypes.SET_USER_TRANSACTIONS_PAGINATION_OFFSET]:
    setUserTransactionsPaginationOffset,
  [UserTypes.DELETE_USER_LOADING]: deleteUserLoading,
  [UserTypes.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [UserTypes.DELETE_USER_FAIL]: deleteUserFail,
  [UserTypes.SET_PAGINATION_OFFSET]: setPaginationOffset,
  [UserTypes.SET_USER_TRANSACTION_PAGINATION_OFFSET]:
    setUserTransactionPaginationOffset,
  [UserTypes.SET_USERS_FILTER]: setUsersFilter,
  [UserTypes.CLEAR_USERS]: clearUsers,
  [UserTypes.FETCH_USER_LIST_COUNT_LOADING]: fetchUserListCountLoading,
  [UserTypes.FETCH_USER_LIST_COUNT_SUCCESS]: fetchUserListCountSuccess,
  [UserTypes.FETCH_USER_LIST_COUNT_FAIL]: fetchUserListCountFail,
  [UserTypes.FETCH_USER_SESSION_LIST_LOADING]: fetchUserSessionListLoading,
  [UserTypes.FETCH_USER_SESSION_LIST_SUCCESS]: fetchUserSessionListSuccess,
  [UserTypes.FETCH_USER_SESSION_LIST_FAIL]: fetchUserSessionListFail,
  [UserTypes.EMITTER_UPDATE_USER_SESSION_STATUS]:
    emitterUpdateUserSessionStatus,
  [UserTypes.FETCH_LOCAL_LIST_FILTERED_USERS_LOADING]:
    fetchLocalListFilteredUsersLoading,
  [UserTypes.FETCH_LOCAL_LIST_FILTERED_USERS_SUCCESS]:
    fetchLocalListFilteredUsersSuccess,
  [UserTypes.FETCH_LOCAL_LIST_FILTERED_USERS_FAIL]:
    fetchLocalListFilteredUsersFail,
  [UserTypes.CLEAR_LOCAL_LIST_FILTERED_USERS]: clearLocalListFilteredUsers,
})
