import React from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Styles from './ChargingScheduleListItem.module.css'
import Colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import { useState } from 'react'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { formatChargingSchedule } from '../../Utils/Format'
import { ChargingScheduleChart } from 'Components'
import { ChargingSchedulePeriodList } from 'Containers'

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px !important',
    width: '100%',
  },
})

const AccordionSummary = withStyles({
  root: {
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: Colors.ChargePointLogListItemHover,
    },
  },
  content: {
    '&$expanded': {
      marginTop: '4px',
      marginBottom: '8px',
    },
    marginTop: '4px',
    marginBottom: '8px',
  },
  expanded: {},
})(MuiAccordionSummary)

const StyledAccordionDetails = withStyles({
  root: {
    padding: '0px',
  },
})(AccordionDetails)

const ChargingScheduleListItem = ({ day, maxCurrent, itemRef }) => {
  const [chargingPeriods, setChargingPeriods] = useState([])

  const classes = useStyles()

  return (
    <div className={Styles.Container}>
      <Accordion className={classes.root}>
        <AccordionSummary
          classes={{ content: classes.content }}
          expandIcon={<ExpandMoreIcon />}
          className={classes.AccordionSummary}
        >
          <div className={Styles.ChargingScheduleChartContainer}>
            <ChargingScheduleChart
              day={day}
              chartHeight={50}
              seriesData={chargingPeriods}
              hideAxis={false}
            />
          </div>
        </AccordionSummary>
        <StyledAccordionDetails>
          <div className={Styles.ChargingSchedulePeriodListContainer}>
            <ChargingSchedulePeriodList
              onValueSelected={(chargingPeriods) => {
                const formattedChargingSchedule =
                  formatChargingSchedule(chargingPeriods)
                setChargingPeriods(formattedChargingSchedule)
              }}
              defaultMaxCurrentInput={maxCurrent}
              ref={itemRef}
            />
          </div>
        </StyledAccordionDetails>
      </Accordion>
    </div>
  )
}

ChargingScheduleListItem.propTypes = {
  day: PropTypes.string,
  maxCurrent: PropTypes.number,
}

export default ChargingScheduleListItem
