import Styles from './ChargePointSubscriptionListItem.module.css'
import { useTranslation } from 'react-i18next'
import IFText from 'Components/IFText/IFText'
import { DoNotDisturbOn, AddCircle } from '@mui/icons-material'
import { Colors } from 'Theme'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@mui/material/Fade'
import { withStyles } from '@material-ui/styles'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { isOverflowActive } from 'Utils/Calculations'
import { isPropsMatch } from 'Utils/PropsMatch'

const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '0rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
  },
})(Tooltip)

const StyledOverflowTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '-1.4rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
    width: 'max-content',
    maxWidth: '100%',
    marginLeft: '-0.35rem',
    paddingRight: '0.6rem',
  },
})(Tooltip)

const ChargePointSubscriptionListItem = ({
  _id,
  name,
  tariff = {},
  expiryDate,
  consumedPower,
  power,
  isEditing,
  isGlobal,
  onClick = () => {},
}) => {
  const [toBeRemoved, setToBeRemoved] = useState(false)
  const { t } = useTranslation()
  const { level1, level2, level3 } = tariff
  const textRef = useRef()

  useEffect(() => {
    if (!isEditing) setToBeRemoved(false)
  }, [isEditing])

  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        {isEditing ? (
          isGlobal ? null : (
            <div
              className={Styles.IconContainer}
              onClick={() => {
                setToBeRemoved((prevState) => {
                  onClick(_id, !prevState)
                  return !prevState
                })
              }}
            >
              {toBeRemoved ? (
                <AddCircle
                  className={Styles.Icon}
                  style={{
                    color: Colors.ChargePointSubscriptionListItem.AddIcon,
                  }}
                />
              ) : (
                <DoNotDisturbOn
                  className={Styles.Icon}
                  style={{
                    color: Colors.ChargePointSubscriptionListItem.RemoveIcon,
                  }}
                />
              )}
            </div>
          )
        ) : null}

        {isOverflowActive(textRef.current) ? (
          <StyledOverflowTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 60 }}
            title={
              <div>
                <IFText
                  className={Styles.ToolTipOverflownText}
                  style={{
                    color: toBeRemoved
                      ? Colors.ChargePointSubscriptionListItem.gray
                      : Colors.ChargePointSubscriptionListItem.black,
                  }}
                >
                  {name}
                </IFText>
              </div>
            }
            placement="bottom-start"
          >
            <div className={Styles.TextContainer}>
              <IFText
                ref={textRef}
                className={Styles.Text}
                style={{
                  color: toBeRemoved
                    ? Colors.ChargePointSubscriptionListItem.gray
                    : Colors.ChargePointSubscriptionListItem.black,
                }}
              >
                {name}
              </IFText>
            </div>
          </StyledOverflowTooltip>
        ) : (
          <div className={Styles.TextContainer}>
            <IFText
              ref={textRef}
              className={Styles.Text}
              style={{
                color: toBeRemoved
                  ? Colors.ChargePointSubscriptionListItem.gray
                  : Colors.ChargePointSubscriptionListItem.black,
              }}
            >
              {name}
            </IFText>
          </div>
        )}

        {!isEditing && (
          <StyledTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 60 }}
            title={
              <div className={Styles.ToolTipWrapper}>
                <div>
                  <IFText className={Styles.ToolTipTitleText}>
                    {t('SubscriptionListItem.ExpiryDate')}
                  </IFText>
                  <IFText className={Styles.ToolTipTitleText}>
                    {t('SubscriptionListItem.ConsumedEnergy')}
                  </IFText>
                </div>
                <div className={Styles.ToolTipRightContainer}>
                  <IFText
                    style={{
                      color: Colors.ChargePointSubscriptionListItem.gray,
                    }}
                    className={Styles.ToolTipValueText}
                  >
                    {expiryDate ? expiryDate : '-'}
                  </IFText>
                  <IFText
                    style={{
                      color: Colors.ChargePointSubscriptionListItem.gray,
                    }}
                    className={Styles.ToolTipValueText}
                  >
                    {power && consumedPower
                      ? `${consumedPower.toFixed(2)} / ${power.toFixed(0)}`
                      : '-'}
                  </IFText>
                </div>
              </div>
            }
            placement="bottom-start"
          >
            <InfoIcon className={Styles.infoIcon} />
          </StyledTooltip>
        )}
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.PriceTextContainer}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointSubscriptionListItem.gray
                : Colors.ChargePointSubscriptionListItem.black,
            }}
            className={Styles.Text}
          >
            {Number(level1 * 1000).toFixed(2)}
          </IFText>
        </div>
        <div className={`${Styles.PriceTextContainer}`}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointSubscriptionListItem.gray
                : Colors.ChargePointSubscriptionListItem.black,
            }}
            className={Styles.Text}
          >
            {Number(level2 * 1000).toFixed(2)}
          </IFText>
        </div>
        <div className={Styles.PriceTextContainer}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointSubscriptionListItem.gray
                : Colors.ChargePointSubscriptionListItem.black,
            }}
            className={Styles.Text}
          >
            {Number(level3 * 1000).toFixed(2)}{' '}
          </IFText>
        </div>
      </div>
    </div>
  )
}

ChargePointSubscriptionListItem.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  tariff: PropTypes.object,
  expiryDate: PropTypes.string,
  consumedPower: PropTypes.string,
  power: PropTypes.string,
  isEditing: PropTypes.bool,
  onClick: PropTypes.func,
  isGlobal: PropTypes.bool,
}
function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    '_id',
    'name',
    'tariff',
    'expiryDate',
    'consumedPower',
    'power',
    'isEditing',
    'isGlobal',
    'onClick',
  ])
}
export default React.memo(ChargePointSubscriptionListItem, shouldSkipRender)
