const IFFilterType = {
  KEYWORD: 0,
  SELECT: 1,
  CHECK: 2,
  DATE_RANGE: 3,
  VALUE_RANGE: 4,
  SEARCH_TERM: 5,
  SLIDER: 6,
  VALUE_RANGE_DECIMAL: 7,
}

export default IFFilterType
