import { put, call, select, spawn } from 'redux-saga/effects'
import { handleError, userApiService } from 'Services/ApiService.js'
import UserActions from 'Stores/User/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import history from 'history/browser'

var base64 = require('base-64')
export function* fetchUserListCount({ filter }) {
  yield put(UserActions.fetchUserListCountLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(userApiService.get, '/me/count', {
      params: {
        filter: filter,
      },
    })
    yield put(UserActions.fetchUserListCountSuccess(data.usersCount))
  } catch (e) {
    yield put(UserActions.fetchUserListCountFail(e))
    if (e.message !== 'Network Error') handleError(e)
  }
}
export function* fetchUserList({ filter, offset, onNotFound }) {
  if (offset === 0) {
    yield put(UserActions.fetchUserListLoading())
    yield spawn(fetchUserListCount, { filter })
  } else yield put(UserActions.fetchUserListLoadingMore())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(userApiService.get, '/me', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    const slashArray = history.location.pathname.split('/')
    const userIdParam = slashArray[2]
    if (userIdParam && userIdParam.match(/^[0-9a-fA-F]{24}$/) && offset === 0) {
      const index = data.users.findIndex((user) => user._id === userIdParam)
      if (index !== -1) {
        yield put(
          UserActions.fetchUserListSuccess(
            offset,
            data.users,
            data.nextOffset,
            index,
          ),
        )
      } else {
        const userData = yield call(userApiService.get, `/${userIdParam}`)

        if (!userData.data) {
          onNotFound()
          return
        }
        let { users } = data
        users = [
          ...users,
          {
            _id: userData.data._id,
            email: userData.data.email,
            name: userData.data.name,
            updatedAt: userData.data.updatedAt,
            appUserId: userData.data.appUserId,
            isActive: userData.data.isActive,
          },
        ]

        yield put(
          UserActions.fetchUserListSuccess(
            offset,
            users,
            data.nextOffset,
            data.users.length,
          ),
        )
      }
    } else {
      yield put(
        UserActions.fetchUserListSuccess(
          offset,
          data.users,
          data.nextOffset,
          0,
        ),
      )
    }
  } catch (e) {
    yield put(UserActions.fetchUserListFail(e))
    handleError(e)
    e = yield e
    if (e.status === 404) onNotFound()
  }
}

export function* fetchUserDetails({ userId }) {
  yield put(UserActions.fetchUserDetailsLoading())
  try {
    const { data } = yield call(userApiService.get, `/${userId}`)
    yield put(UserActions.fetchUserDetailsSuccess(data))
    return data
  } catch (e) {
    yield put(UserActions.fetchUserDetailsFail(e))
    handleError(e)
  }
}

export function* inviteUser({ invitations, onSuccess }) {
  yield put(UserActions.inviteUserLoading())
  try {
    const { data } = yield call(userApiService.post, '/invite', {
      ...invitations,
    })
    yield put(UserActions.inviteUserSuccess())
    onSuccess()
  } catch (e) {
    yield put(UserActions.inviteUserFail(e))
    handleError(e)
  }
}

export function* inviteUserBulk({ invitations, onSuccess }) {
  yield put(UserActions.inviteUserLoading())
  try {
    const { data } = yield call(userApiService.post, '/invite/bulk', {
      ...invitations,
    })
    yield put(UserActions.inviteUserSuccess())
    onSuccess()
  } catch (e) {
    yield put(UserActions.inviteUserFail(e))
    handleError(e)
  }
}

export function* fetchUserTransactions({ filter, offset, userId, ascending }) {
  if (offset === 0) yield put(UserActions.fetchUserTransactionsLoading())
  else yield put(UserActions.fetchUserTransactionsLoadingMore())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(userApiService.get, `/${userId}/transactions`, {
      params: {
        filter: filter,
        offset: offset,
        ascending: ascending,
      },
    })

    yield put(
      UserActions.fetchUserTransactionsSuccess(
        offset,
        data.transactions,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(UserActions.fetchUserTransactionsFail(e))
    handleError(e)
  }
}

export function* addUserSubscriptions({ userId, subscriptions }) {
  yield put(UserActions.addUserSubscriptionsLoading())
  try {
    const { data } = yield call(
      userApiService.post,
      `/${userId}/subscriptions/bulk`,
      {
        subscriptions,
      },
    )
    yield put(UserActions.addUserSubscriptionsSuccess(data.newSubscriptions))
  } catch (e) {
    yield put(UserActions.addUserSubscriptionsFail(e))
    handleError(e)
  }
}

export function* deleteUserSubscriptions({ userId, subscriptions }) {
  yield put(UserActions.deleteUserSubscriptionsLoading())
  try {
    const { data } = yield call(
      userApiService.delete,
      `/${userId}/subscriptions/bulk`,
      {
        data: { subscriptions },
      },
    )
    yield put(UserActions.deleteUserSubscriptionsSuccess(subscriptions))
  } catch (e) {
    yield put(UserActions.deleteUserSubscriptionsFail(e))
    handleError(e)
  }
}

export function* addUserAccessGroups({ userId, accessGroups }) {
  yield put(UserActions.addUserAccessGroupsLoading())
  try {
    const { data } = yield call(
      userApiService.post,
      `/${userId}/accessGroups/bulk`,
      {
        accessGroups,
      },
    )
    yield put(UserActions.addUserAccessGroupsSuccess(data.newAccessGroups))
  } catch (e) {
    yield put(UserActions.addUserAccessGroupsFail(e))
    handleError(e)
  }
}

export function* deleteUserAccessGroups({ userId, accessGroups }) {
  yield put(UserActions.deleteUserAccessGroupsLoading())
  try {
    const { data } = yield call(
      userApiService.delete,
      `/${userId}/accessGroups/bulk`,
      {
        data: { accessGroups },
      },
    )

    yield put(UserActions.deleteUserAccessGroupsSuccess(accessGroups))
  } catch (e) {
    yield put(UserActions.deleteUserAccessGroupsFail(e))
    handleError(e)
  }
}

export function* deleteUser({ userId }) {
  yield put(UserActions.deleteUserLoading())
  try {
    const tenant = yield select(AuthSelectors.getTenant)
    const { data } = yield call(
      userApiService.delete,
      `/${userId}/tenants/${tenant._id}`,
    )

    yield put(UserActions.deleteUserSuccess(userId))
  } catch (e) {
    yield put(UserActions.deleteUserFail(e))
    handleError(e)
  }
}

export function* fetchUserSessionList({ userId }) {
  yield put(UserActions.fetchUserSessionListLoading())
  try {
    const { data } = yield call(
      userApiService.get,
      `/${userId}/chargingSessions`,
    )
    yield put(UserActions.fetchUserSessionListSuccess(data.chargingSessions))
  } catch (e) {
    yield put(UserActions.fetchUserSessionListFail(e))
    if (e.message !== 'Network Error') handleError(e)
  }
}

export function* fetchLocalListFilteredUsers({ filter, offset, onNotFound }) {
  yield put(UserActions.fetchLocalListFilteredUsersLoading())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(userApiService.get, '/chargingTokenCount', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    yield put(
      UserActions.fetchLocalListFilteredUsersSuccess(
        offset,
        data.users,
        data.nextOffset,
        0,
      ),
    )
  } catch (e) {
    yield put(UserActions.fetchLocalListFilteredUsersFail(e))
    handleError(e)
    e = yield e
    if (e.status === 404) onNotFound()
  }
}
