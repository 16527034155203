import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './AlertItem.module.css'
import { IFText, IFsvg, IFToggle } from 'Components'
import React, { useState, useEffect, useRef } from 'react'
import InfinityEnums from 'Enums/InfinityEnums'
import PropTypes from 'prop-types'

const StatusContainer = styled.div`
  background-color: ${Colors.AlertOfflineBackground};
  color: ${(props) => props.status};
`

const Container = styled.div`
  &:hover {
    background-color: ${(props) => props.hoverColor} !important;
  }
`

const AlertItem = ({
  name,
  trackedStatus,
  duration,
  percentageAffected,
  isIncluded,
  isGlobal,
  isEdit,
  onIconClick,
  onToggle,
  disableHover = true,
}) => {
  const [isRemoveIcon, setIsRemoveIcon] = useState(true)
  const [convertedTime, setConvertedTime] = useState({ hours: 0, minutes: 0 })
  const toggleRef = useRef()

  useEffect(() => {
    if (!isEdit) {
      setIsRemoveIcon(true)
      toggleRef.current?.setValue(isIncluded)
    }
  }, [isEdit])
  useEffect(() => {
    if (duration) {
      const hours = Math.floor(duration / 60)
      const remainingMinutes = duration % 60

      setConvertedTime({
        hours: hours,
        minutes: remainingMinutes,
      })
    }
  }, [duration])

  const handleStatusTextColor = (status) => {
    switch (status) {
      case InfinityEnums.ConnectorStatus.OFFLINE:
        return Colors.AlertOfflineText
      case InfinityEnums.ConnectorStatus.FAULTED:
        return Colors.AlertFaultedText
      case InfinityEnums.ChargePointUptimeStatus.UNSTABLE:
        return Colors.AlertUnstableText
      default:
        return Colors.AlertOfflineText
    }
  }
  return (
    <Container
      className={Styles.Container}
      {...(!disableHover ? { hoverColor: Colors.AlertItem.Hover } : {})}
    >
      <div className={Styles.LeftContainer}>
        <div
          className={Styles.NameContainer}
          style={
            isRemoveIcon
              ? { color: Colors.text }
              : { color: Colors.AlertItem.GreyedOut }
          }
        >
          {name}
        </div>
        <div className={Styles.StatusContainerWrapper}>
          {trackedStatus.map((status, index) => {
            let style = index === 0 ? {} : { marginLeft: '8px' }
            return (
              <StatusContainer
                status={handleStatusTextColor(status)}
                className={Styles.StatusContainer}
                style={{ ...style }}
              >
                <IFText className={Styles.StatusText}>{status}</IFText>
              </StatusContainer>
            )
          })}
        </div>
      </div>
      <div className={Styles.DurationContainer}>
        <IFsvg.Timer
          height={16}
          width={16}
          fill={isRemoveIcon ? Colors.black : Colors.AlertItem.GreyedOut}
          className={Styles.Icon}
        />
        <div
          className={Styles.DurationText}
          style={
            isRemoveIcon
              ? { color: Colors.text }
              : { color: Colors.AlertItem.GreyedOut }
          }
        >
          {convertedTime.hours
            ? convertedTime.hours + `h `
            : '' + convertedTime.minutes
            ? convertedTime.minutes + 'm'
            : ''}
        </div>
      </div>
      <div
        className={Styles.PercentageContainer}
        style={
          isRemoveIcon
            ? { color: Colors.text }
            : { color: Colors.AlertItem.GreyedOut }
        }
      >
        <IFsvg.Percentage
          height={16}
          width={16}
          fill={isRemoveIcon ? Colors.black : Colors.AlertItem.GreyedOut}
          className={Styles.Icon}
        />
        <div>{`${percentageAffected * 100}%`}</div>
      </div>
      <div className={Styles.ToggleContainer}>
        {isGlobal ? (
          <IFToggle
            ref={toggleRef}
            height={20}
            readOnly={!isEdit}
            defaultChecked={isIncluded}
            onChange={(value) => {
              if (isEdit) onToggle(value)
            }}
            marginLeftRight={12}
          />
        ) : isEdit ? (
          <div
            className={Styles.RemoveIcon}
            onClick={() => {
              onIconClick(!isRemoveIcon)
              setIsRemoveIcon((prev) => {
                return !prev
              })
            }}
          >
            {!isRemoveIcon ? (
              <IFsvg.AddRounded
                height={20}
                width={20}
                fill={Colors.AlertItem.AddIcon}
              />
            ) : (
              <IFsvg.RemoveRounded height={20} width={20} fill={Colors.red} />
            )}
          </div>
        ) : null}
      </div>
    </Container>
  )
}

AlertItem.propTypes = {
  name: PropTypes.string,
  trackedStatus: PropTypes.arrayOf(PropTypes.string),
  duration: PropTypes.number,
  percentageAffected: PropTypes.number,
  isIncluded: PropTypes.bool,
  isGlobal: PropTypes.bool,
  isEdit: PropTypes.bool,
  onIconClick: PropTypes.func,
  onToggle: PropTypes.func,
  disableHover: PropTypes.bool,
}

export default AlertItem
