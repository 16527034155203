/**
 * Images should be stored in the `App/Images` directory and referenced using variables defined here.
 */

const images = {
  logo: require('Assets/Images/logo.png'),
  infinity: require('../Assets/Images/Infinity.png'),
  newLogo: require('../Assets/Images/newLogo.png'),
  noSitesFilter: require('../Assets/Images/noSitesFilter.png'),
  noSites: require('../Assets/Images/noSites.png'),
  dashboard: require('../Assets/Images/dashboard.png'),
  noStations: require('../Assets/Images/noStations.png'),
  loginBackground: require('../Assets/Images/LoginBackground.jpeg'),
  NoConnection: require('../Assets/Images/NoConnection.png'),
  createAccountBackground: require('../Assets/Images/LoginBackground.jpeg'),
  noCards: require('../Assets/Images/cards.png'),
  noLogs: require('../Assets/Images/noLogs.png'),
  noConfig: require('../Assets/Images/noConfig.png'),
  noDiagnostics: require('../Assets/Images/diagnostic.png'),
  emptySpecs: require('../Assets/Images/EmptySpecs.png'),
  sessions: require('../Assets/Images/sessions.png'),
}

export default images
