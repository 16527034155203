import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  ChargingScheduleListItem,
  IFModal,
  IFButton,
  IFText,
  IFTextInput,
} from 'Components'

import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import Styles from './ChargingScheduleModal.module.css'
import moment from 'moment'
import { Colors } from 'Theme'
import { Send } from '@mui/icons-material'
import { formatChargingProfiles } from 'Utils/Format'
import validator from 'validator'
import RequestState from 'Enums/RequestState'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

TimeAgo.addDefaultLocale(en)

const ChargingScheduleModal = React.forwardRef(
  (
    {
      maxCurrent = 32,
      createChargePointChargingProfile,
      chargePoint,
      connectorIndex,
      createChargePointChargingProfileRequestState,
      setChargePointChargingProfileRequestState,
    },
    ref,
  ) => {
    const ChargingScheduleListRefs = Array.from({ length: 7 }).map(() =>
      React.createRef(),
    )

    const [maxCurrentInput, setMaxCurrentInput] = useState(maxCurrent)
    const [nameInput, setNameInput] = useState('')
    const { t } = useTranslation()

    return (
      <IFModal
        width={'44rem'}
        title={t('ChargingScheduleModal.Title')}
        ref={ref}
        height={'40rem'}
      >
        <div className={Styles.Wrapper}>
          <div className={Styles.Fields}>
            <IFText className={Styles.TextName}>
              {t('ChargingScheduleModal.Name')}
            </IFText>
            <IFTextInput
              className={Styles.NameInput}
              value={nameInput}
              onChange={(e) => setNameInput(e.target.value)}
              placeholder={t('ChargingScheduleModal.NamePlaceHolder')}
            />
          </div>
          <div className={Styles.Fields}>
            <IFText className={Styles.TextName}>
              {t('ChargingScheduleModal.MaxCurrent')}
            </IFText>
            <IFTextInput
              className={Styles.CurrentInput}
              value={maxCurrentInput}
              onChange={(e) => setMaxCurrentInput(e.target.value)}
            />
          </div>

          <div className={Styles.Container}>
            {[...Array(7)].map((e, index) => {
              return (
                <ChargingScheduleListItem
                  day={moment().day(index).format('ddd')}
                  maxCurrent={maxCurrent}
                  itemRef={ChargingScheduleListRefs[index]}
                />
              )
            })}
          </div>
          <IFButton
            text={t('ChargingScheduleModal.Send')}
            icon={<Send style={{ fontSize: '18px' }} />}
            color={Colors.primary}
            isFill={true}
            className={Styles.Button}
            isLoading={
              createChargePointChargingProfileRequestState ===
                RequestState.LOADING ||
              setChargePointChargingProfileRequestState === RequestState.LOADING
            }
            size={'sm'}
            isDead={validator.isEmpty(nameInput)}
            onClick={() => {
              let profile = formatChargingProfiles(
                ChargingScheduleListRefs,
                nameInput,
                maxCurrentInput,
              )
              createChargePointChargingProfile(
                profile,
                chargePoint._id,
                chargePoint.connectors[connectorIndex].uid,
              )
            }}
          />
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointChargingProfiles: (chargePointId, connectorId) =>
      dispatch(
        ChargePointActions.fetchChargePointChargingProfiles(
          chargePointId,
          connectorId,
        ),
      ),
    createChargePointChargingProfile: (
      chargingProfile,
      chargePointId,
      connectorId,
    ) =>
      dispatch(
        ChargePointActions.createChargePointChargingProfile(
          chargingProfile,
          chargePointId,
          connectorId,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargingProfiles: ChargePointSelectors.getChargingProfiles(state),
  fetchChargePointChargingProfilesRequestState:
    ChargePointSelectors.getFetchChargePointChargingProfilesRequestState(state),
  clearChargePointChargingProfileRequestState:
    ChargePointSelectors.getClearChargePointChargingProfileRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  createChargePointChargingProfileRequestState:
    ChargePointSelectors.getCreateChargePointChargingProfileRequestState(state),
  setChargePointChargingProfileRequestState:
    ChargePointSelectors.getSetChargePointChargingProfileRequestState(state),
})

ChargingScheduleModal.propTypes = {
  maxCurrent: PropTypes.number,
  createChargePointChargingProfile: PropTypes.func,
  chargePoint: PropTypes.object,
  connectorIndex: PropTypes.number,
  createChargePointChargingProfileRequestState: PropTypes.number,
  setChargePointChargingProfileRequestState: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargingScheduleModal)
