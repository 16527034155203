import { IFSkeleton } from 'Components'
import React from 'react'
import Styles from './ChargingProfileListItemSkeleton.module.css'

const ChargingProfileListItemSkeleton = () => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.NameContainer}>
        <IFSkeleton variant="text" width="8rem" height="1.5rem" />
      </div>
      <div className={Styles.MaxCurrentContainer}>
        <IFSkeleton variant="text" width="8rem" height="1.5rem" />
      </div>
      <div className={Styles.ArrowContainer}>
        <IFSkeleton variant="circular" width="1.5rem" height="1.5rem" />
      </div>
    </div>
  )
}

export default ChargingProfileListItemSkeleton
