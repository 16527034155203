const InfinityEnums = {
  ConnectorStatus: {
    AVAILABLE: 'Available',
    PREPARING: 'Preparing',
    CHARGING: 'Charging',
    SUSPENDEDEV: 'SuspendedEV',
    SUSPENDEDEVSE: 'SuspendedEVSE',
    FINISHING: 'Finishing',
    RESERVED: 'Reserved',
    UNAVAILABLE: 'Unavailable',
    FAULTED: 'Faulted',
    OFFLINE: 'Offline',
  },
  ConnectorType: {
    TYPE_2: 'TYPE_2',
    COMBO_CCS: 'COMBO_CCS',
    CHADEMO: 'CHADEMO',
    SCHUKO: 'SCHUKO',
  },
  TenantUserPermission: {
    CAN_VIEW_CHARGING_TOKEN: 1,
    CAN_EDIT_CHARGING_TOKEN: 2,

    CAN_VIEW_CHARGING: 3,
    CAN_EDIT_CHARGING: 4,

    CAN_VIEW_STATION_CHARGEPOINT: 5,
    CAN_EDIT_CHARGEPOINT: 6,
    CAN_DELETE_CHARGEPOINT: 7,
    CAN_EDIT_STATION: 8,

    CAN_VIEW_TENANT_USER: 9,
    CAN_EDIT_TENANT_USER: 10,

    CAN_VIEW_USER: 11,
    CAN_EDIT_USER: 12,

    CAN_VIEW_ACCESS_GROUPS: 13,
    CAN_EDIT_ACCESS_GROUPS: 14,

    CAN_VIEW_SUBSCRIPTION: 15,
    CAN_EDIT_SUBSCRIPTION: 16,

    CAN_VIEW_CHARGEPOINT_PRIVACY_RULE: 17,
    CAN_EDIT_CHARGEPOINT_PRIVACY_RULE: 18,

    CAN_EDIT_RULE: 19,

    CAN_EDIT_USER_ACCESS_GROUPS: 20,

    CAN_EDIT_TENANT: 21,

    CAN_ADD_STATION: 22,
    CAN_ADD_CHARGEPOINT: 23,

    CAN_START_TX: 24,
    CAN_STOP_TX: 25,
    CAN_RESERVE_CONNECTOR: 26,
    CAN_CANCEL_RESERVATION: 27,
    CAN_UNLOCK_CONNECTOR: 28,

    CAN_VIEW_CONFIGURATION: 29,
    CAN_EDIT_CONFIGURATION: 30,
    CAN_VIEW_DIAGNOSTICS: 31,
    CAN_REQUEST_DIAGNOSTICS: 32,
    CAN_VIEW_FIRMWARE: 33,
    CAN_UPLOAD_FIRMWARE: 34,
    CAN_USE_TERMINAL: 35,
    CAN_HARD_RESET_CHARGEPOINT: 36,
    CAN_SOFT_RESET_CHARGEPOINT: 37,
    CAN_CLEAR_CACHE: 38,
    CAN_TRIGGER_MESSAGE: 39,

    CAN_DOWNLOAD_TRANSACTIONS: 40,
    CAN_DOWNLOAD_STATION_LOGS: 41,

    CAN_VIEW_CHARGEPOINT_UPTIME: 42,
    CAN_VIEW_CONNECTOR_UTILIZATION: 43,

    CAN_VIEW_LOCAL_LIST: 44,
    CAN_EDIT_LOCAL_LIST: 45,

    CAN_VIEW_ALERT: 46,
    CAN_EDIT_ALERT: 47,
    CAN_VIEW_LOCAL_LIST: 44,
    CAN_EDIT_LOCAL_LIST: 45,

    CAN_VIEW_ALERT: 46,
    CAN_EDIT_ALERT: 47,

    CAN_VIEW_CHARGING_PROFILE: 48,
    CAN_EDIT_CHARGING_PROFILE: 49,

    CAN_GET_COMPOSITE_SCHEDULE: 50,

    CAN_VIEW_CHARGEPOINT_CHARGING_PROFILE: 51,
    CAN_EDIT_CHARGEPOINT_CHARGING_PROFILE: 52,
  },
  InvitationStatus: {
    ACCEPTED: 'Accepted',
    PENDING: 'Pending',
  },
  ResetType: {
    HARD: 'Hard',
    SOFT: 'Soft',
  },

  ChargingTokenStatus: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked',
    DEACTIVATED: 'Deactivated',
  },
  ChargingTokenType: {
    RFID: 'Rfid',
    VIRTUAL: 'Virtual',
  },
  ChargingSessionStatus: {
    STARTING: 'Starting',
    STARTED: 'Started',
    UPDATED: 'Updated',
    ENDING: 'Ending',
    ENDED: 'Ended',
  },
  ChargingSpeedLevels: {
    LEVEL1: 'level 1',
    LEVEL2: 'level 2',
    LEVEL3: 'level 3',
  },
  ConfigurationStatus: {
    ACCEPTED: 'Accepted',
    REJECTED: 'Rejected',
    REBOOT_REQUIRED: 'RebootRequired',
    NOT_SUPPORTED: 'NotSupported',
  },
  UploadDiagnosticsStatus: {
    IDLE: 'Idle',
    UPLOADING: 'Uploading',
    UPLOADED: 'Uploaded',
    UPLOAD_FAILED: 'UploadFailed',
  },
  MessageTrigger: {
    BOOT_NOTIFICATION: 'BootNotification',
    DIAGNOSTICS_STATUS_NOTIFICATION: 'DiagnosticsStatusNotification',
    FIRMWARE_STATUS_NOTIFICATION: 'FirmwareStatusNotification',
    HEARTBEAT: 'Heartbeat',
    METER_VALUES: 'MeterValues',
    STATUS_NOTIFICATION: 'StatusNotification',
  },
  OCPPActions: {
    // Outgoing
    CANCEL_RESERVATION: 'CancelReservation',
    CHANGE_AVAILABILITY: 'ChangeAvailability',
    CHANGE_CONFIGURATION: 'ChangeConfiguration',
    CLEAR_CACHE: 'ClearCache',
    CLEAR_CHARGING_PROFILE: 'ClearChargingProfile',
    DATA_TRANSFER: 'DataTransfer',
    GET_COMPOSITE_SCHEDULE: 'GetCompositeSchedule',
    GET_CONFIGURATION: 'GetConfiguration',
    GET_DIAGNOSTICS: 'GetDiagnostics',
    GET_LOCAL_LIST_VERSION: 'GetLocalListVersion',
    REMOTE_START_TRANSACTION: 'RemoteStartTransaction',
    REMOTE_STOP_TRANSACTION: 'RemoteStopTransaction',
    RESERVE_NOW: 'ReserveNow',
    RESET: 'Reset',
    SEND_LOCAL_LIST: 'SendLocalList',
    SET_CHARGING_PROFILE: 'SetChargingProfile',
    TRIGGER_MESSAGE: 'TriggerMessage',
    UNLOCK_CONNECTOR: 'UnlockConnector',
    UPDATE_FIRMWARE: 'UpdateFirmware',

    //Incoming
    BOOT_NOTIFICATION: 'BootNotification',
    AUTHORIZE: 'Authorize',
    DIAGNOSTICS_STATUS_NOTIFICATION: 'DiagnosticsStatusNotification',
    FIRMWARE_STATUS_NOTIFICATION: 'FirmwareStatusNotification',
    HEARTBEAT: 'Heartbeat',
    METER_VALUES: 'MeterValues',
    START_TRANSACTION: 'StartTransaction',
    STATUS_NOTIFICATION: 'StatusNotification',
    STOP_TRANSACTION: 'StopTransaction',
  },
  ChargePointSpecsContainer: {
    CHARGEPOINT: 0,
    USER: 1,
    MODAL: 2,
    CHARGINGTOKENS: 3,
  },
  ConnectorPowerLevel: {
    LEVEL1: 22,
    LEVEL2: 100,
  },
  TransactionStatus: {
    REVERSED: 'Reversed',
    CORRECTED: 'Corrected',
    NORMAL: 'Normal',
    VOID: 'Void',
  },
  ChargePointUptimeStatus: {
    ONLINE: 'Online',
    OFFLINE: 'Offline',
    UNSTABLE: 'Unstable',
  },
}

export default InfinityEnums
