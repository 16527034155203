import { toaster } from 'rsuite'
import { IFToastMessage } from 'Components'
import { Config } from 'Config'
var emitter = require('emitter-io')
const emitterPort = window.location.protocol == 'https:' ? 443 : 80
let client = emitter.connect({
  host: Config.EMITTER_URL,
  port: emitterPort,
})
let emitterChannels = {}
let errorFlag = false

const subscribeToChannel = (
  channel,
  channelKey,
  callBackFunction,
  extraData,
) => {
  if (!emitterChannels[channel]) {
    client.subscribe({
      key: channelKey,
      channel: channel,
    })
    emitterChannels[channel] = {
      function: callBackFunction,
      args: extraData,
    }
  }
}

const unsubscribeFromChannel = (channel, channelKey) => {
  if (emitterChannels[channel]) {
    client.unsubscribe({
      key: channelKey,
      channel: channel,
    })
    delete emitterChannels[channel]
  }
}

const unsubscribeFromChannelGroup = (channel, channelKey) => {
  for (const [key, value] of Object.entries(emitterChannels)) {
    if (key.includes(channel)) {
      client.unsubscribe({
        key: channelKey,
        channel: key,
      })
      delete emitterChannels[key]
    }
  }
}

client.on('message', function (obj) {
  const data = JSON.parse(obj.binary)
  if (!emitterChannels[obj.channel]) {
    if (obj.channel.includes('error') && !errorFlag) {
      toaster.push(<IFToastMessage type="error" text="Emitter error" />, {
        placement: 'topEnd',
      })
      errorFlag = true
      setTimeout(() => {
        errorFlag = false
      }, 1000)
    }
  } else {
    emitterChannels[obj.channel].function({
      data: data,
      ...emitterChannels[obj.channel].args,
    })
  }
})

export const Emitter = {
  subscribeToChannel,
  unsubscribeFromChannel,
  unsubscribeFromChannelGroup,
}
