import React, { forwardRef } from 'react'
import Styles from './ScheduleContainer.module.css'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Colors } from 'Theme'
import { IFSkeleton, IFText } from 'Components'
import { useTranslation } from 'react-i18next'
import ChargePointSchedule from './ChargePointSchedule/ChargePointSchedule'

const ScheduleContainer = forwardRef(
  (
    {
      isEditing,
      renderFor,
      maxListHeight,
      scrollbarsHeight,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointRequestState,
      fetchChargePointPrivacyScheduleRequestState,
      style,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    return (
      <div className={Styles.ScheduleContainer} style={style}>
        <div className={Styles.TitleIconsContainer}>
          <div className={Styles.TitleContainer}>
            {fetchStationListRequestState === RequestState.LOADING ||
            fetchChargePointListRequestState === RequestState.LOADING ||
            fetchChargePointRequestState === RequestState.LOADING ||
            fetchChargePointPrivacyScheduleRequestState ===
              RequestState.LOADING ? (
              <IFSkeleton variant="text" width="5rem" height="1.5rem" />
            ) : (
              <IFText
                style={{ color: Colors.text }}
                className={Styles.RuleTitle}
              >
                {t('ScheduleList.Rule')}
              </IFText>
            )}
          </div>
        </div>
        <ChargePointSchedule
          ref={ref}
          isEditing={isEditing}
          maxListHeight={maxListHeight}
        />
      </div>
    )
  },
)

const mapStateToProps = (state) => ({
  fetchChargePointPrivacyScheduleRequestState:
    ChargePointSelectors.getFetchChargePointPrivacyScheduleRequestState(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
})

ScheduleContainer.propTypes = {
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  fetchChargePointRequestState: PropTypes.number,
  isEditing: PropTypes.bool,
  renderFor: PropTypes.number,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
  subscriptionsListRef: PropTypes.object,
  fetchChargePointSubscriptionsRequestState: PropTypes.number,
  onModalSubmit: PropTypes.func,
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(ScheduleContainer)
