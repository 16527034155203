import React, { useState, useEffect } from 'react'
import Styles from './CreateAccountPage.module.css'
import { IFButton, IFText, IFTextInput, IFLoadingIndicator } from 'Components'
import { Colors, Images } from 'Theme'
import AuthSelectors from 'Stores/Auth/Selectors'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import validator from 'validator'
import TenantUserActions from 'Stores/TenantUser/Actions'
import history from 'history/browser'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'
import Link from '@mui/material/Link'

const CreateAccountPage = ({
  isLoginLoading,
  fetchInviteTenantUser,
  inviteTenantUser,
  fetchInviteTenantUserRequestState,
  createTenantUser,
  createTenantUserRequestState,
  inviteTenantUserStatus,
}) => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [nameError, setNameError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [canRegister, setCanRegister] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    const slashArray = history.location.pathname.split('/')
    const inviteTenantUserId = slashArray[slashArray.length - 1]
    if (inviteTenantUserId && inviteTenantUserId.match(/^[0-9a-fA-F]{24}$/))
      fetchInviteTenantUser(inviteTenantUserId)
  }, [])

  const ResetPasswords = () => {
    setPasswordError('')
    setConfirmPasswordError('')
  }

  const handleRegisterClick = () => {
    let tenantUser = {
      name: name,
      password: password,
      email: inviteTenantUser.email,
      permissions: inviteTenantUser.permissions,
      tenant: inviteTenantUser.tenant,
      inviteId: inviteTenantUser._id,
    }
    createTenantUser(tenantUser)
  }

  return (
    <div>
      <div
        className={Styles.backgroundImage}
        style={{
          backgroundImage: `url(${Images.createAccountBackground})`,
        }}
      />
      <div
        className={Styles.loginwrapper}
        style={{
          backgroundColor: Colors.CreateAccountBackgroundColor,
          boxShadow: `0px 0px 4px ${Colors.CreateAccountBackgroundShadow}`,
        }}
      >
        <div
          className={
            fetchInviteTenantUserRequestState === RequestState.LOADING ||
            inviteTenantUserStatus === InfinityEnums.InvitationStatus.ACCEPTED
              ? Styles.LogoLoading
              : Styles.Logo
          }
        >
          <img className={Styles.img} src={Images.infinity} alt="logo" />
        </div>
        {fetchInviteTenantUserRequestState === RequestState.LOADING ? (
          <div className={Styles.LoadingIndicator}>
            <IFLoadingIndicator />
          </div>
        ) : inviteTenantUserStatus ===
          InfinityEnums.InvitationStatus.PENDING ? (
          <div className={Styles.inputContainer}>
            <div>
              <div className={Styles.titleContainer}>
                <IFText style={{ color: Colors.text }} className={Styles.title}>
                  {t('CreateAccountPage.CreateAccount')}
                </IFText>
              </div>
              <div className={Styles.InputFieldContainer}>
                <IFText style={{ color: Colors.text }} className={Styles.label}>
                  {t('CreateAccountPage.EmailAddress')}
                </IFText>

                <IFText style={{ color: Colors.text }} className={Styles.email}>
                  {inviteTenantUser.email}
                </IFText>
              </div>
              <div className={Styles.NameContainer}>
                <IFText style={{ color: Colors.text }} className={Styles.label}>
                  {t('CreateAccountPage.Name')}
                </IFText>

                <IFTextInput
                  style={{ caretColor: Colors.primary }}
                  className={Styles.input}
                  isFixed={true}
                  value={name}
                  name="name"
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value)
                    e.target.value.length === 0
                      ? setNameError(t('CreateAccountPage.Required'))
                      : validator.isAlpha(e.target.value, 'en-US', {
                          ignore: ' ',
                        })
                      ? setNameError('')
                      : setNameError(t('CreateAccountPage.NameError'))
                    const register =
                      validator.isAlpha(e.target.value, 'en-US', {
                        ignore: ' ',
                      }) &&
                      validator.isLength(password, { min: 8, max: 100 }) &&
                      validator.equals(password, confirmPassword)
                    setCanRegister(register)
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0
                      ? setNameError(t('CreateAccountPage.Required'))
                      : validator.isAlpha(e.target.value, 'en-US', {
                          ignore: ' ',
                        })
                      ? setNameError('')
                      : setNameError(t('CreateAccountPage.NameError'))
                    const register =
                      validator.isAlpha(e.target.value, 'en-US', {
                        ignore: ' ',
                      }) &&
                      validator.isLength(password, { min: 8, max: 100 }) &&
                      validator.equals(password, confirmPassword)
                    setCanRegister(register)
                  }}
                  {...(nameError.length > 0 ? { errorText: nameError } : {})}
                />
              </div>
              <div className={Styles.InputFieldContainer}>
                <IFText style={{ color: Colors.text }} className={Styles.label}>
                  {t('CreateAccountPage.Password')}
                </IFText>

                <IFTextInput
                  className={Styles.input}
                  style={{ caretColor: Colors.primary }}
                  isFixed={true}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    e.target.value.length === 0
                      ? setPasswordError(t('CreateAccountPage.Required'))
                      : !validator.isLength(e.target.value, {
                          min: 8,
                          max: 100,
                        })
                      ? setPasswordError(t('CreateAccountPage.PasswordError'))
                      : !validator.equals(e.target.value, confirmPassword) &&
                        confirmPassword.length === 0
                      ? setPasswordError('')
                      : validator.equals(e.target.value, confirmPassword)
                      ? ResetPasswords()
                      : setPasswordError(
                          t('CreateAccountPage.ConfirmPasswordError'),
                        )
                    const register =
                      validator.isLength(e.target.value, {
                        min: 8,
                        max: 100,
                      }) && validator.equals(e.target.value, confirmPassword)
                    setCanRegister(register)
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0
                      ? setPasswordError(t('CreateAccountPage.Required'))
                      : !validator.isLength(e.target.value, {
                          min: 8,
                          max: 100,
                        })
                      ? setPasswordError(t('CreateAccountPage.PasswordError'))
                      : !validator.equals(e.target.value, confirmPassword) &&
                        confirmPassword.length === 0
                      ? setPasswordError('')
                      : validator.equals(e.target.value, confirmPassword)
                      ? ResetPasswords()
                      : setPasswordError(
                          t('CreateAccountPage.ConfirmPasswordError'),
                        )
                    const register =
                      validator.isLength(e.target.value, {
                        min: 8,
                        max: 100,
                      }) && validator.equals(e.target.value, confirmPassword)
                    setCanRegister(register)
                  }}
                  type="password"
                  {...(passwordError.length > 0
                    ? { errorText: passwordError }
                    : {})}
                />
              </div>
              <div className={Styles.InputFieldContainer}>
                <IFText style={{ color: Colors.text }} className={Styles.label}>
                  {t('CreateAccountPage.ConfirmPassword')}
                </IFText>

                <IFTextInput
                  className={Styles.input}
                  style={{ caretColor: Colors.primary }}
                  isFixed={true}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                    e.target.value.length === 0
                      ? setConfirmPasswordError(t('CreateAccountPage.Required'))
                      : !validator.isLength(e.target.value, {
                          min: 8,
                          max: 100,
                        })
                      ? setConfirmPasswordError(
                          t('CreateAccountPage.PasswordError'),
                        )
                      : validator.equals(e.target.value, password)
                      ? ResetPasswords()
                      : setConfirmPasswordError(
                          t('CreateAccountPage.ConfirmPasswordError'),
                        )
                    const register =
                      validator.isLength(password, { min: 8, max: 100 }) &&
                      validator.equals(password, e.target.value)
                    setCanRegister(register)
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0
                      ? setConfirmPasswordError(t('CreateAccountPage.Required'))
                      : !validator.isLength(e.target.value, {
                          min: 8,
                          max: 100,
                        })
                      ? setConfirmPasswordError(
                          t('CreateAccountPage.PasswordError'),
                        )
                      : validator.equals(e.target.value, password)
                      ? ResetPasswords()
                      : setConfirmPasswordError(
                          t('CreateAccountPage.ConfirmPasswordError'),
                        )
                    const register =
                      validator.isLength(password, { min: 8, max: 100 }) &&
                      validator.equals(password, e.target.value)
                    setCanRegister(register)
                  }}
                  type="password"
                  {...(confirmPasswordError.length > 0
                    ? { errorText: confirmPasswordError }
                    : {})}
                />
              </div>
            </div>
            <div className={Styles.signIn}>
              <IFButton
                text={t('CreateAccountPage.Register')}
                color={Colors.primary}
                isFill={true}
                size="sm"
                onClick={handleRegisterClick}
                isLoading={
                  createTenantUserRequestState === RequestState.LOADING ||
                  isLoginLoading
                }
                isDead={!canRegister}
              ></IFButton>
            </div>
          </div>
        ) : inviteTenantUserStatus ===
          InfinityEnums.InvitationStatus.ACCEPTED ? (
          <div className={Styles.AcceptedText}>
            <div>
              <IFText style={{ color: Colors.text }}>
                {t('CreateAccountPage.AcceptedInvite')}
              </IFText>
            </div>
            <div className={Styles.ClickHere}>
              <IFText
                className={Styles.ClickHereText}
                style={{ color: Colors.text }}
              >
                {t('CreateAccountPage.ToLogin')}
              </IFText>
              <Link
                href="/auth/login"
                variant="body2"
                style={{
                  color: Colors.linkColor,
                  fontSize: '1.1rem',
                }}
                className={Styles.link}
              >
                <IFText className={Styles.ClickHereText}>
                  {t('CreateAccountPage.Here')}
                </IFText>
              </Link>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchInviteTenantUser: (inviteTenantUserId) =>
      dispatch(TenantUserActions.fetchInviteTenantUser(inviteTenantUserId)),
    createTenantUser: (tenantUser) =>
      dispatch(TenantUserActions.createTenantUser(tenantUser)),
  }
}

const mapStateToProps = (state) => ({
  isLoginLoading: AuthSelectors.isTenantUserLoginLoading(state),
  inviteTenantUser: TenantUserSelectors.getInviteTenantUser(state),
  fetchInviteTenantUserRequestState:
    TenantUserSelectors.getFetchInviteTenantUserRequestState(state),
  createTenantUserRequestState:
    TenantUserSelectors.getCreateTenantUserRequestState(state),
  inviteTenantUserStatus: TenantUserSelectors.getInviteTenantUserStatus(state),
})

CreateAccountPage.propTypes = {
  isLoginLoading: PropTypes.bool,
  fetchInviteTenantUser: PropTypes.func,
  inviteTenantUser: PropTypes.func,
  fetchInviteTenantUserRequestState: PropTypes.number,
  createTenantUser: PropTypes.func,
  createTenantUserRequestState: PropTypes.number,
  inviteTenantUserStatus: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage)
