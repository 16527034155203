import PropTypes from 'prop-types'
import Styles from './UserChip.module.css'
import IFText from 'Components/IFText/IFText'
import styled from 'styled-components'
import { Colors } from 'Theme'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'

const UserChip = ({ id, name, chargingTokensCount, onDelete }) => {
  const useStyles = makeStyles((theme) => ({
    chip: {
      margin: theme.spacing(0.5),
      backgroundColor: Colors.FilterBackground,
      '&:hover': {
        backgroundColor: Colors.primary,
        color: 'white !important',
        '& $smallChip': {
          backgroundColor: 'white',
          color: Colors.primary,
        },
        '& $tokensCount': {
          color: Colors.primary,
        },
        '& $closeIcon': {
          color: Colors.white,
        },
      },

      fontSize: '0.875rem',
    },
    smallChip: {
      marginLeft: 4,
      backgroundColor: Colors.primary,
      borderRadius: 12,
      paddingLeft: 4,
      paddingRight: 4,
      minWidth: 16,
      alignContent: 'center !important',
      alignItems: 'center !important',
    },
    tokensCount: {
      textAlign: 'center',
      fontSize: 12,
      color: Colors.white,
    },
    closeIcon: {
      color: 'black',
      width: '14px',
      height: '14px',
    },
    accordionDetails: {
      padding: '0em',
    },
    Accordion: {
      boxShadow: '0px 0px 2px 1px' + Colors.ChargingTransactionListItemShadow,
      zIndex: 3,
    },
  }))
  const classes = useStyles()

  return (
    <div className={Styles.Container}>
      <Chip
        deleteIcon={<CloseIcon className={classes.closeIcon} style={{}} />}
        label={
          <div className={Styles.Label}>
            <IFText className={Styles.Name}>{name}</IFText>

            {chargingTokensCount && (
              <div className={classes.smallChip}>
                <IFText className={classes.tokensCount}>
                  {chargingTokensCount}
                </IFText>
              </div>
            )}
          </div>
        }
        size="small"
        className={classes.chip}
        onDelete={onDelete}
      />
    </div>
  )
}

UserChip.propTypes = {
  name: PropTypes.string,
  imageURL: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isRound: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
}

export default UserChip
