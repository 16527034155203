import React, { useState, useRef, createRef, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  TenantUserListItem,
  IFAvatar,
  IFText,
  TenantUserListItemSkeleton,
  InviteTenantUserListItem,
  IFToastMessage,
  CustomScrollbar,
} from 'Components'
import { Colors, Icons } from 'Theme'
import Styles from './TenantUserList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import IFDialog from 'Components/IFDialog/IFDialog'
import InfinityEnums from 'Enums/InfinityEnums'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import RequestState from 'Enums/RequestState'
import { toaster } from 'rsuite'
import AuthSelectors from 'Stores/Auth/Selectors'
import { Virtuoso } from 'react-virtuoso'

const TenantUserList = ({
  tenantUsers,
  inviteTenantUsers,
  updateTenantUserPermission,
  fetchTenantUserListRequestState,
  fetchInviteTenantUserListRequestState,
  deleteTenantUser,
  deleteTenantUserRequestState,
  deleteInviteTenantUser,
  deleteInviteTenantUserRequestState,
  sendTenantUserEmail,
  sendTenantUserEmailRequestState,
  loggedTenantUser,
}) => {
  const { t } = useTranslation()

  const confirmDeleteDialog = useRef()
  const [tenantUserId, setTenantUserId] = useState('')
  const [index, setIndex] = useState(-1)
  const [loadingStates, setLoadingStates] = useState(
    Array(tenantUsers.length).fill(false),
  )
  const [combinedTenantUsers, setCombinedTenantUsers] = useState([])

  useEffect(() => {
    if (deleteTenantUserRequestState === RequestState.SUCCEEDED) {
      confirmDeleteDialog.current.dismiss()
    }
  }, [deleteTenantUserRequestState])

  useEffect(() => {
    if (
      fetchTenantUserListRequestState === RequestState.LOADING ||
      fetchInviteTenantUserListRequestState === RequestState.LOADING
    )
      return

    const formattedTenantUsers = tenantUsers.map((tenantUser) => {
      return {
        ...tenantUser,
        type: 'tenantUser',
      }
    })
    const formattedInviteTenantUsers = inviteTenantUsers.map(
      (inviteTenantUser) => {
        return {
          ...inviteTenantUser,
          type: 'inviteTenantUser',
        }
      },
    )
    setCombinedTenantUsers([
      ...formattedTenantUsers,
      ...formattedInviteTenantUsers,
    ])
  }, [tenantUsers, inviteTenantUsers])

  useEffect(() => {
    if (sendTenantUserEmailRequestState === RequestState.SUCCEEDED) {
      setTimeout(
        () =>
          toaster.push(
            <IFToastMessage
              type="success"
              text={t('ToasterMessages.InvitationSuccess')}
            />,
            { placement: 'topEnd' },
          ),
        0,
      )
    }
  }, [sendTenantUserEmailRequestState])

  const tenantUsersLength = tenantUsers.length
  const inviteTenantUsersLength = inviteTenantUsers.length
  const tenantUserListItemsRefs = React.useRef([])
  const inviteTenantUserListItemRefs = React.useRef([])

  if (tenantUserListItemsRefs.current.length !== tenantUsersLength) {
    tenantUserListItemsRefs.current = Array(tenantUsersLength)
      .fill()
      .map((_, i) => tenantUserListItemsRefs.current[i] || createRef())
  }

  if (inviteTenantUserListItemRefs.current.length !== inviteTenantUsersLength) {
    inviteTenantUserListItemRefs.current = Array(inviteTenantUsersLength)
      .fill()
      .map((_, i) => inviteTenantUserListItemRefs.current[i] || createRef())
  }

  const convertPermissionsBack = (permissions) => {
    let upperCasePermissions = permissions.map((permission) => {
      let temp = permission.toUpperCase()
      return temp.replace(/ /g, '_')
    })
    let TenantUserEnums = Object.keys(InfinityEnums.TenantUserPermission)
    upperCasePermissions = upperCasePermissions.map((permission) => {
      const index = TenantUserEnums.findIndex(
        (tenantUserEnum) => tenantUserEnum === permission,
      )
      if (index !== -1) return index + 1
    })

    return upperCasePermissions
  }

  const transformPermissions = (permissions) => {
    const transformedPermissions = permissions.map((permission) => {
      let lowerCase = permission.toLowerCase()
      lowerCase = lowerCase.replace(/_/g, ' ')
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
    })
    return transformedPermissions
  }

  const transformPickedPermissions = (permissions) => {
    let TenantUserEnums = Object.keys(InfinityEnums.TenantUserPermission)
    TenantUserEnums = transformPermissions(TenantUserEnums)
    const transformedPermissions = permissions.map(
      (number) => TenantUserEnums[number - 1],
    )
    return transformedPermissions
  }

  const handleConfirmDelete = () => {
    deleteTenantUser(
      tenantUserId,
      deleteListItemRef(
        tenantUserListItemsRefs.current[index],
        index,
        tenantUsersLength,
      ),
    )
  }

  const handleUpdateTenantUser = (
    tenantUserId,
    permissions,
    ref,
    onSuccess,
    onError,
  ) => {
    const convertedPermissions = convertPermissionsBack(permissions)
    updateTenantUserPermission(
      tenantUserId,
      convertedPermissions,
      ref,
      onSuccess,
      onError,
    )
  }

  const onDeleteClickHandler = (tenantUserId, index) => {
    setTenantUserId(tenantUserId)
    setIndex(index)
    confirmDeleteDialog.current.show()
  }

  const deleteListItemRef = (ref, index, length) => {
    return length === 1 ? null : index === length - 1 ? null : ref
  }
  const editPermission = loggedTenantUser.permissions.includes(
    InfinityEnums.TenantUserPermission.CAN_EDIT_TENANT_USER,
  )

  const onSuccess = (index) => {
    setLoadingStates((prevState) => {
      prevState[index] = false
      return [...prevState]
    })
  }

  const tenantUsersContainerRef = useRef()

  const getStationsSkeletonsCount = () => {
    if (tenantUsersContainerRef.current) {
      let containerHeight =
        tenantUsersContainerRef.current.parentElement.parentElement
          .clientHeight / 16
      return Math.floor(containerHeight / 6)
    }
    return 7
  }

  return (
    <div className={Styles.Container} ref={tenantUsersContainerRef}>
      {fetchTenantUserListRequestState === RequestState.LOADING ||
      fetchInviteTenantUserListRequestState === RequestState.LOADING ? (
        [...Array(getStationsSkeletonsCount())].map((e, index) => (
          <TenantUserListItemSkeleton
            key={`TenantUserListItemSkeleton ${index}`}
            editPermission={editPermission}
          />
        ))
      ) : tenantUsers.length === 0 && inviteTenantUsers.length === 0 ? (
        <div className={Styles.EmptySet}>
          <img src={Icons.emptySet.default} alt="" />
          <IFText className={Styles.ModalText}>
            {t('TenantUserList.empty')}
          </IFText>
        </div>
      ) : (
        <Virtuoso
          data={combinedTenantUsers}
          increaseViewportBy={480}
          itemContent={(index, tenantUser) => {
            if (tenantUser.type === 'tenantUser') {
              return (
                <TenantUserListItem
                  ref={tenantUserListItemsRefs.current[index]}
                  key={`tenantUserListItem ${index}`}
                  avatar={
                    <IFAvatar name={tenantUser.name} size={40}></IFAvatar>
                  }
                  tenantUser={tenantUser}
                  onDeleteClick={() =>
                    onDeleteClickHandler(tenantUser.id, index)
                  }
                  onUpdateClick={(selectedPermissions) => {
                    setLoadingStates((prevState) => {
                      prevState[index] = true
                      return [...prevState]
                    })
                    handleUpdateTenantUser(
                      tenantUser.id,
                      selectedPermissions,
                      tenantUserListItemsRefs.current[index],
                      () => onSuccess(index),
                      () => onSuccess(index),
                    )
                  }}
                  isUpdateLoading={loadingStates[index]}
                  isDeleteLoading={false}
                  permissions={transformPermissions(
                    Object.keys(InfinityEnums.TenantUserPermission),
                  )}
                  pickedPermissions={transformPickedPermissions(
                    tenantUser.permissions,
                  )}
                  editPermission={editPermission}
                />
              )
            } else {
              return (
                <InviteTenantUserListItem
                  ref={
                    inviteTenantUserListItemRefs.current[
                      index - tenantUsers.length
                    ]
                  }
                  key={`InviteTenantUserListItem ${index}`}
                  avatar={
                    <IFAvatar name={tenantUser.email} size={40}></IFAvatar>
                  }
                  tenantUser={tenantUser}
                  permissions={transformPickedPermissions(
                    tenantUser.permissions,
                  )}
                  isResendLoading={
                    sendTenantUserEmailRequestState === RequestState.LOADING
                  }
                  onResendClick={() =>
                    sendTenantUserEmail(
                      tenantUser.email,
                      inviteTenantUserListItemRefs.current[
                        index - tenantUsers.length
                      ],
                    )
                  }
                  onDeleteClick={() =>
                    deleteInviteTenantUser(
                      tenantUser._id,
                      deleteListItemRef(
                        inviteTenantUserListItemRefs.current[
                          index - tenantUsers.length
                        ],
                        index,
                        inviteTenantUsersLength,
                      ),
                    )
                  }
                  isDeleteLoading={
                    deleteInviteTenantUserRequestState === RequestState.LOADING
                  }
                />
              )
            }
          }}
          components={{ Scroller: CustomScrollbar }}
        />
      )}
      <IFDialog
        ref={confirmDeleteDialog}
        style={{ margin: '15em' }}
        title={t('TenantUserList.deleteModalTitle')}
        subtitle={t('TenantUserList.deleteModalText')}
        onAction={handleConfirmDelete}
        actionButtonColor={Colors.TenantUserListDeleteModalDelete}
        actionButtonText={t('TenantUserList.deleteModalConfirm')}
        buttonClassName={Styles.Button}
        isLoading={deleteTenantUserRequestState === RequestState.LOADING}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateTenantUserPermission: (
      tenantUserId,
      permissions,
      tenantUserItemRef,
      onSuccess,
      onError,
    ) =>
      dispatch(
        TenantUserActions.updateTenantUser(
          tenantUserId,
          permissions,
          tenantUserItemRef,
          onSuccess,
          onError,
        ),
      ),
    deleteTenantUser: (tenantUserId, ref) =>
      dispatch(TenantUserActions.deleteTenantUser(tenantUserId, ref)),
    deleteInviteTenantUser: (inviteTenantUserId, ref) =>
      dispatch(
        TenantUserActions.deleteInviteTenantUser(inviteTenantUserId, ref),
      ),
    sendTenantUserEmail: (email, ref) =>
      dispatch(TenantUserActions.sendTenantUserEmail(email, ref)),
  }
}

const mapStateToProps = (state) => ({
  tenantUsers: TenantUserSelectors.getTenantUsers(state),
  fetchTenantUserListRequestState:
    TenantUserSelectors.getFetchTenantUserListRequestState(state),
  inviteTenantUsers: TenantUserSelectors.getInviteTenantUsers(state),
  deleteTenantUserRequestState:
    TenantUserSelectors.getDeleteTenantUserRequestState(state),
  deleteInviteTenantUserRequestState:
    TenantUserSelectors.getDeleteInviteTenantUserRequestState(state),
  sendTenantUserEmailRequestState:
    TenantUserSelectors.getSendTenantUserEmailRequestState(state),
  fetchInviteTenantUserListRequestState:
    TenantUserSelectors.getFetchInviteTenantUserListRequestState(state),
  loggedTenantUser: AuthSelectors.getTenantUser(state),
})

TenantUserList.propTypes = {
  tenantUsers: PropTypes.arrayOf(PropTypes.object),
  inviteTenantUsers: PropTypes.arrayOf(PropTypes.object),
  updateTenantUserPermission: PropTypes.func,
  fetchTenantUserListRequestState: PropTypes.number,
  fetchInviteTenantUserListRequestState: PropTypes.number,
  deleteTenantUser: PropTypes.func,
  deleteTenantUserRequestState: PropTypes.number,
  deleteInviteTenantUser: PropTypes.func,
  deleteInviteTenantUserRequestState: PropTypes.number,
  sendTenantUserEmail: PropTypes.func,
  sendTenantUserEmailRequestState: PropTypes.number,
  loggedTenantUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantUserList)
