import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Styles from './StatePicker.module.css'
import { SelectPicker } from 'rsuite'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { getTextWidth } from 'Utils/Calculations'

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  border-radius: 0.3rem;
  width: ${(props) => props.width};
  color: ${(props) => props.containerColor};
  border: 1px solid ${(props) => props.containerColor};
`

const SelectPickerContainer = styled.div`
  border: 1px solid ${(props) => props.containerColor};
  border-radius: 0.3rem;
`

const StatePicker = ({
  isEdit,
  onPrivacyChange,
  options,
  defaultOptionIndex = 0,
}) => {
  const [value, setValue] = useState(options[defaultOptionIndex].value)
  useEffect(() => {
    if (isEdit === false) {
      setValue(options[defaultOptionIndex].value)
    }
  }, [isEdit])
  const { t } = useTranslation()

  return (
    <div className={Styles.Container}>
      {isEdit ? (
        <SelectPickerContainer
          containerColor={
            options.find((option) => value === option.value).color
          }
        >
          <SelectPicker
            className={Styles.SelectPicker}
            appearance="subtle"
            renderValue={() => (
              <IFText
                style={{
                  color: options.find((option) => value === option.value).color,
                  fontSize: '14px',
                }}
              >
                {value}
              </IFText>
            )}
            value={value}
            onSelect={(value) => {
              setValue(value)
              onPrivacyChange(value)
            }}
            data={options}
            cleanable={false}
            size="xs"
            searchable={false}
          />
        </SelectPickerContainer>
      ) : (
        <TextContainer
          width={getTextWidth(
            options[defaultOptionIndex].label,
            '14px ProximaNova',
          )}
          containerColor={options[defaultOptionIndex].color}
        >
          <IFText className={Styles.Text}>
            {options[defaultOptionIndex].label}
          </IFText>
        </TextContainer>
      )}
    </div>
  )
}

StatePicker.propTypes = {
  isPrivate: PropTypes.bool,
  isEdit: PropTypes.bool,
  onPrivacyChange: PropTypes.func,
}

export default StatePicker
