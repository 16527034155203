import React, { useImperativeHandle } from 'react'
import { IFText, IFsvg } from 'Components'
import styles from './IFSliderPicker.module.css'
import Colors from 'Theme/Colors'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Slider } from 'rsuite'

const PopperContainer = styled.div`
  border-color: ${Colors.white};
  background-color: ${Colors.white};
  box-shadow: 0px 2px 2px 1px ${Colors.IFRangePickerShadow};
`

const IFSliderPicker = React.forwardRef(
  (
    {
      initialValue,
      minSliderValue,
      maxSliderValue,
      title,
      onValueSelected = () => {},
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isOpen, setIsOpen] = React.useState(false)
    const [placement, setPlacement] = React.useState()
    const [value, setValue] = React.useState(initialValue)

    const closePopper = () => {
      setIsOpen(false)
    }

    const handleClick = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget)
      setIsOpen((prev) => placement !== newPlacement || !prev)
      setPlacement(newPlacement)
    }

    const clearValue = () => {
      setValue('')
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))

    return (
      <ClickAwayListener onClickAway={closePopper}>
        <div>
          <div
            className={styles.Container}
            onClick={handleClick('bottom-start')}
            style={{
              backgroundColor: Colors.filterBackground,
            }}
          >
            <IFText className={styles.Text}>
              {title.replaceAll(' ', '\u00a0')}
            </IFText>
            <div>
              <IFsvg.Expand
                height={20}
                width={20}
                fill={Colors.FilterIconFilled}
              />
            </div>
          </div>

          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            <PopperContainer className={styles.PopperContainer}>
              <Slider
                value={parseInt(value)}
                className={styles.Slider}
                min={minSliderValue}
                max={maxSliderValue}
                onChange={(value) => {
                  setValue(`${value}`)
                  onValueSelected(`${value}`)
                }}
              />
            </PopperContainer>
          </Popper>
        </div>
      </ClickAwayListener>
    )
  },
)

IFSliderPicker.propTypes = {
  initialValue: PropTypes.string,
  minSliderValue: PropTypes.number,
  maxSliderValue: PropTypes.number,
  title: PropTypes.string,
  onValueSelected: PropTypes.func,
}

export default IFSliderPicker
