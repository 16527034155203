import { put, call, spawn } from 'redux-saga/effects'
import { chargingTokenService, handleError } from 'Services/ApiService.js'
import CardActions from 'Stores/Card/Actions'
import { toaster } from 'rsuite'
import { IFToastMessage } from 'Components'
import history from 'history/browser'
var base64 = require('base-64')
export function* fetchCardListCount({ filter }) {
  yield put(CardActions.fetchCardListCountLoading())
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }
    const { data } = yield call(chargingTokenService.get, '/count', {
      params: {
        filter: filter,
      },
    })
    yield put(CardActions.fetchCardListCountSuccess(data.tokensCount))
  } catch (e) {
    yield put(CardActions.fetchCardListCountFail(e))
    if (e.message !== 'Network Error') handleError(e)
  }
}
export function* fetchCardList({ filter, offset, onNotFound }) {
  if (offset === 0) {
    yield spawn(fetchCardListCount, { filter })
    yield put(CardActions.fetchCardListLoading())
  } else {
    yield put(CardActions.fetchCardListLoadingMore())
  }
  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(chargingTokenService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })
    const slashArray = history.location.pathname.split('/')
    const chargingTokenIdParam = slashArray[2]

    if (
      chargingTokenIdParam &&
      chargingTokenIdParam.match(/^[0-9a-fA-F]{24}$/) &&
      offset === 0
    ) {
      const index = data.chargingTokens.findIndex(
        (token) => token._id === chargingTokenIdParam,
      )
      if (index !== -1) {
        yield put(
          CardActions.fetchCardListSuccess(
            offset,
            data.chargingTokens,
            data.nextOffset,
            index,
            data.tokensCount,
          ),
        )
      } else {
        const fetchedCard = yield call(
          chargingTokenService.get,
          `/${chargingTokenIdParam}`,
        )

        if (!fetchedCard.data) {
          onNotFound()
          return
        }
        let chargingTokens = [
          ...data.chargingTokens,
          {
            ...fetchedCard.data.chargingToken,
          },
        ]
        yield put(
          CardActions.fetchCardListSuccess(
            offset,
            chargingTokens,
            data.nextOffset,
            data.chargingTokens.length,
          ),
        )
      }
    } else {
      yield put(
        CardActions.fetchCardListSuccess(
          offset,
          data.chargingTokens,
          data.nextOffset,
          0,
        ),
      )
    }
  } catch (e) {
    yield put(CardActions.fetchCardListFail(e))
    handleError(e)
    e = yield e
    if (e.status === 404) onNotFound()
  }
}

export function* deleteCard({ cardId, ref }) {
  yield put(CardActions.deleteCardLoading())
  try {
    const { data } = yield call(chargingTokenService.delete, `/${cardId}`, {})
    yield put(CardActions.deleteCardSuccess(cardId))
    if (ref) ref.current.closeAccordion()
    toaster.push(<IFToastMessage type="success" text={'Card deleted'} />, {
      placement: 'topEnd',
    })
  } catch (e) {
    yield put(CardActions.deleteCardFail(e))
    handleError(e)
  }
}

export function* updateCard({ cardId, newCard, index }) {
  yield put(CardActions.updateCardLoading())
  try {
    const { data } = yield call(chargingTokenService.patch, `/${cardId}`, {
      ...newCard,
    })

    yield put(CardActions.updateCardSuccess(index, newCard))
    toaster.push(<IFToastMessage type="success" text={'Card updated'} />, {
      placement: 'topEnd',
    })
  } catch (e) {
    yield put(CardActions.updateCardFail(e))
    handleError(e)
  }
}

export function* addCard({ newCard }) {
  yield put(CardActions.addCardLoading())
  try {
    const { data } = yield call(chargingTokenService.post, `/`, {
      ...newCard,
    })
    yield put(CardActions.addCardSuccess(data))
  } catch (e) {
    yield put(CardActions.addCardFail(e))
    handleError(e)
  }
}

export function* addCardBulk({ file }) {
  yield put(CardActions.addCardLoading())
  try {
    const { data } = yield call(
      chargingTokenService.post,
      `/chargingTokenBulk`,
      file,
    )
    yield put(CardActions.addCardBulkSuccess(data))
  } catch (e) {
    yield put(CardActions.addCardFail(e))
    handleError(e)
  }
}

export function* fetchCardTransactions({ filter, offset, cardId, ascending }) {
  if (offset === 0) yield put(CardActions.fetchCardTransactionsLoading())
  else yield put(CardActions.fetchCardTransactionsLoadingMore())
  try {
    if (filter !== null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(
      chargingTokenService.get,
      `/${cardId}/transactions`,
      {
        params: {
          filter: filter,
          offset: offset,
          ascending: ascending,
        },
      },
    )
    yield put(
      CardActions.fetchCardTransactionsSuccess(
        offset,
        data.transactions,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(CardActions.fetchCardTransactionsFail(e))
    handleError(e)
  }
}

export function* fetchCard({ cardId }) {
  yield put(CardActions.fetchCardLoading())
  try {
    const { data } = yield call(chargingTokenService.get, `/${cardId}/`)
    yield put(CardActions.fetchCardSuccess(data))
  } catch (e) {
    yield put(CardActions.fetchCardFail(e))
    handleError(e)
  }
}

export function* addCardAccessGroups({ cardId, accessGroups }) {
  yield put(CardActions.addCardAccessGroupsLoading())
  try {
    const { data } = yield call(
      chargingTokenService.post,
      `/${cardId}/accessGroups/bulk`,
      {
        accessGroups: accessGroups,
      },
    )
    yield put(CardActions.addCardAccessGroupsSuccess(data.accessGroups))
  } catch (e) {
    yield put(CardActions.addCardAccessGroupsFail(e))
    handleError(e)
  }
}

export function* deleteCardAccessGroups({ cardId, accessGroups }) {
  yield put(CardActions.deleteCardAccessGroupsLoading())
  try {
    accessGroups = accessGroups.map((group) => {
      return group.id
    })
    const { data } = yield call(
      chargingTokenService.delete,
      `/${cardId}/accessGroups/bulk`,
      {
        data: { accessGroups },
      },
    )

    yield put(CardActions.deleteCardAccessGroupsSuccess(accessGroups))
  } catch (e) {
    yield put(CardActions.deleteCardAccessGroupsFail(e))
    handleError(e)
  }
}

export function* addCardSubscriptions({ cardId, subscriptions }) {
  yield put(CardActions.addCardSubscriptionsLoading())
  try {
    const { data } = yield call(
      chargingTokenService.post,
      `/${cardId}/subscriptions/bulk`,
      {
        subscriptions: subscriptions,
      },
    )
    yield put(CardActions.addCardSubscriptionsSuccess(data.subscriptions))
  } catch (e) {
    yield put(CardActions.addCardSubscriptionsFail(e))
    handleError(e)
  }
}

export function* deleteCardSubscriptions({ cardId, subscriptions }) {
  yield put(CardActions.deleteCardSubscriptionsLoading())
  try {
    const { data } = yield call(
      chargingTokenService.delete,
      `/${cardId}/subscriptions/bulk`,
      {
        data: { subscriptions },
      },
    )
    yield put(CardActions.deleteCardSubscriptionsSuccess(subscriptions))
  } catch (e) {
    yield put(CardActions.deleteCardSubscriptionsFail(e))
    handleError(e)
  }
}

export function* fetchLocalListFilteredCards({ filter, offset }) {
  yield put(CardActions.fetchLocalListFilteredCardsLoading())

  try {
    if (filter != null) {
      filter = base64.encode(JSON.stringify(filter))
    }

    const { data } = yield call(chargingTokenService.get, '/', {
      params: {
        filter: filter,
        offset: offset,
      },
    })

    yield put(
      CardActions.fetchLocalListFilteredCardsSuccess(
        offset,
        data.chargingTokens,
        data.nextOffset,
      ),
    )
  } catch (e) {
    yield put(CardActions.fetchLocalListFilteredCardsFail(e))
    handleError(e)
  }
}
