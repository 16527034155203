import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchUserList: ['filter', 'offset', 'onNotFound'],
  fetchUserListLoading: null,
  fetchUserListLoadingMore: null,
  fetchUserListSuccess: ['offset', 'users', 'nextOffset', 'index'],
  fetchUserListFail: ['error'],
  setSelectedUserIndex: ['index'],

  fetchUserDetails: ['userId'],
  fetchUserDetailsLoading: null,
  fetchUserDetailsSuccess: ['user'],
  fetchUserDetailsFail: ['error'],
  resetUserState: null,

  fetchUserTransactions: ['filter', 'offset', 'userId', 'ascending'],
  fetchUserTransactionsLoading: null,
  fetchUserTransactionsLoadingMore: null,
  fetchUserTransactionsSuccess: ['offset', 'transactions', 'nextOffset'],
  fetchUserTransactionsFail: ['error'],
  clearUserTransactions: null,
  setUserTransactionsFilter: ['newFilter'],
  setUserTransactionsPaginationOffset: ['offset'],

  addUserSubscriptions: ['userId', 'subscriptions'],
  addUserSubscriptionsLoading: null,
  addUserSubscriptionsSuccess: ['subscriptions'],
  addUserSubscriptionsFail: ['error'],

  deleteUserSubscriptions: ['userId', 'subscriptions'],
  deleteUserSubscriptionsLoading: null,
  deleteUserSubscriptionsSuccess: ['subscriptions'],
  deleteUserSubscriptionsFail: ['error'],

  addUserAccessGroups: ['userId', 'accessGroups'],
  addUserAccessGroupsLoading: null,
  addUserAccessGroupsSuccess: ['accessGroups'],
  addUserAccessGroupsFail: ['error'],

  deleteUserAccessGroups: ['userId', 'accessGroups'],
  deleteUserAccessGroupsLoading: null,
  deleteUserAccessGroupsSuccess: ['accessGroups'],
  deleteUserAccessGroupsFail: ['error'],

  inviteUser: ['invitations', 'onSuccess'],
  inviteUserBulk: ['invitations', 'onSuccess'],
  inviteUserLoading: null,
  inviteUserSuccess: null,
  inviteUserFail: ['error'],

  deleteUser: ['userId'],
  deleteUserLoading: null,
  deleteUserSuccess: ['userId'],
  deleteUserFail: ['error'],

  setPaginationOffset: ['offset'],
  setUserTransactionPaginationOffset: ['offset'],
  setUsersFilter: ['filter'],
  clearUsers: null,

  fetchUserListCount: ['filter'],
  fetchUserListCountLoading: null,
  fetchUserListCountSuccess: ['usersCount'],
  fetchUserListCountFail: ['error'],

  fetchUserSessionList: ['userId'],
  fetchUserSessionListLoading: null,
  fetchUserSessionListSuccess: ['sessions'],
  fetchUserSessionListFail: ['error'],

  emitterUpdateUserSessionStatus: ['session'],

  fetchLocalListFilteredUsers: ['filter', 'offset', 'onNotFound'],
  fetchLocalListFilteredUsersLoading: null,
  fetchLocalListFilteredUsersSuccess: [
    'offset',
    'users',
    'nextOffset',
    'index',
  ],
  fetchLocalListFilteredUsersFail: ['error'],
  clearLocalListFilteredUsers: null,
})

export const UserTypes = Types
export default Creators
