import React, { useEffect } from 'react'
import Styles from './IFTooltipIconsLoading.module.css'
import Colors from 'Theme/Colors'
import Tooltip from '@mui/material/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import Fade from '@mui/material/Fade'
import { SpinnerCircularFixed } from 'spinners-react'
import styled from 'styled-components'
import { isPropsMatch } from 'Utils/PropsMatch'

const IFTooltipIconsLoading = React.forwardRef(
  (
    {
      onClick,
      title,
      iconColor = Colors.primary,
      iconBackgroundColor = Colors.primary,
      Icon,
      FilledIcon,
      isLoading,
      tooltipPlacement = 'bottom',
      size = 24,
      iconClassname,
      animationDisabled = true,
      isDead = false,
    },
    ref,
  ) => {
    const IconOutlined = styled.div`
      opacity: ${isLoading ? '0' : '1'};
      z-index: 1;
      &:hover {
        opacity: 0;
      }
    `
    const IconFilled = styled.div`
      opacity: ${isLoading ? '1' : '0'};
      position: absolute;
      ${IconOutlined}:hover ~ & {
        opacity: 1;
      }
    `
    const StyledTooltip = withStyles({
      tooltip: {
        backgroundColor: `${
          isDead ? Colors.DeadTooltip : iconBackgroundColor
        } !important`,
        color: `${Colors.white} !important`,
        fontSize: '0.875rem !important',
        fontFamily: 'ProximaNova !important',
        padding: '0.3rem !important',
        marginTop: '0rem !important',
      },
    })(Tooltip)

    useEffect(() => {
      if (!isLoading) {
        setTimeout(() => {
          setIsClicked(false)
        }, 300)
      }
    }, [isLoading])

    const [isClicked, setIsClicked] = React.useState(false)

    return (
      <StyledTooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 60 }}
        title={isLoading ? '' : title}
        placement={tooltipPlacement}
        {...(!title ? { open: false } : {})}
      >
        <div
          ref={ref}
          onClick={(e) => {
            if (isLoading || isDead) {
              e.stopPropagation()
              return
            }
            setIsClicked(true)
            e.stopPropagation()
            onClick()
          }}
          className={iconClassname}
          style={{
            cursor: isLoading || isDead ? 'default' : 'pointer',
          }}
        >
          {isLoading ? (
            <SpinnerCircularFixed
              color={iconColor}
              secondaryColor={Colors.SpinnerSecondaryColor}
              thickness={100}
              size={size}
              style={{ position: 'absolute' }}
              className={Styles.loading}
            />
          ) : null}
          <div
            style={{
              height: `${size}px`,
              width: `${size}px`,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <IconOutlined>
              <Icon
                className={`${
                  animationDisabled
                    ? ''
                    : isClicked
                    ? isLoading
                      ? Styles.IconSmaller
                      : Styles.IconBigger
                    : ''
                }`}
                width={size}
                height={size}
                fill={isDead ? Colors.DeadTooltip : iconColor}
              />
            </IconOutlined>
            <IconFilled>
              <FilledIcon
                className={`${
                  animationDisabled
                    ? ''
                    : isClicked
                    ? isLoading
                      ? Styles.IconSmaller
                      : Styles.IconBigger
                    : ''
                }`}
                width={size}
                height={size}
                fill={isDead ? Colors.DeadTooltip : iconColor}
              />
            </IconFilled>
          </div>
        </div>
      </StyledTooltip>
    )
  },
)

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, ['isLoading', 'isDead'])
}

export default React.memo(IFTooltipIconsLoading, shouldSkipRender)
