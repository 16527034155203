import Styles from './ConfigurationListItemSkeleton.module.css'
import { IFSkeleton } from 'Components'
const ConfigurationListItemSkeleton = ({ ...props }) => {
  return (
    <div {...props}>
      <div className={Styles.container}>
        <div className={Styles.row}>
          <IFSkeleton className={Styles.left} />
        </div>
        <div className={Styles.row}>
          <IFSkeleton className={Styles.middle} />
        </div>
        <div className={Styles.row} />
        <IFSkeleton
          variant="circular"
          width="1.5rem"
          height="1.5rem"
          className={Styles.right}
        />
      </div>
    </div>
  )
}

export default ConfigurationListItemSkeleton
