import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import {
  IFText,
  IFFilter,
  IFTextInput,
  IFDialog,
  IFSkeleton,
  IFsvg,
  IFTooltipIconsLoading,
  IFToggle,
  IFToastMessage,
  StationUptimeMonitor,
  IFButtonSkeleton,
} from 'Components'
import {
  ConnectorList,
  ChargePointDetailsTabs,
  ChargePointLogList,
  ChargePointTransactionList,
  ChargePointSpecsContainer,
  ChargerSpecsEdit,
  ConfigurationModal,
  MoveStationModal,
  TerminalModal,
  UploadDiagnosticsModal,
  AlertModal,
  FirmwareModal,
  LocalListModal,
  ChargingProfileModal,
} from 'Containers'
import { Colors } from 'Theme'
import Styles from './ChargePointDetails.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import Scrollbars from 'react-custom-scrollbars-2'
import Icon from '@material-ui/core/Icon'
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'
import icons from 'Theme/Icons'
import styled from 'styled-components'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import OCPPServices from '../../Services/OCPPService'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import Fade from '@mui/material/Fade'
import { mapStatus } from 'Utils/ConnectorFunctions'
import { increaseBrightness } from '../../Utils/Calculations'
import { Opacity, VisibilityOff, VisibilityRounded } from '@mui/icons-material'
import { NoInternet } from 'Components/IFEmptyStates/IFEmptyStates'
import { toaster } from 'rsuite'
import ConnectorUtilization from 'Components/ConnectorUtilization/ConnectorUtilization'
const { ConnectorPowerLevel } = InfinityEnums
const DividerDivHorizontal = styled.div`
  border-top: 1px solid ${Colors.DividerColor};
`

const LeftContainer = styled.div`
  border-right: ${(props) =>
    props.canEdit ? `1px solid ${Colors.DividerColor}` : ''};
`
const weekToSecs = 604800

const handleChargingIcon = (maxPower) => {
  if (maxPower <= ConnectorPowerLevel.LEVEL1)
    return (
      <img className={Styles.ChargingIcon} src={icons.normalCharging} alt="" />
    )
  else if (maxPower <= ConnectorPowerLevel.LEVEL2)
    return (
      <img className={Styles.ChargingIcon} src={icons.fastCharging} alt="" />
    )
  else
    return (
      <img className={Styles.ChargingIcon} src={icons.superCharging} alt="" />
    )
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.tooltipBoxShadow}`,
    maxWidth: 'none',
  },
}))

const StyledTooltipToggle = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `${Colors.primary} !important`,
    color: `${Colors.white} !important`,
    fontSize: '0.75rem !important',
    fontFamily: 'ProximaNova !important',
    padding: '0.3rem !important',
    marginRight: '0.5rem !important',
  },
}))

const ChargePointDetails = ({
  chargePoint,
  chargePointName,
  chargePointConnectors,
  maxPower,
  fetchChargePointConfigList,
  chargePointLogFilter,
  fetchChargePointLogList,
  chargePointTransactionFilter,
  fetchChargePointTransactionList,
  setChargePointLogsFilter,
  setChargePointTransactionsFilter,
  clearChargePointLogs,
  clearChargePointTransactions,
  updateChargePoint,
  updateChargePointRequestState,
  selectedIndex,
  deleteChargePoint,
  deleteChargePointRequestState,
  fetchChargePointListRequestState,
  fetchStationMoveList,
  moveStationsFilter,
  fetchStationListRequestState,
  moveChargePointRequestState,
  setChargePointLogPaginationOffset,
  setChargePointTransactionPaginationOffset,
  editChargePoints,
  clearMoveStations,
  setMoveStationPaginationOffset,
  stationSelectedIndex,
  fetchChargePointLogListRequestState,
  fetchChargePointTransactionListRequestState,
  tenantUser,
  tenant,
  chargePointLogs,
  chargePointTransactions,
  resetChargePointRequestState,
  activateChargePoint,
  resetChargePointState,
  fetchChargePointRequestState,
  clearChargePointCacheRequestState,
  chargePoints,
  resetFilters,
  changeChargePointAvailabilityRequestState,
  selectedStation,
  scrollStationListToIndex,
  fetchChargePoint,
  fetchChargePointList,
  deleteChargePointSubscriptionsRequestState,
  addChargePointSubscriptionsRequestState,
  addChargePointAccessGroupsBulkRequestState,
  deleteChargePointAccessGroupsBulkRequestState,
  deleteChargePointPrivacyRulesRequestState,
  addChargePointPrivacyRulesRequestState,
  chargePointUid,
  isChargePointLogsLive,
  setIsChargePointLogsLive,
  chargePointPrivacy,
  chargePointAppVisibility,
  chargePointUptimeData,
  fetchChargePointUptimeStatistics,
  fetchChargePointUptimeStatisticsRequestState,
  chargePointStatisticsFilter,
  setChargePointStatisticsFilter,
  connectorsUtilizationData,
  fetchConnectorsUtilization,
  fetchConnectorsUtilizationRequestState,
  fetchChargePointSubscribedAlerts,
  fetchChargePointAlerts,
  fetchChargePointLocalList,
  fetchChargePointCompositeSchedule,
}) => {
  const { t } = useTranslation()

  const confirmDeleteDialog = useRef()
  const resetDialog = useRef()
  const moveStationRef = useRef()
  const configurationRef = useRef()
  const chargePointDetailsTabsRef = useRef()
  const firmwareRef = useRef()
  const uploadDiagnosticsRef = useRef()
  const connectorListRef = useRef()
  const appToggleRef = useRef()
  const chargePointPrivacyRef = useRef()
  const terminalRef = useRef()
  const localListRef = useRef()
  const chargePointSpecsEditRef = useRef()
  const chargepointSpecsContainerRef = useRef()
  const alertModalRef = useRef()
  const ProfileModalRef = useRef()

  const toolTipTitles = [
    {
      title: t('ChargePointDetails.SerialNumber'),
      detail: chargePoint?.serial ? chargePoint?.serial : '—',
    },
    {
      title: t('ChargePointDetails.Model'),
      detail: chargePoint?.model ? chargePoint?.model : '—',
    },
    {
      title: t('ChargePointDetails.Vendor'),
      detail: chargePoint?.vendor ? chargePoint?.vendor : '—',
    },
    {
      title: t('ChargePointDetails.FirmwareVersion'),
      detail: chargePoint?.firmwareVersion ? chargePoint?.firmwareVersion : '—',
    },
    {
      title: t('ChargePointDetails.OCPPVersion'),
      detail: chargePoint?.ocppVersion ? chargePoint?.ocppVersion : '—',
    },
    {
      title: t('ChargePointDetails.IMSI'),
      detail: chargePoint?.imsi ? chargePoint?.imsi : '—',
    },
    {
      title: t('ChargePointDetails.ICCID'),
      detail: chargePoint?.iccid ? chargePoint?.iccid : '—',
    },
  ]
  const [isEdit, setIsEdit] = useState(false)
  const [newChargePoint, setNewChargePoint] = useState({})
  const [selectedDetails, setSelectedDetails] = useState(0)
  const [title, setTitle] = useState(chargePointName ? chargePointName : '')
  const [isConnecting, setIsConnecting] = useState(
    chargePoint
      ? !chargePoint.isActivated && chargePoint.connectors.length === 0
      : false,
  )
  const connectorEdits = useRef(
    chargePoint
      ? chargePoint.connectors.map((conn) => {
          return { _id: conn._id, uid: conn.uid, type: '', maxPower: -1 }
        })
      : [],
  )
  const [logListLastUpdated, setLogListLastUpdated] = useState(null)
  const [transactionListLastUpdated, setTransactionListLastUpdated] =
    useState(null)
  const [cpUptimeLastUpdated, setCpUpTimeLastUpdated] = useState(null)
  const [refreshingLogs, setRefreshingLogs] = useState(false)
  const [isFiltersSelected, setIsFiltersSelected] = useState(false)
  const [ascendingLogs, setAscendingLogs] = useState(false)
  const [ascendingTransactions, setAscendingTransactions] = useState(false)
  const [toggleValueChanged, setToggleValueChanged] = useState(false)
  const [isPrivate, setIsPrivate] = useState(chargePoint?.isPrivate)

  const handleChargePointConnectorsUpdates = () => {
    connectorEdits.current = []

    chargePointConnectors.forEach((connector) => {
      const connectorFound = connectorEdits.current.find(
        (connectorEdit) => connectorEdit._id === connector._id,
      )
      if (!connectorFound) {
        connectorEdits.current.push({
          _id: connector._id,
          uid: connector.uid,
          type: '',
          maxPower: -1,
        })
      }
    })
  }

  useEffect(() => {
    if (chargePoint) {
      setIsConnecting(
        !chargePoint.isActivated && chargePoint.connectors.length === 0,
      )
      if (
        !chargePoint.isActivated &&
        chargePoint.connectors.length !== connectorEdits.current.length
      ) {
        handleChargePointConnectorsUpdates()
      }
    }
  }, [chargePointConnectors])

  useEffect(() => {
    setTitle(chargePointName ? chargePointName : '')
  }, [chargePointName])

  useEffect(() => {
    chargePointPrivacyRef.current?.setValue(!chargePointPrivacy)
  }, [chargePointPrivacy])

  useEffect(() => {
    appToggleRef.current?.setValue(!chargePointAppVisibility)
  }, [chargePointAppVisibility])

  useEffect(() => {
    handleChargePointConnectorsUpdates()

    setTitle(chargePoint ? chargePoint.name : '')
    if (
      chargePoint &&
      connectorListRef &&
      connectorListRef.current &&
      connectorListRef.current !== null
    ) {
      connectorListRef.current.resetMaxPowerValues()
      connectorListRef.current.closeAccordions()
      connectorListRef.current.resetStatusValue()
    }

    if (chargePointDetailsTabsRef.current) {
      chargePointDetailsTabsRef.current.resetIndex()
      setSelectedDetails(0)
    }
    setChargePointLogPaginationOffset(0)
    clearChargePointLogs()
    clearChargePointTransactions()
    if (chargePoint) {
      fetchChargePointLogList(
        chargePointLogFilter,
        0,
        chargePoint._id,
        ascendingLogs,
      )
      const updatedFilterConnectors = [...chargePointTransactionFilter]
      updatedFilterConnectors.forEach((filter) => {
        if (filter.field === 'connector') {
          const connectorsUid = chargePoint.connectors
            .filter((connector) => connector.uid !== 0)
            .map((connector) => connector.uid)
          filter.data = connectorsUid
        }
      })
      setChargePointTransactionsFilter(updatedFilterConnectors)
    }
    setLogListLastUpdated(null)
    setTransactionListLastUpdated(null)
    setCpUpTimeLastUpdated(null)
    setIsFiltersSelected(false)
    resetFilters()
    setAscendingLogs(false)
    setAscendingTransactions(false)
  }, [chargePointUid])

  useEffect(() => {
    if (chargePoint && selectedDetails === 0) {
      setChargePointLogPaginationOffset(0)
      fetchChargePointLogList(
        chargePointLogFilter,
        0,
        chargePoint._id,
        ascendingLogs,
      )
    } else if (chargePoint && selectedDetails === 1) {
      setChargePointTransactionPaginationOffset(0)
      fetchChargePointTransactionList(
        chargePointTransactionFilter,
        0,
        chargePoint._id,
        ascendingTransactions,
      )
    } else if (chargePoint && selectedDetails === 2) {
      fetchChargePointUptimeStatistics(
        chargePoint._id,
        chargePointStatisticsFilter,
      )
      fetchConnectorsUtilization(chargePoint._id, chargePointStatisticsFilter)
    }
  }, [selectedDetails])

  useEffect(() => {
    if (updateChargePointRequestState === RequestState.SUCCEEDED) {
      editChargePoints(selectedIndex, newChargePoint)
      resetChargePointState()
    }
    if (
      (updateChargePointRequestState === RequestState.SUCCEEDED ||
        moveChargePointRequestState === RequestState.SUCCEEDED ||
        changeChargePointAvailabilityRequestState === RequestState.SUCCEEDED ||
        deleteChargePointSubscriptionsRequestState === RequestState.SUCCEEDED ||
        addChargePointSubscriptionsRequestState === RequestState.SUCCEEDED ||
        addChargePointAccessGroupsBulkRequestState === RequestState.SUCCEEDED ||
        deleteChargePointAccessGroupsBulkRequestState ===
          RequestState.SUCCEEDED ||
        deleteChargePointPrivacyRulesRequestState === RequestState.SUCCEEDED ||
        addChargePointPrivacyRulesRequestState === RequestState.SUCCEEDED) &&
      updateChargePointRequestState !== RequestState.LOADING &&
      moveChargePointRequestState !== RequestState.LOADING &&
      changeChargePointAvailabilityRequestState !== RequestState.LOADING &&
      deleteChargePointSubscriptionsRequestState !== RequestState.LOADING &&
      addChargePointSubscriptionsRequestState !== RequestState.LOADING &&
      addChargePointAccessGroupsBulkRequestState !== RequestState.LOADING &&
      deleteChargePointAccessGroupsBulkRequestState !== RequestState.LOADING &&
      deleteChargePointPrivacyRulesRequestState !== RequestState.LOADING &&
      addChargePointPrivacyRulesRequestState !== RequestState.LOADING
    ) {
      let toasterMessage = ''
      if (newChargePoint.activate && !newChargePoint.preActivate) {
        toasterMessage = t('ToasterMessages.StationActivated')
      } else toasterMessage = t('ToasterMessages.StationUpdated')
      if (toasterMessage.length > 0) {
        toaster.push(<IFToastMessage type="success" text={toasterMessage} />, {
          placement: 'topEnd',
        })
      }
      setNewChargePoint({})
      setIsEdit(false)
    }
  }, [
    updateChargePointRequestState,
    moveChargePointRequestState,
    changeChargePointAvailabilityRequestState,
    deleteChargePointSubscriptionsRequestState,
    addChargePointSubscriptionsRequestState,
    addChargePointAccessGroupsBulkRequestState,
    deleteChargePointAccessGroupsBulkRequestState,
    deleteChargePointPrivacyRulesRequestState,
    addChargePointPrivacyRulesRequestState,
  ])

  useEffect(() => {
    if (deleteChargePointRequestState === RequestState.SUCCEEDED) {
      if (confirmDeleteDialog.current) confirmDeleteDialog.current.dismiss()
      resetChargePointState()
      setIsEdit(false)
    }
  }, [deleteChargePointRequestState])

  useEffect(() => {
    if (chargePoint) {
      setChargePointLogPaginationOffset(0)
      fetchChargePointLogList(
        chargePointLogFilter,
        0,
        chargePoint._id,
        ascendingLogs,
      )
    }
  }, [chargePointLogFilter])

  useEffect(() => {
    if (chargePoint) {
      setChargePointTransactionPaginationOffset(0)
      fetchChargePointTransactionList(
        chargePointTransactionFilter,
        0,
        chargePoint._id,
        ascendingTransactions,
      )
    }
  }, [chargePointTransactionFilter])

  useEffect(() => {
    if (
      !(
        resetChargePointRequestState === RequestState.UNINITIALIZED ||
        resetChargePointRequestState === RequestState.LOADING
      ) &&
      resetDialog
    ) {
      resetDialog.current.dismiss()
    }
  }, [resetChargePointRequestState])
  useEffect(() => {
    if (
      fetchChargePointLogListRequestState !== RequestState.LOADING &&
      fetchChargePointLogListRequestState !== RequestState.UNINITIALIZED
    ) {
      setRefreshingLogs(false)
      setLogListLastUpdated(Date.now())
    }
  }, [fetchChargePointLogListRequestState])

  useEffect(() => {
    if (
      fetchChargePointUptimeStatisticsRequestState !== RequestState.LOADING &&
      fetchChargePointUptimeStatisticsRequestState !==
        RequestState.UNINITIALIZED
    ) {
      setRefreshingLogs(false)
      setCpUpTimeLastUpdated(Date.now())
    }
  }, [fetchChargePointUptimeStatisticsRequestState])

  useEffect(() => {
    if (
      fetchConnectorsUtilizationRequestState !== RequestState.LOADING &&
      fetchConnectorsUtilizationRequestState !== RequestState.UNINITIALIZED
    ) {
      setRefreshingLogs(false)
      setCpUpTimeLastUpdated(Date.now())
    }
  }, [fetchConnectorsUtilizationRequestState])

  useEffect(() => {
    if (
      fetchChargePointTransactionListRequestState !== RequestState.LOADING &&
      fetchChargePointTransactionListRequestState !== RequestState.UNINITIALIZED
    ) {
      setRefreshingLogs(false)
      setTransactionListLastUpdated(Date.now())
    }
  }, [fetchChargePointTransactionListRequestState])

  useEffect(() => {
    setIsEdit(false)
  }, [selectedIndex, selectedStation])

  const handleSelectedTab = (index) => {
    setIsFiltersSelected(false)
    setSelectedDetails(index)
  }
  const handleChargePointLogs = (newFilter) => {
    clearChargePointLogs()
    const adjustedFilter = newFilter.map((filter) => {
      if (filter.value) filter.initialValue = filter.value
      return filter
    })
    setChargePointLogsFilter(adjustedFilter)
  }
  const handleChargePointTransactions = (newFilter) => {
    clearChargePointTransactions()
    const adjustedFilter = newFilter.map((filter) => {
      if (filter.value) filter.initialValue = filter.value
      return filter
    })
    setChargePointTransactionsFilter(adjustedFilter)
  }

  const handleConfigurationOpen = () => {
    configurationRef.current.handleOpen()
    fetchChargePointConfigList(chargePoint._id)
  }

  const handleAlertModalOpen = () => {
    alertModalRef.current.handleOpen()
    fetchChargePointSubscribedAlerts(chargePoint._id)
    fetchChargePointAlerts(chargePoint._id)
  }

  const handleMaxPowerChange = (value, id) => {
    let connector = connectorEdits.current.find((connector) => {
      return id === connector._id
    })
    connector.maxPower = value
  }

  const handleStatusChange = (value, id) => {
    let updatedList = connectorEdits.current.map((connector) => {
      if (id === connector._id) {
        return { ...connector, status: value }
      }
      return connector
    })

    connectorEdits.current = updatedList
  }

  const handleTypeChange = (value, id) => {
    let connector = connectorEdits.current.find((connector) => {
      return id === connector._id
    })
    connector.type = value
  }

  const handleCancel = () => {
    setIsEdit(false)
    connectorEdits.current = chargePoint.connectors.map((conn) => {
      return { _id: conn._id, uid: conn.uid, type: '', maxPower: -1 }
    })

    setTitle(chargePoint.name)
    connectorListRef.current.resetMaxPowerValues()
    connectorListRef.current.closeAccordions()
    connectorListRef.current.resetStatusValue()
    chargePointSpecsEditRef.current.handleCancel()
    if (
      chargePoint &&
      tenant.isUsingMobileApp &&
      appToggleRef.current.getValue &&
      chargePoint.isHidden === appToggleRef.current.getValue()
    )
      appToggleRef.current.toggleValue()

    if (
      chargePoint &&
      chargePointPrivacyRef.current.getValue &&
      chargePoint.isPrivate === chargePointPrivacyRef.current.getValue()
    )
      chargePointPrivacyRef.current.toggleValue()
  }

  const changeAvailability = (connectorStatus) => {
    OCPPServices.changeAvailability(chargePoint._id, connectorStatus, () => {})
  }

  const handleSubmit = (activate) => {
    let chargePointConnectors = chargePoint.connectors
    let newChargePoint = {}
    let connectors = []
    let connectorFlag = 0
    let connectorStatus = []

    if (title !== chargePoint.name) {
      newChargePoint.name = title
    }

    let reversedPrivacy = false
    if (
      chargePointPrivacyRef.current &&
      chargePointPrivacyRef.current.getValue
    ) {
      reversedPrivacy = !chargePointPrivacyRef.current.getValue()
      if (reversedPrivacy !== chargePoint.isPrivate)
        newChargePoint.isPrivate = reversedPrivacy
    }

    connectorEdits.current.map((connector) => {
      const connectorObj = chargePointConnectors.find(
        (conn) => conn._id === connector._id,
      )
      if (connector.maxPower > 0 && connector.type !== '') {
        connectorFlag = 1
        connectors.push({
          ...connectorObj,
          maxPower: Number(connector.maxPower),
          type: connector.type,
        })
      } else if (connector.maxPower > 0) {
        connectorFlag = 1
        connectors.push({
          ...connectorObj,
          maxPower: Number(connector.maxPower),
        })
      } else if (connector.type !== '') {
        connectorFlag = 1
        connectors.push({
          ...connectorObj,
          type: connector.type,
        })
      } else {
        connectors.push({
          ...connectorObj,
        })
      }
      if (connector.status) {
        const mappedStatus = mapStatus(connectorObj.status).text
        if (mappedStatus !== connector.status)
          connectorStatus.push({
            id: connector.uid,
            status: connector.status === 'Available' ? true : false,
          })
      }
    })

    if (activate) {
      newChargePoint.activate = true
    }
    if (connectorFlag) newChargePoint.connectors = connectors
    if (appToggleRef.current) {
      const appVisibility = appToggleRef.current.getValue()
      if (chargePoint.isHidden === appVisibility)
        newChargePoint.isHidden = !appVisibility
    }

    if (
      newChargePoint.name ||
      newChargePoint.connectors ||
      newChargePoint.activate ||
      'isHidden' in newChargePoint ||
      newChargePoint.hasOwnProperty('isPrivate')
    ) {
      updateChargePoint(selectedIndex, newChargePoint, () => {
        if (connectorStatus.length !== 0) {
          changeAvailability(connectorStatus)
        }
      })
      if (newChargePoint.activate) {
        activateChargePoint(selectedIndex)
        newChargePoint.preActivate = chargePoint.isActivated
      }
      setNewChargePoint(newChargePoint)
      if (chargePointSpecsEditRef.current.didChange())
        chargePointSpecsEditRef.current.handleSubmit(false)
    } else if (
      connectorStatus.length !== 0 ||
      chargePointSpecsEditRef.current.didChange()
    ) {
      if (connectorStatus.length !== 0) {
        changeAvailability(connectorStatus)
      }
      if (chargePointSpecsEditRef.current.didChange())
        chargePointSpecsEditRef.current.handleSubmit(false)
    } else setIsEdit(false)
  }

  const handleSubmitRef = useRef(handleSubmit)

  useEffect(() => {
    handleSubmitRef.current = handleSubmit
  })

  useEffect(() => {
    if (chargePoint) {
      fetchChargePointUptimeStatistics(
        chargePoint._id,
        chargePointStatisticsFilter,
      )
      fetchConnectorsUtilization(chargePoint._id, chargePointStatisticsFilter)
    }
  }, [chargePointStatisticsFilter])

  const handleMoveModal = () => {
    clearMoveStations()
    setMoveStationPaginationOffset(0)
    moveStationRef.current.handleOpen()
    fetchStationMoveList(moveStationsFilter, 0)
  }

  const userResetPermissionsText = () => {
    return tenantUser.permissions.includes(
      InfinityEnums.TenantUserPermission.CAN_SOFT_RESET_CHARGEPOINT,
    ) &&
      tenantUser.permissions.includes(
        InfinityEnums.TenantUserPermission.CAN_HARD_RESET_CHARGEPOINT,
      )
      ? t('ChargePointDetails.BothResetDialogSubtitle')
      : tenantUser.permissions.includes(
          InfinityEnums.TenantUserPermission.CAN_SOFT_RESET_CHARGEPOINT,
        )
      ? t('ChargePointDetails.SoftResetDialogSubtitle')
      : tenantUser.permissions.includes(
          InfinityEnums.TenantUserPermission.CAN_HARD_RESET_CHARGEPOINT,
        )
      ? t('ChargePointDetails.HardResetDialogSubtitle')
      : ''
  }

  const toolTipTitle = (title, details, index) => {
    return (
      <div
        key={`toolTipTitle ${index}`}
        className={
          index === 0 ? Styles.ToolTipContainerFirst : Styles.ToolTipContainer
        }
      >
        <div className={Styles.LeftToolTipDetails}>
          <IFText
            style={{ color: Colors.text }}
            className={Styles.ToolTipTitle}
          >
            {title}
          </IFText>
        </div>
        <div className={Styles.RightToolTipDetails}>
          <IFText
            style={{ color: Colors.tooltipText }}
            className={Styles.ToolTipDetail}
          >
            {details}
          </IFText>
        </div>
      </div>
    )
  }
  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) return true
    else return false
  }

  const permissionArray = [
    InfinityEnums.TenantUserPermission.CAN_VIEW_CONFIGURATION,
    InfinityEnums.TenantUserPermission.CAN_VIEW_DIAGNOSTICS,
    InfinityEnums.TenantUserPermission.CAN_VIEW_FIRMWARE,
    InfinityEnums.TenantUserPermission.CAN_USE_TERMINAL,
    InfinityEnums.TenantUserPermission.CAN_CLEAR_CACHE,
    InfinityEnums.TenantUserPermission.CAN_HARD_RESET_CHARGEPOINT,
    InfinityEnums.TenantUserPermission.CAN_SOFT_RESET_CHARGEPOINT,
    InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGEPOINT,
  ]

  const canEdit = chargePoint
    ? !isConnecting && !chargePoint.isActivated
      ? tenantUser.permissions.includes(
          InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGEPOINT,
        )
      : permissionArray.some((permission) =>
          tenantUser.permissions.includes(permission),
        )
    : true

  const calculateConnectorUtilizationGraphHeight = (numConnectors) => {
    if (isNaN(numConnectors)) return '250px'
    return `${numConnectors * 48 + 90}px`
  }

  return (
    <Fragment>
      {fetchChargePointRequestState === RequestState.ERROR_0_NETWORK ||
      (fetchChargePointListRequestState === RequestState.ERROR_0_NETWORK &&
        selectedIndex === 0) ? (
        <div style={{ flexGrow: 1 }}>
          <NoInternet
            onClick={() => {
              if (chargePoints.length === 0) {
                fetchChargePointList(selectedStation._id)
              } else {
                fetchChargePoint(chargePoints[selectedIndex]._id)
              }
            }}
          />
        </div>
      ) : (
        <div
          className={Styles.Container}
          style={
            isEdit &&
            (updateChargePointRequestState === RequestState.LOADING ||
              changeChargePointAvailabilityRequestState ===
                RequestState.LOADING ||
              deleteChargePointSubscriptionsRequestState ===
                RequestState.LOADING ||
              addChargePointSubscriptionsRequestState ===
                RequestState.LOADING ||
              addChargePointAccessGroupsBulkRequestState ===
                RequestState.LOADING ||
              deleteChargePointAccessGroupsBulkRequestState ===
                RequestState.LOADING ||
              deleteChargePointPrivacyRulesRequestState ===
                RequestState.LOADING ||
              addChargePointPrivacyRulesRequestState === RequestState.LOADING)
              ? { pointerEvents: 'none' }
              : {}
          }
        >
          <LeftContainer
            className={Styles.LeftContainer}
            canEdit={canEdit}
            style={canEdit ? {} : { marginRight: '0rem' }}
          >
            <div className={Styles.TitleContainer}>
              {fetchChargePointListRequestState === RequestState.LOADING ||
              fetchChargePointRequestState === RequestState.LOADING ||
              fetchStationListRequestState === RequestState.LOADING ? (
                <IFSkeleton
                  style={{ marginTop: '-8px' }}
                  variant="text"
                  width="14rem"
                  height=" 3rem"
                />
              ) : isEdit ||
                (chargePoint &&
                  !chargePoint.isActivated &&
                  !isConnecting &&
                  tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGEPOINT,
                  )) ? (
                <div className={Styles.ChargePointNameContainer}>
                  <div className={Styles.ChargePointNameWrapper}>
                    <div
                      className={Styles.ChargePointStationNameContainer}
                      onClick={() => {
                        scrollStationListToIndex(stationSelectedIndex)
                      }}
                      style={{
                        backgroundColor: increaseBrightness(Colors.primary, 10),
                      }}
                    >
                      <IFText
                        style={{ color: Colors.white }}
                        className={Styles.NameText}
                      >
                        {selectedStation ? selectedStation.name : ''}
                      </IFText>
                    </div>
                    <div
                      className={`${Styles.ChargePointName} ${Styles.ChargePointNameEdit}`}
                      style={{
                        border: `1.5px solid ${increaseBrightness(
                          Colors.primary,
                          10,
                        )}`,
                      }}
                    >
                      <IFTextInput
                        className={Styles.TextInputStyle}
                        value={title}
                        fontSize={20}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <HtmlTooltip
                      title={
                        <div className={Styles.ToolTipWrapper}>
                          {toolTipTitles.map((item, index) =>
                            toolTipTitle(item.title, item.detail, index),
                          )}
                        </div>
                      }
                      placement="right-start"
                    >
                      <InfoIcon
                        style={{ color: Colors.tooltipGreyBackground }}
                        className={Styles.infoIcon}
                      ></InfoIcon>
                    </HtmlTooltip>
                  </div>
                  <div className={Styles.PrivacyToggleContainer}>
                    <IFToggle
                      ref={chargePointPrivacyRef}
                      height={24}
                      fontSize={12}
                      checkedText={t('ChargePointPrivacyToggle.Public')}
                      uncheckedText={t('ChargePointPrivacyToggle.Private')}
                      CheckedIcon={IFsvg.EncodedUnlock(Colors.primary)}
                      UncheckedIcon={IFsvg.EncodedLock(Colors.primary)}
                      readOnly={
                        (chargePoint && chargePoint.isActivated && !isEdit) ||
                        isConnecting
                      }
                      defaultChecked={!chargePoint?.isPrivate}
                      onChange={(value) => setIsPrivate(value)}
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.ChargePointNameContainer}>
                  <div className={Styles.ChargePointNameWrapper}>
                    <div
                      className={Styles.ChargePointStationNameContainer}
                      onClick={() => {
                        scrollStationListToIndex(stationSelectedIndex)
                      }}
                      style={{
                        backgroundColor: increaseBrightness(Colors.primary, 10),
                      }}
                    >
                      <IFText
                        style={{ color: Colors.white }}
                        className={Styles.NameText}
                      >
                        {selectedStation ? selectedStation.name : ''}
                      </IFText>
                    </div>
                    <div
                      className={Styles.ChargePointName}
                      style={{
                        border: `1.5px solid ${increaseBrightness(
                          Colors.primary,
                          10,
                        )}`,
                      }}
                    >
                      <IFText
                        style={{ color: Colors.text }}
                        className={Styles.NameText}
                      >
                        {chargePointName ? chargePointName : ''}
                      </IFText>
                    </div>
                  </div>

                  <div>
                    {!isConnecting && (
                      <HtmlTooltip
                        title={
                          <div className={Styles.ToolTipWrapper}>
                            {toolTipTitles.map((item, index) =>
                              toolTipTitle(item.title, item.detail, index),
                            )}
                          </div>
                        }
                        placement="right-start"
                      >
                        <InfoIcon
                          style={{ color: Colors.tooltipGreyBackground }}
                          className={Styles.infoIcon}
                        ></InfoIcon>
                      </HtmlTooltip>
                    )}
                  </div>
                  <div className={Styles.PrivacyToggleContainer}>
                    {!isConnecting && (
                      <IFToggle
                        ref={chargePointPrivacyRef}
                        height={22}
                        fontSize={12}
                        checkedText={t('ChargePointPrivacyToggle.Public')}
                        uncheckedText={t('ChargePointPrivacyToggle.Private')}
                        CheckedIcon={IFsvg.EncodedUnlock(Colors.primary)}
                        UncheckedIcon={IFsvg.EncodedLock(Colors.primary)}
                        readOnly={
                          (chargePoint && chargePoint.isActivated && !isEdit) ||
                          isConnecting
                        }
                        defaultChecked={!chargePoint?.isPrivate}
                        onChange={(value) => setIsPrivate(value)}
                      />
                    )}
                  </div>
                </div>
              )}
              {fetchChargePointListRequestState === RequestState.LOADING ||
              fetchChargePointRequestState === RequestState.LOADING ||
              fetchStationListRequestState === RequestState.LOADING ? (
                <div className={Styles.DateSkeleton}>
                  <IFSkeleton variant="text" width="8rem" height=" 1.5rem" />
                </div>
              ) : (
                <div
                  style={{ color: Colors.black }}
                  className={Styles.DateContainer}
                >
                  <Icon
                    sx={{ fontSize: '16px !important' }}
                    component={DateRangeIcon}
                  />
                  <IFText>
                    &nbsp;
                    {chargePoint
                      ? moment(chargePoint.createdAt).format(
                          'MMM DD, YYYY HH:mm',
                        )
                      : ''}
                  </IFText>
                </div>
              )}
            </div>
            <div className={Styles.UidContainer}>
              {fetchChargePointListRequestState === RequestState.LOADING ||
              fetchChargePointRequestState === RequestState.LOADING ||
              fetchStationListRequestState === RequestState.LOADING ? (
                <IFSkeleton variant="text" width="8rem" height=" 2rem" />
              ) : (
                <div className={Styles.ChargePointUidContainer}>
                  {handleChargingIcon(maxPower)}
                  <IFText
                    className={Styles.UIDText}
                    style={{ color: Colors.text }}
                  >
                    {chargePoint ? chargePoint.uid : ''}
                  </IFText>
                </div>
              )}
              <div className={Styles.ToggleLoadingContainer}>
                {(fetchStationListRequestState === RequestState.LOADING ||
                  fetchChargePointListRequestState === RequestState.LOADING ||
                  fetchChargePointRequestState === RequestState.LOADING) &&
                tenant.isUsingMobileApp ? (
                  <div className={Styles.ToggleSkeletonContainer}>
                    <IFSkeleton height="2rem" width="4rem" />
                  </div>
                ) : (
                  <StyledTooltipToggle
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 60 }}
                    title={
                      toggleValueChanged
                        ? t('ChargePointDetails.VisibleInApp')
                        : t('ChargePointDetails.NotvisibleInApp')
                    }
                    placement="left"
                  >
                    <div className={Styles.ToggleContainer}>
                      {!isConnecting &&
                      tenant.isUsingMobileApp &&
                      ((chargePoint && chargePoint.isHidden !== undefined) ||
                        (chargePoint && !chargePoint.isActivated)) ? (
                        <IFToggle
                          ref={appToggleRef}
                          CheckedIcon={
                            <VisibilityRounded
                              sx={{
                                color: Colors.white,
                                fontSize: '16px !important',
                              }}
                            />
                          }
                          UncheckedIcon={
                            <VisibilityOff
                              sx={{
                                color: Colors.white,
                                fontSize: '16px !important',
                              }}
                            />
                          }
                          readOnly={
                            (chargePoint &&
                              chargePoint.isActivated &&
                              !isEdit) ||
                            isConnecting
                          }
                          defaultChecked={!chargePoint.isHidden}
                          onChange={(value) => {
                            setToggleValueChanged(value)
                          }}
                        />
                      ) : null}
                    </div>
                  </StyledTooltipToggle>
                )}
              </div>
            </div>
            <Scrollbars
              className={
                isEdit || (chargePoint && !chargePoint.isActivated)
                  ? Styles.ScrollParentDisable
                  : Styles.ScrollParent
              }
            >
              <div className={isEdit ? '' : Styles.ChargePointSpecsWrapper}>
                <div className={Styles.ConnectorsWrapper}>
                  <div className={Styles.ConnectorsTextContainer}>
                    {fetchChargePointListRequestState ===
                      RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div>
                        <IFSkeleton
                          style={{ height: '1.5rem', width: '6rem' }}
                        />
                      </div>
                    ) : (
                      <IFText style={{ color: Colors.text }} is>
                        {t('ChargePointDetails.Connectors')}
                      </IFText>
                    )}
                  </div>
                  <div className={Styles.ConnectorsContainer}>
                    <ConnectorList
                      ref={connectorListRef}
                      isEdit={
                        isEdit ||
                        (chargePoint ? !chargePoint.isActivated : false)
                      }
                      isLoading={isConnecting}
                      isChargePointOffline={
                        chargePoint ? !chargePoint.isOnline : false
                      }
                      onMaxPowerChange={(value, id) =>
                        handleMaxPowerChange(value, id)
                      }
                      onStatusChange={(value, id) =>
                        handleStatusChange(value, id)
                      }
                      onTypeChange={(value, id) => handleTypeChange(value, id)}
                    />
                  </div>
                </div>
                {(!chargePoint ||
                  (chargePoint &&
                    !isConnecting &&
                    chargePoint.isActivated)) && (
                  <div
                    ref={chargepointSpecsContainerRef}
                    className={Styles.ChargePointSpecsContainer}
                    style={
                      isEdit
                        ? {
                            position: 'absolute',
                            opacity: 0,
                            zIndex: '-10',
                            pointerEvents: 'none',
                            width:
                              chargepointSpecsContainerRef.current.getBoundingClientRect()
                                .width,
                          }
                        : {}
                    }
                  >
                    <ChargePointSpecsContainer />
                  </div>
                )}
              </div>
              {isEdit ||
              (chargePoint &&
                !chargePoint.isActivated &&
                !(
                  fetchChargePointListRequestState === RequestState.LOADING ||
                  fetchChargePointRequestState === RequestState.LOADING ||
                  fetchStationListRequestState === RequestState.LOADING
                )) ? (
                isConnecting ? null : (
                  <ChargerSpecsEdit
                    className={Styles.SpecsEdit}
                    ref={chargePointSpecsEditRef}
                    isActivated={chargePoint?.isActivated}
                    chargePointId={chargePoint?._id}
                  />
                )
              ) : (
                <div className={Styles.logsWrapper}>
                  <div className={Styles.TabsContainer}>
                    <ChargePointDetailsTabs
                      initialIndex={selectedDetails}
                      ref={chargePointDetailsTabsRef}
                      onValueChange={(index) => handleSelectedTab(index)}
                      isLoading={
                        fetchChargePointListRequestState ===
                          RequestState.LOADING ||
                        fetchChargePointRequestState === RequestState.LOADING ||
                        fetchStationListRequestState === RequestState.LOADING ||
                        !logListLastUpdated
                      }
                      canViewUptimeStats={tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission
                          .CAN_VIEW_CHARGEPOINT_UPTIME,
                      )}
                    />

                    {(fetchChargePointListRequestState ===
                      RequestState.LOADING ||
                      fetchStationListRequestState === RequestState.LOADING ||
                      fetchChargePointLogListRequestState ===
                        RequestState.LOADING ||
                      fetchChargePointRequestState === RequestState.LOADING ||
                      fetchChargePointTransactionListRequestState ===
                        RequestState.LOADING) &&
                    !isChargePointLogsLive ? (
                      <div className={Styles.RefreshIconTextContainer}>
                        <div>
                          <div className={Styles.RefreshIconTextSkeleton}>
                            <div className={Styles.RefreshIconSkeleton}>
                              <IFSkeleton
                                width="1rem"
                                height="1rem"
                                variant="circular"
                              />
                            </div>
                            <IFSkeleton
                              height="1.5rem"
                              className={Styles.RefreshTextSkeleton}
                            />
                          </div>

                          <IFSkeleton
                            height="1.25rem"
                            className={Styles.RefreshTextDateSkeleton}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className={Styles.RefreshIconTextContainer}>
                        <div
                          className={Styles.RefreshTextContainer}
                          style={{
                            opacity:
                              selectedDetails == 0
                                ? isChargePointLogsLive
                                  ? 0
                                  : 1
                                : 1,
                          }}
                        >
                          {fetchChargePointListRequestState ===
                            RequestState.LOADING ||
                          fetchStationListRequestState ===
                            RequestState.LOADING ||
                          fetchChargePointRequestState ===
                            RequestState.LOADING ||
                          fetchChargePointTransactionListRequestState ===
                            RequestState.LOADING ||
                          fetchChargePointUptimeStatisticsRequestState ===
                            RequestState.LOADING ||
                          fetchConnectorsUtilizationRequestState ==
                            RequestState.LOADING ? (
                            <div>
                              <div className={Styles.RefreshIconTextSkeleton}>
                                <div className={Styles.RefreshIconSkeleton}>
                                  <IFSkeleton
                                    width="1rem"
                                    height="1rem"
                                    variant="circular"
                                  />
                                </div>
                                <IFSkeleton
                                  height="1.5rem"
                                  className={Styles.RefreshTextSkeleton}
                                />
                              </div>

                              <IFSkeleton
                                height="1.25rem"
                                className={Styles.RefreshTextDateSkeleton}
                              />
                            </div>
                          ) : (
                            <>
                              <div className={Styles.LiveLogs}>
                                <div
                                  className={Styles.RefreshIcon}
                                  onClick={() => {
                                    if (refreshingLogs) return
                                    setRefreshingLogs(true)
                                    if (selectedDetails === 0) {
                                      setChargePointLogPaginationOffset(0)
                                      fetchChargePointLogList(
                                        chargePointLogFilter,
                                        0,
                                        chargePoint._id,
                                        ascendingLogs,
                                      )
                                    } else if (selectedDetails === 1) {
                                      setChargePointTransactionPaginationOffset(
                                        0,
                                      )
                                      fetchChargePointTransactionList(
                                        chargePointTransactionFilter,
                                        0,
                                        chargePoint._id,
                                        ascendingTransactions,
                                      )
                                    } else {
                                      fetchChargePointUptimeStatistics(
                                        chargePoint._id,
                                        chargePointStatisticsFilter,
                                      )
                                      fetchConnectorsUtilization(
                                        chargePoint._id,
                                        chargePointStatisticsFilter,
                                      )
                                    }
                                  }}
                                >
                                  <div className={Styles.RefreshSvg}>
                                    <IFsvg.refresh
                                      height={16}
                                      width={16}
                                      fill={Colors.black}
                                    />
                                  </div>
                                  <IFText
                                    className={Styles.RefreshText}
                                    style={{ color: Colors.black }}
                                  >
                                    {t('ChargePointDetails.lastUpdated')}
                                  </IFText>
                                </div>
                              </div>
                              <IFText className={Styles.RefreshTextDate}>
                                {selectedDetails === 0 && logListLastUpdated
                                  ? moment(logListLastUpdated).format(
                                      'MMM DD, YYYY HH:mm:ss',
                                    )
                                  : selectedDetails === 1 &&
                                    transactionListLastUpdated
                                  ? moment(transactionListLastUpdated).format(
                                      'MMM DD, YYYY HH:mm:ss',
                                    )
                                  : selectedDetails === 2 && cpUptimeLastUpdated
                                  ? moment(cpUptimeLastUpdated).format(
                                      'MMM DD, YYYY HH:mm:ss',
                                    )
                                  : ''}
                              </IFText>
                            </>
                          )}
                        </div>

                        {selectedDetails == 0 ? (
                          fetchChargePointListRequestState ===
                            RequestState.LOADING ||
                          fetchChargePointRequestState ===
                            RequestState.LOADING ||
                          fetchStationListRequestState ===
                            RequestState.LOADING ||
                          !logListLastUpdated ? (
                            <div className={Styles.LiveLogsToggleSkeleton}>
                              <IFSkeleton
                                width="4rem"
                                height="2rem"
                                variant="text"
                                style={{ alignSelf: 'center' }}
                              />
                            </div>
                          ) : (
                            <div className={Styles.LiveLogsToggle}>
                              <IFToggle
                                height={22}
                                fontSize={12}
                                checkedText={t('ChargePointLogsToggle.Live')}
                                uncheckedText={t('ChargePointLogsToggle.Live')}
                                CheckedIcon={IFsvg.liveEncoded({
                                  fill: Colors.primary,
                                  width: '14',
                                  height: '14',
                                })}
                                UncheckedIcon={IFsvg.notLiveEncoded({
                                  fill: Colors.primary,
                                  width: '14',
                                  height: '14',
                                })}
                                readOnly={ascendingLogs}
                                defaultChecked={isChargePointLogsLive}
                                onChange={(value) => {
                                  setIsChargePointLogsLive(value)
                                }}
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </div>

                  <div className={Styles.logsBorder}>
                    <div className={Styles.FilterContainer}>
                      {(selectedDetails === 1 &&
                        !(
                          chargePointTransactions.length === 0 &&
                          checkFilterEmpty(chargePointTransactionFilter)
                        )) ||
                      fetchChargePointListRequestState ===
                        RequestState.LOADING ||
                      fetchChargePointRequestState === RequestState.LOADING ||
                      fetchStationListRequestState === RequestState.LOADING ||
                      fetchChargePointTransactionListRequestState ===
                        RequestState.LOADING ||
                      updateChargePointRequestState === RequestState.LOADING ? (
                        <IFFilter
                          key={'IFFilter_Transactions'}
                          onFilterChange={(newFilter) => {
                            handleChargePointTransactions(newFilter)
                            setIsFiltersSelected(true)
                          }}
                          filters={chargePointTransactionFilter}
                          textFieldPlaceholder={t(
                            'ChargePointDetails.FilterLogsBy',
                          )}
                          downloadTransactions={tenantUser.permissions.includes(
                            InfinityEnums.TenantUserPermission
                              .CAN_DOWNLOAD_TRANSACTIONS,
                          )}
                          isLoading={
                            fetchChargePointListRequestState ===
                              RequestState.LOADING ||
                            fetchChargePointRequestState ===
                              RequestState.LOADING ||
                            fetchStationListRequestState ===
                              RequestState.LOADING ||
                            (fetchChargePointTransactionListRequestState ===
                              RequestState.LOADING &&
                              !isFiltersSelected)
                          }
                          canSort={true}
                          ascending={ascendingTransactions}
                          onSortChange={() => {
                            setIsFiltersSelected(true)
                            clearChargePointTransactions()
                            setChargePointTransactionPaginationOffset(0)
                            fetchChargePointTransactionList(
                              chargePointTransactionFilter,
                              0,
                              chargePoint._id,
                              !ascendingTransactions,
                            )
                            setAscendingTransactions((prev) => {
                              return !prev
                            })
                          }}
                        />
                      ) : (selectedDetails === 0 &&
                          !(
                            chargePointLogs.length === 0 &&
                            checkFilterEmpty(chargePointLogFilter)
                          )) ||
                        fetchChargePointListRequestState ===
                          RequestState.LOADING ||
                        fetchChargePointRequestState === RequestState.LOADING ||
                        fetchStationListRequestState === RequestState.LOADING ||
                        fetchChargePointLogListRequestState ===
                          RequestState.LOADING ||
                        updateChargePointRequestState ===
                          RequestState.LOADING ? (
                        <IFFilter
                          key={'IFFilter_Logs'}
                          onFilterChange={(newFilter) => {
                            handleChargePointLogs(newFilter)
                            setIsFiltersSelected(true)
                          }}
                          downloadChargePointLogs={tenantUser.permissions.includes(
                            InfinityEnums.TenantUserPermission
                              .CAN_DOWNLOAD_STATION_LOGS,
                          )}
                          filters={chargePointLogFilter}
                          textFieldPlaceholder={t(
                            'ChargePointDetails.FilterLogsBy',
                          )}
                          isLoading={
                            fetchChargePointListRequestState ===
                              RequestState.LOADING ||
                            fetchChargePointRequestState ===
                              RequestState.LOADING ||
                            fetchStationListRequestState ===
                              RequestState.LOADING ||
                            (fetchChargePointLogListRequestState ===
                              RequestState.LOADING &&
                              !isFiltersSelected)
                          }
                          canSort={true}
                          ascending={ascendingLogs}
                          onSortChange={() => {
                            setIsFiltersSelected(true)
                            clearChargePointLogs()
                            setChargePointLogPaginationOffset(0)
                            fetchChargePointLogList(
                              chargePointLogFilter,
                              0,
                              chargePoint._id,
                              !ascendingLogs,
                            )
                            setAscendingLogs((prev) => {
                              return !prev
                            })
                          }}
                        />
                      ) : selectedDetails === 2 ? (
                        <IFFilter
                          key={'IFFilter_ChargepointStatistics'}
                          onFilterChange={(newFilter, index) => {
                            const adjustedFilter = newFilter.map((filter) => {
                              if (filter.value.length === 0) {
                                filter.value =
                                  chargePointStatisticsFilter[index].value
                              }
                              filter.initialValue = filter.value

                              return filter
                            })
                            setChargePointStatisticsFilter(adjustedFilter)
                          }}
                          filters={chargePointStatisticsFilter}
                          textFieldPlaceholder={t(
                            'ChargePointDetails.ChargePointUptimeFilter',
                          )}
                          isLoading={
                            fetchChargePointListRequestState ===
                              RequestState.LOADING ||
                            fetchChargePointRequestState ===
                              RequestState.LOADING ||
                            fetchStationListRequestState ===
                              RequestState.LOADING ||
                            fetchChargePointUptimeStatisticsRequestState ===
                              RequestState.LOADING ||
                            fetchConnectorsUtilizationRequestState ==
                              RequestState.LOADING
                          }
                          disableKeyword={true}
                          fixedFilters={true}
                          disableFutureDates={true}
                        />
                      ) : null}
                    </div>
                    <div className={Styles.ListContainer}>
                      <div className={Styles.LogsContainer}>
                        {selectedDetails === 1 ? (
                          <ChargePointTransactionList
                            ascending={ascendingTransactions}
                          />
                        ) : selectedDetails === 0 ? (
                          <ChargePointLogList ascending={ascendingLogs} />
                        ) : selectedDetails === 2 ? (
                          <>
                            {fetchChargePointUptimeStatisticsRequestState ===
                            RequestState.SUCCEEDED ? (
                              <>
                                <StationUptimeMonitor
                                  chartWidth={'100%'}
                                  onlineSeriesData={
                                    chargePointUptimeData.uptimeGraphSeries[0]
                                      .data
                                  }
                                  offlineSeriesData={
                                    chargePointUptimeData.uptimeGraphSeries[1]
                                      .data
                                  }
                                  unstableSeriesData={
                                    chargePointUptimeData.uptimeGraphSeries[2]
                                      .data
                                  }
                                  startTimestamp={new Date(
                                    chargePointUptimeData.graphStartTimestamp,
                                  ).getTime()}
                                  endTimestamp={new Date(
                                    chargePointUptimeData.graphEndTimestamp,
                                  ).getTime()}
                                  onlinePercentage={
                                    chargePointUptimeData.uptimePercentages
                                      .online
                                  }
                                  offlinePercentage={
                                    chargePointUptimeData.uptimePercentages
                                      .offline
                                  }
                                  unstablePercentage={
                                    chargePointUptimeData.uptimePercentages
                                      .unstable
                                  }
                                  noData={chargePointUptimeData.isEmpty}
                                />
                              </>
                            ) : fetchChargePointUptimeStatisticsRequestState ===
                              RequestState.LOADING ? (
                              <div className={Styles.ChartSkeletonContainer}>
                                <div
                                  className={Styles.ChartResetButtonSkeleton}
                                >
                                  <IFButtonSkeleton size="xsm" />
                                </div>

                                <IFSkeleton
                                  variant="text"
                                  width="100%"
                                  height="9.5rem"
                                />
                              </div>
                            ) : null}

                            {fetchConnectorsUtilizationRequestState ===
                            RequestState.SUCCEEDED ? (
                              <>
                                <ConnectorUtilization
                                  chartWidth={'100%'}
                                  chartHeight={calculateConnectorUtilizationGraphHeight(
                                    chargePointConnectors.filter(
                                      (connector) => connector.uid !== 0,
                                    )?.length, // To disregard default connector
                                  )}
                                  availableSeriesData={
                                    connectorsUtilizationData
                                      .utilizationGraphSeries[0].data
                                  }
                                  chargingSeriesData={
                                    connectorsUtilizationData
                                      .utilizationGraphSeries[1].data
                                  }
                                  occupiedSeriesData={
                                    connectorsUtilizationData
                                      .utilizationGraphSeries[2].data
                                  }
                                  unavailableSeriesData={
                                    connectorsUtilizationData
                                      .utilizationGraphSeries[3].data
                                  }
                                  faultedSeriesData={
                                    connectorsUtilizationData
                                      .utilizationGraphSeries[4].data
                                  }
                                  availablePercentage={
                                    connectorsUtilizationData
                                      .utilizationPercentages.available
                                  }
                                  chargingPercentage={
                                    connectorsUtilizationData
                                      .utilizationPercentages.charging
                                  }
                                  occupiedPercentage={
                                    connectorsUtilizationData
                                      .utilizationPercentages.occupied
                                  }
                                  unavailablePercentage={
                                    connectorsUtilizationData
                                      .utilizationPercentages.unavailable
                                  }
                                  faultedPercentage={
                                    connectorsUtilizationData
                                      .utilizationPercentages.faulted
                                  }
                                  startTimestamp={new Date(
                                    connectorsUtilizationData.graphStartTimestamp,
                                  ).getTime()}
                                  endTimestamp={new Date(
                                    connectorsUtilizationData.graphEndTimestamp,
                                  ).getTime()}
                                  noData={connectorsUtilizationData.isEmpty}
                                />
                              </>
                            ) : fetchConnectorsUtilizationRequestState ===
                              RequestState.LOADING ? (
                              <div className={Styles.ChartSkeletonContainer}>
                                <div
                                  className={Styles.ChartResetButtonSkeleton}
                                >
                                  <IFButtonSkeleton size="xsm" />
                                </div>

                                <IFSkeleton
                                  variant="text"
                                  width="100%"
                                  height="9.5rem"
                                />
                              </div>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Scrollbars>
          </LeftContainer>

          {chargePoint && isEdit && chargePoint.isActivated ? (
            <div className={Styles.RightContainerEdit}>
              <IFTooltipIconsLoading
                onClick={handleMoveModal}
                tooltipPlacement={'left'}
                title={t('ChargePointDetails.MoveButtonText')}
                Icon={IFsvg.Move}
                FilledIcon={IFsvg.Move}
                isLoading={false}
                size={26}
                iconClassname={Styles.iconContainer}
              />
              {tenantUser.permissions.includes(
                InfinityEnums.TenantUserPermission.CAN_DELETE_CHARGEPOINT,
              ) ? (
                <IFTooltipIconsLoading
                  onClick={() => confirmDeleteDialog.current.show()}
                  tooltipPlacement={'left'}
                  iconColor={Colors.red}
                  iconBackgroundColor={Colors.red}
                  title={t('ChargePointDetails.DeleteButtonText')}
                  Icon={IFsvg.Delete}
                  FilledIcon={IFsvg.Delete}
                  isLoading={false}
                  size={26}
                  iconClassname={Styles.iconContainer}
                />
              ) : null}
              <DividerDivHorizontal className={Styles.DividerHorizontal} />
              <IFTooltipIconsLoading
                onClick={handleCancel}
                tooltipPlacement={'left'}
                title={t('ChargePointDetails.CancelButtonText')}
                Icon={IFsvg.Cancel}
                FilledIcon={IFsvg.Cancel}
                isLoading={false}
                size={26}
                iconClassname={Styles.iconContainer}
              />
              {updateChargePointRequestState === RequestState.LOADING &&
              !isEdit ? (
                <div className={Styles.iconContainerSkeleton}>
                  <IFSkeleton variant="circular" width="26px" height="26px" />
                </div>
              ) : (
                <IFTooltipIconsLoading
                  onClick={() => {
                    handleSubmitRef.current()
                  }}
                  tooltipPlacement={'left'}
                  title={t('ChargePointDetails.DoneButtonText')}
                  Icon={IFsvg.Done}
                  FilledIcon={IFsvg.Done}
                  isLoading={
                    updateChargePointRequestState === RequestState.LOADING ||
                    changeChargePointAvailabilityRequestState ===
                      RequestState.LOADING ||
                    deleteChargePointSubscriptionsRequestState ===
                      RequestState.LOADING ||
                    addChargePointSubscriptionsRequestState ===
                      RequestState.LOADING ||
                    addChargePointAccessGroupsBulkRequestState ===
                      RequestState.LOADING ||
                    deleteChargePointAccessGroupsBulkRequestState ===
                      RequestState.LOADING ||
                    deleteChargePointPrivacyRulesRequestState ===
                      RequestState.LOADING ||
                    addChargePointPrivacyRulesRequestState ===
                      RequestState.LOADING
                  }
                  size={26}
                  iconClassname={Styles.iconContainer}
                  animationDisabled={false}
                />
              )}
            </div>
          ) : (
            <div className={canEdit ? Styles.RightContainer : ''}>
              {((chargePoint && chargePoint.isActivated) ||
                fetchChargePointListRequestState === RequestState.LOADING ||
                fetchChargePointRequestState === RequestState.LOADING ||
                fetchStationListRequestState === RequestState.LOADING) && (
                <div className={Styles.TopButtonsContainer}>
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_CONFIGURATION,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={handleConfigurationOpen}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.ConfigurationButtonText')}
                        Icon={IFsvg.Tune}
                        FilledIcon={IFsvg.Tune}
                        isLoading={false}
                        size={26}
                        iconClassname={Styles.iconContainer}
                      />
                    )
                  ) : null}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_CONFIGURATION,
                  ) && (
                    <DividerDivHorizontal
                      className={Styles.DividerHorizontal}
                    />
                  )}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_DIAGNOSTICS,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          uploadDiagnosticsRef.current.handleOpen()
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.DiagnosticsButtonText')}
                        Icon={IFsvg.FindInPage}
                        FilledIcon={IFsvg.FindInPage}
                        isLoading={false}
                        size={26}
                        iconClassname={Styles.iconContainer}
                      />
                    )
                  ) : null}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_FIRMWARE,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          firmwareRef.current.handleOpen()
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.FirmwareButtonText')}
                        Icon={IFsvg.SystemUpdate}
                        FilledIcon={IFsvg.SystemUpdate}
                        isLoading={false}
                        size={26}
                        iconClassname={Styles.iconContainer}
                      />
                    )
                  ) : null}
                  {(tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_DIAGNOSTICS,
                  ) ||
                    tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_VIEW_FIRMWARE,
                    )) && (
                    <DividerDivHorizontal
                      className={Styles.DividerHorizontal}
                    />
                  )}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission
                      .CAN_EDIT_CHARGEPOINT_CHARGING_PROFILE,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          fetchChargePointCompositeSchedule(
                            chargePoint._id,
                            chargePoint.connectors[0].uid,
                            weekToSecs,
                            'A',
                          )
                          ProfileModalRef.current.handleOpen()
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargingProfileModal.CompositeSchedule')}
                        Icon={IFsvg.PendingActions}
                        FilledIcon={IFsvg.PendingActions}
                        size={26}
                        iconClassname={Styles.iconContainer}
                        isLoading={false}
                      />
                    )
                  ) : null}

                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_LOCAL_LIST,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          fetchChargePointLocalList(chargePoint._id)
                          localListRef.current.handleOpen()
                        }}
                        tooltipPlacement={'left'}
                        title={t('LocalListModal.LocalAuthorizationList')}
                        Icon={IFsvg.PassKey}
                        FilledIcon={IFsvg.PassKey}
                        size={26}
                        iconClassname={Styles.iconContainer}
                        isLoading={false}
                      />
                    )
                  ) : null}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_USE_TERMINAL,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          terminalRef.current.handleOpen()
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.Terminal')}
                        Icon={IFsvg.Terminal}
                        FilledIcon={IFsvg.Terminal}
                        size={26}
                        iconClassname={Styles.iconContainer}
                        isLoading={false}
                      />
                    )
                  ) : null}
                  {(tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission
                      .CAN_EDIT_CHARGEPOINT_CHARGING_PROFILE,
                  ) ||
                    tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_EDIT_LOCAL_LIST,
                    ) ||
                    tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_USE_TERMINAL,
                    )) && (
                    <DividerDivHorizontal
                      className={Styles.DividerHorizontal}
                    />
                  )}
                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_CLEAR_CACHE,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          OCPPServices.clearCache(chargePoint._id)
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.ClearCache')}
                        Icon={IFsvg.DeleteSweep}
                        FilledIcon={IFsvg.DeleteSweep}
                        isLoading={
                          clearChargePointCacheRequestState ===
                          RequestState.LOADING
                        }
                        size={26}
                        iconClassname={Styles.iconContainer}
                        animationDisabled={false}
                      />
                    )
                  ) : null}

                  {tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission
                      .CAN_HARD_RESET_CHARGEPOINT,
                  ) ||
                  tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission
                      .CAN_SOFT_RESET_CHARGEPOINT,
                  ) ? (
                    fetchChargePointListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => resetDialog.current.show()}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.ResetButtonText')}
                        Icon={IFsvg.Restart}
                        FilledIcon={IFsvg.Restart}
                        isLoading={false}
                        size={26}
                        iconClassname={Styles.iconContainer}
                      />
                    )
                  ) : null}
                </div>
              )}
              {tenantUser.permissions.includes(
                InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGEPOINT,
              ) ? (
                (chargePoint && chargePoint.isActivated) ||
                fetchChargePointListRequestState === RequestState.LOADING ||
                fetchChargePointRequestState === RequestState.LOADING ||
                fetchStationListRequestState === RequestState.LOADING ? (
                  <div className={Styles.ResetButtonContainer}>
                    {tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_VIEW_ALERT,
                    ) ? (
                      fetchChargePointListRequestState ===
                        RequestState.LOADING ||
                      fetchChargePointRequestState === RequestState.LOADING ||
                      fetchStationListRequestState === RequestState.LOADING ? (
                        <div className={Styles.iconContainerSkeleton}>
                          <IFSkeleton
                            variant="circular"
                            width="26px"
                            height="26px"
                          />
                        </div>
                      ) : (
                        <IFTooltipIconsLoading
                          onClick={handleAlertModalOpen}
                          tooltipPlacement={'left'}
                          title={t('ChargePointDetails.AlertButtonText')}
                          Icon={IFsvg.Alert}
                          FilledIcon={IFsvg.Alert}
                          isLoading={false}
                          size={26}
                          iconClassname={Styles.iconContainer}
                        />
                      )
                    ) : null}
                    {tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_VIEW_ALERT,
                    ) && (
                      <DividerDivHorizontal
                        className={Styles.DividerHorizontal}
                      />
                    )}
                    {fetchChargePointListRequestState ===
                      RequestState.LOADING ||
                    fetchStationListRequestState === RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : !tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGEPOINT,
                      ) ? null : (
                      <IFTooltipIconsLoading
                        onClick={() => {
                          setIsEdit(true)
                        }}
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.EditButtonText')}
                        Icon={IFsvg.Edit}
                        FilledIcon={IFsvg.Edit}
                        isLoading={false}
                        size={26}
                        iconClassname={Styles.iconContainer}
                      />
                    )}
                  </div>
                ) : (
                  <div className={Styles.InactiveButtonContainer}>
                    {fetchChargePointListRequestState ===
                      RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() => confirmDeleteDialog.current.show()}
                        tooltipPlacement="left"
                        title={t('ChargePointDetails.DeleteButtonText')}
                        Icon={IFsvg.Delete}
                        FilledIcon={IFsvg.Delete}
                        size={26}
                        iconColor={Colors.red}
                        iconBackgroundColor={Colors.red}
                        iconClassname={Styles.iconContainer}
                      />
                    )}
                    {fetchChargePointListRequestState ===
                      RequestState.LOADING ||
                    fetchChargePointRequestState === RequestState.LOADING ? (
                      <div className={Styles.iconContainerSkeleton}>
                        <IFSkeleton
                          variant="circular"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    ) : (
                      <IFTooltipIconsLoading
                        onClick={() =>
                          isConnecting
                            ? () => {}
                            : handleSubmitRef.current(true)
                        }
                        tooltipPlacement={'left'}
                        title={t('ChargePointDetails.ActivateButtonText')}
                        Icon={IFsvg.Done}
                        FilledIcon={IFsvg.Done}
                        isLoading={
                          updateChargePointRequestState ===
                            RequestState.LOADING ||
                          changeChargePointAvailabilityRequestState ===
                            RequestState.LOADING ||
                          deleteChargePointSubscriptionsRequestState ===
                            RequestState.LOADING ||
                          addChargePointSubscriptionsRequestState ===
                            RequestState.LOADING ||
                          addChargePointAccessGroupsBulkRequestState ===
                            RequestState.LOADING ||
                          deleteChargePointAccessGroupsBulkRequestState ===
                            RequestState.LOADING ||
                          deleteChargePointPrivacyRulesRequestState ===
                            RequestState.LOADING ||
                          addChargePointPrivacyRulesRequestState ===
                            RequestState.LOADING
                        }
                        size={26}
                        iconClassname={Styles.iconContainer}
                        animationDisabled={false}
                        isDead={isConnecting}
                      />
                    )}
                  </div>
                )
              ) : null}
            </div>
          )}
          <IFDialog
            ref={confirmDeleteDialog}
            title={t('ChargePointDetails.DeleteDialogTitle')}
            subtitle={t('ChargePointDetails.DeleteDialogSubtitle')}
            onAction={() => deleteChargePoint(chargePoint._id)}
            actionButtonIcon={
              <Icon
                sx={{ width: '18px !important', height: '18px !important' }}
                component={DeleteOutlineIcon}
              />
            }
            actionButtonText={t('ChargePointDetails.DeleteButtonText')}
            actionButtonColor={Colors.red}
            confirmationText={chargePoint ? chargePoint.uid : ''}
            isLoading={deleteChargePointRequestState === RequestState.LOADING}
          />

          <IFDialog
            titleStyle={{ fontSize: '1.625rem', fontWeight: '600' }}
            dialogWidth="30rem"
            ref={resetDialog}
            title={t('ChargePointDetails.ResetDialogTitle')}
            subtitle={userResetPermissionsText()}
            onAction={() =>
              OCPPServices.resetChargePoint(
                chargePoint._id,
                InfinityEnums.ResetType.HARD,
                () => {
                  resetDialog.current.resetAnimation()
                },
              )
            }
            actionButtonIcon={
              <IFsvg.hardReset height={20} width={20} fill={Colors.white} />
            }
            actionButtonText={t('ChargePointDetails.HardResetButtonText')}
            actionButtonColor={Colors.primary}
            isLoading={resetChargePointRequestState === RequestState.LOADING}
            hasFirstButton={tenantUser.permissions.includes(
              InfinityEnums.TenantUserPermission.CAN_HARD_RESET_CHARGEPOINT,
            )}
            hasSecondButton={tenantUser.permissions.includes(
              InfinityEnums.TenantUserPermission.CAN_SOFT_RESET_CHARGEPOINT,
            )}
            secondActionButtonText={t('ChargePointDetails.SoftResetButtonText')}
            secondActionButtonColor={Colors.primary}
            isSecondButtonLoading={
              resetChargePointRequestState === RequestState.LOADING
            }
            secondActionButtonOnClick={() =>
              OCPPServices.resetChargePoint(
                chargePoint._id,
                InfinityEnums.ResetType.SOFT,
                () => {
                  resetDialog.current.resetAnimation()
                },
              )
            }
            secondActionButtonIcon={
              <Icon
                sx={{ width: '18px !important', height: '18px !important' }}
                component={RestartAltIcon}
              />
            }
            animateButtons={true}
          />
          <MoveStationModal ref={moveStationRef} />
          <ConfigurationModal ref={configurationRef} />
          <FirmwareModal
            ref={firmwareRef}
            currentFirmware={chargePoint ? chargePoint.firmwareVersion : ''}
          />
          <UploadDiagnosticsModal ref={uploadDiagnosticsRef} />
          <TerminalModal ref={terminalRef} />
          <AlertModal ref={alertModalRef} />
          <LocalListModal ref={localListRef} />
          <ChargingProfileModal ref={ProfileModalRef} />
        </div>
      )}
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setChargePointLogsFilter: (newFilter) =>
      dispatch(ChargePointActions.setChargePointLogsFilter(newFilter)),
    setChargePointTransactionsFilter: (newFilter) =>
      dispatch(ChargePointActions.setChargePointTransactionsFilter(newFilter)),
    clearChargePointLogs: () =>
      dispatch(ChargePointActions.clearChargePointLogs()),
    clearChargePointTransactions: () =>
      dispatch(ChargePointActions.clearChargePointTransactions()),
    updateChargePoint: (index, chargePointData, onComplete) =>
      dispatch(
        ChargePointActions.updateChargePoint(
          index,
          chargePointData,
          onComplete,
        ),
      ),
    deleteChargePoint: (chargePointId) =>
      dispatch(ChargePointActions.deleteChargePoint(chargePointId)),
    fetchStationMoveList: (filter, offset) =>
      dispatch(StationActions.fetchStationMoveList(filter, offset)),
    fetchChargePointLogList: (filter, offset, chargePointId, ascendingLogs) =>
      dispatch(
        ChargePointActions.fetchChargePointLogList(
          filter,
          offset,
          chargePointId,
          ascendingLogs,
        ),
      ),
    fetchChargePointTransactionList: (
      filter,
      offset,
      chargePointId,
      ascendingTransactions,
    ) =>
      dispatch(
        ChargePointActions.fetchChargePointTransactionList(
          filter,
          offset,
          chargePointId,
          ascendingTransactions,
        ),
      ),
    fetchChargePointConfigList: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointConfigList(chargePointId)),
    setChargePointLogPaginationOffset: (offset) =>
      dispatch(ChargePointActions.setChargePointLogPaginationOffset(offset)),
    setChargePointTransactionPaginationOffset: (offset) =>
      dispatch(
        ChargePointActions.setChargePointTransactionPaginationOffset(offset),
      ),
    editChargePoints: (selectedIndex, chargePoint) =>
      dispatch(StationActions.editChargePoints(selectedIndex, chargePoint)),
    clearMoveStations: () => dispatch(StationActions.clearMoveStations()),
    setMoveStationPaginationOffset: (offset) =>
      dispatch(StationActions.setMoveStationPaginationOffset(offset)),
    activateChargePoint: (index) =>
      dispatch(StationActions.activateChargePoint(index)),
    resetChargePointState: () =>
      dispatch(ChargePointActions.resetChargePointState()),
    clearChargePoints: () => dispatch(ChargePointActions.clearChargePoints()),
    resetFilters: () => dispatch(ChargePointActions.resetFilters()),
    fetchChargePoint: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePoint(chargePointId)),
    fetchChargePointList: (stationId) =>
      dispatch(ChargePointActions.fetchChargePointList(stationId)),
    setIsChargePointLogsLive: (flag) =>
      dispatch(ChargePointActions.setIsChargePointLogsLive(flag)),
    fetchChargePointUptimeStatistics: (
      chargePointId,
      chargePointStatisticsFilter,
    ) =>
      dispatch(
        ChargePointActions.fetchChargePointUptimeStatistics(
          chargePointId,
          chargePointStatisticsFilter,
        ),
      ),
    setChargePointStatisticsFilter: (newFilter) =>
      dispatch(ChargePointActions.setChargePointStatisticsFilter(newFilter)),
    fetchConnectorsUtilization: (chargePointId, chargePointStatisticsFilter) =>
      dispatch(
        ChargePointActions.fetchConnectorsUtilization(
          chargePointId,
          chargePointStatisticsFilter,
        ),
      ),
    fetchChargePointSubscribedAlerts: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointSubscribedAlerts(chargePointId),
      ),
    fetchChargePointAlerts: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointAlerts(chargePointId)),
    fetchChargePointLocalList: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointLocalList(chargePointId)),
    fetchChargePointCompositeSchedule: (
      chargePointId,
      connectorId,
      duration,
      chargingRateUnitType,
    ) =>
      dispatch(
        ChargePointActions.fetchChargePointCompositeSchedule(
          chargePointId,
          connectorId,
          duration,
          chargingRateUnitType,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  maxPower: ChargePointSelectors.getFastestConnector(state),
  chargePointLogFilter: ChargePointSelectors.getChargePointLogFilter(state),
  chargePointLogs: ChargePointSelectors.getChargePointLogs(state),
  chargePointTransactionFilter:
    ChargePointSelectors.getChargePointTransactionFilter(state),
  chargePointTransactions:
    ChargePointSelectors.getChargePointTransactions(state),
  fetchChargePointLogListRequestState:
    ChargePointSelectors.getFetchChargePointLogListRequestState(state),
  fetchChargePointTransactionListRequestState:
    ChargePointSelectors.getFetchChargePointTransactionListRequestState(state),
  updateChargePointRequestState:
    ChargePointSelectors.getUpdateChargePointRequestState(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  deleteChargePointRequestState:
    ChargePointSelectors.getDeleteChargePointRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  resetChargePointRequestState:
    ChargePointSelectors.getResetChargePointRequestState(state),
  moveStationsFilter: StationSelectors.getMoveStationsFilter(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  moveChargePointRequestState:
    ChargePointSelectors.getMoveChargePointRequestState(state),
  stationSelectedIndex: StationSelectors.getSelectedIndex(state),
  selectedStation: StationSelectors.getSelectedStation(state),
  stationPaginationOffset: StationSelectors.getPaginationOffset(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  tenant: AuthSelectors.getTenant(state),
  chargePointName: ChargePointSelectors.getChargePointName(state),
  chargePointConnectors: ChargePointSelectors.getChargePointConnectors(state),
  clearChargePointCacheRequestState:
    ChargePointSelectors.getClearChargePointCacheRequestState(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  changeChargePointAvailabilityRequestState:
    ChargePointSelectors.getChangeChargePointAvailabilityRequestState(state),
  deleteChargePointSubscriptionsRequestState:
    ChargePointSelectors.getDeleteChargePointSubscriptionsRequestState(state),
  addChargePointSubscriptionsRequestState:
    ChargePointSelectors.getAddChargePointSubscriptionsRequestState(state),
  addChargePointAccessGroupsBulkRequestState:
    ChargePointSelectors.getAddChargePointAccessGroupsBulkRequestState(state),
  deleteChargePointAccessGroupsBulkRequestState:
    ChargePointSelectors.getDeleteChargePointAccessGroupsBulkRequestState(
      state,
    ),
  deleteChargePointPrivacyRulesRequestState:
    ChargePointSelectors.getDeleteChargePointPrivacyRulesRequestState(state),
  addChargePointPrivacyRulesRequestState:
    ChargePointSelectors.getAddChargePointPrivacyRulesRequestState(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
  isChargePointLogsLive: ChargePointSelectors.getIsChargePointLogsLive(state),
  chargePointPrivacy: ChargePointSelectors.getChargePointPrivacy(state),
  chargePointAppVisibility:
    ChargePointSelectors.getChargePointAppVisibility(state),
  chargePointUptimeData:
    ChargePointSelectors.getChargePointUptimeStatistics(state),
  fetchChargePointUptimeStatisticsRequestState:
    ChargePointSelectors.getFetchChargePointUptimeStatisticsRequestState(state),
  chargePointStatisticsFilter:
    ChargePointSelectors.getChargePointStatisticsFilter(state),
  connectorsUtilizationData:
    ChargePointSelectors.getConnectorsUtilization(state),
  fetchConnectorsUtilizationRequestState:
    ChargePointSelectors.getFetchConnectorsUtilizationRequestState(state),
})

ChargePointDetails.propTypes = {
  chargePoint: PropTypes.object,
  maxPower: PropTypes.number,
  chargePointLogFilter: PropTypes.arrayOf(PropTypes.object),
  chargePointTransactionFilter: PropTypes.arrayOf(PropTypes.object),
  setChargePointLogsFilter: PropTypes.func,
  setChargePointTransactionsFilter: PropTypes.func,
  clearChargePointLogs: PropTypes.func,
  clearChargePointTransactions: PropTypes.func,
  updateChargePoint: PropTypes.func,
  updateChargePointRequestState: PropTypes.number,
  selectedIndex: PropTypes.number,
  deleteChargePoint: PropTypes.func,
  deleteChargePointRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  fetchChargePointRequestState: PropTypes.number,
  fetchChargePointLogList: PropTypes.func,
  fetchChargePointTransactionList: PropTypes.func,
  moveStationsFilter: PropTypes.arrayOf(PropTypes.object),
  fetchStationListRequestState: PropTypes.number,
  editChargePoints: PropTypes.func,
  setMoveStationPaginationOffset: PropTypes.func,
  tenantUser: PropTypes.object,
  tenant: PropTypes.object,
  clearChargePointCacheRequestState: PropTypes.number,
  chargePointUid: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargePointDetails)
