import React, { useState, useRef, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  NoInternet,
  IFFilter,
  FilterNoMatch,
  ListHeader,
  EmptyUsers,
  WelcomeDashboard,
} from 'Components'
import { UserList, UserDetails, InviteUserModal } from 'Containers'
import { Colors } from 'Theme'
import Styles from './UsersPage.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import UserActions from 'Stores/User/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import UserSelectors from 'Stores/User/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import LoadingBar from 'react-top-loading-bar'
import history from 'history/browser'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const UsersPage = ({
  fetchUserListRequestState,
  fetchUserDetailsRequestState,
  fetchUserTransactionsRequestState,
  fetchUserList,
  usersFilter,
  userList,
  tenantUser,
  selectedUserIndex,
  setSelectedUserIndex,
  setPaginationOffset,
  setUserTransactionPaginationOffset,
  setUsersFilter,
  clearUsers,
  usersCount,
  selectedUser,
}) => {
  const [filtering, setFiltering] = useState(false)
  const [empty, setEmpty] = useState(true)
  const [progress, setProgress] = useState(0)
  const [previousUsersFilter, setPreviousUsersFilter] = useState([])
  const inviteUserModalRef = useRef()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }
  useEffect(() => {
    return () => {
      clearUsers()
    }
  }, [])

  useEffect(() => {
    checkFilterEmpty(usersFilter)
    if (fetchUserListRequestState === RequestState.SUCCEEDED)
      if (empty) setFiltering(false)
      else setFiltering(true)
  }, [fetchUserListRequestState])

  useEffect(() => {
    let isFilterChanged = false
    for (let index = 0; index < previousUsersFilter.length; index++) {
      const previousFilter = previousUsersFilter[index]
      const userFilter = usersFilter[index]
      if (
        JSON.stringify(previousFilter.value) !==
        JSON.stringify(userFilter.value)
      ) {
        isFilterChanged = true
        break
      }
    }
    if (previousUsersFilter.length === 0) isFilterChanged = true

    if (isFilterChanged) {
      fetchUserList(usersFilter, 0, () => navigate('/NotFound'))
    }
    setPreviousUsersFilter(JSON.parse(JSON.stringify(usersFilter)))
  }, [usersFilter])

  let location = useLocation()
  useEffect(() => {
    if (location.pathname === '/users') {
      clearUsers()
      fetchUserList(usersFilter, 0, () => navigate('/NotFound'))
    }
  }, [location])

  useEffect(() => {
    if (fetchUserListRequestState === RequestState.LOADING && progress === 0)
      setProgress(progress + 10)
    if (fetchUserListRequestState === RequestState.SUCCEEDED)
      if (userList.length === 0) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    if (
      fetchUserListRequestState !== RequestState.LOADING &&
      fetchUserListRequestState !== RequestState.UNINITIALIZED &&
      fetchUserListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchUserListRequestState])

  useEffect(() => {
    if (fetchUserDetailsRequestState === RequestState.LOADING && progress === 0)
      setProgress(progress + 10)
    if (fetchUserDetailsRequestState === RequestState.SUCCEEDED) {
      if (selectedUserIndex === -1) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    }
    if (
      fetchUserDetailsRequestState !== RequestState.LOADING &&
      fetchUserDetailsRequestState !== RequestState.UNINITIALIZED &&
      fetchUserDetailsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchUserDetailsRequestState])

  useEffect(() => {
    if (userList.length !== 0 && selectedUser && !selectedUser?.appUserId) {
      setProgress(100)
    }
  }, [selectedUser])

  useEffect(() => {
    if (
      fetchUserTransactionsRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchUserTransactionsRequestState === RequestState.SUCCEEDED) {
      setProgress(100)
    }
    if (
      fetchUserTransactionsRequestState !== RequestState.LOADING &&
      fetchUserTransactionsRequestState !== RequestState.UNINITIALIZED &&
      fetchUserTransactionsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchUserTransactionsRequestState])

  const handleUsersFilter = (newFilter) => {
    let isFilterChanged = false
    for (let index = 0; index < previousUsersFilter.length; index++) {
      const previousFilter = previousUsersFilter[index]
      const userFilter = newFilter[index]
      if (
        JSON.stringify(previousFilter.value) !==
        JSON.stringify(userFilter.value)
      ) {
        isFilterChanged = true
        break
      }
    }
    if (previousUsersFilter.length === 0) isFilterChanged = true
    if (isFilterChanged) {
      clearUsers()
      setSelectedUserIndex(-1)
      setPaginationOffset(0)
      setUserTransactionPaginationOffset(0)
      history.push({ pathname: '/users' })
      setUsersFilter(newFilter)
      checkFilterEmpty(newFilter, empty)
    }
  }
  return (
    <div className={Styles.UsersContainer}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      {fetchUserListRequestState === RequestState.ERROR_0_NETWORK ? (
        <NoInternet
          onClick={() => {
            fetchUserList(usersFilter, 0, () => navigate('/NotFound'))
          }}
        />
      ) : (
        <Fragment>
          <div className={Styles.filterContainer}>
            <div
              className={Styles.filter}
              style={{
                backgroundColor: Colors.white,
                boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
              }}
            >
              <IFFilter
                key={'StationsPageIFFilter'}
                onFilterChange={(newFilter) => {
                  handleUsersFilter(newFilter)
                }}
                filters={usersFilter}
                textFieldPlaceholder={t(
                  'UsersPage.UsersPageTextFieldPlaceholder',
                )}
              />
            </div>
          </div>
          {userList.length === 0 &&
          filtering === true &&
          fetchUserListRequestState !== RequestState.LOADING ? (
            <FilterNoMatch
              title={t('emptyStates.noUsersHere')}
              subtitle={t('emptyStates.filterNoSites')}
            />
          ) : (
            <div className={Styles.ListDetailsContainer}>
              <div
                className={Styles.UsersList}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                <ListHeader
                  count={usersCount}
                  title={t('UsersPage.Users')}
                  onAddClick={() => {
                    inviteUserModalRef.current.handleOpen()
                  }}
                  isLoading={fetchUserListRequestState === RequestState.LOADING}
                  addPermission={tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_USER,
                  )}
                  isIconDisabled={true}
                />
                {userList.length === 0 &&
                fetchUserListRequestState === RequestState.SUCCEEDED ? (
                  <EmptyUsers
                    addPermission={tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_EDIT_USER,
                    )}
                  />
                ) : (
                  <UserList />
                )}
              </div>
              <div
                className={Styles.UserDetailsWrapper}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                {userList.length === 0 &&
                fetchUserListRequestState === RequestState.SUCCEEDED ? (
                  <WelcomeDashboard
                    username={tenantUser.name}
                    displayedText={t('emptyStates.newUser')}
                  />
                ) : (
                  <UserDetails />
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
      <InviteUserModal ref={inviteUserModalRef} />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedUserIndex: (index) =>
      dispatch(UserActions.setSelectedUserIndex(index)),
    setPaginationOffset: (offset) =>
      dispatch(UserActions.setPaginationOffset(offset)),
    setUserTransactionPaginationOffset: (offset) =>
      dispatch(UserActions.setUserTransactionPaginationOffset(offset)),
    setUsersFilter: (filter) => dispatch(UserActions.setUsersFilter(filter)),
    fetchUserList: (filter, offset, onNotFound) =>
      dispatch(UserActions.fetchUserList(filter, offset, onNotFound)),
    clearUsers: () => dispatch(UserActions.clearUsers()),
  }
}

const mapStateToProps = (state) => ({
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
  fetchUserTransactionsRequestState:
    UserSelectors.getFetchUserTransactionsRequestState(state),
  usersFilter: UserSelectors.getUsersFilter(state),
  userList: UserSelectors.getUserList(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  selectedUserIndex: UserSelectors.getSelectedUserIndex(state),
  usersCount: UserSelectors.getUsersCount(state),
  selectedUser: UserSelectors.getSelectedUser(state),
})

UsersPage.propTypes = {}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage)
