import PropTypes from 'prop-types'
import React from 'react'
import Colors from 'Theme/Colors'
import Styles from './ChargingTokenListItem.module.css'
import styled from 'styled-components'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'
import { ChargingTokenListItemSkeleton, IFText, IFsvg } from 'Components'

const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.ChargingTokenListItemBackgroundHover};
  }
`

const StatusContainer = styled.div`
  background-color: ${Colors.StatusContainerBackground};
  color: ${(props) => props.status};
`

const ChargingTokenListItem = ({
  reference,
  uid,
  visualNumber,
  isSelected,
  onClick,
  status,
  type,
  backgroundHover,
  isLoading,
}) => {
  const { t } = useTranslation()
  const handleStatus = (status) => {
    switch (status) {
      case InfinityEnums.ChargingTokenStatus.ACTIVE:
        return Colors.AvailableChargingToken
      case InfinityEnums.ChargingTokenStatus.BLOCKED:
        return Colors.BlockedChargingToken
      case InfinityEnums.ChargingTokenStatus.DEACTIVATED:
        return Colors.DeactivatedChargingToken
      default:
        return Colors.black
    }
  }

  return isLoading ? (
    <ChargingTokenListItemSkeleton />
  ) : (
    <Container
      backgroundColor={
        backgroundHover
          ? backgroundHover
          : isSelected
          ? Colors.ChargingTokenListItemSelected
          : Colors.white
      }
      ChargingTokenListItemBackgroundHover={
        backgroundHover
          ? backgroundHover
          : Colors.ChargingTokenListItemBackgroundHover
      }
      className={Styles.Container}
      onClick={onClick}
    >
      <div className={Styles.LeftContainer}>
        <div>
          <IFText
            className={Styles.ReferenceText}
            style={{ color: Colors.text }}
          >
            {reference}
          </IFText>
        </div>
        <div>
          <IFText
            className={Styles.UidText}
            style={{ color: Colors.ChargingTokenListItemUid }}
          >{`#${uid}`}</IFText>
        </div>
        <div>
          <IFText
            className={Styles.VisualNumberText}
            style={{ color: Colors.ChargingTokenListItemUid }}
          >
            {visualNumber}
          </IFText>
        </div>
      </div>
      <div className={Styles.RightContainer}>
        <StatusContainer
          status={handleStatus(status)}
          className={Styles.StatusContainer}
        >
          <IFText className={Styles.StatusText}>{status}</IFText>
        </StatusContainer>
        <div>
          {type === InfinityEnums.ChargingTokenType.RFID ? (
            <IFsvg.RFID height={20} width={20} fill={Colors.black} />
          ) : (
            <IFsvg.VirtualToken height={20} width={20} fill={Colors.black} />
          )}
        </div>
      </div>
    </Container>
  )
}

ChargingTokenListItem.propTypes = {
  reference: PropTypes.string,
  uid: PropTypes.string,
  visualNumber: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  status: PropTypes.string,
  type: PropTypes.string,
}

export default ChargingTokenListItem
