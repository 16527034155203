const getCards = (state) => {
  return state.main.cardState.cards
}

const getFetchCardListRequestState = (state) => {
  return state.main.cardState.fetchCardListRequestState
}

const getCardsFilter = (state) => {
  return state.main.cardState.cardsFilter
}

const getDeleteCardRequestState = (state) => {
  return state.main.cardState.deleteCardRequestState
}

const getUpdateCardRequestState = (state) => {
  return state.main.cardState.updateCardRequestState
}

const getPaginationOffset = (state) => {
  return state.main.cardState.paginationOffset
}

const getAddCardRequestState = (state) => {
  return state.main.cardState.addCardRequestState
}

const getFetchCardRequestState = (state) => {
  return state.main.cardState.fetchCardRequestState
}

const getCardTransactions = (state) => {
  return state.main.cardState.cardTransactions
}

const getFetchCardTransactionsRequestState = (state) => {
  return state.main.cardState.fetchCardTransactionsRequestState
}

const getCardTransactionsFilter = (state) => {
  return state.main.cardState.cardsTransactionsFilter
}

const getSelectedIndex = (state) => {
  return state.main.cardState.selectedIndex
}

const getSelectedCard = (state) => {
  return state.main.cardState.selectedCard
}

const getAddCardAccessGroupsRequestState = (state) => {
  return state.main.cardState.addCardAccessGroupsRequestState
}

const getDeleteCardAccessGroupsRequestState = (state) => {
  return state.main.cardState.deleteCardAccessGroupsRequestState
}

const getAddCardSubscriptionsRequestState = (state) => {
  return state.main.cardState.addCardSubscriptionsRequestState
}

const getDeleteCardSubscriptionsRequestState = (state) => {
  return state.main.cardState.deleteCardSubscriptionsRequestState
}
const getCardTransactionsPaginationOffset = (state) => {
  return state.main.cardState.cardTransactionsPaginationOffset
}

const getCardsCount = (state) => {
  return state.main.cardState.cardsCount
}
const getFetchCardListCountRequestState = (state) => {
  return state.main.cardState.fetchCardListCountRequestState
}

const getLocalListFilteredCards = (state) => {
  return state.main.cardState.localListFilteredCards
}

const getFetchLocalListFilteredCardsRequestState = (state) => {
  return state.main.cardState.fetchLocalListFilteredCardsRequestState
}
const getLocalListFilteredCardsPaginationOffset = (state) => {
  return state.main.cardState.localListFilteredCardsPaginationOffset
}

const CardSelectors = {
  getCards,
  getFetchCardListRequestState,
  getCardsFilter,
  getDeleteCardRequestState,
  getUpdateCardRequestState,
  getPaginationOffset,
  getAddCardRequestState,
  getSelectedCard,
  getFetchCardRequestState,
  getAddCardAccessGroupsRequestState,
  getDeleteCardAccessGroupsRequestState,
  getAddCardSubscriptionsRequestState,
  getDeleteCardSubscriptionsRequestState,
  getCardTransactions,
  getFetchCardTransactionsRequestState,
  getCardTransactionsFilter,
  getSelectedIndex,
  getCardTransactionsPaginationOffset,
  getCardsCount,
  getFetchCardListCountRequestState,
  getLocalListFilteredCards,
  getFetchLocalListFilteredCardsRequestState,
  getLocalListFilteredCardsPaginationOffset,
}

export default CardSelectors
