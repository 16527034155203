import { React } from 'react'
import PropTypes from 'prop-types'
import { IFSkeleton } from 'Components'
import styles from './IFButtonSkeleton.module.css'

const IFButtonSkeleton = ({ size }) => {
  return (
    <IFSkeleton
      variant="rectangular"
      className={styles.button}
      style={
        size === 'micro'
          ? {
              width: '2.5rem',
              height: '1.875rem',
            }
          : size === 'xsm'
          ? {
              width: '6.5rem',
              height: '2rem',
            }
          : size === 'sm'
          ? {
              width: '9rem',
              height: '2.5rem',
            }
          : {
              width: '12rem',
              height: '3rem',
            }
      }
    />
  )
}

IFButtonSkeleton.propTypes = {
  size: PropTypes.string.isRequired,
}

export default IFButtonSkeleton
