import { createReducer } from 'reduxsauce'
import { INITIAL_STATE } from './initialState'
import { TenantUserTypes } from './Actions'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'

export const fetchTenantUserListLoading = (state) => {
  return {
    ...state,
    fetchTenantUserListRequestState: RequestState.LOADING,
  }
}

export const fetchTenantUserListSuccess = (state, { tenantUsers }) => {
  return {
    ...state,
    tenantUsers: [...tenantUsers],
    fetchTenantUserListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchTenantUserListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchTenantUserListRequestState: requestState,
  }
}

export const updateTenantUserLoading = (state) => {
  return {
    ...state,
    updateTenantUserListRequestState: RequestState.LOADING,
  }
}

export const updateTenantUserSuccess = (
  state,
  { tenantUserId, tenantUser },
) => {
  let tenantUsers = state.tenantUsers
  const index = tenantUsers.findIndex(
    (tenantUser) => tenantUser.id === tenantUserId,
  )

  if (index !== -1) tenantUsers[index] = tenantUser
  return {
    ...state,
    tenantUsers: [...tenantUsers],
    updateTenantUserListRequestState: RequestState.SUCCEEDED,
  }
}

export const updateTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    updateTenantUserListRequestState: requestState,
  }
}

export const deleteTenantUserLoading = (state) => {
  return {
    ...state,
    deleteTenantUserRequestState: RequestState.LOADING,
  }
}

export const deleteTenantUserSuccess = (state, { tenantUserId }) => {
  let newTenantUsers = state.tenantUsers.filter(
    (tenantUser) => tenantUser.id !== tenantUserId,
  )

  return {
    ...state,
    tenantUsers: [...newTenantUsers],
    deleteTenantUserRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteTenantUserRequestState: requestState,
  }
}

export const fetchInviteTenantUserListLoading = (state) => {
  return {
    ...state,
    fetchInviteTenantUserListRequestState: RequestState.LOADING,
  }
}

export const fetchInviteTenantUserListSuccess = (
  state,
  { inviteTenantUsers },
) => {
  let result = []
  for (let j = 0; j < inviteTenantUsers.length; j++) {
    if (
      state.inviteTenantUsers.some(
        (inviteTenantUser) =>
          inviteTenantUser._id !== inviteTenantUsers[j]._id &&
          inviteTenantUsers[j].status ===
            InfinityEnums.InvitationStatus.PENDING,
      ) ||
      (state.inviteTenantUsers.length === 0 &&
        inviteTenantUsers[j].status === InfinityEnums.InvitationStatus.PENDING)
    ) {
      result.push(inviteTenantUsers[j])
    }
  }

  return {
    ...state,
    inviteTenantUsers: [...state.inviteTenantUsers, ...result],
    fetchInviteTenantUserListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchInviteTenantUserListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchInviteTenantUserListRequestState: requestState,
  }
}

export const inviteTenantUserLoading = (state) => {
  return {
    ...state,
    inviteTenantUserRequestState: RequestState.LOADING,
  }
}

export const inviteTenantUserSuccess = (state, { tenantUser }) => {
  return {
    ...state,
    inviteTenantUsers: [...state.inviteTenantUsers, tenantUser],
    inviteTenantUserRequestState: RequestState.SUCCEEDED,
  }
}

export const inviteTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    inviteTenantUserRequestState: requestState,
  }
}

export const deleteInviteTenantUserLoading = (state) => {
  return {
    ...state,
    deleteInviteTenantUserRequestState: RequestState.LOADING,
  }
}

export const deleteInviteTenantUserSuccess = (
  state,
  { inviteTenantUserId },
) => {
  let newInviteTenantUsers = state.inviteTenantUsers.filter(
    (inviteTenantUser) => inviteTenantUser._id !== inviteTenantUserId,
  )
  return {
    ...state,
    inviteTenantUsers: [...newInviteTenantUsers],
    deleteInviteTenantUserRequestState: RequestState.SUCCEEDED,
  }
}

export const deleteInviteTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    deleteInviteTenantUserRequestState: requestState,
  }
}

export const sendTenantUserEmailLoading = (state) => {
  return {
    ...state,
    sendTenantUserEmailRequestState: RequestState.LOADING,
  }
}

export const sendTenantUserEmailSuccess = (state) => {
  return {
    ...state,
    sendTenantUserEmailRequestState: RequestState.SUCCEEDED,
  }
}

export const sendTenantUserEmailFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    sendTenantUserEmailRequestState: requestState,
  }
}

export const fetchInviteTenantUserLoading = (state) => {
  return {
    ...state,
    fetchInviteTenantUserRequestState: RequestState.LOADING,
  }
}

export const fetchInviteTenantUserSuccess = (state, { inviteTenantUser }) => {
  return {
    ...state,
    inviteTenantUser: { ...inviteTenantUser },
    fetchInviteTenantUserRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchInviteTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchInviteTenantUserRequestState: requestState,
  }
}

export const setInviteTenantUserStatus = (state, { status }) => {
  return {
    ...state,
    inviteTenantUserStatus: status,
  }
}

export const createTenantUserLoading = (state) => {
  return {
    ...state,
    createTenantUserRequestState: RequestState.LOADING,
  }
}

export const createTenantUserSuccess = (state) => {
  return {
    ...state,
    createTenantUserRequestState: RequestState.SUCCEEDED,
  }
}

export const createTenantUserFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    createTenantUserRequestState: requestState,
  }
}

export const fetchSubscriptionsListLoading = (state) => {
  return {
    ...state,
    fetchSubscriptionListRequestState: RequestState.LOADING,
  }
}

export const fetchSubscriptionsListSuccess = (state, { subscriptions }) => {
  return {
    ...state,
    subscriptionsList: [...subscriptions],
    fetchSubscriptionListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchSubscriptionsListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchSubscriptionListRequestState: requestState,
  }
}

export const fetchPrivacyScheduleLoading = (state) => {
  return {
    ...state,
    fetchPrivacyScheduleRequestState: RequestState.LOADING,
  }
}

export const fetchPrivacyScheduleSuccess = (state, { PrivacySchedule }) => {
  return {
    ...state,
    privacySchedule: [...PrivacySchedule],
    fetchPrivacyScheduleRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchPrivacyScheduleFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchPrivacyScheduleRequestState: requestState,
  }
}

export const fetchAccessGroupsListLoading = (state) => {
  return {
    ...state,
    fetchAccessGroupsListRequestState: RequestState.LOADING,
  }
}

export const fetchAccessGroupsListSuccess = (state, { accessGroups }) => {
  return {
    ...state,
    accessGroupsList: [...accessGroups],
    fetchAccessGroupsListRequestState: RequestState.SUCCEEDED,
  }
}

export const fetchAccessGroupsListFail = (state, { error }) => {
  let requestState = RequestState.ERROR_UNKNOWN_OCCURRED
  if (error && error.message === 'Network Error') {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 503) {
    requestState = RequestState.ERROR_0_NETWORK
  } else if (error && error.status === 409) {
    requestState = RequestState.ERROR_409_OCCURRED
  } else if (error && error.status === 400) {
    requestState = RequestState.ERROR_400_OCCURRED
  }
  return {
    ...state,
    fetchAccessGroupsListRequestState: requestState,
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [TenantUserTypes.FETCH_TENANT_USER_LIST_LOADING]: fetchTenantUserListLoading,
  [TenantUserTypes.FETCH_TENANT_USER_LIST_SUCCESS]: fetchTenantUserListSuccess,
  [TenantUserTypes.FETCH_TENANT_USER_LIST_FAIL]: fetchTenantUserListFail,
  [TenantUserTypes.UPDATE_TENANT_USER_LOADING]: updateTenantUserLoading,
  [TenantUserTypes.UPDATE_TENANT_USER_SUCCESS]: updateTenantUserSuccess,
  [TenantUserTypes.UPDATE_TENANT_USER_FAIL]: updateTenantUserFail,
  [TenantUserTypes.INVITE_TENANT_USER_LOADING]: inviteTenantUserLoading,
  [TenantUserTypes.INVITE_TENANT_USER_SUCCESS]: inviteTenantUserSuccess,
  [TenantUserTypes.INVITE_TENANT_USER_FAIL]: inviteTenantUserFail,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_LIST_LOADING]:
    fetchInviteTenantUserListLoading,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_LIST_SUCCESS]:
    fetchInviteTenantUserListSuccess,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_LIST_FAIL]:
    fetchInviteTenantUserListFail,
  [TenantUserTypes.DELETE_TENANT_USER_LOADING]: deleteTenantUserLoading,
  [TenantUserTypes.DELETE_TENANT_USER_SUCCESS]: deleteTenantUserSuccess,
  [TenantUserTypes.DELETE_TENANT_USER_FAIL]: deleteTenantUserFail,
  [TenantUserTypes.DELETE_INVITE_TENANT_USER_LOADING]:
    deleteInviteTenantUserLoading,
  [TenantUserTypes.DELETE_INVITE_TENANT_USER_SUCCESS]:
    deleteInviteTenantUserSuccess,
  [TenantUserTypes.DELETE_INVITE_TENANT_USER_FAIL]: deleteInviteTenantUserFail,
  [TenantUserTypes.SEND_TENANT_USER_EMAIL_LOADING]: sendTenantUserEmailLoading,
  [TenantUserTypes.SEND_TENANT_USER_EMAIL_SUCCESS]: sendTenantUserEmailSuccess,
  [TenantUserTypes.SEND_TENANT_USER_EMAIL_FAIL]: sendTenantUserEmailFail,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_LOADING]:
    fetchInviteTenantUserLoading,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_SUCCESS]:
    fetchInviteTenantUserSuccess,
  [TenantUserTypes.FETCH_INVITE_TENANT_USER_FAIL]: fetchInviteTenantUserFail,
  [TenantUserTypes.CREATE_TENANT_USER_LOADING]: createTenantUserLoading,
  [TenantUserTypes.CREATE_TENANT_USER_SUCCESS]: createTenantUserSuccess,
  [TenantUserTypes.CREATE_TENANT_USER_FAIL]: createTenantUserFail,
  [TenantUserTypes.SET_INVITE_TENANT_USER_STATUS]: setInviteTenantUserStatus,
  [TenantUserTypes.FETCH_SUBSCRIPTIONS_LIST_LOADING]:
    fetchSubscriptionsListLoading,
  [TenantUserTypes.FETCH_SUBSCRIPTIONS_LIST_SUCCESS]:
    fetchSubscriptionsListSuccess,
  [TenantUserTypes.FETCH_SUBSCRIPTIONS_LIST_FAIL]: fetchSubscriptionsListFail,
  [TenantUserTypes.FETCH_PRIVACY_SCHEDULE_LOADING]: fetchPrivacyScheduleLoading,
  [TenantUserTypes.FETCH_PRIVACY_SCHEDULE_SUCCESS]: fetchPrivacyScheduleSuccess,
  [TenantUserTypes.FETCH_PRIVACY_SCHEDULE_FAIL]: fetchPrivacyScheduleFail,
  [TenantUserTypes.FETCH_ACCESS_GROUPS_LIST_LOADING]:
    fetchAccessGroupsListLoading,
  [TenantUserTypes.FETCH_ACCESS_GROUPS_LIST_SUCCESS]:
    fetchAccessGroupsListSuccess,
  [TenantUserTypes.FETCH_ACCESS_GROUPS_LIST_FAIL]: fetchAccessGroupsListFail,
})
