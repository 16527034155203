import IFFilterType from 'Enums/IFFilterType'
import InfinityEnums from 'Enums/InfinityEnums'
import subDays from 'date-fns/subDays'
import i18n from 'Translations'

export const cardsFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Type',
    data: ['Rfid', 'Virtual'],
    initialValue: [],
    field: 'type',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Card Status',
    data: Object.values(InfinityEnums.ChargingTokenStatus),
    initialValue: [],
    field: 'status',
    value: [],
  },
]

export const chargePointLogFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Message Origin',
    data: ['Station', 'System'],
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.DATE_RANGE,
    title: 'Date',
    data: null,
    initialValue: [],
    value: [],
  },
]

export const chargePointTransactionFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Connector',
    data: [1, 2, 3],
    initialValue: [],
    field: 'connector',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Consumption',
    data: null,
    initialValue: [],
    unit: 'kWh',
    field: 'maxPower',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'Duration',
    data: null,
    initialValue: [],
    unit: 'm',
    field: 'duration',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Cost',
    data: null,
    initialValue: [],
    unit: 'EGP',
    field: 'cost',
    value: [],
  },
  {
    type: IFFilterType.DATE_RANGE,
    title: 'Date',
    data: null,
    initialValue: [],
    value: [],
  },
]

export const moveStationsFilter = [
  {
    type: IFFilterType.SEARCH_TERM,
    title: null,
    data: null,
    initialValue: [],
    value: '',
  },
]

export const stationsMapFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'City',
    data: [],
    initialValue: [],
    field: 'city',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Connector Status',
    data: [
      'Available',
      'Preparing',
      'Charging',
      'SuspendedEV',
      'SuspendedEVSE',
      'Finishing',
      'Reserved',
      'Faulted',
      'Unavailable',
    ],
    initialValue: [],
    field: 'connectorStatus',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Power',
    data: ['level 1', 'level 2', 'level 3'],
    initialValue: [],
    field: 'chargePointType',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'No. Connectors',
    data: [],
    initialValue: [],
    field: 'connector',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'No. Stations',
    data: [],
    initialValue: [],
    field: 'chargePoints',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Connection',
    data: ['Online', 'Offline'],
    initialValue: [],
    field: 'chargePointStatus',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Activation',
    data: ['Active', 'Inactive'],
    initialValue: [],
    field: 'chargePointActivation',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'App Visibility',
    data: ['Visible', 'Not visible'],
    initialValue: [],
    field: 'appVisibility',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Privacy',
    data: ['Public', 'Private'],
    initialValue: [],
    field: 'chargePointPrivacy',
    value: [],
  },
]

export const stationsFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'City',
    data: [],
    initialValue: [],
    field: 'city',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Connector Status',
    data: [
      'Available',
      'Preparing',
      'Charging',
      'SuspendedEV',
      'SuspendedEVSE',
      'Finishing',
      'Reserved',
      'Faulted',
      'Unavailable',
    ],
    initialValue: [],
    field: 'connectorStatus',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Power',
    data: ['level 1', 'level 2', 'level 3'],
    initialValue: [],
    field: 'chargePointType',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'No. Connectors',
    data: [],
    initialValue: [],
    field: 'connector',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'No. Stations',
    data: [],
    initialValue: [],
    field: 'chargePoints',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Connection',
    data: ['Online', 'Offline'],
    initialValue: [],
    field: 'chargePointStatus',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Activation',
    data: ['Active', 'Inactive'],
    initialValue: [],
    field: 'chargePointActivation',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'App Visibility',
    data: ['Visible', 'Not visible'],
    initialValue: [],
    field: 'appVisibility',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Privacy',
    data: ['Public', 'Private'],
    initialValue: [],
    field: 'chargePointPrivacy',
    value: [],
  },
]

export const userTransactionsFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Energy Consumption',
    data: null,
    initialValue: [],
    unit: 'kWh',
    field: 'maxPower',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'Duration',
    data: null,
    initialValue: [],
    unit: 'm',
    field: 'duration',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Cost',
    data: null,
    initialValue: [],
    unit: 'EGP',
    field: 'cost',
    value: [],
  },
  {
    type: IFFilterType.DATE_RANGE,
    title: 'Date',
    data: null,
    initialValue: [],
    value: [],
  },
]

export const usersFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Type',
    data: ['Invited', 'Visited'],
    initialValue: [],
    field: 'type',
    value: [],
  },
  {
    type: IFFilterType.CHECK,
    title: 'Status',
    data: ['Active', 'Blocked'],
    initialValue: [],
    field: 'status',
    value: [],
  },
]

export const cardsTransactionFilter = [
  {
    type: IFFilterType.KEYWORD,
    title: null,
    data: null,
    initialValue: [],
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Energy Consumption',
    data: null,
    initialValue: [],
    unit: 'kWh',
    field: 'maxPower',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE,
    title: 'Duration',
    data: null,
    initialValue: [],
    unit: 'm',
    field: 'duration',
    value: [],
  },
  {
    type: IFFilterType.VALUE_RANGE_DECIMAL,
    title: 'Cost',
    data: null,
    initialValue: [],
    unit: 'EGP',
    field: 'cost',
    value: [],
  },
  {
    type: IFFilterType.DATE_RANGE,
    title: 'Date',
    data: null,
    initialValue: [],
    value: [],
  },
]

const cpStatisticsDateBottomRanges = [
  {
    label: i18n.t('StationsMap.Last7Days'),
    value: [subDays(new Date(), 6), new Date()],
  },
  {
    label: i18n.t('StationsMap.Last30Days'),
    value: [subDays(new Date(), 29), new Date()],
  },
  {
    label: i18n.t('StationsMap.AllTime'),
    value: [new Date(new Date().setFullYear(2021, 0, 1)), new Date()],
  },
]

export const chargePointStatisticsFilter = [
  {
    type: IFFilterType.DATE_RANGE,
    title: 'Date',
    data: null,
    initialValue: [subDays(new Date(), 14), new Date()],
    field: 'date',
    value: [subDays(new Date(), 14), new Date()],
    ranges: cpStatisticsDateBottomRanges,
  },
]
