import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import Styles from './ChargePointTransactionList.module.css'
import {
  ChargePointTransactionListItem,
  ChargePointTransactionListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import { EmptyLogs } from 'Components'
import { NoLogsFilter } from 'Components'
import { Virtuoso } from 'react-virtuoso'

const ChargePointTransactionList = ({
  fetchChargePointTransactionList,
  chargePointTransactions,
  fetchChargePointTransactionListRequestState,
  fetchStationListRequestState,
  fetchChargePointListRequestState,
  paginationOffset,
  chargePointTransactionFilter,
  selectedIndex,
  chargePoints,
  ascending,
  updateChargePointRequestState,
}) => {
  const [empty, setEmpty] = useState(true)

  const Footer = () => {
    return paginationOffset &&
      chargePointTransactions &&
      chargePointTransactions.length !== 0 ? (
      <div className={Styles.WaypointContainer}>
        <ChargePointTransactionListItemSkeleton />
        <ChargePointTransactionListItemSkeleton />
      </div>
    ) : null
  }
  const loadMoreData = () => {
    if (
      fetchChargePointTransactionListRequestState === RequestState.LOADING ||
      paginationOffset === null
    )
      return

    fetchChargePointTransactionList(
      chargePointTransactionFilter,
      paginationOffset,
      chargePoints[selectedIndex]._id,
      ascending,
    )
  }
  const handleChargingIcon = (uid) => {
    const connectors = chargePoints[selectedIndex].connectors.filter(
      (connector) => connector.uid === uid,
    )
    const type = connectors[0]?.type
    return type
  }

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }
  useEffect(() => {
    checkFilterEmpty(chargePointTransactionFilter)
  }, [chargePointTransactionFilter])

  return (
    <div
      className={Styles.container}
      {...((empty && chargePointTransactions.length === 0) ||
      (!empty && chargePointTransactions.length === 0)
        ? { style: { width: '100%', height: '100%' } }
        : {})}
    >
      {(fetchChargePointTransactionListRequestState === RequestState.LOADING &&
        paginationOffset === 0) ||
      fetchStationListRequestState === RequestState.LOADING ||
      fetchChargePointListRequestState === RequestState.LOADING ||
      updateChargePointRequestState === RequestState.LOADING ? (
        [...Array(15)].map((e, index) => (
          <ChargePointTransactionListItemSkeleton key={index} />
        ))
      ) : chargePointTransactions.length === 0 ? (
        empty ? (
          <EmptyLogs />
        ) : (
          <NoLogsFilter />
        )
      ) : (
        <Virtuoso
          data={chargePointTransactions}
          endReached={
            paginationOffset &&
            chargePointTransactions &&
            chargePointTransactions.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, chargePointTransaction) => {
            return (
              <div key={index}>
                <ChargePointTransactionListItem
                  connectorUid={chargePointTransaction.connectorUid}
                  connectorType={handleChargingIcon(
                    chargePointTransaction.connectorUid,
                  )}
                  startedAt={chargePointTransaction.startedAt}
                  endedAt={chargePointTransaction.endedAt}
                  energyConsumption={chargePointTransaction.energyConsumption}
                  processedAt={chargePointTransaction.processedAt}
                  amount={chargePointTransaction.amount}
                  startingChargingToken={
                    chargePointTransaction.startingChargingTokenDetails[0]?.uid
                  }
                  startingChargingTokenType={
                    chargePointTransaction.startingChargingTokenDetails[0]?.type
                  }
                  user={chargePointTransaction?.user[0]}
                  status={chargePointTransaction.status}
                />
              </div>
            )
          }}
          components={{ Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointTransactionList: (
      filter,
      offset,
      chargePointId,
      ascendingTransactions,
    ) =>
      dispatch(
        ChargePointActions.fetchChargePointTransactionList(
          filter,
          offset,
          chargePointId,
          ascendingTransactions,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  chargePointTransactions:
    ChargePointSelectors.getChargePointTransactions(state),
  fetchChargePointTransactionListRequestState:
    ChargePointSelectors.getFetchChargePointTransactionListRequestState(state),
  chargePointTransactionFilter:
    ChargePointSelectors.getChargePointTransactionFilter(state),
  paginationOffset:
    ChargePointSelectors.getChargePointTransactionPaginationOffset(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  updateChargePointRequestState:
    ChargePointSelectors.getUpdateChargePointRequestState(state),
})

ChargePointTransactionList.propTypes = {
  fetchChargePointTransactionList: PropTypes.func,
  chargePointTransactions: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointTransactionListRequestState: PropTypes.number,
  paginationOffset: PropTypes.number,
  chargePointTransactionFilter: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  chargePoints: PropTypes.arrayOf(PropTypes.object),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargePointTransactionList)
