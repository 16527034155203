import * as React from 'react'
import Styles from './TransactionListItemSkeleton.module.css'
import IFSkeleton from 'Components/IFSkeleton/IFSkeleton'

function TransactionListItemSkeleton({ ...props }) {
  return (
    <div {...props}>
      <div className={Styles.container}>
        <div className={Styles.accordionStationWrapper}>
          <IFSkeleton variant="circular" className={Styles.stationIcon} />
          <div className={Styles.stationText}>
            <IFSkeleton className={Styles.stationName} />
            <IFSkeleton className={Styles.chargePointName} />
          </div>
        </div>

        <div className={Styles.accordionTextPower}>
          <IFSkeleton variant="circular" className={Styles.icon} />
          <IFSkeleton className={Styles.accordionTextEnergyConsumption} />
        </div>

        <div className={Styles.accordionDuration}>
          <IFSkeleton variant="circular" className={Styles.icon} />
          <IFSkeleton className={Styles.accordionDurationText} />
        </div>

        <div className={Styles.amountContainer}>
          <IFSkeleton variant="circular" className={Styles.icon} />
          <IFSkeleton className={Styles.amount} />
        </div>

        <div className={Styles.accordionDateContainer}>
          <IFSkeleton className={Styles.time} />
          <IFSkeleton className={Styles.accordionDate} />
        </div>
      </div>
    </div>
  )
}

export default TransactionListItemSkeleton
