import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  tenantUserLogin: ['email', 'password'],
  tenantUserLoginLoading: null,
  tenantUserLoginSuccess: ['token', 'refreshToken', 'tenantUser'],
  tenantUserLoginFail: null,
  tenantUserLoginResetLoading: null,

  resetPassVerification: ['email', 'onSuccess'],
  resetPassVerificationLoading: null,
  resetPassVerificationSuccess: ['emailID'],
  resetPassVerificationFail: ['error'],

  resetPass: [
    'newPassword',
    'emailVerificationCode',
    'emailVerificationId',
    'onSuccess',
  ],
  resetPassLoading: null,
  resetPassSuccess: null,
  resetPassFail: ['error'],

  fetchTenantUser: null,
  fetchTenantUserLoading: null,
  fetchTenantUserSuccess: ['tenantUser'],
  fetchTenantUserFail: ['error'],

  setAuthToken: ['token'],
  tenantUserLogout: null,
})

export const AuthTypes = Types
export default Creators
