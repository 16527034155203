import React, { useImperativeHandle, useEffect, useState } from 'react'
import {
  AccessGroupListItem,
  ChargingSpecsAdd,
  AccessGroupListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import Styles from './AccessGroupList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import { Virtuoso } from 'react-virtuoso'

const AccessGroupList = React.forwardRef(
  (
    {
      displayedList,
      dropdownList,
      isEditing,
      isLoading,
      maxListHeight,
      scrollbarsHeight,
      onSubmit,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [deleteAccessGroups, setDeleteAccessGroups] = useState([])
    const [filteredAccessGroups, setFilteredAccessGroups] = useState([])
    const [addAccessGroups, setAddAccessGroups] = useState([])
    const [newAccessGroups, setNewAccessGroups] = useState([])
    const [dynamicHeight, setDynamicHeight] = useState(0)
    const [combinedAccessGroups, setCombinedAccessGroups] =
      useState(displayedList)

    // Work around for displayedList firing useEffect without it's value changing
    const [newDisplayedList, setNewDisplayedList] = useState(displayedList)
    useEffect(() => {
      if (JSON.stringify(displayedList) !== JSON.stringify(newDisplayedList)) {
        setNewDisplayedList(displayedList)
      }
    }, [displayedList])

    useEffect(() => {
      const formattedAccessGroups = displayedList?.map((group) => {
        return {
          ...group,
          origin: 'original',
        }
      })
      setCombinedAccessGroups(formattedAccessGroups)
    }, [displayedList])

    useEffect(() => {
      setDeleteAccessGroups([])
      setAddAccessGroups([])
      setNewAccessGroups([])
    }, [isEditing])

    const fillFilteredAccessGroups = () => {
      const difference = dropdownList.filter((group) => {
        const found = newDisplayedList?.some((chargePointGroup) => {
          return chargePointGroup._id === group._id
        })
        return !found
      })
      setFilteredAccessGroups(difference)
    }

    useEffect(() => {
      fillFilteredAccessGroups()
    }, [dropdownList, newDisplayedList, isEditing])

    const resetValues = () => {
      setDeleteAccessGroups([])
      setNewAccessGroups([])
      setAddAccessGroups([])
      setCombinedAccessGroups((prevState) => {
        return prevState?.filter((group) => group.origin === 'original')
      })
    }

    const handleCancel = () => {
      fillFilteredAccessGroups()
      resetValues()
    }

    const handleSubmit = (resetValuesFlag) => {
      onSubmit(addAccessGroups, deleteAccessGroups)
      if (resetValuesFlag) resetValues()
    }

    const didChange = () => {
      return deleteAccessGroups.length > 0 || addAccessGroups.length > 0
    }

    const handleAdd = (_id) => {
      let index = addAccessGroups.indexOf(_id)
      if (index === -1) {
        setAddAccessGroups((prevState) => {
          return [...prevState, _id]
        })
      }
      index = dropdownList.findIndex((group) => group._id === _id)
      if (index > -1) {
        const alreadyExists = newAccessGroups.some((group) => {
          return group._id === _id
        })
        if (!alreadyExists) {
          const formattedNewAccessGroup = {
            ...dropdownList[index],
            origin: 'new',
          }
          setCombinedAccessGroups((prevState) => {
            return [...prevState, formattedNewAccessGroup]
          })
          setNewAccessGroups((prevState) => {
            return [...prevState, dropdownList[index]]
          })
        }
      }

      let newFilteredAccessGroups = filteredAccessGroups.filter((group) => {
        return group._id !== _id
      })
      setFilteredAccessGroups(newFilteredAccessGroups)
    }

    const handleAccessGroupDelete = (accessGroup) => {
      const index = deleteAccessGroups.findIndex(
        (group) => group.id === accessGroup.id,
      )
      if (index === -1) {
        setDeleteAccessGroups((prevState) => {
          return [...prevState, accessGroup]
        })
      } else {
        setDeleteAccessGroups(
          deleteAccessGroups.filter((group) => group.id !== accessGroup.id),
        )
      }
    }

    const handleAccessGroupAdd = (accessGroupId) => {
      const index = addAccessGroups.findIndex(
        (groupId) => groupId === accessGroupId,
      )
      if (index === -1) {
        setAddAccessGroups((current) => [...current, accessGroupId])
      } else {
        setAddAccessGroups(addAccessGroups.filter((id) => id !== accessGroupId))
        fillFilteredAccessGroups()
      }
    }

    useImperativeHandle(ref, () => ({
      handleCancel,
      handleSubmit,
      didChange,
    }))

    return (
      <div
        className={
          isEditing
            ? Styles.AccessGroupListWrapperEditing
            : Styles.AccessGroupListWrapper
        }
        style={
          isEditing
            ? isLoading
              ? {}
              : {
                  height: combinedAccessGroups.length > 0 ? dynamicHeight : '',
                  maxHeight: maxListHeight,
                }
            : {}
        }
      >
        {displayedList?.length === 0 &&
        newAccessGroups.length === 0 &&
        !isLoading ? (
          <div
            className={Styles.EmptyContainer}
            style={isEditing ? { height: '4.25rem', marginTop: '0.5rem' } : {}}
          >
            <EmptySpecs title={t('ChargepointSpecs.NoAccessGroups')} />
          </div>
        ) : isLoading ? (
          <div style={{ height: scrollbarsHeight, paddingRight: '1rem' }}>
            {[...Array(6)].map((e, index) => {
              return <AccessGroupListItemSkeleton />
            })}
          </div>
        ) : (
          <div
            className={
              isEditing ? Styles.ListWrapperEditing : Styles.ListWrapper
            }
          >
            <Virtuoso
              data={combinedAccessGroups}
              increaseViewportBy={480}
              totalListHeightChanged={(height) => {
                setDynamicHeight(height + combinedAccessGroups.length * 8 + 40)
              }}
              itemContent={(index, accessGroup) => {
                if (accessGroup && accessGroup.origin === 'original') {
                  return (
                    <div className={Styles.ListItem}>
                      <AccessGroupListItem
                        name={accessGroup.name}
                        userCount={accessGroup.userCount}
                        isEditing={isEditing}
                        onIconClick={() => {
                          const newAccessGroup = {
                            id: accessGroup._id,
                          }
                          handleAccessGroupDelete(newAccessGroup)
                        }}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div
                      className={Styles.ListItem}
                      style={{
                        marginBottom:
                          index === newAccessGroups.length - 1
                            ? '0rem'
                            : '0.5rem',
                      }}
                    >
                      <AccessGroupListItem
                        name={accessGroup.name}
                        userCount={accessGroup.userCount}
                        isEditing={isEditing}
                        onIconClick={() => {
                          handleAccessGroupAdd(accessGroup._id)
                        }}
                      />
                    </div>
                  )
                }
              }}
              components={{
                Scroller: CustomScrollbar,
              }}
            />
          </div>
        )}
        {isEditing && !isLoading && (
          <div className={Styles.AddContainer}>
            <ChargingSpecsAdd
              key={1}
              data={[...filteredAccessGroups]}
              onSelect={(value) => {
                handleAdd(value)
              }}
              RenderMenuItem={AccessGroupListItem}
              RenderMenuItemkeys={['name', 'userCount']}
            />
          </div>
        )}
      </div>
    )
  },
)

AccessGroupList.propTypes = {
  displayedList: PropTypes.arrayOf(PropTypes.object),
  dropdownList: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxListHeigh: PropTypes.number,
  scrollbarsHeight: PropTypes.string,
  onSubmit: PropTypes.func,
}

export default AccessGroupList
