import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  ChargingTokenListItem,
  ChargingTokenListItemSkeleton,
  CustomScrollbar,
} from 'Components'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import { Divider } from '@material-ui/core'
import { Waypoint } from 'react-waypoint'
import CardSelectors from 'Stores/Card/Selectors'
import CardActions from 'Stores/Card/Actions'
import history from 'history/browser'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import Styles from './ChargingTokenList.module.css'

TimeAgo.addDefaultLocale(en)

const ChargingTokenList = ({
  chargingTokensList = [],
  fetchChargingTokensListRequestState,
  fetchChargingTokensList,
  chargingTokensFilter,
  selectedIndex,
  setChargingTokensSelectedIndex,
  paginationOffset,
  clearChargingTokens,
  selectedCard,
}) => {
  let navigate = useNavigate()

  const Footer = () => {
    return paginationOffset &&
      chargingTokensList &&
      chargingTokensList.length !== 0 ? (
      <div>
        <Waypoint onEnter={loadMoreData} />
        <ChargingTokenListItemSkeleton />
        <Divider />
        <ChargingTokenListItemSkeleton />
      </div>
    ) : null
  }

  useEffect(() => {
    fetchChargingTokensList(chargingTokensFilter, 0, () =>
      navigate('/NotFound'),
    )
    return () => {
      clearChargingTokens()
    }
  }, [])

  useEffect(() => {
    if (
      selectedCard &&
      chargingTokensList &&
      chargingTokensList[selectedIndex]
    ) {
      history.push({
        pathname: `/cards/${chargingTokensList[selectedIndex]._id}`,
      })
    }
  }, [selectedCard?._id])

  const handleChargingTokenListItemClick = (index) => {
    setChargingTokensSelectedIndex(index)
  }

  const loadMoreData = () => {
    if (fetchChargingTokensListRequestState === RequestState.LOADING_MORE)
      return
    fetchChargingTokensList(chargingTokensFilter, paginationOffset)
  }

  const chargingTokensContainerRef = useRef()
  const getChargingTokensSkeletonsCount = () => {
    if (chargingTokensContainerRef.current) {
      let containerHeight =
        chargingTokensContainerRef.current.parentElement.clientHeight / 16
      return Math.floor(containerHeight / 5)
    }
    return 12
  }

  return (
    <div className={Styles.Container} ref={chargingTokensContainerRef}>
      {fetchChargingTokensListRequestState === RequestState.LOADING &&
      paginationOffset === 0 ? (
        [...Array(getChargingTokensSkeletonsCount())].map((e, index) => (
          <div key={`ChargingTokenListItemSkeleton ${index}`}>
            <ChargingTokenListItemSkeleton key={index} />
            <Divider />
          </div>
        ))
      ) : (
        <Virtuoso
          data={chargingTokensList}
          endReached={
            paginationOffset &&
            chargingTokensList &&
            chargingTokensList.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, token) => {
            return (
              <div
                key={`ChargingTokenListItem ${index}`}
                className={Styles.ItemContainer}
              >
                <ChargingTokenListItem
                  reference={token.reference}
                  uid={token.uid}
                  visualNumber={token.visualNumber}
                  isSelected={selectedIndex === index}
                  onClick={() => handleChargingTokenListItemClick(index)}
                  isNew={false}
                  status={token.status}
                  type={token.type}
                />
                <Divider />
              </div>
            )
          }}
          components={{ Footer: Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargingTokensList: (offset, filter, onNotFound) =>
      dispatch(CardActions.fetchCardList(offset, filter, onNotFound)),
    clearChargingTokens: () => dispatch(CardActions.clearCards()),

    setChargingTokensSelectedIndex: (index) =>
      dispatch(CardActions.setCardSelectedIndex(index)),
  }
}

const mapStateToProps = (state) => ({
  chargingTokensList: CardSelectors.getCards(state),
  fetchChargingTokensListRequestState:
    CardSelectors.getFetchCardListRequestState(state),
  chargingTokensFilter: CardSelectors.getCardsFilter(state),
  paginationOffset: CardSelectors.getPaginationOffset(state),
  selectedIndex: CardSelectors.getSelectedIndex(state),
  selectedCard: CardSelectors.getSelectedCard(state),
})

ChargingTokenList.propTypes = {
  chargingTokensList: PropTypes.arrayOf(PropTypes.object),
  fetchChargingTokensListRequestState: PropTypes.number,
  fetchChargingTokensList: PropTypes.func,
  chargingTokensFilter: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  paginationOffset: PropTypes.number,
  setChargingTokensSelectedIndex: PropTypes.func,
  clearChargingTokens: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingTokenList)
