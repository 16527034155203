import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import Styles from './ConfigurationList.module.css'
import RequestState from 'Enums/RequestState'
import {
  ConfigurationListItem,
  ConfigurationListItemSkeleton,
  IFToastMessage,
  CustomScrollbar,
} from 'Components'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'
import { toaster } from 'rsuite'
import { Virtuoso } from 'react-virtuoso'

const formatChargePointConfigList = (chargePointConfigList, filter) => {
  return Object.keys(chargePointConfigList).filter((key) => {
    const lower = filter ? filter.toLowerCase() : ''
    return key.toLowerCase().includes(lower)
  })
}

const ConfigurationList = ({
  chargePointConfigList,
  chargePointConfigListRequestState,
  updateChargePointConfig,
  chargePoints,
  selectedIndex,
  filter,
  tenantUser,
}) => {
  const { t } = useTranslation()
  const [loadingConfigs, setLoadingConfigs] = useState(
    Array(Object.keys(chargePointConfigList).length).fill(false),
  )
  const [editingConfigs, setEditingConfigs] = useState(
    Array(Object.keys(chargePointConfigList).length).fill(false),
  )
  const [formattedChargePointConfigList, setFormattedChargePointConfigList] =
    useState(formatChargePointConfigList(chargePointConfigList, filter))
  const editingList = (index, flag) => {
    setEditingConfigs((prevState) => {
      prevState[index] = flag
      return [...prevState]
    })
  }
  const onSuccess = (index, status) => {
    setLoadingConfigs((prevState) => {
      prevState[index] = false
      return [...prevState]
    })
    setEditingConfigs((prevState) => {
      prevState[index] = false
      return [...prevState]
    })

    toaster.push(
      <IFToastMessage
        type="success"
        text={t('ConfigurationList.EditSuccess')}
      />,
      { placement: 'topEnd' },
    )
    if (status === InfinityEnums.ConfigurationStatus.REBOOT_REQUIRED) {
      toaster.push(
        <IFToastMessage
          type="info"
          text={t('ConfigurationList.RebootRequired')}
        />,
        { placement: 'topEnd' },
      )
    }
  }

  const onFail = (index) => {
    setLoadingConfigs((prevState) => {
      prevState[index] = false
      return [...prevState]
    })
    setEditingConfigs((prevState) => {
      prevState[index] = true
      return [...prevState]
    })
    toaster.push(
      <IFToastMessage type="error" text={t('ConfigurationList.EditFail')} />,
      { placement: 'topEnd' },
    )
  }
  useEffect(() => {
    setEditingConfigs(
      Array(Object.keys(chargePointConfigList).length).fill(false),
    )
    setLoadingConfigs(
      Array(Object.keys(chargePointConfigList).length).fill(false),
    )
  }, [chargePointConfigListRequestState])
  useEffect(() => {
    const results = Object.keys(chargePointConfigList).filter((key) => {
      const lower = filter ? filter.toLowerCase() : ''
      return key.toLowerCase().includes(lower)
    })
    setEditingConfigs(Array(results.length).fill(false))
    setLoadingConfigs(Array(results.length).fill(false))
  }, [filter])

  useEffect(() => {
    setFormattedChargePointConfigList(
      formatChargePointConfigList(chargePointConfigList, filter),
    )
  }, [chargePointConfigList, filter])

  return (
    <div className={Styles.ListContainer}>
      {chargePointConfigListRequestState === RequestState.LOADING ? (
        <div>
          {[...Array(11)].map((e, index) => {
            return (
              <ConfigurationListItemSkeleton
                key={index}
                className={Styles.item}
              />
            )
          })}
        </div>
      ) : (
        <Virtuoso
          data={formattedChargePointConfigList}
          increaseViewportBy={480}
          itemContent={(i, key) => {
            return (
              <ConfigurationListItem
                key={key + '_' + i}
                className={Styles.item}
                name={key}
                value={String(chargePointConfigList[key].value)}
                isLoading={loadingConfigs[i]}
                isEditing={editingConfigs[i]}
                type={chargePointConfigList[key].type}
                readonly={chargePointConfigList[key].readonly}
                description={chargePointConfigList[key].description}
                editingList={(flag) => {
                  editingList(i, flag)
                }}
                onDoneClick={(newValue) => {
                  let newloadingConfigs = [...loadingConfigs]
                  newloadingConfigs[i] = true
                  setLoadingConfigs((prevState) => {
                    prevState[i] = true
                    return [...prevState]
                  })
                  updateChargePointConfig(
                    chargePoints[selectedIndex]._id,
                    key,
                    newValue,
                    (status) => {
                      onSuccess(i, status)
                    },
                    () => {
                      onFail(i)
                    },
                  )
                }}
                editPermission={tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_EDIT_CONFIGURATION,
                )}
              />
            )
          }}
          components={{ Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    updateChargePointConfig: (chargePointId, key, value, onSucess, onFail) =>
      dispatch(
        ChargePointActions.updateChargePointConfig(
          chargePointId,
          key,
          value,
          onSucess,
          onFail,
        ),
      ),
  }
}
const mapStateToProps = (state) => ({
  chargePointConfigList: ChargePointSelectors.getChargePointConfigs(state),
  chargePointConfigListRequestState:
    ChargePointSelectors.getFetchChargePointConfigListRequestState(state),
  updateChargePointConfigRequestState:
    ChargePointSelectors.getUpdateChargePointConfigRequestState(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  tenantUser: AuthSelectors.getTenantUser(state),
})

ConfigurationList.propTypes = {
  chargePointConfigList: PropTypes.object,
  chargePointConfigListRequestState: PropTypes.number,
  updateChargePointConfig: PropTypes.func,
  chargePoints: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  filter: PropTypes.string,
  tenantUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationList)
