import PropTypes from 'prop-types'
import Styles from './ChargePointTabs.module.css'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import { Colors } from 'Theme'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import React, { useEffect, useRef } from 'react'
import { ChargePointTab, ChargePointTabSkeleton, IFSkeleton } from 'Components'
import AddIcon from '@mui/icons-material/Add'
import Icon from '@material-ui/core/Icon'
import { connect } from 'react-redux'
import RequestState from 'Enums/RequestState'
import styled from 'styled-components'
import StationSelectors from 'Stores/Station/Selectors'
import history from 'history/browser'
import AuthSelectors from 'Stores/Auth/Selectors'
import TabScrollButton from '@material-ui/core/TabScrollButton'
import { withStyles } from '@material-ui/core/styles'
import { Emitter } from '../../Services/EmitterService'

const Container = styled.div`
  background-color: ${(props) => props.TabsBackground};
`

const AddContainer = styled.div`
  color: ${(props) => props.black};
`

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 30,
    overflow: 'hidden',
    transition: 'width 0.3s',
    '&.Mui-disabled': {
      width: 0,
    },
  },
}))(TabScrollButton)

const ChargePointTabs = ({
  stationList,
  fetchStationListRequestState,
  fetchChargePointList,
  fetchChargePoint,
  chargePointList,
  connectors,
  fetchChargePointListRequestState,
  stationPaginationOffset,
  selectedIndex,
  stationSelectedIndex,
  setSelectedIndex,
  onAddClick,
  stationsFilter,
  addPermission,
  emitterUpdateChargePoint,
  clearChargePoints,
  resetChargePointState,
  emitterAddChargePoint,
  emitterDeleteChargePoint,
  deleteMovedChargePointRequestState,
  deleteChargePointRequestState,
  emitterUpdateChargePointDiagnostics,
  resetChargePointSpecifications,
  tenant,
}) => {
  const shouldUpdateChargePoints = useRef()

  useEffect(() => {
    if (deleteMovedChargePointRequestState === RequestState.SUCCEEDED) {
      if (chargePointList.length > 0) {
        const slashArray = history.location.pathname.split('/')
        const stationId = slashArray[slashArray.length - 1]

        if (stationId !== chargePointList[selectedIndex]._id) {
          let currentPath = history.location.pathname
          const index = currentPath.indexOf('station')
          if (index !== -1) {
            currentPath = currentPath.substring(0, index - 1)
            currentPath =
              currentPath + `/stations/${chargePointList[selectedIndex]._id}`
            history.push({ pathname: currentPath })
          }
        }
      } else {
        let currentPath = history.location.pathname
        const index = currentPath.indexOf('station')
        if (index !== -1) {
          currentPath = currentPath.substring(0, index - 1)
          history.push({ pathname: currentPath })
        }
      }
      if (deleteChargePointRequestState !== RequestState.LOADING)
        resetChargePointState()
    }
  }, [deleteMovedChargePointRequestState])

  useEffect(() => {
    chargePointList.forEach((chargePoint) => {
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/chargePoint/${chargePoint.uid}/updateConnector/`,
        tenant.channelKey,
        emitterUpdateChargePoint,
        {},
      )
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/chargePoint/${chargePoint.uid}/editChargePoint/`,
        tenant.channelKey,
        emitterUpdateChargePoint,
        {},
      )
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/chargePoint/${chargePoint.uid}/chargePointOnline/`,
        tenant.channelKey,
        emitterUpdateChargePoint,
        {},
      )
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/chargePoint/${chargePoint.uid}/updateDiagnostics/`,
        tenant.channelKey,
        emitterUpdateChargePointDiagnostics,
        {},
      )
    })
  }, [chargePointList, tenant])

  useEffect(() => {
    shouldUpdateChargePoints.current = false
    return () => {
      Emitter.unsubscribeFromChannelGroup(
        `tenant/${tenant._id}/chargePoint/`,
        tenant.channelKey,
      )
    }
  }, [])

  useEffect(() => {
    if (
      (stationList[stationSelectedIndex] && stationPaginationOffset === 1) ||
      (stationList[stationSelectedIndex] && shouldUpdateChargePoints.current)
    ) {
      if (shouldUpdateChargePoints.current) {
        shouldUpdateChargePoints.current = false
      }
      fetchChargePointList(stationList[stationSelectedIndex]._id)
    }
    if (stationList[stationSelectedIndex]?._id) {
      const stationId = stationList[stationSelectedIndex]._id
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/station/${stationId}/chargePointActions/addChargePoint/`,
        tenant.channelKey,
        emitterAddChargePoint,
        {},
      )
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/station/${stationId}/chargePointActions/deleteChargePoint/`,
        tenant.channelKey,
        emitterDeleteChargePoint,
        {},
      )
      Emitter.subscribeToChannel(
        `tenant/${tenant._id}/station/${stationId}/chargePointActions/moveChargePoint/`,
        tenant.channelKey,
        (response) => {
          if (
            stationList[stationSelectedIndex]._id === response.data.oldStation
          )
            emitterDeleteChargePoint({ data: response.data.chargePoint.uid })

          if (
            stationList[stationSelectedIndex]._id === response.data.newStation
          )
            emitterAddChargePoint({ data: response.data.chargePoint })
        },
        {},
      )
    }

    return () => {
      if (stationList[stationSelectedIndex] && stationPaginationOffset === 0)
        clearChargePoints()
    }
  }, [stationSelectedIndex])

  useEffect(() => {
    shouldUpdateChargePoints.current = true
  }, [stationsFilter])

  useEffect(() => {
    if (chargePointList[selectedIndex]) {
      let currentPath = history.location.pathname
      const index = currentPath.indexOf('station')
      if (index !== -1) {
        currentPath = currentPath.substring(0, index - 1)
        const chargePointId = chargePointList[selectedIndex]['_id']
        currentPath = currentPath + `/stations/${chargePointId}`
        history.push({ pathname: currentPath })
      } else {
        const siteExists = history.location.pathname.indexOf('site')
        const slashArray = history.location.pathname.split('/')

        if (siteExists !== -1 && slashArray.length !== 2) {
          currentPath = history.location.pathname
          const chargePointId = chargePointList[selectedIndex]['_id']
          currentPath = currentPath + `/stations/${chargePointId}`
          history.push({ pathname: currentPath })
        }
      }
    }
  }, [selectedIndex])

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue)
    resetChargePointState()
    resetChargePointSpecifications()
    fetchChargePoint(chargePointList[newValue]._id)
  }

  const chargePointTabsContainerRef = useRef()
  const getChargePointTabsSkeletonsCount = () => {
    if (chargePointTabsContainerRef.current) {
      let containerHeight =
        chargePointTabsContainerRef.current.parentElement.clientWidth / 16
      return Math.floor(containerHeight / 10)
    }
    return 9
  }

  return (
    <Container
      TabsBackground={Colors.TabsBackground}
      className={Styles.Container}
    >
      <Tabs
        ScrollButtonComponent={MyTabScrollButton}
        scrollButtons="auto"
        value={selectedIndex ? selectedIndex : 0}
        onChange={handleChange}
        {...(chargePointList.length !== 0 ? { variant: 'scrollable' } : {})}
        TabIndicatorProps={{
          style: {
            backgroundColor:
              fetchChargePointListRequestState === RequestState.LOADING ||
              fetchStationListRequestState === RequestState.LOADING
                ? Colors.transparent
                : Colors.primary,
          },
        }}
        ref={chargePointTabsContainerRef}
      >
        {fetchChargePointListRequestState === RequestState.LOADING ||
        fetchStationListRequestState === RequestState.LOADING
          ? [...Array(getChargePointTabsSkeletonsCount())].map((e, index) => (
              <Tab
                sx={{ padding: '0px' }}
                key={index}
                label={<ChargePointTabSkeleton />}
                disabled
              />
            ))
          : chargePointList.map((chargePoint, index) => (
              <Tab
                sx={{ padding: '0px' }}
                key={index}
                label={
                  <ChargePointTab
                    name={chargePoint.name}
                    connectors={connectors[index]}
                    isSelected={selectedIndex === index}
                    isActivated={chargePoint.isActivated}
                    isConnecting={
                      !chargePoint.isActivated &&
                      chargePoint.connectors.length === 0
                    }
                  />
                }
              />
            ))}
      </Tabs>
      <AddContainer black={Colors.black} className={Styles.AddContainer}>
        {addPermission ? (
          fetchChargePointListRequestState === RequestState.LOADING ||
          fetchStationListRequestState === RequestState.LOADING ? (
            <IFSkeleton variant="circular" width="1.5rem" height="1.5rem" />
          ) : (
            <Icon
              fontSize="medium"
              component={AddIcon}
              onClick={
                fetchChargePointListRequestState === RequestState.LOADING
                  ? () => {}
                  : onAddClick
              }
            />
          )
        ) : null}
      </AddContainer>
    </Container>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedIndex: (index) =>
      dispatch(ChargePointActions.setChargePointSelectedIndex(index)),
    fetchChargePointList: (stationId) =>
      dispatch(ChargePointActions.fetchChargePointList(stationId)),
    fetchChargePoint: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePoint(chargePointId)),
    emitterUpdateChargePoint: (args) =>
      dispatch(ChargePointActions.emitterUpdateChargePoint(args)),
    clearChargePoints: () => dispatch(ChargePointActions.clearChargePoints()),
    resetChargePointState: () =>
      dispatch(ChargePointActions.resetChargePointState()),
    emitterAddChargePoint: (newChargePoint) =>
      dispatch(ChargePointActions.emitterAddChargePoint(newChargePoint)),
    emitterDeleteChargePoint: (chargePointUid) =>
      dispatch(ChargePointActions.emitterDeleteChargePoint(chargePointUid)),
    emitterUpdateChargePointDiagnostics: (status) =>
      dispatch(ChargePointActions.emitterUpdateChargePointDiagnostics(status)),
    resetChargePointSpecifications: () =>
      dispatch(ChargePointActions.resetChargePointSpecifications()),
  }
}

const mapStateToProps = (state) => ({
  chargePointList: ChargePointSelectors.getChargePoints(state),
  connectors: ChargePointSelectors.getFormattedChargePointConnectors(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  stationList: StationSelectors.getStationsList(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  stationSelectedIndex: StationSelectors.getSelectedIndex(state),
  stationPaginationOffset: StationSelectors.getPaginationOffset(state),
  stationsFilter: StationSelectors.getStationsFilter(state),
  deleteMovedChargePointRequestState:
    ChargePointSelectors.getDeleteMovedChargePointRequestState(state),
  deleteChargePointRequestState:
    ChargePointSelectors.getDeleteChargePointRequestState(state),
  tenant: AuthSelectors.getTenant(state),
})

ChargePointTabs.propTypes = {
  chargePointList: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointListRequestState: PropTypes.number,
  selectedIndex: PropTypes.number,
  onAddClick: PropTypes.func,
  addPermission: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargePointTabs)
