import { IFSkeleton } from 'Components'
import React from 'react'
import Styles from './ListHeaderSkeleton.module.css'
import PropTypes from 'prop-types'

const ListHeaderSkeleton = ({ addPermission }) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}></div>

      <div className={Styles.TextContainer}>
        <IFSkeleton width="5rem" height="1.5rem" />
      </div>

      <div className={Styles.IconContainer}>
        {addPermission && (
          <IFSkeleton variant="circular" width="1.25rem" height="1.25rem" />
        )}
      </div>
    </div>
  )
}
ListHeaderSkeleton.propTypes = {
  addPermission: PropTypes.bool,
}

export default ListHeaderSkeleton
