import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import styles from './IFDateRangePicker.module.css'
import { IFText, IFsvg } from 'Components'
import { DateRangePicker } from 'rsuite'
import { getTextWidth } from '../../../Utils/Calculations'
import isAfter from 'date-fns/isAfter'

const IFDateRangePicker = React.forwardRef(
  (
    {
      title,
      onDateRangeSelected = () => {},
      initialValue,
      ranges,
      disableFutureDates = false,
    },
    ref,
  ) => {
    const [value, setValue] = useState(initialValue)
    const [isOpen, setIsOpen] = useState(false)
    const currentDate = new Date()
    const initialDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      0,
      0,
      0,
    )
    const finalDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      currentDate.getDate(),
      23,
      59,
      59,
    )

    const clearValue = () => {
      setValue([])
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))
    return (
      <div className={styles.container}>
        <DateRangePicker
          style={{ width: getTextWidth(title, `600 ${14}px ProximaNova`) + 16 }}
          onOpen={() => setIsOpen(true)}
          format="yyyy-MM-dd HH:mm:ss"
          {...(value.length === 0
            ? {
                defaultCalendarValue: [initialDate, finalDate],
              }
            : {})}
          onClose={() => setIsOpen(false)}
          {...(value.length !== 0 ? { value: value } : {})}
          onOk={(value) => {
            setValue(value)
            onDateRangeSelected(value)
          }}
          cleanable={false}
          size="lg"
          placeholder={title}
          renderValue={() => (
            <div>
              <IFText style={{ color: 'white' }}>{title}</IFText>
            </div>
          )}
          className={styles.DateRange}
          preventOverflow={true}
          ranges={ranges}
          onChange={(value) => {
            setValue(value)
            onDateRangeSelected(value)
          }}
          shouldDisableDate={
            disableFutureDates ? (date) => isAfter(date, new Date()) : null
          }
        />

        <div
          className={styles.TextWrapper}
          style={{
            backgroundColor: Colors.filterBackground,
          }}
        >
          <IFText className={styles.Text}>
            {/* {Replacing all spaces with &nbsp; for styling} */}
            {title.replaceAll(' ', '\u00a0')}
          </IFText>
          <div>
            <IFsvg.Expand
              height={20}
              width={20}
              fill={Colors.FilterIconFilled}
            />
          </div>
        </div>
      </div>
    )
  },
)

IFDateRangePicker.propTypes = {
  title: PropTypes.string.isRequired,
  onDateRangeSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
}

export default IFDateRangePicker
