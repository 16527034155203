import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IFModal, IFText, IFTextArea, IFButton } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import Styles from './TerminalModal.module.css'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { Send } from '@material-ui/icons'
import { SelectPicker } from 'rsuite'
import { FormControl, makeStyles } from '@material-ui/core'
import styled from 'styled-components'
import JSONPretty from 'react-json-pretty'
import OCPPServices from 'Services/OCPPService'
import { isPropsMatch } from 'Utils/PropsMatch'
import OCPPEnums from 'Enums/OCPPEnums'

const useStyles = makeStyles({
  root: {
    boxShadow: `0px 0px 2px ${Colors.black} `,
    borderRadius: '5px',
  },
})

const TerminalContainer = styled.div`
  padding: 0.5rem;
  box-shadow: 0px 0px 4px ${Colors.black};
  border-radius: 5px;
`

const TerminalModal = React.forwardRef(({ chargePoint }, ref) => {
  const { OCPPMessages } = OCPPEnums
  const { t } = useTranslation()
  const Classes = useStyles()
  const [textValue, setTextValue] = useState('')
  const [response, setResponse] = useState()
  const [textError, setTextError] = useState('')
  const [messageType, setMessageType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const onResponse = (res) => {
    setResponse(res)
    setIsLoading(false)
  }

  return (
    <IFModal
      onClose={() => {
        setTextValue('')
        setResponse('')
        setTextError('')
        setMessageType('')
        setIsLoading(false)
      }}
      width={'45rem'}
      title={t('TerminalModal.Title')}
      ref={ref}
    >
      <div className={Styles.SelectPickerContainer}>
        <IFText className={Styles.Label}>{t('TerminalModal.Type')}</IFText>
        <FormControl
          className={Classes.root}
          onClick={(e) => e.stopPropagation()}
        >
          <SelectPicker
            preventOverflow={true}
            menuStyle={{ color: Colors.text, zIndex: 15 }}
            appearance="default"
            searchable={false}
            cleanable={false}
            onSelect={(value) => {
              const i = OCPPMessages.findIndex(
                (message) => message.value === value,
              )
              setResponse('')
              setMessageType(value)
              setTextValue(
                JSON.stringify(OCPPMessages[i]?.description, undefined, 8),
              )
            }}
            onClean={() => {
              setResponse('')
              setMessageType('')
            }}
            data={OCPPMessages}
            size="md"
          />
        </FormControl>
      </div>
      <IFText className={Styles.Label}>{t('TerminalModal.Payload')}</IFText>
      <IFTextArea
        value={textValue}
        onChange={(e) => {
          setTextValue(e.target.value)
          if (textError.length > 0) setTextError('')
        }}
        className={Styles.TextArea}
        errorText={textError}
      />
      <div className={response ? Styles.ResponseIn : Styles.ResponseOut}>
        <div className={Styles.ResponseTitle}>
          <IFText className={Styles.Label}>
            {t('TerminalModal.Response')}
          </IFText>
        </div>
        <TerminalContainer>
          <JSONPretty mainStyle="margin:0em" data={response} />
        </TerminalContainer>
      </div>
      <div className={Styles.Container}>
        <IFButton
          text={t('TerminalModal.ButtonText')}
          icon={<Send style={{ fontSize: '18px' }} />}
          color={Colors.primary}
          isFill={true}
          className={Styles.Button}
          isLoading={isLoading}
          size={'sm'}
          isDead={!messageType || textError}
          onClick={() => {
            if (
              textValue.includes('Required') ||
              textValue.includes('Optional')
            ) {
              setTextError(t('TerminalModal.NotAllowed'))
              return
            }
            try {
              JSON.parse(textValue)
            } catch (error) {
              setTextError(t('TerminalModal.InvalidJson'))
              return
            }
            setIsLoading(true)
            OCPPServices.ocppMessage(
              chargePoint._id,
              messageType,
              JSON.parse(textValue),
              onResponse,
            )
          }}
        />
      </div>
    </IFModal>
  )
})

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
})

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, ['ocppMesaages', 'chargePoint'])
}

export default React.memo(
  connect(mapStateToProps, null, null, {
    forwardRef: true,
  })(TerminalModal),
  shouldSkipRender,
)
