import React, { useEffect, forwardRef } from 'react'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SubscriptionList from '../SubscriptionList/SubscriptionList'
import TenantUserActions from 'Stores/TenantUser/Actions'
import UserActions from 'Stores/User/Actions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import UserSelectors from 'Stores/User/Selectors'
import Styles from './UserSubscription.module.css'

const UserSubscription = forwardRef(
  (
    {
      isEditing,
      fetchSubscriptionsList,
      user,
      fetchUserRequestState,
      addUserSubscriptionsRequestState,
      addUserSubscriptions,
      deleteUserSubscriptions,
      deleteUserSubscriptionsRequestState,
      subscriptionsList,
      maxListHeight,
      scrollbarsHeight,
      fetchUserListRequestState,
      removeExtraMargin,
    },
    ref,
  ) => {
    useEffect(() => {
      if (user) {
        fetchSubscriptionsList()
      }
    }, [user])

    const onSubmit = (addSubscriptions, deleteSubscriptions) => {
      if (deleteSubscriptions.length > 0)
        deleteUserSubscriptions(user._id, deleteSubscriptions)
      if (addSubscriptions.length > 0)
        addUserSubscriptions(user._id, addSubscriptions)
    }

    return (
      <div className={Styles.UserSubscriptionContainer}>
        <SubscriptionList
          ref={ref}
          displayedList={user?.subscriptions ? user?.subscriptions : []}
          dropdownList={subscriptionsList}
          isEditing={isEditing}
          isLoading={
            fetchUserRequestState === RequestState.LOADING ||
            fetchUserListRequestState === RequestState.LOADING ||
            user?.isInvitedUser
          }
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={onSubmit}
          removeExtraMargin={removeExtraMargin}
        />
      </div>
    )
  },
)
function mapDispatchToProps(dispatch) {
  return {
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),
    addUserSubscriptions: (userId, subscriptions) =>
      dispatch(UserActions.addUserSubscriptions(userId, subscriptions)),
    deleteUserSubscriptions: (userId, subscriptions) =>
      dispatch(UserActions.deleteUserSubscriptions(userId, subscriptions)),
  }
}

const mapStateToProps = (state) => ({
  subscriptionsList: TenantUserSelectors.getSubscriptionsList(state),
  user: UserSelectors.getSelectedUser(state),
  fetchUserRequestState: UserSelectors.getFetchUserDetailsRequestState(state),
  addUserSubscriptionsRequestState:
    UserSelectors.getAddUserSubscriptionsRequestState(state),
  deleteUserSubscriptionsRequestState:
    UserSelectors.getDeleteUserSubscriptionsRequestState(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
})

UserSubscription.propTypes = {
  isEditing: PropTypes.bool,
  fetchSubscriptionsList: PropTypes.array,
  user: PropTypes.object,
  fetchUser: PropTypes.func,
  fetchUserRequestState: PropTypes.number,
  addUserSubscriptionsRequestState: PropTypes.number,
  addUserSubscriptions: PropTypes.func,
  deleteUserSubscriptions: PropTypes.func,
  deleteUserSubscriptionsRequestState: PropTypes.number,
  subscriptionsList: PropTypes.array,
  subscriptionsListRef: PropTypes.object,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserSubscription)
