import PropTypes from 'prop-types'
import Styles from './UploadDiagnosticsListItem.module.css'
import { IFText, IFsvg, IFTooltipIconsLoading } from 'Components'
import { useState, useRef } from 'react'
import { Colors } from 'Theme'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'

const Container = styled('div')(({ theme }) => ({
  '&:hover': {
    backgroundColor: Colors.ChargePointLogListItemHover,
  },
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  cursor: 'default',
}))

const UploadDiagnosticsListItem = ({
  fileName,
  status,
  createdAt,
  onDownload,
}) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const IFTooltipRef = useRef(null)

  return (
    <Container className={Styles.Container}>
      <div className={Styles.FileNameContainer}>
        <div className={Styles.FileContainer}>
          {status === InfinityEnums.UploadDiagnosticsStatus.UPLOADING ||
          status === InfinityEnums.UploadDiagnosticsStatus.IDLE ? (
            <div className={Styles.spinner}>
              <div
                className={Styles.bounce1}
                style={{
                  backgroundColor: Colors.FileColor,
                }}
              />
              <div
                className={Styles.bounce2}
                style={{
                  backgroundColor: Colors.FileColor,
                }}
              />
              <div
                style={{
                  backgroundColor: Colors.FileColor,
                }}
                className={Styles.bounce3}
              />
            </div>
          ) : status === InfinityEnums.UploadDiagnosticsStatus.UPLOAD_FAILED ? (
            <IFsvg.Error height={20} width={20} fill={Colors.red} />
          ) : (
            <IFTooltipIconsLoading
              ref={IFTooltipRef}
              onClick={async () => {
                setLoading(true)
                await onDownload(fileName)
                setLoading(false)
              }}
              Icon={IFsvg.File}
              FilledIcon={IFsvg.File}
              iconColor={Colors.text}
              isLoading={loading}
              size={20}
              iconClassname={Styles.iconContainer}
              animationDisabled={false}
            />
          )}
        </div>
        <IFText
          onClick={() => {
            if (IFTooltipRef && IFTooltipRef.current)
              IFTooltipRef.current.click()
            else return
          }}
          className={
            status === InfinityEnums.UploadDiagnosticsStatus.UPLOADING ||
            status === InfinityEnums.UploadDiagnosticsStatus.IDLE
              ? Styles.TextUploading
              : status === InfinityEnums.UploadDiagnosticsStatus.UPLOADED
              ? Styles.FileNameText
              : {}
          }
          style={{
            color:
              status === InfinityEnums.UploadDiagnosticsStatus.UPLOADING ||
              status === InfinityEnums.UploadDiagnosticsStatus.IDLE
                ? Colors.FileColor
                : status === InfinityEnums.UploadDiagnosticsStatus.UPLOAD_FAILED
                ? Colors.red
                : Colors.text,
          }}
        >
          {fileName}
        </IFText>
      </div>
      <div className={Styles.CreatedAtContainer}>
        <IFText
          className={
            status === InfinityEnums.UploadDiagnosticsStatus.UPLOADING ||
            status === InfinityEnums.UploadDiagnosticsStatus.IDLE
              ? Styles.TextUploading
              : {}
          }
          style={{
            color:
              status === InfinityEnums.UploadDiagnosticsStatus.UPLOADING ||
              status === InfinityEnums.UploadDiagnosticsStatus.IDLE
                ? Colors.FileColor
                : Colors.text,
          }}
        >
          {createdAt}
        </IFText>
      </div>
    </Container>
  )
}

UploadDiagnosticsListItem.propTypes = {
  fileName: PropTypes.string,
  status: PropTypes.string,
  createdAt: PropTypes.string,
  onDownload: PropTypes.func,
}

export default UploadDiagnosticsListItem
