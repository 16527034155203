import InfinityEnums from 'Enums/InfinityEnums'
import IFFilterType from 'Enums/IFFilterType'
var stringSimilarity = require('string-similarity')

const textSearchFields = [
  'name',
  'uid',
  'model',
  'vendor',
  'firmwareVersion',
  'ocppVersion',
  'serial',
  'iccid',
]

let filterField = [
  {
    name: 'chargePointStatus',
    searchField: 'isOnline',
    trueCondition: 'Online',
    weight: 2,
  },
  {
    name: 'chargePointActivation',
    searchField: 'isActivated',
    trueCondition: 'Active',
    weight: 3,
  },
  {
    name: 'connectorStatus',
    searchField: 'status',
    weight: 4,
  },
  {
    name: 'chargePointType',
    searchField: 'maxPower',
    weight: 1,
  },
  {
    name: 'appVisibility',
    searchField: 'isHidden',
    trueCondition: 'Not visible',
    weight: 2,
  },
  {
    name: 'chargePointPrivacy',
    searchField: 'isPrivate',
    trueCondition: 'Private',
    weight: 2,
  },
]

// chargepoint activation, status, app visibility or privacy
function chargePointBooleanMatch(chargePoints, filters, filterField) {
  if (filterField.trueCondition) {
    filters = filters.map((filter) => filter === filterField.trueCondition)
  }

  for (const [index, cp] of chargePoints.entries()) {
    const doesMatch = filters.some(
      (filter) => filter === cp[filterField.searchField],
    )

    if (doesMatch) {
      if (chargePoints[index].weight)
        chargePoints[index].weight =
          chargePoints[index].weight + filterField.weight
      else chargePoints[index].weight = filterField.weight
      if (chargePoints[index].matches)
        chargePoints[index].matches = chargePoints[index].matches + 1
      else chargePoints[index].matches = 1
    }
  }
}

// chargepoint connector status
function connectorStatusMatch(chargePoints, filters, filterField) {
  let doesMatch = false
  for (const [index, cp] of chargePoints.entries()) {
    if (!cp.isOnline) continue
    for (const conn of cp.connectors) {
      if (conn.uid !== 0) {
        doesMatch = filters.some(
          (filter) => filter === conn[filterField.searchField],
        )
        if (doesMatch) break
      }
    }
    if (doesMatch) {
      if (chargePoints[index].weight)
        chargePoints[index].weight =
          chargePoints[index].weight + filterField.weight
      else chargePoints[index].weight = filterField.weight
      if (chargePoints[index].matches)
        chargePoints[index].matches = chargePoints[index].matches + 1
      else chargePoints[index].matches = 1
      doesMatch = false
    }
  }
}

// connector max charging speed
function chargingSpeedMatch(chargePoints, filters, filterField) {
  let doesMatch = false
  for (const [index, cp] of chargePoints.entries()) {
    for (const conn of cp.connectors) {
      if (conn.uid !== 0) {
        for (const filter of filters) {
          if (
            (filter === InfinityEnums.ChargingSpeedLevels.LEVEL1 &&
              conn.maxPower > 0 &&
              conn.maxPower <= InfinityEnums.ConnectorPowerLevel.LEVEL1) ||
            (filter === InfinityEnums.ChargingSpeedLevels.LEVEL2 &&
              conn.maxPower > InfinityEnums.ConnectorPowerLevel.LEVEL1 &&
              conn.maxPower < InfinityEnums.ConnectorPowerLevel.LEVEL2) ||
            (filter === InfinityEnums.ChargingSpeedLevels.LEVEL3 &&
              conn.maxPower >= InfinityEnums.ConnectorPowerLevel.LEVEL2)
          ) {
            doesMatch = true
            break
          }
        }
      }
    }
    if (doesMatch) {
      if (chargePoints[index].weight)
        chargePoints[index].weight =
          chargePoints[index].weight + filterField.weight
      else chargePoints[index].weight = filterField.weight
      if (chargePoints[index].matches)
        chargePoints[index].matches = chargePoints[index].matches + 1
      else chargePoints[index].matches = 1
      doesMatch = false
    }
  }
}

export function filterBestMatch(stationsFilter, chargePoints) {
  const textFilter = stationsFilter.filter(
    (filter) => filter.type === IFFilterType.KEYWORD,
  )
  let filteredChargePoints = chargePoints
  let ratingsArray = Array(textSearchFields.length).fill({
    rating: { number: -1, index: -1, comparedString: '' },
    duplicate: false,
    duplicateIndices: [],
  })

  let highestRating = {
    index: -1,
    rating: -1,
    duplicate: [],
    comparedString: '',
  }
  let searchWord = ''
  if (textFilter[0].value.length !== 0)
    searchWord = textFilter[0].value[0].toLowerCase()

  if (textFilter[0].value.length !== 0) {
    // Checking text filter
    for (const [fieldIndex, field] of textSearchFields.entries()) {
      let extractedFields = []
      for (const cp of filteredChargePoints) {
        if (cp[field]) {
          extractedFields.push(cp[field].toLowerCase())
        } else {
          extractedFields.push('')
        }
      }
      // finding best match
      let matchSimilarity = stringSimilarity.findBestMatch(
        searchWord,
        extractedFields,
      )

      // 100% match
      if (matchSimilarity.bestMatch.rating === 1) {
        return matchSimilarity.bestMatchIndex
      } else {
        // checking subset matches
        for (const [
          ratingIndex,
          similarity,
        ] of matchSimilarity.ratings.entries()) {
          if (
            similarity.rating > ratingsArray[fieldIndex]['rating']['number']
          ) {
            ratingsArray[fieldIndex] = {
              ...ratingsArray[fieldIndex],
              rating: {
                number: similarity.rating,
                index: ratingIndex,
                comparedString: similarity.target,
              },
              duplicate: false,
              duplicateIndices: [],
            }
          } else if (
            similarity.rating ===
              ratingsArray[fieldIndex]['rating']['number'] &&
            similarity.rating !== 0
          ) {
            ratingsArray[fieldIndex] = {
              ...ratingsArray[fieldIndex],
              duplicate: true,
              duplicateIndices: [
                ...ratingsArray[fieldIndex]['duplicateIndices'],
                ratingIndex,
              ],
            }
          }
        }

        if (
          ratingsArray[fieldIndex]['rating']['number'] > highestRating['rating']
        ) {
          highestRating['index'] = fieldIndex
          highestRating['rating'] = ratingsArray[fieldIndex]['rating']['number']
          highestRating['duplicate'] = []
          highestRating['comparedString'] =
            ratingsArray[fieldIndex]['rating']['comparedString']
        } else if (
          ratingsArray[fieldIndex]['rating']['number'] ===
            highestRating['rating'] &&
          highestRating['rating'] !== 0
        ) {
          highestRating['duplicate'] = [
            ...highestRating['duplicate'],
            fieldIndex,
          ]
        }
      }
    }

    if (highestRating.duplicate.length === 0) {
      // No multiple text field duplicates (name, uid etc...)
      if (!ratingsArray[highestRating.index].duplicate) {
        // no duplicates within a specific text field
        if (
          ratingsArray[highestRating.index].rating.comparedString.includes(
            searchWord,
          )
        ) {
          return ratingsArray[highestRating.index].rating.index
        } else return 0
      } else {
        // duplicates within a specific text field
        let cpIndices = [
          ratingsArray[highestRating.index].rating.index,
          ...ratingsArray[highestRating.index].duplicateIndices,
        ]
        filteredChargePoints = []
        for (const index of cpIndices) {
          filteredChargePoints.push(chargePoints[index])
        }
      }
    } else {
      // Multiple text field duplicates (name, uid etc...)
      let fieldIndices = [highestRating.index, ...highestRating.duplicate]
      let fields = []
      let finalIndices = []
      for (const index of fieldIndices) {
        fields.push(ratingsArray[index])
      }

      for (const field of fields) {
        finalIndices = [
          ...finalIndices,
          ...field.duplicateIndices,
          field.rating.index,
        ]
      }

      finalIndices = finalIndices.sort((a, b) => a - b)
      const finalIndicesSet = new Set(finalIndices)
      filteredChargePoints = []
      for (const index of finalIndicesSet) {
        filteredChargePoints.push(chargePoints[index])
      }
    }
  }

  for (const filter of stationsFilter) {
    if (filter.type !== IFFilterType.KEYWORD) {
      if (filter.value.length !== 0) {
        const selectedFilterField = filterField.filter(
          (field) => field.name === filter.field,
        )
        // checking other filter fields
        switch (filter.field) {
          case 'appVisibility':
          case 'chargePointStatus':
          case 'chargePointActivation':
          case 'chargePointPrivacy':
            chargePointBooleanMatch(
              filteredChargePoints,
              filter.value,
              ...selectedFilterField,
            )
          case 'connectorStatus':
            connectorStatusMatch(
              filteredChargePoints,
              filter.value,
              ...selectedFilterField,
            )
          case 'chargePointType':
            chargingSpeedMatch(
              filteredChargePoints,
              filter.value,
              ...selectedFilterField,
            )
        }
      }
    }
  }

  let mostMatches = { number: 0, id: null }
  let biggestWeight = { weight: 0, id: null }
  let duplicateChargePoints = []
  // Check to see which cp has more matches/weight
  for (const cp of filteredChargePoints) {
    if (cp.matches > mostMatches.number) {
      duplicateChargePoints = []
      mostMatches.number = cp.matches
      mostMatches.id = cp._id
      duplicateChargePoints.push(cp)
    } else if (cp.matches === mostMatches.number) {
      duplicateChargePoints.push(cp)
    }
  }

  if (duplicateChargePoints.length === 0) {
    if (mostMatches.id === null) {
      if (
        highestRating.index > -1 &&
        ratingsArray[highestRating.index].rating.comparedString.includes(
          searchWord,
        )
      ) {
        return chargePoints.findIndex(
          (cp) => cp._id === filteredChargePoints[0]._id,
        )
      } else return 0
    }

    return chargePoints.findIndex((cp) => cp._id === mostMatches.id)
  } else {
    for (const cp of duplicateChargePoints) {
      if (cp.weight > biggestWeight.weight) {
        biggestWeight.weight = cp.weight
        biggestWeight.id = cp._id
      }
    }
    return chargePoints.findIndex((cp) => cp._id === biggestWeight.id)
  }
}
