import Styles from './ChargePointSubscriptionListItemSkeleton.module.css'
import IFSkeleton from 'Components/IFSkeleton/IFSkeleton'

const ChargePointSubscriptionListItemSkeleton = ({}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        <div className={Styles.TextContainer}>
          <IFSkeleton className={Styles.Name} variant="text" />
        </div>
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.PriceTextContainer}>
          <IFSkeleton className={Styles.Price} variant="text" />
        </div>
        <div className={Styles.PriceTextContainer}>
          <IFSkeleton className={Styles.Price} variant="text" />
        </div>
        <div className={Styles.PriceTextContainer}>
          <IFSkeleton className={Styles.Price} variant="text" />
        </div>
      </div>
    </div>
  )
}

ChargePointSubscriptionListItemSkeleton.propTypes = {}

export default ChargePointSubscriptionListItemSkeleton
