import { put, call } from 'redux-saga/effects'
import {
  setAuthHeader,
  handleError,
  authApiService,
  tenantUserApiService,
} from 'Services/ApiService.js'
import AuthActions from 'Stores/Auth/Actions'
import RootActions from 'Stores/RootActions'

export function* tenantUserLogin({ email, password }) {
  yield put(AuthActions.tenantUserLoginLoading())

  try {
    const response = yield call(authApiService.post, '/tenantUser', {
      email: email,
      password: password,
    })

    setAuthHeader(
      response.headers['x-tenant-auth-token'],
      response.headers['x-tenant-refresh-token'],
    )
    yield put(
      AuthActions.tenantUserLoginSuccess(
        response.headers['x-tenant-auth-token'],
        response.headers['x-tenant-refresh-token'],
        response.data,
      ),
    )
    yield put(AuthActions.tenantUserLoginResetLoading())
  } catch (e) {
    yield put(AuthActions.tenantUserLoginFail())
    handleError(e)
  }
}

export function* fetchTenantUser() {
  try {
    const { data } = yield call(tenantUserApiService.get, '/me')
    yield put(AuthActions.fetchTenantUserSuccess(data))
  } catch (e) {
    yield put(AuthActions.fetchTenantUserFail())
    handleError(e)
  }
}

export function* tenantUserLogout() {
  try {
    yield call(authApiService.post, '/tenantUser/logout')
    yield put(RootActions.resetAllStores())
  } catch (e) {
    handleError(e)
  }
}

export function* resetPassVerification({ email, onSuccess }) {
  yield put(AuthActions.resetPassVerificationLoading())

  try {
    const { data } = yield call(
      tenantUserApiService.post,
      '/resetPasswordEmailVerification',
      {
        email: email,
      },
    )

    yield put(AuthActions.resetPassVerificationSuccess(data.id))
    onSuccess()
  } catch (e) {
    yield put(AuthActions.resetPassVerificationFail())
    handleError(e)
  }
}

export function* resetPass({
  newPassword,
  emailVerificationCode,
  emailVerificationId,
  onSuccess,
}) {
  yield put(AuthActions.resetPassLoading())

  try {
    yield call(tenantUserApiService.post, '/resetPassword', {
      emailVerificationId: emailVerificationId,
      emailVerificationCode: emailVerificationCode,
      newPassword: newPassword,
    })

    yield put(AuthActions.resetPassSuccess())
    onSuccess()
  } catch (e) {
    yield put(AuthActions.resetPassFail(e))
    handleError(e)
  }
}
