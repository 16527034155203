import React, { useState, useEffect, useImperativeHandle, useRef } from 'react'
import { connect } from 'react-redux'
import Styles from './AddCityModal.module.css'
import { Colors } from 'Theme'
import IFTextInput from 'Components/IFTextInput/IFTextInput'
import { useTranslation } from 'react-i18next'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import RequestState from 'Enums/RequestState'
import AddIcon from '@mui/icons-material/Add'
import PropTypes from 'prop-types'
import IFDialog from 'Components/IFDialog/IFDialog'
import { Icon } from '@material-ui/core'

const AddCityModal = React.forwardRef(
  ({ addCityRequestState, addCity }, ref) => {
    const [cityName, setCityName] = useState('')
    const [latestCityAdded, setLatestCityAdded] = useState('')
    const { t } = useTranslation()

    const dialogRef = useRef()

    useEffect(() => {
      if (addCityRequestState === RequestState.SUCCEEDED) {
        dialogRef.current.dismiss()
      }
    }, [addCityRequestState])

    const returnLastAddedCity = () => {
      return latestCityAdded
    }

    const show = () => {
      dialogRef.current.show()
    }

    const dismiss = () => {
      dialogRef.current.dismiss()
    }

    useImperativeHandle(ref, () => ({
      returnLastAddedCity,
      show,
      dismiss,
    }))

    return (
      <IFDialog
        ref={dialogRef}
        dialogWidth={'25rem'}
        title={t('AddCityModal.Title')}
        subtitle={t('AddCityModal.Subtitle')}
        onAction={() => {
          addCity(cityName)
          setLatestCityAdded(cityName)
        }}
        actionButtonIcon={
          <Icon className={Styles.AddIconStyle} component={AddIcon} />
        }
        actionButtonText={t('AddCityModal.AddButtonText')}
        actionButtonColor={Colors.primary}
        isLoading={addCityRequestState === RequestState.LOADING}
        onDismiss={() => setCityName('')}
      >
        <IFTextInput
          value={cityName}
          isFixed={true}
          className={Styles.IFTextInputStyle}
          onChange={(e) => setCityName(e.target.value)}
        ></IFTextInput>
      </IFDialog>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    addCity: (city) => dispatch(StationActions.addStationCity(city)),
  }
}

const mapStateToProps = (state) => ({
  addCityRequestState: StationSelectors.getAddCityRequestState(state),
})

AddCityModal.propTypes = {
  addCityRequestState: PropTypes.number,
  addCity: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddCityModal)
