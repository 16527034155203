import PropTypes from 'prop-types'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import styles from './IFCheckPicker.module.css'
import { IFText, IFsvg } from 'Components'
import { CheckPicker } from 'rsuite'
import { getTextWidth } from '../../../Utils/Calculations'

const IFCheckPicker = React.forwardRef(
  (
    {
      title,
      data,
      onValueChange = () => {},
      initialValue,
      isChargingSpeed = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const [value, setValue] = useState(initialValue)
    const clearValue = () => {
      setValue([])
    }

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))
    const options = []
    for (const index in data) {
      isChargingSpeed
        ? options.push({ label: data[index].label, value: data[index].value })
        : options.push({
            label: data[index],
            value: data[index],
          })
    }
    return (
      <div className={styles.container}>
        <CheckPicker
          style={{ width: getTextWidth(title, `600 ${14}px ProximaNova`) + 16 }}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={value}
          onSelect={(value) => {
            onValueChange(value)
            setValue(value)
          }}
          data={options}
          cleanable={false}
          size="lg"
          searchable={false}
          placeholder={title}
          renderValue={() => (
            <div>
              <IFText>{title}</IFText>
            </div>
          )}
          className={styles.CheckPicker}
        />
        <div
          className={styles.TextWrapper}
          style={{
            backgroundColor: Colors.filterBackground,
          }}
        >
          <IFText className={styles.Text}>
            {/* {Replacing all spaces with &nbsp; for styling} */}
            {title.replaceAll(' ', '\u00a0')}
          </IFText>
          <div>
            <IFsvg.Expand
              height={20}
              width={20}
              fill={Colors.FilterIconFilled}
            />
          </div>
        </div>
      </div>
    )
  },
)

IFCheckPicker.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onValueChange: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  isChargingSpeed: PropTypes.bool,
}

export default IFCheckPicker
