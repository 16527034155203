import * as React from 'react'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import IFSearchFieldSkeleton from './IFSearchFieldSkeleton'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  marginLeft: 0,
  background: colors.white,
  borderRadius: '5px',
  borderWidth: '0px',
  boxShadow: `0px 0px 2px ${colors.BoxShadowColor}`,
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  fontSize: '0.875rem',
  color: 'inherit',
  borderRadius: '5px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'white',
  fontFamily: 'ProximaNova',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3.5)})`,
    width: '100%',
  },

  '&.Mui-focused': {
    outline: 'none',
    borderColor: colors.primary,
  },
}))

const IFSearchField = ({ className, style, loading, ...props }) => {
  return !loading ? (
    <Search className={className} style={style}>
      <SearchIconWrapper>
        <SearchIcon style={{ height: '20px' }} />
      </SearchIconWrapper>
      <StyledInputBase {...props} placeholder="Search" />
    </Search>
  ) : (
    <IFSearchFieldSkeleton />
  )
}
IFSearchField.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IFSearchField
