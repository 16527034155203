import PropTypes from 'prop-types'
import Styles from './ConfigurationListItem.module.css'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import { IFText } from 'Components'
import { useState, useEffect, Fragment, useRef } from 'react'
import { IFTextInput } from 'Components'
import { IFButton, IFToggle } from 'Components'
import { Colors } from 'Theme'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    color: Colors.black,
    maxWidth: '37.5rem',
    fontSize: '1rem',
    border: `1px solid ${Colors.grey}`,
  },
}))
const Container = styled('div')(({ theme }) => ({
  '&:hover': {
    backgroundColor: Colors.ChargePointLogListItemHover,
  },
}))
const ConfigurationListItem = ({
  name,
  value,
  type,
  description,
  onDoneClick,
  isLoading,
  isEditing,
  editingList,
  editPermission,
  readonly,
  ...props
}) => {
  const [editmode, setEditmode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [input, setInput] = useState(value)
  const [overflowActive, setOverflowActive] = useState(false)
  const toggleRef = useRef()
  const { t } = useTranslation()
  const ref = useRef(null)

  useEffect(() => {
    setEditmode(isEditing)
  }, [isEditing])
  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])
  useEffect(() => {
    if (!editmode) setInput(value)
  }, [editmode])
  useEffect(() => {
    if (ref.current && isOverflowActive(ref.current)) {
      setOverflowActive(true)
      return
    }

    setOverflowActive(false)
  }, [isOverflowActive])

  function isOverflowActive(event) {
    return (
      event.offsetHeight < event.scrollHeight ||
      event.offsetWidth < event.scrollWidth
    )
  }

  const isValueBool = (value) => {
    return value === 'true' || value === 'false'
  }
  return (
    <div {...props}>
      <Container className={Styles.container}>
        <div className={Styles.row}>
          <div className={Styles.textIcon}>
            <IFText className={Styles.leftText}>{name}</IFText>
            {type && description ? (
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <div className={Styles.tooltipType}>
                      <IFText className={Styles.tooltipText}>
                        {t('ConfigurationListItem.type')}
                      </IFText>
                      <IFText
                        className={Styles.tooltipText}
                        style={{ color: Colors.tooltipGrey }}
                      >
                        {type}
                      </IFText>
                    </div>
                    <IFText className={Styles.tooltipText}>
                      {t('ConfigurationListItem.description')}
                    </IFText>
                    <IFText
                      className={Styles.tooltipText}
                      style={{ color: Colors.tooltipGrey }}
                    >
                      {description}
                    </IFText>
                  </React.Fragment>
                }
                placement="right-start"
              >
                <InfoIcon className={Styles.infoIcon} />
              </HtmlTooltip>
            ) : null}
          </div>
        </div>
        <div className={Styles.row}>
          {!editmode ? (
            <Fragment>
              {overflowActive ? (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <IFText>{value}</IFText>
                    </React.Fragment>
                  }
                  placement="right-start"
                >
                  <IFText ref={ref} className={Styles.middleText}>
                    {value}
                  </IFText>
                </HtmlTooltip>
              ) : isValueBool(value) ? (
                <div className={Styles.middleText}>
                  <IFToggle
                    key={`${name}readonlytoggle`}
                    readOnly
                    height={24}
                    fontSize={12}
                    checkedText={t('ConfigurationListItem.Enabled')}
                    uncheckedText={t('ConfigurationListItem.Disabled')}
                    defaultChecked={value === 'true'}
                  />
                </div>
              ) : (
                <IFText ref={ref} className={Styles.middleText}>
                  {value}
                </IFText>
              )}
            </Fragment>
          ) : (
            <div className={Styles.middleText}>
              {isValueBool(value) ? (
                <IFToggle
                  key={`${name}toggle`}
                  ref={toggleRef}
                  height={24}
                  fontSize={12}
                  checkedText={t('ConfigurationListItem.Enabled')}
                  uncheckedText={t('ConfigurationListItem.Disabled')}
                  defaultChecked={input === 'true'}
                />
              ) : (
                <IFTextInput
                  className={Styles.input}
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  isFixed={false}
                />
              )}
            </div>
          )}
        </div>
        <div className={Styles.row}>
          {!editmode ? (
            editPermission && !readonly ? (
              <EditIcon
                style={{ height: '20px' }}
                className={Styles.editIcon}
                onClick={() => {
                  setEditmode(true)
                  editingList(true)
                }}
              />
            ) : null
          ) : (
            <div className={Styles.buttonsContainer}>
              <IFButton
                text={t('ConfigurationListItem.done')}
                isFill={true}
                color={Colors.primary}
                size="xsm"
                icon={<CheckIcon className={Styles.buttonIcon} />}
                className={Styles.button}
                onClick={() => {
                  isValueBool(value)
                    ? onDoneClick(toggleRef.current.getValue().toString())
                    : onDoneClick(input)
                }}
                isLoading={loading}
              />
              <IFButton
                text={t('ConfigurationListItem.cancel')}
                isFill={false}
                color={Colors.primary}
                size="xsm"
                icon={<CloseIcon className={Styles.buttonIcon} />}
                className={Styles.button}
                onClick={() => {
                  setEditmode(false)
                  editingList(false)
                }}
              />
            </div>
          )}
        </div>
      </Container>
    </div>
  )
}

ConfigurationListItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string,
  description: PropTypes.string,
  onDoneClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  editingList: PropTypes.func.isRequired,
  editPermission: PropTypes.bool,
}

export default ConfigurationListItem
