import React, { useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import UserActions from 'Stores/User/Actions'
import UserSelectors from 'Stores/User/Selectors'
import RequestState from 'Enums/RequestState'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import AccessGroupList from '../AccessGroupList/AccessGroupList'

const UserContainer = forwardRef(
  (
    {
      selectedUser,
      accessGroups,
      fetchAccessGroups,
      addUserAccessGroups,
      deleteUserAccessGroups,
      isEditing,
      fetchUserListRequestState,
      deleteUserAccessGroupsRequestState,
      addUserAccessGroupsRequestState,
      fetchUserDetailsRequestState,
      maxListHeight,
      scrollbarsHeight,
    },
    ref,
  ) => {
    useEffect(() => {
      if (selectedUser) {
        fetchAccessGroups()
      }
    }, [selectedUser])

    return (
      <div style={{ height: '100%' }}>
        <AccessGroupList
          ref={ref}
          displayedList={selectedUser?.accessGroups}
          dropdownList={accessGroups}
          isEditing={isEditing}
          isLoading={
            fetchUserListRequestState === RequestState.LOADING ||
            fetchUserDetailsRequestState === RequestState.LOADING ||
            selectedUser?.isInvitedUser
          }
          maxListHeight={maxListHeight}
          scrollbarsHeight={scrollbarsHeight}
          onSubmit={(addAccessGroups, deleteAccessGroups) => {
            if (deleteAccessGroups.length !== 0) {
              deleteUserAccessGroups(selectedUser._id, deleteAccessGroups)
            }
            if (addAccessGroups.length !== 0) {
              addUserAccessGroups(selectedUser._id, addAccessGroups)
            }
          }}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    addUserAccessGroups: (userId, accessGroups) =>
      dispatch(UserActions.addUserAccessGroups(userId, accessGroups)),
    deleteUserAccessGroups: (userId, accessGroups) =>
      dispatch(UserActions.deleteUserAccessGroups(userId, accessGroups)),
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

const mapStateToProps = (state) => ({
  selectedUser: UserSelectors.getSelectedUser(state),
  accessGroups: TenantUserSelectors.getAccessGroupsList(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  deleteUserAccessGroupsRequestState:
    UserSelectors.getDeleteUserAccessGroupsRequestState(state),
  addUserAccessGroupsRequestState:
    UserSelectors.getAddUserAccessGroupsRequestState(state),
  fetchUserDetailsRequestState:
    UserSelectors.getFetchUserDetailsRequestState(state),
})

UserContainer.propTypes = {
  accessGroups: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  deleteUserAccessGroups: PropTypes.func,
  addUserAccessGroups: PropTypes.func,
  selectedUser: PropTypes.object,
  fetchAccessGroups: PropTypes.func,
  fetchUserListRequestState: PropTypes.number,
  deleteUserAccessGroupsRequestState: PropTypes.number,
  addUserAccessGroupsRequestState: PropTypes.number,
  fetchUserDetailsRequestState: PropTypes.number,
  maxListHeight: PropTypes.number,
  scrollbarsHeight: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UserContainer)
