import React, { useState, useImperativeHandle, useRef } from 'react'
import Styles from './CancelConnectorReservation.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { IFDialog } from 'Components'
import Icon from '@material-ui/core/Icon'
import { EventBusyOutlined } from '@mui/icons-material'

const CancelConnectorReservation = React.forwardRef(({ onClick }, ref) => {
  const [loading, setLoading] = useState(false)
  const [connectorUid, setConnectorUid] = useState(null)
  const reserveRef = useRef()
  const { t } = useTranslation()
  const show = (connectorUid) => {
    setConnectorUid(connectorUid)
    reserveRef.current.show()
  }
  const dismiss = () => {
    reserveRef.current.dismiss()
    setLoading(false)
  }
  useImperativeHandle(ref, () => ({
    show,
    dismiss,
  }))
  return (
    <IFDialog
      titleStyle={{ fontSize: '1.625rem', fontWeight: '600' }}
      dialogWidth="30rem"
      ref={reserveRef}
      title={t('CancelConnectorReservation.Title')}
      subtitle={t('CancelConnectorReservation.Subtitle')}
      actionButtonIcon={
        <Icon
          sx={{ width: '18px !important', height: '18px !important' }}
          component={EventBusyOutlined}
        />
      }
      actionButtonText={t('CancelConnectorReservation.ButtonText')}
      actionButtonColor={Colors.primary}
      isLoading={loading}
      onAction={() => {
        setLoading(true)
        onClick(connectorUid)
      }}
      onDismiss={() => {
        setLoading(false)
      }}
    />
  )
})
CancelConnectorReservation.propTypes = {
  onStartClick: PropTypes.func,
}

export default CancelConnectorReservation
