import React, { useEffect, useState, useMemo } from 'react'
import ReactApexChart from 'react-apexcharts'
import ApexCharts from 'apexcharts'
import { IFTooltipIconsLoading, IFText, IFsvg } from 'Components'
import { Colors } from 'Theme'
import Styles from './StationUptimeMonitor.module.css'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const StationUptimeMonitor = ({
  chartHeight = 152,
  chartWidth = '100%',
  barsHeight = '100%',
  onlineSeriesData,
  offlineSeriesData,
  unstableSeriesData,
  chartID = 'uptimeGraphChart',
  startTimestamp,
  endTimestamp,
  onlinePercentage,
  offlinePercentage,
  unstablePercentage,
  noData = false,
}) => {
  const { t } = useTranslation()
  const seriesColors = [
    Colors.StationUptimeStatus.Online,
    Colors.StationUptimeStatus.Offline,
    Colors.StationUptimeStatus.Unstable,
  ]
  const [series, setSeries] = useState([
    {
      name: 'Online',
      data: onlineSeriesData,
    },
    {
      name: 'Offline',
      data: offlineSeriesData,
    },
    {
      name: 'Unstable',
      data: unstableSeriesData,
    },
  ])
  const [options, setOptions] = useState({
    chart: {
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 1,
        animateGradually: {
          enabled: false,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      id: chartID,
      type: 'rangeBar',
      events: {
        beforeResetZoom: function (chartContext, opts) {
          return {
            xaxis: {
              min: startTimestamp,
              max: endTimestamp,
            },
          }
        },
      },
      selection: {
        enabled: true,
      },
      autoSelected: 'zoom',
      toolbar: {
        show: false,
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        rangeBarGroupRows: true,
        barHeight: barsHeight,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: seriesColors,
    xaxis: {
      type: 'datetime',
      min: startTimestamp,
      max: endTimestamp,
      labels: {
        style: {
          fontSize: '0.75rem',
          textTransform: 'none',
          marginBottom: '0.125rem',
          width: '100%',
          fontFamily: 'ProximaNova',
        },
      },
    },
    yaxis: {
      show: false,
      floating: true,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    tooltip: {
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      theme: 'dark',
      x: {
        show: true,
        format: 'MMM dd, HH:mm:ss',
      },
      marker: {
        show: true,
      },
    },
    legend: {
      position: 'top',
      fontSize: '14px',
      fontWeight: '600',
      fontFamily: 'ProximaNova',
      markers: {
        width: 16,
        height: 16,
        radius: 2,
      },
      itemMargin: {
        vertical: 4,
      },
      formatter: function (seriesName, opts) {
        switch (seriesName) {
          case 'Online':
            return `‎ ${seriesName} ( ${onlinePercentage}% )‎ `
          case 'Offline':
            return `‎ ${seriesName} ( ${offlinePercentage}% )‎ `
          default:
            return `‎ ${seriesName} ( ${unstablePercentage}% )‎ `
        }
      },
    },
  })
  const [enablePanning, setEnablePanning] = useState(false)
  const [cursor, setCursor] = useState('auto')

  useEffect(() => {
    const chart = ApexCharts.getChartByID(chartID)
    chart.w.globals.panEnabled = enablePanning ? true : false
  }, [enablePanning])

  const memoizedKeyDown = useMemo(
    () => (event) => {
      if (event.shiftKey && !enablePanning) {
        setCursor('grab')
        setEnablePanning(true)
      }
    },
    [enablePanning],
  )
  const memoizedKeyUp = useMemo(
    () => (event) => {
      if (event.key == 'Shift') {
        setCursor('auto')
        setEnablePanning(false)
      }
    },
    [],
  )

  useEffect(() => {
    window.addEventListener('keydown', memoizedKeyDown)
    window.addEventListener('keyup', memoizedKeyUp)
    return () => {
      window.removeEventListener('keydown', memoizedKeyDown)
      window.removeEventListener('keyup', memoizedKeyUp)
    }
  }, [])

  const resetButtonOnClick = () => {
    const chart = ApexCharts.getChartByID(chartID)
    chart.resetSeries()
  }

  return (
    <div id="chart" className={Styles.WrapperContainer}>
      <div className={Styles.MapHeader}>
        <IFText className={Styles.StatisticsSubTitle}>
          {t('StationUptimeMonitor.Title')}
        </IFText>
        <IFTooltipIconsLoading
          onClick={() => resetButtonOnClick()}
          tooltipPlacement={'left'}
          title={t('StationUptimeMonitor.ResetButtonText')}
          Icon={IFsvg.Undo}
          FilledIcon={IFsvg.Undo}
          isLoading={false}
          size={26}
        />
      </div>

      <div className={Styles.GraphContainer} style={{ cursor: cursor }}>
        <ReactApexChart
          options={options}
          series={series}
          type="rangeBar"
          height={chartHeight}
          width={chartWidth}
          className={noData ? Styles.OverlayTest : ''}
        />
        {noData ? (
          <IFText className={Styles.OverlayText}>
            {t('StationUptimeMonitor.NoData')}
          </IFText>
        ) : null}
      </div>
    </div>
  )
}

StationUptimeMonitor.propTypes = {
  chartHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barsHeight: PropTypes.string,
  onlineSeriesData: PropTypes.array,
  offlineSeriesData: PropTypes.array,
  unstableSeriesData: PropTypes.array,
  chartID: PropTypes.string,
  startTimestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
  endTimestamp: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.number,
  ]),
}

export default StationUptimeMonitor
