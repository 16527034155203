import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import Styles from './AlertList.module.css'
import RequestState from 'Enums/RequestState'
import { CustomScrollbar, AlertItem, AlertItemSkeleton } from 'Components'
import { Virtuoso } from 'react-virtuoso'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import { useTranslation } from 'react-i18next'

const AlertList = ({
  combinedAlerts,
  fetchChargePointSubscribedAlertsRequestState,
  chargePoint,
  isEdit,
  onIconClick,
  onToggle,
  fetchChargePointAlertsRequestState,
}) => {
  const { t } = useTranslation()

  const isChargePointIncluded = (alertItem) => {
    return !alertItem.exclude?.includes(chargePoint.uid)
  }

  return (
    <div className={Styles.ListContainer}>
      {fetchChargePointSubscribedAlertsRequestState === RequestState.LOADING ||
      fetchChargePointAlertsRequestState === RequestState.LOADING ? (
        <div>
          {[...Array(5)].map((e, index) => {
            return (
              <div className={Styles.ItemContainer}>
                <div className={Styles.Item}>
                  <AlertItemSkeleton key={index} />
                </div>
              </div>
            )
          })}
        </div>
      ) : combinedAlerts.length === 0 ? (
        <EmptySpecs title={t('AlertModal.NoAlertsAvailable')} />
      ) : (
        <Virtuoso
          data={combinedAlerts}
          increaseViewportBy={480}
          itemContent={(index, alert) => {
            return (
              <div className={Styles.ItemContainer}>
                <div className={Styles.Item}>
                  <AlertItem
                    key={index}
                    name={alert.name}
                    trackedStatus={alert.trackedStatus}
                    duration={alert.duration}
                    percentageAffected={alert.percentageAffected}
                    isIncluded={isChargePointIncluded(alert)}
                    isGlobal={alert.isGlobal}
                    isEdit={isEdit}
                    onIconClick={(value) => onIconClick(value, alert._id)}
                    onToggle={(value) => {
                      onToggle(value, alert._id)
                    }}
                    disableHover={false}
                  />
                </div>
              </div>
            )
          }}
          components={{ Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    updateChargePointConfig: (chargePointId, key, value, onSucess, onFail) =>
      dispatch(
        ChargePointActions.updateChargePointConfig(
          chargePointId,
          key,
          value,
          onSucess,
          onFail,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  fetchChargePointSubscribedAlertsRequestState:
    ChargePointSelectors.getFetchChargePointSubscribedAlertsRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  fetchChargePointAlertsRequestState:
    ChargePointSelectors.getFetchChargePointAlertsRequestState(state),
})

AlertList.propTypes = {
  combinedAlerts: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointSubscribedAlertsRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  isEdit: PropTypes.bool,
  onIconClick: PropTypes.func,
  onToggle: PropTypes.func,
  fetchChargePointAlertsRequestState: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertList)
