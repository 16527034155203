import PropTypes from 'prop-types'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars-2'

const IFScrollbars = ({
  children,
  className,
  style,
  disableScroll,
  ...props
}) => {
  return disableScroll ? (
    <div {...props} style={style} className={className}>
      {children}
    </div>
  ) : (
    <Scrollbars {...props} style={style} className={className}>
      {children}
    </Scrollbars>
  )
}

IFScrollbars.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default IFScrollbars
