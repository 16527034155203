import Styles from './StationConnectorMapComponent.module.css'
import { IFText } from 'Components'
import icons from '../../Theme/Icons'
import InfinityEnums from '../../Enums/InfinityEnums'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
const { ConnectorPowerLevel } = InfinityEnums

const StationConnectorMapComponent = ({ connector, isChargePointOnline }) => {
  const handleType = (type) => {
    switch (type) {
      case InfinityEnums.ConnectorType.CHADEMO:
        return {
          icon: icons.chademo,
          name: 'Chademo',
          style: Styles.ChademoStyle,
        }
      case InfinityEnums.ConnectorType.TYPE_2:
        return {
          icon: icons.type2,
          name: 'Type 2',
          style: Styles.Type2Style,
        }
      case InfinityEnums.ConnectorType.COMBO_CCS:
        return {
          icon: icons.comboCCS,
          name: 'Combo CCS',
          style: Styles.ComboCCSStyle,
        }
      case InfinityEnums.ConnectorType.SCHUKO:
        return {
          icon: icons.schuko,
          name: 'Schuko',
          style: Styles.SchukoStyle,
        }
      default:
        return null
    }
  }

  const connectorTypeFormatted = handleType(connector.type)

  const getIconColor = () => {
    if (!isChargePointOnline) {
      return Colors.OfflineConnector
    }
    let iconColor = Colors.AvailableConnector
    if (connector.status !== InfinityEnums.ConnectorStatus.AVAILABLE) {
      switch (connector.status) {
        case InfinityEnums.ConnectorStatus.UNAVAILABLE:
        case InfinityEnums.ConnectorStatus.FAULTED:
          iconColor = Colors.UnAvailableConnector
          break
        case InfinityEnums.ConnectorStatus.PREPARING:
        case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
        case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
        case InfinityEnums.ConnectorStatus.RESERVED:
          iconColor = Colors.SuspendedConnector
          break
        case InfinityEnums.ConnectorStatus.CHARGING:
        case InfinityEnums.ConnectorStatus.FINISHING:
          iconColor = Colors.ChargingConnector
          break
      }
    }
    return iconColor
  }

  return (
    <div
      className={Styles.Connector}
      style={{
        border: `1px solid ${Colors.ConnectorComponentBorder}`,
        backgroundColor: Colors.white,
      }}
    >
      <div
        className={Styles.ChargerSpeedContainer}
        style={{ backgroundColor: Colors.ChargerSpeedContainerBackground }}
      >
        <div className={Styles.ConnectorSpeedIconContainer}>
          {connector.maxPower <= ConnectorPowerLevel.LEVEL1 ? (
            <div className={Styles.FlashIcon}>{icons.flash}</div>
          ) : connector.maxPower <= ConnectorPowerLevel.LEVEL2 ? (
            [...Array(2)].map((e, index) => {
              return (
                <div key={`flashComp-${index}`} className={Styles.FlashIcon}>
                  {icons.flash}
                </div>
              )
            })
          ) : (
            [...Array(3)].map((e, index) => {
              return (
                <div key={`flashComp-${index}`} className={Styles.FlashIcon}>
                  {icons.flash}
                </div>
              )
            })
          )}
        </div>

        <div className={Styles.DividedText}>
          <IFText className={Styles.killowattNumberText}>
            {connector.maxPower}
          </IFText>
          <IFText className={Styles.KiloWattText}> {'kW'}</IFText>
        </div>
      </div>
      <div className={Styles.ChargerTypeContainer}>
        <img
          src={connectorTypeFormatted.icon}
          alt={'ChargeIcon'}
          className={connectorTypeFormatted.style}
        />
        <IFText className={Styles.ConnectorText}>
          {connectorTypeFormatted.name}
        </IFText>
      </div>

      <IFText
        className={Styles.ConnectorStatusText}
        style={{ color: getIconColor() }}
      >
        {isChargePointOnline
          ? connector.status
          : InfinityEnums.ConnectorStatus.OFFLINE}
      </IFText>
    </div>
  )
}

StationConnectorMapComponent.propTypes = {
  connector: PropTypes.object,
}

export default StationConnectorMapComponent
