import Styles from './AccessGroupListItemSkeleton.module.css'
import { IFSkeleton } from 'Components'

const AccessGroupListItemSkeleton = ({}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        <div className={Styles.TextContainer}>
          <IFSkeleton className={Styles.Name} variant="text" />
        </div>
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.UsersCountContainer}>
          <IFSkeleton
            className={Styles.Price}
            variant="circular"
            width="1.2rem"
            height="1.2rem"
          />
        </div>
      </div>
    </div>
  )
}

export default AccessGroupListItemSkeleton
