import Colors from 'Theme/Colors'
import Styles from './ChargerSpecsEdit.module.css'
import React, {
  useImperativeHandle,
  useRef,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import {
  AccessGroupsContainer,
  SubscriptionContainer,
  ScheduleContainer,
} from 'Containers'
import { IFText } from 'Components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import InfinityEnums from 'Enums/InfinityEnums'
import { connect } from 'react-redux'
import TenantUserActions from 'Stores/TenantUser/Actions'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import PropTypes from 'prop-types'

const ListContainer = styled.div`
  box-shadow: 0px 0px 4px ${Colors.ChargePointSpecsEdit.ListBoxShadow};
  border-radius: 5px;
`

const ChargerSpecsEdit = forwardRef(
  (
    {
      className,
      isActivated,
      chargePointId,
      fetchChargePointSubscriptionsList,
      fetchSubscriptionsList,
      fetchChargePointPrivacyScheduleList,
      fetchPrivacySchedule,
      fetchChargePointAccessGroups,
      fetchAccessGroups,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const refDiv = useRef()
    const chargePointSubscriptionsRef = useRef()
    const chargePointScheduleRef = useRef()
    const chargePointAccessGroupsRef = useRef()
    const [height, setHeight] = useState(0)

    const handleCancel = () => {
      chargePointSubscriptionsRef?.current?.handleCancel()
      chargePointScheduleRef?.current?.handleCancel()
      chargePointAccessGroupsRef?.current?.handleCancel()
    }
    const handleSubmit = () => {
      chargePointSubscriptionsRef?.current?.handleSubmit(false)
      chargePointScheduleRef?.current?.handleSubmit(false)
      chargePointAccessGroupsRef?.current?.handleSubmit(false)
    }

    const didChange = () => {
      const subscriptionsChanged =
        chargePointSubscriptionsRef?.current?.didChange()
      const scheduleChanged = chargePointScheduleRef?.current?.didChange()
      const accessGroupsChanged =
        chargePointAccessGroupsRef?.current?.didChange()
      return subscriptionsChanged || scheduleChanged || accessGroupsChanged
    }

    useImperativeHandle(ref, () => ({
      handleCancel,
      handleSubmit,
      didChange,
    }))

    useEffect(() => {
      // max list height container height - padding,title,add button
      setHeight(refDiv.current.getBoundingClientRect().height - 140)
    }, [refDiv])

    useEffect(() => {
      if (!isActivated && chargePointId) {
        fetchChargePointSubscriptionsList(chargePointId)
        fetchSubscriptionsList()

        fetchChargePointPrivacyScheduleList(chargePointId)
        fetchPrivacySchedule()

        fetchChargePointAccessGroups(chargePointId)
        fetchAccessGroups()
      }
    }, [isActivated])

    return (
      <div className={`${Styles.Container} ${className}`} ref={refDiv}>
        <div className={Styles.ListTitleContainer}>
          <div className={Styles.TitleContainer}>
            <IFText className={Styles.Title} style={{ color: Colors.black }}>
              {t('ChargePointSpecsEdit.Subscriptions')}
            </IFText>
          </div>
          <ListContainer className={Styles.SubscriptionList}>
            <SubscriptionContainer
              key={'ChargerSpecsEdit ChargePointSubscriptionList'}
              ref={chargePointSubscriptionsRef}
              renderFor={InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT}
              isEditing
              maxListHeight={height}
            />
          </ListContainer>
        </div>

        <div className={Styles.ListTitleContainer}>
          <div className={Styles.TitleContainer}>
            <IFText className={Styles.Title} style={{ color: Colors.black }}>
              {t('ChargePointSpecsEdit.AccessGroups')}
            </IFText>
          </div>
          <ListContainer className={Styles.AccessGroupList}>
            <AccessGroupsContainer
              renderFor={InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT}
              ref={chargePointAccessGroupsRef}
              isEditing={true}
              maxListHeight={height}
            />
          </ListContainer>
        </div>
        <div className={Styles.ListTitleContainer}>
          <div className={Styles.TitleContainer}>
            <IFText className={Styles.Title} style={{ color: Colors.black }}>
              {t('ChargePointSpecsEdit.Schedule')}
            </IFText>
          </div>
          <ListContainer className={Styles.ScheduleList}>
            <ScheduleContainer
              ref={chargePointScheduleRef}
              isEditing
              maxListHeight={height}
            />
          </ListContainer>
        </div>
      </div>
    )
  },
)

ChargerSpecsEdit.propTypes = {
  className: PropTypes.object,
  isActivated: PropTypes.bool,
  chargePointId: PropTypes.string,
  fetchChargePointSubscriptionsList: PropTypes.func,
  fetchSubscriptionsList: PropTypes.func,
  fetchChargePointPrivacyScheduleList: PropTypes.func,
  fetchPrivacySchedule: PropTypes.func,
  fetchChargePointAccessGroups: PropTypes.func,
  fetchAccessGroups: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointSubscriptionsList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointSubscriptionsList(chargePointId),
      ),
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),

    fetchPrivacySchedule: () =>
      dispatch(TenantUserActions.fetchPrivacySchedule()),
    fetchChargePointPrivacyScheduleList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointPrivacyScheduleList(chargePointId),
      ),

    fetchChargePointAccessGroups: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointAccessGroups(chargePointId)),
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

export default connect(null, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargerSpecsEdit)
