import React from 'react'
import Styles from './NotFound.module.css'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import IFButton from 'Components/IFButton/IFButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import history from 'history/browser'

const NotFound = () => {
  let navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.container}>
        <div>
          <IFText
            style={{
              backgroundImage: `linear-gradient(
                -225deg,
                ${Colors.primary} 0%,
                ${Colors.primary + Colors.HexTransparent.T90} 25%,
                ${Colors.primary + Colors.HexTransparent.T70} 50%,
                ${Colors.primary + Colors.HexTransparent.T90} 75%,
                ${Colors.primary} 100%
                )`,
            }}
            className={Styles.animateCharacter}
          >
            {t('NotFound.404')}
          </IFText>
        </div>
        <div>
          <IFText className={Styles.title} style={{ color: Colors.black }}>
            {t('NotFound.NotFound')}
          </IFText>
        </div>
        <div>
          <IFText
            style={{
              color: Colors.primary + Colors.HexTransparent.T70,
            }}
            className={Styles.subTitle}
          >
            {t('NotFound.RequestNotFound')}
            <br />
            {t('NotFound.PleaseGoBack')}
          </IFText>
        </div>
        <div className={Styles.buttonContainer}>
          <IFButton
            color={Colors.primary}
            isFill
            text={t('NotFound.GoHome')}
            onClick={() => {
              navigate('/sites')
              history.push({ pathname: '/sites' })
            }}
          ></IFButton>
        </div>
      </div>
    </div>
  )
}

export default NotFound
