import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Styles from './ConnectorSelectPicker.module.css'
import InfinityEnums from 'Enums/InfinityEnums'
import { SelectPicker } from 'rsuite'
import { IFText } from 'Components'
import { Colors, Icons } from 'Theme'

const handleType = (type) => {
  switch (type) {
    case InfinityEnums.ConnectorType.CHADEMO:
      return {
        icon: Icons.chademo,
        name: 'Chademo',
        style: Styles.ChademoStyle,
      }
    case InfinityEnums.ConnectorType.TYPE_2:
      return {
        icon: Icons.type2,
        name: 'Type 2',
        style: Styles.Type2Style,
      }
    case InfinityEnums.ConnectorType.COMBO_CCS:
      return {
        icon: Icons.comboCCS,
        name: 'Combo CCS',
        style: Styles.ComboCCSStyle,
      }
    case InfinityEnums.ConnectorType.SCHUKO:
      return {
        icon: Icons.schuko,
        name: 'Schuko',
        style: Styles.SchukoStyle,
      }
    default:
      return null
  }
}

const ConnectorSelectPicker = ({
  data,
  onValueChange = () => {},
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue)

  const options = []
  for (const index in data) {
    const value = handleType(data[index])
    options.push({
      label: (
        <div className={Styles.Container}>
          <div className={Styles.IconContainer}>
            <img className={value.style} src={value.icon} alt="" />
          </div>
          <IFText style={{ color: Colors.text }} className={Styles.Text}>
            {value.name}
          </IFText>
        </div>
      ),
      value: data[index],
    })
  }

  return (
    <SelectPicker
      className={Styles.SelectPicker}
      appearance="subtle"
      value={value}
      onSelect={(value) => {
        setValue(value)
        onValueChange(value)
      }}
      data={options}
      cleanable={false}
      size="sm"
    />
  )
}

ConnectorSelectPicker.propTypes = {
  data: PropTypes.array.isRequired,
  onValueChange: PropTypes.func,
  initialValue: PropTypes.string,
}

export default ConnectorSelectPicker
