import React, { useEffect, forwardRef, useState } from 'react'
import { connect } from 'react-redux'
import UserActions from 'Stores/User/Actions'
import UserSelectors from 'Stores/User/Selectors'
import RequestState from 'Enums/RequestState'
import { UserListItem, LocalListAdd } from 'Components'
import { usersFilter } from 'Constants/Filters'
import Variant from 'Enums/Variant'

const UsersTokenSearchBar = forwardRef(
  ({
    localListFilteredUsers,
    fetchLocalListFilteredUsersRequestState,
    localListFilteredUsersPaginationOffset,
    fetchLocalListFilteredUsers,
    clearLocalListFilteredUsers,
    onSelect = () => {},
  }) => {
    const [filter, setFilter] = useState(
      JSON.parse(JSON.stringify(usersFilter)),
    )
    useEffect(() => {
      fetchLocalListFilteredUsers(
        filter,
        localListFilteredUsersPaginationOffset,
        () => {},
      )
    }, [])

    useEffect(() => {
      return () => {
        clearLocalListFilteredUsers()
      }
    }, [])
    useEffect(() => {
      clearLocalListFilteredUsers()
      fetchLocalListFilteredUsers(filter, 0, () => {})
    }, [filter])

    const loadMoreData = () => {
      if (
        fetchLocalListFilteredUsersRequestState === RequestState.LOADING ||
        localListFilteredUsersPaginationOffset === null
      )
        return

      fetchLocalListFilteredUsers(
        filter,
        localListFilteredUsersPaginationOffset,
        () => {},
      )
    }
    return (
      <div>
        <LocalListAdd
          data={
            fetchLocalListFilteredUsersRequestState === RequestState.LOADING
              ? [
                  ...localListFilteredUsers.map((user) => {
                    return {
                      ...user,
                      email: user.appUserId,
                      userExists: true,
                      backgroundHover: 'inherit',
                      variant: Variant.LOCALLIST,
                    }
                  }),

                  ...(localListFilteredUsersPaginationOffset === 0
                    ? [...Array(7)].map((e, index) => {
                        return {
                          isLoading: true,
                          name: 'skeleton',
                          variant: Variant.LOCALLIST,
                        }
                      })
                    : [...Array(2)].map(() => {
                        return {
                          isLoading: true,
                          name: 'skeleton',
                          variant: Variant.LOCALLIST,
                        }
                      })),
                ]
              : localListFilteredUsers.map((user) => {
                  return {
                    ...user,
                    email: user.appUserId,
                    userExists: true,
                    backgroundHover: 'inherit',
                    variant: Variant.LOCALLIST,
                  }
                })
          }
          onSelect={(value) => {
            onSelect?.(value)
          }}
          RenderMenuItem={UserListItem}
          RenderMenuItemkeys={[
            'name',
            'count',
            'email',
            'isActive',
            'userExists',
            'appUserId',
            'isLoading',
            'backgroundHover',
            'variant',
            'chargingTokensCount',
          ]}
          onSearch={(searchKeyword) => {
            let newFilter = [...filter]
            newFilter[0].value = [searchKeyword]
            setFilter(newFilter)
          }}
          isLoading={
            fetchLocalListFilteredUsersRequestState === RequestState.LOADING
          }
          loadMoreData={() => {
            loadMoreData()
          }}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchLocalListFilteredUsers: (filter, offset, onNotFound) =>
      dispatch(
        UserActions.fetchLocalListFilteredUsers(filter, offset, onNotFound),
      ),
    clearLocalListFilteredUsers: () =>
      dispatch(UserActions.clearLocalListFilteredUsers()),
  }
}

const mapStateToProps = (state) => ({
  localListFilteredUsersPaginationOffset:
    UserSelectors.getLocalListFilteredUsersPaginationOffset(state),
  fetchLocalListFilteredUsersRequestState:
    UserSelectors.getFetchLocalListFilteredUsersRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UsersTokenSearchBar)
