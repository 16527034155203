import { IFSkeleton } from 'Components'
import React from 'react'
import Styles from './ConnectorListItemSkeleton.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'

const Container = styled.div`
  box-shadow: 0px 0px 2px ${Colors.BoxShadowColor};
`

const MaxPowerContainer = styled.div`
  background-color: ${Colors.MaxPowerBackground};
`

const StatusContainer = styled.div`
  background-color: ${Colors.GreyBackground};
  color: ${(props) => props.status};
`

const LastUpdatedContainer = styled.div`
  border: 2px solid ${Colors.GreyBackground};
  background-color: ${Colors.white};
`

const ConnectorListItemSkeleton = ({ editPermission }) => {
  return (
    <Container className={Styles.Container}>
      <MaxPowerContainer className={Styles.MaxPowerContainer}>
        <IFSkeleton variant="text" width="2.5rem" height="2.25rem" />
        <IFSkeleton variant="text" width="3.5rem" height="1.75rem" />
      </MaxPowerContainer>
      <div className={Styles.LeftDetailsContainer}>
        <div className={Styles.IconContainer}>
          <IFSkeleton variant="circular" width="1.5rem" height="1.5rem" />
        </div>
        <div className={Styles.UIDContainer}>
          <IFSkeleton variant="text" width="3rem" height="1.5rem" />
        </div>
      </div>
      <div
        className={
          editPermission
            ? Styles.MiddleContentContainer
            : Styles.MiddleContentContainerNoPermissions
        }
      >
        <div className={Styles.StatusContainerWrapper}>
          <StatusContainer className={Styles.StatusContainer}>
            <IFSkeleton variant="text" width="4rem" height="1.25rem" />
          </StatusContainer>
          <LastUpdatedContainer className={Styles.LastUpdatedContainer}>
            <IFSkeleton variant="text" width="2rem" height="1.25rem" />
          </LastUpdatedContainer>
        </div>
      </div>
      {editPermission ? (
        <div className={Styles.ActionsContainer}>
          <div className={Styles.RightDetailsSkeleton}>
            <IFSkeleton variant="text" width="5.5rem" height="1.5rem" />
          </div>
        </div>
      ) : null}
    </Container>
  )
}

export default ConnectorListItemSkeleton
