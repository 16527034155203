import React from 'react'
import Styles from './ChargingTokenListItemSkeleton.module.css'
import { useTranslation } from 'react-i18next'
import { IFSkeleton } from 'Components'

const ChargingTokenListItemSkeleton = ({}) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        <IFSkeleton variant="text" width="5rem" height="1.2rem" />
        <IFSkeleton variant="text" width="8rem" height="1.2rem" />
        <IFSkeleton variant="text" width="8rem" height="1.2rem" />
      </div>
      <div className={Styles.RightContainer}>
        <IFSkeleton variant="text" width="5rem" height="1.2rem" />
        <IFSkeleton variant="circular" width="1.2rem" height="1.2rem" />
      </div>
    </div>
  )
}

export default ChargingTokenListItemSkeleton
