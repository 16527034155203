import React, { useImperativeHandle, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Styles from './AccessGroupListItem.module.css'
import { IFText, IFsvg } from 'Components'
import { Colors } from 'Theme'
import { useState } from 'react'
import { DoNotDisturbOn, AddCircle } from '@mui/icons-material'
import Tooltip from '@material-ui/core/Tooltip'
import { isOverflowActive } from 'Utils/Calculations'
import Fade from '@mui/material/Fade'
import { withStyles } from '@material-ui/styles'
import { isPropsMatch } from 'Utils/PropsMatch'

const StyledOverflowTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '-1.55rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
    width: 'max-content',
    maxWidth: '100%',
    marginLeft: '-0.38rem',
    paddingRight: '0.6rem',
  },
})(Tooltip)

const AccessGroupListItem = ({
  name,
  userCount,
  isEditing,
  onIconClick = () => {},
}) => {
  const [isRemoveIcon, setIsRemoveIcon] = useState(true)
  const textRef = useRef()

  useEffect(() => {
    if (!isEditing) setIsRemoveIcon(true)
  }, [isEditing])

  return (
    <div className={Styles.Container}>
      {isEditing ? (
        <div
          className={Styles.IconContainer}
          onClick={() => {
            onIconClick()
            setIsRemoveIcon((prev) => {
              return !prev
            })
          }}
        >
          {!isRemoveIcon ? (
            <AddCircle
              className={Styles.Icon}
              style={{
                color: Colors.ChargePointSubscriptionListItem.AddIcon,
              }}
            />
          ) : (
            <DoNotDisturbOn
              className={Styles.Icon}
              style={{
                color: Colors.ChargePointSubscriptionListItem.RemoveIcon,
              }}
            />
          )}
        </div>
      ) : null}
      {isOverflowActive(textRef.current) ? (
        <StyledOverflowTooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 60 }}
          title={
            <div>
              <IFText
                className={Styles.ToolTipOverflownText}
                style={
                  isRemoveIcon
                    ? { color: Colors.text }
                    : { color: Colors.ChargePointSpecsGreyedOut }
                }
              >
                {name}
              </IFText>
            </div>
          }
          placement="bottom-start"
        >
          <div className={Styles.NameContainer}>
            <IFText
              ref={textRef}
              className={Styles.Text}
              style={
                isRemoveIcon
                  ? { color: Colors.text }
                  : { color: Colors.ChargePointSpecsGreyedOut }
              }
            >
              {name}
            </IFText>
          </div>
        </StyledOverflowTooltip>
      ) : (
        <div className={Styles.NameContainer}>
          <IFText
            ref={textRef}
            className={Styles.Text}
            style={
              isRemoveIcon
                ? { color: Colors.text }
                : { color: Colors.ChargePointSpecsGreyedOut }
            }
          >
            {name}
          </IFText>
        </div>
      )}

      <div className={Styles.UsersCountContainer}>
        <IFText
          style={
            isRemoveIcon
              ? { color: Colors.text }
              : { color: Colors.ChargePointSpecsGreyedOut }
          }
        >
          {userCount?.toLocaleString()}
        </IFText>
      </div>
    </div>
  )
}

AccessGroupListItem.propTypes = {
  name: PropTypes.string,
  userCount: PropTypes.number,
  isEditing: PropTypes.bool,
  onIconClick: PropTypes.func,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'name',
    'userCount',
    'isEditing',
    'onIconClick',
  ])
}
export default React.memo(AccessGroupListItem, shouldSkipRender)
