import React, { useState, useImperativeHandle, useRef } from 'react'
import Styles from './StartTx.module.css'
import { Colors } from 'Theme'
import IFTextInput from 'Components/IFTextInput/IFTextInput'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import validator from 'validator'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { IFDialog } from 'Components'
import Icon from '@material-ui/core/Icon'
const StartTx = React.forwardRef(({ onStartClick, isUsingMobileApp }, ref) => {
  const [tokenId, setTokenId] = useState('')
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const confirmStartTx = useRef()
  const { t } = useTranslation()
  const show = () => {
    confirmStartTx.current.show()
  }
  const dismiss = () => {
    confirmStartTx.current.dismiss()
    setTokenId('')
    setError()
    setLoading(false)
  }
  const handleError = () => {
    setLoading(false)
  }
  useImperativeHandle(ref, () => ({
    show,
    dismiss,
    handleError,
  }))
  return (
    <IFDialog
      ref={confirmStartTx}
      EmailTitle
      title={t(
        isUsingMobileApp
          ? 'StartTxDialogue.EmailTitle'
          : 'StartTxDialogue.TokenTitle',
      )}
      dialogWidth="25rem"
      subtitle={
        <div className={Styles.inputContainer}>
          <IFTextInput
            className={Styles.lgInput}
            value={tokenId}
            onChange={(e) => {
              setTokenId(e.target.value)
              if (error && validator.isLength(e.target.value, { min: 14 })) {
                setError()
              }
            }}
            errorText={error}
            style={{ caretColor: Colors.primary }}
            placeholder={t(
              isUsingMobileApp
                ? 'StartTxDialogue.EmailPhonePlaceHolder'
                : 'StartTxDialogue.EgToken',
            )}
            onBlur={() => {
              if (validator.isEmpty(tokenId)) {
                setError(
                  t(
                    isUsingMobileApp
                      ? 'StartTxDialogue.EmailCanNotBeEmpty'
                      : 'StartTxDialogue.TokenCanNotBeEmpty',
                  ),
                )
                return
              }
              if (
                !(validator.isEmail(tokenId) || tokenId.match(/^[0-9]{11}$/)) &&
                isUsingMobileApp
              ) {
                setError(t('StartTxDialogue.EnterValidEMailPhone'))
                return
              }

              setError()
            }}
          />
        </div>
      }
      onAction={() => {
        setLoading(true)
        onStartClick(tokenId)
      }}
      actionButtonIcon={
        <Icon
          sx={{ width: '18px !important', height: '18px !important' }}
          component={PlayArrow}
        />
      }
      actionButtonText={t('StartTxDialogue.Start')}
      actionButtonColor={Colors.primary}
      isLoading={loading}
      isDead={
        validator.isEmpty(tokenId) ||
        (!(validator.isEmail(tokenId) || tokenId.match(/^[0-9]{11}$/)) &&
          isUsingMobileApp)
      }
    />
  )
})
StartTx.propTypes = {
  onStartClick: PropTypes.func,
}

export default StartTx
