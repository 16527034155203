import React from 'react'
import PropTypes from 'prop-types'
import Styles from './ChargePointTab.module.css'
import IFText from 'Components/IFText/IFText'
import { Colors } from 'Theme'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'
import { isPropsMatch } from 'Utils/PropsMatch'

const handleBoxColor = (status, isSelected) => {
  switch (status) {
    case InfinityEnums.ConnectorStatus.AVAILABLE:
      return {
        backgroundColor:
          Colors.AvailableConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.CHARGING:
      return {
        backgroundColor: Colors.ChargingConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.PREPARING:
      return {
        backgroundColor:
          Colors.PreparingConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
      return {
        backgroundColor:
          Colors.SuspendedConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
      return {
        backgroundColor:
          Colors.SuspendedConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.FINISHING:
      return {
        backgroundColor:
          Colors.FinishingConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.RESERVED:
      return {
        backgroundColor: Colors.ReservedConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.UNAVAILABLE:
      return {
        backgroundColor:
          Colors.UnAvailableConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.OFFLINE:
      return {
        backgroundColor: Colors.OfflineConnector + `${isSelected ? '' : '66'}`,
      }
    case InfinityEnums.ConnectorStatus.FAULTED:
      return {
        backgroundColor: Colors.FaultedConnector + `${isSelected ? '' : '66'}`,
      }
    default:
      return { backgroundColor: Colors.black }
  }
}

const ChargePointTab = ({
  name,
  connectors,
  isSelected,
  isActivated,
  isConnecting,
}) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.Container}>
      <div>
        <IFText
          style={{ color: isSelected ? Colors.text : Colors.LowOpacityText }}
          className={Styles.ChargePointName}
        >
          {name}
        </IFText>
      </div>
      <div className={Styles.ConnectorsWrapper}>
        {isActivated &&
          connectors.map((connector, index) => {
            return (
              <div
                key={index}
                style={{
                  marginRight:
                    index === connectors.length - 1 ? '0rem' : '0.75rem',
                }}
                className={Styles.ConnectorContainer}
              >
                <IFText
                  style={{
                    color: isSelected ? Colors.text : Colors.LowOpacityText,
                  }}
                  className={Styles.ConnectorsNumber}
                >
                  {connector.number}
                </IFText>
                <div
                  style={handleBoxColor(connector.status, isSelected)}
                  className={Styles.StatusBox}
                ></div>
              </div>
            )
          })}
        {!isActivated && !isConnecting && (
          <div className={Styles.ConnectorContainerInactive}>
            <IFText
              style={{
                color: isSelected ? Colors.red : Colors.LowOpacityText,
              }}
              className={Styles.inactive}
            >
              {t('ChargePointTab.Inactive')}
            </IFText>
          </div>
        )}
        {isConnecting && !isActivated && (
          <div className={Styles.ConnectingContainer}>
            <IFText
              style={{
                color: isSelected
                  ? Colors.StatusYellowText
                  : Colors.LowOpacityText,
              }}
              className={Styles.inactive}
            >
              {t('ChargePointTab.Connecting')}
            </IFText>
            <div className={Styles.spinner}>
              <div
                className={Styles.bounce1}
                style={{
                  backgroundColor: isSelected
                    ? Colors.StatusYellowText
                    : Colors.LowOpacityText,
                }}
              ></div>
              <div
                className={Styles.bounce2}
                style={{
                  backgroundColor: isSelected
                    ? Colors.StatusYellowText
                    : Colors.LowOpacityText,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: isSelected
                    ? Colors.StatusYellowText
                    : Colors.LowOpacityText,
                }}
                className={Styles.bounce3}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ChargePointTab.propTypes = {
  name: PropTypes.string,
  connectors: PropTypes.arrayOf(PropTypes.object),
  isSelected: PropTypes.bool,
  isActivated: PropTypes.bool,
  isConnecting: PropTypes.bool,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'name',
    'connectors',
    'isSelected',
    'isActivated',
    'isConnecting',
  ])
}
export default React.memo(ChargePointTab, shouldSkipRender)
