import React, { Fragment, useEffect, useState } from 'react'
import IFModal from 'Components/IFModal/IFModal'
import { IFSearchField, IFSkeleton } from 'Components'
import { ConfigurationList } from 'Components'
import Styles from './ConfigurationModal.module.css'
import { useTranslation } from 'react-i18next'
import RefreshIcon from '@mui/icons-material/Refresh'
import IFText from 'Components/IFText/IFText'
import moment from 'moment'
import { Colors } from 'Theme'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import { NoConfigurations } from 'Components/IFEmptyStates/IFEmptyStates'

const ConfigurationModal = React.forwardRef(
  (
    {
      chargePointConfigsLastUpdated,
      chargePointConfigListRequestState,
      fetchChargePointConfigList,
      chargePoints,
      selectedIndex,
      chargePointConfigList,
      ...props
    },
    ref,
  ) => {
    const [filter, setFilter] = useState('')
    const [refreshingConfigs, setRefreshingConfigs] = useState(false)
    const { t } = useTranslation()
    useEffect(() => {
      if (chargePointConfigListRequestState !== RequestState.LOADING) {
        setRefreshingConfigs(false)
      }
    }, [chargePointConfigListRequestState])

    return (
      <IFModal
        width="65rem"
        title={t('ConfigurationModal.title')}
        ref={ref}
        onClose={() => {
          setFilter('')
        }}
      >
        <Fragment>
          <div className={Styles.wrapper}>
            {(Object.keys(chargePointConfigList).length !== 0 ||
              chargePointConfigListRequestState === RequestState.LOADING) && (
              <IFSearchField
                className={Styles.searchField}
                loading={
                  chargePointConfigListRequestState === RequestState.LOADING
                }
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
                {...(filter.length > 0 ? { defaultValue: filter } : {})}
              />
            )}

            <div className={Styles.refreshContainer}>
              {chargePointConfigListRequestState === RequestState.LOADING ? (
                <div>
                  <div>
                    <IFSkeleton
                      className={Styles.refreshTextSkeleton}
                      variant="text"
                    />

                    <IFSkeleton
                      className={Styles.refreshTextDateSkeleton}
                      variant="text"
                    />
                  </div>
                </div>
              ) : (
                <div className={Styles.refreshTextContainer}>
                  <IFText
                    className={Styles.refreshText}
                    style={{ color: Colors.black }}
                  >
                    {t('ConfigurationModal.lastUpdated')}
                  </IFText>

                  <IFText className={Styles.refreshTextDate}>
                    {chargePointConfigsLastUpdated
                      ? moment(chargePointConfigsLastUpdated).format(
                          'MMM DD, YYYY hh:mm:ss',
                        )
                      : t('ConfigurationModal.notAvailable')}
                  </IFText>
                </div>
              )}
              {chargePointConfigListRequestState !== RequestState.LOADING ? (
                <div
                  className={Styles.refreshIcon}
                  onClick={() => {
                    setRefreshingConfigs(true)
                    fetchChargePointConfigList(chargePoints[selectedIndex]._id)
                  }}
                >
                  <RefreshIcon
                    className={refreshingConfigs ? Styles.refreshStart : null}
                  />
                </div>
              ) : (
                <div className={Styles.refreshIcon}>
                  <IFSkeleton
                    className={Styles.refreshIconSkeleton}
                    variant="circular"
                  />
                </div>
              )}
            </div>
          </div>
          {chargePointConfigListRequestState !== RequestState.LOADING &&
          Object.keys(chargePointConfigList).length === 0 ? (
            <div className={Styles.emptyState}>
              <NoConfigurations />
            </div>
          ) : (
            <div className={Styles.configurationList}>
              <ConfigurationList filter={filter} />
            </div>
          )}
        </Fragment>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointConfigList: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointConfigList(chargePointId)),
  }
}
const mapStateToProps = (state) => ({
  chargePointConfigList: ChargePointSelectors.getChargePointConfigs(state),
  chargePointConfigsLastUpdated:
    ChargePointSelectors.getChargePointConfigsLastUpdated(state),
  chargePointConfigListRequestState:
    ChargePointSelectors.getFetchChargePointConfigListRequestState(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
})

ConfigurationModal.propTypes = {
  chargePointConfigListRequestState: PropTypes.number,
  chargePoints: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ConfigurationModal)
