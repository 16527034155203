import React, { useState, useRef, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  NoInternet,
  IFFilter,
  FilterNoMatch,
  ListHeader,
  WelcomeDashboard,
  EmptyCards,
} from 'Components'
import {
  ChargingTokenList,
  AddChargingTokensModal,
  ChargingTokensDetails,
} from 'Containers'
import { Colors } from 'Theme'
import Styles from './ChargingTokensPage.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import LoadingBar from 'react-top-loading-bar'
import history from 'history/browser'
import { useNavigate } from 'react-router-dom'
import CardSelectors from 'Stores/Card/Selectors'
import CardActions from 'Stores/Card/Actions'

const ChargingTokensPage = ({
  fetchChargingTokensListRequestState,
  fetchChargingTokenRequestState,
  fetchChargingTokensTransactionsRequestState,
  fetchChargingTokensList,
  chargingTokensFilter,
  chargingTokensList,
  tenantUser,
  selectedChargingTokenIndex,
  setSelectedChargingTokenIndex,
  setPaginationOffset,
  setCardsTransactionPaginationOffset,
  setChargingTokensFilter,
  clearChargingTokens,
  resetChargingTokensRequestStates,
  cardsCount,
  fetchCardListCountRequestState,
}) => {
  const [filtering, setFiltering] = useState(false)
  const [empty, setEmpty] = useState(true)
  const [progress, setProgress] = useState(0)

  const addChargingTokensModalRef = useRef()
  const { t } = useTranslation()
  let navigate = useNavigate()

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }

  useEffect(() => {
    return () => {
      clearChargingTokens()
      resetChargingTokensRequestStates()
    }
  }, [])

  useEffect(() => {
    checkFilterEmpty(chargingTokensFilter)
    if (fetchChargingTokensListRequestState === RequestState.SUCCEEDED)
      if (empty) setFiltering(false)
      else setFiltering(true)
  }, [fetchChargingTokensListRequestState])

  useEffect(() => {
    fetchChargingTokensList(chargingTokensFilter, 0, () =>
      navigate('/NotFound'),
    )
  }, [chargingTokensFilter])

  useEffect(() => {
    if (
      fetchChargingTokensListRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchChargingTokensListRequestState === RequestState.SUCCEEDED)
      if (chargingTokensList.length === 0) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    if (
      fetchChargingTokensListRequestState !== RequestState.LOADING &&
      fetchChargingTokensListRequestState !== RequestState.UNINITIALIZED &&
      fetchChargingTokensListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchChargingTokensListRequestState])

  useEffect(() => {
    if (
      fetchChargingTokenRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchChargingTokenRequestState === RequestState.SUCCEEDED) {
      if (selectedChargingTokenIndex === -1) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    }
    if (
      fetchChargingTokenRequestState !== RequestState.LOADING &&
      fetchChargingTokenRequestState !== RequestState.UNINITIALIZED &&
      fetchChargingTokenRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchChargingTokenRequestState])

  useEffect(() => {
    if (
      fetchChargingTokensTransactionsRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (
      fetchChargingTokensTransactionsRequestState === RequestState.SUCCEEDED
    ) {
      setProgress(100)
    }
    if (
      fetchChargingTokensTransactionsRequestState !== RequestState.LOADING &&
      fetchChargingTokensTransactionsRequestState !==
        RequestState.UNINITIALIZED &&
      fetchChargingTokensTransactionsRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchChargingTokensTransactionsRequestState])

  const handleChargingTokensFilter = (newFilter) => {
    setSelectedChargingTokenIndex(-1)
    setPaginationOffset(0)
    setCardsTransactionPaginationOffset(0)
    history.push({ pathname: '/cards' })
    setChargingTokensFilter(newFilter)
    clearChargingTokens()
    checkFilterEmpty(newFilter, empty)
  }
  return (
    <div className={Styles.ChargingTokensContainer}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      {fetchChargingTokensListRequestState === RequestState.ERROR_0_NETWORK ? (
        <NoInternet
          onClick={() => {
            fetchChargingTokensList(chargingTokensFilter, 0, () =>
              navigate('/NotFound'),
            )
          }}
        />
      ) : (
        <Fragment>
          <div className={Styles.filterContainer}>
            <div
              className={Styles.filter}
              style={{
                backgroundColor: Colors.white,
                boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
              }}
            >
              <IFFilter
                key={'CardsPageIFFilter'}
                onFilterChange={(newFilter) => {
                  handleChargingTokensFilter(newFilter)
                }}
                filters={chargingTokensFilter}
                textFieldPlaceholder={t('CardsPage.FilterPlaceholder')}
              />
            </div>
          </div>
          {chargingTokensList.length === 0 &&
          filtering === true &&
          fetchChargingTokensListRequestState !== RequestState.LOADING ? (
            <FilterNoMatch
              title={t('emptyStates.noCardsHere')}
              subtitle={t('emptyStates.filterNoCards')}
            />
          ) : (
            <div className={Styles.ListDetailsContainer}>
              <div
                className={Styles.ChargingTokensList}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                <ListHeader
                  count={cardsCount}
                  title={t('CardsPage.ChargingTokens')}
                  onAddClick={() => {
                    addChargingTokensModalRef.current.handleOpen()
                  }}
                  isLoading={
                    fetchCardListCountRequestState === RequestState.LOADING
                  }
                  addPermission={tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING_TOKEN,
                  )}
                />
                {chargingTokensList.length === 0 &&
                fetchChargingTokensListRequestState ===
                  RequestState.SUCCEEDED ? (
                  <EmptyCards />
                ) : (
                  <ChargingTokenList />
                )}
              </div>
              <div
                className={Styles.ChargingTokenDetailsWrapper}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                {chargingTokensList.length === 0 &&
                fetchChargingTokensListRequestState ===
                  RequestState.SUCCEEDED ? (
                  <WelcomeDashboard
                    username={tenantUser.name}
                    displayedText={t('emptyStates.newChargingToken')}
                  />
                ) : (
                  <ChargingTokensDetails />
                )}
              </div>
            </div>
          )}
        </Fragment>
      )}
      <AddChargingTokensModal ref={addChargingTokensModalRef} />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setSelectedChargingTokenIndex: (index) =>
      dispatch(CardActions.setCardSelectedIndex(index)),
    setPaginationOffset: (offset) =>
      dispatch(CardActions.setPaginationOffset(offset)),
    setCardsTransactionPaginationOffset: (offset) =>
      dispatch(CardActions.setCardsTransactionPaginationOffset(offset)),
    setChargingTokensFilter: (filter) =>
      dispatch(CardActions.setCardsFilter(filter)),
    fetchChargingTokensList: (filter, offset, onNotFound) =>
      dispatch(CardActions.fetchCardList(filter, offset, onNotFound)),
    clearChargingTokens: () => dispatch(CardActions.clearCards()),
    resetChargingTokensRequestStates: () =>
      dispatch(CardActions.resetCardRequestStates()),
  }
}

const mapStateToProps = (state) => ({
  fetchChargingTokensListRequestState:
    CardSelectors.getFetchCardListRequestState(state),
  fetchChargingTokenRequestState: CardSelectors.getFetchCardRequestState(state),
  fetchChargingTokensTransactionsRequestState:
    CardSelectors.getFetchCardTransactionsRequestState(state),
  chargingTokensFilter: CardSelectors.getCardsFilter(state),
  chargingTokensList: CardSelectors.getCards(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  selectedChargingTokenIndex: CardSelectors.getSelectedIndex(state),
  cardsCount: CardSelectors.getCardsCount(state),
  fetchCardListCountRequestState:
    CardSelectors.getFetchCardListCountRequestState(state),
})

ChargingTokensPage.propTypes = {
  fetchChargingTokensListRequestState: PropTypes.number,
  fetchChargingTokenRequestState: PropTypes.number,
  fetchChargingTokensTransactionsRequestState: PropTypes.number,
  fetchChargingTokensList: PropTypes.func,
  chargingTokensFilter: PropTypes.arrayOf(PropTypes.object),
  chargingTokensList: PropTypes.arrayOf(PropTypes.object),
  tenantUser: PropTypes.object,
  selectedChargingTokenIndex: PropTypes.number,
  setSelectedChargingTokenIndex: PropTypes.func,
  setPaginationOffset: PropTypes.func,
  setCardsTransactionPaginationOffset: PropTypes.func,
  setChargingTokensFilter: PropTypes.func,
  clearChargingTokens: PropTypes.func,
  resetChargingTokensRequestStates: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChargingTokensPage)
