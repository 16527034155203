import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { useCallback } from 'react'

const CustomScrollbar = React.forwardRef(
  ({ style, children, ...props }, ref) => {
    const refSetter = useCallback(
      (scrollbarsRef) => {
        if (scrollbarsRef) {
          let customViewport = scrollbarsRef.osInstance().getElements().viewport
          for (const key in props) {
            if (!props.hasOwnProperty(key)) {
              continue
            }
            customViewport.setAttribute(key, props[key])
          }
          ref.current = customViewport
        }
      },
      [ref],
    )

    return (
      <OverlayScrollbarsComponent
        ref={refSetter}
        style={{ ...style }}
        options={{
          scrollbars: {
            autoHide: 'scroll',
          },
          callbacks: {
            onScroll: props.onScroll,
          },
        }}
      >
        {children}
      </OverlayScrollbarsComponent>
    )
  },
)

export default CustomScrollbar
