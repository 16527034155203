import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import IFModal from 'Components/IFModal/IFModal'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import StationActions from 'Stores/Station/Actions'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import IFSearchField from 'Components/IFSearchField/IFSearchField'
import { IFButton } from 'Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import Styles from './MoveStationModal.module.css'
import StationMoveList from 'Containers/StationMoveList/StationMoveList'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import RequestState from 'Enums/RequestState'

const MoveStationModal = React.forwardRef(
  (
    {
      fetchStationMoveList,
      moveStationsFilter,
      moveChargePoint,
      moveChargePointRequestState,
      setMoveStationsFilter,
      selectedIndex,
      setMoveStationIndex,
      selectedMoveStation,
      deleteStationChargePoint,
      selectedChargePoint,
      addMovedStation,
      deleteMovedChargePoint,
      resetMoveStationsFilter,
      setMoveStationPaginationOffset,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    useEffect(() => {
      if (moveChargePointRequestState === RequestState.SUCCEEDED) {
        const selectedCP = selectedChargePoint
        deleteStationChargePoint(selectedChargePoint.uid)
        addMovedStation(selectedCP)
        deleteMovedChargePoint(selectedChargePoint._id)
        ref.current.handleClose()
      }
    }, [moveChargePointRequestState])

    const onClickMoveHandler = () => {
      let updatedChargePoint = {
        station: selectedMoveStation._id,
      }
      moveChargePoint(selectedIndex, updatedChargePoint)
    }

    return (
      <IFModal
        onClose={() => resetMoveStationsFilter()}
        width={'29rem'}
        title={t('MoveStationModal.Title')}
        ref={ref}
      >
        <div className={Styles.Container}>
          <IFSearchField
            onChange={(e) => {
              setMoveStationPaginationOffset(0)
              let newStationsFilter = [...moveStationsFilter]
              newStationsFilter[0].value = e.target.value
              setMoveStationsFilter(newStationsFilter)
              fetchStationMoveList(moveStationsFilter, 0)
            }}
          />

          <div className={Styles.StationsContainer}>
            <StationMoveList
              isPicking={true}
              returnIndex={(index) => {
                setMoveStationIndex(index)
              }}
            />
          </div>

          <IFButton
            text={t('MoveStationModal.AcceptButtonText')}
            icon={<FontAwesomeIcon icon={faExchangeAlt} />}
            color={Colors.primary}
            isFill={true}
            onClick={onClickMoveHandler}
            className={Styles.Button}
            isLoading={moveChargePointRequestState === RequestState.LOADING}
            size={'sm'}
          />
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchStationMoveList: (filter, offset) =>
      dispatch(StationActions.fetchStationMoveList(filter, offset)),
    setMoveStationsFilter: (filter) =>
      dispatch(StationActions.setMoveStationsFilter(filter)),
    setMoveStationIndex: (index) =>
      dispatch(StationActions.setMoveStationSelectedIndex(index)),
    deleteStationChargePoint: (chargePointUid) =>
      dispatch(StationActions.deleteStationChargePoint(chargePointUid)),
    addMovedStation: (chargePoint) =>
      dispatch(StationActions.addMovedStation(chargePoint)),
    deleteMovedChargePoint: (chargePointId) =>
      dispatch(ChargePointActions.deleteMovedChargePoint(chargePointId)),
    moveChargePoint: (index, chargePoint) =>
      dispatch(ChargePointActions.moveChargePoint(index, chargePoint)),
    resetMoveStationsFilter: () =>
      dispatch(StationActions.resetMoveStationsFilter()),
    setMoveStationPaginationOffset: (offset) =>
      dispatch(StationActions.setMoveStationPaginationOffset(offset)),
  }
}

const mapStateToProps = (state) => ({
  formattedMoveStationList:
    StationSelectors.getFormattedMoveStationsList(state),
  fetchStationListRequestState:
    StationSelectors.getFetchMoveStationListRequestState(state),
  paginationOffset: StationSelectors.getPaginationOffsetMove(state),
  moveStationsFilter: StationSelectors.getMoveStationsFilter(state),
  selectedIndex: ChargePointSelectors.getSelectedIndex(state),
  selectedMoveStation: StationSelectors.getSelectedMoveStation(state),
  selectedChargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  moveChargePointRequestState:
    ChargePointSelectors.getMoveChargePointRequestState(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MoveStationModal)
