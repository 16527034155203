import Styles from './StationListItemIconStyle.module.css'
import InfinityEnums from 'Enums/InfinityEnums'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import IFsvg from 'Components/IFsvg/IFsvg'

const StationListItemIcon = ({
  onClick,
  connectors = [],
  chargePoints = [],
  size = 'sm',
  isFill = false,
  addMapOffset,
}) => {
  const getIconColor = () => {
    const connectorStatuses = connectors.map((connector) => connector.status)
    const suspendedArray = [
      InfinityEnums.ConnectorStatus.SUSPENDEDEV,
      InfinityEnums.ConnectorStatus.SUSPENDEDEVSE,
      InfinityEnums.ConnectorStatus.PREPARING,
      InfinityEnums.ConnectorStatus.RESERVED,
    ]
    if (
      connectorStatuses.length === 0 ||
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.OFFLINE)
    )
      return Colors.OfflineConnector
    else if (
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.UNAVAILABLE) ||
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.FAULTED)
    )
      return Colors.UnAvailableConnector
    else if (
      connectorStatuses.some((status) => suspendedArray.includes(status))
    )
      return Colors.SuspendedConnector
    else if (
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.CHARGING) ||
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.FINISHING)
    )
      return Colors.ChargingConnector
    else if (
      connectorStatuses.includes(InfinityEnums.ConnectorStatus.AVAILABLE)
    )
      return Colors.AvailableConnector
    else return Colors.OfflineConnector
  }
  const getChargingLevel = () => {
    let chargingLevel = 1
    for (let i = 0; i < chargePoints.length; i++) {
      switch (chargePoints[i].level) {
        case 3:
          chargingLevel = 3
          break
        case 2:
          chargingLevel = 2
          continue
      }
    }
    return chargingLevel
  }

  return (
    <div
      style={addMapOffset ? { marginTop: '-3.5rem', marginLeft: '-1rem' } : {}}
    >
      <div onClick={onClick} className={Styles.container}>
        <div
          className={Styles.dot}
          style={{
            border: `2.5px solid ${getIconColor()}`,
            backgroundColor: isFill ? getIconColor() : 'transparent',
            pointerEvents: 'none',
            width: size === 'sm' ? 28 : 48,
            height: size === 'sm' ? 28 : 48,
          }}
        >
          <div className={Styles.chargingSpeedContainer}>
            {getChargingLevel() === 1 ? (
              <div className={Styles.flash}>
                <IFsvg.FlashMaterial
                  height={size === 'sm' ? 16 : 24}
                  width={size === 'sm' ? 16 : 24}
                  fill={isFill ? Colors.white : Colors.black}
                />
              </div>
            ) : getChargingLevel() === 2 ? (
              [...Array(2)].map((e, index) => {
                return (
                  <div
                    key={`flash-${index}`}
                    className={Styles.flash}
                    style={{ marginLeft: index !== 0 ? -6 : 0 }}
                  >
                    <IFsvg.FlashMaterial
                      height={size === 'sm' ? 16 : 24}
                      width={size === 'sm' ? 16 : 24}
                      fill={isFill ? Colors.white : Colors.black}
                    />
                  </div>
                )
              })
            ) : (
              [...Array(3)].map((e, index) => {
                return (
                  <div
                    key={`flash-${index}`}
                    className={Styles.flash}
                    style={{ marginLeft: index !== 0 ? -7 : 0 }}
                  >
                    <IFsvg.FlashMaterial
                      height={size === 'sm' ? 14 : 22}
                      width={size === 'sm' ? 14 : 22}
                      fill={isFill ? Colors.white : Colors.black}
                    />
                  </div>
                )
              })
            )}
          </div>
        </div>
        <div
          className={Styles.triangle}
          style={{
            marginTop: size === 'sm' ? '-2px' : '-5px',
            borderWidth: size === 'sm' ? '10px 6px 0 6px' : '15px 9px 0 9px',
            borderColor: `${getIconColor()} transparent transparent transparent`,
          }}
        />
      </div>
    </div>
  )
}

StationListItemIcon.propTypes = {
  maxChargingSpeed: PropTypes.number,
  onClick: PropTypes.func,
  stationStatus: PropTypes.string,
  isClickable: PropTypes.bool,
  addHeatMapOffset: PropTypes.bool,
  isOffline: PropTypes.bool,
}

export default StationListItemIcon
