import RequestState from 'Enums/RequestState'
import { cardsFilter, cardsTransactionFilter } from 'Constants/Filters'

export const INITIAL_STATE = {
  cards: [],
  selectedCard: null,
  cardsCount: 0,
  cardTransactions: [],
  paginationOffset: 0,
  fetchCardListRequestState: RequestState.UNINITIALIZED,
  fetchCardListCountRequestState: RequestState.UNINITIALIZED,
  fetchCardRequestState: RequestState.UNINITIALIZED,
  fetchCardTransactionsRequestState: RequestState.UNINITIALIZED,
  deleteCardRequestState: RequestState.UNINITIALIZED,
  updateCardRequestState: RequestState.UNINITIALIZED,
  addCardRequestState: RequestState.UNINITIALIZED,
  addCardAccessGroupsRequestState: RequestState.UNINITIALIZED,
  addCardSubscriptionsRequestState: RequestState.UNINITIALIZED,
  deleteCardAccessGroupsRequestState: RequestState.UNINITIALIZED,
  deleteCardSubscriptionsRequestState: RequestState.UNINITIALIZED,
  cardsFilter: cardsFilter,
  cardsTransactionsFilter: cardsTransactionFilter,
  cardTransactionsPaginationOffset: 0,
  selectedIndex: -1,

  localListFilteredCards: [],
  fetchLocalListFilteredCardsRequestState: RequestState.UNINITIALIZED,
  localListFilteredCardsPaginationOffset: 0,
}
