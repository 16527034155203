import Styles from './StationClusterIcon.module.css'
import icons from '../../Theme/Icons'
import { IFText } from 'Components'
import InfinityEnums from 'Enums/InfinityEnums'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
const { ConnectorPowerLevel } = InfinityEnums

const StationClusterIcon = ({
  clusterCount,
  maxChargingSpeed = ConnectorPowerLevel.LEVEL1,
  onClick,
  stationStatus = InfinityEnums.ConnectorStatus.AVAILABLE,
}) => {
  function adjust(color, amount) {
    if (!color.replace) return '#000000'
    return (
      '#' +
      color
        .replace(/^#/, '')
        .replace(/../g, (color) =>
          (
            '0' +
            Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
              16,
            )
          ).substr(-2),
        )
    )
  }

  const getIconColor = () => {
    let iconColor = Colors.AvailableConnector
    if (stationStatus !== InfinityEnums.ConnectorStatus.AVAILABLE) {
      switch (stationStatus) {
        case InfinityEnums.ConnectorStatus.OFFLINE:
          iconColor = Colors.OfflineConnector
          break
        case InfinityEnums.ConnectorStatus.UNAVAILABLE:
        case InfinityEnums.ConnectorStatus.FAULTED:
          iconColor = Colors.UnAvailableConnector
          break
        case InfinityEnums.ConnectorStatus.PREPARING:
        case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
        case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
        case InfinityEnums.ConnectorStatus.RESERVED:
          iconColor = Colors.SuspendedConnector
          break
        case InfinityEnums.ConnectorStatus.CHARGING:
        case InfinityEnums.ConnectorStatus.FINISHING:
          iconColor = Colors.ChargingConnector
          break
      }
    }
    return iconColor
  }

  return (
    <div className={Styles.container}>
      <div
        onClick={onClick}
        className={Styles.outerDot}
        style={{
          cursor: 'pointer',
          backgroundColor: adjust(getIconColor(), 30),
        }}
      >
        <div
          className={Styles.innerDot}
          style={{
            backgroundColor: getIconColor(),
          }}
        >
          <IFText
            className={Styles.clusterCount}
            style={{ color: Colors.ClusterCountText }}
          >
            {clusterCount}
          </IFText>
          <div className={Styles.chargingSpeedContainer}>
            <div className={Styles.flash}>{icons.flash}</div>

            {maxChargingSpeed > ConnectorPowerLevel.LEVEL1 &&
            maxChargingSpeed <= ConnectorPowerLevel.LEVEL2 ? (
              <div className={Styles.flash}>{icons.flash}</div>
            ) : maxChargingSpeed > ConnectorPowerLevel.LEVEL2 ? (
              <>
                <div className={Styles.flash}>{icons.flash}</div>
                <div className={Styles.flash}>{icons.flash}</div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

StationClusterIcon.propTypes = {
  clusterCount: PropTypes.number,
  maxChargingSpeed: PropTypes.number,
  onClick: PropTypes.func,
  stationStatus: PropTypes.string,
}

export default StationClusterIcon
