import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import Styles from './ChargingScheduleChart.module.css'
import PropTypes from 'prop-types'
import { increaseBrightness } from '../../Utils/Calculations'

const ChargingScheduleChart = ({
  day,
  chartHeight = 50,
  chartWidth = '100%',
  barsHeight = '100%',
  seriesData,
  chartID = 'chargingScheduleChart',
  startTimestamp = new Date().setUTCHours(0, 0, 0, 0),
  endTimestamp = new Date().setUTCHours(23, 59, 59, 999),
  hideAxis = false,
}) => {
  const [series, setSeries] = useState([])
  const [seriesColors, setSeriesColors] = useState([])

  const defaultOptions = {
    chart: {
      offsetY: -7.5,
      parentHeightOffset: 0,
      zoom: {
        enabled: false,
      },
      id: chartID,
      type: 'rangeBar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        rangeBarGroupRows: true,
        barHeight: barsHeight,
      },
    },
    colors: seriesColors,
    xaxis: {
      type: 'datetime',
      min: startTimestamp,
      max: endTimestamp,
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      floating: true,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
    grid: {
      padding: {
        left: 0,
        right: 0,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let startHours = new Date(
          w.config.series[seriesIndex].data[0].y[0],
        ).getUTCHours()
        startHours = ('0' + startHours).slice(-2)
        let startMinutes = new Date(
          w.config.series[seriesIndex].data[0].y[0],
        ).getUTCMinutes()
        startMinutes = ('0' + startMinutes).slice(-2)
        let startAmPm
        if (startHours < 12) {
          startAmPm = 'AM'
          startHours = startHours === '00' ? 12 : startHours
        } else {
          startAmPm = 'PM'
          startHours = startHours === '12' ? 12 : startHours - 12
        }
        let startTime = `${startHours}:${startMinutes} ${startAmPm}`

        let endHours = new Date(
          w.config.series[seriesIndex].data[0].y[1],
        ).getUTCHours()
        endHours = ('0' + endHours).slice(-2)
        let endMinutes = new Date(
          w.config.series[seriesIndex].data[0].y[1],
        ).getUTCMinutes()
        endMinutes = ('0' + endMinutes).slice(-2)
        let endAmPm
        if (endHours < 12) {
          endAmPm = 'AM'
          endHours = endHours === '00' ? 12 : endHours
        } else {
          endAmPm = 'PM'
          endHours = endHours === '12' ? 12 : endHours - 12
        }
        let endTime = `${endHours}:${endMinutes} ${endAmPm}`

        return `
         <div style="padding-right:0.5rem;padding-left:0.5rem;padding-top:0.25rem;padding-bottom:0.25rem;">
         <div>
         ${w.config.series[seriesIndex].name} A
         </div>
         <div>
         ${startTime} - ${endTime}
         </div>
         </div>
         `
      },
      enabled: true,
      onDatasetHover: {
        highlightDataSeries: false,
      },
      theme: 'dark',
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  }
  const [options, setOptions] = useState({ ...defaultOptions })

  const formatSeriesColors = (output, color) => {
    let currentsArray = output.map((entry) => entry.name)
    let maxCurrent = Math.max(...currentsArray)
    let formattedColors = []
    for (const entry of output) {
      let percentage = (1 - entry.name / maxCurrent) * 100
      formattedColors.push(increaseBrightness(color, percentage))
    }
    return formattedColors
  }

  useEffect(() => {
    setSeries(seriesData)
    const formattedSeriesColors = formatSeriesColors(seriesData, Colors.primary)
    setSeriesColors(formattedSeriesColors)
    setOptions({
      ...defaultOptions,
      colors: [...formattedSeriesColors],
    })
  }, [seriesData])

  return (
    <div id="ChargingScheduleChart" className={Styles.WrapperContainer}>
      <div className={Styles.DayContainer}>
        <IFText className={Styles.dayText} style={{ color: Colors.text }}>
          {day}
        </IFText>
      </div>
      <div className={Styles.GraphContainer}>
        <ReactApexChart
          options={options}
          series={series}
          type="rangeBar"
          height={chartHeight}
          width={chartWidth}
        />
        {!hideAxis ? (
          <div className={Styles.xAxisContainer}>
            <div className={Styles.LabelsContainer}>
              <IFText className={Styles.LabelWidth}>12:00 AM</IFText>
              <div className={Styles.LabelWidth}>
                <IFText className={Styles.LabelMargin}>06:00 AM</IFText>
              </div>
              <div className={Styles.LabelWidth}>
                <IFText className={Styles.LabelMargin}>12:00 PM</IFText>
              </div>
              <div className={Styles.LastLabelContainer}>
                <IFText className={Styles.LabelMargin}>06:00 PM</IFText>
                <IFText style={{ marginTop: 0 }}>11:59 PM</IFText>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

ChargingScheduleChart.propTypes = {
  day: PropTypes.string,
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.string,
  barsHeight: PropTypes.string,
  seriesData: PropTypes.arrayOf(PropTypes.object),
  chartID: PropTypes.string,
  startTimestamp: PropTypes.string,
  endTimestamp: PropTypes.string,
  hideAxis: PropTypes.bool,
}

export default ChargingScheduleChart
