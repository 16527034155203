import React, { useState, useImperativeHandle, useRef } from 'react'
import Styles from './ReserveConnector.module.css'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import validator from 'validator'
import PlayArrow from '@mui/icons-material/PlayArrow'
import { IFDialog, IFsvg, IFText, IFTextInput } from 'Components'
import Icon from '@material-ui/core/Icon'
import { SelectPicker } from 'rsuite'
import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EventNote } from '@mui/icons-material'

const useStyles = makeStyles({
  root: {
    boxShadow: `0px 0px 4px ${Colors.black} `,
    borderRadius: '6px',
  },
})

const ReserveConnector = React.forwardRef(
  ({ onClick, isUsingMobileApp }, ref) => {
    const Classes = useStyles()
    const [tokenId, setTokenId] = useState('')
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [reserveTime, setReserveTime] = useState('')
    const [connectorUid, setConnectorUid] = useState(null)
    const [reserveOptions, setReserveOptions] = useState([
      {
        label: <IFText style={{ color: Colors.text }}>{'5 mins'}</IFText>,
        value: '5 m',
      },
      {
        label: <IFText style={{ color: Colors.text }}>{'15 mins'}</IFText>,
        value: '15 m',
      },
      {
        label: <IFText style={{ color: Colors.text }}>{'30 mins'}</IFText>,
        value: '30 m',
      },
      {
        label: <IFText style={{ color: Colors.text }}>{'1 hour'}</IFText>,
        value: '1 h',
      },
      {
        label: <IFText style={{ color: Colors.text }}>{'2 hours'}</IFText>,
        value: '2 h',
      },
    ])
    const reserveRef = useRef()
    const { t } = useTranslation()
    const show = (connectorUid) => {
      setConnectorUid(connectorUid)
      reserveRef.current.show()
    }
    const dismiss = () => {
      reserveRef.current.dismiss()
      setTokenId('')
      setError()
      setLoading(false)
    }
    const handleError = () => {
      setLoading(false)
    }
    useImperativeHandle(ref, () => ({
      show,
      dismiss,
      handleError,
    }))
    return (
      <IFDialog
        ref={reserveRef}
        title={t(
          isUsingMobileApp
            ? 'ReserveDialogue.EmailTitle'
            : 'ReserveDialogue.TokenTitle',
        )}
        dialogWidth="25rem"
        children={
          <div className={Styles.InputContainer}>
            <IFText style={{ color: Colors.text }}>
              {t(
                isUsingMobileApp
                  ? 'ReserveDialogue.FieldEmail'
                  : 'ReserveDialogue.FieldToken',
              )}
            </IFText>
            <IFTextInput
              className={Styles.LgInput}
              value={tokenId}
              onChange={(e) => {
                setTokenId(e.target.value)
                if (error && validator.isLength(e.target.value, { min: 14 })) {
                  setError()
                }
              }}
              errorText={error}
              style={{ caretColor: Colors.primary, paddingRight: '2rem' }}
              placeholder={t(
                isUsingMobileApp
                  ? 'ReserveDialogue.EgEmail'
                  : 'ReserveDialogue.EgToken',
              )}
              onBlur={() => {
                if (validator.isEmpty(tokenId)) {
                  setError(
                    t(
                      isUsingMobileApp
                        ? 'ReserveDialogue.EmailCanNotBeEmpty'
                        : 'ReserveDialogue.TokenCanNotBeEmpty',
                    ),
                  )
                  return
                }
                if (!validator.isEmail(tokenId) && isUsingMobileApp) {
                  setError(t('ReserveDialogue.EnterValidEMail'))
                  return
                }
                setError()
              }}
            />
            <IFText style={{ color: Colors.text, marginTop: '1rem' }}>
              {t('ReserveDialogue.ReserveFor')}
            </IFText>

            <div className={Styles.FormContainer}>
              <FormControl
                className={Classes.root}
                onClick={(e) => e.stopPropagation()}
              >
                <SelectPicker
                  preventOverflow={true}
                  menuStyle={{
                    color: Colors.text,
                    zIndex: 1500,
                  }}
                  appearance="subtle"
                  searchable={true}
                  cleanable={false}
                  onSelect={(value) => setReserveTime(value)}
                  data={reserveOptions}
                  value={reserveTime}
                  className={Styles.selectPicker}
                />
              </FormControl>
            </div>
          </div>
        }
        onAction={() => {
          setLoading(true)
          onClick(tokenId, reserveTime, connectorUid)
        }}
        actionButtonIcon={
          <Icon
            sx={{ width: '18px !important', height: '18px !important' }}
            component={EventNote}
          />
        }
        actionButtonText={t('ReserveDialogue.Reserve')}
        actionButtonColor={Colors.primary}
        isLoading={loading}
        isDead={
          validator.isEmpty(tokenId) ||
          (!validator.isEmail(tokenId) && isUsingMobileApp)
        }
        onDismiss={() => {
          setTokenId('')
          setError()
          setLoading(false)
        }}
      />
    )
  },
)
ReserveConnector.propTypes = {
  onStartClick: PropTypes.func,
}

export default ReserveConnector
