import Styles from './ChargePointTabSkeleton.module.css'
import { IFSkeleton } from 'Components'

const ChargePointTabSkeleton = () => {
  return (
    <div className={Styles.Container}>
      <div>
        <IFSkeleton variant="text" width="8rem" height="1.5rem" />
      </div>
      <div>
        <IFSkeleton variant="text" width="3rem" height="1.25rem" />
      </div>
    </div>
  )
}

export default ChargePointTabSkeleton
