import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import Colors from 'Theme/Colors'
import { IFText, StationListItemIcon, IFsvg } from 'Components'
import Styles from './StationListItem.module.css'
import styled from 'styled-components'
import InfinityEnums from 'Enums/InfinityEnums'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { isPropsMatch } from 'Utils/PropsMatch'

const useStyles = makeStyles({
  root: {
    '&.Mui-checked': {
      color: Colors.primary,
    },
    '&:hover': {
      backgroundColor: Colors.TransparentBackground,
    },
  },
})
const Container = styled.div`
  background-color: ${(props) => props.backgroundColor};
  &:hover {
    background-color: ${(props) => props.StationListItemBackgroundHover};
  }
`
const IconContainer = styled.div`
  color: ${Colors.black};
`

const StationListItem = ({
  name,
  connectors,
  chargePoints,
  lastUpdated,
  onMarkerClick,
  isSelected,
  isPicking,
  onClick,
  isNew,
}) => {
  const Classes = useStyles()
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation()
  const handleChecked = (event) => {
    setChecked(event.target.checked)
  }

  const handleBoxColor = (status) => {
    switch (status) {
      case InfinityEnums.ConnectorStatus.AVAILABLE:
        return { backgroundColor: Colors.AvailableConnector }
      case InfinityEnums.ConnectorStatus.PREPARING:
        return { backgroundColor: Colors.PreparingConnector }
      case InfinityEnums.ConnectorStatus.CHARGING:
        return { backgroundColor: Colors.ChargingConnector }
      case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
        return { backgroundColor: Colors.SuspendedConnector }
      case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
        return { backgroundColor: Colors.SuspendedConnector }
      case InfinityEnums.ConnectorStatus.FINISHING:
        return { backgroundColor: Colors.FinishingConnector }
      case InfinityEnums.ConnectorStatus.RESERVED:
        return { backgroundColor: Colors.ReservedConnector }
      case InfinityEnums.ConnectorStatus.OFFLINE:
        return { backgroundColor: Colors.OfflineConnector }
      case InfinityEnums.ConnectorStatus.UNAVAILABLE:
        return { backgroundColor: Colors.UnAvailableConnector }
      case InfinityEnums.ConnectorStatus.FAULTED:
        return { backgroundColor: Colors.FaultedConnector }
      default:
        return { backgroundColor: Colors.black }
    }
  }

  const handleChargingLogo = (type) => {
    switch (type) {
      case 1:
        return (
          <IFsvg.NormalSpeedCharging
            height={19}
            width={19}
            fill={Colors.black}
          />
        )
      case 2:
        return (
          <IFsvg.FastSpeedCharging height={19} width={19} fill={Colors.black} />
        )
      case 3:
        return (
          <IFsvg.SuperSpeedCharging
            height={19}
            width={19}
            fill={Colors.black}
          />
        )
      default:
        return (
          <IFsvg.NormalSpeedCharging
            height={19}
            width={19}
            fill={Colors.black}
          />
        )
    }
  }

  return (
    <Container
      backgroundColor={
        isSelected ? Colors.StationListItemSelected : Colors.white
      }
      StationListItemBackgroundHover={Colors.StationListItemBackgroundHover}
      className={Styles.Container}
      onClick={onClick}
    >
      {isPicking ? (
        <div className={Styles.IconContainer}>
          <Checkbox
            className={Classes.root}
            checked={isSelected}
            onChange={handleChecked}
            style={{ color: Colors.primary }}
          />
        </div>
      ) : (
        <IconContainer
          className={Styles.IconContainer}
          onClick={(e) => {
            onMarkerClick(e)
          }}
        >
          <div className={Styles.IconOutlined}>
            <StationListItemIcon
              key={`station-${name}`}
              connectors={connectors}
              chargePoints={chargePoints}
            />
          </div>
          <div className={Styles.IconFilled}>
            <StationListItemIcon
              connectors={connectors}
              chargePoints={chargePoints}
              isFill
            />
          </div>
        </IconContainer>
      )}

      <div className={Styles.StationDetailsContainer}>
        <div className={Styles.StationNameDateContainer}>
          <div className={Styles.StationNameContainer}>
            <IFText
              className={Styles.StationNameText}
              style={{ color: Colors.text }}
            >
              {name}
            </IFText>
          </div>
          <div className={Styles.LastUpdatedContainer}>
            {isNew ? (
              <Fragment>
                <div
                  className={Styles.NewContainer}
                  style={{
                    backgroundColor: Colors.primary,
                  }}
                >
                  <IFText
                    className={Styles.New}
                    style={{ color: Colors.white }}
                  >
                    {t('StationListItem.New')}
                  </IFText>
                </div>
                <IFText style={{ color: Colors.LastUpdatedColor }}>
                  {lastUpdated}
                </IFText>
              </Fragment>
            ) : (
              <IFText style={{ color: Colors.LastUpdatedColor }}>
                {lastUpdated}
              </IFText>
            )}
          </div>
        </div>
        <div className={Styles.BottomContainer}>
          <div className={Styles.ConnectorsWrapper}>
            {connectors?.length > 0
              ? connectors.map((connector, index) => {
                  return (
                    <div
                      key={`Connector ${index}`}
                      index={`Connector ${index}`}
                      className={Styles.ConnectorContainer}
                    >
                      <IFText
                        style={{
                          color: Colors.text,
                          fontSize: '0.75rem',
                          paddingTop: '1px',
                        }}
                      >
                        {connector.number}
                      </IFText>
                      <div
                        style={handleBoxColor(connector.status)}
                        className={Styles.StatusBox}
                      />
                    </div>
                  )
                })
              : null}
          </div>
          <div className={Styles.ChargersWrapper}>
            {chargePoints?.length > 0
              ? chargePoints.map((chargePoint, index) => {
                  return (
                    <div
                      key={`ChargePoint ${index}`}
                      className={Styles.ChargersContainer}
                    >
                      <IFText
                        className={Styles.ChargersText}
                        style={{ color: Colors.text }}
                      >
                        {chargePoint.number}
                      </IFText>
                      <div className={Styles.ImgBox}>
                        {handleChargingLogo(chargePoint.level)}
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </div>
    </Container>
  )
}

StationListItem.propTypes = {
  name: PropTypes.string,
  connectors: PropTypes.array,
  chargePoints: PropTypes.array,
  lastUpdated: PropTypes.object,
  onMarkerClick: PropTypes.func,
  isSelected: PropTypes.bool,
  isPicking: PropTypes.bool,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'name',
    'connectors',
    'chargePoints',
    'lastUpdated',
    'isSelected',
    'isPicking',
    'isNew',
  ])
}
export default React.memo(StationListItem, shouldSkipRender)
