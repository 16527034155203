import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchStationList: ['filter', 'offset', 'onNotFound'],
  fetchStationListLoading: null,
  fetchStationListLoadingMore: null,
  fetchStationListSuccess: [
    'offset',
    'stations',
    'nextOffset',
    'newStation',
    'index',
    'onNotFound',
    'stationsCount',
  ],
  fetchStationListFail: ['error'],

  fetchStationDetails: ['emitterData'],
  addStationDetails: ['station'],

  fetchStationMoveList: ['filter', 'offset'],
  fetchStationMoveListLoading: null,
  fetchStationMoveListLoadingMore: null,
  fetchStationMoveListSuccess: ['offset', 'stations', 'nextOffset'],
  fetchStationMoveListFail: ['error'],
  clearMoveStations: null,
  resetMoveStationsFilter: null,

  clearStations: null,
  setStationsFilter: ['filter'],
  setMoveStationsFilter: ['filter'],

  updateStation: ['index', 'station'],
  updateStationLoading: null,
  updateStationSuccess: ['index', 'updatedStation'],
  updateStationFail: ['error'],

  addStation: ['station'],
  addStationLoading: null,
  addStationSuccess: ['station'],
  addStationFail: ['error'],

  deleteStation: ['index'],
  deleteStationLoading: null,
  deleteStationSuccess: ['stationId'],
  deleteStationFail: ['error'],
  resetDeleteStationEmitterState: null,

  setStationListSelectedIndex: ['index'],
  setStationDetailsSelectedIndex: ['index'],
  setMoveStationSelectedIndex: ['index'],
  setPaginationOffset: ['offset'],
  setMoveStationPaginationOffset: ['offset'],

  deleteStationChargePoint: ['chargePointUid'],
  addMovedStation: ['chargePoint'],
  editChargePoints: ['selectedIndex', 'chargePoint'],
  addStationChargePoint: ['chargePoint'],
  activateChargePoint: ['index'],

  emitterUpdateChargePointStation: ['args'],
  emitterAddChargePointStation: ['args'],
  emitterDeleteChargePointStation: ['args'],
  emitterMoveChargePointStation: ['args'],
  emitterAddStation: ['args'],
  emitterUpdateStation: ['args'],
  emitterDeleteStation: ['args'],
  emitterActivateChargePointStation: ['args'],

  fetchStationCities: null,
  fetchStationCitiesLoading: null,
  fetchStationCitiesSuccess: ['cities'],
  fetchStationCitiesFail: ['error'],

  addStationCity: ['city'],
  addStationCityLoading: null,
  addStationCitySuccess: ['city'],
  addStationCityFail: ['error'],

  fetchStationMapClusters: ['zoom', 'bounds', 'filter'],
  fetchStationMapClustersSuccess: ['data'],
  fetchStationMapClustersLoading: [],

  fetchStationHeatMap: ['filter'],
  fetchStationHeatMapSuccess: ['data'],
  fetchStationHeatMapLoading: null,
  fetchStationHeatMapFail: ['error'],

  setStationsMapFilter: ['filter'],

  fetchStationListCount: ['filter'],
  fetchStationListCountLoading: null,
  fetchStationListCountSuccess: ['stationsCount'],
  fetchStationListCountFail: ['error'],

  fetchStationMapClustersSearch: ['zoom', 'bounds', 'filter'],
  fetchStationMapClustersSearchLoading: null,
  fetchStationMapClustersSearchSuccess: ['data'],
  fetchStationMapClustersSearchFail: ['error'],
  setStationMapClustersSearchWord: ['filter'],
  clearStationMapClustersSearchWord: null,
  clearStationMapClustersSearch: null,
})

export const StationTypes = Types
export default Creators
