import GoogleMapReact from 'google-map-react'
import { Config } from 'Config'
import { StationListItemIcon } from 'Components'

const IFMap = ({
  style,
  latitude,
  longitude,
  connectors = [],
  chargePoints = [],
}) => {
  return (
    <div style={{ ...style }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: Config.REACT_APP_API_KEY,
        }}
        defaultCenter={{
          lat: Number(latitude),
          lng: Number(longitude),
        }}
        center={{
          lat: Number(latitude),
          lng: Number(longitude),
        }}
        defaultZoom={14}
        options={{
          fullscreenControl: false,
          clickableIcons: false,
          panControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
      >
        <StationListItemIcon
          lat={latitude}
          lng={longitude}
          connectors={connectors}
          chargePoints={chargePoints}
          isFill
          addMapOffset
          size={48}
        />
      </GoogleMapReact>
    </div>
  )
}

export default IFMap
