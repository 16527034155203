import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './DefaultRouter.module.css'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  StationsPage,
  TenantUserPage,
  CardsPage,
  StationsMapPage,
  UsersPage,
  ChargingTokensPage,
} from 'Containers'
import { connect } from 'react-redux'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import InfinityEnums from 'Enums/InfinityEnums'
import { SideNavBar, IFAvatar, NotFound } from 'Components'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useState } from 'react'
import { changeRsuiteColors } from 'Theme'

const PageContainer = styled.div`
  background-color: ${(props) => props.background};
`
const DefaultRouter = ({ logout, user, tenant }) => {
  // theme with state to implement darkmode
  const [theme, setTheme] = useState(
    createTheme({
      palette: {
        primary: {
          main: tenant.primaryColor,
        },
      },
    }),
  )

  const TenantColors = Colors
  TenantColors.primary = tenant.primaryColor
  TenantColors.StationListHeaderBackground =
    tenant.primaryColor + Colors.HexTransparent.T10
  TenantColors.StationListItemBackgroundHover =
    tenant.primaryColor + Colors.HexTransparent.T10
  TenantColors.StationListItemSelected =
    tenant.primaryColor + Colors.HexTransparent.T20
  TenantColors.TabsBackground = tenant.primaryColor + Colors.HexTransparent.T10
  TenantColors.FilterBackground =
    tenant.primaryColor + Colors.HexTransparent.T10
  TenantColors.filterBackground = Colors.transparent
  TenantColors.ChargePointLogListItemHover =
    tenant.primaryColor + Colors.HexTransparent.T10
  changeRsuiteColors(tenant.primaryColor)
  return (
    <ThemeProvider theme={theme}>
      <PageContainer
        background={Colors.background}
        className={Styles.PageContainer}
      >
        <div className={Styles.ContentContainer}>
          <SideNavBar
            avatar={<IFAvatar name={user.name} size={32} />}
            username={user.name}
            permissions={user.permissions}
            isUsingMobileApp={tenant.isUsingMobileApp}
            onLogoutClick={() => {
              logout()
            }}
            logoUrl={tenant.logoUrl}
          />
        </div>
        <div className={Styles.RightColumnContainer}>
          <Routes>
            <Route
              path="/"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) ? (
                  <Navigate to="/sites" />
                ) : user.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_CHARGING_TOKEN,
                  ) ? (
                  <Navigate to="/cards" />
                ) : user.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_VIEW_TENANT_USER,
                  ) ? (
                  <Navigate to="/tenantUsers" />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/sites/"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) ? (
                  <StationsPage username={user.name} />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/sites/:siteId"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) ? (
                  <StationsPage username={user.name} />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/sites/:siteId/stations/:stationid"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) ? (
                  <StationsPage username={user.name} />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/cards"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_VIEW_CHARGING_TOKEN,
                ) && !tenant.isUsingMobileApp ? (
                  <ChargingTokensPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/cards/:cardId"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_VIEW_CHARGING_TOKEN,
                ) && !tenant.isUsingMobileApp ? (
                  <ChargingTokensPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/tenantUsers"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_VIEW_TENANT_USER,
                ) ? (
                  <TenantUserPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/map"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) ? (
                  <StationsMapPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/users"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) && tenant.isUsingMobileApp ? (
                  <UsersPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route
              path="/users/:userId"
              element={
                user.permissions.includes(
                  InfinityEnums.TenantUserPermission
                    .CAN_VIEW_STATION_CHARGEPOINT,
                ) && tenant.isUsingMobileApp ? (
                  <UsersPage />
                ) : (
                  <Navigate to="/NotFound" />
                )
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </PageContainer>
    </ThemeProvider>
  )
}

const mapStateToProps = (state) => ({
  user: AuthSelectors.getTenantUser(state),
  tenant: AuthSelectors.getTenant(state),
})

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(AuthActions.tenantUserLogout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter)
