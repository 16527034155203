import PropTypes from 'prop-types'
import Styles from './IFEmptyStates.module.css'
import images from 'Theme/Images'
import { IFButton, IFText } from 'Components'
import icons from 'Theme/Icons'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'

const FilterNoMatch = ({ title, subtitle }) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.container}>
      <img
        alt={'noSitesFilter'}
        className={Styles.image}
        src={images.noSitesFilter}
      />
      <IFText className={Styles.title}> {title}</IFText>
      <IFText
        style={{ color: Colors.emptyStatesSubTitle }}
        className={Styles.subTitle}
      >
        {subtitle}
        <br />
        {t('emptyStates.query')}
      </IFText>
    </div>
  )
}

const WelcomeDashboard = ({ username, displayedText }) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.container}>
      <img
        alt={'dashboard'}
        className={Styles.imageSESC}
        src={images.dashboard}
      />
      <IFText className={Styles.titleSESC}>
        {t('emptyStates.hi')}
        {username}!
      </IFText>
      <IFText
        style={{ color: Colors.emptyStatesSubTitle }}
        className={Styles.subTitleSESC}
      >
        {`${t('emptyStates.dashboard')} ${displayedText}`}
      </IFText>
    </div>
  )
}

const EmptySites = ({ addPermission }) => {
  const { t } = useTranslation()

  return (
    <div
      {...(!addPermission ? { style: { justifyContent: 'center' } } : {})}
      className={Styles.containerSES}
    >
      {addPermission && (
        <div className={Styles.textArrow}>
          <IFText
            style={{ color: Colors.black }}
            className={Styles.addStationSite}
          >
            {t('emptyStates.addSite')}
          </IFText>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
      <div className={Styles.imagetextContainer}>
        <img alt={'noSites'} className={Styles.imageSES} src={images.noSites} />
        <IFText style={{ color: Colors.black }} className={Styles.titleSES}>
          {t('emptyStates.stationEmptySites')}
        </IFText>
      </div>
      {addPermission && (
        <div className={Styles.textArrowHidden}>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
    </div>
  )
}
const EmptyStations = ({ addPermission }) => {
  const { t } = useTranslation()
  return (
    <div
      {...(!addPermission ? { style: { justifyContent: 'center' } } : {})}
      className={Styles.containerSE}
    >
      {addPermission && (
        <div className={Styles.textArrow}>
          <IFText
            style={{ color: Colors.black }}
            className={Styles.addStationSite}
          >
            {t('emptyStates.addStation')}
          </IFText>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
      <div className={Styles.imagetextContainer}>
        <img
          alt={'noStations'}
          className={Styles.imageSESC}
          src={images.noStations}
        />
        <IFText style={{ color: Colors.black }} className={Styles.titleSE}>
          {t('emptyStates.noStationsSite')}
          <br />
          {t('emptyStates.thisSite')}
        </IFText>
      </div>
      {addPermission && (
        <div className={Styles.textArrowHidden}>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
    </div>
  )
}

const EmptyCards = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.ContainerCardsEmpty}>
      <div className={Styles.AddCardContainer}>
        <IFText style={{ color: Colors.black }} className={Styles.AddCardText}>
          {t('emptyStates.addCard')}
        </IFText>
        <div className={Styles.CardTwistedArrow}>{icons.twistedArrow}</div>
      </div>
      <div className={Styles.CardsImageContainer}>
        <img
          alt={'noCards'}
          className={Styles.CardsImage}
          src={images.noCards}
        />
        <IFText style={{ color: Colors.black }} className={Styles.TitleCards}>
          {t('emptyStates.CardsEmpty')}
        </IFText>
      </div>
    </div>
  )
}
const NoInternet = ({ onClick = () => {} }) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.container}>
      <img
        alt={'NoConnection'}
        className={Styles.image}
        src={images.NoConnection}
      />
      <IFText className={Styles.titleNC}>
        {t('emptyStates.NoConnection')}
      </IFText>

      <IFText
        style={{ color: Colors.emptyStatesSubTitle }}
        className={Styles.subTitle}
      >
        {t('emptyStates.NoInternet')}
        <br />
        {t('emptyStates.ConnectionTry')}
      </IFText>
      <IFButton
        className={Styles.buttonNC}
        color={Colors.primary}
        isFill
        text={t('emptyStates.TryAgain')}
        onClick={onClick}
      />
    </div>
  )
}

const EmptyLogs = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.container}>
      <img alt={'noLogs'} className={Styles.image} src={images.noLogs} />
      <IFText className={Styles.noLogsTitle} style={{ color: Colors.black }}>
        {t('emptyStates.NoLogsHere')}
      </IFText>
      <IFText className={Styles.TryToRefresh}>
        {t('emptyStates.TryToRefresh')}
      </IFText>
    </div>
  )
}
const NoLogsFilter = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.container}>
      <img
        alt={'noSitesFilter'}
        className={Styles.image}
        src={images.noSitesFilter}
      />
      <IFText className={Styles.noLogsTitle} style={{ color: Colors.black }}>
        {t('emptyStates.NoLogsHere')}
      </IFText>
      <IFText
        style={{ color: Colors.emptyStatesSubTitle }}
        className={Styles.subTitle}
      >
        {t('emptyStates.filterNoLogs')}
        <br />
        {t('emptyStates.query')}
      </IFText>
    </div>
  )
}
const NoConfigurations = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.container}>
      <img alt={'noConfig'} className={Styles.image} src={images.noConfig} />
      <IFText className={Styles.noLogsTitle} style={{ color: Colors.black }}>
        {t('emptyStates.NoConfigs')}
      </IFText>
      <IFText className={Styles.TryToRefresh}>
        {t('emptyStates.TryToRefresh')}
      </IFText>
    </div>
  )
}

const NoDiagnostics = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.container}>
      <img
        alt={'noDiagnostics'}
        className={Styles.image}
        src={images.noDiagnostics}
      />
      <IFText
        className={Styles.noDiagnosticsTitle}
        style={{ color: Colors.black }}
      >
        {t('emptyStates.NoDiagnostics')}
      </IFText>
      <IFText className={Styles.TryToRefresh}>
        {t('emptyStates.TryToRefresh')}
      </IFText>
    </div>
  )
}

const EmptySpecs = ({ title }) => {
  const { t } = useTranslation()

  return (
    <div className={Styles.EmptySpecsContainer}>
      <div className={Styles.ImageContainer}>
        <img
          alt={'noDocumentsFound'}
          className={Styles.imageEmptySpecs}
          src={images.emptySpecs}
        />
      </div>
      <div>
        <IFText
          style={{ color: Colors.text }}
          className={Styles.titleEmptySpecs}
        >
          {title}
        </IFText>
      </div>
    </div>
  )
}

const EmptyUsers = ({ addPermission }) => {
  const { t } = useTranslation()

  return (
    <div
      {...(!addPermission ? { style: { justifyContent: 'center' } } : {})}
      className={Styles.containerSES}
    >
      {addPermission && (
        <div className={Styles.textArrow}>
          <IFText
            style={{ color: Colors.black }}
            className={Styles.addStationSite}
          >
            {t('emptyStates.InviteUsers')}
          </IFText>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
      <div className={Styles.imagetextContainer}>
        <img alt={'noSites'} className={Styles.imageSES} src={images.noSites} />
        <IFText style={{ color: Colors.black }} className={Styles.titleSES}>
          {t('emptyStates.UsersEmpty')}
        </IFText>
      </div>
      {addPermission && (
        <div className={Styles.textArrowHidden}>
          <div className={Styles.twistedArrow}>{icons.twistedArrow}</div>
        </div>
      )}
    </div>
  )
}

const NoUserSessions = () => {
  const { t } = useTranslation()
  return (
    <div className={Styles.ContainerNoSessions}>
      <div className={Styles.CardsImageContainer}>
        <img
          alt={'noCards'}
          className={Styles.SessionsImage}
          src={images.sessions}
        />
        <IFText
          style={{ color: Colors.black }}
          className={Styles.TitleNoSessions}
        >
          {t('emptyStates.NoUserSessions')}
        </IFText>
      </div>
    </div>
  )
}

WelcomeDashboard.propTypes = {
  username: PropTypes.string.isRequired,
  displayedText: PropTypes.string.isRequired,
}
EmptyStations.propTypes = {
  addPermission: PropTypes.bool,
}
EmptySites.propTypes = {
  addPermission: PropTypes.bool,
}

EmptySpecs.propTypes = {
  title: PropTypes.string,
}

export {
  FilterNoMatch,
  EmptySites,
  EmptyStations,
  EmptyCards,
  NoInternet,
  EmptyLogs,
  NoLogsFilter,
  NoConfigurations,
  NoDiagnostics,
  EmptySpecs,
  EmptyUsers,
  WelcomeDashboard,
  NoUserSessions,
}
