import Styles from './AlertItemSkeleton.module.css'
import { IFSkeleton } from 'Components'

const AlertItemSkeleton = ({}) => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        <div>
          <IFSkeleton variant="text" width="6.5rem" height="1.8rem" />
        </div>
        <div className={Styles.StatusContainerWrapper}>
          {[...Array(2)].map((e, index) => {
            let style = index === 0 ? {} : { marginLeft: '12px' }
            return (
              <IFSkeleton
                variant="rounded"
                width="3.75rem"
                height="1.35rem"
                style={{ ...style, borderRadius: '16px' }}
              />
            )
          })}
        </div>
      </div>
      <div className={Styles.DurationContainer}>
        <IFSkeleton variant="text" width="3rem" height="1.5rem" />
      </div>
      <div className={Styles.PercentageContainer}>
        <IFSkeleton variant="text" width="4rem" height="1.5rem" />
      </div>
      <div className={Styles.ToggleContainer}>
        <IFSkeleton variant="circular" width="1.5rem" height="1.5rem" />
      </div>
    </div>
  )
}

export default AlertItemSkeleton
