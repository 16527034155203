import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchCardList: ['filter', 'offset', 'onNotFound'],
  fetchCardListLoading: null,
  fetchCardListLoadingMore: null,
  fetchCardListSuccess: ['offset', 'cards', 'nextOffset', 'index'],
  fetchCardListFail: ['error'],

  fetchCard: ['cardId'],
  fetchCardLoading: null,
  fetchCardSuccess: ['card'],
  fetchCardFail: ['error'],

  deleteCard: ['cardId', 'ref'],
  deleteCardLoading: null,
  deleteCardSuccess: ['cardId'],
  deleteCardFail: ['error'],

  updateCard: ['cardId', 'newCard', 'index'],
  updateCardLoading: null,
  updateCardSuccess: ['index', 'newCard'],
  updateCardFail: ['error'],

  addCard: ['newCard'],
  addCardLoading: null,
  addCardSuccess: ['newCard'],
  addCardFail: ['error'],

  clearCards: null,
  setCardsFilter: ['newFilter'],
  resetCardRequestStates: null,

  addCardBulk: ['file'],
  addCardBulkSuccess: ['newCards'],

  addCardAccessGroups: ['cardId', 'accessGroups'],
  addCardAccessGroupsLoading: null,
  addCardAccessGroupsSuccess: ['accessGroups'],
  addCardAccessGroupsFail: ['error'],

  deleteCardAccessGroups: ['cardId', 'accessGroups'],
  deleteCardAccessGroupsLoading: null,
  deleteCardAccessGroupsSuccess: ['accessGroups'],
  deleteCardAccessGroupsFail: ['error'],

  addCardSubscriptions: ['cardId', 'subscriptions'],
  addCardSubscriptionsLoading: null,
  addCardSubscriptionsSuccess: ['subscriptions'],
  addCardSubscriptionsFail: ['error'],

  deleteCardSubscriptions: ['cardId', 'subscriptions'],
  deleteCardSubscriptionsLoading: null,
  deleteCardSubscriptionsSuccess: ['subscriptions'],
  deleteCardSubscriptionsFail: ['error'],

  fetchCardTransactions: ['filter', 'offset', 'cardId', 'ascending'],
  fetchCardTransactionsLoading: null,
  fetchCardTransactionsLoadingMore: null,
  fetchCardTransactionsSuccess: [
    'offset',
    'chargingTokenTransactions',
    'nextOffset',
  ],
  fetchCardTransactionsFail: ['error'],
  setCardsTransactionPaginationOffset: ['offset'],

  setCardTransactionsFilter: ['newFilter'],
  setCardSelectedIndex: ['index'],

  clearCardTransactions: null,
  resetCardState: null,
  setPaginationOffset: ['offset'],

  fetchCardListCount: ['filter'],
  fetchCardListCountLoading: null,
  fetchCardListCountSuccess: ['cardsCount'],
  fetchCardListCountFail: ['error'],

  fetchLocalListFilteredCards: ['filter', 'offset'],
  fetchLocalListFilteredCardsLoading: null,
  fetchLocalListFilteredCardsSuccess: ['offset', 'cards', 'nextOffset'],
  fetchLocalListFilteredCardsFail: ['error'],
  clearLocalListFilteredCards: null,
})

export const CardTypes = Types
export default Creators
