import Styles from './AddChargingTokensModal.module.css'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { SelectPicker } from 'rsuite'
import InfinityEnums from 'Enums/InfinityEnums'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import CardActions from 'Stores/Card/Actions'
import CardSelectors from 'Stores/Card/Selectors'
import {
  IFModal,
  IFButton,
  IFTextInput,
  IFText,
  IFToastMessage,
} from 'Components'
import { SubscriptionContainer, AccessGroupsContainer } from 'Containers'
import React, { useState, useEffect, useRef } from 'react'
import RequestState from 'Enums/RequestState'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { xlsxToJson, csvToJson } from '../../Utils/DataConversion'
import { toaster } from 'rsuite'
import Icon from '@material-ui/core/Icon'
import CancelIcon from '@mui/icons-material/Cancel'

const useStyles = makeStyles({
  root: {
    boxShadow: `0px 0px 4px ${Colors.black} `,
    borderRadius: '6px',
  },
})

const AddChargingTokens = React.forwardRef(
  (
    { addChargingTokens, addChargingTokensRequestState, addChargingTokensBulk },
    ref,
  ) => {
    const [contractId, setContractId] = useState('')
    const [reference, setReference] = useState('')
    const [uid, setUid] = useState('')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [contractIdError, setContractIdError] = useState('')
    const [referenceError, setReferenceError] = useState('')
    const [uidError, setUidError] = useState('')
    const [typeError, setTypeError] = useState('')
    const [statusError, setStatusError] = useState('')
    const [uploadedFile, setUploadedFile] = useState(null)
    const [jsonFile, setJsonFile] = useState({})
    const [addedFile, setAddedFile] = useState(false)
    const [typedText, setTypedText] = useState(false)
    const [canContinue, setCanContinue] = useState(false)
    const [clickedNext, setClickedNext] = useState(false)
    const [receivedSubscriptions, setReceivedSubscriptions] = useState({
      received: false,
      subscriptions: [],
    })
    const [receivedAccessGroups, setReceivedAccessGroups] = useState({
      received: false,
      accessGroups: [],
    })
    let errorFlag = false

    useEffect(() => {
      if (addChargingTokensRequestState === RequestState.SUCCEEDED) {
        ref.current.handleClose()
      }
    }, [addChargingTokensRequestState])

    useEffect(() => {
      if (receivedAccessGroups.received && receivedSubscriptions.received) {
        handleSubmit()
      }
    }, [receivedSubscriptions, receivedAccessGroups])

    const { t } = useTranslation()
    const Classes = useStyles()
    const accessGroupsRef = useRef()
    const subscriptionsRef = useRef()

    const typeEnum = Object.values(InfinityEnums.ChargingTokenType)
    const statusEnum = Object.values(InfinityEnums.ChargingTokenStatus)

    const typeOptions = []
    for (const index in typeEnum) {
      typeOptions.push({
        label: (
          <IFText style={{ color: Colors.text }}>{typeEnum[index]}</IFText>
        ),
        value: typeEnum[index],
      })
    }

    const statusOptions = []
    for (const index in statusEnum) {
      if (statusEnum[index] !== InfinityEnums.ChargingTokenStatus.DEACTIVATED) {
        statusOptions.push({
          label: (
            <IFText style={{ color: Colors.text }}>{statusEnum[index]}</IFText>
          ),
          value: statusEnum[index],
        })
      }
    }

    const resetErrors = () => {
      setContractIdError('')
      setReferenceError('')
      setUidError('')
      setTypeError('')
      setStatusError('')
    }

    const handleModalClose = () => {
      setContractId('')
      setReference('')
      setUid('')
      setType('')
      setStatus('')
      resetErrors()
      setAddedFile(false)
      setTypedText(false)
      setUploadedFile(null)
      setCanContinue(false)
      setClickedNext(false)
    }

    const handleXlsx = (file) => {
      const json = xlsxToJson(file)
      json.then((data) => setJsonFile(data))
    }

    const handleCsv = (file) => {
      const json = csvToJson(file)
      json.then((parsedFile) => setJsonFile(parsedFile.data))
    }

    const handleOnDrop = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (acceptedFiles[0].name.includes('csv')) {
          handleCsv(acceptedFiles[0])
          setCanContinue(true)
        } else if (acceptedFiles[0].name.includes('xlsx')) {
          handleXlsx(acceptedFiles[0])
          setCanContinue(true)
        } else {
          toaster.push(
            <IFToastMessage
              type={'error'}
              text={t('AddChargingTokensModal.FileNotSupported')}
            />,
            { placement: 'topEnd' },
          )
          errorFlag = true
        }
        setUploadedFile(acceptedFiles)
        setAddedFile(true)

        resetErrors()
      }
    }

    const handleSubmit = () => {
      if (typedText) {
        const data = {
          visualNumber: contractId,
          reference: reference,
          uid: uid,
          type: type,
          status: status,
          subscriptions: receivedSubscriptions.subscriptions,
          accessGroups: receivedAccessGroups.accessGroups,
        }
        addChargingTokens(data)
      } else if (addedFile && !errorFlag) {
        // TODO: fix
        const data = {
          chargingTokens: jsonFile,
          subscriptions: receivedSubscriptions.subscriptions,
          accessGroups: receivedAccessGroups.accessGroups,
        }

        addChargingTokensBulk(data)
      } else errorFlag = false
    }

    return (
      <IFModal
        onClose={handleModalClose}
        title={t('AddChargingTokensModal.AddNewCard')}
        ref={ref}
        canGoBack={clickedNext}
        onBackClicked={() => setClickedNext(false)}
      >
        <div
          className={
            clickedNext ? Styles.InputWrapperRight : Styles.InputWrapperLeft
          }
        >
          <div
            className={
              clickedNext
                ? [Styles.EmailDivShow, Styles.AnimateDropZoneHeightOut].join(
                    ' ',
                  )
                : [Styles.EmailDivShow, Styles.AnimateDropZoneHeightIn].join(
                    ' ',
                  )
            }
          >
            <div className={addedFile ? Styles.FadeOut : Styles.FadeIn}>
              <div className={Styles.RowContainer}>
                <div className={Styles.InputContainer}>
                  <IFText className={Styles.Label}>
                    {t('AddChargingTokensModal.ContractID')}
                  </IFText>
                  <IFTextInput
                    style={{ caretColor: Colors.primary }}
                    className={Styles.Input}
                    isFixed={true}
                    value={contractId}
                    name="contractID"
                    type="text"
                    onChange={(e) => {
                      setContractId(e.target.value)
                      if (
                        e.target.value.length > 0 ||
                        reference.length > 0 ||
                        uid.length > 0 ||
                        type.length > 0 ||
                        status.length > 0
                      ) {
                        setTypedText(true)
                      } else setTypedText(false)
                      if (contractIdError.length > 0) {
                        e.target.value.length === 0
                          ? setContractIdError(
                              t('AddChargingTokensModal.Required'),
                            )
                          : setContractIdError('')
                      }
                      const submit =
                        e.target.value.length > 0 &&
                        reference.length > 0 &&
                        uid.length > 0 &&
                        type.length > 0 &&
                        status.length > 0
                      setCanContinue(submit)
                    }}
                    onBlur={(e) => {
                      e.target.value.length === 0
                        ? setContractIdError(
                            t('AddChargingTokensModal.Required'),
                          )
                        : setContractIdError('')
                      const submit =
                        e.target.value.length > 0 &&
                        reference.length > 0 &&
                        uid.length > 0 &&
                        type.length > 0 &&
                        status.length > 0
                      setCanContinue(submit)
                    }}
                    {...(contractIdError.length > 0
                      ? { errorText: contractIdError }
                      : {})}
                  />
                </div>
                <div className={Styles.InputContainer}>
                  <IFText className={Styles.Label}>
                    {t('AddChargingTokensModal.Reference')}
                  </IFText>
                  <IFTextInput
                    style={{
                      caretColor: Colors.primary,
                    }}
                    className={Styles.Input}
                    isFixed={true}
                    value={reference}
                    name="reference"
                    type="text"
                    onChange={(e) => {
                      setReference(e.target.value)
                      if (
                        contractId.length > 0 ||
                        e.target.value.length > 0 ||
                        uid.length > 0 ||
                        type.length > 0 ||
                        status.length > 0
                      ) {
                        setTypedText(true)
                      } else setTypedText(false)
                      if (referenceError.length > 0) {
                        e.target.value.length === 0
                          ? setReferenceError(
                              t('AddChargingTokensModal.Required'),
                            )
                          : setReferenceError('')
                      }
                      const submit =
                        contractId.length > 0 &&
                        e.target.value.length > 0 &&
                        uid.length > 0 &&
                        type.length > 0 &&
                        status.length > 0

                      setCanContinue(submit)
                    }}
                    onBlur={(e) => {
                      e.target.value.length === 0
                        ? setReferenceError(
                            t('AddChargingTokensModal.Required'),
                          )
                        : setReferenceError('')

                      const submit =
                        contractId.length > 0 &&
                        e.target.value.length > 0 &&
                        uid.length > 0 &&
                        type.length > 0 &&
                        status.length > 0

                      setCanContinue(submit)
                    }}
                    {...(referenceError.length > 0
                      ? { errorText: referenceError }
                      : {})}
                  />
                </div>
              </div>
              <div className={Styles.RowContainerMiddle}>
                <div className={Styles.InputContainer}>
                  <IFText className={Styles.Label}>
                    {t('AddChargingTokensModal.RFID')}
                  </IFText>
                  <IFTextInput
                    style={{ caretColor: Colors.primary }}
                    className={Styles.Input}
                    isFixed={true}
                    value={uid}
                    name="uid"
                    type="text"
                    onChange={(e) => {
                      setUid(e.target.value)
                      if (
                        contractId.length > 0 ||
                        reference.length > 0 ||
                        e.target.value.length > 0 ||
                        type.length > 0 ||
                        status.length > 0
                      ) {
                        setTypedText(true)
                      } else setTypedText(false)
                      if (uidError.length > 0) {
                        e.target.value.length === 0
                          ? setUidError(t('AddChargingTokensModal.Required'))
                          : setUidError('')
                      }
                      const submit =
                        contractId.length > 0 &&
                        reference.length > 0 &&
                        e.target.value.length > 0 &&
                        type.length > 0 &&
                        status.length > 0

                      setCanContinue(submit)
                    }}
                    onBlur={(e) => {
                      e.target.value.length === 0
                        ? setUidError(t('AddChargingTokensModal.Required'))
                        : setUidError('')

                      const submit =
                        contractId.length > 0 &&
                        reference.length > 0 &&
                        e.target.value.length > 0 &&
                        type.length > 0 &&
                        status.length > 0

                      setCanContinue(submit)
                    }}
                    {...(uidError.length > 0 ? { errorText: uidError } : {})}
                  />
                  <div style={{ height: '0.6rem' }} />
                </div>
                <div className={Styles.SelectPickerContainer}>
                  <IFText className={Styles.Label}>
                    {t('AddChargingTokensModal.Type')}
                  </IFText>
                  <FormControl
                    className={Classes.root}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <SelectPicker
                      preventOverflow={true}
                      menuStyle={{ color: Colors.text, zIndex: 1500 }}
                      appearance="default"
                      searchable={false}
                      cleanable={true}
                      value={type}
                      onSelect={(value) => {
                        setType(value)
                        if (
                          contractId.length > 0 ||
                          reference.length > 0 ||
                          uid.length > 0 ||
                          value.length > 0 ||
                          status.length > 0
                        ) {
                          setTypedText(true)
                        } else setTypedText(false)
                        value.length === 0
                          ? setTypeError(t('AddChargingTokensModal.Required'))
                          : setTypeError('')
                        const submit =
                          contractId.length > 0 &&
                          reference.length > 0 &&
                          uid.length > 0 &&
                          value.length > 0 &&
                          status.length > 0

                        setCanContinue(submit)
                      }}
                      onOpen={() => {
                        type.length === 0
                          ? setTypeError(t('AddChargingTokensModal.Required'))
                          : setTypeError('')
                      }}
                      data={typeOptions}
                      size="md"
                      onClean={() => {
                        setType('')
                        if (
                          contractId.length === 0 &&
                          reference.length === 0 &&
                          uid.length === 0 &&
                          status.length === 0
                        ) {
                          setTypedText(false)
                        }
                      }}
                    />
                  </FormControl>
                  <div
                    style={{ color: Colors.red }}
                    className={Styles.ErrorStyling}
                  >
                    {typeError.length > 0 ? (
                      <IFText style={{ fontSize: '0.75rem' }}>
                        {typeError}
                      </IFText>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className={Styles.RowContainerBottom}>
                <div className={Styles.SelectPickerContainer}>
                  <IFText className={Styles.Label}>
                    {t('AddChargingTokensModal.Status')}
                  </IFText>
                  <FormControl
                    className={Classes.root}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <SelectPicker
                      preventOverflow={true}
                      menuStyle={{ color: Colors.text, zIndex: 1500 }}
                      appearance="default"
                      searchable={false}
                      cleanable={true}
                      value={status}
                      onSelect={(value) => {
                        setStatus(value)
                        if (
                          contractId.length > 0 ||
                          reference.length > 0 ||
                          uid.length > 0 ||
                          type.length > 0 ||
                          value.length > 0
                        ) {
                          setTypedText(true)
                        } else setTypedText(false)
                        value.length === 0
                          ? setStatusError(t('AddChargingTokensModal.Required'))
                          : setStatusError('')
                        const submit =
                          contractId.length > 0 &&
                          reference.length > 0 &&
                          uid.length > 0 &&
                          type.length > 0 &&
                          value.length > 0

                        setCanContinue(submit)
                      }}
                      onOpen={() => {
                        status.length === 0
                          ? setStatusError(t('AddChargingTokensModal.Required'))
                          : setStatusError('')
                      }}
                      data={statusOptions}
                      size="md"
                      onClean={() => {
                        setStatus('')
                        if (
                          contractId.length === 0 &&
                          reference.length === 0 &&
                          uid.length === 0 &&
                          type.length === 0
                        ) {
                          setTypedText(false)
                        }
                      }}
                    />
                  </FormControl>
                  <div
                    style={{ color: Colors.red }}
                    className={Styles.ErrorStyling}
                  >
                    {statusError.length > 0 ? (
                      <IFText style={{ fontSize: '0.75rem' }}>
                        {statusError}
                      </IFText>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                typedText || addedFile
                  ? [Styles.DividerWrapper, Styles.FadeOut].join(' ')
                  : [Styles.DividerWrapper, Styles.FadeIn].join(' ')
              }
            >
              <div className={Styles.DividerContainer}>
                <Divider />
              </div>
              <div className={Styles.DividerText}>
                <IFText style={{ color: Colors.text }}>
                  {t('AddChargingTokensModal.Or')}
                </IFText>
              </div>
              <div className={Styles.DividerContainer}>
                <Divider />
              </div>
            </div>

            <div className={typedText ? Styles.FadeOut : Styles.FadeIn}>
              <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className={
                          addedFile ? Styles.DropZoneSelected : Styles.DropZone
                        }
                        style={{ backgroundColor: Colors.DropZoneBackground }}
                      >
                        {uploadedFile == null ? (
                          <>
                            <FileUploadIcon />

                            <IFText>
                              {t('AddChargingTokensModal.DragAndDrop')}
                            </IFText>
                          </>
                        ) : (
                          <>
                            <InsertDriveFileIcon fontSize="large" />
                            <div
                              className={Styles.CloseIconContainer}
                              onClick={(e) => {
                                e.stopPropagation()
                                setUploadedFile(null)
                                setAddedFile(false)

                                setCanContinue(false)
                              }}
                            >
                              <Icon
                                className={Styles.CloseIcon}
                                component={CancelIcon}
                              />
                            </div>
                            <IFText>{uploadedFile[0].name}</IFText>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            <div className={Styles.Submit}>
              <IFButton
                text={t('AddChargingTokensModal.Next')}
                color={Colors.primary}
                isFill={true}
                isLoading={false}
                size="sm"
                onClick={() => setClickedNext(true)}
                isDead={!canContinue}
              ></IFButton>
            </div>
          </div>
          <div
            className={
              clickedNext
                ? [
                    Styles.SpecificationsContainerVisible,
                    Styles.AnimateHeightIn,
                  ].join(' ')
                : [
                    Styles.SpecificationsContainerVisible,
                    Styles.AnimateHeightOut,
                  ].join(' ')
            }
          >
            <div className={Styles.SpecificationsWrapper}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <AccessGroupsContainer
                  key={'AccessGroupsContainerId'}
                  ref={accessGroupsRef}
                  renderFor={InfinityEnums.ChargePointSpecsContainer.MODAL}
                  isEditing={true}
                  maxListHeight={136}
                  onModalSubmit={(addAccessGroups) => {
                    setReceivedAccessGroups({
                      received: true,
                      accessGroups: addAccessGroups,
                    })
                  }}
                />
                <div style={{ width: '5rem' }}></div>
                <SubscriptionContainer
                  ref={subscriptionsRef}
                  isEditing={true}
                  renderFor={InfinityEnums.ChargePointSpecsContainer.MODAL}
                  maxListHeight={136}
                  onModalSubmit={(addSubscriptions) => {
                    setReceivedSubscriptions({
                      received: true,
                      subscriptions: addSubscriptions,
                    })
                  }}
                />
              </div>
              <div className={Styles.Submit}>
                <IFButton
                  text={t('AddChargingTokensModal.Submit')}
                  color={Colors.primary}
                  isFill={true}
                  isLoading={
                    addChargingTokensRequestState === RequestState.LOADING
                  }
                  size="sm"
                  onClick={() => {
                    accessGroupsRef.current.handleSubmit(false)
                    subscriptionsRef.current.handleSubmit(false)
                  }}
                  isDead={false}
                />
              </div>
            </div>
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    addChargingTokens: (newCard) => dispatch(CardActions.addCard(newCard)),
    addChargingTokensBulk: (file) => dispatch(CardActions.addCardBulk(file)),
  }
}

const mapStateToProps = (state) => ({
  addChargingTokensRequestState: CardSelectors.getAddCardRequestState(state),
})

AddChargingTokens.propTypes = {
  addChargingTokens: PropTypes.func,
  addChargingTokensRequestState: PropTypes.number,
  addChargingTokensBulk: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AddChargingTokens)
