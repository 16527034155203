import React, { useState, useEffect, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import {
  IFText,
  IFFilter,
  IFDialog,
  IFSkeleton,
  IFsvg,
  IFScrollbars,
  IFTooltipIconsLoading,
  StatePicker,
  IFTextInput,
  IFToastMessage,
} from 'Components'
import {
  SubscriptionContainer,
  AccessGroupsContainer,
  ChargingTokenTransactionList,
} from 'Containers'
import { Colors } from 'Theme'
import Styles from './ChargingTokensDetails.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import Icon from '@material-ui/core/Icon'
import DateRangeIcon from '@mui/icons-material/DateRange'
import moment from 'moment'
import styled from 'styled-components'
import InfinityEnums from 'Enums/InfinityEnums'
import RefreshIcon from '@mui/icons-material/Refresh'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { SelectPicker } from 'rsuite'
import CardSelectors from 'Stores/Card/Selectors'
import AuthSelectors from 'Stores/Auth/Selectors'
import CardActions from 'Stores/Card/Actions'
import { toaster } from 'rsuite'

const LeftContainer = styled.div`
  border-right: ${(props) => (props.underTenant ? '1px' : '0px')} solid
    ${Colors.DividerColor};
  padding-right: ${(props) => (props.underTenant ? '1rem' : '0rem')};
`

const CardGroupsContainer = styled.div`
  box-shadow: 0px 0px 4px 0px ${Colors.UserGroupsContainer};
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-left: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const ChargingTokensDetails = ({
  selectedCard,
  selectedIndex,
  fetchCardListRequestState,
  fetchCardRequestState,
  cardsTransactionsFilter,
  cardsList,
  fetchCard,
  clearCardTransactions,
  resetCardState,
  setCardTransactionsFilter,
  setCardsTransactionPaginationOffset,
  fetchCardTransactions,
  fetchCardTransactionsRequestState,
  deleteCard,
  deleteCardRequestState,
  deleteCardAccessGroupsRequestState,
  addCardAccessGroupsRequestState,
  addCardSubscriptionsRequestState,
  deleteCardSubscriptionsRequestState,
  cardTransactions,
  updateCard,
  updateCardRequestState,
}) => {
  const { t } = useTranslation()
  const confirmDeleteDialog = useRef()
  const subscriptionsRef = useRef()
  const accessGroupsRef = useRef()
  const refDiv = useRef()
  const [isEdit, setIsEdit] = useState(false)
  const [ascendingTransactions, setAscendingTransactions] = useState(false)
  const [height, setHeight] = useState(0)
  const [refreshingTransactions, setRefreshingTransactions] = useState(false)
  const [transactionListLastUpdated, setTransactionListLastUpdated] =
    useState(null)
  const [visualNumberEdit, setVisualNumberEdit] = useState(
    selectedCard?.visualNumber,
  )
  const [referenceEdit, setReferenceEdit] = useState(selectedCard?.reference)
  const [uidEdit, setUidEdit] = useState(selectedCard?.uid)
  const [typeEdit, setTypeEdit] = useState(selectedCard?.type)
  const [statusEdit, setStatusEdit] = useState(selectedCard?.status)
  const [empty, setEmpty] = useState(true)

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
    return emptyFlag
  }

  useEffect(() => {
    if (selectedCard) {
      setIsEdit(false)
      setCardsTransactionPaginationOffset(0)
      clearCardTransactions()
      fetchCard(selectedCard._id)
      fetchCardTransactions(
        cardsTransactionsFilter,
        0,
        selectedCard._id,
        ascendingTransactions,
      )
      setTransactionListLastUpdated(null)
      subscriptionsRef?.current?.handleCancel()
      accessGroupsRef?.current?.handleCancel()
    }
  }, [selectedCard?._id])

  useEffect(() => {
    if (selectedCard) {
      setCardsTransactionPaginationOffset(0)
      fetchCardTransactions(
        cardsTransactionsFilter,
        0,
        selectedCard._id,
        ascendingTransactions,
      )
    }
  }, [cardsTransactionsFilter])
  useEffect(() => {
    if (selectedCard) {
      setVisualNumberEdit(selectedCard?.visualNumber)
      setReferenceEdit(selectedCard?.reference)
      setUidEdit(selectedCard?.uid)
      setTypeEdit(selectedCard?.type)
      setStatusEdit(selectedCard?.status)
    }
  }, [selectedCard])
  useEffect(() => {
    if (
      fetchCardTransactionsRequestState !== RequestState.LOADING &&
      fetchCardTransactionsRequestState !== RequestState.UNINITIALIZED
    ) {
      setRefreshingTransactions(false)
      setTransactionListLastUpdated(Date.now())
    }
  }, [fetchCardTransactionsRequestState])

  useEffect(() => {
    // max list height container height - padding,title,add button
    if (refDiv && refDiv.current) {
      setHeight(refDiv.current.getBoundingClientRect().height - 130)
    }
  }, [refDiv, selectedCard, isEdit])

  useEffect(() => {
    if (deleteCardRequestState === RequestState.SUCCEEDED) {
      if (confirmDeleteDialog.current) confirmDeleteDialog.current.dismiss()
      resetCardState()
      setIsEdit(false)
    }
  }, [deleteCardRequestState])
  useEffect(() => {
    if (
      ((updateCardRequestState !== RequestState.LOADING &&
        updateCardRequestState !== RequestState.UNINITIALIZED) ||
        (addCardAccessGroupsRequestState !== RequestState.LOADING &&
          addCardAccessGroupsRequestState !== RequestState.UNINITIALIZED) ||
        (deleteCardAccessGroupsRequestState !== RequestState.LOADING &&
          deleteCardAccessGroupsRequestState !== RequestState.UNINITIALIZED) ||
        (addCardSubscriptionsRequestState !== RequestState.LOADING &&
          addCardSubscriptionsRequestState !== RequestState.UNINITIALIZED) ||
        (deleteCardSubscriptionsRequestState !== RequestState.LOADING &&
          deleteCardSubscriptionsRequestState !==
            RequestState.UNINITIALIZED)) &&
      updateCardRequestState !== RequestState.LOADING &&
      addCardAccessGroupsRequestState !== RequestState.LOADING &&
      deleteCardAccessGroupsRequestState !== RequestState.LOADING &&
      addCardSubscriptionsRequestState !== RequestState.LOADING &&
      deleteCardSubscriptionsRequestState !== RequestState.LOADING
    ) {
      setIsEdit(false)
      toaster.push(
        <IFToastMessage
          type="success"
          text={t('CardDetails.ChargingTokenUpdated')}
        />,
        {
          placement: 'topEnd',
        },
      )
    }
  }, [
    updateCardRequestState,
    addCardAccessGroupsRequestState,
    deleteCardAccessGroupsRequestState,
    addCardSubscriptionsRequestState,
    deleteCardSubscriptionsRequestState,
  ])
  const handleCancel = () => {
    setIsEdit(false)
    setVisualNumberEdit(selectedCard?.visualNumber)
    setReferenceEdit(selectedCard?.reference)
    setUidEdit(selectedCard?.uid)
    setTypeEdit(selectedCard?.type)
    setStatusEdit(selectedCard?.status)
    subscriptionsRef?.current?.handleCancel()
    accessGroupsRef?.current?.handleCancel()
  }

  const handleSubmit = () => {
    subscriptionsRef?.current?.handleSubmit(false)
    accessGroupsRef?.current?.handleSubmit(false)
    let newCard = {}
    if (visualNumberEdit !== selectedCard.visualNumber)
      newCard.visualNumber = visualNumberEdit
    if (referenceEdit !== selectedCard.reference)
      newCard.reference = referenceEdit
    if (uidEdit !== selectedCard.uid) newCard.uid = uidEdit
    if (typeEdit !== selectedCard.type) newCard.type = typeEdit
    if (statusEdit !== selectedCard.status) newCard.status = statusEdit
    if (Object.keys(newCard).length > 0) {
      updateCard(selectedCard._id, newCard, selectedIndex)
    }
    if (
      Object.keys(newCard).length === 0 &&
      !subscriptionsRef.current.didChange() &&
      !accessGroupsRef.current.didChange()
    )
      setIsEdit(false)
  }

  const handleCardTransactions = (newFilter) => {
    clearCardTransactions()
    setCardTransactionsFilter(newFilter)
  }
  const handleSubmitRef = useRef(handleSubmit)

  useEffect(() => {
    handleSubmitRef.current = handleSubmit
  })

  return (
    <Fragment>
      <div className={Styles.Container}>
        <LeftContainer
          className={Styles.LeftContainer}
          underTenant={
            fetchCardListRequestState === RequestState.LOADING ||
            (cardsList.length > 0 && selectedCard)
              ? true
              : false
          }
        >
          <div className={Styles.CardDetailsContainer}>
            <div>
              {fetchCardListRequestState === RequestState.LOADING ||
              fetchCardRequestState === RequestState.LOADING ? (
                <div>
                  <div className={Styles.CardInfoSkeletonContainer}>
                    <IFSkeleton variant="text" width="6rem" height="1.75rem" />
                    <IFSkeleton
                      style={{ marginLeft: '1rem' }}
                      variant="text"
                      width="6rem"
                      height="1.75rem"
                    />
                  </div>
                  <div className={Styles.CardInfoSkeletonContainer}>
                    <IFSkeleton
                      variant="circular"
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <IFSkeleton
                      className={Styles.CardInfoSkeleton}
                      variant="text"
                      width="10rem"
                      height="1.5rem"
                    />
                  </div>
                  <div className={Styles.CardInfoSkeletonContainer}>
                    <IFSkeleton
                      variant="circular"
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <IFSkeleton
                      className={Styles.CardInfoSkeleton}
                      variant="text"
                      width="13rem"
                      height="1.5rem"
                    />
                  </div>
                  <div className={Styles.CardInfoSkeletonContainer}>
                    <IFSkeleton
                      variant="circular"
                      width="1.25rem"
                      height="1.25rem"
                    />
                    <IFSkeleton
                      className={Styles.CardInfoSkeleton}
                      variant="text"
                      width="10rem"
                      height="1.5rem"
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className={Styles.ReferenceContainer}>
                    {isEdit ? (
                      <IFTextInput
                        className={Styles.IFTextReferenceEdit}
                        value={referenceEdit}
                        fontSize={18}
                        onChange={(e) => {
                          setReferenceEdit(e.target.value)
                        }}
                      />
                    ) : (
                      <IFText
                        className={Styles.NameText}
                        style={{ color: Colors.text }}
                      >
                        {referenceEdit}
                      </IFText>
                    )}

                    {selectedCard && (
                      <StatePicker
                        options={Object.values(
                          InfinityEnums.ChargingTokenStatus,
                        ).map((status) => {
                          return {
                            label: status,
                            value: status,
                            color: Colors.ChargingtokenStatus[status],
                          }
                        })}
                        defaultOptionIndex={Object.values(
                          InfinityEnums.ChargingTokenStatus,
                        )
                          .map((status) => {
                            return {
                              label: status,
                              value: status,
                              color: Colors.ChargingtokenStatus[status],
                            }
                          })
                          .findIndex(
                            (option) => option.value === selectedCard?.status,
                          )}
                        isEdit={isEdit || selectedCard?.isActivated === false}
                        onPrivacyChange={(value) => setStatusEdit(value)}
                      />
                    )}
                  </div>
                  <div className={Styles.InfoIconContainer}>
                    <IFsvg.Tag
                      className={Styles.Icon}
                      height={20}
                      width={20}
                      fill={Colors.black}
                    />
                    <div className={Styles.InfoTextContainer}>
                      {isEdit ? (
                        <IFTextInput
                          value={uidEdit}
                          fontSize={14}
                          onChange={(e) => setUidEdit(e.target.value)}
                        />
                      ) : (
                        <IFText
                          className={Styles.InfoText}
                          style={{ color: Colors.text }}
                        >
                          {selectedCard?.uid}
                        </IFText>
                      )}
                    </div>
                  </div>

                  <div className={Styles.InfoIconContainer}>
                    <IFsvg.VisibleFilled
                      height={20}
                      width={20}
                      fill={Colors.black}
                      className={Styles.Icon}
                    />
                    <div className={Styles.InfoTextContainer}>
                      {isEdit ? (
                        <IFTextInput
                          value={visualNumberEdit}
                          fontSize={14}
                          onChange={(e) => setVisualNumberEdit(e.target.value)}
                        />
                      ) : (
                        <IFText
                          className={Styles.InfoText}
                          style={{ color: Colors.text }}
                        >
                          {selectedCard?.visualNumber}
                        </IFText>
                      )}
                    </div>
                  </div>

                  <div className={Styles.InfoIconContainer}>
                    {isEdit ? null : selectedCard?.type ===
                      InfinityEnums.ChargingTokenType.RFID ? (
                      <IFsvg.Card height={20} width={20} fill={Colors.black} />
                    ) : (
                      <IFsvg.VirtualToken
                        height={20}
                        width={20}
                        fill={Colors.black}
                      />
                    )}
                    <div className={Styles.TypeContainer}>
                      {isEdit ? (
                        <div className={Styles.TypeSelectPicker}>
                          <SelectPicker
                            className={Styles.SelectPicker}
                            appearance="subtle"
                            renderValue={(value) => (
                              <div
                                className={Styles.SelectPickerValueContainer}
                              >
                                {value ===
                                InfinityEnums.ChargingTokenType.RFID ? (
                                  <IFsvg.Card
                                    height={20}
                                    width={20}
                                    fill={Colors.black}
                                    className={Styles.Icon}
                                  />
                                ) : (
                                  <IFsvg.VirtualToken
                                    height={20}
                                    width={20}
                                    fill={Colors.black}
                                    className={Styles.Icon}
                                  />
                                )}
                                <IFText
                                  className={Styles.SelectPickerText}
                                  style={{
                                    color: Colors.text,
                                  }}
                                >
                                  {typeEdit}
                                </IFText>
                              </div>
                            )}
                            defaultValue={typeEdit}
                            value={typeEdit}
                            onSelect={(value) => {
                              setTypeEdit(value)
                            }}
                            renderMenuItem={(item) => (
                              <div className={Styles.RenderMenuItemContainer}>
                                {item ===
                                InfinityEnums.ChargingTokenType.RFID ? (
                                  <IFsvg.Card
                                    height={20}
                                    width={20}
                                    fill={Colors.black}
                                    className={Styles.Icon}
                                  />
                                ) : (
                                  <IFsvg.VirtualToken
                                    height={20}
                                    width={20}
                                    fill={Colors.black}
                                    className={Styles.Icon}
                                  />
                                )}
                                <IFText className={Styles.SelectPickerText}>
                                  {item}
                                </IFText>
                              </div>
                            )}
                            data={Object.keys(
                              InfinityEnums.ChargingTokenType,
                            ).map((key) => {
                              return {
                                label: InfinityEnums.ChargingTokenType[key],
                                value: InfinityEnums.ChargingTokenType[key],
                              }
                            })}
                            cleanable={false}
                            size="xs"
                            searchable={false}
                          />
                        </div>
                      ) : (
                        <IFText
                          className={Styles.TypeText}
                          style={{ color: Colors.text }}
                        >
                          {selectedCard?.type}
                        </IFText>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {fetchCardListRequestState === RequestState.LOADING ||
            cardsList.length > 0 ? (
              <div style={{ color: Colors.black }}>
                {fetchCardListRequestState === RequestState.LOADING ||
                fetchCardRequestState === RequestState.LOADING ? (
                  <IFSkeleton variant="text" width="8rem" height=" 1.5rem" />
                ) : (
                  <div className={Styles.DateContainer}>
                    <Icon
                      sx={{ fontSize: '16px !important' }}
                      component={DateRangeIcon}
                    />
                    <IFText>
                      &nbsp;
                      {selectedCard
                        ? moment(selectedCard.updatedAt).format(
                            'MMM DD, YYYY HH:mm',
                          )
                        : ''}
                    </IFText>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <IFScrollbars>
            {fetchCardListRequestState === RequestState.LOADING ||
            cardsList.length > 0 ? (
              <div
                ref={refDiv}
                className={
                  isEdit
                    ? Styles.CardGroupsContainerEdit
                    : Styles.CardGroupsContainer
                }
              >
                <div className={Styles.SubscriptionsContainer}>
                  {fetchCardListRequestState === RequestState.LOADING ||
                  fetchCardRequestState === RequestState.LOADING ? (
                    <IFSkeleton variant="text" width="6rem" height="1.75rem" />
                  ) : (
                    <IFText
                      className={Styles.CardsGroupsText}
                      style={{ color: Colors.text }}
                    >
                      {t('CardDetails.Subscriptions')}
                    </IFText>
                  )}
                  <CardGroupsContainer
                    style={
                      isEdit
                        ? {}
                        : {
                            flexGrow: 1,
                            height: '10.25rem',
                          }
                    }
                  >
                    <SubscriptionContainer
                      ref={subscriptionsRef}
                      isEditing={isEdit}
                      renderFor={
                        InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS
                      }
                      maxListHeight={height}
                      scrollbarsHeight={100}
                      removeExtraMargin={true}
                    />
                  </CardGroupsContainer>
                </div>
                <div className={Styles.AccessGroupsContainer}>
                  {fetchCardListRequestState === RequestState.LOADING ||
                  fetchCardRequestState === RequestState.LOADING ? (
                    <IFSkeleton variant="text" width="6rem" height="1.75rem" />
                  ) : (
                    <IFText
                      className={Styles.CardsGroupsText}
                      style={{ color: Colors.text }}
                    >
                      {t('CardDetails.AccessGroups')}
                    </IFText>
                  )}

                  <CardGroupsContainer
                    style={
                      isEdit
                        ? { paddingRight: '1rem' }
                        : {
                            flexGrow: 1,
                            flexGrow: 1,
                            height: '10.25rem',
                          }
                    }
                  >
                    <AccessGroupsContainer
                      key={'AccessGroupsContainerId'}
                      ref={accessGroupsRef}
                      renderFor={
                        InfinityEnums.ChargePointSpecsContainer.CHARGINGTOKENS
                      }
                      isEditing={isEdit}
                      maxListHeight={height}
                      scrollbarsHeight={100}
                      removeExtraMargin={true}
                    />
                  </CardGroupsContainer>
                </div>
              </div>
            ) : null}

            {!isEdit && (
              <div className={Styles.TransactionsContainer}>
                <div className={Styles.TransactionsTitlesContainer}>
                  {fetchCardListRequestState === RequestState.LOADING ||
                  fetchCardRequestState === RequestState.LOADING ||
                  !transactionListLastUpdated ? (
                    <div className={Styles.TransactionLogContainer}>
                      <IFSkeleton
                        variant="text"
                        width="7rem"
                        height="1.75rem"
                      />
                    </div>
                  ) : (
                    <div className={Styles.TransactionLogContainer}>
                      <IFText
                        className={Styles.TransactionsLogText}
                        style={{ color: Colors.text }}
                      >
                        {t('CardDetails.TransactionLog')}
                      </IFText>
                    </div>
                  )}
                  {fetchCardListRequestState === RequestState.LOADING ||
                  fetchCardRequestState === RequestState.LOADING ||
                  fetchCardTransactionsRequestState === RequestState.LOADING ? (
                    <div className={Styles.RefreshIconTextContainer}>
                      <div>
                        <IFSkeleton
                          height="1.5rem"
                          className={Styles.RefreshTextSkeleton}
                        />
                        <IFSkeleton
                          height="1.25rem"
                          className={Styles.RefreshTextDateSkeleton}
                        />
                      </div>
                      <div
                        className={Styles.RefreshIcon}
                        style={{ marginBottom: '0.5rem' }}
                      >
                        <IFSkeleton
                          width="1.5rem"
                          height="1.5rem"
                          variant="circular"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={Styles.RefreshIconTextContainer}>
                      <div className={Styles.RefreshTextContainer}>
                        <IFText
                          className={Styles.RefreshText}
                          style={{ color: Colors.black }}
                        >
                          {t('ChargePointDetails.lastUpdated')}
                        </IFText>
                        <IFText className={Styles.RefreshTextDate}>
                          {transactionListLastUpdated
                            ? moment(transactionListLastUpdated).format(
                                'MMM DD, YYYY HH:mm:ss',
                              )
                            : ''}
                        </IFText>
                      </div>
                      <div
                        className={Styles.RefreshIcon}
                        onClick={() => {
                          if (refreshingTransactions) return
                          setRefreshingTransactions(true)
                          setCardsTransactionPaginationOffset(0)
                          fetchCardTransactions(
                            cardsTransactionsFilter,
                            0,
                            selectedCard._id,
                            ascendingTransactions,
                          )
                        }}
                      >
                        <RefreshIcon
                          sx={{ height: '20px' }}
                          className={
                            refreshingTransactions ? Styles.refreshStart : null
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className={Styles.FilterContainer}>
                  {!(
                    fetchCardTransactionsRequestState ===
                      RequestState.SUCCEEDED &&
                    cardTransactions.length === 0 &&
                    !empty
                  ) && (
                    <IFFilter
                      key={'IFFilter_CardDetails_Transactions'}
                      onFilterChange={(newFilter) => {
                        handleCardTransactions(newFilter)
                      }}
                      filters={cardsTransactionsFilter}
                      textFieldPlaceholder={t('CardDetails.FilterLogsBy')}
                      downloadTransactions={false}
                      isLoading={
                        fetchCardListRequestState === RequestState.LOADING ||
                        fetchCardRequestState === RequestState.LOADING ||
                        (fetchCardTransactionsRequestState ===
                          RequestState.LOADING &&
                          !transactionListLastUpdated)
                      }
                      canSort={true}
                      ascending={ascendingTransactions}
                      onSortChange={() => {
                        clearCardTransactions()
                        setCardsTransactionPaginationOffset(0)
                        fetchCardTransactions(
                          cardsTransactionsFilter,
                          0,
                          selectedCard.id,
                          !ascendingTransactions,
                        )
                        setAscendingTransactions((prev) => {
                          return !prev
                        })
                      }}
                    />
                  )}
                </div>
                <ChargingTokenTransactionList
                  ascending={ascendingTransactions}
                />
              </div>
            )}
          </IFScrollbars>
        </LeftContainer>

        {isEdit ? (
          <div className={Styles.RightContainerEdit}>
            <IFTooltipIconsLoading
              onClick={() => confirmDeleteDialog.current.show()}
              title={t('ChargePointDetails.DeleteButtonText')}
              iconColor={Colors.red}
              iconBackgroundColor={Colors.red}
              Icon={IFsvg.Delete}
              FilledIcon={IFsvg.Delete}
              isLoading={deleteCardRequestState === RequestState.LOADING}
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.IconContainer}
              style={{ marginRight: '1rem !important' }}
            />

            <IFTooltipIconsLoading
              onClick={handleCancel}
              title={t('ChargePointDetails.CancelButtonText')}
              Icon={IFsvg.Cancel}
              FilledIcon={IFsvg.Cancel}
              isLoading={false}
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.IconContainer}
              style={{ marginRight: '1rem !important' }}
            />
            <IFTooltipIconsLoading
              onClick={() => {
                handleSubmitRef.current()
              }}
              title={t('ChargePointDetails.DoneButtonText')}
              Icon={IFsvg.Done}
              FilledIcon={IFsvg.Done}
              isLoading={
                deleteCardAccessGroupsRequestState === RequestState.LOADING ||
                addCardAccessGroupsRequestState === RequestState.LOADING ||
                addCardSubscriptionsRequestState === RequestState.LOADING ||
                deleteCardSubscriptionsRequestState === RequestState.LOADING ||
                updateCardRequestState === RequestState.LOADING
              }
              tooltipPlacement="left"
              size={26}
              iconClassname={Styles.IconContainer}
              style={{ marginRight: '1rem !important' }}
              animationDisabled={false}
            />
          </div>
        ) : fetchCardListRequestState === RequestState.LOADING ||
          cardsList.length > 0 ? (
          <div className={Styles.RightContainer}>
            <div className={Styles.EditButtonContainer}>
              {fetchCardListRequestState === RequestState.LOADING ||
              fetchCardRequestState === RequestState.LOADING ? (
                <div className={Styles.IconContainerSkeleton}>
                  <IFSkeleton variant="circular" width="26px" height="26px" />
                </div>
              ) : (
                <IFTooltipIconsLoading
                  onClick={() => {
                    setIsEdit(true)
                  }}
                  title={t('ChargePointDetails.EditButtonText')}
                  Icon={IFsvg.Edit}
                  FilledIcon={IFsvg.Edit}
                  isLoading={false}
                  tooltipPlacement="left"
                  size={26}
                  iconClassname={Styles.IconContainer}
                  style={{ marginRight: '1rem !important' }}
                />
              )}
            </div>
          </div>
        ) : null}
        <IFDialog
          ref={confirmDeleteDialog}
          title={t('CardDetails.DeleteCard')}
          subtitle={t('CardDetails.DeleteCardSubtitle')}
          onAction={() => deleteCard(selectedCard._id)}
          actionButtonIcon={
            <Icon
              sx={{ width: '18px !important', height: '18px !important' }}
              component={DeleteOutlineIcon}
            />
          }
          actionButtonText={t('CardDetails.DeleteButtonText')}
          actionButtonColor={Colors.red}
          confirmationText={selectedCard?.uid ? selectedCard.uid : ''}
          isLoading={deleteCardRequestState === RequestState.LOADING}
        />
      </div>
    </Fragment>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    clearCardTransactions: () => dispatch(CardActions.clearCardTransactions()),
    resetCardState: () => dispatch(CardActions.resetCardState()),
    setCardTransactionsFilter: (newFilter) =>
      dispatch(CardActions.setCardTransactionsFilter(newFilter)),
    setCardsTransactionPaginationOffset: (offset) =>
      dispatch(CardActions.setCardsTransactionPaginationOffset(offset)),
    fetchCardTransactions: (filter, offset, cardId, ascending) =>
      dispatch(
        CardActions.fetchCardTransactions(filter, offset, cardId, ascending),
      ),
    deleteCard: (cardId) => dispatch(CardActions.deleteCard(cardId)),
    fetchCard: (cardId) => dispatch(CardActions.fetchCard(cardId)),
    updateCard: (cardId, newCard, index) =>
      dispatch(CardActions.updateCard(cardId, newCard, index)),
  }
}

const mapStateToProps = (state) => ({
  cardsTransactionsFilter: CardSelectors.getCardTransactionsFilter(state),
  selectedIndex: CardSelectors.getSelectedIndex(state),
  fetchCardRequestState: CardSelectors.getFetchCardRequestState(state),
  selectedCard: CardSelectors.getSelectedCard(state),
  cardTransactions: CardSelectors.getCardTransactions(state),
  cardsFilter: CardSelectors.getCardsFilter(state),
  cardsList: CardSelectors.getCards(state),
  fetchCardListRequestState: CardSelectors.getFetchCardListRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  updateCardRequestState: CardSelectors.getUpdateCardRequestState(state),
  fetchCardTransactionsRequestState:
    CardSelectors.getFetchCardTransactionsRequestState(state),
  deleteCardRequestState: CardSelectors.getDeleteCardRequestState(state),
  addCardAccessGroupsRequestState:
    CardSelectors.getAddCardAccessGroupsRequestState(state),
  deleteCardAccessGroupsRequestState:
    CardSelectors.getDeleteCardAccessGroupsRequestState(state),
  addCardSubscriptionsRequestState:
    CardSelectors.getAddCardSubscriptionsRequestState(state),
  deleteCardSubscriptionsRequestState:
    CardSelectors.getDeleteCardSubscriptionsRequestState(state),
})

ChargingTokensDetails.propTypes = {
  selectedCard: PropTypes.object,
  selectedIndex: PropTypes.number,
  fetchCardListRequestState: PropTypes.number,
  fetchCardRequestState: PropTypes.number,
  cardsTransactionsFilter: PropTypes.arrayOf(PropTypes.object),
  cardsList: PropTypes.arrayOf(PropTypes.object),
  fetchCard: PropTypes.func,
  clearCardTransactions: PropTypes.func,
  resetCardState: PropTypes.func,
  setCardTransactionsFilter: PropTypes.func,
  setCardsTransactionPaginationOffset: PropTypes.number,
  fetchCardTransactions: PropTypes.func,
  fetchCardTransactionsRequestState: PropTypes.number,
  deleteCard: PropTypes.func,
  deleteCardRequestState: PropTypes.number,
  deleteCardAccessGroupsRequestState: PropTypes.number,
  addCardAccessGroupsRequestState: PropTypes.number,
  addCardSubscriptionsRequestState: PropTypes.number,
  deleteCardSubscriptionsRequestState: PropTypes.number,
  cardTransactions: PropTypes.arrayOf(PropTypes.object),
  updateCard: PropTypes.func,
  updateCardRequestState: PropTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargingTokensDetails)
