import React, { useState, useImperativeHandle, useEffect } from 'react'
import { IFText, IFsvg } from 'Components'
import styles from './IFRangePicker.module.css'
import Colors from 'Theme/Colors'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { IFButton } from 'Components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import { SelectPicker } from 'rsuite'
import { makeStyles } from '@material-ui/core/styles'
import { InputNumber, InputGroup } from 'rsuite'

const PopperContainer = styled.div`
  border-color: ${Colors.white};
  background-color: ${Colors.white};
  box-shadow: 0px 2px 2px 1px ${Colors.IFRangePickerShadow};
`

const useStyles = makeStyles({
  root: {
    borderRadius: '6px',
    marginTop: '1px',
    width: '160px',
  },
})

const IFRangePicker = React.forwardRef(
  (
    {
      title,
      onRangeSelected = () => {},
      isDecimal = false,
      min = 0,
      unit,
      step = isDecimal ? 0.1 : 1,
    },
    ref,
  ) => {
    let rangeOptions = ['Less than', 'More than', 'Exactly', 'Between']
    let rangeOptionsData = []
    for (const index in rangeOptions) {
      rangeOptionsData.push({
        label: (
          <IFText style={{ color: Colors.text }}>{rangeOptions[index]}</IFText>
        ),
        value: rangeOptions[index],
      })
    }

    const Classes = useStyles()

    const [selectedRangeOption, setSelectedRangeOption] = useState()
    const [rangeValue, setRangeValue] = useState([])
    const [minBetweenUpperLimit, setMinBetweenUpperLimit] = useState(
      Number(min) + step,
    )
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isOpen, setIsOpen] = React.useState(false)
    const [placement, setPlacement] = React.useState()
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      clearValue,
      isOpen,
    }))

    const clearValue = () => {
      if (selectedRangeOption === 'Between')
        if (isDecimal) setRangeValue([step, 2 * step])
        else setRangeValue([0, step])
      else setRangeValue([0, 0])
    }

    const handleClick = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget)
      setIsOpen((prev) => placement !== newPlacement || !prev)
      setPlacement(newPlacement)
    }

    const handleSubmit = () => {
      let newRangeValue = []
      if (selectedRangeOption === 'Less than')
        newRangeValue = [-1, rangeValue[1]]
      else if (selectedRangeOption === 'More than')
        newRangeValue = [rangeValue[0], -1]
      else newRangeValue = rangeValue

      onRangeSelected(newRangeValue)
      closePopper()
    }

    const closePopper = () => {
      setIsOpen(false)
    }

    useEffect(() => {
      setMinBetweenUpperLimit(Number(rangeValue[0]) + step)
    }, [rangeValue[0]])

    useEffect(() => {
      if (selectedRangeOption === 'Between')
        if (isDecimal) setRangeValue([step, 2 * step])
        else setRangeValue([rangeValue[0], step])
    }, [selectedRangeOption])

    return (
      <ClickAwayListener onClickAway={closePopper}>
        <div>
          <div
            className={styles.Container}
            onClick={handleClick('bottom-start')}
            style={{
              backgroundColor: Colors.filterBackground,
            }}
          >
            <IFText className={styles.Text}>
              {title.replaceAll(' ', '\u00a0')}
            </IFText>
            <div>
              <IFsvg.Expand
                height={20}
                width={20}
                fill={Colors.FilterIconFilled}
              />
            </div>
          </div>

          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement={placement}
            transition
          >
            <PopperContainer className={styles.PopperContainer}>
              <div className={styles.Input}>
                <FormControl
                  className={Classes.root}
                  onClick={(e) => e.stopPropagation()}
                >
                  <SelectPicker
                    size="md"
                    preventOverflow={true}
                    menuStyle={{
                      color: Colors.text,
                      zIndex: 1500,
                    }}
                    appearance="default"
                    searchable={false}
                    cleanable={false}
                    onSelect={(value) => {
                      setRangeValue([0, 0])
                      setSelectedRangeOption(value)
                    }}
                    data={rangeOptionsData}
                    value={selectedRangeOption}
                    className={styles.SelectPicker}
                  />
                </FormControl>

                <div className={styles.NumberPicker}>
                  {selectedRangeOption === 'Between' ? (
                    isDecimal ? (
                      <InputGroup>
                        <InputNumber
                          size="md"
                          className={styles.TextInput}
                          min={step}
                          value={rangeValue[0]}
                          onChange={(value) => {
                            setRangeValue(
                              value >= rangeValue[1]
                                ? [value, +(Number(value) + step).toFixed(2)]
                                : [value, Number(rangeValue[1])],
                            )
                          }}
                          step={step}
                        />
                        <InputGroup.Addon>~</InputGroup.Addon>
                        <InputNumber
                          size="md"
                          className={styles.TextInput}
                          min={minBetweenUpperLimit}
                          value={rangeValue[1]}
                          onChange={(value) => {
                            setRangeValue([
                              rangeValue[0],
                              +Number(value).toFixed(2),
                            ])
                          }}
                          step={step}
                          disabled={selectedRangeOption ? false : true}
                        />
                        {unit ? (
                          <InputGroup.Addon className={styles.Unit}>
                            {unit}
                          </InputGroup.Addon>
                        ) : null}
                      </InputGroup>
                    ) : (
                      <InputGroup>
                        <InputNumber
                          size="md"
                          className={styles.TextInput}
                          min={min}
                          value={rangeValue[0]}
                          onChange={(value) => {
                            setRangeValue(
                              value >= rangeValue[1]
                                ? [Number(value), Number(value) + step]
                                : [Number(value), Number(rangeValue[1])],
                            )
                          }}
                          step={step}
                        />
                        <InputGroup.Addon>~</InputGroup.Addon>
                        <InputNumber
                          size="md"
                          className={styles.TextInput}
                          min={rangeValue[0] + step}
                          value={rangeValue[1]}
                          onChange={(value) => {
                            setRangeValue(
                              isDecimal
                                ? [rangeValue[0], value]
                                : [rangeValue[0], Number(value)],
                            )
                          }}
                          step={step}
                          disabled={selectedRangeOption ? false : true}
                        />
                        {unit ? (
                          <InputGroup.Addon className={styles.Unit}>
                            {unit}
                          </InputGroup.Addon>
                        ) : null}
                      </InputGroup>
                    )
                  ) : (
                    <InputGroup>
                      <InputNumber
                        size="md"
                        className={styles.TextInput}
                        min={min}
                        value={
                          selectedRangeOption === 'Less than'
                            ? rangeValue[1]
                            : rangeValue[0]
                        }
                        onChange={(value) => {
                          setRangeValue(
                            selectedRangeOption === 'Less than'
                              ? isDecimal
                                ? [0, value]
                                : [0, Number(value)]
                              : selectedRangeOption === 'More than'
                              ? isDecimal
                                ? [value, 0]
                                : [Number(value), 0]
                              : isDecimal
                              ? [value, value]
                              : [Number(value), Number(value)],
                          )
                        }}
                        step={step}
                        disabled={selectedRangeOption ? false : true}
                      />
                      {unit ? (
                        <InputGroup.Addon className={styles.Unit}>
                          {unit}
                        </InputGroup.Addon>
                      ) : null}
                    </InputGroup>
                  )}
                </div>
              </div>
              <div className={styles.ButtonContainer}>
                <IFButton
                  onClick={() => {
                    handleSubmit()
                  }}
                  text={t('IFRangePicker.Ok')}
                  size="micro"
                  isFill={true}
                  isDead={
                    !selectedRangeOption ||
                    (selectedRangeOption === 'Less than' &&
                      (rangeValue[1] === 0 || isNaN(rangeValue[1]))) ||
                    (selectedRangeOption === 'Between' &&
                      (isNaN(rangeValue[0]) || isNaN(rangeValue[1]))) ||
                    (selectedRangeOption === 'More than' &&
                      isNaN(rangeValue[0]))
                  }
                  color={Colors.primary}
                />
              </div>
            </PopperContainer>
          </Popper>
        </div>
      </ClickAwayListener>
    )
  },
)

IFRangePicker.propTypes = {
  min: PropTypes.number,
  title: PropTypes.string,
  onRangeSelected: PropTypes.func,
  isDecimal: PropTypes.bool,
  unit: PropTypes.string,
  step: PropTypes.number,
}

export default IFRangePicker
