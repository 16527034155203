import Styles from './ChargePointScheduleListItem.module.css'
import IFText from 'Components/IFText/IFText'
import { DoNotDisturbOn, AddCircle } from '@mui/icons-material'
import { Colors } from 'Theme'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Divider } from '@material-ui/core'
import { formatRepeatDate } from 'Utils/Format'
import Tooltip from '@material-ui/core/Tooltip'
import { isOverflowActive } from 'Utils/Calculations'
import Fade from '@mui/material/Fade'
import { withStyles } from '@material-ui/styles'
import { isPropsMatch } from 'Utils/PropsMatch'

const StyledOverflowTooltip = withStyles({
  tooltip: {
    backgroundColor: Colors.white,
    color: Colors.black,
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginTop: '-1.55rem',
    alignSelf: 'center',
    border: `1px solid ${Colors.grey}`,
    width: 'max-content',
    maxWidth: '110%',
    marginLeft: '-0.33rem',
  },
})(Tooltip)

const ChargePointScheduleListItem = ({
  _id,
  name,
  day,
  start,
  end,
  repeat = [],
  isEditing,
  onClick = () => {},
}) => {
  const [toBeRemoved, setToBeRemoved] = useState(false)
  const textRef = useRef()
  useEffect(() => {
    if (!isEditing) setToBeRemoved(false)
  }, [isEditing])

  let dateText = day
    ? moment.weekdaysShort(moment(day).day()) +
      ' ' +
      moment(day).format('MM/DD/YY')
    : formatRepeatDate(repeat)
  let timeText =
    ' ' + moment(start).format('HH:mm') + '-' + moment(end).format('HH:mm')

  return (
    <div className={Styles.Container}>
      <div className={Styles.LeftContainer}>
        {isEditing && (
          <div
            className={Styles.IconContainer}
            onClick={() => {
              setToBeRemoved((prevState) => {
                onClick(_id, !prevState)
                return !prevState
              })
            }}
          >
            {toBeRemoved ? (
              <AddCircle
                className={Styles.Icon}
                style={{
                  color: Colors.ChargePointScheduleListItem.AddIcon,
                }}
              />
            ) : (
              <DoNotDisturbOn
                className={Styles.Icon}
                style={{
                  color: Colors.ChargePointScheduleListItem.RemoveIcon,
                }}
              />
            )}
          </div>
        )}
        {isOverflowActive(textRef.current) ? (
          <StyledOverflowTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 60 }}
            title={
              <div className={Styles.TextContainer}>
                <IFText
                  className={Styles.ToolTipOverflownText}
                  style={{
                    color: toBeRemoved
                      ? Colors.ChargePointScheduleListItem.gray
                      : Colors.ChargePointScheduleListItem.black,
                  }}
                >
                  {name}
                </IFText>
              </div>
            }
            placement="bottom-start"
          >
            <div className={Styles.TextContainer}>
              <IFText
                ref={textRef}
                className={Styles.NameText}
                style={{
                  color: toBeRemoved
                    ? Colors.ChargePointScheduleListItem.gray
                    : Colors.ChargePointScheduleListItem.black,
                }}
              >
                {name}
              </IFText>
            </div>
          </StyledOverflowTooltip>
        ) : (
          <div className={Styles.TextContainer}>
            <IFText
              ref={textRef}
              className={Styles.NameText}
              style={{
                color: toBeRemoved
                  ? Colors.ChargePointScheduleListItem.gray
                  : Colors.ChargePointScheduleListItem.black,
              }}
            >
              {name}
            </IFText>
          </div>
        )}
      </div>
      <div className={Styles.RightContainer}>
        <div className={Styles.DateTextContainer}>
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointScheduleListItem.gray
                : Colors.ChargePointScheduleListItem.black,
            }}
            className={Styles.Text}
          >
            {dateText}
          </IFText>
          <Divider
            className={Styles.Divider}
            style={{
              backgroundColor: Colors.black,
            }}
          />
          <IFText
            style={{
              color: toBeRemoved
                ? Colors.ChargePointScheduleListItem.gray
                : Colors.ChargePointScheduleListItem.black,
            }}
            className={Styles.Text}
          >
            {timeText}
          </IFText>
        </div>
      </div>
    </div>
  )
}

ChargePointScheduleListItem.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  tariff: PropTypes.object,
  expiryDate: PropTypes.string,
  consumedPower: PropTypes.string,
  power: PropTypes.string,
  isEditing: PropTypes.bool,
  onClick: PropTypes.func,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    '_id',
    'name',
    'day',
    'start',
    'end',
    'repeat',
    'isEditing',
    'onClick',
  ])
}
export default React.memo(ChargePointScheduleListItem, shouldSkipRender)
