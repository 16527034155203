import Styles from './InviteTenantUserModal.module.css'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { CheckPicker } from 'rsuite'
import InfinityEnums from 'Enums/InfinityEnums'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import TenantUserActions from 'Stores/TenantUser/Actions'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import { IFModal, IFButton, IFTextInput, IFText } from 'Components'
import React, { useState, useEffect } from 'react'
import validator from 'validator'
import RequestState from 'Enums/RequestState'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  root: {
    width: '17.375rem',
    boxShadow: `0px 0px 4px ${Colors.black} `,
    borderRadius: '6px',
  },
})

const transformPermissions = (permissions) => {
  const transformedPermissions = permissions.map((permission) => {
    let lowerCase = permission.toLowerCase()
    lowerCase = lowerCase.replace(/_/g, ' ')
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
  })
  return transformedPermissions
}

const convertPermissionsBack = (permissions) => {
  let upperCasePermissions = permissions.map((permission) => {
    let temp = permission.toUpperCase()
    return temp.replace(/ /g, '_')
  })
  let TenantUserEnums = Object.keys(InfinityEnums.TenantUserPermission)
  upperCasePermissions = upperCasePermissions.map((permission) => {
    const index = TenantUserEnums.findIndex(
      (tenantUserEnum) => tenantUserEnum === permission,
    )
    if (index != -1) return index + 1
  })

  return upperCasePermissions
}

const InviteTenantUserModal = React.forwardRef(
  ({ inviteTenantUser, inviteTenantUserRequestState }, ref) => {
    const [email, setEmail] = useState('')
    const [selectedPermissions, setSelectedPermissions] = useState([])
    const [emailError, setEmailError] = useState('')
    const [permissionsError, setPermissionsError] = useState('')
    const [canSubmit, setCanSubmit] = useState(false)

    useEffect(() => {
      if (inviteTenantUserRequestState === RequestState.SUCCEEDED) {
        ref.current.handleClose()
      }
    }, [inviteTenantUserRequestState])

    const { t } = useTranslation()
    const Classes = useStyles()
    const permissions = transformPermissions(
      Object.keys(InfinityEnums.TenantUserPermission),
    )

    const options = []
    for (const index in permissions) {
      options.push({
        label: permissions[index],
        value: permissions[index],
      })
    }

    const handleModalClose = () => {
      setEmailError('')
      setPermissionsError('')
      setCanSubmit(false)
      setSelectedPermissions([])
      setEmail('')
    }

    const handleSubmit = () => {
      const transformedPermissions = convertPermissionsBack(selectedPermissions)
      const data = {
        email: email,
        permissions: transformedPermissions,
      }
      inviteTenantUser(data)
    }

    return (
      <IFModal
        onClose={handleModalClose}
        title={t('TenantUserPageContainer.InviteTenantUser')}
        ref={ref}
      >
        <div>
          <div className={Styles.inputContainer}>
            <IFText className={Styles.label}>
              {t('TenantUserPageContainer.Email')}
            </IFText>
            <IFTextInput
              placeholder={t('TenantUserPageContainer.EmailAddressPlaceholder')}
              style={{ caretColor: Colors.primary }}
              className={Styles.input}
              isFixed={true}
              value={email}
              name="email"
              type="email"
              onChange={(e) => {
                setEmail(e.target.value)
                e.target.value.length === 0
                  ? setEmailError(t('TenantUserPageContainer.Required'))
                  : validator.isEmail(e.target.value)
                  ? setEmailError('')
                  : setEmailError(t('TenantUserPageContainer.EmailError'))
                const submit =
                  validator.isEmail(e.target.value) &&
                  selectedPermissions.length > 0
                setCanSubmit(submit)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setEmailError(t('TenantUserPageContainer.Required'))
                  : validator.isEmail(e.target.value)
                  ? setEmailError('')
                  : setEmailError(t('TenantUserPageContainer.EmailError'))
                const submit =
                  validator.isEmail(e.target.value) &&
                  selectedPermissions.length > 0
                setCanSubmit(submit)
              }}
              {...(emailError.length > 0 ? { errorText: emailError } : {})}
            />
          </div>
          <div className={Styles.CheckPickerContainer}>
            <IFText className={Styles.label}>
              {t('TenantUserPageContainer.Permissions')}
            </IFText>
            <FormControl
              className={Classes.root}
              onClick={(e) => e.stopPropagation()}
            >
              <CheckPicker
                size="lg"
                placeholder={
                  <IFText className={Styles.CheckPickerPlaceholder}>
                    {t('TenantUserPageContainer.Select')}
                  </IFText>
                }
                searchable={false}
                preventOverflow={true}
                data={options}
                menuStyle={{
                  color: Colors.text,
                  zIndex: 1500,
                }}
                onChange={(e) => {
                  setSelectedPermissions(e)
                  e.length === 0
                    ? setPermissionsError(t('TenantUserPageContainer.Required'))
                    : setPermissionsError('')
                  const submit = validator.isEmail(email) && e.length > 0
                  setCanSubmit(submit)
                }}
                onOpen={() => {
                  selectedPermissions.length === 0
                    ? setPermissionsError(t('TenantUserPageContainer.Required'))
                    : setPermissionsError('')
                }}
              />
            </FormControl>
            <div style={{ color: Colors.red }} className={Styles.ErrorStyling}>
              {permissionsError.length > 0 ? (
                <IFText>{permissionsError}</IFText>
              ) : null}
            </div>
          </div>
          <div className={Styles.Submit}>
            <IFButton
              text={t('TenantUserPageContainer.Submit')}
              color={Colors.primary}
              isFill={true}
              isLoading={inviteTenantUserRequestState === RequestState.LOADING}
              size="sm"
              onClick={handleSubmit}
              isDead={!canSubmit}
            ></IFButton>
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    inviteTenantUser: (tenantUserData) =>
      dispatch(TenantUserActions.inviteTenantUser(tenantUserData)),
  }
}

const mapStateToProps = (state) => ({
  inviteTenantUserRequestState:
    TenantUserSelectors.getInviteTenantUserRequestState(state),
})

InviteTenantUserModal.propTypes = {
  inviteTenantUser: PropTypes.func,
  inviteTenantUserRequestState: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(InviteTenantUserModal)
