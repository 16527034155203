import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import IFModal from 'Components/IFModal/IFModal'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import {
  AlertItem,
  ChargingSpecsAdd,
  IFsvg,
  IFTooltipIconsLoading,
  IFSkeleton,
} from 'Components'
import Styles from './AlertModal.module.css'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import { AlertList } from 'Containers'

const AlertModal = React.forwardRef(
  (
    {
      open,
      tenantUser,
      chargePointAlerts,
      chargePointSubscribedAlerts,
      updateChargePointAlerts,
      chargePoint,
      updateChargePointAlertsRequestState,
      fetchChargePointSubscribedAlertsRequestState,
      fetchChargePointAlertsRequestState,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const [isEdit, setIsEdit] = useState(false)
    const [filteredAlerts, setFilteredAlerts] = useState([])
    const [combinedAlerts, setCombinedAlerts] = useState(
      chargePointSubscribedAlerts,
    )
    const [alertChanges, setAlertChanges] = useState({})
    const alertListRef = useRef()

    useEffect(() => {
      setAlertChanges({})
    }, [isEdit])

    const handleAlertChange = (value, alertId, field) => {
      if (alertId in alertChanges) {
        if (alertChanges[alertId][field] !== value) {
          let newAlertChanges = alertChanges
          delete newAlertChanges[alertId]
          setAlertChanges(newAlertChanges)
        }
      } else {
        setAlertChanges((prev) => {
          return {
            ...prev,
            [alertId]: {
              [field]: value,
            },
          }
        })
      }
    }

    const handleSubmit = () => {
      if (Object.keys(alertChanges).length !== 0) {
        updateChargePointAlerts(chargePoint._id, alertChanges, () => {
          setIsEdit(false)
        })
      }
    }

    const handleSubmitRef = useRef(handleSubmit)
    const handleAlertChangeRef = useRef(handleAlertChange)

    useEffect(() => {
      handleSubmitRef.current = handleSubmit
      handleAlertChangeRef.current = handleAlertChange
    })

    useEffect(() => {
      setCombinedAlerts(chargePointSubscribedAlerts)
      fillFilteredAlerts()
    }, [chargePointSubscribedAlerts, chargePointAlerts])

    const resetValues = () => {
      fillFilteredAlerts()
      setCombinedAlerts(chargePointSubscribedAlerts)
    }

    const handleModalClose = () => {
      setIsEdit(false)
      setCombinedAlerts([])
      setFilteredAlerts([])
    }

    const fillFilteredAlerts = () => {
      const difference = chargePointAlerts.filter((alert) => {
        const found = chargePointSubscribedAlerts?.some((subscribedAlert) => {
          return subscribedAlert?._id === alert?._id
        })
        return !found
      })
      setFilteredAlerts(difference)
    }

    return (
      <IFModal
        ref={ref}
        title={t('AlertModal.Title')}
        width="48rem"
        open={open}
        onClose={handleModalClose}
      >
        <div className={Styles.Container}>
          <div
            className={isEdit ? Styles.ListWrapperEditing : Styles.ListWrapper}
          >
            <AlertList
              ref={alertListRef}
              isEdit={isEdit}
              combinedAlerts={combinedAlerts}
              onIconClick={(value, alertId) =>
                handleAlertChangeRef.current(value, alertId, 'chargePoints')
              }
              onToggle={(value, alertId) =>
                handleAlertChangeRef.current(value, alertId, 'isGlobal')
              }
            />
            {isEdit ? (
              <div className={Styles.AddButtonWrapper}>
                <ChargingSpecsAdd
                  key={1}
                  data={[...filteredAlerts]}
                  onSelect={(value) => {
                    const alertAdded = chargePointAlerts.find(
                      (alert) => alert._id === value,
                    )
                    setCombinedAlerts((prev) => {
                      return [...prev, alertAdded]
                    })
                    handleAlertChangeRef.current(true, value, 'chargePoints')
                    let newFilteredAlerts = filteredAlerts.filter((alert) => {
                      return alert._id !== value
                    })
                    setFilteredAlerts(newFilteredAlerts)
                  }}
                  RenderMenuItem={AlertItem}
                  RenderMenuItemkeys={[
                    'name',
                    'trackedStatus',
                    'duration',
                    'percentageAffected',
                    'isGlobal',
                  ]}
                  listProps={{
                    itemSize: () => 88,
                  }}
                />
              </div>
            ) : null}
          </div>
          {tenantUser.permissions.includes(
            InfinityEnums.TenantUserPermission.CAN_EDIT_ALERT,
          ) && (
            <div
              className={Styles.ActionsContainer}
              style={{ borderLeft: `1px solid ${Colors.DividerColor}` }}
            >
              {isEdit ? (
                <div className={Styles.ButtonsContainer}>
                  <IFTooltipIconsLoading
                    key={'AlertModal.Cancel'}
                    onClick={() => {
                      setIsEdit(false)
                      resetValues()
                    }}
                    tooltipPlacement="left"
                    title={t('AlertModal.Cancel')}
                    Icon={IFsvg.Cancel}
                    FilledIcon={IFsvg.Cancel}
                    iconColor={Colors.primary}
                    iconBackgroundColor={Colors.primary}
                    isDead={false}
                    isLoading={false}
                    size={24}
                    iconClassname={Styles.TooltipIcons}
                  />
                  <IFTooltipIconsLoading
                    key={'AlertModal.Done'}
                    onClick={() => {
                      handleSubmitRef.current()
                    }}
                    tooltipPlacement="left"
                    title={t('AlertModal.Done')}
                    Icon={IFsvg.Done}
                    FilledIcon={IFsvg.Done}
                    iconColor={Colors.primary}
                    iconBackgroundColor={Colors.primary}
                    isDead={false}
                    isLoading={
                      updateChargePointAlertsRequestState ===
                      RequestState.LOADING
                    }
                    size={24}
                    iconClassname={Styles.TooltipIcons}
                    animationDisabled={false}
                  />
                </div>
              ) : (
                <div className={Styles.ButtonsContainer}>
                  {fetchChargePointSubscribedAlertsRequestState ===
                    RequestState.LOADING ||
                  fetchChargePointAlertsRequestState ===
                    RequestState.LOADING ? (
                    <div className={Styles.iconContainerSkeleton}>
                      <IFSkeleton
                        variant="circular"
                        width="24px"
                        height="24px"
                      />
                    </div>
                  ) : (
                    <IFTooltipIconsLoading
                      key={'AlertModal.Edit'}
                      onClick={() => setIsEdit(true)}
                      tooltipPlacement="left"
                      title={t('AlertModal.Edit')}
                      isDead={false}
                      Icon={IFsvg.Edit}
                      FilledIcon={IFsvg.Edit}
                      iconColor={Colors.primary}
                      size={24}
                      iconClassname={Styles.TooltipIcons}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </IFModal>
    )
  },
)

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  chargePointAlerts: ChargePointSelectors.getChargePointAlerts(state),
  chargePointSubscribedAlerts:
    ChargePointSelectors.getChargePointSubscribedAlerts(state),
  updateChargePointAlertsRequestState:
    ChargePointSelectors.getUpdateChargePointAlertsRequestState(state),
  fetchChargePointSubscribedAlertsRequestState:
    ChargePointSelectors.getFetchChargePointSubscribedAlertsRequestState(state),
  fetchChargePointAlertsRequestState:
    ChargePointSelectors.getFetchChargePointAlertsRequestState(state),
})

function mapDispatchToProps(dispatch) {
  return {
    updateChargePointAlerts: (chargePointId, alertChanges, onResponse) =>
      dispatch(
        ChargePointActions.updateChargePointAlerts(
          chargePointId,
          alertChanges,
          onResponse,
        ),
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(AlertModal)
