import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchTenantUserList: null,
  fetchTenantUserListLoading: null,
  fetchTenantUserListSuccess: ['tenantUsers'],
  fetchTenantUserListFail: ['error'],

  updateTenantUser: [
    'tenantUserId',
    'permissions',
    'tenantUserItemRef',
    'onSuccess',
    'onError',
  ],
  updateTenantUserLoading: null,
  updateTenantUserSuccess: ['tenantUserId', 'tenantUser'],
  updateTenantUserFail: ['error'],

  deleteTenantUser: ['tenantUserId', 'ref'],
  deleteTenantUserLoading: null,
  deleteTenantUserSuccess: ['tenantUserId'],
  deleteTenantUserFail: ['error'],

  fetchInviteTenantUser: ['inviteTenantUserId'],
  fetchInviteTenantUserLoading: null,
  fetchInviteTenantUserSuccess: ['inviteTenantUser'],
  fetchInviteTenantUserFail: ['error'],
  setInviteTenantUserStatus: ['status'],

  fetchInviteTenantUserList: null,
  fetchInviteTenantUserListLoading: null,
  fetchInviteTenantUserListSuccess: ['inviteTenantUsers'],
  fetchInviteTenantUserListFail: ['error'],

  inviteTenantUser: ['tenantUserData'],
  inviteTenantUserLoading: null,
  inviteTenantUserSuccess: ['tenantUser'],
  inviteTenantUserFail: ['error'],

  deleteInviteTenantUser: ['inviteTenantUserId', 'ref'],
  deleteInviteTenantUserLoading: null,
  deleteInviteTenantUserSuccess: ['inviteTenantUserId'],
  deleteInviteTenantUserFail: ['error'],

  sendTenantUserEmail: ['email', 'ref'],
  sendTenantUserEmailLoading: null,
  sendTenantUserEmailSuccess: null,
  sendTenantUserEmailFail: ['error'],

  createTenantUser: ['tenantUser'],
  createTenantUserLoading: null,
  createTenantUserSuccess: null,
  createTenantUserFail: ['error'],

  fetchSubscriptionsList: null,
  fetchSubscriptionsListLoading: null,
  fetchSubscriptionsListSuccess: ['subscriptions'],
  fetchSubscriptionsListFail: ['error'],

  fetchPrivacySchedule: null,
  fetchPrivacyScheduleLoading: null,
  fetchPrivacyScheduleSuccess: ['PrivacySchedule'],
  fetchPrivacyScheduleFail: ['error'],
  fetchAccessGroupsList: null,
  fetchAccessGroupsListLoading: null,
  fetchAccessGroupsListSuccess: ['accessGroups'],
  fetchAccessGroupsListFail: ['error'],
})

export const TenantUserTypes = Types
export default Creators
