// Makes sure only thing changed in filter is the city
export function isCityAdded(oldFilter, newFilter) {
  oldFilter.forEach((filter, index) => {
    if (filter.value !== newFilter[index]['value']) return false
  })
  const CityFilter = oldFilter.find((filter) => filter.title === 'City')
  const newCityFilter = newFilter.find((filter) => filter.title === 'City')

  if (CityFilter && newCityFilter)
    if (CityFilter.data.length !== newCityFilter.data.length) return true
  return false
}
