import Styles from './UploadDiagnosticsListItemSkeleton.module.css'
import { IFSkeleton } from 'Components'
import React from 'react'

const UploadDiagnosticsListItemSkeleton = () => {
  return (
    <div className={Styles.Container}>
      <div className={Styles.UploadIconContainer}>
        <IFSkeleton variant="circular" width="1.375rem" height="1.375rem" />
      </div>
      <div className={Styles.FileNameContainer}>
        <IFSkeleton variant="text" width="20rem" height="1.375rem" />
      </div>
      <div className={Styles.CreatedAtContainer}>
        <IFSkeleton variant="text" width="5rem" height="1.375rem" />
      </div>
    </div>
  )
}

export default UploadDiagnosticsListItemSkeleton
