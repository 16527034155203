const getTenantUsers = (state) => {
  return state.main.tenantUserState.tenantUsers
}

const getInviteTenantUsers = (state) => {
  return state.main.tenantUserState.inviteTenantUsers
}

const getFetchTenantUserListRequestState = (state) => {
  return state.main.tenantUserState.fetchTenantUserListRequestState
}

const getUpdateTenantUserListRequestState = (state) => {
  return state.main.tenantUserState.updateTenantUserListRequestState
}

const getInviteTenantUserRequestState = (state) => {
  return state.main.tenantUserState.inviteTenantUserRequestState
}

const getFetchInviteTenantUserListRequestState = (state) => {
  return state.main.tenantUserState.fetchInviteTenantUserListRequestState
}

const getDeleteTenantUserRequestState = (state) => {
  return state.main.tenantUserState.deleteTenantUserRequestState
}

const getDeleteInviteTenantUserRequestState = (state) => {
  return state.main.tenantUserState.deleteInviteTenantUserRequestState
}

const getSendTenantUserEmailRequestState = (state) => {
  return state.main.tenantUserState.sendTenantUserEmailRequestState
}

const getFetchInviteTenantUserRequestState = (state) => {
  return state.main.tenantUserState.fetchInviteTenantUserRequestState
}

const getInviteTenantUser = (state) => {
  return state.main.tenantUserState.inviteTenantUser
}

const getCreateTenantUserRequestState = (state) => {
  return state.main.tenantUserState.createTenantUserRequestState
}

const getInviteTenantUserStatus = (state) => {
  return state.main.tenantUserState.inviteTenantUserStatus
}
const getSubscriptionsList = (state) => {
  return state.main.tenantUserState.subscriptionsList
}

const getFetchSubscriptionListRequestState = (state) => {
  return state.main.tenantUserState.fetchSubscriptionListRequestState
}

const getPrivacySchedule = (state) => {
  return state.main.tenantUserState.privacySchedule
}

const getFetchPrivacyScheduleRequestState = (state) => {
  return state.main.tenantUserState.fetchPrivacyScheduleRequestState
}
const getAccessGroupsList = (state) => {
  return state.main.tenantUserState.accessGroupsList
}

const getFetchAccessGroupsListRequestState = (state) => {
  return state.main.tenantUserState.fetchAccessGroupsListRequestState
}

const TenantUserSelectors = {
  getTenantUsers,
  getInviteTenantUsers,
  getFetchTenantUserListRequestState,
  getUpdateTenantUserListRequestState,
  getInviteTenantUserRequestState,
  getFetchInviteTenantUserListRequestState,
  getDeleteTenantUserRequestState,
  getDeleteInviteTenantUserRequestState,
  getSendTenantUserEmailRequestState,
  getFetchInviteTenantUserRequestState,
  getInviteTenantUser,
  getCreateTenantUserRequestState,
  getInviteTenantUserStatus,
  getSubscriptionsList,
  getFetchSubscriptionListRequestState,
  getPrivacySchedule,
  getFetchPrivacyScheduleRequestState,
  getAccessGroupsList,
  getFetchAccessGroupsListRequestState,
}

export default TenantUserSelectors
