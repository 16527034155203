import * as React from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IFText from 'Components/IFText/IFText'
import Styles from './ChargePointLogListItem.module.css'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthWestIcon from '@mui/icons-material/SouthWest'
import CheckIcon from '@mui/icons-material/Check'
import colors from 'Theme/Colors'
import JSONPretty from 'react-json-pretty'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Moment from 'moment'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { isPropsMatch } from 'Utils/PropsMatch'

function ChargePointLogListItem({
  isIncoming,
  action,
  date,
  callId,
  message,
  ...props
}) {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()

  const AccordionSummary = withStyles({
    root: {
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px',
      },
    },
    expanded: {},
  })(MuiAccordionSummary)

  const useStyles = makeStyles((theme) => ({
    accordionSummaryContainer: {
      '&:hover': {
        backgroundColor: colors.ChargePointLogListItemHover,
      },
    },
    copyIconContainer: {
      '&:hover': {
        backgroundColor: colors.ChargePointLogListItemCopyHover,
      },
    },
  }))
  const classes = useStyles()
  const animate = () => {
    setIsCopied(!isCopied)
    setTimeout(() => setIsCopied(false), 2000)
  }
  return (
    <div {...props}>
      <Accordion
        className={Styles.accordion}
        {...(!message ? { expanded: false } : {})}
        style={{
          boxShadow: `0px 0px 0px ${colors.ChargePointLogListItemBoxShadow}`,
          borderRadius: '0px',
        }}
      >
        <AccordionSummary
          expandIcon={
            message ? <ExpandMoreIcon /> : <div className={Styles.EmptyIcon} />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={[
            Styles.accordionSummary,
            classes.accordionSummaryContainer,
          ]}
        >
          <div className={Styles.container}>
            {isIncoming ? (
              <SouthWestIcon
                sx={{
                  height: '16px !important',
                  width: '16px !important',
                  alignSelf: 'center',
                }}
                className={Styles.accordionicon}
              />
            ) : (
              <NorthEastIcon
                sx={{
                  height: '16px !important',
                  width: '16px !important',
                  alignSelf: 'center',
                }}
                className={Styles.accordionicon}
              />
            )}
            <IFText className={Styles.accordionLeftText}>{action}</IFText>
            <IFText className={Styles.accordionRightText}>
              {Moment(date).format('MMM DD, yyy \xa0 HH:mm:ss')}
            </IFText>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={Styles.DetailsContainer}>
            {callId ? (
              <React.Fragment>
                <IFText className={Styles.callIdText}>
                  {`${t('ChargePointLogListItem.callID')} : ${callId}`}
                </IFText>
                {message ? (
                  <div
                    className={Styles.codeSnippetContainer}
                    style={{
                      background: `${colors.ChargePointLogListItemCodeSnippet}`,
                    }}
                  >
                    <JSONPretty
                      id="json-pretty"
                      className={Styles.jsonPretty}
                      data={message}
                      mainStyle="margin:0em"
                    />
                    {Object.keys(JSON.parse(message)).length !== 0 ? (
                      <div
                        className={`${Styles.iconContainer} ${classes.copyIconContainer}`}
                        onClick={() => {
                          animate()
                          navigator.clipboard.writeText(JSON.stringify(message))
                        }}
                      >
                        <CheckIcon
                          className={`${Styles.icon} ${
                            isCopied ? Styles.visible : Styles.hide
                          }`}
                          style={{
                            color: colors.primary,
                          }}
                        />
                        <ContentCopyIcon
                          sx={{
                            color: colors.ChargePointLogListItemCopy,
                          }}
                          className={`${Styles.icon} ${
                            !isCopied ? Styles.visible : Styles.hide
                          }`}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </React.Fragment>
            ) : null}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
ChargePointLogListItem.propTypes = {
  isIncoming: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  callId: PropTypes.string,
  message: PropTypes.string,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'isIncoming',
    'action',
    'date',
    'callId',
    'message',
  ])
}
export default React.memo(ChargePointLogListItem, shouldSkipRender)
