import React, { useEffect, useRef } from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Styles from './ChargingProfileListItem.module.css'
import Colors from 'Theme/Colors'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { formatChargingSchedulePeriods } from '../../Utils/Format'
import { ChargingScheduleChart, IFButton, IFDialog, IFText } from 'Components'
import Icon from '@material-ui/core/Icon'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import RequestState from 'Enums/RequestState'

const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px !important',
    width: '100%',
  },
})

const AccordionSummary = withStyles({
  root: {
    borderRadius: '5px',
    padding: '8px',
    '&:hover': {
      backgroundColor: Colors.ChargePointLogListItemHover,
    },
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const ChargingProfileListItem = ({
  name,
  maxCurrent,
  chargingSchedulePeriods,
  chargingSchedulePeriodsOffset,
  clearChargingProfile,
  clearChargePointChargingProfileRequestState,
  hideIndividualAxis,
  useAccordion = true,
}) => {
  const [chargingPeriods, setChargingPeriods] = useState([])
  const clearChargingProfileRef = useRef()
  const { t } = useTranslation()
  const classes = useStyles()

  useEffect(() => {
    const formattedChargingPeriods = formatChargingSchedulePeriods(
      chargingSchedulePeriods,
      chargingSchedulePeriodsOffset,
    )
    setChargingPeriods(formattedChargingPeriods)
  }, [chargingSchedulePeriods])

  return (
    <div className={Styles.Container}>
      {useAccordion ? (
        <Accordion className={classes.root}>
          <AccordionSummary
            classes={{ content: classes.content }}
            expandIcon={<ExpandMoreIcon />}
            className={classes.AccordionSummary}
          >
            <div className={Styles.AccordionSummaryContainer}>
              <div className={Styles.NameContainer}>
                <IFText>{name}</IFText>
              </div>
              <div className={Styles.MaxCurrentContainer}>
                <IFText>{`${t(
                  'ChargingProfile.MaxCurrent',
                )}: ${maxCurrent} A`}</IFText>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={Styles.AccordionDetailsContainer}>
              <div className={Styles.ChargingScheduleContainer}>
                {chargingPeriods.map((schedule, index) => (
                  <div className={index !== 0 ? Styles.AddTopMargin : {}}>
                    <ChargingScheduleChart
                      day={schedule.day}
                      seriesData={schedule.periods}
                      chartHeight={50}
                      hideAxis={hideIndividualAxis}
                    />
                  </div>
                ))}
              </div>
              {hideIndividualAxis ? (
                <div className={Styles.BottomContainer}>
                  <div className={Styles.EmptyDiv} />
                  <div className={Styles.LabelsContainer}>
                    <IFText className={Styles.LabelWidth}>12:00 AM</IFText>
                    <div className={Styles.LabelWidth}>
                      <IFText className={Styles.LabelMargin}>06:00 AM</IFText>
                    </div>
                    <div className={Styles.LabelWidth}>
                      <IFText className={Styles.LabelMargin}>12:00 PM</IFText>
                    </div>
                    <div className={Styles.LastLabelContainer}>
                      <IFText className={Styles.LabelMargin}>06:00 PM</IFText>
                      <IFText style={{ marginTop: 0 }}>11:59 PM</IFText>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className={Styles.ClearProfileContainer}>
                <IFButton
                  color={Colors.primary}
                  isFill={true}
                  text={t('ChargingProfile.ClearProfile')}
                  onClick={() => clearChargingProfileRef.current.show()}
                  size="xsm"
                  isLoading={false}
                />
              </div>
            </div>
          </AccordionDetails>
          <IFDialog
            ref={clearChargingProfileRef}
            title={t('ChargingProfile.RemoveChargingProfileTitle')}
            subtitle={t('ChargingProfile.RemoveChargingProfileSubtitle')}
            onAction={clearChargingProfile}
            actionButtonIcon={
              <Icon
                sx={{ width: '18px !important', height: '18px !important' }}
                component={DeleteOutlineIcon}
              />
            }
            actionButtonText={t('ChargingProfile.ActionButton')}
            actionButtonColor={Colors.red}
            isLoading={
              clearChargePointChargingProfileRequestState ===
              RequestState.LOADING
            }
          />
        </Accordion>
      ) : (
        <div className={Styles.AccordionDetailsContainer}>
          <div className={Styles.ChargingScheduleContainer}>
            {chargingPeriods.map((schedule, index) => (
              <div className={index !== 0 ? Styles.AddTopMargin : {}}>
                <ChargingScheduleChart
                  day={schedule.day}
                  seriesData={schedule.periods}
                  chartHeight={50}
                  hideAxis={hideIndividualAxis}
                />
              </div>
            ))}
          </div>
          {hideIndividualAxis ? (
            <div className={Styles.BottomContainer}>
              <div className={Styles.EmptyDiv} />
              <div className={Styles.LabelsContainer}>
                <IFText className={Styles.LabelWidth}>12:00 AM</IFText>
                <div className={Styles.LabelWidth}>
                  <IFText className={Styles.LabelMargin}>06:00 AM</IFText>
                </div>
                <div className={Styles.LabelWidth}>
                  <IFText className={Styles.LabelMargin}>12:00 PM</IFText>
                </div>
                <div className={Styles.LastLabelContainer}>
                  <IFText className={Styles.LabelMargin}>06:00 PM</IFText>
                  <IFText style={{ marginTop: 0 }}>11:59 PM</IFText>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

ChargingProfileListItem.propTypes = {
  name: PropTypes.string,
  maxCurrent: PropTypes.number,
  chargingSchedulePeriods: PropTypes.arrayOf(PropTypes.object),
  chargingSchedulePeriodsOffset: PropTypes.number,
  clearChargingProfile: PropTypes.func,
  clearChargePointChargingProfileRequestState: PropTypes.number,
  hideIndividualAxis: PropTypes.bool,
}

export default ChargingProfileListItem
