import Styles from './InviteUserModal.module.css'
import Colors from 'Theme/Colors'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import InfinityEnums from 'Enums/InfinityEnums'
import UserActions from 'Stores/User/Actions'
import UserSelectors from 'Stores/User/Selectors'
import {
  IFModal,
  IFButton,
  IFTextInput,
  IFText,
  IFToastMessage,
} from 'Components'
import React, { useState, useEffect, useRef } from 'react'
import RequestState from 'Enums/RequestState'
import PropTypes from 'prop-types'
import { Divider } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import { xlsxToJson, csvToJson } from '../../Utils/DataConversion'
import { toaster } from 'rsuite'
import Icon from '@material-ui/core/Icon'
import CancelIcon from '@mui/icons-material/Cancel'
import { AccessGroupsContainer, SubscriptionContainer } from 'Containers'

const InviteUserModal = React.forwardRef(
  ({ inviteUser, inviteUserRequestState, inviteUserBulk }, ref) => {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const [uploadedFile, setUploadedFile] = useState(null)
    const [jsonFile, setJsonFile] = useState({})
    const [addedFile, setAddedFile] = useState(false)
    const [typedText, setTypedText] = useState(false)
    const [canContinue, setCanContinue] = useState(false)
    const [clickedNext, setClickedNext] = useState(false)
    const [receivedSubscriptions, setReceivedSubscriptions] = useState({
      received: false,
      subscriptions: [],
    })
    const [receivedAccessGroups, setReceivedAccessGroups] = useState({
      received: false,
      accessGroups: [],
    })

    const accessGroupsRef = useRef()
    const subscriptionsRef = useRef()

    let errorFlag = false

    useEffect(() => {
      if (inviteUserRequestState === RequestState.SUCCEEDED) {
        ref.current.handleClose()
      }
    }, [inviteUserRequestState])

    useEffect(() => {
      if (receivedAccessGroups.received && receivedSubscriptions.received) {
        handleSubmit()
      }
    }, [receivedSubscriptions, receivedAccessGroups])

    const { t } = useTranslation()

    const resetErrors = () => {
      setEmailError('')
    }

    const handleModalClose = () => {
      setEmail('')
      setEmailError('')
      setCanContinue(false)
      setClickedNext(false)
      setAddedFile(false)
      setTypedText(false)
      setUploadedFile(null)
    }

    const handleXlsx = (file) => {
      const json = xlsxToJson(file)
      json.then((data) => setJsonFile(data))
    }

    const handleCsv = (file) => {
      const json = csvToJson(file)
      json.then((parsedFile) => setJsonFile(parsedFile.data))
    }

    const handleOnDrop = (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        if (acceptedFiles[0].name.includes('csv')) {
          handleCsv(acceptedFiles[0])
          setCanContinue(true)
        } else if (acceptedFiles[0].name.includes('xlsx')) {
          handleXlsx(acceptedFiles[0])
          setCanContinue(true)
        } else {
          toaster.push(
            <IFToastMessage
              type={'error'}
              text={t('UsersPage.FileNotSupported')}
            />,
            { placement: 'topEnd' },
          )
          errorFlag = true
        }
        setUploadedFile(acceptedFiles)
        setAddedFile(true)

        resetErrors()
      }
    }

    const handleSubmit = () => {
      if (typedText) {
        const data = {
          email: email,
          subscriptions: receivedSubscriptions.subscriptions,
          accessGroups: receivedAccessGroups.accessGroups,
        }
        inviteUser(data, () =>
          toaster.push(
            <IFToastMessage
              type="success"
              text={
                addedFile
                  ? t('UsersPage.InvitationsSuccess')
                  : t('UsersPage.InvitationSuccess')
              }
            />,
            { placement: 'topEnd' },
          ),
        )
      } else if (addedFile && !errorFlag) {
        const data = {
          emails: jsonFile,
          subscriptions: receivedSubscriptions.subscriptions,
          accessGroups: receivedAccessGroups.accessGroups,
        }
        inviteUserBulk(data, () =>
          toaster.push(
            <IFToastMessage
              type="success"
              text={
                addedFile
                  ? t('UsersPage.InvitationsSuccess')
                  : t('UsersPage.InvitationSuccess')
              }
            />,
            { placement: 'topEnd' },
          ),
        )
      } else errorFlag = false
    }

    return (
      <IFModal
        onClose={handleModalClose}
        title={t('UsersPage.InviteNewUser')}
        ref={ref}
        canGoBack={clickedNext}
        onBackClicked={() => setClickedNext(false)}
      >
        <div
          className={
            clickedNext ? Styles.InputWrapperRight : Styles.InputWrapperLeft
          }
        >
          <div
            className={
              clickedNext
                ? [Styles.EmailDivShow, Styles.AnimateDropZoneHeightOut].join(
                    ' ',
                  )
                : [Styles.EmailDivShow, Styles.AnimateDropZoneHeightIn].join(
                    ' ',
                  )
            }
          >
            <div className={addedFile ? Styles.FadeOut : Styles.FadeIn}>
              <div className={Styles.InputContainer}>
                <IFText className={Styles.Label}>{t('UsersPage.Email')}</IFText>
                <IFTextInput
                  style={{ caretColor: Colors.primary }}
                  className={Styles.Input}
                  isFixed={true}
                  value={email}
                  name="email"
                  type="text"
                  onChange={(e) => {
                    setEmail(e.target.value)
                    if (e.target.value.length > 0) {
                      setTypedText(true)
                    } else setTypedText(false)
                    if (emailError.length > 0) {
                      e.target.value.length === 0
                        ? setEmailError(t('UsersPage.Required'))
                        : setEmailError('')
                    }
                    const submit = e.target.value.length > 0
                    setCanContinue(submit)
                  }}
                  onBlur={(e) => {
                    e.target.value.length === 0
                      ? setEmailError(t('UsersPage.Required'))
                      : setEmailError('')
                    const submit = e.target.value.length > 0
                    setCanContinue(submit)
                  }}
                  {...(emailError.length > 0 ? { errorText: emailError } : {})}
                />
              </div>
            </div>
            <div
              className={
                typedText || addedFile
                  ? [Styles.DividerWrapper, Styles.FadeOut].join(' ')
                  : [Styles.DividerWrapper, Styles.FadeIn].join(' ')
              }
            >
              <div className={Styles.DividerContainer}>
                <Divider />
              </div>
              <div className={Styles.DividerText}>
                <IFText style={{ color: Colors.text }}>
                  {t('UsersPage.Or')}
                </IFText>
              </div>
              <div className={Styles.DividerContainer}>
                <Divider />
              </div>
            </div>

            <div
              className={typedText ? Styles.FadeOut : Styles.FadeIn}
              style={{ cursor: 'pointer' }}
            >
              <Dropzone
                multiple={false}
                onDrop={(acceptedFiles) => handleOnDrop(acceptedFiles)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        className={
                          addedFile ? Styles.DropZoneSelected : Styles.DropZone
                        }
                        style={{ backgroundColor: Colors.DropZoneBackground }}
                      >
                        {uploadedFile == null ? (
                          <>
                            <FileUploadIcon />
                            <IFText>{t('UsersPage.DragAndDrop')}</IFText>
                          </>
                        ) : (
                          <>
                            <InsertDriveFileIcon fontSize="large" />
                            <div
                              className={Styles.CloseIconContainer}
                              onClick={(e) => {
                                e.stopPropagation()
                                setUploadedFile(null)
                                setAddedFile(false)
                                setCanContinue(false)
                              }}
                            >
                              <Icon
                                className={Styles.CloseIcon}
                                component={CancelIcon}
                              />
                            </div>
                            <IFText>{uploadedFile[0].name}</IFText>
                          </>
                        )}
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>

            <div className={Styles.Next}>
              <IFButton
                text={t('UsersPage.Next')}
                color={Colors.primary}
                isFill={true}
                isLoading={false}
                size="sm"
                onClick={() => setClickedNext(true)}
                isDead={!canContinue}
              ></IFButton>
            </div>
          </div>

          <div
            className={
              clickedNext
                ? [
                    Styles.SpecificationsContainerVisible,
                    Styles.AnimateHeightIn,
                  ].join(' ')
                : [
                    Styles.SpecificationsContainerVisible,
                    Styles.AnimateHeightOut,
                  ].join(' ')
            }
          >
            <div className={Styles.SpecificationsWrapper}>
              <div className={Styles.EmailContainer}>
                {typedText ? (
                  <IFText style={{ color: Colors.text }}>{`${t(
                    'UsersPage.Email',
                  )}: ${email}`}</IFText>
                ) : addedFile ? (
                  <IFText style={{ color: Colors.text }}>{`${t(
                    'UsersPage.UploadedFile',
                  )}: ${uploadedFile[0].name}`}</IFText>
                ) : null}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <AccessGroupsContainer
                  key={'AccessGroupsContainerId'}
                  ref={accessGroupsRef}
                  renderFor={InfinityEnums.ChargePointSpecsContainer.MODAL}
                  isEditing={true}
                  maxListHeight={136}
                  onModalSubmit={(addAccessGroups) => {
                    setReceivedAccessGroups({
                      received: true,
                      accessGroups: addAccessGroups,
                    })
                  }}
                />
                <div style={{ width: '5rem' }}></div>
                <SubscriptionContainer
                  ref={subscriptionsRef}
                  isEditing={true}
                  renderFor={InfinityEnums.ChargePointSpecsContainer.MODAL}
                  maxListHeight={136}
                  onModalSubmit={(addSubscriptions) => {
                    setReceivedSubscriptions({
                      received: true,
                      subscriptions: addSubscriptions,
                    })
                  }}
                />
              </div>
              <div className={Styles.Submit}>
                <IFButton
                  text={t('UsersPage.Submit')}
                  color={Colors.primary}
                  isFill={true}
                  isLoading={inviteUserRequestState === RequestState.LOADING}
                  size="sm"
                  onClick={() => {
                    accessGroupsRef.current.handleSubmit(false)
                    subscriptionsRef.current.handleSubmit(false)
                  }}
                  isDead={false}
                />
              </div>
            </div>
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    inviteUser: (invitations, onSuccess) =>
      dispatch(UserActions.inviteUser(invitations, onSuccess)),
    inviteUserBulk: (invitations, onSuccess) =>
      dispatch(UserActions.inviteUserBulk(invitations, onSuccess)),
  }
}

const mapStateToProps = (state) => ({
  inviteUserRequestState: UserSelectors.getInviteUserRequestState(state),
})

InviteUserModal.propTypes = {
  inviteUser: PropTypes.func,
  inviteUserRequestState: PropTypes.number,
  inviteUserBulk: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(InviteUserModal)
