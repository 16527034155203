import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import Styles from './IFToggle.module.css'
import styled from 'styled-components'
import IFText from 'Components/IFText/IFText'
import { Colors } from 'Theme'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { isPropsMatch } from 'Utils/PropsMatch'
import { makeStyles } from '@material-ui/core'

const getTextWidth = (txt, font) => {
  let element = document.createElement('canvas')
  let context = element.getContext('2d')
  context.font = font
  return context.measureText(txt).width
}

const ratioToggleHeight = 0.85
const ratioTopHeight = 0.075

const CustomLabel = styled.label`
  background: ${(props) => props.backgroundColor + Colors.HexTransparent.T85};
  &:hover {
    background: ${(props) => props.backgroundColor};
  }
  &:after {
    height: ${(props) => props.height}px;
    width: ${(props) => props.height}px;
    top: ${(props) => props.top}px;
    left: ${(props) => props.top}px;
    background: ${Colors.white};
    background-image: ${(props) =>
      props.checkedText
        ? props.isChecked
          ? `url('data:image/svg+xml,${props.encodedCheckedIcon}')`
          : `url('data:image/svg+xml,${props.encodedUncheckedIcon}')`
        : null};
    background-repeat: no-repeat;
    background-position: center;
  }
  &:active:after {
    width: ${(props) => props.toggleActive}px;
  }
`

const CustomInput = styled.input`
  &:checked + label:after {
    left: calc(100% - ${(props) => props.margin}px);
  }
`

export const useAnimationStyles = ({ height }) => {
  const classes = makeStyles({
    initialCheckedVisible: {
      opacity: 1,
      marginRight: height * ratioToggleHeight,
    },
    checkAnimation: {
      animation: `$animateCheck 0.15s normal forwards`,
    },
    checkAnimationReverse: {
      animation: `$animateCheckReverse 0.15s reverse forwards`,
    },
    '@keyframes animateCheck': {
      '0%': {
        opacity: 0,
      },
      '30%': {
        opacity: 0,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        opacity: 1,
        marginRight: height * ratioToggleHeight,
      },
    },
    '@keyframes animateCheckReverse': {
      '0%': {
        opacity: 0,
      },
      '45%': {
        opacity: 0,
      },
      '65%': {
        opacity: 1,
      },
      '100%': {
        opacity: 1,
        marginRight: height * ratioToggleHeight,
      },
    },
    uncheckAnimation: {
      animation: `$animateUncheck 0.15s normal forwards`,
    },
    uncheckAnimationReverse: {
      animation: `$animateUncheckReverse 0.15s reverse forwards`,
    },
    '@keyframes animateUncheck': {
      '0%': {
        opacity: 1,
      },
      '30%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0,
        marginRight: height * ratioToggleHeight,
      },
    },
    '@keyframes animateUncheckReverse': {
      '0%': {
        opacity: 1,
      },
      '45%': {
        opacity: 1,
      },
      '65%': {
        opacity: 0,
      },
      '100%': {
        opacity: 0,
        marginRight: height * ratioToggleHeight,
      },
    },
  })
  return classes()
}

const IFToggle = React.forwardRef(
  (
    {
      fontWeight = 'normal',
      height = 20,
      fontSize = 12,
      defaultChecked = false,
      readOnly = false,
      CheckedIcon,
      UncheckedIcon,
      checkedText = '',
      uncheckedText = '',
      checkedTextColor = Colors.white,
      uncheckedTextColor = Colors.white,
      checkedColor = Colors.primary,
      uncheckedColor = Colors.ToggleDisabled,
      marginLeftRight = 6,
      checked,
      onChange = () => {},
    },
    ref,
  ) => {
    const [isChecked, setIsChecked] = useState(defaultChecked)
    const [checkedIconWidth, setCheckedIconWidth] = useState(0)
    const [uncheckedIconWidth, setUnCheckedIconWidth] = useState(0)
    const [isClicked, setIsClicked] = useState(false)

    const checkedIconRef = useRef()
    const id = 'switch' + uuidv4()
    const uncheckedIconRef = useRef()

    const classes = useAnimationStyles({ height })

    useEffect(() => {
      setIsClicked(false)
    }, [readOnly])

    useEffect(() => {
      if (checkedIconRef && checkedIconRef.current) {
        setCheckedIconWidth(checkedIconRef.current.clientWidth)
      }
    }, [checkedIconRef])
    useEffect(() => {
      if (uncheckedIconRef && uncheckedIconRef.current) {
        setUnCheckedIconWidth(uncheckedIconRef.current.clientWidth)
      }
    }, [uncheckedIconRef])
    useEffect(() => {
      if (checked === false || checked === true) {
        setIsChecked(checked)
      }
    }, [checked])
    useEffect(() => {
      onChange(isChecked)
    }, [isChecked])

    const getValue = () => {
      return isChecked
    }
    const toggleValue = () => {
      setIsChecked(!isChecked)
    }
    const setValue = (value) => {
      setIsChecked(value)
    }
    useImperativeHandle(ref, () => ({
      getValue,
      toggleValue,
      setValue,
    }))

    return (
      <div
        className={
          isClicked
            ? `${Styles.Container} ${Styles.transitionWidth}`
            : Styles.Container
        }
        style={{
          width:
            height * ratioTopHeight +
            height * ratioToggleHeight +
            marginLeftRight +
            (isChecked
              ? checkedText
                ? getTextWidth(
                    checkedText,
                    `${fontWeight} ${fontSize}px ProximaNova`,
                  )
                : checkedIconWidth
              : uncheckedText
              ? getTextWidth(
                  uncheckedText,
                  `${fontWeight} ${fontSize}px ProximaNova`,
                )
              : uncheckedIconWidth) +
            marginLeftRight,
        }}
      >
        <div
          className={
            (isClicked
              ? isChecked
                ? classes.checkAnimation
                : classes.checkAnimationReverse
              : isChecked
              ? classes.initialCheckedVisible
              : '') + ` ${Styles.IconTextContainer}`
          }
          style={{
            right: marginLeftRight,
          }}
        >
          {checkedText ? (
            <IFText
              style={{
                color: checkedTextColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
              }}
            >
              {checkedText}
            </IFText>
          ) : (
            <div ref={checkedIconRef} className={Styles.IconContainer}>
              {CheckedIcon}
            </div>
          )}
        </div>
        <div
          className={
            (isClicked
              ? isChecked
                ? classes.uncheckAnimation
                : classes.uncheckAnimationReverse
              : isChecked
              ? ''
              : Styles.initialUncheckedVisible) + ` ${Styles.IconTextContainer}`
          }
          style={{
            right: marginLeftRight,
          }}
        >
          {uncheckedText ? (
            <IFText
              style={{
                color: uncheckedTextColor,
                fontSize: fontSize,
                fontWeight: fontWeight,
              }}
            >
              {uncheckedText}
            </IFText>
          ) : (
            <div ref={uncheckedIconRef} className={Styles.IconContainer}>
              {UncheckedIcon}
            </div>
          )}
        </div>
        <CustomInput
          className={Styles.Input}
          type="checkbox"
          autoFocus={false}
          id={id}
          checked={isChecked}
          onChange={(e) => {
            setIsClicked(true)
            setIsChecked(e.target.checked)
          }}
          disabled={readOnly}
          margin={height * ratioTopHeight}
        />
        <CustomLabel
          height={height * ratioToggleHeight}
          top={height * ratioTopHeight}
          className={
            isClicked
              ? `${Styles.Label} ${Styles.transitionWidthBackground}`
              : Styles.Label
          }
          labelHeightAfter={height * ratioTopHeight}
          encodedCheckedIcon={CheckedIcon}
          encodedUncheckedIcon={UncheckedIcon}
          isChecked={isChecked}
          checkedText={checkedText}
          toggleActive={height * ratioToggleHeight + height * ratioTopHeight}
          backgroundColor={isChecked ? checkedColor : uncheckedColor}
          style={{
            height: height,
            width:
              height * ratioTopHeight +
              height * ratioToggleHeight +
              marginLeftRight +
              (isChecked
                ? checkedText
                  ? getTextWidth(
                      checkedText,
                      `${fontWeight} ${fontSize}px ProximaNova`,
                    )
                  : checkedIconWidth
                : uncheckedText
                ? getTextWidth(
                    uncheckedText,
                    `${fontWeight} ${fontSize}px ProximaNova`,
                  )
                : uncheckedIconWidth) +
              marginLeftRight,
          }}
          for={id}
        />
      </div>
    )
  },
)
IFToggle.propTypes = {
  height: PropTypes.number,
  fontSize: PropTypes.number,
  defaultChecked: PropTypes.bool,
  readOnly: PropTypes.bool,
  CheckedIcon: PropTypes.element,
  UncheckedIcon: PropTypes.element,
  checkedText: PropTypes.string,
  uncheckedText: PropTypes.string,
  marginLeftRight: PropTypes.number,
  checked: PropTypes.bool,
  fontWeight: PropTypes.string,
  checkedTextColor: PropTypes.string,
  uncheckedTextColor: PropTypes.string,
  checkedColor: PropTypes.string,
  uncheckedColor: PropTypes.string,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'height',
    'fontSize',
    'readOnly',
    'CheckedIcon',
    'UncheckedIcon',
    'checkedText',
    'uncheckedText',
    'marginLeftRight',
    'checked',
    'fontWeight',
    'checkedTextColor',
    'uncheckedTextColor',
    'checkedColor',
    'uncheckedColor',
  ])
}
export default React.memo(IFToggle, shouldSkipRender)
