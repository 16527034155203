import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import { IFButton, IFModal, IFSkeleton, IFText } from 'Components'
import { UploadDiagnosticsList, RequestDiagnosticsModal } from 'Containers'
import Styles from './UploadDiagnosticsModal.module.css'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import InfinityEnums from 'Enums/InfinityEnums'
import OCPPServices from '../../Services/OCPPService'
import PropTypes from 'prop-types'
import RefreshIcon from '@mui/icons-material/Refresh'
import moment from 'moment'
import AuthSelectors from 'Stores/Auth/Selectors'

const UploadDiagnosticsModal = React.forwardRef(
  (
    {
      open,
      requestChargePointUploadDiagnosticsRequestState,
      chargePoint,
      uploadDiagnosticsList,
      diagnosticsStatusNotificationRequestState,
      fetchChargePointUploadDiagnosticsRequestState,
      tenantUser,
    },
    ref,
  ) => {
    useEffect(() => {
      if (uploadDiagnosticsList.length > 0) {
        setIsDiagnosticsRequestDisabled(isUploading())
      } else setIsDiagnosticsRequestDisabled(false)
    }, [uploadDiagnosticsList])

    useEffect(() => {
      if (
        requestChargePointUploadDiagnosticsRequestState ===
        RequestState.SUCCEEDED
      )
        requestDiagnosticsRef.current.handleClose()
    }, [requestChargePointUploadDiagnosticsRequestState])

    useEffect(() => {
      if (
        fetchChargePointUploadDiagnosticsRequestState !==
          RequestState.LOADING &&
        fetchChargePointUploadDiagnosticsRequestState !==
          RequestState.UNINITIALIZED
      ) {
        setDiagnosticsLastUpdated(Date.now())
      }
    }, [fetchChargePointUploadDiagnosticsRequestState])

    useEffect(() => {
      if (
        diagnosticsStatusNotificationRequestState !== RequestState.LOADING &&
        diagnosticsStatusNotificationRequestState !== RequestState.UNINITIALIZED
      ) {
        setRefreshingLogs(false)
        setDiagnosticsLastUpdated(Date.now())
      }
    }, [diagnosticsStatusNotificationRequestState])

    const isUploading = () => {
      return (
        uploadDiagnosticsList[0].status ===
          InfinityEnums.UploadDiagnosticsStatus.IDLE ||
        uploadDiagnosticsList[0].status ===
          InfinityEnums.UploadDiagnosticsStatus.UPLOADING
      )
    }

    const { t } = useTranslation()
    const [isDiagnosticsRequestDisabled, setIsDiagnosticsRequestDisabled] =
      useState(uploadDiagnosticsList.length > 0 ? isUploading() : true)

    const [diagnosticsLastUpdated, setDiagnosticsLastUpdated] = useState(null)
    const [refreshingLogs, setRefreshingLogs] = useState(false)

    const uploadDiagnosticsListRef = useRef()
    const requestDiagnosticsRef = useRef()

    return (
      <IFModal
        ref={ref}
        title={t('UploadDiagnosticsModal.Diagnostics')}
        width="54rem"
        open={open}
      >
        <div>
          <div className={Styles.UploadDiagnosticsContainer}>
            <UploadDiagnosticsList ref={uploadDiagnosticsListRef} />
          </div>
          <div className={Styles.ButtonContainer}>
            {fetchChargePointUploadDiagnosticsRequestState ===
            RequestState.LOADING ? (
              <div className={Styles.RefreshIconTextContainer}>
                <div>
                  <IFSkeleton
                    height="1.5rem"
                    className={Styles.RefreshTextSkeleton}
                  />
                  <IFSkeleton
                    height="1.25rem"
                    className={Styles.RefreshTextDateSkeleton}
                  />
                </div>
                <div
                  className={Styles.RefreshIcon}
                  style={{ marginBottom: '0.5rem' }}
                >
                  <IFSkeleton
                    width="1.5rem"
                    height="1.5rem"
                    variant="circular"
                  />
                </div>
              </div>
            ) : (
              tenantUser.permissions.includes(
                InfinityEnums.TenantUserPermission.CAN_TRIGGER_MESSAGE,
              ) && (
                <div className={Styles.RefreshIconTextContainer}>
                  <div
                    className={Styles.RefreshIcon}
                    onClick={() => {
                      if (refreshingLogs) return
                      setRefreshingLogs(true)
                      OCPPServices.diagnosticsStatusNotification(
                        chargePoint._id,
                      )
                    }}
                  >
                    <RefreshIcon
                      sx={{ height: '20px' }}
                      className={refreshingLogs ? Styles.refreshStart : null}
                    />
                  </div>
                  <div className={Styles.RefreshTextContainer}>
                    <IFText
                      className={Styles.RefreshText}
                      style={{ color: Colors.black }}
                    >
                      {t('UploadDiagnosticsModal.lastUpdated')}
                    </IFText>
                    <IFText className={Styles.RefreshTextDate}>
                      {diagnosticsLastUpdated
                        ? moment(diagnosticsLastUpdated).format(
                            'MMM DD, YYYY HH:mm:ss',
                          )
                        : ''}
                    </IFText>
                  </div>
                </div>
              )
            )}
            {tenantUser.permissions.includes(
              InfinityEnums.TenantUserPermission.CAN_REQUEST_DIAGNOSTICS,
            ) && (
              <IFButton
                className={Styles.Button}
                size="sm"
                text={t('UploadDiagnosticsModal.RequestDiagnostics')}
                color={Colors.primary}
                isFill={true}
                isDead={isDiagnosticsRequestDisabled}
                onClick={() => requestDiagnosticsRef.current.handleOpen()}
                isLoading={false}
                loadSkeleton={
                  fetchChargePointUploadDiagnosticsRequestState ===
                  RequestState.LOADING
                }
              />
            )}
          </div>
        </div>
        <RequestDiagnosticsModal ref={requestDiagnosticsRef} />
      </IFModal>
    )
  },
)

const mapStateToProps = (state) => ({
  requestChargePointUploadDiagnosticsRequestState:
    ChargePointSelectors.getRequestChargePointUploadDiagnosticsRequestState(
      state,
    ),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  uploadDiagnosticsList:
    ChargePointSelectors.getChargePointUploadDiagnostics(state),
  diagnosticsStatusNotificationRequestState:
    ChargePointSelectors.getDiagnosticsStatusNotificationRequestState(state),
  fetchChargePointUploadDiagnosticsRequestState:
    ChargePointSelectors.getFetchChargePointUploadDiagnosticsRequestState(
      state,
    ),
  tenantUser: AuthSelectors.getTenantUser(state),
})

UploadDiagnosticsModal.propTypes = {
  open: PropTypes.bool,
  requestChargePointUploadDiagnostics: PropTypes.func,
  requestChargePointUploadDiagnosticsRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  uploadDiagnosticsList: PropTypes.arrayOf(PropTypes.object),
  diagnosticsStatusNotificationRequestState: PropTypes.number,
}

export default connect(mapStateToProps, null, null, {
  forwardRef: true,
})(UploadDiagnosticsModal)
