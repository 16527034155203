import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import PropTypes from 'prop-types'
import { IFButton, IFText, IFTextInput } from 'Components'
import React, { useState, useImperativeHandle } from 'react'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import Icon from '@material-ui/core/Icon'
import Styles from './IFDialog.module.css'

const IFDialog = React.forwardRef(
  (
    {
      style,
      dialogWidth,
      title,
      titleStyle = { FontSize: '1.25rem' },
      subtitle,
      onAction,
      actionButtonText,
      actionButtonColor,
      actionButtonIcon,
      confirmationText,
      buttonClassName,
      isLoading,
      isDead,
      children,
      onDismiss = () => {},
      hasFirstButton = true,
      hasSecondButton = false,
      secondActionButtonText = '',
      secondActionButtonColor = '',
      secondActionButtonIcon = '',
      secondActionButtonOnClick = () => {},
      isSecondButtonDead = false,
      isSecondButtonLoading = false,
      animateButtons = false,
    },
    ref,
  ) => {
    const useStyles = makeStyles({
      root: {
        width: dialogWidth ? dialogWidth : 'unset',
        padding: '1rem',
        paddingTop: '1.5rem',
        paddingBottom: '1.5rem',
      },
      MuiDialogTitle: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
        paddingTop: '0rem',
        paddingBottom: '0.5rem',
      },
      MuiDialogContent: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
        paddingTop: '0rem',
        paddingBottom: '0.5rem',
        overflow: 'unset',
      },
      MuiDialogActions: {
        paddingLeft: '0rem',
        paddingRight: '0rem',
        paddingTop: `${confirmationText ? '1em' : '0em'}`,
        paddingBottom: '0rem',
      },
    })

    const Classes = useStyles()

    const { t } = useTranslation()

    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [buttonIsDead, setButtonIsDead] = useState(true)
    const [firstButtonClicked, setFirstButtonClicked] = useState(false)
    const [secondButtonClicked, setSecondButtonClicked] = useState(false)

    const updateInputValue = (event) => {
      if (confirmationText)
        if (event.target.value === confirmationText) setButtonIsDead(false)
        else setButtonIsDead(true)
      setInputValue(event.target.value)
    }

    const show = () => {
      setIsOpen(true)
    }

    const dismiss = () => {
      setIsOpen(false)
      setInputValue('')
      setButtonIsDead(true)
      onDismiss()
    }

    const resetAnimation = () => {
      setFirstButtonClicked(false)
      setSecondButtonClicked(false)
    }

    useImperativeHandle(ref, () => ({
      show,
      dismiss,
      resetAnimation,
    }))

    return (
      <div>
        <Dialog
          classes={{ paper: Classes.root }}
          style={style}
          open={isOpen}
          onClose={dismiss}
        >
          <DialogTitle className={Classes.MuiDialogTitle}>
            <IFText style={titleStyle}>{title}</IFText>
          </DialogTitle>
          <DialogContent className={Classes.MuiDialogContent}>
            <div className={Styles.subtitleContainer}>
              {subtitle && (
                <IFText
                  className={Styles.subtitleText}
                  style={{ color: Colors.text }}
                >
                  {subtitle}
                </IFText>
              )}
              {children ? children : null}
            </div>
            {confirmationText ? (
              <IFTextInput
                className={Styles.IFTextInputStyle}
                value={inputValue}
                onChange={updateInputValue}
                isFixed={true}
              />
            ) : null}
          </DialogContent>
          <DialogActions className={Classes.MuiDialogActions}>
            <IFButton
              onClick={dismiss}
              size="xsm"
              icon={
                <Icon
                  sx={{ width: '15px !important', height: '15px !important' }}
                  component={CloseIcon}
                />
              }
              color={Colors.primary}
              text={t('IFDialog.Cancel')}
              className={buttonClassName}
              isFill={false}
            />
            {hasFirstButton ? (
              <div
                className={
                  secondButtonClicked && animateButtons
                    ? Styles.ButtonFade
                    : Styles.ButtonVisible
                }
              >
                <IFButton
                  onClick={() => {
                    if (hasSecondButton) {
                      setFirstButtonClicked(true)
                    }
                    onAction()
                  }}
                  color={actionButtonColor}
                  isDead={confirmationText ? buttonIsDead : isDead}
                  isLoading={secondButtonClicked ? false : isLoading}
                  isFill={true}
                  size="xsm"
                  icon={actionButtonIcon}
                  autoFocus
                  text={actionButtonText}
                  className={buttonClassName}
                />
              </div>
            ) : null}

            {hasSecondButton ? (
              <div
                className={
                  firstButtonClicked && animateButtons
                    ? Styles.ButtonFade
                    : Styles.ButtonVisible
                }
              >
                <IFButton
                  onClick={() => {
                    if (hasFirstButton) {
                      setSecondButtonClicked(true)
                    }
                    secondActionButtonOnClick()
                  }}
                  size="xsm"
                  color={secondActionButtonColor}
                  isDead={isSecondButtonDead}
                  text={secondActionButtonText}
                  className={buttonClassName}
                  isLoading={firstButtonClicked ? false : isSecondButtonLoading}
                  isFill={true}
                  icon={secondActionButtonIcon}
                  autoFocus
                />
              </div>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    )
  },
)

IFDialog.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.any,
  onAction: PropTypes.func,
  actionButtonText: PropTypes.string,
  actionButtonColor: PropTypes.string,
  buttonCancelText: PropTypes.string,
  actionButtonIcon: PropTypes.element,
  buttonClassName: PropTypes.string,
  confirmationText: PropTypes.string,
}

export default IFDialog
