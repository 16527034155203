import React, { createRef, useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Config } from 'Config'
import { connect } from 'react-redux'
import {
  StationIcon,
  StationClusterIcon,
  StationMapComponent,
  IFFilter,
  SearchBox,
  IFsvg,
  IFTooltipIconsLoading,
} from 'Components'
import StationSelectors from 'Stores/Station/Selectors'
import StationActions from 'Stores/Station/Actions'
import AuthSelectors from 'Stores/Auth/Selectors'
import Popover from '@mui/material/Popover'
import IFFilterType from 'Enums/IFFilterType'
import Styles from './IFStationsMap.module.css'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Colors } from 'Theme'
import RequestState from 'Enums/RequestState'
import subDays from 'date-fns/subDays'
import { useLocation } from 'react-router-dom'
import { stationsMapFilter } from 'Constants/Filters'
var base64 = require('base-64')

const IFStationsMap = ({
  stationClusters,
  stationClustersRequestState,
  stationsData,
  fetchStationMapClusters,
  fetchStationHeatMap,
  stationsFilter,
  fetchStationCities,
  setStationsFilter,
  width = '70%',
  height = '80vh',
  fetchStationHeatMapRequestState,
  tenant,
  stationMapClustersSearch,
  fetchStationMapClustersSearch,
  setStationMapClustersSearchWord,
  clearStationMapClustersSearchWord,
  clearStationMapClustersSearch,
}) => {
  const defaultProps = {
    center: {
      lat: 26.923829355301727,
      lng: 30.056246323840774,
    },
    zoom: 7,
    bounds: ['-180', '-85', '180', '85'],
  }

  const { t } = useTranslation()
  const predefinedBottomRanges = [
    {
      label: t('StationsMap.Last7Days'),
      value: [subDays(new Date(), 6), new Date()],
    },
    {
      label: t('StationsMap.Last30Days'),
      value: [subDays(new Date(), 29), new Date()],
    },
    {
      label: t('StationsMap.AllTime'),
      value: [new Date(new Date().setFullYear(2021, 0, 1)), new Date()],
    },
  ]
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [bounds, setBounds] = useState(null)
  const [googleMap, setGoogleMap] = useState(null)
  const [zoom, setZoom] = useState(7)
  const [center, setCenter] = useState()
  const [stationComponentChargePoints, setStationComponentChargePoints] =
    useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [mapViewIndex, setMapViewIndex] = useState(0)
  const stationRefs = useRef()
  const [heatMapFilter, setHeatMapFilter] = useState([
    {
      type: IFFilterType.SELECT,
      title: 'Weight',
      data: ['Transactions Count', 'Energy Consumed', 'Total Charging Time'],
      initialValue: [],
      field: 'weight',
      value: 'Transactions Count',
    },
    {
      type: IFFilterType.SLIDER,
      title: 'Radius',
      data: [20, 40],
      initialValue: [],
      field: 'radius',
      value: '20',
    },
    {
      type: IFFilterType.DATE_RANGE,
      title: 'Date',
      data: null,
      initialValue: [subDays(new Date(), 29), new Date()],
      field: 'date',
      value: [subDays(new Date(), 29), new Date()],
      ranges: predefinedBottomRanges,
    },
  ])
  const mapRef = useRef()
  const filterStationMapRef = useRef()
  const filterHeatMapRef = useRef()
  const previousFilterValues = useRef({ stationsFilter })
  const location = useLocation()
  useEffect(() => {
    const filter = base64.encode(JSON.stringify(stationsMapFilter))
    fetchStationMapClusters(defaultProps.zoom, defaultProps.bounds, filter)
  }, [])
  useEffect(() => {
    if (location.pathname === '/map') {
      setStationsFilter(stationsMapFilter)
      filterStationMapRef?.current?.clearFilter()
      filterHeatMapRef?.current?.clearFilter()
      setHeatMapFilter([
        {
          type: IFFilterType.SELECT,
          title: 'Weight',
          data: [
            'Transactions Count',
            'Energy Consumed',
            'Total Charging Time',
          ],
          initialValue: [],
          field: 'weight',
          value: 'Transactions Count',
        },
        {
          type: IFFilterType.SLIDER,
          title: 'Radius',
          data: [20, 40],
          initialValue: [],
          field: 'radius',
          value: '20',
        },
        {
          type: IFFilterType.DATE_RANGE,
          title: 'Date',
          data: null,
          initialValue: [subDays(new Date(), 29), new Date()],
          field: 'date',
          value: [subDays(new Date(), 29), new Date()],
          ranges: predefinedBottomRanges,
        },
      ])
      setMapViewIndex(0)
      if (!isFirstLoad) {
        const filter = base64.encode(JSON.stringify(stationsMapFilter))
        fetchStationMapClusters(defaultProps.zoom, defaultProps.bounds, filter)
        fetchStationCities()
      }
    }
  }, [location])

  useEffect(() => {
    if (bounds && mapViewIndex === 0) {
      const filter = base64.encode(JSON.stringify(stationsFilter))
      if (!isFirstLoad) fetchStationMapClusters(zoom, bounds, filter)
    }
  }, [bounds])

  useEffect(() => {
    const filter = base64.encode(JSON.stringify(stationsFilter))
    if (!isFirstLoad)
      fetchStationMapClusters(defaultProps.zoom, defaultProps.bounds, filter)
  }, [stationsFilter])

  const handleStationClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleStationClose = () => {
    setAnchorEl(null)
  }

  const stationPopoverOpen = Boolean(anchorEl)
  const stationPopoverID = stationPopoverOpen ? 'simple-popover' : undefined

  const handleChange = (event, newValue) => {
    setMapViewIndex(newValue)
    if (newValue === 1) setAnchorEl(null)
  }

  const fetchStationMapClustersSearchHandler = () => {
    fetchStationMapClustersSearch(zoom, defaultProps.bounds, stationsFilter)
  }

  const fetchStationMapClustersSearchRef = useRef(
    fetchStationMapClustersSearchHandler,
  )

  useEffect(() => {
    fetchStationMapClustersSearchRef.current =
      fetchStationMapClustersSearchHandler
  })

  const setZoomAndCenteringAuto = () => {
    if (window.google && stationClusters.length !== 0) {
      let boundsTemp = new window.google.maps.LatLngBounds()
      stationClusters.forEach((cluster) => {
        if (stationClusters.length === 1 && cluster.properties.cluster) {
          cluster.properties.clusterChildrenLocations.forEach((child) => {
            let myLatLng = new window.google.maps.LatLng(
              child.coordinates[1],
              child.coordinates[0],
            )
            boundsTemp.extend(myLatLng)
          })
        } else {
          let myLatLng = new window.google.maps.LatLng(
            cluster.geometry.coordinates[1],
            cluster.geometry.coordinates[0],
          )
          boundsTemp.extend(myLatLng)
        }
      })
      if (googleMap !== null) {
        googleMap.fitBounds(boundsTemp, 114)
        googleMap.setCenter(boundsTemp.getCenter())
      }
    } else {
      setCenter(defaultProps.center)
    }
  }

  useEffect(() => {
    if (stationClusters.length !== 0) {
      setIsFirstLoad(false)
    }
    let newStationFilter = stationsFilter
    if (previousFilterValues.current !== newStationFilter) {
      setZoomAndCenteringAuto()
      previousFilterValues.current = newStationFilter
    }
  }, [stationClusters])

  useEffect(() => {
    if (googleMap !== null) setZoomAndCenteringAuto()
  }, [googleMap])

  useEffect(() => {
    const filter = base64.encode(JSON.stringify(heatMapFilter))
    if (mapViewIndex === 1) {
      fetchStationHeatMap(filter)
    }
  }, [heatMapFilter])
  useEffect(() => {
    const filter = base64.encode(JSON.stringify(heatMapFilter))
    if (!isFirstLoad) {
      if (mapViewIndex === 1) {
        fetchStationHeatMap(filter)
      } else {
        fetchStationMapClusters(zoom, bounds, filter)
      }
    }
  }, [mapViewIndex])

  const getHeatMapFilterIndex = (filterField) => {
    let filterIndex = -1
    heatMapFilter.forEach((filter, index) => {
      if (filter.field === filterField) filterIndex = index
    })

    return filterIndex
  }

  return (
    <div style={{ height: height, width: width }}>
      <GoogleMapReact
        ref={mapRef}
        onGoogleApiLoaded={({ map }) => {
          setGoogleMap(map)
          mapRef.current = map
        }}
        bootstrapURLKeys={{
          key: Config.REACT_APP_API_KEY,
          libraries: ['visualization'],
        }}
        libraries={['visualization']}
        defaultZoom={defaultProps.zoom}
        defaultCenter={defaultProps.center}
        center={center}
        heatmap={{
          positions:
            mapViewIndex === 1
              ? stationsData.map((station) => {
                  return {
                    lat: station.lat,
                    lng: station.lng,
                    weight: heatMapFilter[0].value
                      ? heatMapFilter[0].value === 'Transactions Count'
                        ? station.transactionCount
                        : heatMapFilter[0].value === 'Energy Consumed'
                        ? station.energyConsumed
                        : station.totalChargingTime
                      : station.energyConsumed,
                  }
                })
              : [],
          options: {
            radius: heatMapFilter[1].value ? heatMapFilter[1].value : 20,
            opacity: 1,
          },
        }}
        yesIWantToUseGoogleMapApiInternals
        onChange={({ zoom: mapZoom, bounds: mapBounds }) => {
          if (mapZoom !== zoom) {
            setZoom(mapZoom)
          }
          if (
            !bounds ||
            bounds[0] !== mapBounds[0] ||
            bounds[1] !== mapBounds[1] ||
            bounds[2] !== mapBounds[2] ||
            bounds[3] !== mapBounds[3]
          ) {
            setBounds([
              mapBounds.nw.lng,
              mapBounds.se.lat,
              mapBounds.se.lng,
              mapBounds.nw.lat,
            ])
          }
        }}
        options={{
          fullscreenControl: false,
          maxZoom: 20,
          clickableIcons: false,
        }}
      >
        {mapViewIndex === 0
          ? stationClusters.map((cluster, index) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const {
                cluster: isCluster,
                point_count: pointCount,
                maxPower,
                status,
              } = cluster.properties
              const key = `${latitude},${longitude}`
              stationRefs.current = {
                ...stationRefs.current,
                [key]: createRef(),
              }

              if (isCluster) {
                return (
                  <StationClusterIcon
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                    clusterCount={pointCount}
                    maxChargingSpeed={cluster.properties.clusterMaxPower}
                    stationStatus={cluster.properties.clusterStationStatus}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        cluster.properties.clusterExpansionZoom,
                        20,
                      )
                      mapRef.current.setZoom(expansionZoom)
                      mapRef.current.panTo({ lat: latitude, lng: longitude })
                    }}
                  />
                )
              }
              return (
                <StationIcon
                  key={`station-${index}`}
                  ref={stationRefs.current[key]}
                  lat={latitude}
                  lng={longitude}
                  maxChargingSpeed={maxPower}
                  stationStatus={status}
                  onClick={(event) => {
                    handleStationClick(event)
                    setStationComponentChargePoints(cluster.properties)
                  }}
                  addHeatMapOffset={true}
                  isOffline={cluster.properties.isStationOffline}
                />
              )
            })
          : null}
      </GoogleMapReact>
      <div className={mapViewIndex === 0 ? Styles.TopDetailsContainer : {}}>
        {mapViewIndex === 0 ? (
          <div className={Styles.SearchBoxContainer}>
            <SearchBox
              mapRef={mapRef}
              stationMapClustersSearch={stationMapClustersSearch}
              fetchStationMapClustersSearch={() =>
                fetchStationMapClustersSearchRef.current()
              }
              setStationMapClustersSearchWord={(word) =>
                setStationMapClustersSearchWord(word)
              }
              showPopover={(key) => {
                stationRefs?.current[key].current?.click()
              }}
              hidePopover={() => setAnchorEl(null)}
              clearStationMapClustersSearchWord={() =>
                clearStationMapClustersSearchWord()
              }
              clearStationMapClustersSearch={() =>
                clearStationMapClustersSearch()
              }
            />
          </div>
        ) : null}

        <div
          className={Styles.MapFilter}
          style={{ boxShadow: `${Colors.BoxShadow} 0px 0px 4px` }}
        >
          {mapViewIndex === 0 ? (
            <div className={Styles.StationsFilter}>
              <IFFilter
                ref={filterStationMapRef}
                key={'IFFilter_Stations'}
                onFilterChange={(newFilter) => {
                  setStationsFilter(newFilter)
                  setAnchorEl(null)
                }}
                filters={stationsFilter}
                textFieldPlaceholder={t('StationsMap.HeatMapFilterPlaceholder')}
                showLoadingIndicator={
                  stationClustersRequestState === RequestState.LOADING
                }
                tenant={tenant}
              />
            </div>
          ) : (
            <div className={Styles.HeatMapFilter}>
              <IFFilter
                ref={filterHeatMapRef}
                key={'IFFilter_Heatmap'}
                onFilterChange={(newFilter) => {
                  let adjustedFilter = newFilter.map((filter) => {
                    if (filter.value.length === 0) {
                      const filterIndex = getHeatMapFilterIndex(filter.field)
                      if (filterIndex !== -1)
                        filter.value = heatMapFilter[filterIndex].value
                    }

                    filter.initialValue = filter.value
                    // This is done so that the filters are persisted on re-rendering
                    // instead of displaying static initial values

                    return filter
                  })
                  setHeatMapFilter(adjustedFilter)
                }}
                filters={heatMapFilter}
                textFieldPlaceholder={t(
                  'StationsMap.StationsFilterPlaceholder',
                )}
                disableKeyword={true}
                fixedFilters={true}
                showLoadingIndicator={
                  fetchStationHeatMapRequestState === RequestState.LOADING
                }
                tenant={tenant}
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={Styles.MapViewToggle}
        style={{
          boxShadow: `${Colors.BoxShadow} 0px 0px 4px`,
          backgroundColor: Colors.MapViewToggleBackground,
        }}
      >
        <Tabs
          value={mapViewIndex}
          onChange={handleChange}
          orientation="vertical"
          TabIndicatorProps={{
            style: {
              backgroundColor: Colors.primary,
              width: '4px',
            },
          }}
        >
          <Tab
            sx={{
              minWidth: '40px',
              paddingTop: '0px',
              paddingBottom: '0px',
              paddingRight: '4px',
              paddingLeft: '4px',
            }}
            key={'mapView-stations'}
            label={
              <IFTooltipIconsLoading
                onClick={(e) => handleChange(e, 0)}
                title={t('StationsMap.StationsView')}
                iconColor={Colors.black}
                Icon={IFsvg.Flash}
                FilledIcon={IFsvg.Flash}
                isLoading={false}
                animationDisabled={true}
                tooltipPlacement={'left'}
              />
            }
          />
          <Tab
            sx={{
              minWidth: '40px',
              paddingTop: '0px',
              paddingBottom: '0px',
              paddingRight: '4px',
              paddingLeft: '4px',
            }}
            key={'mapView-heatmap'}
            label={
              <IFTooltipIconsLoading
                onClick={(e) => handleChange(e, 1)}
                title={t('StationsMap.HeatMapView')}
                iconColor={Colors.black}
                Icon={IFsvg.HeatMap}
                FilledIcon={IFsvg.HeatMap}
                isLoading={false}
                animationDisabled={true}
                tooltipPlacement={'left'}
              />
            }
          />
        </Tabs>
      </div>

      <Popover
        id={stationPopoverID}
        open={stationPopoverOpen}
        anchorEl={anchorEl}
        disableEnforceFocus={true}
        onClose={handleStationClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { borderRadius: '10px' } }}
      >
        <StationMapComponent stationMapData={stationComponentChargePoints} />
      </Popover>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchStationMapClusters: (zoom, bounds, filter) =>
      dispatch(StationActions.fetchStationMapClusters(zoom, bounds, filter)),
    fetchStationHeatMap: (filter) =>
      dispatch(StationActions.fetchStationHeatMap(filter)),
    fetchStationCities: () => dispatch(StationActions.fetchStationCities()),
    setStationsFilter: (filter) =>
      dispatch(StationActions.setStationsMapFilter(filter)),
    fetchStationMapClustersSearch: (zoom, bounds, filter) =>
      dispatch(
        StationActions.fetchStationMapClustersSearch(zoom, bounds, filter),
      ),
    setStationMapClustersSearchWord: (searchWord) =>
      dispatch(StationActions.setStationMapClustersSearchWord(searchWord)),
    clearStationMapClustersSearchWord: () =>
      dispatch(StationActions.clearStationMapClustersSearchWord()),
    clearStationMapClustersSearch: () =>
      dispatch(StationActions.clearStationMapClustersSearch()),
  }
}

const mapStateToProps = (state) => ({
  stationClusters: StationSelectors.getStationMapClusters(state),
  stationsData: StationSelectors.getStationMapData(state),
  stationsFilter: StationSelectors.getStationMapFilters(state),
  stationClustersRequestState:
    StationSelectors.getFetchStationMapClustersRequestState(state),
  fetchStationHeatMapRequestState:
    StationSelectors.getfetchStationHeatMapRequestState(state),
  tenant: AuthSelectors.getTenant(state),
  stationMapClustersSearch: StationSelectors.getStationMapClustersSearch(state),
})

IFStationsMap.propTypes = {
  stationClusters: PropTypes.arrayOf(PropTypes.object),
  stationClustersRequestState: PropTypes.number,
  stationsData: PropTypes.arrayOf(PropTypes.object),
  fetchStationMapClusters: PropTypes.func,
  fetchStationHeatMap: PropTypes.func,
  stationsFilter: PropTypes.arrayOf(PropTypes.object),
  fetchStationCities: PropTypes.func,
  setStationsFilter: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(IFStationsMap)
