import RequestState from 'Enums/RequestState'

export const INITIAL_STATE = {
  tenantUsers: [],
  subscriptionsList: [],
  privacySchedule: [],
  accessGroupsList: [],
  inviteTenantUsers: [],
  inviteTenantUser: {},
  inviteTenantUserStatus: '',
  fetchTenantUserListRequestState: RequestState.UNINITIALIZED,
  fetchSubscriptionListRequestState: RequestState.UNINITIALIZED,
  fetchPrivacyScheduleRequestState: RequestState.UNINITIALIZED,
  fetchAccessGroupsListRequestState: RequestState.UNINITIALIZED,
  updateTenantUserListRequestState: RequestState.UNINITIALIZED,
  deleteTenantUserRequestState: RequestState.UNINITIALIZED,
  fetchInviteTenantUserListRequestState: RequestState.UNINITIALIZED,
  fetchInviteTenantUserRequestState: RequestState.UNINITIALIZED,
  inviteTenantUserRequestState: RequestState.UNINITIALIZED,
  deleteInviteTenantUserRequestState: RequestState.UNINITIALIZED,
  sendTenantUserEmailRequestState: RequestState.UNINITIALIZED,
  createTenantUserRequestState: RequestState.UNINITIALIZED,
}
