import { takeLatest, all } from 'redux-saga/effects'
import { AuthTypes } from '../Stores/Auth/Actions'
import { StationTypes } from '../Stores/Station/Actions'
import { ChargePointTypes } from '../Stores/ChargePoint/Actions'
import { TenantUserTypes } from '../Stores/TenantUser/Actions'
import { UserTypes } from '../Stores/User/Actions'
import { CardTypes } from '../Stores/Card/Actions'

import {
  tenantUserLogin,
  fetchTenantUser,
  tenantUserLogout,
  resetPassVerification,
  resetPass,
} from './AuthSaga'

import {
  fetchStationList,
  fetchStationListCount,
  updateStation,
  addStation,
  fetchStationMoveList,
  deleteStation,
  fetchStationDetails,
  fetchStationMapClusters,
  fetchStationHeatMap,
  fetchStationMapClustersSearch,
} from './StationSaga'

import {
  fetchChargePointList,
  fetchChargePoint,
  fetchChargePointLogList,
  fetchChargePointTransactionList,
  fetchChargePointConfigList,
  updateChargePoint,
  updateChargePointConfig,
  deleteChargePoint,
  addChargePoint,
  moveChargePoint,
  updateChargePointFirmware,
  fetchChargePointUploadDiagnostics,
  requestChargePointUploadDiagnostics,
  fetchChargePointSubscriptionsList,
  deleteChargePointSubscriptions,
  addChargePointSubscriptions,
  fetchChargePointPrivacyScheduleList,
  deleteChargePointPrivacyRules,
  addChargePointPrivacyRules,
  fetchChargePointAccessGroups,
  deleteChargePointAccessGroupsBulk,
  addChargePointAccessGroupsBulk,
  fetchChargePointUptimeStatistics,
  fetchConnectorsUtilization,
  fetchChargePointSubscribedAlerts,
  fetchChargePointAlerts,
  updateChargePointAlerts,
  fetchChargePointLocalList,
  updateChargePointLocalList,
  fetchChargePointChargingProfiles,
  fetchChargePointCompositeSchedule,
  clearChargePointChargingProfile,
  createChargePointChargingProfile,
} from './ChargePointSaga'

import {
  fetchTenantUserList,
  updateTenantUser,
  fetchInviteTenantUserList,
  inviteTenantUser,
  deleteTenantUser,
  deleteInviteTenantUser,
  sendTenantUserEmail,
  fetchInviteTenantUser,
  createTenantUser,
  fetchStationCities,
  addStationCity,
  fetchSubscriptionsList,
  fetchPrivacySchedule,
  fetchAccessGroupsList,
} from './TenantUserSaga'

import {
  fetchCardList,
  deleteCard,
  updateCard,
  addCard,
  addCardBulk,
  addCardAccessGroups,
  deleteCardAccessGroups,
  addCardSubscriptions,
  deleteCardSubscriptions,
  fetchCardTransactions,
  fetchCard,
  fetchLocalListFilteredCards,
} from './CardSaga'

import {
  fetchUserDetails,
  fetchUserList,
  fetchUserTransactions,
  addUserSubscriptions,
  deleteUserSubscriptions,
  addUserAccessGroups,
  deleteUserAccessGroups,
  inviteUser,
  inviteUserBulk,
  deleteUser,
  fetchUserListCount,
  fetchUserSessionList,
  fetchLocalListFilteredUsers,
} from './UserSaga'

export default function* root() {
  yield all([takeLatest(AuthTypes.TENANT_USER_LOGIN, tenantUserLogin)])
  yield all([takeLatest(AuthTypes.FETCH_TENANT_USER, fetchTenantUser)])
  yield all([takeLatest(AuthTypes.TENANT_USER_LOGOUT, tenantUserLogout)])
  yield all([
    takeLatest(AuthTypes.RESET_PASS_VERIFICATION, resetPassVerification),
  ])
  yield all([takeLatest(AuthTypes.RESET_PASS, resetPass)])

  yield all([takeLatest(StationTypes.FETCH_STATION_LIST, fetchStationList)])
  yield all([
    takeLatest(StationTypes.FETCH_STATION_LIST_COUNT, fetchStationListCount),
  ])

  yield all([
    takeLatest(StationTypes.FETCH_STATION_MOVE_LIST, fetchStationMoveList),
  ])
  yield all([takeLatest(StationTypes.UPDATE_STATION, updateStation)])
  yield all([takeLatest(StationTypes.ADD_STATION, addStation)])
  yield all([takeLatest(StationTypes.DELETE_STATION, deleteStation)])
  yield all([
    takeLatest(StationTypes.FETCH_STATION_DETAILS, fetchStationDetails),
  ])

  yield all([
    takeLatest(ChargePointTypes.FETCH_CHARGE_POINT_LIST, fetchChargePointList),
  ])
  yield all([takeLatest(ChargePointTypes.FETCH_CHARGE_POINT, fetchChargePoint)])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_LOG_LIST,
      fetchChargePointLogList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_TRANSACTION_LIST,
      fetchChargePointTransactionList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_CONFIG_LIST,
      fetchChargePointConfigList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIPTIONS_LIST,
      fetchChargePointSubscriptionsList,
    ),
  ])
  yield all([
    takeLatest(ChargePointTypes.UPDATE_CHARGE_POINT, updateChargePoint),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.UPDATE_CHARGE_POINT_CONFIG,
      updateChargePointConfig,
    ),
  ])
  yield all([
    takeLatest(ChargePointTypes.DELETE_CHARGE_POINT, deleteChargePoint),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.DELETE_CHARGE_POINT_SUBSCRIPTIONS,
      deleteChargePointSubscriptions,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.ADD_CHARGE_POINT_SUBSCRIPTIONS,
      addChargePointSubscriptions,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_PRIVACY_SCHEDULE_LIST,
      fetchChargePointPrivacyScheduleList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.DELETE_CHARGE_POINT_PRIVACY_RULES,
      deleteChargePointPrivacyRules,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.ADD_CHARGE_POINT_PRIVACY_RULES,
      addChargePointPrivacyRules,
    ),
  ])
  yield all([takeLatest(ChargePointTypes.ADD_CHARGE_POINT, addChargePoint)])
  yield all([takeLatest(ChargePointTypes.MOVE_CHARGE_POINT, moveChargePoint)])
  yield all([
    takeLatest(
      ChargePointTypes.UPDATE_CHARGE_POINT_FIRMWARE,
      updateChargePointFirmware,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_UPLOAD_DIAGNOSTICS,
      fetchChargePointUploadDiagnostics,
    ),
  ])

  yield all([
    takeLatest(
      ChargePointTypes.REQUEST_CHARGE_POINT_UPLOAD_DIAGNOSTICS,
      requestChargePointUploadDiagnostics,
    ),
  ])

  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_ACCESS_GROUPS,
      fetchChargePointAccessGroups,
    ),
  ])

  yield all([
    takeLatest(
      ChargePointTypes.DELETE_CHARGE_POINT_ACCESS_GROUPS_BULK,
      deleteChargePointAccessGroupsBulk,
    ),
  ])

  yield all([
    takeLatest(
      ChargePointTypes.ADD_CHARGE_POINT_ACCESS_GROUPS_BULK,
      addChargePointAccessGroupsBulk,
    ),
  ])

  yield all([
    takeLatest(TenantUserTypes.FETCH_TENANT_USER_LIST, fetchTenantUserList),
  ])
  yield all([
    takeLatest(
      TenantUserTypes.FETCH_SUBSCRIPTIONS_LIST,
      fetchSubscriptionsList,
    ),
  ])
  yield all([
    takeLatest(TenantUserTypes.FETCH_PRIVACY_SCHEDULE, fetchPrivacySchedule),
  ])
  yield all([takeLatest(TenantUserTypes.UPDATE_TENANT_USER, updateTenantUser)])
  yield all([takeLatest(TenantUserTypes.INVITE_TENANT_USER, inviteTenantUser)])
  yield all([
    takeLatest(
      TenantUserTypes.FETCH_INVITE_TENANT_USER_LIST,
      fetchInviteTenantUserList,
    ),
  ])
  yield all([takeLatest(TenantUserTypes.DELETE_TENANT_USER, deleteTenantUser)])
  yield all([
    takeLatest(
      TenantUserTypes.DELETE_INVITE_TENANT_USER,
      deleteInviteTenantUser,
    ),
  ])
  yield all([
    takeLatest(TenantUserTypes.SEND_TENANT_USER_EMAIL, sendTenantUserEmail),
  ])
  yield all([
    takeLatest(TenantUserTypes.FETCH_INVITE_TENANT_USER, fetchInviteTenantUser),
  ])
  yield all([takeLatest(TenantUserTypes.CREATE_TENANT_USER, createTenantUser)])
  yield all([
    takeLatest(TenantUserTypes.FETCH_ACCESS_GROUPS_LIST, fetchAccessGroupsList),
  ])

  yield all([takeLatest(CardTypes.FETCH_CARD_LIST, fetchCardList)])
  yield all([takeLatest(CardTypes.DELETE_CARD, deleteCard)])
  yield all([takeLatest(CardTypes.UPDATE_CARD, updateCard)])
  yield all([takeLatest(CardTypes.ADD_CARD, addCard)])
  yield all([takeLatest(CardTypes.ADD_CARD_BULK, addCardBulk)])
  yield all([takeLatest(CardTypes.FETCH_CARD, fetchCard)])
  yield all([takeLatest(CardTypes.ADD_CARD_ACCESS_GROUPS, addCardAccessGroups)])
  yield all([
    takeLatest(CardTypes.DELETE_CARD_ACCESS_GROUPS, deleteCardAccessGroups),
  ])
  yield all([
    takeLatest(CardTypes.ADD_CARD_SUBSCRIPTIONS, addCardSubscriptions),
  ])
  yield all([
    takeLatest(CardTypes.DELETE_CARD_SUBSCRIPTIONS, deleteCardSubscriptions),
  ])
  yield all([
    takeLatest(CardTypes.FETCH_CARD_TRANSACTIONS, fetchCardTransactions),
  ])
  yield all([
    takeLatest(
      CardTypes.FETCH_LOCAL_LIST_FILTERED_CARDS,
      fetchLocalListFilteredCards,
    ),
  ])

  yield all([takeLatest(StationTypes.FETCH_STATION_CITIES, fetchStationCities)])
  yield all([takeLatest(StationTypes.ADD_STATION_CITY, addStationCity)])
  yield all([
    takeLatest(
      StationTypes.FETCH_STATION_MAP_CLUSTERS,
      fetchStationMapClusters,
    ),
  ])
  yield all([
    takeLatest(StationTypes.FETCH_STATION_HEAT_MAP, fetchStationHeatMap),
  ])
  yield all([
    takeLatest(
      StationTypes.FETCH_STATION_MAP_CLUSTERS_SEARCH,
      fetchStationMapClustersSearch,
    ),
  ])

  yield all([takeLatest(UserTypes.FETCH_USER_DETAILS, fetchUserDetails)])
  yield all([takeLatest(UserTypes.FETCH_USER_LIST, fetchUserList)])
  yield all([
    takeLatest(UserTypes.FETCH_USER_TRANSACTIONS, fetchUserTransactions),
  ])

  yield all([
    takeLatest(UserTypes.ADD_USER_SUBSCRIPTIONS, addUserSubscriptions),
  ])
  yield all([
    takeLatest(UserTypes.DELETE_USER_SUBSCRIPTIONS, deleteUserSubscriptions),
  ])
  yield all([takeLatest(UserTypes.ADD_USER_ACCESS_GROUPS, addUserAccessGroups)])
  yield all([
    takeLatest(UserTypes.DELETE_USER_ACCESS_GROUPS, deleteUserAccessGroups),
  ])
  yield all([takeLatest(UserTypes.INVITE_USER, inviteUser)])
  yield all([takeLatest(UserTypes.INVITE_USER_BULK, inviteUserBulk)])
  yield all([takeLatest(UserTypes.DELETE_USER, deleteUser)])
  yield all([takeLatest(UserTypes.FETCH_USER_LIST_COUNT, fetchUserListCount)])
  yield all([
    takeLatest(UserTypes.FETCH_USER_SESSION_LIST, fetchUserSessionList),
  ])
  yield all([
    takeLatest(
      UserTypes.FETCH_LOCAL_LIST_FILTERED_USERS,
      fetchLocalListFilteredUsers,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_UPTIME_STATISTICS,
      fetchChargePointUptimeStatistics,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CONNECTORS_UTILIZATION,
      fetchConnectorsUtilization,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_SUBSCRIBED_ALERTS,
      fetchChargePointSubscribedAlerts,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_ALERTS,
      fetchChargePointAlerts,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.UPDATE_CHARGE_POINT_ALERTS,
      updateChargePointAlerts,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_LOCAL_LIST,
      fetchChargePointLocalList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_CHARGING_PROFILES,
      fetchChargePointChargingProfiles,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.UPDATE_CHARGE_POINT_LOCAL_LIST,
      updateChargePointLocalList,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.FETCH_CHARGE_POINT_COMPOSITE_SCHEDULE,
      fetchChargePointCompositeSchedule,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.CLEAR_CHARGE_POINT_CHARGING_PROFILE,
      clearChargePointChargingProfile,
    ),
  ])
  yield all([
    takeLatest(
      ChargePointTypes.CREATE_CHARGE_POINT_CHARGING_PROFILE,
      createChargePointChargingProfile,
    ),
  ])
}
