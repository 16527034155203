import { Route, Routes, Navigate } from 'react-router-dom'
import { ResetPassword, CreateAccountPage, Login } from 'Containers'
import { Colors } from 'Theme'

const LoginRouter = () => {
  Colors.primary = Colors.defaultPrimary
  Colors.StationListHeaderBackground =
    Colors.defaultPrimary + Colors.HexTransparent.T10
  Colors.StationListItemBackgroundHover =
    Colors.defaultPrimary + Colors.HexTransparent.T10
  Colors.StationListItemSelected =
    Colors.defaultPrimary + Colors.HexTransparent.T20
  Colors.TabsBackground = Colors.defaultPrimary + Colors.HexTransparent.T10
  Colors.FilterBackground = Colors.defaultPrimary + Colors.HexTransparent.T10
  Colors.filterBackground = Colors.transparent
  return (
    <Routes>
      <Route path="/reset" element={<ResetPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/createAccount" element={<Navigate to="/auth/login" />} />
      <Route path="/createAccount/*" element={<CreateAccountPage />} />
    </Routes>
  )
}

export default LoginRouter
