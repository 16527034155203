import React, { useState, useRef, Fragment } from 'react'
import Styles from './ResetPassword.module.css'
import { IFButton, IFModal, IFText, IFTextInput } from 'Components'
import { Colors, Images } from 'Theme'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import validator from 'validator'
import ReactPinField from 'react-pin-field'
import { makeStyles } from '@mui/styles'
import RequestState from 'Enums/RequestState'
import { toaster } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { IFToastMessage } from 'Components'

const useStyles = makeStyles({
  pinFieldStyles: {
    backgroundColor: `${Colors.pinFieldBackground}`,
    border: `1px solid ${Colors.pinFieldBorder}`,
    borderRadius: '0.3rem',
    fontSize: '2rem',
    margin: '0.25rem',
    height: '3.5rem',
    outline: 'none',
    textAlign: 'center',
    transitionDuration: '250ms',
    transitionProperty: 'background, color, border, box-shadow, transform',
    width: '3rem',
    '&:focus': {
      borderColor: `${Colors.primary}`,
      outline: 'none',
      transform: 'scale(1.05)',
    },
    '&:invalid': {
      animation: 'shake 3 linear 75ms',
      borderColor: `${Colors.pinFieldInvalidBorder}`,
      boxShadow: `0 0 0.25rem ${Colors.pinFieldInvalidBoxShadow}`,
    },
  },
})

const ResetPassword = ({
  resetPass,
  resetPasswordRequestState,
  resetPassVerification,
  resetPassVerificationRequestState,
  resetPassEmailID,
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [canReset, setCanReset] = useState(false)
  const [confirmPin, setConfirmPin] = useState('')
  const { t } = useTranslation()
  const navigate = useNavigate()
  const confirmResetDialog = useRef()

  const ResetPasswords = () => {
    setPasswordError('')
    setConfirmPasswordError('')
  }

  const classes = useStyles()

  return (
    <div>
      <div
        className={Styles.backgroundImage}
        style={{
          backgroundImage: `url(${Images.loginBackground})`,
        }}
      />
      <div
        className={Styles.ResetPasswordWrapper}
        style={{
          backgroundColor: Colors.resetPasswordBackgroundColor,
          boxShadow: `0px 0px 4px ${Colors.resetPasswordBackgroundShadow}`,
        }}
      >
        <div className={Styles.logo}>
          <img className={Styles.img} src={Images.infinity} alt="logo" />
        </div>
        <div className={Styles.titleContainer}>
          <IFText className={Styles.title}> {t('ResetPassword.Header')}</IFText>
        </div>
        <div className={Styles.inputContainer}>
          <div className={Styles.InputFieldContainer}>
            <IFText className={Styles.label}>{t('ResetPassword.Email')}</IFText>

            <IFTextInput
              placeholder={t('ResetPassword.EmailAddressPlaceholder')}
              style={{ caretColor: Colors.primary }}
              className={Styles.input}
              isFixed={true}
              value={email}
              name="email"
              type="email"
              onChange={(e) => {
                setEmail(e.target.value)
                e.target.value.length === 0
                  ? setEmailError(t('ResetPassword.Required'))
                  : validator.isEmail(e.target.value)
                  ? setEmailError('')
                  : setEmailError(t('ResetPassword.EmailError'))
                const reset =
                  validator.isEmail(e.target.value) &&
                  validator.isLength(password, { min: 8, max: 100 }) &&
                  validator.equals(password, confirmPassword)
                setCanReset(reset)
              }}
              onBlur={(e) => {
                e.target.value.length === 0
                  ? setEmailError(t('ResetPassword.Required'))
                  : validator.isEmail(e.target.value)
                  ? setEmailError('')
                  : setEmailError(t('ResetPassword.EmailError'))
                const reset =
                  validator.isEmail(e.target.value) &&
                  validator.isLength(password, { min: 8, max: 100 }) &&
                  validator.equals(password, confirmPassword)
                setCanReset(reset)
              }}
              {...(emailError.length > 0 ? { errorText: emailError } : {})}
            />
          </div>

          <div className={Styles.InputFieldContainer}>
            <div>
              <IFText className={Styles.label}>
                {t('ResetPassword.Password')}
              </IFText>

              <IFTextInput
                className={Styles.input}
                style={{ caretColor: Colors.primary }}
                isFixed={true}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  e.target.value.length === 0
                    ? setPasswordError(t('ResetPassword.Required'))
                    : !validator.isLength(e.target.value, { min: 8, max: 100 })
                    ? setPasswordError(t('ResetPassword.PasswordError'))
                    : !validator.equals(e.target.value, confirmPassword) &&
                      confirmPassword.length === 0
                    ? setPasswordError('')
                    : validator.equals(e.target.value, confirmPassword)
                    ? ResetPasswords()
                    : setPasswordError(t('ResetPassword.PasswordMismatch'))
                  const reset =
                    validator.isEmail(email) &&
                    validator.isLength(e.target.value, { min: 8, max: 100 }) &&
                    validator.equals(e.target.value, confirmPassword)
                  setCanReset(reset)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setPasswordError(t('ResetPassword.Required'))
                    : !validator.isLength(e.target.value, { min: 8, max: 100 })
                    ? setPasswordError(t('ResetPassword.PasswordError'))
                    : !validator.equals(e.target.value, confirmPassword) &&
                      confirmPassword.length === 0
                    ? setPasswordError('')
                    : validator.equals(e.target.value, confirmPassword)
                    ? ResetPasswords()
                    : setPasswordError(t('ResetPassword.PasswordMismatch'))
                  const reset =
                    validator.isEmail(email) &&
                    validator.isLength(e.target.value, { min: 8, max: 100 }) &&
                    validator.equals(e.target.value, confirmPassword)
                  setCanReset(reset)
                }}
                type="password"
                {...(passwordError.length > 0
                  ? { errorText: passwordError }
                  : {})}
              />
            </div>
          </div>
          <div className={Styles.InputFieldContainer}>
            <div>
              <IFText className={Styles.label}>
                {t('ResetPassword.ConfirmPassword')}
              </IFText>

              <IFTextInput
                className={Styles.input}
                style={{ caretColor: Colors.primary }}
                isFixed={true}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value)
                  e.target.value.length === 0
                    ? setConfirmPasswordError(t('ResetPassword.Required'))
                    : !validator.isLength(e.target.value, { min: 8, max: 100 })
                    ? setConfirmPasswordError(t('ResetPassword.PasswordError'))
                    : validator.equals(e.target.value, password)
                    ? ResetPasswords()
                    : setConfirmPasswordError(
                        t('ResetPassword.PasswordMismatch'),
                      )
                  const reset =
                    validator.isEmail(email) &&
                    validator.isLength(password, { min: 8, max: 100 }) &&
                    validator.equals(password, e.target.value)
                  setCanReset(reset)
                }}
                onBlur={(e) => {
                  e.target.value.length === 0
                    ? setConfirmPasswordError(t('ResetPassword.Required'))
                    : !validator.isLength(e.target.value, { min: 8, max: 100 })
                    ? setConfirmPasswordError(t('ResetPassword.PasswordError'))
                    : validator.equals(e.target.value, password)
                    ? ResetPasswords()
                    : setConfirmPasswordError(
                        t('ResetPassword.PasswordMismatch'),
                      )
                  const reset =
                    validator.isEmail(email) &&
                    validator.isLength(password, { min: 8, max: 100 }) &&
                    validator.equals(password, e.target.value)
                  setCanReset(reset)
                }}
                type="password"
                {...(confirmPasswordError.length > 0
                  ? { errorText: confirmPasswordError }
                  : {})}
              />
            </div>
          </div>
        </div>
        <div className={Styles.signIn}>
          <IFButton
            text={t('ResetPassword.Reset')}
            color={Colors.primary}
            isFill={true}
            size="sm"
            onClick={() => {
              resetPassVerification(email, () => {
                confirmResetDialog.current.handleOpen()
              })
            }}
            isLoading={
              resetPassVerificationRequestState === RequestState.LOADING
            }
            isDead={!canReset}
          ></IFButton>
        </div>
      </div>
      <IFModal
        ref={confirmResetDialog}
        title={t('ResetPassword.ModalTitle')}
        width={'30rem'}
        centerTitle={true}
      >
        <Fragment>
          <IFText
            className={Styles.SubTitle}
            style={{ color: Colors.resetPasswordModalSubTitle }}
          >
            {t('ResetPassword.ModalSubTitle')}
            <br></br>
            {email}
          </IFText>
          <div className={Styles.PinFieldContainer}>
            <ReactPinField
              className={classes.pinFieldStyles}
              length={6}
              validate={t('ResetPassword.PinValidationString')}
              onChange={(value) => {
                setConfirmPin(value)
              }}
            />
          </div>
          <IFButton
            text={t('ResetPassword.ModalButtonText')}
            size="sm"
            color={Colors.primary}
            isFill={true}
            className={Styles.ConfirmButton}
            isLoading={resetPasswordRequestState === RequestState.LOADING}
            onClick={() => {
              resetPass(password, confirmPin, resetPassEmailID, () => {
                confirmResetDialog.current.handleClose()
                toaster.push(
                  <IFToastMessage
                    type="success"
                    text={t('ResetPassword.ResetPasswordSuccess')}
                  />,

                  navigate('/auth/login'),
                )
              })
            }}
          ></IFButton>
        </Fragment>
      </IFModal>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    resetPass: (
      newPassword,
      emailVerificationCode,
      emailVerificationId,
      onSuccess,
    ) =>
      dispatch(
        AuthActions.resetPass(
          newPassword,
          emailVerificationCode,
          emailVerificationId,
          onSuccess,
        ),
      ),
    resetPassVerification: (email, onSuccess) =>
      dispatch(AuthActions.resetPassVerification(email, onSuccess)),
  }
}

const mapStateToProps = (state) => ({
  resetPasswordRequestState: AuthSelectors.getResetPasswordRequestState(state),
  resetPassVerificationRequestState:
    AuthSelectors.getResetPassVerificationRequestState(state),
  resetPassEmailID: AuthSelectors.getResetPassEmailID(state),
})

ResetPassword.propTypes = {
  resetPass: PropTypes.func,
  resetPassVerification: PropTypes.func,
  isResetPassLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
