import React from 'react'
import Styles from './UserListItemSkeleton.module.css'
import { useTranslation } from 'react-i18next'
import { IFSkeleton } from 'Components'
import Variant from 'Enums/Variant'

const UserListItemSkeleton = ({ variant }) => {
  const { t } = useTranslation()

  return (
    <div
      className={Styles.Container}
      style={variant === Variant.LOCALLIST ? { padding: 0 } : {}}
    >
      <div className={Styles.LastUpdatedContainer}>
        {!variant === Variant.LOCALLIST && (
          <IFSkeleton variant="text" width="1rem" height="1rem" />
        )}
      </div>
      <div className={Styles.UserDetailsContainer}>
        <div>
          <IFSkeleton
            variant="circular"
            width={variant === Variant.LOCALLIST ? '1.8rem' : '2.5rem'}
            height={variant === Variant.LOCALLIST ? '1.8rem' : '2.5rem'}
          />
        </div>
        <div className={Styles.UserCredentialsContainer}>
          <div>
            <IFSkeleton
              variant="text"
              width="5rem"
              height={variant === Variant.LOCALLIST ? '0.8rem' : '1.2rem'}
            />
          </div>
          <div>
            <IFSkeleton
              variant="text"
              width="3rem"
              height={variant === Variant.LOCALLIST ? '0.8rem' : '1rem'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserListItemSkeleton
