import PropTypes from 'prop-types'
import React from 'react'
import Colors from 'Theme/Colors'
import Styles from './ListHeader.module.css'
import styled from 'styled-components'
import { IFText, ListHeaderSkeleton } from 'Components'
import { Divider } from '@material-ui/core'
import AddIcon from '@mui/icons-material/Add'
import Icon from '@material-ui/core/Icon'
import { isPropsMatch } from 'Utils/PropsMatch'

const Container = styled.div`
  background-color: ${(props) => props.StationListHeaderBackground};
`

const IconContainer = styled.div`
  color: ${(props) => props.textColor};
`

const ListHeader = ({
  style,
  count,
  title,
  isLoading,
  onAddClick,
  addPermission,
  isIconDisabled = false,
}) => {
  const singularTitle = title.slice(0, -1)
  return (
    <Container
      StationListHeaderBackground={Colors.StationListHeaderBackground}
      style={style}
    >
      {isLoading ? (
        <ListHeaderSkeleton addPermission={addPermission} />
      ) : (
        <div className={Styles.Container}>
          <div className={Styles.LeftContainer}></div>

          <div className={Styles.TextContainer}>
            <IFText
              style={{ color: `${Colors.text}` }}
              className={Styles.SitesText}
            >{`${count} ${count === 1 ? singularTitle : title}`}</IFText>
          </div>

          <IconContainer
            textColor={Colors.text}
            className={
              isIconDisabled
                ? Styles.IconContainerDisabled
                : Styles.IconContainer
            }
          >
            {addPermission && (
              <Icon
                sx={{ fontSize: 20 }}
                component={AddIcon}
                onClick={isIconDisabled ? () => {} : onAddClick}
              />
            )}
          </IconContainer>
        </div>
      )}
      <Divider />
    </Container>
  )
}

ListHeader.propTypes = {
  count: PropTypes.number,
  isLoading: PropTypes.bool,
  onAddClick: PropTypes.func,
  addPermission: PropTypes.bool,
  title: PropTypes.string,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'style',
    'count',
    'title',
    'isLoading',
    'addPermission',
  ])
}
export default React.memo(ListHeader, shouldSkipRender)
