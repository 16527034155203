import Styles from './SideNavBar.module.css'
import { Colors, Images } from 'Theme'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import { useTranslation } from 'react-i18next'
import Fade from '@mui/material/Fade'
import { withStyles } from '@material-ui/styles'
import { IFText, IFsvg } from 'Components'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'
import history from 'history/browser'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const getSelectedIndex = (pathname, newPages) => {
  const slashArray = pathname.split('/')
  const result = newPages.findIndex(
    (page) => page.endpoint === '/' + slashArray[1],
  )
  return result
}

const onMapsPage = (pathname) => {
  if (pathname.includes('map')) return true
  else return false
}

const calculateMidpoint = (index) => {
  // 16 padding
  // 40 div height
  // 20 center of div
  return 16 + 40 * index + 20 + 16 * index
}

const IconContainer = styled.button`
  color: ${Colors.white};
`

const IconsDiv = styled.div`
  clip-path: polygon(
    0% 0%,
    0% 100%,
    100% 100%,
    100% ${(props) => props.triangleMidpoint + props.offset}px,
    87.5% ${(props) => props.triangleMidpoint}px,
    100% ${(props) => props.triangleMidpoint - props.offset}px,
    100% 0%
  );
`

const SideBarItem = styled.div`
  display: flex;
  border-left: 2px solid;
  justify-content: space-between;
  box-sizing: border-box;
  margin-left: 1px;
  &:hover {
    border-color: ${Colors.SideBarItemHover} !important;
  }
`

const SideNavBar = ({
  initialIndex = 0,
  avatar,
  username,
  permissions,
  onLogoutClick,
  logoUrl,
  isUsingMobileApp,
}) => {
  const navigate = useNavigate()

  const displayedPages = [
    {
      icon: IFsvg.Flash,
      endpoint: '/sites',
      role: InfinityEnums.TenantUserPermission.CAN_VIEW_STATION_CHARGEPOINT,
      title: 'Sites',
    },
    {
      icon: IFsvg.Admin,
      endpoint: '/tenantUsers',
      role: InfinityEnums.TenantUserPermission.CAN_VIEW_TENANT_USER,
      title: 'Tenant Users',
    },
    {
      icon: IFsvg.Globe,
      endpoint: '/map',
      role: InfinityEnums.TenantUserPermission.CAN_VIEW_STATION_CHARGEPOINT,
      title: 'Map',
    },
  ]
  if (isUsingMobileApp) {
    displayedPages.splice(1, 0, {
      icon: IFsvg.Users,
      endpoint: '/users',
      role: InfinityEnums.TenantUserPermission.CAN_VIEW_USER,
      title: 'Users',
    })
  } else {
    displayedPages.splice(1, 0, {
      icon: IFsvg.Card,
      endpoint: '/cards',
      role: InfinityEnums.TenantUserPermission.CAN_VIEW_CHARGING_TOKEN,
      title: 'Cards',
    })
  }
  const StyledTooltip = withStyles({
    tooltip: {
      backgroundColor: Colors.primary,
      color: Colors.white,
      fontSize: '0.875rem',
      fontFamily: 'ProximaNova',
      padding: '0.3rem',
      marginLeft: '0.313rem',
      alignSelf: 'center',
    },
  })(Tooltip)
  const [newPages, setNewPages] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(
    getSelectedIndex(window.location.pathname, newPages),
  )
  const [anchorEl, setAnchorEl] = useState(null)
  const [triangleMidpoint, setTriangleMidpoint] = useState(
    calculateMidpoint(initialIndex),
  )
  const offset = 8

  const { t } = useTranslation()

  const menuClickHandler = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const menuCloseHandler = () => setAnchorEl(null)

  const logoutHandler = () => {
    setAnchorEl(null)
    onLogoutClick()
  }
  let location = useLocation()

  useEffect(() => {
    const index = getSelectedIndex(location.pathname, newPages)
    setSelectedIndex(index)
    setTriangleMidpoint(calculateMidpoint(index))
  }, [location])

  useEffect(() => {
    if (!permissions || permissions.length === 0) return
    else {
      checkPermissions()
    }
  }, [permissions])

  const checkPermissions = () => {
    setNewPages([])
    let tempPages = []
    for (let page of displayedPages) {
      if (
        page.role &&
        permissions.some((permission) => permission === page.role)
      )
        tempPages.push(page)
    }
    setNewPages(tempPages)
    const index = getSelectedIndex(window.location.pathname, tempPages)
    setSelectedIndex(index)
    setTriangleMidpoint(calculateMidpoint(index))
  }

  return (
    <div
      className={
        onMapsPage(window.location.pathname)
          ? Styles.SideNavBarWrappingDivMap
          : Styles.SideNavBarWrappingDiv
      }
    >
      <Link to="/">
        <div className={Styles.logoWrappingDiv}>
          <img
            className={Styles.logo}
            src={logoUrl ? logoUrl : Images.newLogo}
            alt="logo"
          />
        </div>
      </Link>
      <IconsDiv
        offset={offset}
        triangleMidpoint={triangleMidpoint}
        className={Styles.iconsWrappingDiv}
        style={{
          backgroundColor: Colors.primary,
          boxShadow: `0px 0px 4px ${Colors.BoxShadow}`,
        }}
      >
        {newPages.map((page, index) => (
          <div onClick={() => {}} className={Styles.IconDiv}>
            <Link
              key={`link ${index}`}
              to={page.endpoint}
              style={{ width: '100%' }}
              onClick={() => {
                setSelectedIndex(index)
                setTriangleMidpoint(calculateMidpoint(index))
                history.push(page.endpoint)
                navigate(page.endpoint)
              }}
            >
              <StyledTooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 60 }}
                title={page.title}
                placement="right"
              >
                <SideBarItem
                  style={{
                    borderColor:
                      index === selectedIndex
                        ? Colors.white
                        : Colors.transparent,
                  }}
                >
                  <IconContainer
                    shouldHover={selectedIndex !== index}
                    key={index}
                    className={Styles.iconContainer}
                    style={{
                      backgroundColor: Colors.primary,
                    }}
                  >
                    <page.icon fill={Colors.white} width={30} height={30} />
                  </IconContainer>
                </SideBarItem>
              </StyledTooltip>
            </Link>
          </div>
        ))}

        <div className={Styles.bottomContainer}>
          <div className={Styles.TitleWrappingDiv}></div>

          <div
            className={Styles.AvatarWrappingDiv}
            style={{ backgroundColor: Colors.transparent }}
            onClick={menuClickHandler}
          >
            {avatar}
          </div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={menuCloseHandler}
            className={styled.Menu}
            style={{
              top: '0rem',
              left: '3rem',
              bottom: '2.5rem',
            }}
          >
            <MenuItem
              className={Styles.Username}
              disabled={true}
              onClick={menuCloseHandler}
            >
              <IFText>{username}</IFText>
            </MenuItem>
            <MenuItem className={Styles.Username} onClick={logoutHandler}>
              <IFText style={{ color: Colors.red }}>
                {t('SideNavBar.logout')}
              </IFText>
            </MenuItem>
          </Menu>
        </div>
      </IconsDiv>
    </div>
  )
}

SideNavBar.propTypes = {
  initialIndex: PropTypes.number,
  avatar: PropTypes.object,
  username: PropTypes.string,
  onLogoutClick: PropTypes.func,
}

export default SideNavBar
