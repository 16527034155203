import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  UploadDiagnosticsListItemSkeleton,
  UploadDiagnosticsListItem,
  IFToastMessage,
  NoDiagnostics,
  CustomScrollbar,
} from 'Components'
import Styles from './UploadDiagnosticsList.module.css'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import { chargePointDataApiService } from 'Services/ApiService'
import { saveAs } from 'file-saver'
import { toaster } from 'rsuite'
import { Virtuoso } from 'react-virtuoso'

TimeAgo.addDefaultLocale(en)

const UploadDiagnosticsList = React.forwardRef(
  (
    {
      uploadDiagnosticsList,
      fetchChargePointUploadDiagnostics,
      fetchChargePointUploadDiagnosticsRequestState,
      chargePoint,
    },
    ref,
  ) => {
    useEffect(() => {
      if (chargePoint) {
        fetchChargePointUploadDiagnostics(chargePoint._id)
      }
    }, [chargePoint])
    const skeletonsCount = 11

    const downloadFile = async (fileName, chargePointId) => {
      return chargePointDataApiService({
        url: `/${chargePointId}/uploadDiagnostics/file`,
        method: 'GET',
        params: {
          fileName: fileName,
        },
        responseType: 'blob',
      })
        .then((res) => {
          const blob = new Blob([res.data])
          saveAs(blob, `${res.config.params.fileName}`)
        })
        .catch((err) => {
          toaster.push(
            <IFToastMessage
              type="error"
              text={err.message ? err.message : err.statusText}
            />,
            { placement: 'topEnd' },
          )
        })
    }

    return (
      <div className={Styles.Container}>
        {fetchChargePointUploadDiagnosticsRequestState ===
        RequestState.LOADING ? (
          [...Array(skeletonsCount)].map((e, index) => (
            <div
              className={
                index === skeletonsCount - 1 ? {} : Styles.ListItemContainer
              }
              key={`UploadDiagnosticsListItemSkeleton ${index}`}
            >
              <UploadDiagnosticsListItemSkeleton key={index} />
            </div>
          ))
        ) : fetchChargePointUploadDiagnosticsRequestState !==
            RequestState.LOADING && uploadDiagnosticsList.length === 0 ? (
          <NoDiagnostics />
        ) : (
          <Virtuoso
            data={uploadDiagnosticsList}
            increaseViewportBy={480}
            itemContent={(index, uploadDiagnostic) => {
              return (
                <div
                  className={
                    index === uploadDiagnosticsList.length - 1
                      ? {}
                      : Styles.ListItemContainer
                  }
                  key={`UploadDiagnosticListItem ${index}`}
                >
                  <UploadDiagnosticsListItem
                    fileName={uploadDiagnostic.fileName}
                    status={uploadDiagnostic.status}
                    createdAt={
                      <ReactTimeAgo
                        date={new Date(uploadDiagnostic.createdAt)}
                        locale="en-US"
                      />
                    }
                    onDownload={(fileName) =>
                      downloadFile(fileName, chargePoint._id)
                    }
                  />
                </div>
              )
            }}
            components={{ Scroller: CustomScrollbar }}
          />
        )}
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointUploadDiagnostics: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointUploadDiagnostics(chargePointId),
      ),
  }
}

const mapStateToProps = (state) => ({
  uploadDiagnosticsList:
    ChargePointSelectors.getChargePointUploadDiagnostics(state),
  fetchChargePointUploadDiagnosticsRequestState:
    ChargePointSelectors.getFetchChargePointUploadDiagnosticsRequestState(
      state,
    ),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
})

UploadDiagnosticsList.propTypes = {
  uploadDiagnosticsList: PropTypes.arrayOf(PropTypes.object),
  fetchChargePointUploadDiagnostics: PropTypes.func,
  fetchChargePointUploadDiagnosticsRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  listHeight: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UploadDiagnosticsList)
