import * as React from 'react'
import Styles from './ChargePointLogListItemSkeleton.module.css'
import IFSkeleton from 'Components/IFSkeleton/IFSkeleton'
import colors from 'Theme/Colors'
function ChargePointLogListItemSkeleton({ ...props }) {
  return (
    <div {...props}>
      <div className={Styles.wrapper}>
        <div className={Styles.container}>
          <IFSkeleton className={Styles.icon} variant="circular" />
          <IFSkeleton className={Styles.leftText} />
          <IFSkeleton className={Styles.rightText} />
        </div>
      </div>
    </div>
  )
}

export default ChargePointLogListItemSkeleton
