import React, { useEffect, useState } from 'react'
import Styles from './Login.module.css'
import Link from '@mui/material/Link'
import { IFButton, IFText, IFTextInput } from 'Components'
import { Colors, Images } from 'Theme'
import AuthSelectors from 'Stores/Auth/Selectors'
import AuthActions from 'Stores/Auth/Actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import validator from 'validator'

const Login = ({ login, isLoginLoading, tenantUserLoginResetLoading }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(undefined)
  const [passwordError, setPasswordError] = useState(undefined)
  const [canLogin, setCanLogin] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    tenantUserLoginResetLoading()
  }, [])

  return (
    <div>
      <div
        className={Styles.backgroundImage}
        style={{
          backgroundImage: `url(${Images.loginBackground})`,
        }}
      />
      <div
        className={Styles.loginwrapper}
        style={{
          backgroundColor: Colors.loginBackgroundColor,
          boxShadow: `0px 0px 4px ${Colors.loginBackgroundShadow}`,
        }}
      >
        <div className={Styles.logo}>
          <img className={Styles.img} src={Images.infinity} alt="logo" />
        </div>
        <div className={Styles.titleContainer}>
          <IFText className={Styles.title}> {t('Login.Welcome')}</IFText>
        </div>
        <div className={Styles.inputContainer}>
          <IFText className={Styles.label}>{t('Login.EmailAddress')}</IFText>

          <IFTextInput
            placeholder={t('Login.EmailAddressPlaceholder')}
            style={{ caretColor: Colors.primary }}
            className={Styles.input}
            isFixed={true}
            value={email}
            name="email"
            type="email"
            onChange={(e) => {
              setEmail(e.target.value)
              if (emailError) {
                validator.isEmail(e.target.value)
                  ? setEmailError(undefined)
                  : setEmailError(t('Login.EmailError'))
              }
              const login =
                validator.isEmail(e.target.value) &&
                validator.isLength(password, { min: 8, max: 100 })
              setCanLogin(login)
            }}
            onBlur={(e) => {
              validator.isEmail(email)
                ? setEmailError(undefined)
                : setEmailError(t('Login.EmailError'))
              const login =
                validator.isEmail(e.target.value) &&
                validator.isLength(password, { min: 8, max: 100 })
              setCanLogin(login)
            }}
            onKeyPress={(event) => {
              if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (canLogin) login(email, password)
              }
            }}
            {...(emailError ? { errorText: emailError } : {})}
          />
          <div style={{ height: '1.75rem' }}></div>
          <IFText className={Styles.label}>{t('Login.Password')}</IFText>

          <IFTextInput
            className={Styles.input}
            style={{ caretColor: Colors.primary }}
            isFixed={true}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              if (passwordError) {
                validator.isLength(e.target.value, { min: 8, max: 100 })
                  ? setPasswordError(undefined)
                  : setPasswordError(t('Login.PasswordError'))
              }
              const login =
                validator.isEmail(email) &&
                validator.isLength(e.target.value, { min: 8, max: 100 })
              setCanLogin(login)
            }}
            onBlur={(e) => {
              validator.isLength(password, { min: 8, max: 100 })
                ? setPasswordError(undefined)
                : setPasswordError(t('Login.PasswordError'))
              const login =
                validator.isEmail(email) &&
                validator.isLength(e.target.value, { min: 8, max: 100 })
              setCanLogin(login)
            }}
            type="password"
            onKeyPress={(event) => {
              if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (canLogin) login(email, password)
              }
            }}
            {...(passwordError ? { errorText: passwordError } : {})}
          />
        </div>
        <div className={Styles.signIn}>
          <IFButton
            text={t('Login.SignIn')}
            color={Colors.primary}
            isFill={true}
            size="sm"
            onClick={() => {
              login(email, password)
            }}
            isLoading={isLoginLoading}
            isDead={!canLogin}
          ></IFButton>
        </div>
        <div className={Styles.linkContainer}>
          <Link
            href="/auth/reset"
            variant="body2"
            className={Styles.link}
            style={{
              color: Colors.primary,
            }}
          >
            <IFText className={Styles.link}>{t('Login.ForgotPassword')}</IFText>
          </Link>
        </div>
      </div>
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) =>
      dispatch(AuthActions.tenantUserLogin(email, password)),
    tenantUserLoginResetLoading: () =>
      dispatch(AuthActions.tenantUserLoginResetLoading()),
  }
}

const mapStateToProps = (state) => ({
  isLoginLoading: AuthSelectors.isTenantUserLoginLoading(state),
})

Login.propTypes = {
  login: PropTypes.func,
  isLoginLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
