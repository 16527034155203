import { put, call } from 'redux-saga/effects'
import {
  tenantUserApiService,
  handleError,
  subscriptionService,
  privacyRuleService,
  accessGroupApiService,
  cityService,
} from 'Services/ApiService.js'
import TenantUserActions from 'Stores/TenantUser/Actions'
import StationActions from 'Stores/Station/Actions'
import { tenantUserLogin } from './AuthSaga'
import { toaster } from 'rsuite'
import { IFToastMessage } from 'Components'

export function* fetchTenantUserList({}) {
  yield put(TenantUserActions.fetchTenantUserListLoading())
  try {
    const { data } = yield call(tenantUserApiService.get, '/')

    yield put(TenantUserActions.fetchTenantUserListSuccess(data))
  } catch (e) {
    yield put(TenantUserActions.fetchTenantUserListFail(e))
    handleError(e)
  }
}

export function* updateTenantUser({
  tenantUserId,
  permissions,
  tenantUserItemRef,
  onSuccess,
  onError,
}) {
  yield put(TenantUserActions.updateTenantUserLoading())
  try {
    const { data } = yield call(
      tenantUserApiService.patch,
      `/${tenantUserId}`,
      { permissions: permissions },
    )

    yield put(TenantUserActions.updateTenantUserSuccess(tenantUserId, data))
    tenantUserItemRef.current.collapse()
    onSuccess()
    toaster.push(
      <IFToastMessage type="success" text={'Tenant user updated'} />,
      {
        placement: 'topEnd',
      },
    )
  } catch (e) {
    onError()
    tenantUserItemRef.current.collapse()
    yield put(TenantUserActions.updateTenantUserFail(e))
    handleError(e)
  }
}

export function* fetchInviteTenantUserList({}) {
  yield put(TenantUserActions.fetchInviteTenantUserListLoading())
  try {
    const { data } = yield call(tenantUserApiService.get, '/inviteTenantUser')

    yield put(TenantUserActions.fetchInviteTenantUserListSuccess(data))
  } catch (e) {
    yield put(TenantUserActions.fetchInviteTenantUserListFail(e))
    handleError(e)
  }
}

export function* inviteTenantUser({ tenantUserData }) {
  yield put(TenantUserActions.inviteTenantUserLoading())
  try {
    const { data } = yield call(
      tenantUserApiService.post,
      '/inviteTenantUser',
      { ...tenantUserData },
    )

    yield put(TenantUserActions.inviteTenantUserSuccess(data))
    toaster.push(<IFToastMessage type="success" text={'Invitation sent'} />, {
      placement: 'topEnd',
    })
  } catch (e) {
    yield put(TenantUserActions.inviteTenantUserFail(e))
    handleError(e)
  }
}

export function* deleteTenantUser({ tenantUserId, ref }) {
  yield put(TenantUserActions.deleteTenantUserLoading())
  try {
    const { data } = yield call(tenantUserApiService.delete, `${tenantUserId}`)

    yield put(TenantUserActions.deleteTenantUserSuccess(tenantUserId))
    if (ref) ref.current.collapse()
  } catch (e) {
    yield put(TenantUserActions.deleteTenantUserFail(e))
    handleError(e)
  }
}

export function* deleteInviteTenantUser({ inviteTenantUserId, ref }) {
  yield put(TenantUserActions.deleteInviteTenantUserLoading())
  try {
    const { data } = yield call(
      tenantUserApiService.delete,
      `/inviteTenantUser/${inviteTenantUserId}`,
    )

    yield put(
      TenantUserActions.deleteInviteTenantUserSuccess(inviteTenantUserId),
    )
    if (ref) ref.current.collapse()
  } catch (e) {
    yield put(TenantUserActions.deleteInviteTenantUserFail(e))
    handleError(e)
  }
}

export function* sendTenantUserEmail({ email, ref }) {
  yield put(TenantUserActions.sendTenantUserEmailLoading())
  try {
    const { data } = yield call(
      tenantUserApiService.post,
      `/sendTenantUserEmail`,
      {
        email: email,
      },
    )
    yield put(TenantUserActions.sendTenantUserEmailSuccess())
    ref.current.collapse()
  } catch (e) {
    yield put(TenantUserActions.sendTenantUserEmailFail(e))
    handleError(e)
  }
}

export function* fetchInviteTenantUser({ inviteTenantUserId }) {
  yield put(TenantUserActions.fetchInviteTenantUserLoading())
  try {
    const { data } = yield call(
      tenantUserApiService.get,
      `/inviteTenantUser/${inviteTenantUserId}`,
    )

    yield put(TenantUserActions.setInviteTenantUserStatus(data.status))
    yield put(TenantUserActions.fetchInviteTenantUserSuccess(data))
  } catch (e) {
    yield put(TenantUserActions.fetchInviteTenantUserFail(e))
    handleError(e)
  }
}

export function* createTenantUser({ tenantUser }) {
  yield put(TenantUserActions.createTenantUserLoading())
  try {
    const { data } = yield call(tenantUserApiService.post, '/', {
      ...tenantUser,
    })
    yield put(TenantUserActions.createTenantUserSuccess())

    yield* tenantUserLogin({
      email: tenantUser.email,
      password: tenantUser.password,
    })
  } catch (e) {
    yield put(TenantUserActions.createTenantUserFail(e))
    handleError(e)
  }
}

export function* fetchStationCities({}) {
  yield put(StationActions.fetchStationCitiesLoading())
  try {
    const { data } = yield call(cityService.get, '/')
    yield put(StationActions.fetchStationCitiesSuccess(data))
  } catch (e) {
    yield put(StationActions.fetchStationCitiesFail(e))
    handleError(e)
  }
}

export function* addStationCity({ city }) {
  yield put(StationActions.addStationCityLoading())
  try {
    const { data } = yield call(cityService.post, '/', {
      name: city,
    })

    yield put(StationActions.addStationCitySuccess(data.name))
  } catch (e) {
    yield put(StationActions.addStationCityFail(e))
    handleError(e)
  }
}

export function* fetchSubscriptionsList({}) {
  yield put(TenantUserActions.fetchSubscriptionsListLoading())
  try {
    const { data } = yield call(subscriptionService.get, '/me/all')
    yield put(
      TenantUserActions.fetchSubscriptionsListSuccess(data.subscriptions),
    )
  } catch (e) {
    yield put(TenantUserActions.fetchSubscriptionsListFail(e))
    handleError(e)
  }
}

export function* fetchPrivacySchedule({}) {
  yield put(TenantUserActions.fetchPrivacyScheduleLoading())
  try {
    const { data } = yield call(privacyRuleService.get, '/me/all')
    yield put(TenantUserActions.fetchPrivacyScheduleSuccess(data.privacyRules))
  } catch (e) {
    yield put(TenantUserActions.fetchPrivacyScheduleFail(e))
    handleError(e)
  }
}

export function* fetchAccessGroupsList({}) {
  yield put(TenantUserActions.fetchAccessGroupsListLoading())
  try {
    const { data } = yield call(accessGroupApiService.get, '/me/all')
    yield put(TenantUserActions.fetchAccessGroupsListSuccess(data.accessGroups))
  } catch (e) {
    yield put(TenantUserActions.fetchAccessGroupsListFail(e))
    handleError(e)
  }
}
