import React, { useEffect, useState, useRef } from 'react'
import Styles from './ChargePointSpecsContainer.module.css'
import { IFText, IFSkeleton, IFsvg } from 'Components'
import {
  AccessGroupsContainer,
  ScheduleContainer,
  SubscriptionContainer,
} from 'Containers'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Colors } from 'Theme'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import RequestState from 'Enums/RequestState'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { calculateOuterContainerHeight } from 'Utils/ConnectorFunctions'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import TenantUserActions from 'Stores/TenantUser/Actions'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import InfinityEnums from 'Enums/InfinityEnums'
import { useTranslation } from 'react-i18next'

const ListContainer = styled.div`
  box-shadow: 0px 0px 2px 0px ${Colors.ChargePointSpecsListContainerShadow};
  border-radius: 0px 0px 5px 5px;
  padding-top: 0.5rem;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
`

const ChargePointSpecsContainer = ({
  chargePoint,
  fetchStationListRequestState,
  fetchChargePointListRequestState,
  fetchChargePointRequestState,
  isEditing,
  fetchChargePointSubscriptionsList,
  fetchSubscriptionsList,
  fetchPrivacySchedule,
  fetchChargePointPrivacyScheduleList,
  fetchChargePointAccessGroups,
  fetchAccessGroups,
  chargePointUid,
}) => {
  const TabHeaders = [
    {
      title: 'Subscriptions',
      component: SubscriptionContainer,
      style: { paddingRight: '0.5rem' },
    },
    {
      title: 'Access Groups',
      component: AccessGroupsContainer,
      style: {
        filter: chargePoint && !chargePoint.isPrivate ? 'blur(5px)' : '',
      },
    },
    {
      title: 'Schedule',
      component: ScheduleContainer,
    },
  ]

  const [selectedIndex, setSelectedIndex] = useState(0)
  const selectedComponentRef = useRef()
  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue)
  }

  const handleRefresh = (index) => {
    if (index === selectedIndex) {
      selectedComponentRef.current.handleRefresh()
    }
  }
  useEffect(() => {
    if (chargePoint) {
      fetchChargePointSubscriptionsList(chargePoint._id)
      fetchSubscriptionsList()

      fetchChargePointPrivacyScheduleList(chargePoint._id)
      fetchPrivacySchedule()

      fetchChargePointAccessGroups(chargePoint._id)
      fetchAccessGroups()
    }
  }, [chargePointUid])

  const { t } = useTranslation()

  return (
    <div className={Styles.ChargePointSpecsWrapper}>
      <div className={Styles.ChargePointSpecsTabsWrapper}>
        <Tabs
          value={selectedIndex}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              transition: 'none',
              backgroundColor:
                fetchChargePointListRequestState === RequestState.LOADING ||
                fetchStationListRequestState === RequestState.LOADING ||
                fetchChargePointRequestState === RequestState.LOADING
                  ? Colors.transparent
                  : Colors.primary,
            },
          }}
        >
          {TabHeaders.map((tab, index) => (
            <Tab
              sx={{
                padding: '0px',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
              key={index}
              label={
                fetchChargePointListRequestState === RequestState.LOADING ||
                fetchStationListRequestState === RequestState.LOADING ||
                fetchChargePointRequestState === RequestState.LOADING ? (
                  <IFSkeleton variant="text" width="6rem" height="2rem" />
                ) : (
                  <div
                    className={Styles.SpecsTab}
                    onClick={() => handleRefresh(index)}
                  >
                    {tab.title === 'Access Groups' &&
                    chargePoint &&
                    !chargePoint.isPrivate ? (
                      <div className={Styles.CautionContainer}>
                        <IFsvg.Caution
                          height={15}
                          width={15}
                          fill={Colors.red}
                        />
                      </div>
                    ) : null}
                    <IFText
                      style={{
                        color:
                          selectedIndex === index
                            ? Colors.text
                            : Colors.ChargePointSpecsTabsNotSelected,
                      }}
                      className={
                        tab.title === 'Access Groups'
                          ? Styles.AccessGroupsTabTitle
                          : Styles.TabTitle
                      }
                    >
                      {tab.title}
                    </IFText>
                  </div>
                )
              }
            />
          ))}
        </Tabs>
      </div>
      {TabHeaders.map((tab, index) =>
        selectedIndex === index ? (
          <div className={Styles.CautionTextWrapper}>
            <div className={Styles.CautionTextContainer}>
              {tab.title === 'Access Groups' &&
              chargePoint &&
              !chargePoint.isPrivate ? (
                <div>
                  <IFText
                    className={Styles.CautionText}
                    style={{ color: Colors.red }}
                  >
                    {t('ChargepointSpecs.PublicStationsCaution')}
                  </IFText>
                </div>
              ) : null}
            </div>
            <ListContainer
              style={{
                height:
                  fetchChargePointRequestState === RequestState.LOADING
                    ? '104px'
                    : calculateOuterContainerHeight(chargePoint?.connectors),
              }}
            >
              <tab.component
                ref={selectedComponentRef}
                renderFor={InfinityEnums.ChargePointSpecsContainer.CHARGEPOINT}
                isEditing={isEditing}
                {...(tab.style ? { style: tab.style } : {})}
              />
            </ListContainer>
          </div>
        ) : null,
      )}
    </div>
  )
}
function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointSubscriptionsList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointSubscriptionsList(chargePointId),
      ),
    fetchSubscriptionsList: () =>
      dispatch(TenantUserActions.fetchSubscriptionsList()),

    fetchPrivacySchedule: () =>
      dispatch(TenantUserActions.fetchPrivacySchedule()),
    fetchChargePointPrivacyScheduleList: (chargePointId) =>
      dispatch(
        ChargePointActions.fetchChargePointPrivacyScheduleList(chargePointId),
      ),

    fetchChargePointAccessGroups: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointAccessGroups(chargePointId)),
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

const mapStateToProps = (state) => ({
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
})

ChargePointSpecsContainer.propTypes = {
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  chargePoint: PropTypes.object,
  fetchChargePointRequestState: PropTypes.number,
  chargePointUid: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChargePointSpecsContainer)
