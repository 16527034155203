import moment from 'moment'

export function formatRepeatDate(repeat) {
  repeat = repeat.sort(function (a, b) {
    return a - b
  })
  let foundRange = false
  let consecutiveCount = 1
  let arrayOfRanges = []
  let range = []
  let singleDays = []
  let loopAgain = false
  for (let index = 1; index < repeat.length && consecutiveCount < 7; index++) {
    if (loopAgain && !foundRange) {
      break
    }
    const dayNum = repeat[index]
    if (dayNum === repeat[index - 1] + 1) {
      foundRange = true
      consecutiveCount++
      range.push(repeat[index - 1])
    } else {
      if (foundRange) {
        range.push(repeat[index - 1])
        if (range.length > 2) arrayOfRanges.push(range)
      }

      foundRange = false
      consecutiveCount = 1
      range = []
    }
    if (index === repeat.length - 1) {
      if (
        !loopAgain &&
        repeat[0] === 0 &&
        repeat[index] === 6 &&
        consecutiveCount < 7
      ) {
        range.push(repeat[index])
        index = 0
        consecutiveCount = 1
        loopAgain = true
        foundRange = true
        continue
      } else {
        if (foundRange) {
          range.push(repeat[index])
          if (range.length > 2) arrayOfRanges.push(range)
        }
      }
    }
  }
  arrayOfRanges.forEach((range, i) => {
    let arrayOfRangesCopy = [...arrayOfRanges]
    arrayOfRangesCopy.splice(i, 1)
    arrayOfRangesCopy.forEach((rangeCopy) => {
      if (range.every((r) => rangeCopy.includes(r))) {
        arrayOfRanges.splice(i, 1)
      } else {
      }
    })
  })
  singleDays = [...new Set(singleDays)]
  let dateText = ''
  let uniqueRangeDays = []
  arrayOfRanges.forEach((range, i) => {
    uniqueRangeDays = [...range]
  })
  uniqueRangeDays = [...new Set(uniqueRangeDays)]
  singleDays = repeat.filter((x) => !uniqueRangeDays.includes(x))

  singleDays.forEach((day, i) => {
    dateText += moment.weekdaysShort(day) + ','
  })
  if (arrayOfRanges.length === 0)
    dateText = dateText.substring(0, dateText.length - 1)

  arrayOfRanges.forEach((range, i) => {
    dateText += i !== 0 ? ' ' : ''
    dateText +=
      moment.weekdaysShort(range[0]) +
      '-' +
      moment.weekdaysShort(range[range.length - 1])
  })
  return dateText
}

export function formatChargingSchedule(chargingSchedule) {
  /*
  entry: {
    id: float,
    current: number,
    time: dateTime
  }
  */
  let output = []

  for (let i = 0; i < chargingSchedule.length; i++) {
    const startTime = chargingSchedule[i].time
    const current = chargingSchedule[i].current
    if (chargingSchedule[i + 1]) {
      const endTime = chargingSchedule[i + 1].time
      pushEntry(output, startTime, endTime, current)
    } else {
      const endTime = new Date().setUTCHours(23, 59, 59, 999)

      pushEntry(output, startTime, endTime, current)
    }
  }
  return output
}

export const formatChargingSchedulePeriods = (
  chargingSchedulePeriods,
  chargingSchedulePeriodsOffset,
) => {
  /*
  expected format:
  [
    {
      startPeriod: 0,
      limit: 32
    },
  ]
  */

  const SECONDS_IN_DAY = 86400
  let current = 0
  let formattedSchedule = []
  let dayPeriods = []
  const sortedPeriods = chargingSchedulePeriods.sort(
    (a, b) => a.startPeriod - b.startPeriod,
  )
  for (let i = 0; i < sortedPeriods.length; i++) {
    const startTime = (sortedPeriods[i].startPeriod - current) / 3600
    let endTime = 0
    let nextEntry = sortedPeriods[i + 1]
    if (nextEntry) {
      endTime = (nextEntry.startPeriod - current) / 3600

      if (nextEntry.startPeriod % SECONDS_IN_DAY === 0) {
        let index = nextEntry.startPeriod / SECONDS_IN_DAY - 1
        dayPeriods.push({
          startTime: new Date().setUTCHours(startTime, 0, 0, 0),
          endTime: new Date().setUTCHours(23, 59, 0, 0),
          current: sortedPeriods[i].limit,
        })
        formattedSchedule.push({
          day: dayOfWeek(index, chargingSchedulePeriodsOffset),
          periods: dayPeriods,
        })
        current = nextEntry.startPeriod
        dayPeriods = []
      } else {
        dayPeriods.push({
          startTime: new Date().setUTCHours(startTime, 0, 0, 0),
          endTime: new Date().setUTCHours(endTime, 0, 0, 0),
          current: sortedPeriods[i].limit,
        })
      }
    } else {
      endTime = (SECONDS_IN_DAY * 7 - current) / 3600
      dayPeriods.push({
        startTime: new Date().setUTCHours(startTime, 0, 0, 0),
        endTime: new Date().setUTCHours(23, 59, 0, 0),
        current: sortedPeriods[i].limit,
      })
      formattedSchedule.push({
        day: dayOfWeek(6, chargingSchedulePeriodsOffset),
        periods: dayPeriods,
      })
    }
  }
  for (let i = 0; i < formattedSchedule.length; i++) {
    let periodsFormatted = []
    for (const period of formattedSchedule[i].periods) {
      pushEntry(
        periodsFormatted,
        period.startTime,
        period.endTime,
        period.current,
      )
    }
    formattedSchedule[i].periods = periodsFormatted
  }
  return formattedSchedule
}

const dayOfWeek = (index, offset = 0) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const adjustedIndex = (index + offset) % 7
  return days[adjustedIndex] || ''
}
const pushEntry = (output, startTime, endTime, current, id) => {
  output.push({
    name: current,
    data: [
      {
        x: 'x',
        y: [startTime, endTime],
      },
    ],
  })
}
export const formatChargingProfiles = (
  ChargingScheduleListRefs,
  name,
  maxCurrent,
) => {
  let periods = []
  let formattedPeriods = []

  for (const ref of ChargingScheduleListRefs) {
    periods.push(ref.current.getChargingPeriods())
  }

  for (let index = 0; index < periods.length; index++) {
    const firstDay = periods[index]
    let lastPeriod = firstDay[0]
    for (const period of firstDay) {
      const date = new Date(period.time)
      const lastDate = new Date(lastPeriod.time)
      const diffInHours = moment(date).diff(moment(lastDate), 'hours')
      formattedPeriods.push({
        startPeriod: index * 86400 + diffInHours * 3600,
        limit: period.current,
      })
    }
  }
  let profile = {
    name,
    maxCurrent,
    profile: {
      chargingSchedule: {
        chargingSchedulePeriod: formattedPeriods,
      },
    },
  }
  return profile
}

export const fillChargingSchedulePeriodDays = (
  duration,
  chargingSchedulePeriod,
) => {
  const totalDays = Math.ceil(duration / (24 * 3600))
  let result = new Array(totalDays)

  chargingSchedulePeriod.forEach((period) => {
    const startDay = Math.floor(period.startPeriod / (24 * 3600))
    const limit = period.limit
    for (let i = startDay; i < totalDays; i++) {
      result[i] = { startPeriod: i * 24 * 3600, limit: limit }
    }
  })

  return result
}
