import React, { useState } from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IFText from 'Components/IFText/IFText'
import Styles from './ChargePointTransactionListItem.module.css'
import Colors from 'Theme/Colors'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Moment from 'moment'
import icons from 'Theme/Icons'
import PropTypes from 'prop-types'
import InfinityEnums from 'Enums/InfinityEnums'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import { isPropsMatch } from 'Utils/PropsMatch'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import styled from 'styled-components'
import { CreditCard, Info, Person, PhoneIphone } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import TransactionStatus from 'Components/TransactionStatus/TransactionStatus'

const StyledHyperLink = styled.a`
  font-size: 14;
  vertical-align: middle;
  text-align: center;
  padding-bottom: 2px;
  margin-left: 6px;
  color: ${(props) => props.textColor};
  &:hover {
    color: ${(props) => props.hoverColor} !important;
  }
  &:before {
    content: '';
    width: 0;
    height: 0.1em;
    position: absolute;
    bottom: 0;
    left: 50%;
    background: ${(props) => props.hoverColor} !important;
    transition: all 0.15s;
  }
  &:hover:before {
    width: 100%;
    left: 0;
    background: ${(props) => props.hoverColor} !important;
  }
`
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.white,
    boxShadow: `0px 0px 4px 0px ${Colors.tooltipBoxShadow}`,
    maxWidth: 'none',
    width: '350px',
  },
}))
function ChargePointTransactionListItem({
  connectorUid,
  connectorType,
  energyConsumption,
  startedAt,
  endedAt,
  processedAt,
  startingChargingToken,
  amount,
  user,
  status,
  startingChargingTokenType,
  ...props
}) {
  const [appUserIdHover, setAppUserIdHover] = useState(false)
  const [isAppUserIdCopied, setIsAppUserIdCopied] = useState(false)
  const durationHr = parseInt(Moment(endedAt).diff(startedAt, 'hours'))
  const durationMin = Moment.utc(
    Moment(endedAt, 'HH:mm:ss').diff(Moment(startedAt, 'HH:mm:ss')),
  ).format('mm')
  const { t } = useTranslation()

  const AccordionSummary = withStyles({
    root: {
      '&$expanded': {
        minHeight: 48,
      },
    },
    content: {
      '&$expanded': {
        margin: '0px',
      },
    },
    expanded: {},
  })(MuiAccordionSummary)

  const useStyles = makeStyles((theme) => ({
    accordionSummaryContainer: {
      '&:hover': {
        backgroundColor: Colors.ChargePointLogListItemHover,
      },
    },
  }))

  const animateUid = () => {
    setIsAppUserIdCopied(!isAppUserIdCopied)
    setTimeout(() => setIsAppUserIdCopied(false), 2000)
  }

  const classes = useStyles()
  const IconSrc = () => {
    let src = ''
    switch (connectorType) {
      case InfinityEnums.ConnectorType.TYPE_2:
        src = icons.type2
        break
      case InfinityEnums.ConnectorType.COMBO_CCS:
        src = icons.comboCCS
        break
      case InfinityEnums.ConnectorType.CHADEMO:
        src = icons.chademo
        break
      case InfinityEnums.ConnectorType.SCHUKO:
        src = icons.schuko
        break
      default:
        src = icons.type2
        break
    }
    return src
  }
  return (
    <div {...props}>
      <Accordion
        className={Styles.accordion}
        style={{
          boxShadow: `0px 0px 0px ${Colors.ChargePointLogTransactionItemBoxShadow}`,
          borderRadius: '0px',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={[
            Styles.accordionSummary,
            classes.accordionSummaryContainer,
          ]}
        >
          <div className={Styles.container}>
            <TransactionStatus status={status} />
            <div className={Styles.accordionIconWrapper}>
              <img
                className={Styles.accordionicon}
                src={IconSrc()}
                alt={'ChargeIcon'}
              />
              <div className={Styles.uidContainer}>
                <IFText className={Styles.accordionLeftText}>
                  {`#${connectorUid}`}
                </IFText>
              </div>
            </div>
            <div className={Styles.accordionTextPower}>
              <div className={Styles.flashIcon}>{icons.flash}</div>
              <IFText className={Styles.accordionMiddleText}>
                {`${energyConsumption / 1000} kWh`}
              </IFText>
            </div>
            <div className={Styles.accordionTextDuration}>
              <TimerOutlinedIcon
                style={{ height: '18px' }}
                className={Styles.accordionicon}
              />
              <IFText className={Styles.accordionMiddleText}>
                {durationHr !== 0 ? `${durationHr}h` : ''}
                {durationMin === '00' && durationHr !== 0
                  ? ''
                  : ` ${durationMin}m`}
              </IFText>
            </div>
            <div className={Styles.accordionDateContainer}>
              <IFText className={Styles.accordionDate}>
                {Moment(processedAt).format('MMM DD, yyy \xa0 HH:mm:ss')}
              </IFText>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={Styles.container}>
            <AccessTimeIcon className={Styles.accessTimeIcon} />
            <div className={Styles.expandedLeftText}>
              <div className={Styles.accordionDetails}>
                <div>
                  <IFText className={Styles.semiBold}>{'Start'}</IFText>
                  <IFText className={Styles.semiBold}> {'End'}</IFText>
                </div>
                <div>
                  <IFText className={Styles.accordionLeftText}>
                    {Moment(startedAt).format('MMM DD, yyy \xa0 HH:mm:ss')}
                  </IFText>
                  <IFText className={Styles.accordionLeftText}>
                    {Moment(endedAt).format('MMM DD, yyy \xa0 HH:mm:ss')}
                  </IFText>
                </div>
              </div>
            </div>

            <div className={Styles.rightDetailsContainer}>
              {user ? (
                <div className={Styles.userContainer}>
                  {startingChargingTokenType ===
                  InfinityEnums.ChargingTokenType.VIRTUAL ? (
                    <PhoneIphone sx={{ fontSize: 20, alignSelf: 'center' }} />
                  ) : (
                    <CreditCard sx={{ fontSize: 20, alignSelf: 'center' }} />
                  )}
                  <div style={{ alignSelf: 'center' }}>
                    <IFText
                      style={{ color: Colors.text }}
                      className={Styles.middle}
                    >
                      <StyledHyperLink
                        hoverColor={Colors.primary}
                        textColor={Colors.text}
                        target="_blank"
                        href={`/users/${user._id}`}
                      >
                        {user.name}
                      </StyledHyperLink>
                    </IFText>
                  </div>
                  <HtmlTooltip
                    title={
                      <div className={Styles.userInfo}>
                        <div className={Styles.infoLeft}>
                          <IFText
                            className={Styles.toolTipText}
                            style={{ color: Colors.black }}
                          >
                            {t('UserInfo.ID')}
                          </IFText>
                          <IFText
                            className={Styles.toolTipText}
                            style={{ color: Colors.black }}
                          >
                            {t('UserInfo.Name')}
                          </IFText>
                          {user.email ? (
                            <IFText
                              className={Styles.toolTipText}
                              style={{ color: Colors.black }}
                            >
                              {t('UserInfo.Email')}
                            </IFText>
                          ) : null}
                        </div>
                        <div className={Styles.infoRight}>
                          <div className={Styles.AppUserIdContainer}>
                            <IFText
                              className={Styles.toolTipText}
                              style={{ color: Colors.tooltipGreyBackground }}
                            >
                              {user.appUserId}
                            </IFText>

                            <div
                              className={Styles.AppUserIdCopyContainer}
                              onMouseEnter={() => setAppUserIdHover(true)}
                              onMouseLeave={() => setAppUserIdHover(false)}
                              onClick={(e) => e.stopPropagation()}
                            >
                              {appUserIdHover ? (
                                <div
                                  className={Styles.IconContainer}
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      user.appUserId,
                                    )
                                  }}
                                >
                                  <CheckIcon
                                    className={
                                      isAppUserIdCopied
                                        ? Styles.visible
                                        : Styles.hide
                                    }
                                    style={{
                                      color: Colors.primary,
                                    }}
                                  />
                                  <ContentCopyIcon
                                    onClick={animateUid}
                                    style={{ color: Colors.ClipboardCopy }}
                                    className={
                                      !isAppUserIdCopied
                                        ? Styles.visible
                                        : Styles.hide
                                    }
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <IFText
                            className={Styles.toolTipText}
                            style={{
                              color: Colors.tooltipGreyBackground,
                              marginTop: '8px',
                            }}
                          >
                            {user.name}
                          </IFText>
                          {user.email ? (
                            <IFText
                              className={Styles.toolTipText}
                              style={{ color: Colors.tooltipGreyBackground }}
                            >
                              {user.email}
                            </IFText>
                          ) : null}
                        </div>
                      </div>
                    }
                    placement="right-start"
                  >
                    <Info
                      style={{ color: Colors.tooltipGreyBackground }}
                      className={Styles.infoIcon}
                    ></Info>
                  </HtmlTooltip>
                </div>
              ) : (
                <div className={Styles.expandedRight}>
                  <CreditCardIcon />
                  <IFText className={Styles.accordionRightText}>
                    {`#${startingChargingToken}`}
                  </IFText>
                </div>
              )}
              <div className={Styles.expandedRight}>
                <IFText className={Styles.EGP}>{t('UserInfo.EGP')}</IFText>
                <IFText className={Styles.accordionRightText}>
                  {`${Number(amount).toFixed(2)}`}
                </IFText>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
ChargePointTransactionListItem.propTypes = {
  connectorUid: PropTypes.number.isRequired,
  connectorType: PropTypes.string,
  energyConsumption: PropTypes.number.isRequired,
  startedAt: PropTypes.string.isRequired,
  endedAt: PropTypes.string.isRequired,
  processedAt: PropTypes.string.isRequired,
  startingChargingToken: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, [
    'connectorUid',
    'connectorType',
    'energyConsumption',
    'startedAt',
    'endedAt',
    'processedAt',
    'startingChargingToken',
    'amount',
  ])
}
export default React.memo(ChargePointTransactionListItem, shouldSkipRender)
