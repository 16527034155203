import RequestState from 'Enums/RequestState'
import {
  moveStationsFilter,
  stationsMapFilter,
  stationsFilter,
} from 'Constants/Filters'

export const INITIAL_STATE = {
  stations: [],
  stationsCount: 0,
  moveStations: [],
  fetchMoveStationListRequestState: RequestState.UNINITIALIZED,
  moveStationsFilter: moveStationsFilter,
  selectedMoveIndex: 0,
  paginationOffsetMove: 0,
  fetchStationListRequestState: RequestState.UNINITIALIZED,
  fetchStationListCountRequestState: RequestState.UNINITIALIZED,
  updateStationRequestState: RequestState.UNINITIALIZED,
  addStationRequestState: RequestState.UNINITIALIZED,
  deleteStationRequestState: RequestState.UNINITIALIZED,
  deleteStationEmitterState: RequestState.UNINITIALIZED,
  selectedIndex: -1,
  stationDetailsSelectedIndex: 0,
  paginationOffset: 0,
  fetchStationCitiesRequestState: RequestState.UNINITIALIZED,
  addStationCityRequestState: RequestState.UNINITIALIZED,
  stationMapClusters: [],
  stationMapData: [],
  fetchStationMapClustersRequestState: RequestState.UNINITIALIZED,
  fetchStationHeatMapRequestState: RequestState.UNINITIALIZED,
  stationsMapFilter: stationsMapFilter,
  stationsFilter: stationsFilter,
  fetchStationMapClustersSearchRequestState: RequestState.UNINITIALIZED,
  stationMapClustersSearch: [],
  stationMapClustersSearchWord: '',
}
