import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import StationSelectors from 'Stores/Station/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import AccessGroupList from '../AccessGroupList/AccessGroupList'
import { calculateConnectorsHeight } from 'Utils/ConnectorFunctions'

const ChargePointContainer = forwardRef(
  (
    {
      chargePoint,
      chargePointAccessGroups,
      accessGroups,
      fetchChargePointAccessGroups,
      fetchAccessGroups,
      deleteChargePointAccessGroupsBulk,
      addChargePointAccessGroupsBulk,
      isEditing,
      fetchChargePointRequestState,
      fetchStationListRequestState,
      fetchChargePointListRequestState,
      fetchChargePointAccessGroupsRequestState,
      maxListHeight,
      chargePointUid,
    },
    ref,
  ) => {
    const accessGroupListRef = useRef()

    useEffect(() => {
      if (
        (chargePoint && !isEditing) ||
        (chargePoint && chargePoint.isActivated === false)
      ) {
        fetchChargePointAccessGroups(chargePoint._id)
        fetchAccessGroups()
      }
    }, [chargePointUid])

    const handleRefresh = () => {
      fetchChargePointAccessGroups(chargePoint._id)
      fetchAccessGroups()
    }

    const handleSubmit = (resetValues) => {
      accessGroupListRef.current.handleSubmit(resetValues)
    }

    const handleCancel = () => {
      accessGroupListRef.current.handleCancel()
    }

    const didChange = () => {
      return accessGroupListRef.current.didChange()
    }

    useImperativeHandle(ref, () => ({
      handleRefresh,
      handleSubmit,
      handleCancel,
      didChange,
    }))

    const isLoading = () => {
      return (
        fetchStationListRequestState === RequestState.LOADING ||
        fetchChargePointListRequestState === RequestState.LOADING ||
        fetchChargePointRequestState === RequestState.LOADING ||
        fetchChargePointAccessGroupsRequestState === RequestState.LOADING
      )
    }

    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: isEditing && !isLoading() ? 'unset' : 'hidden',
        }}
      >
        <AccessGroupList
          ref={accessGroupListRef}
          displayedList={chargePointAccessGroups}
          dropdownList={accessGroups}
          isEditing={isEditing}
          isLoading={isLoading()}
          maxListHeight={maxListHeight}
          scrollbarsHeight={
            isLoading()
              ? '68px'
              : calculateConnectorsHeight(chargePoint?.connectors)
          }
          onSubmit={(addAccessGroups, deleteAccessGroups) => {
            if (deleteAccessGroups.length !== 0) {
              deleteChargePointAccessGroupsBulk(
                chargePoint._id,
                deleteAccessGroups,
              )
            }
            if (addAccessGroups.length !== 0) {
              addChargePointAccessGroupsBulk(chargePoint._id, addAccessGroups)
            }
          }}
        />
      </div>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    fetchChargePointAccessGroups: (chargePointId) =>
      dispatch(ChargePointActions.fetchChargePointAccessGroups(chargePointId)),
    deleteChargePointAccessGroupsBulk: (chargePointId, accessGroups) =>
      dispatch(
        ChargePointActions.deleteChargePointAccessGroupsBulk(
          chargePointId,
          accessGroups,
        ),
      ),
    addChargePointAccessGroupsBulk: (chargePointId, accessGroups) =>
      dispatch(
        ChargePointActions.addChargePointAccessGroupsBulk(
          chargePointId,
          accessGroups,
        ),
      ),
    fetchAccessGroups: () =>
      dispatch(TenantUserActions.fetchAccessGroupsList()),
  }
}

const mapStateToProps = (state) => ({
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  chargePointAccessGroups:
    ChargePointSelectors.getChargePointAccessGroups(state),
  accessGroups: TenantUserSelectors.getAccessGroupsList(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointAccessGroupsRequestState:
    ChargePointSelectors.getFetchChargePointAccessGroupsRequestState(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
})

ChargePointContainer.propTypes = {
  chargePointAccessGroups: PropTypes.arrayOf(PropTypes.object),
  accessGroups: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  chargePoint: PropTypes.object,
  fetchChargePointAccessGroups: PropTypes.func,
  deleteChargePointAccessGroupsBulk: PropTypes.func,
  fetchAccessGroups: PropTypes.func,
  addChargePointAccessGroupsBulk: PropTypes.func,
  fetchChargePointRequestState: PropTypes.number,
  fetchStationListRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  fetchChargePointAccessGroupsRequestState: PropTypes.number,
  maxListHeight: PropTypes.number,
  chargePointUid: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ChargePointContainer)
