import Styles from './TenantUserPage.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { IFButton, IFText } from 'Components'
import { useRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TenantUserSelectors from 'Stores/TenantUser/Selectors'
import TenantUserActions from 'Stores/TenantUser/Actions'
import { connect } from 'react-redux'
import RequestState from 'Enums/RequestState'
import { TenantUserList } from 'Containers'
import InviteTenantUserModal from './InviteTenantUserModal'
import PropTypes from 'prop-types'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import LoadingBar from 'react-top-loading-bar'
import { useLocation } from 'react-router-dom'

const TenantUsersContainer = styled.div`
  background-color: ${Colors.white};
`

const TenantUserPage = ({
  fetchTenantUserList,
  fetchTenantUserListRequestState,
  fetchInviteTenantUserList,
  fetchInviteTenantUserListRequestState,
  tenantUser,
}) => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    fetchTenantUserList()
    fetchInviteTenantUserList()
  }, [])
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === '/tenantUsers') {
      fetchTenantUserList()
      fetchInviteTenantUserList()
    }
  }, [location])

  useEffect(() => {
    if (
      fetchTenantUserListRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (
      (fetchTenantUserListRequestState !== RequestState.LOADING &&
        fetchTenantUserListRequestState !== RequestState.UNINITIALIZED &&
        fetchTenantUserListRequestState !== RequestState.SUCCEEDED) ||
      fetchTenantUserListRequestState === RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchTenantUserListRequestState])

  const shouldShowLoadingState = () => {
    return (
      fetchTenantUserListRequestState === RequestState.LOADING ||
      fetchInviteTenantUserListRequestState === RequestState.LOADING
    )
  }

  const InviteTenantUserRef = useRef()
  const { t } = useTranslation()
  return (
    <div className={Styles.Wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <TenantUsersContainer className={Styles.TenantUsersContainer}>
        <div className={Styles.HeaderContainer}>
          <div
            className={Styles.TenantUserTextContainer}
            {...(!tenantUser.permissions.includes(
              InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING_TOKEN,
            )
              ? {
                  style: {
                    flexGrow: 1,
                  },
                }
              : {})}
          >
            <IFText className={Styles.HeaderTitles}>
              {t('TenantUserPageContainer.TenantUsers')}
            </IFText>
          </div>
          <div
            className={Styles.PermissionsTextContainer}
            {...(!tenantUser.permissions.includes(
              InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING_TOKEN,
            )
              ? {
                  style: {
                    textAlign: 'center',
                    marginLeft: '-4rem',
                  },
                }
              : {})}
          >
            <IFText className={Styles.HeaderTitles}>
              {t('TenantUserPageContainer.Permissions')}
            </IFText>
          </div>
          {tenantUser.permissions.includes(
            InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING_TOKEN,
          ) && (
            <div className={Styles.HeaderButtonContainer}>
              <IFButton
                color={Colors.primary}
                isFill={false}
                isDead={shouldShowLoadingState()}
                size="xsm"
                isLoading={false}
                text={`+ ${t('TenantUserPageContainer.Invite')}`}
                onClick={() => InviteTenantUserRef.current.handleOpen()}
                className={Styles.InviteButton}
                loadSkeleton={
                  fetchTenantUserListRequestState === RequestState.LOADING
                }
              />
            </div>
          )}
        </div>
        <TenantUserList
          editPermission={tenantUser.permissions.includes(
            InfinityEnums.TenantUserPermission.CAN_EDIT_TENANT_USER,
          )}
        />
      </TenantUsersContainer>
      <InviteTenantUserModal ref={InviteTenantUserRef} />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTenantUserList: () =>
      dispatch(TenantUserActions.fetchTenantUserList()),
    fetchInviteTenantUserList: () =>
      dispatch(TenantUserActions.fetchInviteTenantUserList()),
  }
}

const mapStateToProps = (state) => ({
  fetchTenantUserListRequestState:
    TenantUserSelectors.getFetchTenantUserListRequestState(state),
  fetchInviteTenantUserListRequestState:
    TenantUserSelectors.getFetchInviteTenantUserListRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
})

TenantUserPage.propTypes = {
  fetchTenantUserList: PropTypes.func,
  fetchTenantUserListRequestState: PropTypes.number,
  fetchInviteTenantUserList: PropTypes.func,
  fetchInviteTenantUserListRequestState: PropTypes.number,
  tenantUser: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantUserPage)
