import PropTypes from 'prop-types'
import React from 'react'
import Styles from './IFText.module.css'
import { isPropsMatch } from 'Utils/PropsMatch'

const IFText = React.forwardRef(
  ({ children, className, style, ...props }, ref) => {
    return (
      <p
        {...props}
        ref={ref}
        style={style}
        className={`${Styles.text} ${className}`}
      >
        {children}
      </p>
    )
  },
)

IFText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
}

function shouldSkipRender(prevProps, nextProps) {
  return isPropsMatch(prevProps, nextProps, ['className', 'style', 'children'])
}

export default React.memo(IFText, shouldSkipRender)
