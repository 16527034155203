import * as React from 'react'
import Styles from './ChargePointTransactionListItemSkeleton.module.css'
import IFSkeleton from 'Components/IFSkeleton/IFSkeleton'
import colors from 'Theme/Colors'
function ChargePointTransactionListItemSkeleton({ ...props }) {
  return (
    <div {...props}>
      <div
        className={Styles.wrapper}
        style={{
          background: colors.ChargePointLogTransactionItemBackground,
        }}
      >
        <div className={Styles.item}>
          <div className={Styles.iconText}>
            <IFSkeleton variant="circular" className={Styles.icon} />
            <IFSkeleton className={Styles.text} />
          </div>
        </div>
        <div className={Styles.item}>
          <IFSkeleton className={Styles.middle} />
        </div>
        <div className={Styles.item}>
          <IFSkeleton className={Styles.middle} />
        </div>

        <div className={Styles.item}>
          <IFSkeleton className={Styles.rightText} />
        </div>
      </div>
    </div>
  )
}

export default ChargePointTransactionListItemSkeleton
