import React, { useImperativeHandle, useEffect, useRef } from 'react'
import IFText from 'Components/IFText/IFText'
import Styles from './ChargingSchedulePeriodListItem.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { SelectPicker } from 'rsuite'
import IFTextInput from 'Components/IFTextInput/IFTextInput'
import { DoNotDisturbOn } from '@mui/icons-material'
import { Colors } from 'Theme'

const ChargingSchedulePeriodListItem = React.forwardRef(
  (
    {
      defaultValue,
      data,
      selectedValues,
      onClean,
      onSelect,
      readOnly,
      readOnlyValue,
      defaultMaxCurrentInput = 500,
      onChange,
    },
    ref,
  ) => {
    const [listData, setListData] = useState(data)
    const [maxCurrentInput, setMaxCurrentInput] = useState(
      defaultMaxCurrentInput,
    )
    const [selectedValue, setSelectedValue] = useState(defaultValue)
    const idRef = useRef()
    const divRef = useRef()

    useEffect(() => {
      idRef.current = Math.random()
    }, [])

    const getId = () => {
      return idRef.current
    }

    useImperativeHandle(ref, () => ({
      getId,
    }))

    const { t } = useTranslation()
    useEffect(() => {
      let newData = [...data]
      let dateItem = selectedValues.find((d) => d.value === selectedValue)
      if (dateItem) newData = [...newData, dateItem]
      setListData(newData)
    }, [data])

    return (
      <div ref={divRef} className={Styles.Wrapper}>
        <div className={Styles.Container}>
          <div className={Styles.FieldContainer}>
            <IFText className={Styles.AccordionLeftText}>
              {t('ChargingSchedulePeriod.From')}
            </IFText>
            {readOnly ? (
              <div className={Styles.ReadOnlyValueContainer}>
                <IFText>{readOnlyValue}</IFText>
              </div>
            ) : (
              <SelectPicker
                data={listData}
                onSelect={(value) => {
                  onSelect?.(value, selectedValue)
                  setSelectedValue(value)
                  onChange(value, maxCurrentInput, idRef.current)
                }}
                defaultValue={defaultValue}
                onClean={() => {
                  setSelectedValue()
                  onClean?.(selectedValue)
                }}
                searchable={false}
                size="sm"
                readOnly={readOnly}
              />
            )}
          </div>

          <div className={Styles.FieldContainer}>
            <IFText className={Styles.AccordionLeftText}>
              {t('ChargingSchedulePeriod.MaxCurrent')}
            </IFText>
            <IFTextInput
              isFixed={true}
              value={maxCurrentInput}
              className={Styles.IFTextInput}
              onChange={(e) => {
                setMaxCurrentInput(e.target.value)
                onChange(selectedValue, e.target.value, idRef.current)
              }}
            />
            <IFText className={Styles.Ampere}>
              {t('ChargingSchedulePeriod.A')}
            </IFText>
          </div>
          <div className={Styles.FieldContainer}>
            <IFText className={Styles.AccordionLeftText}>
              {t('ChargingSchedulePeriod.OnePhase')}
            </IFText>
            <IFText className={Styles.AccordionRightText}>
              {(Number((maxCurrentInput * 230) / 1000)
                ? Number((maxCurrentInput * 230) / 1000)?.toFixed(1)
                : '\u2014') + t('ConnectorListItem.kW')}
            </IFText>
          </div>
          <div className={Styles.FieldContainer}>
            <IFText className={Styles.AccordionLeftText}>
              {t('ChargingSchedulePeriod.ThreePhase')}
            </IFText>
            <IFText className={Styles.AccordionRightText}>
              {(Number((maxCurrentInput * 230) / 1000)
                ? Number((maxCurrentInput * 400 * 1.732) / 1000)?.toFixed(1)
                : '\u2014') + t('ConnectorListItem.kW')}
            </IFText>
          </div>
          {!readOnly ? (
            <DoNotDisturbOn
              className={Styles.Icon}
              style={{
                color: Colors.ChargePointSubscriptionListItem.RemoveIcon,
              }}
              onClick={() => {
                onClean?.(selectedValue)
                divRef?.current?.remove()
              }}
            />
          ) : (
            <div className={Styles.IconContainer} />
          )}
        </div>
      </div>
    )
  },
)

export default ChargingSchedulePeriodListItem
