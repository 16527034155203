import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Colors from 'Theme/Colors'
import React, { useState, useImperativeHandle } from 'react'
import ChargingProfileTabsSkeleton from './ChargingProfileTabsSkeleton'
import Styles from './ChargingProfileTabs.module.css'
import { IFText } from 'Components'

const ChargingProfileTabs = React.forwardRef(
  ({ onValueChange, isLoading, initialIndex, connectors }, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)
    const handleChange = (event, newValue) => {
      setSelectedIndex(newValue)
      onValueChange(newValue)
    }

    const resetIndex = () => {
      setSelectedIndex(0)
    }

    useImperativeHandle(ref, () => ({
      resetIndex,
    }))

    return (
      <div>
        {isLoading ? (
          <ChargingProfileTabsSkeleton tabsCount={connectors.length} />
        ) : (
          <Tabs
            value={selectedIndex}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: Colors.primary,
              },
            }}
          >
            {connectors.map((conn, index) => {
              return (
                <Tab
                  sx={{ padding: '0px' }}
                  key={index}
                  label={
                    <div className={Styles.Container}>
                      <IFText
                        style={{
                          color:
                            index === selectedIndex
                              ? Colors.text
                              : Colors.NotSelectedText,
                        }}
                        className={Styles.ChargePointTitle}
                      >
                        {`Conn ${conn.uid}`}
                      </IFText>
                    </div>
                  }
                />
              )
            })}
          </Tabs>
        )}
      </div>
    )
  },
)

ChargingProfileTabs.propTypes = {
  onValueChange: PropTypes.func,
  isLoading: PropTypes.bool,
  initialIndex: PropTypes.number,
  connectors: PropTypes.arrayOf(PropTypes.number),
}

export default ChargingProfileTabs
