import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { UserListItem, UserListItemSkeleton, CustomScrollbar } from 'Components'
import PropTypes from 'prop-types'
import RequestState from 'Enums/RequestState'
import en from 'javascript-time-ago/locale/en'
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago'
import { Divider } from '@material-ui/core'
import { Waypoint } from 'react-waypoint'
import UserActions from 'Stores/User/Actions'
import UserSelectors from 'Stores/User/Selectors'
import history from 'history/browser'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import Styles from './UserList.module.css'

TimeAgo.addDefaultLocale(en)

const UserList = ({
  usersList,
  fetchUserListRequestState,
  fetchUserList,
  usersFilter,
  selectedIndex,
  setSelectedUserIndex,
  paginationOffset,
}) => {
  const navigate = useNavigate()

  const Footer = () => {
    return paginationOffset && usersList && usersList.length !== 0 ? (
      <div>
        <Waypoint onEnter={loadMoreData} />
        <UserListItemSkeleton />
        <Divider />
        <UserListItemSkeleton />
      </div>
    ) : null
  }

  const handleUserItemClick = (index) => {
    setSelectedUserIndex(index)
  }

  useEffect(() => {
    if (selectedIndex > -1) {
      history.push({ pathname: `/users/${usersList[selectedIndex]._id}` })
    }
  }, [selectedIndex])

  const loadMoreData = () => {
    if (fetchUserListRequestState === RequestState.LOADING_MORE) return
    fetchUserList(usersFilter, paginationOffset, () => navigate('/NotFound'))
  }

  const usersContainerRef = useRef()
  const getStationsSkeletonsCount = () => {
    if (usersContainerRef.current) {
      let containerHeight =
        usersContainerRef.current.parentElement.clientHeight / 16
      return Math.floor(containerHeight / 5)
    }
    return 12
  }

  return (
    <div className={Styles.Container} ref={usersContainerRef}>
      {fetchUserListRequestState === RequestState.LOADING &&
      paginationOffset === 0 ? (
        [...Array(getStationsSkeletonsCount())].map((e, index) => (
          <div key={`UserListItemSkeleton ${index}`}>
            <UserListItemSkeleton key={index} />
            <Divider />
          </div>
        ))
      ) : (
        <Virtuoso
          data={usersList}
          endReached={
            paginationOffset && usersList && usersList.length !== 0
              ? loadMoreData
              : () => {}
          }
          increaseViewportBy={480}
          itemContent={(index, user) => {
            return (
              <div key={`UserListItem ${index}`}>
                <UserListItem
                  name={user.name}
                  email={
                    user.email
                      ? user.email
                      : user.appUserId
                      ? user.appUserId
                      : ''
                  }
                  lastUpdated={
                    user ? (
                      <ReactTimeAgo
                        date={new Date(user.updatedAt)}
                        locale="en-US"
                        timeStyle="mini"
                      />
                    ) : (
                      ''
                    )
                  }
                  isSelected={selectedIndex === index}
                  onClick={() => handleUserItemClick(index)}
                  isNew={false}
                  isActive={user.isActive}
                  isInvited={user.isInvitedUser}
                  userExists={user.appUserId}
                />
                <Divider />
              </div>
            )
          }}
          components={{ Footer: Footer, Scroller: CustomScrollbar }}
        />
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserList: (filter, offset, onNotFound) =>
      dispatch(UserActions.fetchUserList(filter, offset, onNotFound)),
    setSelectedUserIndex: (index) =>
      dispatch(UserActions.setSelectedUserIndex(index)),
  }
}

const mapStateToProps = (state) => ({
  usersList: UserSelectors.getUserList(state),
  fetchUserListRequestState: UserSelectors.getFetchUserListRequestState(state),
  paginationOffset: UserSelectors.getUserPaginationOffset(state),
  usersFilter: UserSelectors.getUsersFilter(state),
  selectedIndex: UserSelectors.getSelectedUserIndex(state),
})

UserList.propTypes = {
  usersList: PropTypes.arrayOf(PropTypes.object),
  fetchUserListRequestState: PropTypes.number,
  fetchUserList: PropTypes.func,
  usersFilter: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  setSelectedUserIndex: PropTypes.func,
  paginationOffset: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
