import React, { useState } from 'react'
import { connect } from 'react-redux'
import Styles from './RequestDiagnosticsModal.module.css'
import { IFButton, IFText, IFModal } from 'Components'
import { Colors } from 'Theme'
import { useTranslation } from 'react-i18next'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import RequestState from 'Enums/RequestState'
import { DateRangePicker } from 'rsuite'

const RequestDiagnosticsModal = React.forwardRef(
  (
    {
      requestChargePointUploadDiagnosticsRequestState,
      chargePoint,
      requestChargePointUploadDiagnostics,
    },
    ref,
  ) => {
    const [value, setValue] = useState([])
    const { t } = useTranslation()

    const handleCloseIcon = () => {
      setValue([])
    }

    return (
      <IFModal
        onClose={handleCloseIcon}
        width={'30rem'}
        title={t('RequestDiagnosticsModal.RequestDiagnosticsTitle')}
        ref={ref}
      >
        <div className={Styles.Container}>
          <div>
            <IFText className={Styles.DateText} style={{ color: Colors.text }}>
              {t('RequestDiagnosticsModal.Date')}
            </IFText>
            <DateRangePicker
              menuStyle={{ zIndex: 10 }}
              format="yyyy-MM-dd HH:mm:ss"
              value={value}
              onOk={(value) => {
                setValue(value)
              }}
              size="lg"
              appearance="default"
              editable={false}
              onClean={() => {
                setValue([])
              }}
            />
          </div>

          <div className={Styles.Button}>
            <IFButton
              text={t('RequestDiagnosticsModal.SendRequest')}
              color={Colors.primary}
              isFill={true}
              size={'sm'}
              isLoading={
                requestChargePointUploadDiagnosticsRequestState ===
                RequestState.LOADING
              }
              onClick={() => {
                requestChargePointUploadDiagnostics(chargePoint._id, value)
              }}
            />
          </div>
        </div>
      </IFModal>
    )
  },
)

function mapDispatchToProps(dispatch) {
  return {
    requestChargePointUploadDiagnostics: (chargePointId, dateTime) =>
      dispatch(
        ChargePointActions.requestChargePointUploadDiagnostics(
          chargePointId,
          dateTime,
        ),
      ),
  }
}

const mapStateToProps = (state) => ({
  requestChargePointUploadDiagnosticsRequestState:
    ChargePointSelectors.getRequestChargePointUploadDiagnosticsRequestState(
      state,
    ),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(RequestDiagnosticsModal)
