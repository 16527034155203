import PropTypes from 'prop-types'
import Styles from './ChargePointDetailsTab.module.css'
import { IFText } from 'Components'
import { Colors } from 'Theme'

const ChargePointDetailsTab = ({ title, isSelected }) => {
  return (
    <div className={Styles.Container}>
      <IFText
        style={{ color: isSelected ? Colors.text : Colors.NotSelectedText }}
        className={Styles.ChargePointTitle}
      >
        {title}
      </IFText>
    </div>
  )
}

ChargePointDetailsTab.propTypes = {
  title: PropTypes.string,
  isSelected: PropTypes.bool,
}

export default ChargePointDetailsTab
