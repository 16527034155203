import styled from 'styled-components'
import Colors from 'Theme/Colors'
import Styles from './CardListItem.module.css'
import { IFText, IFButton, IFTextInput } from 'Components'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState, useImperativeHandle } from 'react'
import { Accordion, AccordionDetails } from '@material-ui/core'
import PropTypes from 'prop-types'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Icon from '@material-ui/core/Icon'
import InfinityEnums from 'Enums/InfinityEnums'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectPicker } from 'rsuite'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const PenIcon = styled.div`
 color: ${Colors.TenantUserListPenIcon};
   }
 `

const DeleteIcon = styled.div`
 color: ${Colors.TenantUserListDeleteIcon};
   }
 `
const StatusContainer = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
`

const AccordionSummary = withStyles({
  root: {
    '&$expanded': {
      minHeight: 56,
    },
    borderRadius: '5px',
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.white,
    boxShadow: '0px 0px 0px 0px',
    borderRadius: '5px !important',
    '&:hover': {
      backgroundColor: Colors.ChargePointLogListItemHover,
    },
  },
  AccordionSummary: {
    padding: '0px',
  },

  content: {
    margin: 0,
    height: '3.5rem',
  },
}))

const handleStatus = (status) => {
  switch (status) {
    case InfinityEnums.ChargingTokenStatus.ACTIVE:
      return Colors.ActiveCard
    case InfinityEnums.ChargingTokenStatus.BLOCKED:
      return Colors.BlockedCard
    case InfinityEnums.ChargingTokenStatus.DEACTIVATED:
      return Colors.DeActivatedCard
    default:
      return Colors.black
  }
}

const CardListItem = React.forwardRef(
  (
    {
      visualNumber,
      reference,
      uid,
      type,
      status,
      onDeleteClick,
      isUpdateLoading,
      onUpdateClick,
      editPermission,
    },
    ref,
  ) => {
    const [accordExpanded, setAccordExpanded] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [visualNumberEdit, setVisualNumberEdit] = useState(visualNumber)
    const [referenceEdit, setReferenceEdit] = useState(reference)
    const [uidEdit, setUidEdit] = useState(uid)
    const [typeEdit, setTypeEdit] = useState(type)
    const [statusEdit, setStatusEdit] = useState(status)
    const [contractIdHover, setContractIdHover] = useState(false)
    const [RFIDHover, setRFIDHover] = useState(false)
    const [isContractIDCopied, setIsContractIDCopied] = useState(false)
    const [isRFIDCopied, setIsRFIDCopied] = useState(false)

    const animateContractID = () => {
      setIsContractIDCopied(!isContractIDCopied)
      setTimeout(() => setIsContractIDCopied(false), 2000)
    }

    const animateRFID = () => {
      setIsRFIDCopied(!isRFIDCopied)
      setTimeout(() => setIsRFIDCopied(false), 2000)
    }

    const OpenAccordion = () => {
      setIsEdit(true)
      setAccordExpanded(true)
    }

    const closeAccordion = () => {
      setAccordExpanded(false)
      setIsEdit(false)
    }

    const handleCancel = () => {
      setIsEdit(false)
      setAccordExpanded(false)
      setVisualNumberEdit(visualNumber)
      setReferenceEdit(reference)
      setUidEdit(uid)
      setTypeEdit(type)
      setStatusEdit(status)
    }

    const handleDone = () => {
      let newCard = {}
      if (visualNumberEdit !== visualNumber)
        newCard.visualNumber = visualNumberEdit
      if (referenceEdit !== reference) newCard.reference = referenceEdit
      if (uidEdit !== uid) newCard.uid = uidEdit
      if (typeEdit !== type) newCard.type = typeEdit
      if (statusEdit !== status) newCard.status = statusEdit

      if (Object.keys(newCard).length !== 0) onUpdateClick(newCard)
    }

    useImperativeHandle(ref, () => ({
      closeAccordion,
    }))

    let typeArray = []
    let statusArray = []
    typeArray = Object.values(InfinityEnums.ChargingTokenType).map((type) => {
      return {
        label: (
          <IFText
            key={Math.floor(Math.random() * 100)}
            style={{ color: Colors.text }}
          >
            {type}
          </IFText>
        ),
        value: type,
      }
    })
    statusArray = Object.values(InfinityEnums.ChargingTokenStatus).map(
      (status) => {
        return {
          label: (
            <IFText
              key={Math.floor(Math.random() * 100)}
              style={{ color: Colors.text }}
            >
              {status}
            </IFText>
          ),
          value: status,
        }
      },
    )

    const { t } = useTranslation()
    const classes = useStyles()

    return (
      <Accordion expanded={accordExpanded} className={classes.root}>
        <AccordionSummary
          classes={{ content: classes.content }}
          className={classes.AccordionSummary}
        >
          <div className={Styles.CardWrapper}>
            <div
              onMouseEnter={() => setContractIdHover(true)}
              onMouseLeave={() => setContractIdHover(false)}
              className={Styles.VisualNumberContainer}
            >
              {isEdit ? (
                <div className={Styles.IFTextContainer}>
                  <IFTextInput
                    className={Styles.IFText}
                    value={visualNumberEdit}
                    onChange={(e) => setVisualNumberEdit(e.target.value)}
                  />
                </div>
              ) : (
                <div className={Styles.VisualNumberCopyContainer}>
                  <IFText className={Styles.wrapText}>{visualNumber}</IFText>
                  {contractIdHover ? (
                    <div
                      className={Styles.iconContainer}
                      style={{
                        backgroundColor: Colors.red,
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(visualNumber)
                      }}
                    >
                      <CheckIcon
                        className={
                          isContractIDCopied ? Styles.visible : Styles.hide
                        }
                        style={{
                          color: Colors.primary,
                        }}
                      />
                      <ContentCopyIcon
                        onClick={animateContractID}
                        style={{ color: Colors.ClipboardCopy }}
                        className={
                          !isContractIDCopied ? Styles.visible : Styles.hide
                        }
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className={Styles.ReferenceContainer}>
              {isEdit ? (
                <div className={Styles.IFTextContainer}>
                  <IFTextInput
                    className={Styles.IFText}
                    value={referenceEdit}
                    onChange={(e) => setReferenceEdit(e.target.value)}
                  />
                </div>
              ) : (
                <IFText>{reference}</IFText>
              )}
            </div>
            <div
              onMouseEnter={() => setRFIDHover(true)}
              onMouseLeave={() => setRFIDHover(false)}
              className={Styles.UIDContainer}
            >
              {isEdit ? (
                <div className={Styles.IFTextContainer}>
                  <IFTextInput
                    className={Styles.IFText}
                    value={uidEdit}
                    onChange={(e) => setUidEdit(e.target.value)}
                  />
                </div>
              ) : (
                <div className={Styles.VisualNumberCopyContainer}>
                  <IFText className={Styles.wrapText}>{uid}</IFText>
                  <div
                    className={Styles.iconContainer}
                    style={{
                      backgroundColor: Colors.white,
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(uid)
                    }}
                  >
                    {RFIDHover ? (
                      <div>
                        <CheckIcon
                          className={
                            isRFIDCopied ? Styles.visible : Styles.hide
                          }
                          style={{
                            color: Colors.primary,
                          }}
                        />
                        <ContentCopyIcon
                          onClick={animateRFID}
                          style={{ color: Colors.ClipboardCopy }}
                          className={
                            !isRFIDCopied ? Styles.visible : Styles.hide
                          }
                        />{' '}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className={Styles.TypeContainer}>
              {isEdit ? (
                <SelectPicker
                  className={Styles.SelectPicker}
                  appearance="default"
                  searchable={false}
                  value={typeEdit}
                  onSelect={(value) => {
                    setTypeEdit(value)
                  }}
                  data={typeArray}
                  cleanable={false}
                  size="md"
                />
              ) : (
                <IFText>{type}</IFText>
              )}
            </div>
            <div className={Styles.StatusWrapper}>
              {isEdit ? (
                <SelectPicker
                  className={Styles.SelectPicker}
                  appearance="default"
                  searchable={false}
                  value={statusEdit}
                  onSelect={(value) => {
                    setStatusEdit(value)
                  }}
                  data={statusArray}
                  cleanable={false}
                  size="md"
                />
              ) : (
                <StatusContainer
                  backgroundColor={
                    isEdit ? Colors.transparent : Colors.GreyBackground
                  }
                  color={handleStatus(status)}
                  className={Styles.StatusContainer}
                >
                  <IFText className={Styles.StatusText}>{status}</IFText>
                </StatusContainer>
              )}
            </div>
            {!accordExpanded ? (
              editPermission ? (
                <PenIcon className={Styles.PenIconContainer}>
                  <FontAwesomeIcon
                    onClick={OpenAccordion}
                    className={Styles.PenIcon}
                    icon={faPen}
                  />
                </PenIcon>
              ) : null
            ) : (
              <DeleteIcon className={Styles.DeleteIconContainer}>
                <FontAwesomeIcon
                  className={Styles.DeleteIcon}
                  icon={faTrash}
                  onClick={(e) => {
                    e.stopPropagation()
                    onDeleteClick()
                  }}
                />
              </DeleteIcon>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={Styles.ButtonContainer}>
          <IFButton
            color={Colors.primary}
            isFill={true}
            text={t('CardListItem.Done')}
            onClick={handleDone}
            size="xsm"
            icon={
              <Icon
                sx={{ width: '20px', height: '20px' }}
                component={CheckIcon}
              />
            }
            isLoading={isUpdateLoading}
          />
          <IFButton
            color={Colors.primary}
            isFill={false}
            text={t('CardListItem.Cancel')}
            onClick={handleCancel}
            size="xsm"
            className={Styles.Button}
            icon={<CloseIcon className={Styles.ButtonIcon} />}
          />
        </AccordionDetails>
      </Accordion>
    )
  },
)
CardListItem.propTypes = {
  visualNumber: PropTypes.string,
  reference: PropTypes.string,
  uid: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
  onDeleteClick: PropTypes.func,
  isUpdateLoading: PropTypes.bool,
  onUpdateClick: PropTypes.func,
  editPermission: PropTypes.bool,
}

export default CardListItem
