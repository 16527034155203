import PropTypes from 'prop-types'
import Styles from './IFToastMessage.module.css'
import { Notification } from 'rsuite'
import { Cancel } from '@mui/icons-material'
import { IFText } from 'Components'
import { Colors } from 'Theme'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'

const formatText = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const IFToastMessage = ({ duration = 4000, type = 'info', text, ...props }) => {
  return (
    <Notification
      {...props}
      closable
      style={{
        backgroundColor:
          type === 'info'
            ? Colors.ToastInfo
            : type === 'error'
            ? Colors.ToastError
            : type === 'success'
            ? Colors.ToastSuccess
            : type === 'warning'
            ? Colors.ToastWarning
            : Colors.ToastInfo,
      }}
      duration={duration}
    >
      <div className={Styles.container}>
        <div className={Styles.header}>
          {type === 'error' && (
            <Cancel className={Styles.icon} style={{ color: Colors.white }} />
          )}
          {type === 'success' && (
            <CheckCircleIcon
              className={Styles.icon}
              style={{ color: Colors.white }}
            />
          )}
          {type === 'info' && (
            <InfoIcon className={Styles.icon} style={{ color: Colors.white }} />
          )}
          {type === 'warning' && (
            <WarningIcon
              className={Styles.icon}
              style={{ color: Colors.white }}
            />
          )}
          <IFText className={Styles.titleText} style={{ color: Colors.white }}>
            {formatText(type)}
          </IFText>
        </div>
        <IFText className={Styles.text} style={{ color: Colors.white }}>
          {text}
        </IFText>
      </div>
    </Notification>
  )
}

IFToastMessage.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default IFToastMessage
