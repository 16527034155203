import InfinityEnums from 'Enums/InfinityEnums'
import { Colors } from 'Theme'

export const calculateConnectorsHeight = (chargePointConnectors) => {
  // 48 connector height
  // 8 connector padding
  // 12 list padding
  // 23 List header height
  if (chargePointConnectors) {
    const connectors = chargePointConnectors.filter((conn) => conn.uid !== 0)
    if (connectors.length > 1)
      return `${
        48 * connectors.length + 8 * (connectors.length - 1) - (8 + 20)
      }px`
    else return '68px'
  }
}

export const calculateOuterContainerHeight = (chargePointConnectors) => {
  // 48 connector height
  // 8 connector padding
  // 12 list padding
  // 23 List header height
  if (chargePointConnectors) {
    const connectors = chargePointConnectors.filter((conn) => conn.uid !== 0)
    if (connectors.length > 1)
      return `${48 * connectors.length + 8 * (connectors.length - 1)}px`
    else return '104px'
  }
}

export const mapStatus = (status) => {
  switch (status) {
    case InfinityEnums.ConnectorStatus.AVAILABLE:
    case InfinityEnums.ConnectorStatus.PREPARING:
    case InfinityEnums.ConnectorStatus.CHARGING:
    case InfinityEnums.ConnectorStatus.SUSPENDEDEV:
    case InfinityEnums.ConnectorStatus.SUSPENDEDEVSE:
    case InfinityEnums.ConnectorStatus.FINISHING:
    case InfinityEnums.ConnectorStatus.RESERVED:
      return { color: Colors.AvailableConnector, text: 'Available' }
    case InfinityEnums.ConnectorStatus.UNAVAILABLE:
    case InfinityEnums.ConnectorStatus.FAULTED:
      return { color: Colors.UnAvailableConnector, text: 'Unavailable' }
    default:
      return Colors.black
  }
}
export const handleMaxPower = (maxPower) => {
  if (maxPower <= InfinityEnums.ConnectorPowerLevel.LEVEL1)
    return InfinityEnums.ChargingSpeedLevels.LEVEL1
  else if (maxPower <= InfinityEnums.ConnectorPowerLevel.LEVEL2)
    return InfinityEnums.ChargingSpeedLevels.LEVEL2
  else return InfinityEnums.ChargingSpeedLevels.LEVEL3
}
