import { IFText, IFButton, IFLoadingIndicator } from 'Components'
import PropTypes from 'prop-types'
import Styles from './TenantUserListItem.module.css'
import styled from 'styled-components'
import Colors from 'Theme/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import React, { useState, useImperativeHandle } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { CheckPicker } from 'rsuite'

const PenIcon = styled.div`
color: ${Colors.TenantUserListPenIcon};
  }
`

const DeleteIcon = styled.div`
color: ${Colors.TenantUserListDeleteIcon};
  }
`

const TenantUserListItem = React.forwardRef(
  (
    {
      tenantUser,
      avatar,
      permissions,
      pickedPermissions,
      isDeleteLoading,
      isUpdateLoading,
      onUpdateClick,
      onDeleteClick,
      editPermission,
    },
    ref,
  ) => {
    const { t } = useTranslation()

    const options = []
    for (const index in permissions) {
      options.push({
        label: permissions[index],
        value: permissions[index],
      })
    }

    const [accordExpanded, setAccordExpanded] = useState(false)
    const [selectedPermissions, setSelectedPermissions] = useState(
      pickedPermissions,
    )
    const collapse = () => {
      setAccordExpanded(false)
    }

    useImperativeHandle(ref, () => ({
      collapse,
    }))

    const getPermissionPlaceholder = () => {
      let result = ''
      if (pickedPermissions[0] && pickedPermissions[1])
        result = `${pickedPermissions[0]} + ${pickedPermissions.length - 1}`
      else if (pickedPermissions[0]) result = pickedPermissions[0]
      else result = t('TenantUserListItem.NoPermissionsSelected')
      return result
    }

    const onClickAccordionHandler = () => {
      setAccordExpanded((expanded) => {
        return !expanded
      })
    }

    const useStyles = makeStyles({
      root: {
        height: '5rem',
        width: '12rem',
      },
      accordion: {
        boxShadow: '0px 0px 0px 0px' + Colors.ChargingTransactionListItemShadow,
        paddingRight: '1rem',
        paddingLeft: '1rem',
        '&:hover': {
          backgroundColor: Colors.ChargePointLogListItemHover,
        },
      },
    })

    const Classes = useStyles()

    return (
      <div className={Styles.Container}>
        <Accordion
          onClick={onClickAccordionHandler}
          expanded={accordExpanded && editPermission}
          className={Classes.accordion}
        >
          <AccordionSummary>
            <div className={Styles.ListItemSummary}>
              <div
                className={Styles.AvatarNameEmail}
                {...(!editPermission
                  ? {
                      style: {
                        flexGrow: 1,
                      },
                    }
                  : {})}
              >
                <div className={Styles.AvatarIcon}>{avatar}</div>
                <div className={Styles.NameEmail}>
                  <IFText className={Styles.Name}>{tenantUser.name}</IFText>
                  <IFText
                    className={Styles.Email}
                    style={{ color: Colors.TenantUserListItemEmail }}
                  >
                    {tenantUser.email}
                  </IFText>
                </div>
              </div>

              {!accordExpanded || !editPermission ? (
                <div
                  className={Styles.PermissionsContainer}
                  {...(!editPermission
                    ? {
                        style: {
                          justifyContent: 'center',
                          flexGrow: 1,
                        },
                      }
                    : {})}
                >
                  <IFText
                    style={{ backgroundColor: Colors.GreyBackground }}
                    className={Styles.Role}
                  >
                    {getPermissionPlaceholder()}
                  </IFText>
                </div>
              ) : (
                <div className={Styles.CheckPicker}>
                  <FormControl
                    className={Classes.root}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <CheckPicker
                      searchable={false}
                      preventOverflow={true}
                      data={options}
                      defaultValue={pickedPermissions}
                      menuStyle={{ color: Colors.text }}
                      onChange={(e) => setSelectedPermissions(e)}
                    />
                  </FormControl>
                </div>
              )}

              {!accordExpanded ? (
                editPermission ? (
                  <PenIcon className={Styles.PenIcon}>
                    <FontAwesomeIcon icon={faPen} />
                  </PenIcon>
                ) : null
              ) : !editPermission ? null : !isDeleteLoading ? (
                <DeleteIcon className={Styles.DeleteIcon}>
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteClick()
                    }}
                  />
                </DeleteIcon>
              ) : (
                <div className={Styles.LoadingIndicator}>
                  <IFLoadingIndicator size={'50%'} />
                </div>
              )}
            </div>
          </AccordionSummary>

          <AccordionDetails
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <div className={Styles.ListItemDetails}>
              <IFButton
                color={Colors.primary}
                text={t('TenantUserListItem.cancelButton')}
                isLoading={false}
                size="xsm"
                isFill={false}
                className={Styles.CancelButton}
                onClick={onClickAccordionHandler}
              />

              <IFButton
                color={Colors.primary}
                text={t('TenantUserListItem.updateButton')}
                isLoading={isUpdateLoading}
                size="xsm"
                isFill={true}
                className={Styles.UpdateButton}
                onClick={() => onUpdateClick(selectedPermissions)}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  },
)

TenantUserListItem.propTypes = {
  tenantUser: PropTypes.object,
  avatar: PropTypes.object,
  permissions: PropTypes.arrayOf(PropTypes.string),
  pickedPermissions: PropTypes.arrayOf(PropTypes.string),
  onUpdateClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  isDeleteLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  editPermission: PropTypes.bool,
}

export default TenantUserListItem
