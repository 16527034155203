import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import PropTypes from 'prop-types'
import Styles from './ScheduleList.module.css'
import {
  ChargePointScheduleListItem,
  ChargePointScheduleListItemSkeleton,
  ChargingSpecsAdd,
  CustomScrollbar,
} from 'Components'
import { useTranslation } from 'react-i18next'
import { EmptySpecs } from 'Components/IFEmptyStates/IFEmptyStates'
import { Virtuoso } from 'react-virtuoso'

const ScheduleList = forwardRef(
  (
    {
      displayedList = [],
      dropdownList = [],
      isEditing,
      isLoading,
      maxListHeight,
      scrollbarsHeight,
      onSubmit = () => {},
      isEmpty,
    },
    ref,
  ) => {
    const [deletePrivacyRules, setDeletePrivacyRules] = useState([])
    const [addPrivacyRules, setAddPrivacyRules] = useState([])
    const [filteredPrivacyRules, setFilteredPrivacyRules] = useState([])
    const [newPrivacyRules, setNewPrivacyRules] = useState([])
    const [dynamicHeight, setDynamicHeight] = useState(0)
    const [combinedPrivacyRules, setCombinedPrivacyRules] =
      useState(displayedList)
    const { t } = useTranslation()

    // Work around for displayedList firing useEffect without it's value changing
    const [newDisplayedList, setNewDisplayedList] = useState(displayedList)
    useEffect(() => {
      if (JSON.stringify(displayedList) !== JSON.stringify(newDisplayedList)) {
        setNewDisplayedList(displayedList)
      }
    }, [displayedList])

    useEffect(() => {
      const formattedPrivacyRules = displayedList.map((rule) => {
        return {
          ...rule,
          origin: 'original',
        }
      })
      setCombinedPrivacyRules(formattedPrivacyRules)
    }, [displayedList])

    useEffect(() => {
      const difference = dropdownList.filter((rule) => {
        const found = newDisplayedList.filter((ruleObject) => {
          return ruleObject.rule._id === rule._id
        })
        return found.length === 0
      })
      setFilteredPrivacyRules(difference)
    }, [dropdownList, newDisplayedList, isEditing])

    const handleRemove = (_id, toBeRemoved) => {
      if (toBeRemoved) {
        const index = deletePrivacyRules.indexOf(_id)
        if (index === -1) {
          setDeletePrivacyRules([...deletePrivacyRules, _id])
        }
      } else {
        let index = deletePrivacyRules.indexOf(_id)
        if (index > -1) {
          setDeletePrivacyRules(deletePrivacyRules.filter((id) => id !== _id))
        }
      }
      const index = addPrivacyRules.indexOf(_id)
      if (index > -1) {
        setAddPrivacyRules(
          addPrivacyRules.filter((id) => {
            return id !== _id
          }),
        )
      }
    }

    const handleAdd = (_id) => {
      let index = addPrivacyRules.indexOf(_id)
      if (index === -1) {
        setAddPrivacyRules([...addPrivacyRules, _id])
      }
      index = dropdownList.findIndex((rule) => rule._id === _id)
      if (index > -1) {
        const alreadyExist = newPrivacyRules.filter((sub) => {
          return sub._id === _id
        })
        if (alreadyExist.length !== 1) {
          const formattedNewPrivacyRule = {
            ...dropdownList[index],
            origin: 'new',
          }
          setCombinedPrivacyRules((prevState) => {
            return [...prevState, formattedNewPrivacyRule]
          })
          setNewPrivacyRules([...newPrivacyRules, dropdownList[index]])
        }
      }

      let newFilteredRules = filteredPrivacyRules.filter((rule) => {
        return rule._id !== _id
      })
      setFilteredPrivacyRules(newFilteredRules)
    }

    const handleSubmit = (resetValues) => {
      onSubmit(addPrivacyRules, deletePrivacyRules)
      if (resetValues) {
        setAddPrivacyRules([])
        setDeletePrivacyRules([])
        setNewPrivacyRules([])
      }
    }
    const handleCancel = () => {
      setDeletePrivacyRules([])
      setAddPrivacyRules([])
      setNewPrivacyRules([])
      setCombinedPrivacyRules((prevState) => {
        return prevState.filter((rule) => rule.origin === 'original')
      })
    }
    const didChange = () => {
      return deletePrivacyRules.length > 0 || addPrivacyRules.length > 0
    }
    useImperativeHandle(ref, () => ({
      handleSubmit,
      handleCancel,
      didChange,
    }))

    return (
      <div
        className={
          isEditing
            ? Styles.PrivacyRulesListWrapperEditing
            : Styles.PrivacyRulesListWrapper
        }
        style={
          isEditing
            ? isLoading
              ? {}
              : {
                  height: combinedPrivacyRules.length > 0 ? dynamicHeight : '',
                  maxHeight: maxListHeight,
                }
            : {}
        }
      >
        {displayedList?.length === 0 &&
        newPrivacyRules.length === 0 &&
        !isLoading ? (
          <div
            className={Styles.EmptyContainer}
            style={isEditing ? { height: '4.25rem', marginTop: '0.5rem' } : {}}
          >
            <EmptySpecs title={t('ChargepointSpecs.NoPrivacyRules')} />
          </div>
        ) : isLoading ? (
          <div style={{ height: scrollbarsHeight, paddingRight: '1rem' }}>
            {[...Array(6)].map(() => {
              return <ChargePointScheduleListItemSkeleton />
            })}
          </div>
        ) : (
          <div
            className={
              isEditing ? Styles.ListWrapperEditing : Styles.ListWrapper
            }
          >
            <Virtuoso
              data={combinedPrivacyRules}
              increaseViewportBy={480}
              totalListHeightChanged={(height) => {
                setDynamicHeight(height + combinedPrivacyRules.length * 8 + 40)
              }}
              itemContent={(index, ruleObject) => {
                if (ruleObject.origin === 'original') {
                  return (
                    <div className={Styles.ListItem}>
                      <ChargePointScheduleListItem
                        _id={ruleObject.rule._id}
                        name={ruleObject.rule.name}
                        day={ruleObject.rule.day}
                        start={ruleObject.rule.start}
                        end={ruleObject.rule.end}
                        repeat={ruleObject.rule.repeat}
                        isEditing={isEditing}
                        onClick={(_id, toBeRemoved) => {
                          handleRemove(_id, toBeRemoved)
                        }}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div className={Styles.ListItem}>
                      <ChargePointScheduleListItem
                        _id={ruleObject._id}
                        name={ruleObject.name}
                        day={ruleObject.day}
                        start={ruleObject.start}
                        end={ruleObject.end}
                        repeat={ruleObject.repeat}
                        isEditing={isEditing}
                        onClick={(_id, toBeRemoved) => {
                          handleRemove(_id, toBeRemoved)
                        }}
                      />
                    </div>
                  )
                }
              }}
              components={{ Scroller: CustomScrollbar }}
            />
          </div>
        )}
        {isEditing && !isLoading && (
          <div className={Styles.ChargingSpecsAdd}>
            <ChargingSpecsAdd
              key={1}
              data={[...filteredPrivacyRules]}
              onSelect={(value) => {
                handleAdd(value)
              }}
              RenderMenuItem={ChargePointScheduleListItem}
              RenderMenuItemkeys={[
                '_id',
                'name',
                'day',
                'start',
                'end',
                'repeat',
              ]}
            />
          </div>
        )}
      </div>
    )
  },
)

ScheduleList.propTypes = {
  displayedList: PropTypes.array,
  dropdownList: PropTypes.array,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxListHeight: PropTypes.any,
  scrollbarsHeight: PropTypes.any,
  onSubmit: PropTypes.func,
  isEmpty: PropTypes.bool,
}

export default ScheduleList
