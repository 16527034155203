import { IFFilter, ListHeader, WelcomeDashboard } from 'Components'
import { ChargePointDetails, StationList, AddStationModal } from 'Containers'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Styles from './StationsPage.module.css'
import { ChargePointTabs } from 'Containers'
import React, { useRef, useEffect, useState, Fragment } from 'react'
import StationSelectors from 'Stores/Station/Selectors'
import StationActions from 'Stores/Station/Actions'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import AddChargePointModal from 'Containers/AddChargePointModal/AddChargePointModal'
import { Colors } from 'Theme'
import RequestState from 'Enums/RequestState'
import { useTranslation } from 'react-i18next'
import history from 'history/browser'
import LoadingBar from 'react-top-loading-bar'
import {
  EmptyStations,
  EmptySites,
  FilterNoMatch,
  NoInternet,
} from 'Components/IFEmptyStates/IFEmptyStates'
import AuthSelectors from 'Stores/Auth/Selectors'
import InfinityEnums from 'Enums/InfinityEnums'
import { isCityAdded } from 'Utils/FilterMatch'
import { useLocation, useNavigate } from 'react-router-dom'
import { stationsFilter as defaultStationsFilter } from 'Constants/Filters'
import { Emitter } from '../../Services/EmitterService'

const StationsPage = ({
  stationsFilter,
  setStationsFilter,
  clearStations,
  stationListRequestState,
  stationsList,
  selectedIndex,
  fetchStationList,
  setPaginationOffset,
  clearChargePoints,
  clearChargePointLogs,
  clearChargePointTransactions,
  setChargePointLogPaginationOffset,
  setChargePointTransactionPaginationOffset,
  username,
  deleteChargePointRequestState,
  addChargePointRequestState,
  setSelectedIndex,
  chargePoints,
  fetchChargePointListRequestState,
  fetchChargePointLogListRequestState,
  tenantUser,
  resetChargePointState,
  emitterAddStation,
  emitterDeleteStation,
  fetchChargePointList,
  setStationListSelectedIndex,
  fetchStationDetails,
  deleteStationEmitterState,
  resetDeleteStationEmitterState,
  fetchStationCities,
  stationsCount,
  fetchChargePointRequestState,
  isSelectedStationChargePointsEmpty,
  resetChargePointSpecifications,
  tenant,
  fetchStationListCountRequestState,
}) => {
  const addChargePoint = useRef()
  const addStation = useRef()
  const stationListRef = useRef()
  const [filtering, setFiltering] = useState(false)
  const [empty, setEmpty] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [progress, setProgress] = useState(0)
  const [previousStationsFilter, setPreviousStationsFilter] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const filterRef = useRef()
  useEffect(() => {
    fetchStationCities()
    setIsFirstLoad(false)
    return () => {
      clearChargePointLogs()
      clearChargePoints()
      resetChargePointState()
      resetChargePointSpecifications()
    }
  }, [])
  useEffect(() => {
    if (location.pathname === '/sites') {
      setStationsFilter(defaultStationsFilter)
      clearStations()
      clearChargePointLogs()
      clearChargePoints()
      resetChargePointState()
      resetChargePointSpecifications()
      if (!isFirstLoad) {
        fetchStationList(defaultStationsFilter, 0, () => navigate('/NotFound'))
      }
      filterRef?.current?.clearFilter()
    }
  }, [location])
  useEffect(() => {
    if (stationListRequestState === RequestState.LOADING && progress === 0)
      setProgress(progress + 10)
    if (stationListRequestState === RequestState.SUCCEEDED)
      if (stationsList.length === 0) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    if (
      stationListRequestState !== RequestState.LOADING &&
      stationListRequestState !== RequestState.UNINITIALIZED &&
      stationListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [stationListRequestState])

  useEffect(() => {
    if (
      fetchChargePointListRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchChargePointListRequestState === RequestState.SUCCEEDED) {
      if (chargePoints.length === 0) {
        setProgress(100)
      } else {
        setProgress(progress + 40)
      }
    }
    if (
      fetchChargePointListRequestState !== RequestState.LOADING &&
      fetchChargePointListRequestState !== RequestState.UNINITIALIZED &&
      fetchChargePointListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchChargePointListRequestState])

  useEffect(() => {
    if (
      fetchChargePointLogListRequestState === RequestState.LOADING &&
      progress === 0
    )
      setProgress(progress + 10)
    if (fetchChargePointLogListRequestState === RequestState.SUCCEEDED) {
      setProgress(100)
    }
    if (
      fetchChargePointLogListRequestState !== RequestState.LOADING &&
      fetchChargePointLogListRequestState !== RequestState.UNINITIALIZED &&
      fetchChargePointLogListRequestState !== RequestState.SUCCEEDED
    )
      setProgress(100)
  }, [fetchChargePointLogListRequestState])

  const checkFilterEmpty = (newFilter) => {
    const emptyFlag = !newFilter.some((filter) => filter.value.length !== 0)
    if (emptyFlag) setEmpty(true)
    else setEmpty(false)
  }
  const scrollStationList = (index) => {
    stationListRef.current.scrollToIndex({
      index: index,
      behavior: 'smooth',
      align: 'start',
    })
  }

  useEffect(() => {
    if (addChargePointRequestState === RequestState.SUCCEEDED) {
      addChargePoint.current.handleClose()
      resetChargePointState()
    }
  }, [addChargePointRequestState])

  useEffect(() => {
    checkFilterEmpty(stationsFilter)
    if (stationListRequestState === RequestState.SUCCEEDED)
      if (empty) setFiltering(false)
      else setFiltering(true)
  }, [stationListRequestState])

  const handleStationFilter = (newFilter) => {
    let isFilterChanged = false
    for (let index = 0; index < previousStationsFilter.length; index++) {
      const previousFilter = previousStationsFilter[index]
      const stationFilter = newFilter[index]
      if (
        JSON.stringify(previousFilter.value) !==
        JSON.stringify(stationFilter.value)
      ) {
        isFilterChanged = true
        break
      }
    }
    if (previousStationsFilter.length === 0) isFilterChanged = true
    if (isFilterChanged) {
      setStationListSelectedIndex(-1)
      setPaginationOffset(0)
      setChargePointLogPaginationOffset(0)
      setChargePointTransactionPaginationOffset(0)
      history.push({ pathname: '/sites' })
      setStationsFilter(newFilter)
      clearStations()
      clearChargePoints()
      checkFilterEmpty(newFilter, empty)
    }
  }
  const { t } = useTranslation()

  useEffect(() => {
    if (!isCityAdded(previousStationsFilter, stationsFilter)) {
      clearChargePointLogs()
      clearChargePointTransactions()
      let isFilterChanged = false

      for (let index = 0; index < previousStationsFilter.length; index++) {
        const previousFilter = previousStationsFilter[index]
        const stationFilter = stationsFilter[index]
        if (
          JSON.stringify(previousFilter.value) !==
          JSON.stringify(stationFilter.value)
        ) {
          isFilterChanged = true
          break
        }
      }
      if (previousStationsFilter.length === 0) isFilterChanged = true
      if (isFilterChanged) {
        fetchStationList(stationsFilter, 0, () => navigate('/NotFound'))
      }
    }
    setPreviousStationsFilter(JSON.parse(JSON.stringify(stationsFilter)))
  }, [stationsFilter])

  useEffect(() => {
    const slashArray = history.location.pathname.split('/')
    const stationId = slashArray[2]
    let fetchCPs = false

    if (deleteStationEmitterState === RequestState.SUCCEEDED) {
      if (
        stationsList.length > 0 &&
        stationsList[selectedIndex]?._id !== stationId
      ) {
        let currentPath = history.location.pathname
        const index = currentPath.indexOf('site')
        const selectedStation = stationsList[selectedIndex]
        const selectedChargePoint =
          stationsList[selectedIndex]['chargePoints']?.[0]
        if (index !== -1) currentPath = currentPath.substring(0, index - 1)
        if (stationsList[selectedIndex]['chargePoints']?.length > 0) {
          currentPath =
            currentPath +
            `/sites/${selectedStation._id}/stations/${selectedChargePoint._id}`
          fetchCPs = true
        } else currentPath = currentPath + `/sites/${selectedStation._id}`
        history.push({ pathname: currentPath })
        if (fetchCPs) fetchChargePointList(selectedStation._id)
      }
      if (stationsList.length === 0) history.push({ pathname: '/sites' })
      resetDeleteStationEmitterState()
    }
  }, [deleteStationEmitterState])

  useEffect(() => {
    Emitter.subscribeToChannel(
      `tenant/${tenant._id}/stationActions/addStation/`,
      tenant.channelKey,
      emitterAddStation,
      {},
    )
    Emitter.subscribeToChannel(
      `tenant/${tenant._id}/stationActions/deleteStation/`,
      tenant.channelKey,
      emitterDeleteStation,
      {},
    )
    Emitter.subscribeToChannel(
      `tenant/${tenant._id}/stationActions/chargePointOnlineFilter/`,
      tenant.channelKey,
      (response) => {
        fetchStationDetails(response.data)
      },
      {},
    )
    return () => {
      Emitter.unsubscribeFromChannel(
        `tenant/${tenant._id}/stationActions/addStation/`,
        tenant.channelKey,
      )
      Emitter.unsubscribeFromChannel(
        `tenant/${tenant._id}/stationActions/deleteStation/`,
        tenant.channelKey,
      )
      Emitter.unsubscribeFromChannel(
        `tenant/${tenant._id}/stationActions/chargePointOnlineFilter/`,
        tenant.channelKey,
      )
    }
  }, [])

  return (
    <div className={Styles.wrapper}>
      <div>
        <LoadingBar
          color={Colors.primary}
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      {stationListRequestState === RequestState.ERROR_0_NETWORK ? (
        <NoInternet
          onClick={() => {
            window.location.reload()
          }}
        />
      ) : (
        <Fragment>
          <div className={Styles.filterContainer}>
            <div
              className={Styles.filter}
              style={{
                backgroundColor: Colors.white,
                boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
              }}
            >
              <IFFilter
                ref={filterRef}
                key={'StationsPageIFFilter'}
                onFilterChange={(newFilter) => {
                  handleStationFilter(newFilter)
                }}
                filters={stationsFilter}
                textFieldPlaceholder={t(
                  'StationsPage.stationsTextFieldPlaceholder',
                )}
                tenant={tenant}
              />
            </div>
          </div>
          {stationsList.length === 0 &&
          filtering === true &&
          stationListRequestState !== RequestState.LOADING ? (
            <FilterNoMatch
              title={t('emptyStates.noSiteshere')}
              subtitle={t('emptyStates.filterNoSites')}
            />
          ) : (
            <div className={Styles.stationsChargersContainer}>
              <div
                className={Styles.stationList}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                <ListHeader
                  count={stationsCount}
                  title={t('StationListHeader.Sites')}
                  onAddClick={() => {
                    addStation.current.handleOpen()
                  }}
                  isLoading={
                    fetchStationListCountRequestState === RequestState.LOADING
                  }
                  addPermission={tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_ADD_STATION,
                  )}
                />

                {stationsList.length === 0 &&
                stationListRequestState === RequestState.SUCCEEDED ? (
                  <EmptySites
                    addPermission={tenantUser.permissions.includes(
                      InfinityEnums.TenantUserPermission.CAN_EDIT_STATION,
                    )}
                  />
                ) : (
                  <StationList ref={stationListRef} />
                )}
              </div>
              <div
                className={Styles.chargePointsWrapper}
                style={{
                  backgroundColor: Colors.white,
                  boxShadow: `0px 0px 4px ${Colors.BoxShadowColor}`,
                }}
              >
                <div className={Styles.chargePointsContainer}>
                  {stationListRequestState === RequestState.LOADING ? (
                    <ChargePointTabs
                      onAddClick={() => {
                        addChargePoint.current.handleOpen()
                      }}
                      addPermission={tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission.CAN_ADD_CHARGEPOINT,
                      )}
                    />
                  ) : stationsList.length === 0 ||
                    fetchChargePointListRequestState ===
                      RequestState.ERROR_0_NETWORK ||
                    fetchChargePointRequestState ===
                      RequestState.ERROR_0_NETWORK ? null : (
                    <ChargePointTabs
                      onAddClick={() => {
                        addChargePoint.current.handleOpen()
                      }}
                      addPermission={tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission.CAN_ADD_CHARGEPOINT,
                      )}
                    />
                  )}

                  {stationListRequestState === RequestState.LOADING ? (
                    <ChargePointDetails
                      scrollStationListToIndex={scrollStationList}
                    />
                  ) : stationsList.length === 0 &&
                    stationListRequestState === RequestState.SUCCEEDED ? (
                    <WelcomeDashboard
                      username={username ? username : ''}
                      displayedText={t('emptyStates.newSite')}
                    />
                  ) : !isSelectedStationChargePointsEmpty ? (
                    <ChargePointDetails
                      scrollStationListToIndex={scrollStationList}
                    />
                  ) : (
                    <EmptyStations
                      addPermission={tenantUser.permissions.includes(
                        InfinityEnums.TenantUserPermission.CAN_EDIT_STATION,
                      )}
                    />
                  )}
                </div>
              </div>
              <AddChargePointModal ref={addChargePoint} />
              <AddStationModal ref={addStation} />
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    setStationsFilter: (newFilter) =>
      dispatch(StationActions.setStationsFilter(newFilter)),
    clearStations: () => dispatch(StationActions.clearStations()),
    fetchStationList: (filter, offset, onNotFound) =>
      dispatch(StationActions.fetchStationList(filter, offset, onNotFound)),
    setPaginationOffset: (offset) =>
      dispatch(StationActions.setPaginationOffset(offset)),
    clearChargePointLogs: () =>
      dispatch(ChargePointActions.clearChargePointLogs()),
    clearChargePointTransactions: () =>
      dispatch(ChargePointActions.clearChargePointTransactions()),
    setChargePointLogPaginationOffset: (offset) =>
      dispatch(ChargePointActions.setChargePointLogPaginationOffset(offset)),
    setChargePointTransactionPaginationOffset: (offset) =>
      dispatch(
        ChargePointActions.setChargePointTransactionPaginationOffset(offset),
      ),
    clearChargePoints: () => dispatch(ChargePointActions.clearChargePoints()),
    setSelectedIndex: (index) =>
      dispatch(ChargePointActions.setChargePointSelectedIndex(index)),
    resetChargePointState: () =>
      dispatch(ChargePointActions.resetChargePointState()),
    emitterAddStation: (station) =>
      dispatch(StationActions.emitterAddStation(station)),
    emitterDeleteStation: (stationId) =>
      dispatch(StationActions.emitterDeleteStation(stationId)),
    fetchChargePointList: (stationId) =>
      dispatch(ChargePointActions.fetchChargePointList(stationId)),
    setStationListSelectedIndex: (index) =>
      dispatch(StationActions.setStationListSelectedIndex(index)),
    fetchStationDetails: (data) =>
      dispatch(StationActions.fetchStationDetails(data)),
    resetDeleteStationEmitterState: () =>
      dispatch(StationActions.resetDeleteStationEmitterState()),
    fetchStationCities: () => dispatch(StationActions.fetchStationCities()),
    resetChargePointSpecifications: () => {
      dispatch(ChargePointActions.resetChargePointSpecifications())
    },
  }
}

const mapStateToProps = (state) => ({
  stationsFilter: StationSelectors.getStationsFilter(state),
  stationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  stationsList: StationSelectors.getStationsList(state),
  selectedIndex: StationSelectors.getSelectedIndex(state),
  deleteChargePointRequestState:
    ChargePointSelectors.getDeleteChargePointRequestState(state),
  addChargePointRequestState:
    ChargePointSelectors.getAddChargePointRequestState(state),
  chargePoints: ChargePointSelectors.getChargePoints(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointLogListRequestState:
    ChargePointSelectors.getFetchChargePointLogListRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  deleteStationEmitterState:
    StationSelectors.getDeleteStationEmitterState(state),
  stationsCount: StationSelectors.getStationsCount(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  isSelectedStationChargePointsEmpty:
    StationSelectors.isSelectedStationChargePointsEmpty(state),
  fetchStationListCountRequestState:
    StationSelectors.getFetchStationListCountRequestState(state),
  tenant: AuthSelectors.getTenant(state),
})

StationsPage.propTypes = {
  stationsFilter: PropTypes.arrayOf(PropTypes.object),
  setStationsFilter: PropTypes.func,
  clearStations: PropTypes.func,
  username: PropTypes.string,
  stationListRequestState: PropTypes.number,
  stationsList: PropTypes.arrayOf(PropTypes.object),
  selectedIndex: PropTypes.number,
  fetchStationList: PropTypes.func,
  setPaginationOffset: PropTypes.func,
  clearChargePointLogs: PropTypes.func,
  clearChargePointTransactions: PropTypes.func,
  setChargePointLogPaginationOffset: PropTypes.func,
  setChargePointTransactionPaginationOffset: PropTypes.func,
  deleteChargePointRequestState: PropTypes.number,
  addChargePointRequestState: PropTypes.number,
  addStationRequestState: PropTypes.number,
  fetchChargePointListRequestState: PropTypes.number,
  fetchChargePointLogListRequestState: PropTypes.number,
  tenantUser: PropTypes.object,
  setStationListSelectedIndex: PropTypes.func,
  fetchStationDetails: PropTypes.func,
  deleteStationEmitterState: PropTypes.number,
  resetDeleteStationEmitterState: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationsPage)
