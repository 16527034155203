import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  fetchChargePointList: ['stationId'],
  fetchChargePointListLoading: null,
  fetchChargePointListSuccess: ['chargePoints', 'stationsFilter'],
  fetchChargePointListFail: ['error'],

  fetchChargePoint: ['chargePointId'],
  fetchChargePointLoading: null,
  fetchChargePointSuccess: ['chargePoint'],
  fetchChargePointFail: ['error'],

  clearChargePoints: null,
  setChargePointSelectedIndex: ['index'],
  resetChargePointState: null,
  setChargePointLogPaginationOffset: ['offset'],
  setChargePointTransactionPaginationOffset: ['offset'],
  resetFilters: null,

  updateChargePoint: ['index', 'chargePointData', 'onComplete'],
  updateChargePointLoading: null,
  updateChargePointSuccess: ['index', 'updatedChargePoint'],
  updateChargePointFail: ['error'],

  moveChargePoint: ['index', 'chargePointData'],
  moveChargePointLoading: null,
  moveChargePointSuccess: ['index', 'updatedChargePoint'],
  moveChargePointFail: ['error'],

  resetChargePointLoading: null,
  resetChargePointSuccess: null,
  resetChargePointFail: ['error'],

  fetchChargePointLogList: ['filter', 'offset', 'chargePointId', 'ascending'],
  fetchChargePointLogListLoading: null,
  fetchChargePointLogListSuccess: ['offset', 'chargePointLogs', 'nextOffset'],
  fetchChargePointLogListFail: ['error'],
  clearChargePointLogs: null,
  setChargePointLogsFilter: ['newFilter'],

  prependChargePointLogList: ['chargePointLogs'],
  appendBufferChargePointLogList: ['chargePointLogs'],

  fetchChargePointTransactionList: [
    'filter',
    'offset',
    'chargePointId',
    'ascending',
  ],
  fetchChargePointTransactionListLoading: null,
  fetchChargePointTransactionListSuccess: [
    'offset',
    'chargePointTransactions',
    'nextOffset',
  ],
  fetchChargePointTransactionListFail: ['error'],
  clearChargePointTransactions: null,
  setChargePointTransactionsFilter: ['newFilter'],

  fetchChargePointConfigList: ['chargePointId'],
  fetchChargePointConfigListLoading: null,
  fetchChargePointConfigListSuccess: ['data'],
  fetchChargePointConfigListFail: ['error'],
  clearChargePointConfigs: null,
  setChargePointFilterText: ['filter'],

  updateChargePointConfig: [
    'chargePointId',
    'key',
    'value',
    'onSuccess',
    'onFail',
  ],
  updateChargePointConfigLoading: null,
  updateChargePointConfigSuccess: ['key', 'value'],
  updateChargePointConfigFail: ['error'],

  deleteChargePoint: ['chargePointId'],
  deleteChargePointLoading: null,
  deleteChargePointSuccess: ['chargePointId'],
  deleteChargePointFail: ['error'],
  deleteMovedChargePoint: ['chargePointId'],

  addChargePoint: ['chargePoint'],
  addChargePointLoading: null,
  addChargePointSuccess: ['newChargePoint'],
  addChargePointFail: ['error'],

  emitterUpdateChargePoint: ['args'],
  emitterUpdateConnectorSessionStatus: ['args'],
  emitterAddChargePoint: ['args'],
  emitterDeleteChargePoint: ['args'],
  emitterUpdateChargePointDiagnostics: ['args'],

  clearChargePointCacheLoading: null,
  clearChargePointCacheSuccess: null,
  clearChargePointCacheFail: ['error'],

  updateChargePointFirmware: ['file', 'chargePointId', 'onUploadProgress'],
  updateChargePointFirmwareLoading: null,
  updateChargePointFirmwareSuccess: null,
  updateChargePointFirmwareFail: ['error'],

  fetchChargePointUploadDiagnostics: ['chargePointId'],
  fetchChargePointUploadDiagnosticsLoading: null,
  fetchChargePointUploadDiagnosticsSuccess: ['uploadDiagnostics'],
  fetchChargePointUploadDiagnosticsFail: ['error'],

  requestChargePointUploadDiagnostics: ['chargePointId', 'dateTime'],
  requestChargePointUploadDiagnosticsLoading: null,
  requestChargePointUploadDiagnosticsSuccess: ['newUploadDiagnostics'],
  requestChargePointUploadDiagnosticsFail: ['error'],

  updateChargePointFirmwareUpload: ['progress'],

  diagnosticsStatusNotificationLoading: null,
  diagnosticsStatusNotificationSuccess: null,
  diagnosticsStatusNotificationFail: ['error'],
  fetchChargePointSubscriptionsList: ['chargePointId'],
  fetchChargePointSubscriptionsLoading: null,
  fetchChargePointSubscriptionsSuccess: ['subscriptions'],
  fetchChargePointSubscriptionsFail: ['error'],

  deleteChargePointSubscriptions: ['chargePointId', 'subscriptions'],
  deleteChargePointSubscriptionsLoading: null,
  deleteChargePointSubscriptionsSuccess: ['subscriptions'],
  deleteChargePointSubscriptionsFail: ['error'],

  addChargePointSubscriptions: ['chargePointId', 'subscriptions'],
  addChargePointSubscriptionsLoading: null,
  addChargePointSubscriptionsSuccess: ['subscriptions'],
  addChargePointSubscriptionsFail: ['error'],

  changeChargePointAvailabilityLoading: null,
  changeChargePointAvailabilitySuccess: null,
  changeChargePointAvailabilityFail: ['error'],

  fetchChargePointPrivacyScheduleList: ['chargePointId'],
  fetchChargePointPrivacyScheduleLoading: null,
  fetchChargePointPrivacyScheduleSuccess: ['privacySchedule'],
  fetchChargePointPrivacyScheduleFail: ['error'],

  deleteChargePointPrivacyRules: ['chargePointId', 'privacyRules'],
  deleteChargePointPrivacyRulesLoading: null,
  deleteChargePointPrivacyRulesSuccess: ['privacyRules'],
  deleteChargePointPrivacyRulesFail: ['error'],

  addChargePointPrivacyRules: ['chargePointId', 'privacyRules'],
  addChargePointPrivacyRulesLoading: null,
  addChargePointPrivacyRulesSuccess: ['privacyRules'],
  addChargePointPrivacyRulesFail: ['error'],

  fetchChargePointAccessGroups: ['chargePointId'],
  fetchChargePointAccessGroupsLoading: null,
  fetchChargePointAccessGroupsSuccess: ['accessGroups'],
  fetchChargePointAccessGroupsFail: ['error'],
  clearChargePointAccessGroups: null,

  addChargePointAccessGroupsBulk: ['chargePointId', 'accessGroups'],
  addChargePointAccessGroupsBulkLoading: null,
  addChargePointAccessGroupsBulkSuccess: ['accessGroups'],
  addChargePointAccessGroupsBulkFail: ['error'],

  deleteChargePointAccessGroupsBulk: ['chargePointId', 'accessGroups'],
  deleteChargePointAccessGroupsBulkLoading: null,
  deleteChargePointAccessGroupsBulkSuccess: ['ids'],
  deleteChargePointAccessGroupsBulkFail: ['error'],
  setIsChargePointLogsLive: ['flag'],

  resetChargePointSpecifications: null,

  fetchChargePointUptimeStatistics: ['chargePointId', 'filter'],
  fetchChargePointUptimeStatisticsLoading: null,
  fetchChargePointUptimeStatisticsSuccess: ['cpUptimeStatistics'],
  fetchChargePointUptimeStatisticsFail: ['error'],

  fetchConnectorsUtilization: ['chargePointId', 'filter'],
  fetchConnectorsUtilizationLoading: null,
  fetchConnectorsUtilizationSuccess: ['connectorsUtilization'],
  fetchConnectorsUtilizationFail: ['error'],

  setChargePointStatisticsFilter: ['newFilter'],

  fetchChargePointSubscribedAlerts: ['chargePointId'],
  fetchChargePointSubscribedAlertsLoading: null,
  fetchChargePointSubscribedAlertsSuccess: ['alerts'],
  fetchChargePointSubscribedAlertsFail: ['error'],

  fetchChargePointAlerts: ['chargePointId'],
  fetchChargePointAlertsLoading: null,
  fetchChargePointAlertsSuccess: ['alerts'],
  fetchChargePointAlertsFail: ['error'],

  updateChargePointAlerts: ['chargePointId', 'alertChanges', 'onResponse'],
  updateChargePointAlertsLoading: null,
  updateChargePointAlertsSuccess: ['data', 'alertChanges'],
  updateChargePointAlertsFail: ['error'],

  fetchChargePointLocalList: ['chargePointId'],
  fetchChargePointLocalListLoading: null,
  fetchChargePointLocalListSuccess: ['localList'],
  fetchChargePointLocalListFail: ['error'],
  clearChargePointLocalList: null,

  updateChargePointLocalList: [
    'chargePointId',
    'chargingTokenUIDs',
    'userIds',
    'updateType',
    'expiryDate',
    'version',
    'onComplete',
  ],
  updateChargePointLocalListLoading: null,
  updateChargePointLocalListSuccess: null,
  updateChargePointLocalListFail: ['error'],

  fetchChargePointChargingProfiles: ['chargePointId', 'connectorId'],
  fetchChargePointChargingProfilesLoading: null,
  fetchChargePointChargingProfilesSuccess: ['chargingProfiles'],
  fetchChargePointChargingProfilesFail: ['error'],

  fetchChargePointCompositeSchedule: [
    'chargePointId',
    'connectorId',
    'duration',
    'chargingRateUnitType',
  ],
  fetchChargePointCompositeScheduleLoading: null,
  fetchChargePointCompositeScheduleSuccess: ['compositeSchedule'],
  fetchChargePointCompositeScheduleFail: ['error'],

  clearChargePointChargingProfile: [
    'chargePointId',
    'chargingProfileId',
    'connectorId',
    'stackLevel',
    'chargingProfilePurpose',
  ],
  clearChargePointChargingProfileLoading: null,
  clearChargePointChargingProfileSuccess: ['chargingProfileIds'],
  clearChargePointChargingProfileFail: ['error'],

  setChargePointChargingProfile: [
    'chargePointId',
    'chargingProfileId',
    'connectorId',
  ],
  setChargePointChargingProfileLoading: null,
  setChargePointChargingProfileSuccess: ['newChargingProfile'],
  setChargePointChargingProfileFail: ['error'],

  createChargePointChargingProfile: [
    'chargingProfile',
    'chargePointId',
    'connectorId',
  ],
  createChargePointChargingProfileLoading: null,
  createChargePointChargingProfileSuccess: null,
  createChargePointChargingProfileFail: ['error'],
})

export const ChargePointTypes = Types
export default Creators
