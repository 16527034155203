import Styles from './ChargePointMapComponent.module.css'
import {
  IFText,
  StationConnectorMapComponent,
  StationConnectorMapComponentSkeleton,
} from 'Components'
import PropTypes from 'prop-types'
import { Colors } from 'Theme'

const ChargePointMapComponent = ({ chargePoint }) => {
  return (
    <div>
      <div className={Styles.ChargePointHeader}>
        <a
          href={`${window.location.origin}/sites/${chargePoint.station}/stations/${chargePoint._id}`}
          target="_blank"
        >
          <IFText
            className={Styles.ChargePointName}
            style={{ color: Colors.ChargePointNameText }}
          >
            {chargePoint.name}
          </IFText>
        </a>
      </div>
      {chargePoint.connectors.length === 0 ? (
        <StationConnectorMapComponentSkeleton />
      ) : (
        chargePoint.connectors.map((connector, index) => {
          return (
            <StationConnectorMapComponent
              key={`connector-${index}`}
              connector={connector}
              isChargePointOnline={chargePoint.isOnline}
            />
          )
        })
      )}
    </div>
  )
}

ChargePointMapComponent.propTypes = {
  chargePoint: PropTypes.object,
}

export default ChargePointMapComponent
