import Styles from './TransactionStatus.module.css'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Colors from 'Theme/Colors'
import InfinityEnums from 'Enums/InfinityEnums'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@mui/material/Fade'
import { withStyles } from '@material-ui/styles'
const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: (props) => props.statusColor,
    color: Colors.white,
    fontSize: '0.875rem',
    fontFamily: 'ProximaNova',
    padding: '0.3rem',
    marginLeft: '0.313rem',
    alignSelf: 'center',
  },
})(Tooltip)

const TransactionStatus = ({ status }) => {
  const handleTransactionStatusColor = (status) => {
    switch (status) {
      case InfinityEnums.TransactionStatus.CORRECTED:
        return Colors.TransactionStatus.Corrected
      case InfinityEnums.TransactionStatus.REVERSED:
        return Colors.TransactionStatus.Reversed
      case InfinityEnums.TransactionStatus.VOID:
        return Colors.TransactionStatus.Void
      default:
        return Colors.TransactionStatus.Normal
    }
  }
  return (
    <StyledTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 60 }}
      title={status}
      placement="right"
      color="red"
      statusColor={handleTransactionStatusColor(status)}
    >
      <div
        className={Styles.Status}
        style={{ backgroundColor: handleTransactionStatusColor(status) }}
      />
    </StyledTooltip>
  )
}

export default TransactionStatus
