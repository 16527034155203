import RequestState from 'Enums/RequestState'
import IFFilterType from 'Enums/IFFilterType'
import { usersFilter, userTransactionsFilter } from 'Constants/Filters'

export const INITIAL_STATE = {
  users: [],
  selectedUser: null,
  usersCount: 0,
  fetchUserDetailsRequestState: RequestState.UNINITIALIZED,
  addUserSubscriptionsRequestState: RequestState.UNINITIALIZED,
  deleteUserSubscriptionsRequestState: RequestState.UNINITIALIZED,
  addUserAccessGroupsRequestState: RequestState.UNINITIALIZED,
  deleteUserAccessGroupsRequestState: RequestState.UNINITIALIZED,
  inviteUserRequestState: RequestState.UNINITIALIZED,
  fetchUserListRequestState: RequestState.UNINITIALIZED,
  fetchUserListCountRequestState: RequestState.UNINITIALIZED,
  deleteUserRequestState: RequestState.UNINITIALIZED,
  usersPaginationOffset: 0,
  selectedUserIndex: -1,
  usersFilter: usersFilter,
  userTransactions: [],
  fetchUserTransactionsRequestState: RequestState.UNINITIALIZED,
  userTransactionsPaginationOffset: 0,
  userTransactionsFilter: userTransactionsFilter,
  fetchUserSessionListRequestState: RequestState.UNINITIALIZED,
  userSessions: [],
  localListFilteredUsers: [],
  fetchLocalListFilteredUsersRequestState: RequestState.UNINITIALIZED,
  localListFilteredUsersPaginationOffset: 0,
}
