const getUserList = (state) => {
  return state.main.userState.users
}

const getFetchUserListRequestState = (state) => {
  return state.main.userState.fetchUserListRequestState
}

const getUserPaginationOffset = (state) => {
  return state.main.userState.usersPaginationOffset
}

const getUsersFilter = (state) => {
  return state.main.userState.usersFilter
}

const getSelectedUserIndex = (state) => {
  return state.main.userState.selectedUserIndex
}

const getFetchUserDetailsRequestState = (state) => {
  return state.main.userState.fetchUserDetailsRequestState
}

const getAddUserSubscriptionsRequestState = (state) => {
  return state.main.userState.addUserSubscriptionsRequestState
}

const getDeleteUserSubscriptionsRequestState = (state) => {
  return state.main.userState.deleteUserSubscriptionsRequestState
}

const getAddUserAccessGroupsRequestState = (state) => {
  return state.main.userState.addUserAccessGroupsRequestState
}

const getDeleteUserAccessGroupsRequestState = (state) => {
  return state.main.userState.deleteUserAccessGroupsRequestState
}

const getSelectedUser = (state) => {
  return state.main.userState.selectedUser
}

const getUserTransactions = (state) => {
  return state.main.userState.userTransactions
}

const getFetchUserTransactionsRequestState = (state) => {
  return state.main.userState.fetchUserTransactionsRequestState
}

const getUserTransactionsPaginationOffset = (state) => {
  return state.main.userState.userTransactionsPaginationOffset
}

const getUserTransactionsFilter = (state) => {
  return state.main.userState.userTransactionsFilter
}

const getInviteUserRequestState = (state) => {
  return state.main.userState.inviteUserRequestState
}

const getDeleteUserRequestState = (state) => {
  return state.main.userState.deleteUserRequestState
}

const getUsersCount = (state) => {
  return state.main.userState.usersCount
}
const getUserSessions = (state) => {
  return state.main.userState.userSessions
}
const getFetchUserSessionListRequestState = (state) => {
  return state.main.userState.fetchUserSessionListRequestState
}
const getLocalListFilteredUsers = (state) => {
  return state.main.userState.localListFilteredUsers
}

const getFetchLocalListFilteredUsersRequestState = (state) => {
  return state.main.userState.fetchLocalListFilteredUsersRequestState
}
const getLocalListFilteredUsersPaginationOffset = (state) => {
  return state.main.userState.localListFilteredUsersPaginationOffset
}

const UserSelectors = {
  getUserList,
  getFetchUserListRequestState,
  getUserPaginationOffset,
  getUsersFilter,
  getSelectedUserIndex,
  getFetchUserDetailsRequestState,
  getUserTransactions,
  getFetchUserTransactionsRequestState,
  getUserTransactionsPaginationOffset,
  getUserTransactionsFilter,
  getAddUserSubscriptionsRequestState,
  getDeleteUserSubscriptionsRequestState,
  getAddUserAccessGroupsRequestState,
  getDeleteUserAccessGroupsRequestState,
  getSelectedUser,
  getInviteUserRequestState,
  getDeleteUserRequestState,
  getUsersCount,
  getUserSessions,
  getFetchUserSessionListRequestState,
  getLocalListFilteredUsers,
  getFetchLocalListFilteredUsersRequestState,
  getLocalListFilteredUsersPaginationOffset,
}

export default UserSelectors
