import { StationIcon, IFText, ChargePointMapComponent } from 'Components'
import Styles from '../StationMapComponent/StationMapComponent.module.css'
import { Scrollbars } from 'react-custom-scrollbars-2'
import PropTypes from 'prop-types'
import { Colors } from 'Theme'

const StationMapComponent = ({ stationMapData }) => {
  const chargePointsCount = stationMapData.chargePoints.length
  let connectorsCount = 0
  stationMapData.chargePoints.forEach((cp) => {
    if (cp.connectors.length > 0) {
      connectorsCount += cp.connectors.length
    } else {
      connectorsCount++
    }
  })

  return (
    <div
      className={Styles.Container}
      style={{ backgroundColor: Colors.StationMapComponentBackground }}
    >
      <div
        className={Styles.StationHeader}
        style={{ backgroundColor: Colors.StationHeaderBackground }}
      >
        <StationIcon
          maxChargingSpeed={stationMapData.maxPower}
          isClickable={false}
          stationStatus={stationMapData.status}
          isOffline={stationMapData.isStationOffline}
        />
        <IFText
          className={Styles.StationHeaderText}
          style={{ color: Colors.StationHeaderText }}
        >
          {stationMapData.name}
        </IFText>
      </div>

      <div
        className={Styles.StationBody}
        style={{
          height: `${chargePointsCount * 26 + connectorsCount * 56}px`,
          backgroundColor: Colors.StationBodyBackground,
        }}
      >
        <Scrollbars autoHide={true} autoHideDuration={200}>
          {stationMapData.chargePoints.map((cp, index) => {
            return (
              <ChargePointMapComponent
                key={`ChargePoint-${index}`}
                chargePoint={cp}
              />
            )
          })}
        </Scrollbars>
      </div>
    </div>
  )
}

StationMapComponent.propTypes = {
  stationMapData: PropTypes.arrayOf(PropTypes.object),
}

export default StationMapComponent
