import React, {
  useState,
  createRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { connect } from 'react-redux'
import ChargePointSelectors from 'Stores/ChargePoint/Selectors'
import OCPPServices from 'Services/OCPPService'
import {
  IFText,
  ConnectorListItem,
  ConnectorListItemSkeleton,
  ReserveConnector,
  CancelConnectorReservation,
} from 'Components'
import { Icons } from 'Theme'
import Styles from './ConnectorList.module.css'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import RequestState from 'Enums/RequestState'
import ReactTimeAgo from 'react-time-ago'
import StationSelectors from 'Stores/Station/Selectors'
import StartTx from 'Components/StartTx/StartTx'
import InfinityEnums from 'Enums/InfinityEnums'
import AuthSelectors from 'Stores/Auth/Selectors'
import ChargePointActions from 'Stores/ChargePoint/Actions'
import moment from 'moment'
import { Emitter } from '../../Services/EmitterService'
import validator from 'validator'

const ConnectorList = React.forwardRef(
  (
    {
      connectorList,
      isLoading = false,
      fetchChargePointListRequestState,
      fetchStationListRequestState,
      isEdit,
      onMaxPowerChange,
      onTypeChange,
      chargePointUid,
      isChargePointOffline,
      chargePoint,
      tenantUser,
      emitterUpdateConnectorSessionStatus,
      fetchChargePointRequestState,
      onStatusChange = () => {},
      tenant,
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const NumberOfActions = 4
    const connectorsLength = connectorList.length
    const connectorListItemRefs = React.useRef([])
    const confirmStartTxRefs = React.useRef([])
    const reserveConnectorRef = React.useRef()
    const cancelReserveConnectorRef = React.useRef()
    const [previousChargePoint, setPreviousChargePoint] = useState(null)
    if (confirmStartTxRefs.length !== connectorsLength) {
      confirmStartTxRefs.current = Array(connectorsLength)
        .fill()
        .map((_, i) => confirmStartTxRefs.current[i] || createRef())
    }

    if (connectorListItemRefs.current.length !== connectorsLength) {
      connectorListItemRefs.current = Array(connectorsLength)
        .fill()
        .map((_, i) => connectorListItemRefs.current[i] || createRef())
    }

    useImperativeHandle(ref, () => ({
      resetMaxPowerValues,
      closeAccordions,
      resetStatusValue,
    }))
    const [loadingStates, setLoadingStates] = useState(
      new Array(connectorList.length * NumberOfActions).fill(false),
    )

    const resetStatusValue = () => {
      connectorListItemRefs.current.map((connectorListItemRef) => {
        if (connectorListItemRef && connectorListItemRef.current !== null) {
          connectorListItemRef.current.resetStatusValue()
        }
      })
    }

    const handleLoadingStates = (index, offset, value) => {
      setLoadingStates((prevState) => {
        prevState[NumberOfActions * index + offset] = value
        return [...prevState]
      })
    }

    const resetMaxPowerValues = () => {
      connectorListItemRefs.current.map((connectorListItemRef) => {
        if (connectorListItemRef && connectorListItemRef.current !== null) {
          connectorListItemRef.current.resetMaxPowerValue()
        }
      })
    }

    const closeAccordions = () => {
      connectorListItemRefs.current.map((connectorListItemRef) => {
        if (connectorListItemRef && connectorListItemRef.current !== null) {
          connectorListItemRef.current.closeAccordion()
        }
      })
    }
    const dismiss = (index, isError) => {
      if (isError) confirmStartTxRefs.current[index].current.handleError()
      else confirmStartTxRefs.current[index].current.dismiss()
    }
    const dismissReserveConnector = (isError) => {
      if (isError) reserveConnectorRef.current.handleError()
      else reserveConnectorRef.current.dismiss()
    }
    const dismissCancelReserveConnectorRef = () => {
      cancelReserveConnectorRef.current.dismiss()
    }
    useEffect(() => {
      if (previousChargePoint) {
        if (chargePoint) {
          if (previousChargePoint._id !== chargePoint._id) {
            Emitter.unsubscribeFromChannelGroup(
              `tenant/${tenantUser.tenant}/chargePoint/${previousChargePoint.uid}/connector/`,
              tenant.channelKey,
            )
          }
        } else {
          Emitter.unsubscribeFromChannelGroup(
            `tenant/${tenantUser.tenant}/chargePoint/${previousChargePoint.uid}/connector/`,
            tenant.channelKey,
          )
        }
      }
      if (chargePoint) {
        setPreviousChargePoint(chargePoint)
        chargePoint.connectors.forEach((connector) => {
          Emitter.subscribeToChannel(
            `tenant/${tenantUser.tenant}/chargePoint/${chargePoint.uid}/connector/${connector.uid}/sessionStatus/`,
            tenant.channelKey,
            emitterUpdateConnectorSessionStatus,
            {},
          )
        })
      }
    }, [chargePoint])
    return (
      <div className={Styles.ConnectorsWrapper}>
        {fetchChargePointListRequestState === RequestState.LOADING ||
        fetchStationListRequestState === RequestState.LOADING ||
        fetchChargePointRequestState === RequestState.LOADING ||
        isLoading ? (
          [...Array(2)].map((e, index) => (
            <div
              key={index}
              style={{
                marginBottom: '0.5rem',
              }}
            >
              <ConnectorListItemSkeleton
                editPermission={tenantUser.permissions.includes(
                  InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING,
                )}
              />
            </div>
          ))
        ) : connectorList.length === 0 ? (
          <div className={Styles.EmptySet}>
            <img src={Icons.emptySet.default} alt="" />
            <IFText>{t('ConnectorList.Empty')}</IFText>
          </div>
        ) : (
          connectorList
            .filter((connector) => {
              return connector.uid !== 0
            })
            .map((connector, index) => (
              <div
                key={index}
                style={{
                  marginBottom:
                    index === connectorList.length - 1 ? '0rem' : '0.5rem',
                }}
              >
                <StartTx
                  ref={confirmStartTxRefs.current[index]}
                  isUsingMobileApp={tenant.isUsingMobileApp}
                  onStartClick={(input) => {
                    const isEmail = validator.isEmail(input)
                    OCPPServices.startTransaction(
                      chargePoint._id,
                      connector.uid,
                      tenant.isUsingMobileApp ? undefined : input,
                      isEmail
                        ? tenant.isUsingMobileApp
                          ? input
                          : undefined
                        : undefined,
                      isEmail
                        ? undefined
                        : tenant.isUsingMobileApp
                        ? input
                        : undefined,
                      (isError) => {
                        dismiss(index, isError)
                      },
                    )
                  }}
                />
                <ConnectorListItem
                  ref={connectorListItemRefs.current[index]}
                  isUsingMobileApp={tenant.isUsingMobileApp}
                  maxPower={connector.maxPower}
                  status={connector.status}
                  type={connector.type}
                  isChargePointOffline={isChargePointOffline}
                  uid={connector.uid}
                  meterValue={connector.meterValue}
                  session={connector.session}
                  reservation={connector.reservation}
                  lastUpdateTime={
                    <ReactTimeAgo
                      date={new Date(connector.statusUpdatedAt)}
                      locale="en-US"
                      timeStyle="mini"
                    />
                  }
                  onStartClick={() => {
                    confirmStartTxRefs.current[index].current.show()
                    handleLoadingStates(index, 0, true)
                  }}
                  onStopClick={() => {
                    // TODO: charging token and charging session ?
                    handleLoadingStates(index, 1, true)
                    OCPPServices.stopTransaction(
                      chargePoint._id,
                      connector.uid,
                      () => {
                        handleLoadingStates(index, 1, false)
                      },
                    )
                  }}
                  onUnlockClick={() => {
                    handleLoadingStates(index, 2, true)
                    OCPPServices.unlockConnector(
                      chargePoint._id,
                      connector.uid,
                      () => {
                        handleLoadingStates(index, 2, false)
                      },
                    )
                  }}
                  isEdit={isEdit}
                  onMaxPowerChange={(value) =>
                    onMaxPowerChange(value, connector._id)
                  }
                  onStatusChange={(value) =>
                    onStatusChange(value, connector._id)
                  }
                  onTypeChange={(value) => onTypeChange(value, connector._id)}
                  isStopTxLoading={loadingStates[NumberOfActions * index + 1]}
                  isUnlockLoading={loadingStates[NumberOfActions * index + 2]}
                  isStartTxDead={
                    connector.status !==
                      InfinityEnums.ConnectorStatus.AVAILABLE &&
                    connector.status !== InfinityEnums.ConnectorStatus.PREPARING
                  }
                  isStopTxDead={
                    !(
                      connector.status ===
                        InfinityEnums.ConnectorStatus.CHARGING ||
                      connector.status ===
                        InfinityEnums.ConnectorStatus.SUSPENDEDEV ||
                      connector.status ===
                        InfinityEnums.ConnectorStatus.SUSPENDEDEVSE
                    )
                  }
                  editPermission={tenantUser.permissions.includes(
                    InfinityEnums.TenantUserPermission.CAN_EDIT_CHARGING,
                  )}
                  onSyncClick={() => {
                    handleLoadingStates(index, 3, true)
                    OCPPServices.syncConnectorStatus(
                      chargePoint._id,
                      connector.uid,
                      () => {
                        handleLoadingStates(index, 3, false)
                      },
                    )
                  }}
                  isSyncLoading={loadingStates[NumberOfActions * index + 3]}
                  onReserveClick={() => {
                    reserveConnectorRef.current.show(connector.uid)
                  }}
                  onCancelReserveClick={() => {
                    cancelReserveConnectorRef.current.show(connector.uid)
                  }}
                  tenantUser={tenantUser}
                  errorCode={connector.errorCode}
                  vendorErrorCode={connector.vendorErrorCode}
                  vendorId={connector.vendorId}
                />
              </div>
            ))
        )}
        <ReserveConnector
          ref={reserveConnectorRef}
          isUsingMobileApp={tenant.isUsingMobileApp}
          onClick={(chargingTokenUid, reserveTime, connectorUid) => {
            let reserveInfo = reserveTime.split(' ')
            let expiresAt = moment(Date.now()).add(
              Number(reserveInfo[0]),
              reserveInfo[1],
            )
            OCPPServices.reserveConnector(
              chargePoint._id,
              connectorUid,
              tenant.isUsingMobileApp ? undefined : chargingTokenUid,
              expiresAt,
              tenant.isUsingMobileApp ? chargingTokenUid : undefined,
              (isError) => {
                dismissReserveConnector(isError)
              },
            )
          }}
        />
        <CancelConnectorReservation
          ref={cancelReserveConnectorRef}
          onClick={(connectorUid) => {
            const connector = connectorList.find((c) => c.uid === connectorUid)
            OCPPServices.CancelConnectorReservation(
              connector.reservation._id,
              () => {
                dismissCancelReserveConnectorRef()
              },
            )
          }}
        />
      </div>
    )
  },
)

const mapStateToProps = (state) => ({
  connectorList: ChargePointSelectors.getChargePointConnectors(state),
  fetchChargePointListRequestState:
    ChargePointSelectors.getFetchChargePointListRequestState(state),
  fetchChargePointRequestState:
    ChargePointSelectors.getFetchChargePointRequestState(state),
  chargePointUid: ChargePointSelectors.getChargePointUid(state),
  chargePoint: ChargePointSelectors.getSelectedChargePoint(state),
  fetchStationListRequestState:
    StationSelectors.getFetchStationListRequestState(state),
  tenantUser: AuthSelectors.getTenantUser(state),
  tenant: AuthSelectors.getTenant(state),
})
function mapDispatchToProps(dispatch) {
  return {
    emitterUpdateConnectorSessionStatus: (args) =>
      dispatch(ChargePointActions.emitterUpdateConnectorSessionStatus(args)),
  }
}

ConnectorList.propTypes = {
  connectorList: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchChargePointListRequestState: PropTypes.number,
  fetchChargePointRequestState: PropTypes.number,
  isEdit: PropTypes.bool,
  onMaxPowerChange: PropTypes.func,
  onTypeChange: PropTypes.func,
  chargePointUid: PropTypes.string,
  tenantUser: PropTypes.object,
  emitterUpdateConnectorSessionStatus: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ConnectorList)
